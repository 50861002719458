import React, {useCallback, useEffect, useState} from "react";
import commentaireService from "../../../services/survox-back/commentaire.service";
import { Commentaire } from "../../../models/commentaire/commentaire.model";
import {Box, Collapse, FormControl, Typography} from "@mui/material";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import CommentairesListe from "../../../components/commentaire/commentairesListe";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import {VoxTextArea} from "../../../components/genericComponentsUI/input/voxTextArea";
import VoxConditionalWrapper from "../../../components/layouts/wrapper/voxConditionalWrapper";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";

export default function OperationsSIRSDetailCommentaires(props: { operationSIRSId: number, commentsCardHeight: number }) {

    // States
    const [commentaireOperationSIRS, setCommentaireOperationSIRS] = useState<Commentaire[]>([]);
    const [isLoadingCommentairesOperationSIRS, setIsLoadingCommentairesOperationSIRS] = useState(false);
    const [isLoadingCommentairesSIRSError, setIsLoadingCommentairesSIRSError] = useState(false);
    const [messageCommentairesSIRSError, setMessageCommentairesSIRSError] = useState<string>('');

    // States pour la création d'un commentaire
    const [openCollapse, setOpenCollapse] = useState(false);
    const [commentaireSIRS, setCommentaireSIRS] = useState<string>('');
    const [isLoadingCreationCommentaireSIRS, setIsLoadingCreationCommentaireSIRS] = useState(false);
    const [isErrorCreationCommentaireSIRS, setIsErrorCreationCommentaireSIRS] = useState(false);
    const [messageCreationCommentaireSIRSError, setMessageCreationCommentaireSIRSError] = useState<string>('');


    /**
     * Méthode qui permet de charger les commentaires associés à l'opération SIRS
     * @param id , ID de l'opération SIRS
     * @param controller
     */
    const chargerCommentairesOperationSIRS = (id: number, controller?: AbortController) => {

        setIsLoadingCommentairesOperationSIRS(true);
        setIsLoadingCommentairesSIRSError(false);

        commentaireService.getCommentairesByOperationSIRSId(id, controller?.signal)
            .then(response => {
                // Récupération des commentaires de l'opération SIRS
                setCommentaireOperationSIRS(response.data);
                setIsLoadingCommentairesOperationSIRS(false);
                setIsLoadingCommentairesSIRSError(false)
            })
            .catch(err => {
                if (err.message !== 'canceled') {
                    setIsLoadingCommentairesOperationSIRS(false);
                    if (err.response.status === 404) {
                        setIsLoadingCommentairesSIRSError(true);
                        setMessageCommentairesSIRSError(err.response.data.message);   // "L'opération SIRS avec l'id XX n'existe pas"
                    }
                }
            })

    }

    /**
     * Méthode permettant de créer un commentaire sur une opération
     */
    const creerCommentaireSIRS = useCallback((idOperationSIRS: number, commentaireOperationSIRS: string) => {
        setIsLoadingCreationCommentaireSIRS(true);
        setIsErrorCreationCommentaireSIRS(false);
        commentaireService.createCommentaireSIRS(idOperationSIRS, commentaireOperationSIRS)
            .then(response => {
                if (response.status === 201) {
                    setIsLoadingCreationCommentaireSIRS(false);
                    setIsErrorCreationCommentaireSIRS(false);
                    setCommentaireSIRS('');
                    chargerCommentairesOperationSIRS(idOperationSIRS);
                }
            })
            .catch(error => {
                setIsLoadingCreationCommentaireSIRS(false);
                setIsErrorCreationCommentaireSIRS(true);
                if (error.response.status === 404) {
                    setMessageCreationCommentaireSIRSError(error.response.data.message);
                } else if (error.response.status === 400 && error.response.data.message[0] === "Le commentaire ne doit pas être vide.") {
                    setMessageCreationCommentaireSIRSError(error.response.data.message[0]);
                } else {
                    setMessageCreationCommentaireSIRSError("Une erreur est survenue lors de l'enregistrement du commentaire.")
                }
            });
    }, []);

    /**
     * Lors de la validation du formulaire, appel de cette méthode 'handleOnCreateCommentaire' pour faire la création d'un commentaire
     * @param event
     */
    const handleOnCreateCommentaireSIRS = useCallback((event: any) => {
        event.preventDefault();
        setOpenCollapse(prevState => !prevState);
        creerCommentaireSIRS(props.operationSIRSId, commentaireSIRS);
    }, [props.operationSIRSId, commentaireSIRS, creerCommentaireSIRS])

    /**
     * Appel de cette méthode dès la modification de la valeur du textarea "commentaire" (afin de mettre à jour la valeur)
     * @param event
     */
    const handleOnChangeCommentaireSIRS = (event: any) => {
        setCommentaireSIRS(event.target.value)
    }


    /**
     * Fonction anonyme gérant l'affichage des commentaires SIRS
     */
    const showCommentairesSIRS = () => {
        if (isLoadingCommentairesOperationSIRS) {
            return <VoxLoader/>
        } else if (isLoadingCommentairesSIRSError) {
            return <VoxAlert severity={"error"} sx={{margin: 2}}>{messageCommentairesSIRSError}</VoxAlert>
        } else if (commentaireOperationSIRS.length > 0) {
            return <CommentairesListe listeCommentairesOperation={commentaireOperationSIRS}/>
        } else {
            return (
                <Box display={"flex"} justifyContent={"center"} padding={3}>
                    <Typography>Aucun commentaire à afficher...</Typography>
                </Box>
            )
        }
    }


    // Au chargement du composant
    useEffect(() => {
        const controller = new AbortController();
        if (props.operationSIRSId) {
            chargerCommentairesOperationSIRS(props.operationSIRSId, controller);
        }
        return () => controller.abort();
    }, [props.operationSIRSId])


    return (
        <>
            <VoxCard
                id={"card-commentaires"}
                iconBeforeTitle={"comments"}
                title={"Commentaires"}
                action={
                    <VoxButton
                        variant={"text"}
                        size={"small"}
                        endIcon={
                            <FontAwesomeIcon
                                icon={openCollapse ? "chevron-down" : "chevron-right"}
                                style={{minWidth: '14px'}}
                            />
                        }
                        onClick={() => setOpenCollapse(!openCollapse)}
                        aria-expanded={openCollapse}
                        aria-controls={"collapse-commentaire"}
                        sx={{marginRight: 1.5}}
                    >
                        Ajouter
                    </VoxButton>
                }
            >

                <Box sx={{
                    maxHeight: (props.commentsCardHeight - 56), // 56px = height du header de la card
                    overflow: 'auto',
                    margin: '-24px'
                }}>
                    {/* Ajout d'un commentaire */}
                    <Collapse
                        id={"collapse-commentaire"}
                        in={openCollapse}
                        sx={{
                            position: 'sticky',
                            top: 0,
                            background: 'white',
                            '&::after': {
                                content: '""',
                                display: 'block',
                                borderBottom: '1px solid rgba(0,0,0,.075)'
                            }
                        }}
                    >
                        <FormControl
                            onSubmit={handleOnCreateCommentaireSIRS}
                            component={"form"}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                marginBottom: '1.5rem',
                                padding: '1rem 1.5rem 0 1.5rem'
                            }}
                        >

                            {/* TextArea */}
                            <VoxTextArea
                                id={"comment-input"}
                                label={"Ajouter un commentaire"}
                                minRows={3}
                                value={commentaireSIRS}
                                onChange={handleOnChangeCommentaireSIRS}
                                required={true}
                                disabled={isLoadingCreationCommentaireSIRS}
                            />

                            {/* Bouton "Envoyer" */}
                            <VoxConditionalWrapper
                                condition={isLoadingCreationCommentaireSIRS}
                                wrapper={children => (
                                    <Box position={"relative"}
                                         display={"flex"}
                                         justifyContent={"center"}
                                    >
                                        {children}
                                        <VoxLoader size={20}/>
                                    </Box>
                                )}
                            >
                                <VoxButton
                                    customColor={"green"}
                                    size={"small"}
                                    startIcon={<FontAwesomeIcon icon={"check"}/>}
                                    type={"submit"}
                                    disabled={isLoadingCreationCommentaireSIRS}
                                    sx={{margin: '0 auto'}}
                                >
                                    Envoyer
                                </VoxButton>
                            </VoxConditionalWrapper>

                            {/* Erreur lors de la création d'un commentaire */}
                            {isErrorCreationCommentaireSIRS &&
                                <VoxAlert severity={"error"} sx={{mt: 2}}>
                                    {messageCreationCommentaireSIRSError}
                                </VoxAlert>
                            }

                        </FormControl>
                    </Collapse>

                    {/* Liste des commentaires */}
                    {showCommentairesSIRS()}
                </Box>
            </VoxCard>
        </>
    )

}