import { SxProps } from "@mui/material";
import Alert from "@mui/material/Alert";
import React, {ReactNode, useEffect, useState} from "react";
import './voxAlert.css'

type AlertCardProps = {
    severity: 'success' | 'info' | 'warning' | 'error';
    children: ReactNode
    onClose?: () => void
    sx?: SxProps
}

export const VoxAlert = (props: AlertCardProps) => {

    // Ajout d'un fond coloré selon le type d'alerte
    const [backgroundColor, setBackgroundColor] = useState<string>('');
    const [closeColor, setCloseColor] = useState<string>('');

    useEffect(() => {
        switch (props.severity) {
            case 'success':
                setBackgroundColor('cobalt.greenBackground');
                setCloseColor('cobalt.greenIcon')
                break;
            case 'info':
                setBackgroundColor('cobalt.turquoiseBackground');
                setCloseColor('cobalt.turquoiseIcon')
                break;
            case 'warning':
                setBackgroundColor('cobalt.orangeBackground');
                setCloseColor('cobalt.orangeIcon')
                break;
            case 'error':
                setBackgroundColor('cobalt.redBackground');
                setCloseColor('cobalt.redIcon')
                break;
        }
    }, [props.severity])

    return (
        <Alert
            severity={props.severity}
            elevation={2}
            variant={"outlined"}
            sx={{
                fontWeight: 500,
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: backgroundColor,
                ...props.sx,
                '.MuiAlert-action': {
                    color: closeColor
                }
            }}
            onClose={props.onClose}
        >
            {props.children}
        </Alert>
    )
}