import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import operationService from "../../../services/survox-back/operation/operation.service";
import { CellProps, Column, IdType, Row } from "react-table";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import { OperationArchivees } from "../../../models/operation/operationArchivees.model";
import { affichageDateWithFormatDMYHm } from "../../../utils/string.utils";
import { sortDatesInTables } from "../../../utils/date.utils";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";
import VoxLinkOperation from "../../../components/genericComponentsUI/link/voxLinkOperation";

export default function OperationsArchiveesListe() {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les appels asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [operationsArchiveesListe, setOperationsArchiveesListe] = useState<OperationArchivees[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    /**
     * Chargement des données du tableau : toutes les opérations archivées
     */
    const chargerToutesOperationsArchivees = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        operationService.getAllOperationsArchivees(controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setOperationsArchiveesListe(response.data);
                    setIsLoading(false);
                    setIsLoadingError(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                    setIsLoadingError(true);
                }
            });
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        chargerToutesOperationsArchivees(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [chargerToutesOperationsArchivees]);

    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationArchivees[] = useMemo(() => operationsArchiveesListe, [operationsArchiveesListe]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationArchivees>[] = useMemo(() => [
        {
            Header: 'Opération',
            accessor: 'nomClient',
            minWidth: 150,
            Cell: (props: CellProps<OperationArchivees>) => (
                <VoxLinkOperation href={`/operation/detail/${props.row.original.id}`}>
                    {props.value}
                </VoxLinkOperation>
            )
        },
        {
            Header: 'URL',
            accessor: 'url',
            minWidth: 200,
            Cell: (props: CellProps<OperationArchivees>) => <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
        },
        {
            Header: "Date de clôture du tour en cours",
            id: "dateClotureTourEnCours",
            accessor: (originalRow: OperationArchivees) => affichageDateWithFormatDMYHm(originalRow.dateClotureTourEnCours),
            minWidth: 150,
            sortType: (a: Row<OperationArchivees>,
                b: Row<OperationArchivees>,
                columnId: IdType<OperationArchivees>,
                desc: boolean | undefined) => sortDatesInTables(a.original.dateClotureTourEnCours, b.original.dateClotureTourEnCours, desc),

        },
        {
            Header: "Chef(fe) de projet",
            accessor: "utilisateur",
            minWidth: 150,
        },
        {
            Header: "Date de suppression",
            id: 'dateSuppression',
            accessor: (originalRow: OperationArchivees) => affichageDateWithFormatDMYHm(originalRow.dateSuppression),
            minWidth: 150,
            sortType: (a: Row<OperationArchivees>,
                b: Row<OperationArchivees>,
                columnIdId: IdType<OperationArchivees>,
                desc: undefined | boolean) => sortDatesInTables(a.original.dateSuppression, b.original.dateSuppression, desc),
        },
        {
            Header: "Supprimé par",
            accessor: "utilisateurSuppression",
            minWidth: 150,
        }
    ], []);


    if (isLoading) {
        return <VoxLoader />
    } else if (isLoadingError && isErrorVisible) {
        return (
            <VoxAlert severity={"error"} onClose={() => setIsErrorVisible(false)}>
                Une erreur est survenue lors du chargement des opérations archivées.
            </VoxAlert>
        )
    } else {
        return (
            <VoxTable
                data={rows}
                columns={columns}
                initialStateSortBy={[{ id: "dateSuppression", desc: true }, { id: "nom" }]}
            />
        )
    }

}