import VoxModal from "./voxModal";
import { Typography } from "@mui/material";
import { TypeProduit } from "../../models/versionApplicative/typeProduit.enum";

type ModalProps = {
    show: boolean
    handleClose: () => void
    handleSubmit: () => void
    typeProduit: TypeProduit
}


export default function ModaleConfirmationVersionsApplicatives(props: Readonly<ModalProps>) {

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Confirmation"}
            content={
                <Typography variant={"body1"}>
                    {(props.typeProduit === TypeProduit.VOXCORE_ELECTION || props.typeProduit === TypeProduit.VOXCORE_AG) &&
                        <p>Souhaitez-vous vraiment choisir ces version(s) comme autorisée(s) pour les Chefs de projets ?</p>
                    }
                    Les versions archivées n'apparaîtront plus.
                </Typography>
            }
            widthButton={"100px"}
            closeButtonText={"Annuler"}
            submitButtonText={"Valider"}
        />
    )
}