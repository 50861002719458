import { useParams } from "react-router-dom";
import { Groupe } from "../../../models/utilisateur/groupe.model";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import "./groupesDetail.css";
import React, { useEffect, useMemo, useState } from "react";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { CellProps, Column, FilterProps } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import groupeService from "../../../services/survox-back/utilisateur/groupe.service";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import { Grid, List, ListItem, MenuItem, Typography } from "@mui/material";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";


export default function GroupesDetail() {
    let params = useParams();

    const [groupe, setGroupe] = useState<Groupe>();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);
    const [messageErreur, setMessageErreur] = useState("");


    useEffect(() => {
        const controller = new AbortController();
        chargerGroupe(Number(params.id), controller);
        return () => controller.abort();
    }, [params.id]);

    /**
     * Charger le groupe (avec l'ensemble de ses utilisateurs)
     */
    const chargerGroupe = (id: number, controller: AbortController) => {
        setIsLoading(true);
        setIsLoadingError(false);
        groupeService.getGroupe(id, controller.signal)
            .then(response => {
                if (response.status === 200) {
                    setGroupe(response.data);
                    setIsLoading(false);
                    setIsLoadingError(false);
                }
            })
            .catch((err) => {
                if (err.message !== "canceled") {
                    setIsLoading(false);
                    setIsLoadingError(true);
                    if (err.response.status === 404) {
                        setMessageErreur(err.response.data.message);    // Le groupe avec l'id ... n'existe pas.
                    } else {
                        setMessageErreur("Une erreur est survenue lors du chargement du groupe.");
                    }
                }
            });
    }

    /**
     * Configuration des colonnes du tableau
     */
    const columns : Column<Partial<Utilisateur>>[] = useMemo( () => [
            {
                Header: 'Login',
                accessor: 'login',
                flexGrow: 1,
                minWidth: 120,
            },
            {
                Header: 'Prénom',
                accessor: 'prenom',
                flexGrow: 0.5,
                minWidth: 120,
            },

            {
                Header: 'Nom',
                accessor: 'nom',
                flexGrow: 1,
                minWidth: 120,
            },
            {
                Header: 'Actif',
                accessor: 'estActif',
                flexGrow: 0.3,
                minWidth: 90,
                maxWidth: 110,
                Cell: (props: CellProps<Partial<Utilisateur>>) => {
                    return (
                        <>
                            {(props.value) ? <FontAwesomeIcon icon={"check"}/> : <FontAwesomeIcon icon={"times"}/>}
                        </>
                    )
                },
                Filter: ({column: {filterValue, setFilter}}: FilterProps<Partial<Utilisateur>>) => {
                    return (
                        <Select
                            value={!filterValue ? '' : filterValue}
                            onChange={(event: SelectChangeEvent) => setFilter(event.target.value || undefined)}
                            size={'small'}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem aria-label={"None"} value={''} sx={{ height: 36 }}/>
                            <MenuItem value={"true"} sx={{ height: 36 }}>Actif</MenuItem>
                            <MenuItem value={"false"} sx={{ height: 36 }}>Inactif</MenuItem>
                        </Select>
                    )
                },
                filter: 'equal',
                sortType: 'basic'
            },
        ], []
    );

    /**
     * Remplissage des lignes du tableau
     */
    const rows: Partial<Utilisateur>[] = useMemo(() => {
        if (groupe) {
            return groupe.utilisateurs.map(
                (utilisateur: Utilisateur) => ({
                    id: utilisateur.id,
                    login: utilisateur.login,
                    prenom: utilisateur.prenom,
                    nom: utilisateur.nom,
                    estActif: utilisateur.estActif
                })
            );
        } else {
            return [];
        }
    }, [groupe]);


    return (
        <>
            { isLoading &&
                <VoxLoader />
            }
            { isLoadingError ?
                <VoxAlert severity={"error"}>
                    { messageErreur }
                </VoxAlert>
                :
                (groupe &&
                    <Grid container gap={3}>

                       <Grid item xs={12} sm={9} md={7}>
                           <VoxCard iconBeforeTitle={"info-circle"} title={"Détail du groupe"}>
                               <List sx={{padding: 0}}>
                                   <ListItem>
                                       <Typography fontWeight={500} pr={2}>Nom :</Typography>
                                       <Typography component={"p"} variant={"h4"} fontWeight={700}>{groupe.nom}</Typography>
                                   </ListItem>
                                   <ListItem>
                                       <Typography fontWeight={500} pr={2}>Nombre d'utilisateurs :</Typography>
                                       <Typography component={"p"} variant={"h4"} fontWeight={700}>{groupe.utilisateurs.length}</Typography>
                                   </ListItem>
                               </List>
                           </VoxCard>
                       </Grid>

                        <Grid item xs={12}>
                            <Typography component={"div"} variant={"h3"} sx={{pt: 4, pb: 2}}>
                                Liste des utilisateurs du groupe
                            </Typography>
                            <VoxTable
                                data={rows}
                                columns={columns}
                                cellProps={(cell: CellProps<Partial<Utilisateur>>) => {
                                    if (cell.column.id !== "estActif") {
                                        return {
                                            style: {textAlign: "left"}
                                        }
                                    }
                                }}
                                initialStateSortBy={ [{id: "login", desc: false}] }
                            />
                        </Grid>

                    </Grid>
                )
            }
        </>
    )
}