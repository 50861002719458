import React from "react";
import GroupesListe from "./groupesListe";
import { Navigate, Route, Routes } from "react-router-dom";
import GroupesDetail from "./groupesDetail";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import { Grid } from "@mui/material";

export default function GroupesView() {
    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Liste des groupes"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={7}>
                            <GroupesListe/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"detail/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/administration/groupes"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={7}>
                            <GroupesDetail/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}