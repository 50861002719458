import Typography from "@mui/material/Typography";
import { Box, InputAdornment, SxProps, TextField } from "@mui/material";
import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

type TextInputProps = {
    id: string;
    name?: string;
    // Label
    startIconLabel?: IconProp;
    label?: string;
    // Input
    startIcon?: IconProp;
    type?: HTMLInputTypeAttribute;
    placeholder?: string;
    value?: string | number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    pattern?: string;
    isVisiblePasswordOption?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    // Autres Options
    title?: string;
    min?: number;
    minLength?: number;
    maxLength?: number;
    multiline?: boolean;
    multilineMaxRows?: number;
    helperText?: string;
    // Style CSS
    fullWidth?: boolean;
    sx?: SxProps;
}

export const VoxTextField = (props: TextInputProps) => {

    // Ce state est utilisé seulement lorsque la propriété isVisiblePasswordOption est à TRUE
    // (utilisé dans le cas des inputs de type password)
    const [inputPasswordType, setInputPasswordType] = useState<string>(props.isVisiblePasswordOption ? "password" : "");

    // Afficher/Masquer le texte renseigné dans l'input avec l'icône
    // (fonction utiliser seulement lorsque la propriété isVisiblePasswordOption est à TRUE)
    const handlePasswordVisibility = () => {
        if (inputPasswordType === 'password') {
            setInputPasswordType('text');
        } else {
            setInputPasswordType('password');
        }
    }

    return (
        <TextField
            id={props.id}
            name={props.name}
            sx={{
                ...props.sx,
                marginTop: !props.label ? 1 : undefined,
                '& input::placeholder': {
                    opacity: 1,
                    color: 'rgba(102,109,146,0.7)' // Lynch (70%)
                }
            }}
            label={
                props.label &&
                <>
                    {props.startIconLabel &&
                        <Box component={"span"} sx={{pr: 1}}>
                            <FontAwesomeIcon icon={props.startIconLabel}/>
                        </Box>
                    }
                    <Typography component={"span"} variant="subtitle1">{props.label}</Typography>
                </>
            }
            multiline={props.multiline}
            maxRows={props.multilineMaxRows}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            inputProps={{
                minLength: props.minLength,
                maxLength: props.maxLength,
                pattern: props.pattern,
                title: props.title,
                min: props.min,
                autoFocus: props.autoFocus,
                // Désactive l'incrémentation de l'input type "number" au scroll
                onWheel: (props.type === "number") ? (e => e.currentTarget.blur()) : undefined,
                sx: {
                    '&:hover': {
                        backgroundColor: 'white',
                        borderRadius: '10px'
                    }
                }
            }}
            InputProps={{
                startAdornment: (
                    props.startIcon &&
                    <InputAdornment position={"start"} sx={{color: 'cobalt.lynch'}}>
                        <FontAwesomeIcon icon={props.startIcon} size={"sm"}/>
                    </InputAdornment>
                ),
                endAdornment: (
                    // Icône œil permettant de rendre visible ou non le texte dans l'input
                    props.isVisiblePasswordOption &&
                    <InputAdornment position="end" sx={{cursor: 'pointer'}}>
                        <CobaltIcon
                            name={inputPasswordType === 'text' ? 'eye' : 'eye-off'}
                            onClick={handlePasswordVisibility}
                        />
                    </InputAdornment>
                )
            }}
            fullWidth={props.fullWidth}
            required={props.required}
            disabled={props.disabled}
            type={props.isVisiblePasswordOption ? inputPasswordType : props.type}
            helperText={props.helperText}
        />
    )
}