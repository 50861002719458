import axios_instance from "../axios-client";
import { PortefeuilleProjetDto } from "./dtos/portefeuilleProjet.dto";

const portefeuilleProjetService =  {

    getAllPortefeuillesProjetsAvecNbOperations: (signal?: AbortSignal) => {
        return axios_instance.get('/portefeuilles_projets/all_detail', { signal });
    },

    getAllPortefeuillesProjets: (signal: AbortSignal) => {
        return axios_instance.get('/portefeuilles_projets/all', {signal: signal});
    },

    getPortefeuillesProjetsAvecSuiviUtilisateur: (signal?: AbortSignal) => {
        return axios_instance.get('/portefeuilles_projets/avec_suivi_utilisateur', { signal });
    },

    getPortefeuilleProjetById: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`portefeuilles_projets/portefeuille_projet/${id}`, { signal });
    },

    createPortefeuilleProjet: (portefeuilleProjetDto: PortefeuilleProjetDto) => {
        return axios_instance.post("/portefeuilles_projets", portefeuilleProjetDto)
    },

    updatePortefeuilleProjet: (id: number, portefeuilleProjetDto: PortefeuilleProjetDto) => {
        return axios_instance.put(`/portefeuilles_projets/modification/${id}`, portefeuilleProjetDto);
    },

    deletePortefeuilleProjet: (id: number) => {
        return axios_instance.delete(`/portefeuilles_projets/supprimer/${id}`);
    }

}

export default portefeuilleProjetService;