import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import UtilisateursModification from "./utilisateursModification";
import UtilisateursCreation from "./utilisateursCreation";
import UtilisateursListe from "./utilisateursListe";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import {Grid} from "@mui/material";


export default function UtilisateursView() {
    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Liste des utilisateurs"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={8}>
                            <UtilisateursListe/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/administration/utilisateurs"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={8}>
                            <UtilisateursModification/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"/creation"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/administration/utilisateurs"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} md={6}>
                            <UtilisateursCreation/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}