import './dashboard.css'
import React, {useEffect, useState} from "react";
import operationService from "../../services/survox-back/operation/operation.service";
import {TypeOperation} from "../../models/operation/typeOperation.enum";
import DataCounter from "../../components/dashboard/dataCounter/dataCounter";
import {ResultatSurveillance} from "../../models/surveillance/resultatSurveillance.enum";
import DataDoughnut from "../../components/dashboard/dataDoughnut/dataDoughnut";
import {StatutPVDemat} from "../../models/operation/statutPVDemat.enum";
import DataBar from "../../components/dashboard/dataBar/dataBar";
import {RegionOvh} from "../../models/virtualMachine/regionOvh.enum";
import {Hebergeur} from "../../models/operation/hebergeur.enum";
import {StatutOperation} from "../../models/operation/statutOperation.enum";
import {TypePrestation} from "../../models/operationSIRS/typePrestation.enum";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type DataIndicateursType = {
    // Types de plateforme
    nbOpeTypeActionnariat: number,
    nbOpeTypeSIRS: number,
    nbOpeTypeVisiovote: number,
    nbOpeTypeVote: number,
    nbOpeTypeVoteVoxcoreElection: number,
    nbOpeTypeVoteVoxcoreAG: number,
    nbOpeTypeVoteSaturne: number,
    nbOpeTypeVoteMaileva: number,
    // Vote > Environnements
    nbEnvVoteDocaposte: number,
    nbEnvVoteOVH: number,
    // Vote > Environnements > Régions OVH
    nbEnvVoteOVHGra3: number,
    nbEnvVoteOVHGra5: number,
    nbEnvVoteOVHSbg5: number,
    nbEnvVoteOVHSbg7: number,
    // Vote > Environnements > Phases de vote
    nbEnvVoteAvantVote: number,
    nbEnvVoteEnCours: number,
    nbEnvVoteASupprimer: number,
    nbEnvVoteEnProduction: number,
    // SIRS > Environnements
    nbEnvSIRSMyBDES: number,
    nbEnvSIRSUnlimited: number
};

type DataAlertesType = {
    // Vote > Supervision technique
    nbAlertesVoteSiteKO: number,
    nbAlertesVoteSiteKOAcquittes: number,
    nbAlertesVoteCertificatKO: number,
    // Vote > Supervision métier
    nbAlertesVoteScellementsKO: number,
    nbAlertesVoteDemandesElecteursKO: number,
    nbAlertesVoteHorodatageKO: number,
    nbAlertesVoteTeletransmissionPVDematKO: number,
    // SIRS > Supervision technique
    nbAlertesSIRSSiteKO: number,
    nbAlertesSIRSSiteKOAcquittes: number,
    nbAlertesSIRSCertificatKO: number,
    // Visio > Supervision technique
    nbAlertesVisioSiteKO: number,
    nbAlertesVisioCertificatKO: number
};

export default function Dashboard() {

    // Initialisation des indicateurs (pour le chargement de la page)
    const [dataIndicateursDashboard, setDataIndicateursDashboard] = useState<DataIndicateursType>({
        // Types de plateforme
        nbOpeTypeActionnariat: 0,
        nbOpeTypeSIRS: 0,
        nbOpeTypeVisiovote: 0,
        nbOpeTypeVote: 0,
        nbOpeTypeVoteVoxcoreElection: 0,
        nbOpeTypeVoteVoxcoreAG: 0,
        nbOpeTypeVoteSaturne: 0,
        nbOpeTypeVoteMaileva: 0,
        // Vote > Environnements
        nbEnvVoteDocaposte: 0,
        nbEnvVoteOVH: 0,
        // Vote > Environnements > Régions OVH
        nbEnvVoteOVHGra3: 0,
        nbEnvVoteOVHGra5: 0,
        nbEnvVoteOVHSbg5: 0,
        nbEnvVoteOVHSbg7: 0,
        // Vote > Environnements > Phases de vote
        nbEnvVoteAvantVote: 0,
        nbEnvVoteEnCours: 0,
        nbEnvVoteASupprimer: 0,
        nbEnvVoteEnProduction: 0,
        // SIRS > Environnements
        nbEnvSIRSMyBDES: 0,
        nbEnvSIRSUnlimited: 0
    });

    // Initialisation des alertes (pour le chargement de la page)
    const [dataAlertesDashboard, setDataAlertesDashboard] = useState<DataAlertesType>({
        // Vote > Supervision technique
        nbAlertesVoteSiteKO: 0,
        nbAlertesVoteSiteKOAcquittes: 0,
        nbAlertesVoteCertificatKO: 0,
        // Vote > Supervision métier
        nbAlertesVoteScellementsKO: 0,
        nbAlertesVoteDemandesElecteursKO: 0,
        nbAlertesVoteHorodatageKO: 0,
        nbAlertesVoteTeletransmissionPVDematKO: 0,
        // SIRS > Supervision technique
        nbAlertesSIRSSiteKO: 0,
        nbAlertesSIRSSiteKOAcquittes: 0,
        nbAlertesSIRSCertificatKO: 0,
        // Visio > Supervision technique
        nbAlertesVisioSiteKO: 0,
        nbAlertesVisioCertificatKO: 0
    });

    // Heure d'actualisation de la page
    const [updateTime, setUpdateTime] = useState<string>();

    // Récupération des données (indicateurs + alertes)
    const chargerDataDashboard = (controller?: AbortController) => {
        operationService.getDataDashboard(controller?.signal)
            .then(response => {
                if (response.status === 200) {
                    const dataDashboard = response.data;
                    setDataIndicateursDashboard((prevState: DataIndicateursType) => ({...prevState, ...dataDashboard.indicateursDashboard}));
                    setDataAlertesDashboard((prevState: DataAlertesType) => ({...prevState, ...dataDashboard.alertesDashboard}));
                    // Mise à jour de l'heure d'actualisation de la page
                    setUpdateTime(dayjs(new Date()).format('HH:mm:ss'));
                }
            })
            .catch(() => {});
    };


    useEffect(() => {
        const controller = new AbortController();
        chargerDataDashboard(controller);
        // Mise à jour de la page toutes les minutes
        const interval = setInterval(chargerDataDashboard, 60000);
        return () => {
            clearInterval(interval);
            controller.abort();
        }
    }, []);


    return (
        <div className={"dashboard-container"}>
            <div className={"dashboard-grid"}>

                {/* Heure de dernière mise à jour */}
                <div className={"dashboard-last-update"} title={"Dernière mise à jour"} onClick={() => chargerDataDashboard()}>
                    <FontAwesomeIcon icon={'history'} className={'me-1'}/> <span>{updateTime}</span>
                </div>

                {/* Bloc "Types de plateforme" : prend 100% de largeur */}
                <div className="dashboard-block" id={"types_plateforme"}>
                    <h2>Types de plateforme</h2>
                    <div className="content">
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeActionnariat}
                            name={'Actionnariat'}
                            link={`/operations/production?type=${TypeOperation.ACTIONNARIAT}`}
                        />
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeSIRS}
                            name={'SIRS'}
                            link={`/sirs/sites_production`}
                        />
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeVisiovote}
                            name={'VisioVote'}
                            link={`/operations/production?type=${TypeOperation.VISIOVOTE}`}
                        />
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeVote}
                            name={'Vote'}
                            link={`/operations/production?type=${TypeOperation.VOXCORE_ELECTION}&type=${TypeOperation.VOXCORE_AG}&type=${TypeOperation.SATURNE}&type=${TypeOperation.MAILEVA}`}
                        />
                        <div className="separation"/>
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeVoteVoxcoreElection}
                            name={'VoxCore-Election'}
                            link={`/operations/production?type=${TypeOperation.VOXCORE_ELECTION}`}
                        />
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeVoteVoxcoreAG}
                            name={'VoxCore-AG'}
                            link={`/operations/production?type=${TypeOperation.VOXCORE_AG}`}
                        />
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeVoteSaturne}
                            name={'Saturne'}
                            link={`/operations/production?type=${TypeOperation.SATURNE}`}
                        />
                        <DataCounter
                            value={dataIndicateursDashboard.nbOpeTypeVoteMaileva}
                            name={'MailEva'}
                            link={`/operations/production?type=${TypeOperation.MAILEVA}`}
                        />
                    </div>
                </div>

                {/* Bloc "Vote" : prend 66% de largeur */}
                <div className="dashboard-block" id={"vote"}>
                    <h2>Vote</h2>
                    <div className="content">

                        {/* Vote > Supervision technique */}
                        <div className="dashboard-sub-block">
                            <h3>Supervision technique</h3>
                            <div className="content">
                                <div className="doughnut-container">
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVoteSiteKO}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        name={'Sites KO'}
                                        link={`/operations/production?surveillance=${ResultatSurveillance.KO}`}
                                        valueAcquittement={(dataAlertesDashboard.nbAlertesVoteSiteKOAcquittes > 0) ? dataAlertesDashboard.nbAlertesVoteSiteKOAcquittes : undefined}
                                    />
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVoteCertificatKO}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        name={'Certificats KO'}
                                        link={`/operations/production?surveillance=${ResultatSurveillance.ERREUR_CERTIFICAT}`}
                                    />
                            </div>
                            </div>
                        </div>

                        {/* Vote > Supervision métier */}
                        <div className="dashboard-sub-block">
                            <h3>Supervision métier</h3>
                            <div className="content">
                                <div className="doughnut-container">
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVoteScellementsKO}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        name={'Scellements KO'}
                                        link={`/operations/production?surveillance=${ResultatSurveillance.SCELLEMENT_KO}`}
                                    />
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVoteDemandesElecteursKO}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        name={'Demandes Électeurs KO'}
                                        link={`/operations/production?surveillance=${ResultatSurveillance.DEMANDE_ELECTEUR_KO}`}
                                    />
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVoteHorodatageKO}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        name={'Horodatages KO'}
                                        link={'/operations/production?horodatage=false'}
                                    />
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVoteTeletransmissionPVDematKO}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        name={'Télétransmissions PV KO'}
                                        link={`/operations/production?teletransmission=${StatutPVDemat.ECHEC}`}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Vote > Environnements */}
                        <div className="dashboard-sub-block" id={"environnements"}>
                            <h3>Environnements</h3>
                            <div className="content">
                                <DataCounter
                                    value={dataIndicateursDashboard.nbEnvVoteDocaposte}
                                    name={'Docaposte'}
                                    link={`/operations/production?hebergeur=${Hebergeur.DOCAPOSTE}`}
                                />
                                <DataCounter
                                    value={dataIndicateursDashboard.nbEnvVoteOVH}
                                    name={'OVH'}
                                    link={`/operations/production?hebergeur=${Hebergeur.OVH}`}
                                />
                                <div className={"d-flex flex-column w-100 px-4"}>
                                    <h4>Répartition OVH par région</h4>
                                    <DataBar
                                        label={'GRA3'}
                                        labelWidth={40}
                                        value={dataIndicateursDashboard.nbEnvVoteOVHGra3}
                                        total={dataIndicateursDashboard.nbEnvVoteOVH}
                                        link={`/operations/production?region=${RegionOvh.GRA3}`}
                                    />
                                    <DataBar
                                        label={'GRA5'}
                                        labelWidth={40}
                                        value={dataIndicateursDashboard.nbEnvVoteOVHGra5}
                                        total={dataIndicateursDashboard.nbEnvVoteOVH}
                                        link={`/operations/production?region=${RegionOvh.GRA5}`}
                                    />
                                    <DataBar
                                        label={'SBG5'}
                                        labelWidth={40}
                                        value={dataIndicateursDashboard.nbEnvVoteOVHSbg5}
                                        total={dataIndicateursDashboard.nbEnvVoteOVH}
                                        link={`/operations/production?region=${RegionOvh.SBG5}`}
                                    />
                                    <DataBar
                                        label={'SBG7'}
                                        labelWidth={40}
                                        value={dataIndicateursDashboard.nbEnvVoteOVHSbg7}
                                        total={dataIndicateursDashboard.nbEnvVoteOVH}
                                        link={`/operations/production?region=${RegionOvh.SBG7}`}
                                    />
                                </div>
                                <div className={"d-flex flex-column w-100 px-4"}>
                                    <h4>Répartition par phase de vote</h4>
                                    <DataBar
                                        label={'Avant-vote'}
                                        labelWidth={100}
                                        value={dataIndicateursDashboard.nbEnvVoteAvantVote}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        link={`/operations/production?statut=${StatutOperation.AVANT_VOTE}`}
                                    />
                                    <DataBar
                                        label={'En cours'}
                                        labelWidth={100}
                                        value={dataIndicateursDashboard.nbEnvVoteEnCours}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        link={`/operations/production?statut=${StatutOperation.VOTE}`}
                                    />
                                    <DataBar
                                        label={'À supprimer'}
                                        labelWidth={100}
                                        value={dataIndicateursDashboard.nbEnvVoteASupprimer}
                                        total={dataIndicateursDashboard.nbEnvVoteEnProduction}
                                        link={`/operations/supprimer`}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Bloc "SIRS" : prend 33% de largeur */}
                <div className="dashboard-block" id={"sirs"}>
                    <h2>SIRS</h2>
                    <div className="content">

                        {/* SIRS > Supervision technique */}
                        <div className="dashboard-sub-block">
                            <h3>Supervision technique</h3>
                            <div className="content">
                                <div className="doughnut-container">
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesSIRSSiteKO}
                                        total={dataIndicateursDashboard.nbOpeTypeSIRS}
                                        name={'Sites KO'}
                                        link={`/sirs/sites_production?surveillance=${ResultatSurveillance.KO}`}
                                        valueAcquittement={(dataAlertesDashboard.nbAlertesSIRSSiteKOAcquittes > 0) ? dataAlertesDashboard.nbAlertesSIRSSiteKOAcquittes : undefined}
                                    />
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesSIRSCertificatKO}
                                        total={dataIndicateursDashboard.nbOpeTypeSIRS}
                                        name={'Certificats KO'}
                                        link={`/sirs/sites_production?surveillance=${ResultatSurveillance.ERREUR_CERTIFICAT}`}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* SIRS > Environnements */}
                        <div className="dashboard-sub-block">
                            <h3>Environnements</h3>
                            <div className="content">
                                <DataCounter
                                    value={dataIndicateursDashboard.nbEnvSIRSMyBDES}
                                    name={'MyBdes'}
                                    link={`/sirs/sites_production?prestation=${TypePrestation.MY_BDES}`}
                                />
                                <DataCounter
                                    value={dataIndicateursDashboard.nbEnvSIRSUnlimited}
                                    name={'Unlimited'}
                                    link={`/sirs/sites_production?prestation=${TypePrestation.UNLIMITED}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bloc "VisioVote" : prend 33% de largeur */}
                <div className="dashboard-block" id={"visio"}>
                    <h2>VisioVote</h2>
                    <div className="content">

                        {/* SIRS > Supervision technique */}
                        <div className="dashboard-sub-block">
                            <h3>Supervision technique</h3>
                            <div className="content">
                                <div className="doughnut-container">
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVisioSiteKO}
                                        total={dataIndicateursDashboard.nbOpeTypeVisiovote}
                                        name={'Sites KO *'}
                                        link={`/dashboard`}
                                    />
                                    <DataDoughnut
                                        value={dataAlertesDashboard.nbAlertesVisioCertificatKO}
                                        total={dataIndicateursDashboard.nbOpeTypeVisiovote}
                                        name={'Certificats KO *'}
                                        link={`/dashboard`}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}