import { useNavigate } from "react-router-dom";
import React, { FormEvent, useContext, useState } from "react";
import { UpdateMdpUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/updateMdpUtilisateur.dto";
import { AuthContext } from "../../../contexts/authentication/authentication.context";
import utilisateurService from "../../../services/survox-back/utilisateur/utilisateur.service";
import operationService from "../../../services/survox-back/operation/operation.service";
import { PATTERN_PASSWORD, TITLE_PATTERN_PASSWORD } from "../../../utils/string.utils";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import VoxCard from "../../genericComponentsUI/card/voxCard";
import { Grid, Typography } from "@mui/material";
import { VoxLegendChampsObligatoires } from "../../genericComponentsUI/label/voxLegendChampsObligatoires";
import { VoxTextField } from "../../genericComponentsUI/input/voxTextField";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";


export default function ModificationMdpForm() {

    const navigate = useNavigate();

    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isOtherError, setIsOtherError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const authenticationContext = useContext(AuthContext);

    const onUpdateMdp = (updateMdpUtilisateurDto: UpdateMdpUtilisateurDto) => {
        setIsError(false);
        setIsOtherError(false);
        setIsLoadingSubmit(true);
        utilisateurService.updateMdpUtilisateur(updateMdpUtilisateurDto)
            .then((response) => {
                if (response.status === 200) {
                    setIsError(false);
                    setIsOtherError(false);

                    authenticationContext.refreshToken();

                    // Vérification pour l'affichage d'une modale de rappel concernant les opérations à supprimer
                    operationService.verificationAffichageModaleDeRappel(authenticationContext.utilisateur)
                        .then((stateLocation) => {
                            navigate('/', {state: stateLocation});
                        })
                        .finally(() => {
                            setIsLoadingSubmit(false);
                        });
                }
            })
            .catch((error) => {
                setIsLoadingSubmit(false);
                setIsError(true);
                if (error.response.status === 422) {
                    setIsOtherError(false);
                    // 3 réponses de message possibles :
                    // -> "Le mot de passe actuel renseigné n'est pas correct."
                    // -> "La confirmation du nouveau mot de passe est incorrecte."
                    // -> "Le nouveau mot de passe doit être différent."
                    setErrorMessage(error.response.data.message);
                }
                // Type d'erreur qui ne doit pas arriver (car la page va apparaitre seulement pour les utilisateurs concernés)
                if (error.response.status === 401) {
                    setIsOtherError(true);
                    setErrorMessage(error.response.data.message);    // -> "Vous n'êtes pas autorisé à la modification du mot de passe !"
                }
                if (error.response.status === 404) {
                    setIsOtherError(true);
                    setErrorMessage(error.response.data.message);    // -> "L'utilisateur avec l'id ... n'existe pas."
                }
            })
    }

    /**
     * Méthode déclenchée à la validation du formulaire
     * @param event
     */
    const handleOnUpdateMdp = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.target;
        // Lecture des données du formulaire
        const formData = new FormData(form as HTMLFormElement);
        // L'attribut "name" sur les inputs sera utilisé comme clé dans les données récupérées du formulaire
        const {mdp, newMdp, newMdpConfirm} = Object.fromEntries(formData.entries()) as UpdateMdpUtilisateurDto;

        const isNewMdpValide: boolean = validationNewMdp(newMdp, newMdpConfirm);
        if (isNewMdpValide) {
            onUpdateMdp({ mdp, newMdp, newMdpConfirm });
        }
    }

    /**
     * Vérification que le nouveau mot de passe a été écrit à l'identique
     * @param nouveauMdp    Nouveau mot de passe
     * @param nouveauMdpConfirmation    Nouveau mot de passe pour confirmation
     */
    const validationNewMdp = (nouveauMdp: string, nouveauMdpConfirmation: string) : boolean => {
        setIsError(false);
        setErrorMessage("");
        if (nouveauMdp !== nouveauMdpConfirmation) {
            setIsError(true);
            setErrorMessage("La confirmation du nouveau mot de passe est incorrecte.");
            return false;
        } else {
            return true;
        }
    }


    return(
        <VoxCard>
            {/* Message d'erreur générique (si une erreur se produit) */}
            {(isError && isOtherError) &&
                <VoxAlert severity={"error"}>{errorMessage}</VoxAlert>
            }

            {/* Formulaire */}
            <form onSubmit={handleOnUpdateMdp}>
                <Grid container spacing={3} padding={2}>
                    <Grid item xs={12}>
                        <VoxLegendChampsObligatoires/>
                    </Grid>

                    {/* Champ : Mot de passe actuel */}
                    <Grid item xs={12}>
                        <VoxTextField
                            id={"mdp"}
                            name={"mdp"}
                            label={"Mot de passe actuel"}
                            fullWidth
                            isVisiblePasswordOption={true}
                            required={true}
                            disabled={isLoadingSubmit}
                        />
                        {(isError && errorMessage === "Le mot de passe actuel renseigné n'est pas correct.") &&
                            <VoxAlert severity={"error"}>
                                {errorMessage}
                            </VoxAlert>
                        }
                    </Grid>

                    {/* Champ : Nouveau mot de passe */}
                    <Grid item xs={12}>
                        <VoxTextField
                            id={"newMdp"}
                            name={"newMdp"}
                            label={"Nouveau mot de passe"}
                            pattern={PATTERN_PASSWORD}
                            title={TITLE_PATTERN_PASSWORD}
                            fullWidth
                            isVisiblePasswordOption={true}
                            required={true}
                            disabled={isLoadingSubmit}
                        />
                        <Typography variant={"body2"} color={"cobalt.lynch"} id={"passwordHelp"}>
                            {TITLE_PATTERN_PASSWORD}
                        </Typography>
                        {(isError && errorMessage === "Le nouveau mot de passe doit être différent de l'ancien.") &&
                            <VoxAlert severity={"error"}>
                                {errorMessage}
                            </VoxAlert>
                        }
                    </Grid>

                    {/* Champ : Confirmation du nouveau mot de passe */}
                    <Grid item xs={12}>
                        <VoxTextField
                            id={"newMdpConfirm"}
                            name={"newMdpConfirm"}
                            label={"Confirmation du nouveau mot de passe"}
                            pattern={PATTERN_PASSWORD}
                            title={TITLE_PATTERN_PASSWORD}
                            fullWidth
                            isVisiblePasswordOption={true}
                            required={true}
                            disabled={isLoadingSubmit}
                        />
                        {(isError && errorMessage === "La confirmation du nouveau mot de passe est incorrecte.") &&
                            <VoxAlert severity={"error"}>
                                {errorMessage}
                            </VoxAlert>
                        }
                    </Grid>

                    <Grid item xs={12} display={"flex"} justifyContent={"center"} mt={1}>
                        <VoxButton customColor={"green"} type={"submit"} disabled={isLoadingSubmit}>
                            Valider
                        </VoxButton>
                    </Grid>
                </Grid>
            </form>

            {
                isLoadingSubmit && <VoxLoader isBackgroundColor={true}/>
            }
        </VoxCard>
    )
}