import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Fonction permettant d'ajouter le token d'accès à la configuration d'une requête HTTP avec Axios
 * @param config La configuration de la requête
 * @param accessToken Le token d'accès que l'on souhaite transmettre dans les headers de la requête
 */
export const setAuthorizationHeader = (config: AxiosRequestConfig, accessToken: string): AxiosRequestConfig => {
    if (accessToken) {
        if (config.headers) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        } else {
            config.headers = { 'Authorization': `Bearer ${accessToken}` };
        }
    }
    return config;
}

/**
 * Fonction qui définie le traitement éxécuté à chaque requêtes depuis le client
 * @param config La configuration de la requête
 * @param accessToken Le token d'accès que l'on souhaite transmettre dans les headers de la requête
 */
export const requestInterceptorOnFulfilled = (config: AxiosRequestConfig, accessToken: string): AxiosRequestConfig => {
    return setAuthorizationHeader(config, accessToken);
}

/**
 * Fonction qui définie le traitement éxécuté à chaque requêtes depuis le client lorsqu'il y a une erreur (côté client)
 * @param error L'objet définissant l'erreur qui rencontrée
 */
export const requestInterceptorOnRejected = (error: any) => {
    return Promise.reject(error);
}

/**
 * Fonction qui définie le traitement éxécuté à chaque reponse reçu après une requête HTTP sans erreurs
 * @param response L'objet représentant la réponse HTTP
 */
export const responseInterceptorOnFulfilled = (response: AxiosResponse): AxiosResponse => {
    return response;
}

type QueueRequest = {
    resolve: () => void,
    reject: (error: boolean | null) => void
}

let failedQueue: QueueRequest[] = [];
let isRefreshing = false;

const processQueue = (error: boolean | null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
}

export const isRequestCanceled = (err: Error) => {
    return err.message === 'canceled';
}

/**
 * Fonction qui définie le traitement éxécuté à chaque reponse reçu après une requête HTTP avec erreurs
 * @param error L'objet définissant l'erreur qui rencontrée
 * @param login Callback utilisée pour mettre à jour l'authentification après refresh du token effectif
 * @param logout Callback utilisée pour déconnecter l'utilisateur après refrech du token non achevé
 */
export const responseInterceptorOnRejected = (error: any,login: (accessToken: string) => void, logout: () => void) => {
    if (error.message === "Network Error" && error.config && error.config.url === "/login") {
        return Promise.reject(error);
    } else if (error.message === "Network Error") {
        logout();
        return Promise.reject(error);
    } else if (error.message === "canceled") {
        return Promise.reject(error);
    }

    // L'on récupère la configuration de la requête qui a échouée
    const originalRequest = error.response.config;

    if (error.response.config.url === "/login" || error.response.config.url === "/refresh") {
        logout();
        return Promise.reject(error);
    }
    if (error.response.status === 401 && error.response.data.message === "Le compte de l'utilisateur est désactivé") {
        logout()
        return Promise.reject(error);
    }

    // L'on vérifie que le code erreur correspond bien au cas où le token est expiré et que c'est bien la
    // première fois que la requête échoue pour empêcher un refresh de token infini
    if (error.response.status === 401 && error.response.message !== "Erreur lors de l'authentification" && !originalRequest._retry) {
        // Le flag _retry est utilisé pour relancer une requête qu'une seule fois
        originalRequest._retry = true;

        // Dans le cas où il y a déjà un refresh de token en cours pour ne pas en refaire un, l'on place
        // la requête dans une file pour attendre le nouveau token et réessayer
        if (isRefreshing) {
            // On stocke donc une promesse pour gérer le cas s'il y a eu une erreur lors du refresh du token
            return new Promise<void>((resolve, reject) => failedQueue.push({ resolve, reject }))
                .then(() => instance(originalRequest))
                .catch(err => Promise.reject(err));
        }

        // On change l'état pour indiquer que l'on est en train de rafraichir le token
        isRefreshing = true;

        return instance.post("/refresh")
            .then(res => {
                login(res.data.access_token);
                processQueue(null);
                return instance.request(originalRequest)
                    .then(response => Promise.resolve(response))
                    .catch(err => Promise.reject(err));
            })
            .catch(errRefreshToken => {
                if(errRefreshToken.response.status === 401) {
                    logout();
                    processQueue(errRefreshToken);
                }
                return Promise.reject(errRefreshToken);
            })
            .finally(() => {
                // On change la valeur de IsRefreshing pour indiquer que l'on a fini de rafraichir le token
                isRefreshing = false;
            });
    }
    return Promise.reject(error);
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_SURVOX_BACK_URL || "http://localhost:8080",
    withCredentials: true,
})

export default instance;