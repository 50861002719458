import React from "react";
import OperationsASupprimerListe from "../../components/operationsListe/operationsASupprimerListe";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsMailevaASupprimerView() {
    return (
        <>
            <VoxHeader title={"Opérations Maileva à supprimer"}/>
            <OperationsASupprimerListe typeOperation={TypeOperation.MAILEVA} />
        </>
    )
}