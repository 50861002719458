import React, {ChangeEvent, FormEvent, useContext, useMemo, useState} from "react";
import {Grid, MenuItem, SelectChangeEvent, Typography} from "@mui/material";
import {VoxLegendChampsObligatoires} from "../../genericComponentsUI/label/voxLegendChampsObligatoires";
import Box from "@mui/material/Box";
import {OperationSIRSFormulaire} from "../../../models/operationSIRS/operationSIRSFormulaire.model";
import {VoxTextField} from "../../genericComponentsUI/input/voxTextField";
import {VoxCalendarInput} from "../../genericComponentsUI/input/voxCalendarInput";
import dayjs from "dayjs";
import {VoxAlert} from "../../genericComponentsUI/alerte/voxAlert";
import {VoxSelectInput} from "../../genericComponentsUI/input/voxSelectInput";
import {TypePrestation} from "../../../models/operationSIRS/typePrestation.enum";
import {
    affichageLibelleEtatDemande,
    formatDebutUrlOperationSIRS,
    PATTERN_EMAIL,
    PATTERN_TELEPHONE_FRANCAIS
} from "../../../utils/string.utils";
import {SIRSContactClient} from "../../../models/operationSIRS/sirsContactClient.model";
import VoxIconAction from "../../genericComponentsUI/pictogrammes/voxIconAction";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {VoxTextArea} from "../../genericComponentsUI/input/voxTextArea";
import {EtatDemande} from "../../../models/operation/etatDemande.enum";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {Utilisateur} from "../../../models/utilisateur/utilisateur.model";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";


// Props du formulaire
type OperationSIRSFormPropsType = {
    operationSIRS: OperationSIRSFormulaire,
    onSubmit: (operationSIRS: OperationSIRSFormulaire, contactsClientListe: EditSIRSContactClient[]) => void,
    isLoadingOnSubmit: boolean,
    isErrorOnSubmit: boolean,
    isErrorDateInstallation: boolean,
    isErrorTypePrestation: boolean,
    messageError: string
};

// Props des contacts client SIRS
export type EditSIRSContactClient = SIRSContactClient & { isUpdate: boolean, isDelete: boolean, isNew: boolean };


/**
 * Fonction composant contenant le formulaire d'opération SIRS
 */
export default function OperationsSirsForm(props: OperationSIRSFormPropsType) {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    // Récupération de l'opération SIRS via les props (init à vide si CRÉATION)
    const [operationSIRS, setOperationSIRS] = useState<OperationSIRSFormulaire>(props.operationSIRS);

    const etatDemandeInitialOperationSIRS = useMemo(() => props.operationSIRS.etatDemande, [props.operationSIRS.etatDemande]);

    // Booléen permettant de savoir si on est en mode CRÉATION ou MODIFICATION
    // (si l'opération reçue ne contient qu'un seul contact et qu'il est vide => CRÉATION)
    const isModeCreation: boolean = (
        props.operationSIRS.contactsClient.length === 1 &&
        props.operationSIRS.contactsClient[0].contactClient === "" &&
        props.operationSIRS.contactsClient[0].mail === "" &&
        props.operationSIRS.contactsClient[0].telephone === ""
    );

    // Booléen permettant de savoir si l'opération est à l'état Déclaré ou non
    const isEtatDeclare: boolean = props.operationSIRS.etatDemande === EtatDemande.DECLARE;

    // Récupération des contacts clients depuis l'opération reçue via les props
    const [contactsClientListe, setContactsClientListe] = useState<EditSIRSContactClient[]>(
        props.operationSIRS.contactsClient.map(contact => {
            return {
                ...contact,
                isUpdate: false,
                isDelete: false,
                isNew: isModeCreation   // Si CRÉATION, le contact client est considéré comme nouveau (= true)
            }

        })
    );

    const [dernierIdContactClient, setDernierIdContactClient] = useState<number>(
        isModeCreation ? 0 :
            // Récupération de l'ID max dans le State des contacts clients
            Math.max(...props.operationSIRS.contactsClient.map(contact => Number(contact.id)))
    );

    /**
     * Controlled Input (Two-ways Data Binding : value + onChange)
     * Appel de cette méthode dès la modification des valeurs des inputs de type Texte (ou TextArea) du formulaire SIRS
     * @param event
     */
    const handleOnChangeTextSIRS = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let value: string;

        if (event.target.id === "urlPartieClient") {
            // On supprime automatiquement les espaces dans le champ "Partie cliente de l'URL"
            value = (event.target.value).replace(/\s+/g, '');
        } else {
            value = event.target.value;
        }

        setOperationSIRS((prevState: OperationSIRSFormulaire) => ({
            ...prevState,
            [event.target.id]: value
        }));
    }

    /**
     * Controlled Input (Two-ways Data Binding : value + onChange)
     * Appel de cette méthode dès la modification des valeurs des inputs de type Number du formulaire SIRS
     * @param event
     */
    const handleOnChangeNumberSIRS = (event: ChangeEvent<HTMLInputElement>) => {
        setOperationSIRS((prevState: OperationSIRSFormulaire) => ({
            ...prevState,
            [event.target.id]: Number(event.target.value)
        }));
    }

    /**
     * Appel de cette méthode dès la modification des valeurs des inputs de type Date du formulaire SIRS
     * @param id        Id du champ correspondant à un champ de l'objet operation
     * @param date      Date à mettre à jour
     */
    const handleOnChangeDateSIRS = (id: string, date: Date | undefined) => {
        setOperationSIRS((prevState: OperationSIRSFormulaire) => ({...prevState, [id]: date}));
    }

    /**
     * Controlled Input (Two-ways Data Binding : value + onChange)
     * Appel de cette méthode dès la modification des valeurs des inputs de type Select du formulaire SIRS
     * @param event
     */
    const handleOnChangeSelectSIRS = (event: SelectChangeEvent<HTMLInputElement>) => {
        setOperationSIRS((prevState: OperationSIRSFormulaire) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    /**
     * Méthode permettant de rajouter un Contact Client "vide" à l'opération SIRS
     */
    const ajoutContactClient = () => {
        const newId = dernierIdContactClient + 1;
        const newContactClient: EditSIRSContactClient = {
            id: newId,
            contactClient: "",
            mail: "",
            telephone: "",
            isUpdate: false,
            isDelete: false,
            isNew: true
        }

        // Mise à jour du tableau des contacts client de l'opération SIRS
        const tableauContactsClient: EditSIRSContactClient[] = [
            ...contactsClientListe,
            newContactClient
        ]
        setContactsClientListe(tableauContactsClient)

        // Mise à jour du dernier ID contact client
        setDernierIdContactClient(newId);
    }

    /**
     * Méthode permettant de mettre à jour le contact client
     * @param contactClientID
     * @param updatedData
     * @param newValue
     */
    const modificationContactClient = (contactClientID: number, updatedData: "contactClient" | "mail" | "telephone", newValue: string) => {

        setContactsClientListe(contactsClientListe.map((contact: EditSIRSContactClient) => {
            if (contact.id === contactClientID) {
                return {
                    ...contact,
                    // Si la valeur modifiée = "telephone", on formate le string affiché dans l'input
                    [updatedData]: updatedData !== "telephone" ? newValue : newValue
                        .replace(/\D/g, "")   // On ne garde que les chiffres
                        .replace(/(\d\d(?!$))/g, "$1 "),   // On place des espaces tous les 2 caractères,
                    isUpdate: true
                };
            } else {
                return contact;
            }
        }))
    }

    /**
     * Méthode permettant de supprimer un contact client
     * @param editContactClient
     */
    const suppressionContactClient = (editContactClient: EditSIRSContactClient) => {

        // Si le Contact Client existe en BDD
        if (!editContactClient.isNew) {
            // On met à jour le contact dans le State contactsClientListe en le taguant avec "isDelete"
            setContactsClientListe(contactsClientListe.map((contact: EditSIRSContactClient) => {
                if (contact.id === editContactClient.id) {
                    return {...contact, isDelete: true};
                } else {
                    return contact;
                }
            }))
        } else {
            // On supprime directement le contact du State (il n'existe pas en BDD)
            setContactsClientListe(
                contactsClientListe.filter((contact: EditSIRSContactClient) => contact.id !== editContactClient.id)
            );
        }
    }


    return (
        <Box component={"form"}
             onSubmit={(event: FormEvent<HTMLFormElement>) => {
                 event.preventDefault();
                 props.onSubmit(operationSIRS, contactsClientListe);
             }}
        >

            <Grid container spacing={3} padding={2}>
                <Grid item xs={12}>
                    <VoxLegendChampsObligatoires/>
                </Grid>

                {/* Champ : Client */}
                <Grid item xs={12} sm={6}>
                    <VoxTextField
                        id={"nomClient"}
                        startIconLabel={"address-book"}
                        label={"Client"}
                        value={operationSIRS.nomClient}
                        onChange={handleOnChangeTextSIRS}
                        fullWidth
                        required
                        disabled={props.isLoadingOnSubmit}
                    />
                </Grid>

                {/* Champ : Date d'installation */}
                {/* Affiché seulement lorsque l'opération est à l'état DÉCLARÉ */}
                <Grid item xs={12} sm={6} sx={{padding: isEtatDeclare ? undefined : '0 !important'}}>
                    {isEtatDeclare &&
                        <>
                            <VoxCalendarInput
                                id={"dateInstallation"}
                                startIconLabel={"calendar-alt"}
                                label={"Date d'installation"}
                                value={(operationSIRS.dateInstallation === null || operationSIRS.dateInstallation === undefined) ? undefined : new Date(dayjs(operationSIRS.dateInstallation).format("YYYY-MM-DD"))}
                                onChange={handleOnChangeDateSIRS}
                                minDate={new Date()}
                                fullWidth
                                required
                                disabled={props.isLoadingOnSubmit}
                            />
                            {props.isErrorDateInstallation &&
                                <VoxAlert severity={"error"}>La date d'installation doit être renseignée.</VoxAlert>
                            }
                        </>
                    }
                </Grid>

                {/* Champ : Type de prestation */}
                <Grid item xs={12} sm={6}>
                    <VoxSelectInput
                        id={"typePrestation"}
                        idLabel={"typePrestation-label"}
                        startIconLabel={"list-ol"}
                        label={"Type de prestation"}
                        value={operationSIRS.typePrestation}
                        onChange={handleOnChangeSelectSIRS}
                        fullWidth
                        required
                        disabled={props.isLoadingOnSubmit}
                    >
                        {/* On "mappe" directement sur l'Enum des types de prestation pour les afficher */}
                        {(Object.values(TypePrestation) as Array<TypePrestation>).map(typePrestation => (
                            <MenuItem key={typePrestation} value={typePrestation}>
                                {typePrestation}
                            </MenuItem>
                        ))}
                    </VoxSelectInput>
                    {props.isErrorTypePrestation &&
                        <VoxAlert severity={"error"}>Le type de prestation doit être renseigné.</VoxAlert>
                    }
                </Grid>

                {/* Champ : Partie cliente de l'URL */}
                <Grid item xs={12} sm={6}>
                    <Box sx={{display: 'flex', flexDirection: "column"}}>
                        <VoxTextField
                            id={"urlPartieClient"}
                            startIconLabel={"globe-americas"}
                            label={"Partie cliente de l'URL"}
                            value={operationSIRS.urlPartieClient}
                            onChange={handleOnChangeTextSIRS}
                            pattern={"[a-z0-9\\-]+"}
                            title={"Seuls les caractères minuscules alpha-numériques et les tirets (-) sont autorisés"}
                            fullWidth
                            required
                            disabled={props.isLoadingOnSubmit}
                        />
                        <Typography component={"p"}
                                    variant={"body2"}
                                    sx={{color: 'cobalt.lynch', marginTop: 0.5}}
                        >
                            {process.env.REACT_APP_SURVOX_URL_HTTPS}
                            {formatDebutUrlOperationSIRS(operationSIRS.typePrestation)}
                            {(operationSIRS.urlPartieClient === "") ?
                                "[client]" : operationSIRS.urlPartieClient
                            }
                        </Typography>
                    </Box>
                    {(props.isErrorOnSubmit && props.messageError.includes('[urlClient]')) &&
                        <VoxAlert severity={"error"}>
                            {props.messageError.split("[urlClient] ")}
                        </VoxAlert>
                    }
                </Grid>

                {/* Liste des contacts clients */}
                {contactsClientListe
                    .filter((contact: EditSIRSContactClient) => !contact.isDelete)
                    .map((contact: EditSIRSContactClient, index: number) => (
                        <Grid item xs={12} sm={6} key={`edit-contact-${contact.id}`} position={"relative"}>

                            {/* Icône "Corbeille" pour supprimer le contact */}
                            {index > 0 &&
                                <Box position={"absolute"} right={0} top={20} zIndex={5}>
                                    <VoxIconAction
                                        icon={"trash-alt"}
                                        color={'cobalt.redIcon'}
                                        tooltip={"Supprimer ce contact"}
                                        onClick={() => suppressionContactClient(contact)}
                                        disabled={props.isLoadingOnSubmit}
                                    />
                                </Box>
                            }

                            {/* Champ : Prénom NOM (avec le label) */}
                            <VoxTextField
                                id={`contactClient-${contact.id}`}
                                startIconLabel={"id-card"}
                                label={contactsClientListe.length > 1 ? `Contact Client ${index + 1}` : 'Contact Client'}
                                startIcon={"user"}
                                placeholder={"Prénom NOM"}
                                value={contact.contactClient}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => modificationContactClient(
                                    contact.id, "contactClient", event.target.value)}
                                title={"Prénom et NOM du contact"}
                                fullWidth
                                required
                                disabled={props.isLoadingOnSubmit}
                            />

                            {/* Champ : Email (sans label) */}
                            <VoxTextField
                                id={`email-${contact.id}`}
                                startIcon={"envelope"}
                                placeholder={"Adresse e-mail"}
                                value={contact.mail}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => modificationContactClient(
                                    contact.id, "mail", event.target.value)}
                                pattern={PATTERN_EMAIL}
                                title={"Adresse e-mail du contact"}
                                fullWidth
                                required
                                disabled={props.isLoadingOnSubmit}
                            />

                            {/* Champ : Téléphone (sans label) */}
                            <VoxTextField
                                id={`tel-${contact.id}`}
                                startIcon={"phone-alt"}
                                placeholder={"Numéro de téléphone  (facultatif)"}
                                value={contact.telephone}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => modificationContactClient(
                                    contact.id, "telephone", event.target.value)}
                                pattern={PATTERN_TELEPHONE_FRANCAIS}
                                title={"Numéro de téléphone du contact"}
                                fullWidth
                                disabled={props.isLoadingOnSubmit}
                            />

                        </Grid>
                    ))}

                {/* Liste des contacts clients : ajout d'une Grid vide si le nombre de contacts est impair */}
                {(contactsClientListe.length % 2 !== 0) &&
                    <Grid item xs={12} sm={6} padding={{xs: '0 !important', sm: 'initial'}} aria-hidden/>}

                {/* Bouton permettant d'ajouter un contact client pour l'opération SIRS */}
                <Grid item xs={12} marginBottom={2}>
                    <VoxButton
                        variant={"outlined"}
                        size={"small"}
                        sx={{fontSize: "0.8rem"}}
                        startIcon={<FontAwesomeIcon icon={"plus"}/>}
                        onClick={ajoutContactClient}
                    >
                        Ajouter un contact client
                    </VoxButton>
                </Grid>

                {/* Champ : Nombre de licences vendues */}
                <Grid item xs={12} sm={6}>
                    <VoxTextField
                        id={"nbLicencesVendues"}
                        startIconLabel={"receipt"}
                        label={"Nombre de licences vendues"}
                        type={"number"}
                        value={operationSIRS.nbLicencesVendues === -1 ? "" : operationSIRS.nbLicencesVendues}       // -1 : valeur initiale lors de la création d'une opération (valeur impossible de valider telle quelle)
                        min={0}
                        onChange={handleOnChangeNumberSIRS}
                        fullWidth
                        required
                        disabled={props.isLoadingOnSubmit}
                    />
                </Grid>

                {/* Modification possible de l'état d'une opération SIRS :
                        - pour les utilisateurs ADMIN et SUPPORT_SI_INFRA
                        - lorsque l'opération est à un état différent de DECLARE
                */}
                {((utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA)
                        && (operationSIRS.etatDemande !== EtatDemande.DECLARE))
                    && (
                    <Grid item xs={12} sm={6}>
                        <VoxSelectInput
                            id={"etatDemande"}
                            idLabel={"etat-label"}
                            startIconLabel={"cog"}
                            label={"État"}
                            value={operationSIRS.etatDemande}
                            onChange={handleOnChangeSelectSIRS}
                            fullWidth
                            required
                            disabled={props.isLoadingOnSubmit}
                        >
                            <MenuItem key={`etat-${EtatDemande.EN_PRODUCTION}`} value={EtatDemande.EN_PRODUCTION}>
                                {affichageLibelleEtatDemande(EtatDemande.EN_PRODUCTION)}
                            </MenuItem>

                            {/* Cas de l'état A_SUPPRIMER */}
                            {!(etatDemandeInitialOperationSIRS === EtatDemande.EN_PRODUCTION
                                    || etatDemandeInitialOperationSIRS === EtatDemande.SUPPRIME
                                ) &&
                                <MenuItem key={`etatop-${etatDemandeInitialOperationSIRS}`} value={etatDemandeInitialOperationSIRS}>
                                    {affichageLibelleEtatDemande(etatDemandeInitialOperationSIRS)}
                                </MenuItem>
                            }

                            <MenuItem key={`etat-${EtatDemande.SUPPRIME}`} value={EtatDemande.SUPPRIME}>
                                {affichageLibelleEtatDemande(EtatDemande.SUPPRIME)}
                            </MenuItem>
                        </VoxSelectInput>
                    </Grid>
                    )
                }

                {/* Champ : Description */}
                <Grid item xs={12}>
                    <VoxTextArea
                        id={"description"}
                        startIconLabel={"comment-dots"}
                        label={"Description"}
                        value={operationSIRS.description}
                        onChange={handleOnChangeTextSIRS}
                        multilineMaxRows={3}
                        minRows={3}
                        disabled={props.isLoadingOnSubmit}
                    />
                </Grid>

                {/* Bouton VALIDER */}
                <VoxButton
                    id={"btn-commander-operation"}
                    customColor={"green"}
                    startIcon={<FontAwesomeIcon icon={"save"}/>}
                    sx={{fontSize: "0.95rem", py: 2.8, margin: '2rem auto 0'}}
                    type={"submit"}
                    disabled={props.isLoadingOnSubmit}
                >
                    {isModeCreation ? "Commander une opération" : "Valider"}
                </VoxButton>

            </Grid>
        </Box>
    )
}
