import { ChangeEvent, FormEvent, useState } from "react";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import "../../../views/authentification/login/loginView.css"
import { LoginUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/loginUtilisateur.dto";
import Grid from "@mui/material/Grid";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import { VoxTextField } from "../../genericComponentsUI/input/voxTextField";

type PropsType = {
    isLoading: boolean,
    isLoginError: boolean,
    errorMessage: string,
    onLogin: (loginUtilisateurDto: LoginUtilisateurDto) => void
}

export default function LoginForm(props: PropsType) {

    // State
    const [loginUtilisateurDto, setLoginUtilisateurDto] = useState<LoginUtilisateurDto>({username: '', password: ''});

    // Mise à jour du state avec la saisie de l'utilisateur
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLoginUtilisateurDto((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    // Soumission du formulaire
    const handleOnLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onLogin(loginUtilisateurDto);
        setLoginUtilisateurDto({username: '', password: ''})
    }


    return (
        <>
            {/* Erreur lors de la soumission du formulaire */}
            {
                props.isLoginError &&
                    <VoxAlert severity={"error"} sx={{mt: 3}}>
                        {props.errorMessage}
                    </VoxAlert>
            }

            {/* Formulaire */}
            <form onSubmit={handleOnLogin}>
                <Grid container sx={{mt: 2}} flexDirection="column" spacing={2}>
                    <Grid item>
                        <VoxTextField
                            id={"username"}
                            label={"Identifiant"}
                            value={loginUtilisateurDto.username}
                            onChange={handleOnChange}
                            autoFocus={true}
                            required={true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <VoxTextField
                            id={"password"}
                            label={"Mot de passe"}
                            isVisiblePasswordOption={true}
                            value={loginUtilisateurDto.password}
                            onChange={handleOnChange}
                            required={true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Link component={RouterLink} to={'/reinitialisation_mdp'}>Mot de passe oublié ?</Link>
                    </Grid>
                    <Grid item sx={{mt: 2}}>
                        <VoxButton type={"submit"} fullWidth>Connexion</VoxButton>
                    </Grid>
                </Grid>
            </form>

            {/* VoxLoader affiché */}
            {
                props.isLoading && <VoxLoader isBackgroundColor={true} />
            }
        </>
    )
}