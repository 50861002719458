import React, {ChangeEvent} from "react";
import {Grid, Typography} from "@mui/material";
import {VoxTextField} from "../genericComponentsUI/input/voxTextField";
import VoxModal from "./voxModal";

type ModaleType = {
    show: boolean,
    isInstalling: boolean,
    handleClose: () => void,
    handleSubmit: () => void,
    handleChangeNomVM: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function ModaleConfirmationFinalisationInitialisationDocaposte(props: ModaleType) {

    const content = () => {
        return (
            <Grid container spacing={3} padding={2}>
                <Grid item xs={12}>
                    <Typography variant={"body1"}>
                        L'application a-t-elle bien été déployée chez Docaposte ?
                        <br/>
                        (En cliquant sur "Confirmer", cela va finaliser l'initialisation du site et basculer l'état du site à "En production")
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <VoxTextField
                        id={'nomVM'}
                        label={"Nom de la Machine Virtuelle liée à l'opération"}
                        startIconLabel={"laptop"}
                        onChange={props.handleChangeNomVM}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Confirmation d'initialisation du site"}
            content={content()}
            closeButtonText={"Non"}
            submitButtonText={"Confirmer"}
            isValidationLoading={props.isInstalling}
            isFormulaire
        />
    )
}