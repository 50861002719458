import { useNavigate, useParams } from "react-router-dom";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import React, { useEffect, useState } from "react";
import { UpdateUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/updateUtilisateur.dto";
import UtilisateursModificationForm from "./utilisateursModificationForm";
import groupeService from "../../../services/survox-back/utilisateur/groupe.service";
import utilisateurService from "../../../services/survox-back/utilisateur/utilisateur.service";


export default function UtilisateursModification() {
    let params = useParams();
    const navigate = useNavigate();

    const [utilisateur, setUtilisateur] = useState<Required<Utilisateur>>();
    const [isLoadingUtilisateur, setIsLoadingUtilisateur] = useState(false);
    const [isLoadingUtilisateurError, setIsLoadingUtilisateurError] = useState(false);

    const [groupes, setGroupes] = useState([]);
    const [isLoadingGroupes, setIsLoadingGroupes] = useState(false);
    const [isLoadingGroupesError, setIsLoadingGroupesError] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        const controller = new AbortController();
        loadGroupes(controller);
        if (params.id) {
            loadUtilisateur(params.id, controller);
        }
        return () => controller.abort();
    }, [params.id]);

    /**
     * Chargement de l'utilisateur que l'on souhaite modifier
     * @param id l'id de l'utilisateur
     * @param controller
     */
    const loadUtilisateur = (id: string, controller?: AbortController) => {
        setIsLoadingUtilisateur(true)
        setIsLoadingUtilisateurError(false)
        utilisateurService.getUtilisateur(id, controller?.signal)
            .then(response => {
                if (response.status === 200) {
                    setIsLoadingUtilisateur(false);
                    setUtilisateur({...response.data, password: ""});
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    setIsLoadingUtilisateur(false);
                    setIsLoadingUtilisateurError(true);
                }
            });
    }

    /**
     * Chargement des groupes
     */
    const loadGroupes = (controller?: AbortController) => {
        setIsLoadingGroupes(true);
        setIsLoadingGroupesError(false);
        groupeService.getAllGroupes(controller?.signal)
            .then((response) => {
                if (response.status === 200) {
                    setIsLoadingGroupes(false);
                    setGroupes(response.data);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    setIsLoadingGroupes(false);
                    setIsLoadingGroupesError(true);
                }
            });
    }

    /**
     * Modification d'un utilisateur
     * @param updateUtilisateurDto les champs nescessaires à la modification d'un utilisateur
     */
    const onUpdate = (updateUtilisateurDto: UpdateUtilisateurDto) => {
        setIsLoading(true);
        setIsError(false);
        utilisateurService.updateUtilisateur(Number(params.id), updateUtilisateurDto)
            .then((response) => {
                if(response.status === 200) {
                    setIsLoading(false);
                    navigate(`/administration/utilisateurs`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                // Certains types d'erreur ne doivent pas arriver, car aussi contrôlé coté "front" dans le formulaire
                if (error.response.status === 400) {
                    const errorResponseMessage = error.response.data.message;
                    // Traitement de la première erreur trouvée dans les messages d'erreur
                    if (errorResponseMessage[0].includes('should not be empty')) {
                        const champErreur = (errorResponseMessage)[0].split(' ')[0]
                        if (champErreur === 'nom' || champErreur === 'prenom' || champErreur === 'login' || champErreur === 'password')
                            setErrorMessage("Le champ " + champErreur + " est obligatoire.");
                    }
                    if (errorResponseMessage[0] === 'password must be longer than or equal to 8 characters') {
                        setErrorMessage("Le mot de passe est obligatoire avec un nombre de caratères compris entre 8 et 24.");
                    }
                    if (errorResponseMessage[0].includes('mail must match')) {
                        setErrorMessage("Le champ de l'email est invalide.");
                    }
                    else {
                        setErrorMessage("Il y a des champs non valides");
                    }
                }
                else if (error.response.status === 404) {
                    setErrorMessage(`L'utilisateur avec l'id ${params.id} n'existe pas.`);
                } else if (error.response.status === 403) {
                    setErrorMessage("Il est interdit de modifier cet utilisateur.")
                } else {
                    setErrorMessage("Il y a eu une erreur lors de la modification de l'utilisateur");
                }
            })
    }

    if (!isLoadingUtilisateur && utilisateur) {
        if (utilisateur.login === 'audit' || utilisateur.login === 'systeme') {
            return (
                <div className={"alert alert-danger m-0 d-flex justify-content-center align-items-center restricted-alert"}>
                    Il est interdit de modifier un utilisateur du système.
                </div>
            )
        }
        return (
            <div className={"row justify-content-center"}>
                <div className={"col-xs-8 col-lg-10 col-xl-8"}>
                    <div className={"position-relative"}>
                        <div className="card">
                            <div className="card-body d-flex flex-column align-content-center">
                                <div className={"w-100 mb-3 utilisateur-title-style-caption"}>
                                    Modification de l'utilisateur
                                </div>
                                {
                                    isError
                                        ? (
                                            <div className="alert alert-danger" role="alert">
                                                { errorMessage }
                                            </div>
                                        ) : null
                                }
                                <UtilisateursModificationForm
                                    utilisateur={utilisateur}
                                    onUpdate={onUpdate}
                                    groupes={groupes}
                                    isLoadingGroupes={isLoadingGroupes}
                                    isLoadingGroupesError={isLoadingGroupesError}
                                />
                            </div>
                        </div>
                        {
                            isLoading ? <VoxLoader isBackgroundColor={true} /> : null
                        }
                    </div>
                </div>
            </div>
        )
    } else if (isLoadingUtilisateurError) {
        return (
            <div className={"alert alert-danger m-0 d-flex justify-content-center align-items-center restricted-alert"}>
                Une erreur est survenue lors du chargement de l'utilisateur
            </div>
        )
    } else {
        return (
            <div className={"position-relative"}>
                <VoxLoader />
            </div>
        )
    }
}