import { Box, Grid, Typography } from "@mui/material";
import { VoxLegendChampsObligatoires } from "../../genericComponentsUI/label/voxLegendChampsObligatoires";
import { VoxTextField } from "../../genericComponentsUI/input/voxTextField";
import React, { FormEvent, useState } from "react";
import { ResetMdpUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/resetMdpUtilisateur.dto";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import utilisateurService from "../../../services/survox-back/utilisateur/utilisateur.service";
import { PATTERN_EMAIL } from "../../../utils/string.utils";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import { useNavigate } from "react-router-dom";
import VoxCard from "../../genericComponentsUI/card/voxCard";


export default function ReinitialisationMdpForm() {

    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>("");
    const [isSucces, setIsSucces] = useState<boolean>(false);

    const navigate = useNavigate();

    /**
     * Méthode déclenchée à la validation du formulaire
     * @param event
     */
    const handleOnResetMdp = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.target;
        // Lecture des données du formulaire
        const formData = new FormData(form as HTMLFormElement);
        // L'attribut "name" sur les inputs sera utilisé comme clé dans les données récupérées du formulaire
        const {login, email} = Object.fromEntries(formData.entries());

        resetMdp({login: login as string, email: email as string});
    }

    const resetMdp = (resetMdpUtilisateur: ResetMdpUtilisateurDto) => {
        setIsLoadingSubmit(true);
        setIsError(false);
        setIsSucces(false);
        utilisateurService.resetMdpUtilisateur(resetMdpUtilisateur)
            .then(response => {
                if (response.status === 200) {
                    setIsLoadingSubmit(false);
                    setIsError(false);
                    setIsSucces(true);
                }
            })
            .catch(error => {
                setIsLoadingSubmit(false);
                setIsSucces(false);
                setIsError(true);
                if ((error.response.status === 404 || error.response.status === 403) && (error.response.data.message).includes("[Utilisateur]")) {
                    setMessageError(error.response.data.message.split("[Utilisateur] "));       // Soit : L'identifiant ou l'adresse email est incorrect(e).
                                                                                                // Soit : L'utilisateur retrouvé est inactif.
                } else {
                    setMessageError("Une erreur est survenue.")
                }
            });
    }


    if (!isSucces) {
        return (
            <VoxCard>
                {/* Affichage de l'erreur s'il y en a une */}
                {
                    isError && <VoxAlert severity={"error"}>{messageError}</VoxAlert>
                }

                {/* Formulaire */}
                <form onSubmit={handleOnResetMdp}>
                    <Grid container spacing={3} padding={2}>
                        <Grid item xs={12}>
                            <VoxLegendChampsObligatoires/>
                        </Grid>

                        {/* Champ : Identifiant */}
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"login"}
                                name={"login"}
                                startIconLabel={"user"}
                                label={"Identifiant"}
                                fullWidth
                                required={true}
                                disabled={isLoadingSubmit}
                            />
                        </Grid>

                        {/* Champ : Email */}
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"email"}
                                name={"email"}
                                startIconLabel={"at"}
                                label={"Email"}
                                pattern={PATTERN_EMAIL}
                                fullWidth
                                required={true}
                                disabled={isLoadingSubmit}
                            />
                            <Typography component={'span'} color={'cobalt.lynch'} variant={'body2'}>
                                Saisissez l'adresse email associée à votre compte.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} display={"flex"} justifyContent={"space-evenly"} mt={1}>
                            <VoxButton
                                customColor={"red"}
                                variant={"outlined"}
                                onClick={() => navigate("/")}
                                disabled={isLoadingSubmit}
                            >
                                Annuler
                            </VoxButton>
                            <VoxButton type={"submit"} customColor={"green"} disabled={isLoadingSubmit}>
                                Valider
                            </VoxButton>
                        </Grid>
                    </Grid>
                </form>

                {
                    isLoadingSubmit && <VoxLoader isBackgroundColor={true}/>
                }
            </VoxCard>
        )
    } else {
        return(
            // Texte de succès de réinitialisation du mot de passe
            <>
                <VoxAlert severity={"success"}>
                    La réinitialisation de votre mot de passe a été réalisée avec succès.
                    <br/>
                    Vous allez recevoir un e-mail avec votre nouveau mot de passe temporaire.
                </VoxAlert>
                <Box display={"flex"} justifyContent={"center"} mt={4}>
                    <VoxButton onClick={() => navigate('/')}>
                        Retour à la page de connexion
                    </VoxButton>
                </Box>
            </>
        )
    }

}