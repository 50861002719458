import React, { FormEvent, useEffect, useState } from "react";
import "./utilisateursModification.css";
import { UpdateUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/updateUtilisateur.dto";
import { Groupe } from "../../../models/utilisateur/groupe.model";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { affichageDateWithFormatDMYHm, generateRandomString, PATTERN_PASSWORD, PATTERN_EMAIL, TITLE_PATTERN_PASSWORD } from "../../../utils/string.utils";
import ModaleConfirmationUtilisateur from "../../../components/modals/modaleConfirmationUtilisateur";
import { LabelFormulaire } from "../../../components/genericComponentsUI/label/labelFormulaire";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PropsType = {
    utilisateur: Required<Utilisateur>;
    onUpdate: (upd: UpdateUtilisateurDto) => void;
    groupes: Groupe[], isLoadingGroupes: boolean;
    isLoadingGroupesError: boolean
}


export default function UtilisateursModificationForm(props: PropsType)  {

    const [utilisateur, setUtilisateur] = useState<Required<Utilisateur>>({
        id: props.utilisateur.id,
        nom: props.utilisateur.nom,
        prenom: props.utilisateur.prenom,
        login: props.utilisateur.login,
        mail: props.utilisateur.mail,
        password: "",
        groupe: props.utilisateur.groupe,
        estActif: props.utilisateur.estActif,
        mdpAModifier: props.utilisateur.mdpAModifier,
        derniereConnexion: props.utilisateur.derniereConnexion
    });

    const [showConfirmationModale, setShowConfirmationModale] = useState(false);

    useEffect(() => {
       setUtilisateur(props.utilisateur);
    }, [props.utilisateur]);


    /**
     * Gère l'évenement de l'envoi du formulaire et déclenche la modification de l'utilisateur
     */
    const handleOnUpdate = () => {
        setShowConfirmationModale(false);
        props.onUpdate({
            login: utilisateur.login,
            nom: utilisateur.nom,
            prenom: utilisateur.prenom,
            password: utilisateur.password,
            mail: utilisateur.mail,
            groupe: utilisateur.groupe!,
            estActif: utilisateur.estActif
        });
    }

    /**
     * Gère le changement de valeur d'un champ du formulaire et met à jour l'état
     */
    function handleOnChange(event: any) {
        setUtilisateur((prevState: Required<Utilisateur>) => ({...prevState, [event.target.id]: event.target.value }));
    }

    /**
     * Gère le change de valeur du groupe selectionné et met à jour l'état
     */
    function handleOnChangeGroupe(event: any) {
        const newGroupe = props.groupes.find((groupe: Groupe) => groupe.id === Number(event.target.value));
        if (newGroupe) {
            setUtilisateur((prevState: Required<Utilisateur>) => ({
                ...prevState,
                [event.target.id]: newGroupe
            }));
        }
    }

    /**
     * Gère le changement du nom de l'utilisateur
     */
    const handleOnChangeNom = (event: any) => {
        setUtilisateur( (prevState: Required<Utilisateur>) => ({...prevState, nom: event.target.value.toUpperCase() }));
    }

    /**
     * Gère le changement du prénom de l'utilisateur
     * @param event
     */
    const handleOnChangePrenom = (event: any) => {
        const valueInput = event.target.value;
        // capitalize tous les mots de la chaine de caractères (=1ère lettre des mots en majuscule)
        const valeurPrenom = valueInput.replace(/(?:^|\s)\S/g, function(a: string) { return a.toUpperCase(); });
        setUtilisateur( (prevState: Required<Utilisateur>) => ({...prevState, prenom: valeurPrenom }));
    }

    /**
     * Gère le changement de l'état de l'utilisateur (actif ou non)
     */
    function handleOnChangeOnOff(event: any) {
        setUtilisateur(prevState => ({...prevState, [event.target.id]: event.target.checked }));
    }


    let selectGroupes;
    if (props.isLoadingGroupes) {
        selectGroupes = (
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    } else if (props.isLoadingGroupesError) {
        selectGroupes = (
            <div className={"alert alert-danger m-0 d-flex justify-content-center align-items-center restricted-alert"}>
                Une erreur est survenue lors du chargement des groupes
            </div>
        );
    } else if (utilisateur.groupe) {
        selectGroupes = (
            <select className="form-select" id="groupe" onChange={handleOnChangeGroupe} value={utilisateur.groupe.id} required>
                {
                    props.groupes.map((groupe: Groupe) => {
                        return (
                            <option key={groupe.id} value={groupe.id}>{groupe.nom}</option>
                        )
                    })
                }
            </select>
        );
    }

    return (
        <>
        <form onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            (utilisateur.password === undefined || utilisateur.password === "")
                ? handleOnUpdate()    // validation directe (car le mot de passe n'est pas modifié)
                : setShowConfirmationModale(true)    // affichage de la modale de confirmation
        }}>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"login"}>
                            Login
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8"}>
                    <input type="text" className="form-control" id="login" value={utilisateur.login} onChange={handleOnChange} required/>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"nom"}>
                            Nom
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8"}>
                    <input type="text" className="form-control" id="nom" value={utilisateur.nom} onChange={handleOnChangeNom} required/>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"prenom"}>
                            Prénom
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8"}>
                    <input type="text" className="form-control" id="prenom" value={utilisateur.prenom} onChange={handleOnChangePrenom} required/>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}/>
                <div className={"col-8 text-justify"} style={{color:"indianred"}}>
                    <small>Si vous ne souhaitez pas modifier le mot de passe, il suffit de laisser le champ ci-dessous vide.</small>
                </div>

                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"password"}>
                            Mot de passe
                        </LabelFormulaire>
                    </div>
                </div>
                    <div className={"col-8"}>
                        <div className={"row h-100 vertical-align"}>
                            <div className={"col"}>
                                <input type={"text"} className="form-control" id="password"
                                       pattern={PATTERN_PASSWORD}
                                       title={TITLE_PATTERN_PASSWORD}
                                       placeholder={"Mot de passe non modifié"}
                                       value={utilisateur.password ? utilisateur.password : ""}
                                       onChange={handleOnChange}
                                       autoComplete={"off"}
                                />
                            </div>
                            <div className={"col col-fixe-bouton"}>
                                <VoxButton
                                    className={"btn-generation-mdp"}
                                    size={"small"}
                                    onClick={() => setUtilisateur((prevState: Required<Utilisateur>) => ({...prevState, "password": generateRandomString(10)}))}
                                    title={"Génération d'un nouveau mot de passe"}
                                >
                                    <FontAwesomeIcon icon={"cog"}/>
                                </VoxButton>
                            </div>
                        </div>
                    </div>
                <div className={"col-4"}/>
                <div className={"col-8"}>
                    <div className={"text-justify"}>
                        <small id="passwordHelp" className="form-text">{TITLE_PATTERN_PASSWORD}</small>
                    </div>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"mail"}>
                            Email
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8"}>
                    <input type="text" className="form-control" id="mail" pattern={PATTERN_EMAIL} value={utilisateur.mail} onChange={handleOnChange} required/>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"groupe"}>
                            Groupe
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8 d-flex justify-content-center"}>
                    { selectGroupes }
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"estActif"}>
                            Statut
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8"}>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="estActif" onChange={handleOnChangeOnOff} checked={utilisateur.estActif}/>
                        <label className="form-check-label" htmlFor="estActif">&nbsp;
                            {(utilisateur.estActif) ? "utilisateur actif" : "utilisateur inactif"}
                        </label>
                    </div>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"row h-100 vertical-align"}>
                        <LabelFormulaire htmlFor={"derniereConnexion"}>
                            Date de dernière connexion
                        </LabelFormulaire>
                    </div>
                </div>
                <div className={"col-8"}>
                    <label>{affichageDateWithFormatDMYHm(utilisateur.derniereConnexion)}</label>
                </div>
            </div>
            <div className={"row mx-0"}>
                <div className={"card bg-light mt-2 pt-1 pb-3"} style={{borderColor: "red"}}>
                    <p className={"utilisateur-title-style-caption pt-1 pb-1"} style={{color: "red"}}>Informations :</p>
                    <small className={"text-justify"}>- Si un mot de passe est renseigné, celui-ci est temporaire. Il doit être noté afin de le transmettre à l'utilisateur.</small>
                    <small className={"text-justify"}>- L'utilisateur devra changer son mot de passe à sa prochaine connexion.</small>
                </div>
            </div>
            <div className="col-12 d-flex justify-content-center mb-2 mt-4">
                <VoxButton
                    customColor={"green"}
                    type={"submit"}
                >
                    Valider
                </VoxButton>
            </div>
        </form>

        <ModaleConfirmationUtilisateur
            show={showConfirmationModale}
            handleClose={() => {
                setShowConfirmationModale(false);
            }}
            handleSubmit={handleOnUpdate}
            nom={utilisateur.nom}
            prenom={utilisateur.prenom}
            password={utilisateur.password}
        />
    </>
    )
}