import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Groupe } from "../../../models/utilisateur/groupe.model";
import { useNavigate } from "react-router-dom";
import { CellProps, Column} from "react-table";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import groupeService from "../../../services/survox-back/utilisateur/groupe.service";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import VoxIconAction from "../../../components/genericComponentsUI/pictogrammes/voxIconAction";

type GroupeTableau = Pick<Groupe, "id" | "nom"> & { nbUtilisateurs: number };


export default function GroupesListe() {
    const navigate = useNavigate();

    const [groupes, setGroupes] = useState<Groupe[]>([]);
    const [isLoadingGroupes, setIsLoadingGroupes] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        chargerGroupes(controller);
        return () => controller.abort();
    }, []);

    /**
     * Charger les groupes
     */
    const chargerGroupes = (controller: AbortController) => {
        setIsLoadingGroupes(true);
        groupeService.getAllGroupes(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    setGroupes(response.data);
                    setIsLoadingGroupes(false);
                }
            })
            .catch(() => {
                setIsLoadingGroupes(false);
            });
    }

    /**
     * Remplissage des lignes du tableau
     */
    const rows: GroupeTableau[] = useMemo(() => {
        return groupes.map(
            (groupe: Groupe) => {
                return {
                    id: groupe.id,
                    nom: groupe.nom,
                    nbUtilisateurs: groupe.utilisateurs.length
                }
            }
        );
    }, [groupes]);

    /**
     * Redirige vers la page détail du groupe sélectionné
     */
    const handleGroupeDetail = useCallback( function (id: number) {
        navigate(`detail/${id}`);
    }, [navigate]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<GroupeTableau>[] = useMemo( () => [
            {
                Header: 'Nom',
                accessor: 'nom',
                flexGrow: 1,
                minWidth: 130,
            },
            {
                Header: "Nombre d'utilisateurs",
                accessor: 'nbUtilisateurs',
                flexGrow: 1,
                minWidth: 130,
                disableFilters: true
            },
            {
                Header: 'Actions',
                id: 'actions',
                align: "center",
                flexGrow: 0.5,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props: CellProps<GroupeTableau>) => {
                    return (
                         // Oeil → Détail du groupe
                        <VoxIconAction
                            tooltip={"Consulter le détail du groupe"}
                            icon={"eye"}
                            onClick={() => handleGroupeDetail(props.row.original.id)}
                            sx={{py: 0}}
                        />
                    )
                },
                minWidth: 30,
                maxWidth: 50,
            },
        ], [handleGroupeDetail]
    )


    if (isLoadingGroupes) {
        return (
           <VoxLoader />
        )
    } else {
        return (
            <VoxTable
                data={rows}
                columns={columns}
                cellProps={(cell: CellProps<GroupeTableau>) => {
                    if (cell.column.id === "nom") {
                        return {
                            style: {textAlign: "left"}
                        }
                    }
                }}
            />
        )
    }
}