import { EtatDemande } from "../../models/operation/etatDemande.enum";
import React from "react";
import VoxModal from "./voxModal";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { Box, Typography } from "@mui/material";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { Operation } from "../../models/operation/operation.model";

type ModalePropsType = {
    show: boolean
    operation: Operation | undefined
    isAllowedDeleteOperation: boolean
    handleClose: () => void
    handleSubmit: () => void
    loadingDeleteOperation: boolean
    loadingAllowedDeleteOperation: boolean
}


export default function ModaleConfirmationSuppressionOuAnnulation(props: ModalePropsType) {

    let modalBody;
    if (props.loadingAllowedDeleteOperation) {
        modalBody = (
            <Box component={"div"} position={"relative"}>
                <VoxLoader size={24}/>
            </Box>
        );
    } else if (!props.isAllowedDeleteOperation) {
        modalBody = (
            <VoxAlert severity={"warning"}>
                Suppression de l'opération {props.operation && ` ${props.operation.nomClient} `} impossible.
            </VoxAlert>
        )
    } else {
        modalBody = (
            <Typography variant={"body1"}>
                Souhaitez-vous vraiment
                { props.operation && props.operation.etatDemande === EtatDemande.DECLARE ? " annuler " : " supprimer " }
                l'opération {props.operation && props.operation.nomClient } ?
            </Typography>
        )
    }


    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            authorized={props.isAllowedDeleteOperation && !props.loadingAllowedDeleteOperation}
            title={
                `${ props.operation && props.operation.etatDemande === EtatDemande.DECLARE ? "Annulation " : "Suppression " }
                d'une opération`
            }
            content={modalBody}
            closeButtonText={"Non"}
            submitButtonText={"Confirmer"}
            isValidationLoading={props.loadingDeleteOperation}
        />
    )
}