import {Operation} from "../../../models/operation/operation.model";
import {ResultatSurveillance} from "../../../models/surveillance/resultatSurveillance.enum";
import React, {useMemo} from "react";
import {
    getResultatSurveillanceDemandeElecteur,
    getResultatSurveillanceScellement,
    Surveillance
} from "../../../models/surveillance/surveillance.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CellProps, Column, IdType, Row} from "react-table";
import {affichageDateWithFormatDMYHm} from "../../../utils/string.utils";
import {sortDatesInTables} from "../../../utils/date.utils";
import {TypeSurveillance} from "../../../models/surveillance/typeSurveillance.enum";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import {Box} from "@mui/material";

type PropsType = {
    operation: Operation;
}

type SurveillanceOperationTableau = {
    id: number;
    checkTime: Date;
    typeSurveillance: string;
    resultatSurveillance: ResultatSurveillance | string;
    codeHttp: number;
    tempsDeReponse: number;
    erreur: string;
}

export default function OperationsDetailSurveillance(props: PropsType) {

    /**
     * Remplissage des lignes du tableau
     */
    const rows: SurveillanceOperationTableau[] = useMemo(() => {
        if (props.operation.traces) {
            return props.operation.traces.map(
                (surveillance: Surveillance) => ({
                    id: surveillance.id,
                    checkTime: surveillance.checkTime,
                    typeSurveillance: surveillance.typeSurveillance,
                    resultatSurveillance: surveillance.resultatSurveillance,
                    codeHttp: surveillance.codeHttp,
                    tempsDeReponse: surveillance.tempsDeReponse,
                    erreur: surveillance.erreur,
                })
            )
        } else {
            return [];
        }
    }, [props.operation]);

    const pictoLock = (surveillance_resultatSurveillance: ResultatSurveillance | string) => {
        return <FontAwesomeIcon icon={"lock"}
                                size={"lg"}
                                color={(getResultatSurveillanceScellement(surveillance_resultatSurveillance) === ResultatSurveillance.KO
                                    || getResultatSurveillanceScellement(surveillance_resultatSurveillance) === ResultatSurveillance.SCELLEMENT_INACTIF)
                                    ? "#CDCCCC"  // couleur gris (inactif)
                                    : ((getResultatSurveillanceScellement(surveillance_resultatSurveillance) === ResultatSurveillance.SCELLEMENT_KO)
                                            ? "#F10000"  // couleur rouge
                                            : ((getResultatSurveillanceScellement(surveillance_resultatSurveillance) === ResultatSurveillance.ERREUR_CERTIFICAT)
                                                    ? "#FFC300" // couleur jaune
                                                    : "green"  // couleur verte
                                            )
                                    )
                                }
                                title={`Scellement - ${surveillance_resultatSurveillance}`}
        />
    }

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<SurveillanceOperationTableau>[] = useMemo(() => [
        {
            Header: 'Date de vérification',
            id: 'checkTime',
            accessor: (originalRow: SurveillanceOperationTableau) => affichageDateWithFormatDMYHm(originalRow.checkTime),
            minWidth: 160,
            sortType: (a: Row<SurveillanceOperationTableau>,
                       b: Row<SurveillanceOperationTableau>,
                       columnId: IdType<SurveillanceOperationTableau>,
                       desc: boolean | undefined) => sortDatesInTables(a.original.checkTime, b.original.checkTime, desc)
        },
        {
            Header: 'Type',
            accessor: 'typeSurveillance',
            minWidth: 190,
        },
        {
            Header: 'Résultat',
            accessor: 'resultatSurveillance',
            minWidth: 150,
            disableFilters: true,
            Cell: (props: CellProps<SurveillanceOperationTableau>) => {
                const surveillance: SurveillanceOperationTableau = props.row.original;

                if (surveillance.typeSurveillance === TypeSurveillance.SUPERVISION) {
                    if (surveillance.resultatSurveillance !== null && getResultatSurveillanceDemandeElecteur(surveillance.resultatSurveillance) !== null) {
                        return (
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1}>
                                {pictoLock(surveillance.resultatSurveillance)}
                                <FontAwesomeIcon icon={"envelope"}
                                                 size={"2x"}
                                                 color={(getResultatSurveillanceDemandeElecteur(surveillance.resultatSurveillance) === ResultatSurveillance.KO)
                                                     ? "#CDCCCC"  // couleur gris (inactif)
                                                     : ((getResultatSurveillanceDemandeElecteur(surveillance.resultatSurveillance) === ResultatSurveillance.DEMANDE_ELECTEUR_KO)
                                                             ? "#F10000"  // couleur rouge
                                                             : ((getResultatSurveillanceDemandeElecteur(surveillance.resultatSurveillance) === ResultatSurveillance.ERREUR_CERTIFICAT)
                                                                     ? "#FFC300" // couleur jaune
                                                                     : "green"  // couleur verte
                                                             )
                                                     )
                                                 }
                                                 title={`Demande électeur - ${surveillance.resultatSurveillance}`}
                                />
                            </Box>
                        )
                    }
                    if (surveillance.resultatSurveillance !== null) {
                        return pictoLock(surveillance.resultatSurveillance);
                    }
                }

                if (surveillance.typeSurveillance === TypeSurveillance.CONTROLE_AVANT_OUVERTURE && surveillance.resultatSurveillance !== null) {
                    return <FontAwesomeIcon icon={"check-square"}
                                            size={"2x"}
                                            color={(surveillance.resultatSurveillance === ResultatSurveillance.KO)
                                                ? "#F10000"  // couleur rouge
                                                : ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                                        ? "#FFC300" // couleur jaune
                                                        : "green"  // couleur verte
                                                )

                                            }
                                            title={`Contrôle avant ouverture - ${surveillance.resultatSurveillance}`}
                    />
                }

                if (surveillance.typeSurveillance === TypeSurveillance.TEST_SITE && surveillance.resultatSurveillance !== null) {
                    return <FontAwesomeIcon icon={(surveillance.resultatSurveillance === ResultatSurveillance.KO)
                        ? "bolt"
                        : ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                ? "bolt"
                                : "sun"
                        )
                    }
                                            size={"2x"}
                                            color={(surveillance.resultatSurveillance === ResultatSurveillance.KO)
                                                ? undefined
                                                : ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                                        ? "#FFC300" // couleur jaune
                                                        : undefined
                                                )
                                            }
                                            title={`Test site - ${surveillance.resultatSurveillance}`}
                    />
                }
                return null;
            }
        },
        {
            Header: 'Code HTTP',
            accessor: 'codeHttp',
            minWidth: 100,
        },
        {
            Header: 'Temps de réponse (en ms)',
            accessor: 'tempsDeReponse',
            minWidth: 170,
        },
        {
            Header: "Message d'erreur",
            accessor: 'erreur',
            minWidth: 250,
            Cell: (props: CellProps<SurveillanceOperationTableau>) => {
                if (props.value) {
                    const values: string[] = props.value.split('\n');
                    return <>{
                        values.map(value => <p>{value}</p>)
                    }</>
                } else {
                    return <></>
                }
            }
        },
    ], []);


    return (
        <VoxTable
            data={rows}
            columns={columns}
            initialStateSortBy={[{id: 'checkTime', desc: true}]}
            cellProps={(cell: CellProps<SurveillanceOperationTableau>) => {
                if (cell.column.id === "erreur") {
                    return {
                        style: {textAlign: "left"}
                    }
                }
            }}
        />
    )
}