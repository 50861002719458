import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import Navbar from "../../components/layouts/navbar/navbar";
import React from "react";
import { Container, Grid } from "@mui/material";
import ReinitialisationMdpForm from "../../components/forms/authentication/reinitialisationMdpForm";

export default function UtilisateurReinitialisationMdpView() {

    return (
        <>
            <Navbar/>
            <Container sx={{padding: '3rem 0 !important', maxWidth: '90%'}}>
                <VoxHeader title={"Réinitialisation de votre mot de passe"}/>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={10} sm={6} md={5}>
                       <ReinitialisationMdpForm/>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}