import {SIRSOperation} from "../../../models/operationSIRS/sirsOperation.model";
import {useMemo} from "react";
import {Column} from "react-table";
import {Grid} from "@mui/material";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";

type PropsType = { operationSIRS: SIRSOperation }
type parametresTableauType = { code: string, libelle: string, valeur: string | number | null }

export default function OperationsSIRSDetailParametres(props: PropsType) {

    const parametres = props.operationSIRS.sirsParametres;

    /**
     * Remplissage des lignes du tableau
     */
    const rows: parametresTableauType[] = useMemo(() => {

        return parametres.map(paramSIRS => (
            {
                code: paramSIRS.code,
                libelle: paramSIRS.libelle,
                valeur: paramSIRS.valeur
            })
        )

    }, [parametres])


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<parametresTableauType>> = useMemo(() => [
        {
            Header: 'Code',
            accessor: 'code',
            minWidth: 250,
            disableSortBy: true
        },
        {
            Header: 'Libellé',
            accessor: 'libelle',
            minWidth: 250,
            disableSortBy: true
        },
        {
            Header: 'Valeur',
            accessor: 'valeur',
            minWidth: 350,
            disableSortBy: true
        },
    ], [])


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8}>
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialPageSize={20}
                    cellProps={() => {
                        return {
                            style: {textAlign: "left"}
                        }
                    }}
                />
            </Grid>
        </Grid>
    )

}