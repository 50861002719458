import {Box} from "@mui/material";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import {VoxLegendChampsObligatoires} from "../../../components/genericComponentsUI/label/voxLegendChampsObligatoires";
import {VoxTextField} from "../../../components/genericComponentsUI/input/voxTextField";
import {EmailPortefeuilleProjet} from "../../../models/portefeuilleProjet/EmailPortefeuilleProjet.model";
import {PATTERN_EMAIL} from "../../../utils/string.utils";
import React, {ChangeEvent, useState} from "react";
import VoxIconAction from "../../../components/genericComponentsUI/pictogrammes/voxIconAction";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

type PortefeuilleProjetFormPropsType = {
    nomPortefeuille: string,
    emailsPortefeuille: string,
    onSubmit: (nomPortefeuilleProjet: string, tableauEmails: EmailPortefeuilleProjet[]) => void,
    isLoadingOnSubmit: boolean,
    isError: boolean,
    messageError: string
}

export default function PortefeuillesProjetsForm(props: PortefeuilleProjetFormPropsType) {

    // Navigation
    const navigate = useNavigate();

    // States
    const [nomPortefeuilleProjet, setNomPortefeuilleProjet] = useState<string>(props.nomPortefeuille);
    const [tableauEmails, setTableauEmails] = useState<EmailPortefeuilleProjet[]>(
        !(props.emailsPortefeuille === "" || props.emailsPortefeuille === null)
            ?
            props.emailsPortefeuille?.split(";").map((email: string, index: number) => {
                return {id: index, email: email}
            })
            : [{id: 0, email: ""}]
        );
    const [dernierIdEmail, setDernierIdEmail] = useState<number>(
        !(props.emailsPortefeuille === "" || props.emailsPortefeuille === null)
            ? (props.emailsPortefeuille?.split(";").length - 1) : 0

    );

    /**
     * Mise à jour du champ "Nom du responsable" lorsque l'on renseigne l'input du formulaire
     * @param event
     */
    const handleOnChangeNom = (event: ChangeEvent<HTMLInputElement>) => {
        setNomPortefeuilleProjet(event.target.value);
    }

    /**
     * Méthode permettant de rajouter une adresse e-mail "vide"
     */
    const ajoutEmail = () => {

        const newId = dernierIdEmail + 1;

        // Ajout d'un email "vide" dans le tableau des adresses email
        const newEmail: EmailPortefeuilleProjet = {id: newId, email: ""};
        tableauEmails.push(newEmail);

        // Mise à jour du dernier ID pour l'email
        setDernierIdEmail(newId);
    }

    /**
     * Méthode permettant de supprimer une adresse e-mail
     * @param idEmail ID correspondant à l'email
     */
    const suppressionEmail = (idEmail: number) => {
        // Suppression de l'email avec l'ID = idEmail
        const newTableauEmail = tableauEmails.filter((objEmail: EmailPortefeuilleProjet) => objEmail.id !== idEmail);
        setTableauEmails(newTableauEmail);
    }


    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            props.onSubmit(nomPortefeuilleProjet, tableauEmails);
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 2
            }}>

                {/* Si une erreur survient */}
                {props.isError &&
                    <VoxAlert severity={"error"} sx={{marginTop: 0}}>
                        {props.messageError}
                    </VoxAlert>
                }

                <VoxLegendChampsObligatoires/>

                {/* Champ : Nom du responsable */}
                <VoxTextField
                    id={"nom"}
                    label={"Nom du responsable"}
                    onChange={handleOnChangeNom}
                    value={nomPortefeuilleProjet}
                    fullWidth
                    required
                    disabled={props.isLoadingOnSubmit}
                />

                {/* Champ(s) : Adresse(s) e-mail */}
                {tableauEmails.map((adresseEmail: EmailPortefeuilleProjet, index: number) => (
                    <Box
                        key={`adresse-email-${adresseEmail.id}`}
                        sx={{display: 'flex', alignItems: 'center'}}
                    >
                        <VoxTextField
                            id={`email-${adresseEmail.id}`}
                            label={
                                tableauEmails.length > 1 ?
                                    `Adresse e-mail ${index + 1} :` : `Adresse e-mail :`
                            }
                            pattern={PATTERN_EMAIL}
                            value={tableauEmails[index].email}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                // Copie du tableau des adresses e-mail
                                const editAdressesEmail = ([] as EmailPortefeuilleProjet[]).concat(tableauEmails);
                                // Modification d'une valeur dans le tableau des adresses email
                                editAdressesEmail[index].email = event.target.value;
                                // Mise à jour du tableau des adresses email
                                setTableauEmails(editAdressesEmail);
                            }}
                            fullWidth
                            disabled={props.isLoadingOnSubmit}
                        />

                        {index > 0 &&
                            <VoxIconAction
                                icon={"trash-alt"}
                                color={"cobalt.redIcon"}
                                tooltip={"Supprimer cette adresse e-mail"}
                                onClick={() => suppressionEmail(adresseEmail.id)}
                                sx={{marginTop: 4, marginLeft: 1}}
                            />
                        }
                    </Box>
                ))}

                {/* Bouton permettant d'ajouter une adresse e-mail */}
                <VoxButton
                    variant={"outlined"}
                    size={"small"}
                    startIcon={<FontAwesomeIcon icon={"plus"}/>}
                    sx={{alignSelf: 'flex-start', marginY: 1}}
                    onClick={ajoutEmail}
                    disabled={props.isLoadingOnSubmit}
                >
                    Ajouter une adresse email
                </VoxButton>

                {/* Boutons Annulation/Validation du formulaire */}
                <Box display={"flex"} justifyContent={"center"} gap={5} marginTop={4}>
                    <VoxButton
                        customColor={"red"}
                        variant={"outlined"}
                        className={"size-button"}
                        onClick={() => navigate("/administration/portefeuilles_projets")}
                        disabled={props.isLoadingOnSubmit}
                    >
                        Annuler
                    </VoxButton>
                    <VoxButton
                        customColor={"green"}
                        className={"size-button"}
                        type={"submit"}
                        disabled={props.isLoadingOnSubmit}
                    >
                        Valider
                    </VoxButton>
                </Box>

            </Box>
        </form>
    )
}