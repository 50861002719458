import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import OperationsModificationSurveillanceQRadar from "./operationsModificationSurveillanceQRadar";
import OperationsModificationAvancee from "./operationModificationAvancee";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import {Grid} from "@mui/material";


export default function OperationsModificationView() {

    return (
        <Routes>
            <Route path={"/surveillance-QRadar/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={9} md={6}>
                            <OperationsModificationSurveillanceQRadar/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={9} md={6}>
                            <OperationsModificationAvancee/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}