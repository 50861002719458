import React from "react";
import {Box, CircularProgress} from "@mui/material";

type LoaderPropsType = {
    positionRelative?: boolean
    isBackgroundColor?: boolean
    size?: number
}

export default function VoxLoader(props: LoaderPropsType) {

    return (

        <Box
            sx={{
                position: props.positionRelative ? 'relative' : 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: props.isBackgroundColor ? 'rgba(250, 250, 250, .8)' : 'none'
            }}
        >
            <CircularProgress size={props.size}/>
        </Box>

    )
}