import {Navigate, Route, Routes} from "react-router-dom";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import React from "react";
import OperationsSIRSListe from "../../components/operationsSIRSListe/operationsSIRSListe";
import OperationsSIRSModification from "../../components/forms/operationsSIRSFormulaires/operationsSIRSModification";

export default function OperationsSIRSView() {

    return (
        <Routes>

            {/* Route principale */}
            <Route path={""} element={
                <>
                    <VoxHeader title={"Mes opérations SIRS"}/>
                    <OperationsSIRSListe/>
                </>
            }/>

            {/* Formulaire de modification SIRS */}
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/sirs/mes_operations"}/>
                    <OperationsSIRSModification/>
                </>
            }/>

            {/* Retour vers la route principale */}
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>

        </Routes>
    )

}