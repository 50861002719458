import { ColumnInstance, Row } from "react-table";
import { OperationProductionTableau } from "../models/operation/operationProduction/operationProductionTableau.model";
import { OperationAllTableau } from "../models/operation/operationAll/operationAllTableau.model";
import { OperationSIRSProductionTableau } from "../models/operationSIRS/operationSIRSProduction/operationSIRSProductionTableau.model";
import {EnvironnementAllTableau} from "../models/environnements/environnementAllTableau.model";


export function csvDataSerializationDefault<Type extends object>(columns: ColumnInstance<Type>[], row: Row<Type>) {
    return Object.entries(row.values)
        .filter(([, value]) => value !== undefined)
        .reduce((obj, [key, value]) => {
            return { ...obj, [key]: value };
        }, {});
}

/**
 * Sérialise les données d'une ligne du tableau des opérations en production en fonction des colonnes passées
 * en paramètres
 * @param columns une liste de colonne du tableau
 * @param row l'objet représentant une ligne du tableau
 */
export function csvDataSerializationOperationProduction(columns: ColumnInstance<OperationProductionTableau>[], row: Row<OperationProductionTableau>) {
    let serializedRow = {};
    columns.forEach((column: ColumnInstance<OperationProductionTableau>) => {
        switch (column.id) {
            case "isOperationFavoriteUtilisateur":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.isOperationFavoriteUtilisateur) ? 'Oui' : 'Non'
                };
                break;
            case "numeroOperation":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.numeroOperation
                };
                break;
            case "operation":
                serializedRow = {
                    ...serializedRow,
                    Nom: row.values.operation.nom,
                    Url: row.values.operation.urlSite
                };
                break;
            case "versionApplicative":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.versionApplicative
                };
                break;
            case "proprietaire":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.proprietaire
                };
                break;
            case "portefeuilleProjet":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.portefeuilleProjet
                };
                break;
            case "typeOperation":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.typeOperation
                };
                break;
            case "tourEnCours":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.tourEnCours
                };
                break;
            case "dernierRedemarrageTomcat":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.dernierRedemarrageTomcat
                };
                break;
            case "nomVM":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nomVM
                };
                break;
            case "nbJoursVM":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbJoursVM
                };
                break;
            case "dateScellement":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.dateScellement
                };
                break;
            case "ouvertureT1":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.ouvertureT1
                };
                break;
            case "fermetureT1":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.fermetureT1
                };
                break;
            case "ouvertureT2":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.ouvertureT2
                };
                break;
            case "fermetureT2":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.fermetureT2
                };
                break;
            case "hebergeur":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.hebergeur
                };
                break;
            case "isSurveillanceQRadar":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.isSurveillanceQRadar) ? 'Oui' : 'Non'
                };
                break;
            case "plateforme":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.plateforme
                };
                break;
            case "nbElections":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbElections
                };
                break;
            case "nbGestionnaires":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbGestionnaires
                };
                break;
            case "nbElecteurs":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbElecteurs
                };
                break;
            case "participationGlobale":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.participationGlobale
                };
                break;
            case "etatDepouillement":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.etatDepouillement !== null) ? row.values.etatDepouillement : undefined
                };
                break;
            case "statut":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.statut) ? row.values.statut.resultatSurveillance : undefined
                };
                break;
            case "acquittementSite":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.acquittementSite === true) ? 'Oui' : ''
                };
                break;
            case "scellements":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.scellements) ? row.values.scellements.resultatSurveillance : undefined
                };
                break;
            case "basculeT2":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.basculeT2) ? 'Oui' : 'Non'
                };
                break;
            case "clesGenereesPourTousLesBVs":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.clesGenereesPourTousLesBVs) ? 'Oui' : 'Non'
                };
                break;
            case "isServiceCertinomisOK":
                let value;
                if (row.values.isServiceCertinomisOK === undefined) value = "";
                else if (row.values.isServiceCertinomisOK) value = "Oui";
                else value = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: value
                };
                break;
            case "statutPVDemat":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.statutPVDemat
                };
                break;
            case "region":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.region
                };
        }
    });
    return serializedRow;
}

/**
 * Sérialise les données d'une ligne du tableau des opérations en production sirs en fonction des colonnes passées
 * en paramètres
 * @param columns une liste de colonne du tableau
 * @param row l'objet représentant une ligne du tableau
 */
export function csvDataSerializationOperationProductionSIRS(columns: ColumnInstance<OperationSIRSProductionTableau>[], row: Row<OperationSIRSProductionTableau>) {
    let serializedRow = {};
    columns.forEach((column: ColumnInstance<OperationSIRSProductionTableau>) => {
        switch (column.id) {
            case "nomClient":
                serializedRow = {
                    ...serializedRow,
                    'Nom client': row.values.nomClient.nomClient,
                    Url: row.values.nomClient.url
                };
                break;
            case "versionApplicative":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.versionApplicative
                };
                break;
            case "contactClient":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.contactClient.map((contact: string) => contact).join(" / ")
                };
                break;
            case "mailClient":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.mailClient.map((mail: string) => mail).join(" / ")
                };
                break;
            case "telClient":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.telClient.map((tel: string) => tel).join(" / ")
                };
                break;
            case "typePrestation":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.typePrestation
                }
                break;
            case "moduleBDESE":
                let moduleBDESEValue = "";
                if (row.values.moduleBDESE === true) moduleBDESEValue = "Oui";
                else if (row.values.moduleBDESE === false) moduleBDESEValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleBDESEValue
                };
                break;
            case "moduleSHD":
                let moduleSHDValue = "";
                if (row.values.moduleSHD === true) moduleSHDValue = "Oui";
                else if (row.values.moduleSHD === false) moduleSHDValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleSHDValue
                };
                break;
            case "moduleElectionsRepresentativite":
                let moduleElectionsRepresentativiteValue = "";
                if (row.values.moduleElectionsRepresentativite === true) moduleElectionsRepresentativiteValue = "Oui";
                else if (row.values.moduleElectionsRepresentativite === false) moduleElectionsRepresentativiteValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleElectionsRepresentativiteValue
                };
                break;
            case "moduleReclamations":
                let moduleReclamationsValue = "";
                if (row.values.moduleReclamations === true) moduleReclamationsValue = "Oui";
                else if (row.values.moduleReclamations === false) moduleReclamationsValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleReclamationsValue
                };
                break;
            case "moduleAccords":
                let moduleAccordsValue = "";
                if (row.values.moduleAccords === true) moduleAccordsValue = "Oui";
                else if (row.values.moduleAccords === false) moduleAccordsValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleAccordsValue
                };
                break;
            case "moduleComiteDeGroupe":
                let moduleComiteDeGroupeValue = "";
                if (row.values.moduleComiteDeGroupe === true) moduleComiteDeGroupeValue = "Oui";
                else if (row.values.moduleComiteDeGroupe === false) moduleComiteDeGroupeValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleComiteDeGroupeValue
                };
                break;
            case "moduleReporting":
                let moduleReportingValue = "";
                if (row.values.moduleReporting === true) moduleReportingValue = "Oui";
                else if (row.values.moduleReporting === false) moduleReportingValue = "Non";
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: moduleReportingValue
                };
                break;
            case "nbLicencesActivees":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbLicencesActivees
                };
                break;
            case "nbLicencesVendues":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbLicencesVendues
                };
                break;
            case "etatSite":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.etatSite
                };
                break;
            case "acquittementSite":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: (row.values.acquittementSite === true) ? 'Oui' : ''
                };
                break;
        }
    });
    return serializedRow;
}



/**
 * Sérialise les données d'une ligne du tableau de toutes les opérations en fonction des colonnes passées en paramètres
 * @param columns une liste de colonne du tableau
 * @param row l'objet représentant une ligne du tableau
 */
export function csvDataSerializationAllOperations(columns: ColumnInstance<OperationAllTableau>[], row: Row<OperationAllTableau>) {
    let serializedRow = {};
    columns.forEach((column: ColumnInstance<OperationAllTableau>) => {
        switch (column.id) {
            case "numeroOperation":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.numeroOperation
                };
                break;
            case "etatDemande":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.etatDemande
                };
                break;
            case "operation":
                serializedRow = {
                    ...serializedRow,
                    Nom: row.values.operation.nom,
                    Url: row.values.operation.urlSite
                };
                break;
            case "nomVersionApplicative":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nomVersionApplicative
                };
                break;
            case "proprietaire":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.proprietaire
                };
                break;
            case "nomPortefeuilleProjet":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nomPortefeuilleProjet
                };
                break;
            case "typeOperation":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.typeOperation
                };
                break;
            case "hebergeur":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.hebergeur
                };
                break;
            case "nomPlateforme":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nomPlateforme
                };
                break;
            case "nbElecteurs":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.nbElecteurs
                };
                break;
            case "participationGlobale":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.participationGlobale
                };
                break;
        }
    });
    return serializedRow;
}

/**
 * Sérialise les données pour les environnements hors production
 */
export function csvDataSerialisationAllEnvironnements(columns: ColumnInstance<EnvironnementAllTableau>[], row: Row<EnvironnementAllTableau>) {
    let serializedRow = {};

    columns.forEach((column: ColumnInstance<EnvironnementAllTableau>) => {
        switch(column.id) {
            case "operationName":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.operationName
                };
                break;
            case "typeEnvironnement":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.typeEnvironnement
                };
                break;
            case "url":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.url
                };
                break;
            case "etatDemande":
                serializedRow = {
                    ...serializedRow,
                    [String(column.Header)]: row.values.etatDemande
                };
                break;
        }
    });

    return serializedRow;
}