import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import React, { ChangeEvent } from "react";
import { Checkbox, FormControlLabel} from "@mui/material";

type PropsType = {
    isFavori: boolean;
    iconSize?: SizeProp;
    operationId: number;
    onChange: (value: boolean, opId: number) => void;
    libelle: boolean;
}

export default function VoxPictoFavori(props: PropsType) {

    const labelInput = () => {
        if (props.libelle) {
            if (props.isFavori) {
                return "Ne plus suivre";
            } else {
                return "Suivre";
            }
        } else {
            return null;
        }
    }

    return (
        <FormControlLabel
            id={"star-input-" + props.operationId}
            sx={{
                ml: "15px",
                userSelect: "none",
                transition: "0.3s",
                stroke: "#585656",
                strokeWidth: 10,
                display: "flex",
                justifyContent: "center",
                alignContent: "center"
            }}
            control={
                <Checkbox
                    name={"checkbox-favoris"}
                    sx={{
                        border: 'none',
                        "&.MuiCheckbox-root": {
                            background: "none !important"
                        },
                        "&:hover": {
                            background: 'none !important'
                        }

                    }}
                    icon={
                        <FontAwesomeIcon
                            icon={"star"}
                            size={props.iconSize ? props.iconSize : "sm"}
                            color={'#E3E3E3'}
                        />
                    }
                    checkedIcon={
                        <FontAwesomeIcon
                            icon={"star"}
                            size={props.iconSize ? props.iconSize : "sm"}
                            color={"#FFCB05"}
                        />
                    }
                    onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.checked, props.operationId)}
                    checked={props.isFavori}
                />
            }
            label={labelInput()}
            title={(props.isFavori) ? "Retirer des favoris" : "Ajouter aux favoris"}
        />
    )
}