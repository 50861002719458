import React, {useMemo} from "react";
import {Operation} from "../../../models/operation/operation.model";
import {Column} from "react-table";
import {affichageDateWithFormatDMYHm} from "../../../utils/string.utils";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import {Grid} from "@mui/material";

type PropsType = { operation: Operation };
type indicateursTableauType = { indicateur: string, valeur: string | number | null }


export default function OperationsDetailIndicateurs(props: PropsType) {

    /**
     * Remplissage des lignes du tableau
     */
    const rows: indicateursTableauType[] = useMemo(() => {
        return (
            [
                {
                    indicateur: "Nombre d'électeurs",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbElecteurs : null,
                },
                {
                    indicateur: "Nombre d'établissements",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbEtablissements : null,
                },
                {
                    indicateur: "Nombre d'élections",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbElections : null,
                },
                {
                    indicateur: "Nombre de listes de candidats",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbListesCandidats : null,
                },
                {
                    indicateur: "Nombre de candidats",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbCandidats : null,
                },
                {
                    indicateur: "Nombre d'inscriptions",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbInscriptions : null,
                },
                {
                    indicateur: "Nombre de suffrages",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbSuffrages : null,
                },
                {
                    indicateur: "Nombre de gestionnaires",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbGestionnaires : null,
                },
                {
                    indicateur: "Empreinte de code",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.empreinteCode : null,
                },
                {
                    indicateur: "Empreinte de schéma",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.empreinteSchema : null,
                },
                {
                    indicateur: "Date de pose des scellements",
                    valeur: (props.operation && props.operation.indicateursOperation) ? affichageDateWithFormatDMYHm(props.operation.indicateursOperation.datePoseScellements) : null,
                },
                {
                    indicateur: "Nombre d'élections non dépouillées",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.nbElectionsNonDepouillees : null,
                },
                {
                    indicateur: "Reste-t-il des sièges à pourvoir ?",
                    valeur: (props.operation && props.operation.indicateursOperation && String(props.operation.indicateursOperation.isSiegesRestantsAPourvoir) !== 'null')
                        ? ((props.operation.indicateursOperation.isSiegesRestantsAPourvoir) ? "Oui" : "Non")
                        : null,
                },
                {
                    indicateur: "Les clés ont-elle été générées pour tous les BVs ?",
                    valeur: (props.operation && props.operation.indicateursOperation && String(props.operation.indicateursOperation.isClesGenereesPourTousLesBVs) !== 'null')
                        ? ((props.operation.indicateursOperation.isClesGenereesPourTousLesBVs) ? "Oui" : "Non")
                        : null,
                },
                {
                    indicateur: "Horodatage Certinomis",
                    valeur: (props.operation && props.operation.indicateursOperation && String(props.operation.indicateursOperation.isServiceCertinomisOK) !== 'null')
                        ? ((props.operation.indicateursOperation.isServiceCertinomisOK) ? "Oui" : "Non")
                        : null,
                },
                {
                    indicateur: "Télétransmission PV Démat",
                    valeur: (props.operation && props.operation.indicateursOperation) ? props.operation.indicateursOperation.statutPVDemat : null,
                }
            ]
        )
    }, [props.operation]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<indicateursTableauType>> = useMemo(() => [
        {
            Header: 'Indicateur',
            accessor: 'indicateur',
            minWidth: 200,
            disableSortBy: true,
        },
        {
            Header: 'Valeur',
            accessor: 'valeur',
            minWidth: 200,
            disableSortBy: true,
        },
    ], [])


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={6}>
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialPageSize={20}
                    cellProps={() => {
                        return {
                            style: {textAlign: "left"}
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}