import './dataBar.css'
import 'chart.js/auto';
import {Bar} from "react-chartjs-2";
import {Link} from "react-router-dom";

type DataBarType = {
    label: string,
    labelWidth: number, // Largeur du label (en pixels)
    value: number,
    total: number,
    link: string,
}

export default function DataBar(props: DataBarType) {

    // Remplissage du canvas + Config (couleurs, bordures...)
    const data = {
        labels: [props.label],
        datasets: [{
            data: [props.value],
            barThickness: 25,
            backgroundColor: '#3a74a6', // Défaut : #3a74a6
        }]
    };

    // Options de rendu de la barre horizontale
    const options = {
        indexAxis: 'y' as const, // Passer le graphique à l'horizontal
        responsive: true,
        maintainAspectRatio: false,
        events: [], // Désactive la tooltip au hover
        scales: {
            x: {
                grid: {display: false}, // Supprime la grille verticale
                border: {display: false}, // Supprime la bordure du bas
                ticks: {display: false}, // Supprime la règle en bas
                max: props.total // Valeur totale de l'échelle
            },
            y: {
                grid: {display: false}, // Supprime la grille horizontale
                border: {display: false}, // Supprime la bordure de gauche
                ticks: {display: false} // Supprime les labels à gauche
            }
        },
        plugins: {
            legend: {display: false} // Supprime la légende en haut
        }
    };

    return (
        <Link to={props.link} className={'chartjs-bar-link px-2'}>

            {/* Container flex pour aligner Label + Barre horizontale */}
            <div className={'d-flex align-items-center'}>

                {/* Label affiché à gauche du canvas, dont la largeur est personnalisable en pixels */}
                <div style={{width: `${props.labelWidth}px`}} className={'chartjs-bar-label'}>{props.label}</div>

                {/* Div contenant le canvas*/}
                <div className={'chartjs-bar-container'}>
                    <Bar data={data} options={options} height={'50'} style={{marginBottom: '-10px'}}/>
                </div>

            </div>

            {/* Compteur à l'intérieur de la barre horizontale */}
            <span>{props.value}/{props.total}</span>
        </Link>
    );
}