import { CellProps } from "react-table";
import React from "react";

type PropsType<Type extends object> = {
    cellProps: CellProps<Type>;
    separator?: string;
}

export default function ConcatenedStringColumnTypeCell<Type extends object>({ cellProps, separator }: PropsType<Type>) {
    if (!separator) separator = "|";
    if (cellProps.value !== undefined && cellProps.value !== null && typeof cellProps.value === "string") {
        if (cellProps.value.includes(separator)) {
            return <> { cellProps.value.split(separator).map((str: string, i: number) => <p key={`str-${i}`}>{ str }</p>) } </>;
        }
        return <p>{ cellProps.value }</p>
    }
    return null;
}