import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import { CellProps, Column } from "react-table";
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";
import VoxTable from "../genericComponentsUI/table/voxTable";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import { Box, Typography } from "@mui/material";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";
import VoxModal from "../modals/voxModal";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import OperationSIRSService from "../../services/survox-back/operationSIRS/operationSIRS.service";
import { TypePrestation } from "../../models/operationSIRS/typePrestation.enum";

// Type des opérations SIRS à supprimer récupérées depuis survox-back
type OperationsSIRSASupprimer = {
    id: number;
    nomClient: string;
    url: string;
    etatDemande: EtatDemande;
    typePrestation: TypePrestation;
    utilisateur: string;
}

// Type des objets utilisés dans le tableau correspondant aux informations des opérations SIRS à supprimer
type OperationsSIRSASupprimerTableau = {
    id: number;
    nomClient: string;
    url: string;
    typePrestation: TypePrestation;
    utilisateur: string;
}

export default function OperationsSIRSASupprimerListe() {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    const [operationsSIRSASupprimer, setOperationsSIRSASupprimer] = useState<OperationsSIRSASupprimer[]>([]);
    const [operationSIRSSelected, setOperationSIRSSelected] = useState<OperationsSIRSASupprimer | undefined >(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");
    const [isSuccesSuppression, setIsSuccesSuppression] = useState(false);

    const [showConfirmationSuppressionModale, setShowConfirmationSuppressionModale] = useState(false);


    /**
     * Charger les opérations SIRS à l'état A_supprimer
     */
    const chargerOperationsSIRSASupprimer = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        setIsError(false);
        setMessageError("");
        const messageError = "Une erreur est survenue lors de la récupération des opérations SIRS à supprimer.";
        OperationSIRSService.getOperationsSIRSASupprimer(controller?.signal)
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    setOperationsSIRSASupprimer(response.data);
                } else {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError(messageError);
                }
            })
            .catch((error) => {
                if (error.message !== 'canceled') {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError(messageError);
                }
            })
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        chargerOperationsSIRSASupprimer(controller);
        return () => controller.abort();
    }, [chargerOperationsSIRSASupprimer])


    const handleSuppressionOperationSIRS = useCallback((operationId: number) => {
        const operation = operationsSIRSASupprimer.find(op => Number(op.id) === operationId);
        if (operation) {
            setOperationSIRSSelected(operation);
            setShowConfirmationSuppressionModale(true);
        }
    }, [operationsSIRSASupprimer]);

    /**
     * Passage à l'état Supprimé
     */
    const deleteOperationSIRS = useCallback(() => {
        setIsLoading(true);
        setIsError(false);
        setMessageError("");
        setIsSuccesSuppression(false);
        if (operationSIRSSelected) {
            const messageErreur = `Une erreur est survenue lors de la suppression de l'opération SIRS "${operationSIRSSelected.nomClient}".`;
            OperationSIRSService.deleteOperationSIRS(operationSIRSSelected.id)
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        setIsError(false);
                        setIsSuccesSuppression(true);
                        chargerOperationsSIRSASupprimer();
                    } else {
                        setIsLoading(false);
                        setIsError(true);
                        setMessageError(messageErreur);
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError(messageErreur);
                })
                .finally(() => {
                    setShowConfirmationSuppressionModale(false);
                })
        }
    }, [operationSIRSSelected, chargerOperationsSIRSASupprimer]);

    /**
     * Lignes du tableau
     */
    const rows: OperationsSIRSASupprimerTableau[] = useMemo(() => {
        return operationsSIRSASupprimer
            .map((op) => ({
                id: op.id,
                nomClient: op.nomClient,
                url: op.url,
                utilisateur: op.utilisateur,
                typePrestation: op.typePrestation
        }))
    }, [operationsSIRSASupprimer]);

    /**
     * Liens vers les opérations
     */
    const cellOperation = useCallback((props: CellProps<OperationsSIRSASupprimerTableau>) => {
        return (
            <>
                <VoxLinkOperation href={`/sirs/detail/${props.row.original.id}`}>
                    {props.value.nomClient}
                </VoxLinkOperation>
                <br />
                <VoxLinkUrl href={props.value.url} isGreyColor={true}>
                    {props.value.url}
                </VoxLinkUrl>
            </>
        )
    }, []);

    /**
     * Actions possibles
     */
    const cellActions  = useCallback((props: CellProps<OperationsSIRSASupprimerTableau>) => {
        return (
            <Box display={"flex"} justifyContent={"center"} gap={1}>
                {/* Poubelle → Suppression de l'opération SIRS */}
                {(utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) &&
                    <VoxIconAction
                        tooltip={"Supprimer l'opération"}
                        icon={"trash-alt"}
                        onClick={() => handleSuppressionOperationSIRS(Number(props.row.original.id))}
                    />
                }
            </Box>
        )
    }, [utilisateur.groupe.nom, handleSuppressionOperationSIRS]);

    /**
     * Colonnes du tableau
     */
    const columns: Column<OperationsSIRSASupprimerTableau>[] = useMemo(() => [
        {
            Header: 'Opération',
            accessor: function (originalRow) {
                return {
                    nomClient: originalRow.nomClient,
                    url: originalRow.url
                }
            },
            Cell: cellOperation,
            minWidth: 300
        },
        {
            Header: 'Chef(fe) de projet',
            accessor: 'utilisateur',
            minWidth: 120
        },
        {
            Header: 'Type de prestation',
            accessor: 'typePrestation',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 120
        },
        {
            Header: 'Actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: cellActions,
            minWidth: 50
        }
    ], [cellOperation, cellActions]);

    return (
        isLoading ? <VoxLoader positionRelative/> :
            <>
                {isError &&
                    <VoxAlert severity={"error"}>{messageError}</VoxAlert>
                }
                {isSuccesSuppression &&
                    <VoxAlert severity={"success"} onClose={() => setIsSuccesSuppression(false)}>
                        L'opération SIRS a été supprimée.
                    </VoxAlert>
                }
                <VoxTable
                    data={rows}
                    columns={(utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) ?
                        columns
                        : columns.filter((col) => col.Header !== 'Actions')
                    }
                />

                <VoxModal
                    open={showConfirmationSuppressionModale}
                    onSubmit={deleteOperationSIRS}
                    onClose={() => setShowConfirmationSuppressionModale(false)}
                    title={"Suppression d'une opération SIRS"}
                    closeButtonText={"Non"}
                    submitButtonText={"Confirmer"}
                    content={
                        <Typography variant={"body1"}>
                            Souhaitez-vous vraiment supprimer l'opération SIRS {operationSIRSSelected && `"${operationSIRSSelected.nomClient}"`} ?
                        </Typography>
                    }
                />
            </>
    )
}