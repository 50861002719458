import React, { useCallback, useMemo } from "react";
import {
    Column, ColumnInstance,
    Filters,
    FilterValue,
    IdType,
    Row,
    SortingRule,
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import ExportCsvTable from "./exportCsvTable";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from "@mui/material/Paper";
import {Grid, Stack, SxProps} from "@mui/material";
import GlobalFilter from "./filters/GlobalFilter";
import { TextFieldFilter } from "./filters/TextFieldFilter";
import { csvDataSerializationDefault } from "../../../utils/csv.utils";
import ColumnVisibilityFilter from "./columns/ColumnVisibilityFilter";
import './tableReact.css';
import './voxTable.css'

/**
 * Définition du type des props du composant ReactTable
 */
type TableReactProps<Type extends object> = {
    id?: string;
    data: Type[]; // Données initiales du tableau
    columns: Column<Type>[]; // Configuration des colonnes du tableau
    initialStateFilters?: Filters<Type>;
    initialStateSortBy?: SortingRule<Type>[];
    isCsvExport?: boolean;
    csvDataSerialization?: (columns: ColumnInstance<Type>[], row: Row<Type>) => object;
    csvFileName?: string;
    rowProps?: Function;
    cellProps?: Function;
    initialPageSize?: number;
    hiddenColumnsProps?: any;
    handleColumnVisibilityChange?: (visibleColumnsId: string[]) => void;
    pageIndexProps?: number;
    autoResetSortBy?: boolean;
    sx?: SxProps
}

export default function VoxTable<Type extends object>(
    {
        data,
        columns,
        initialStateFilters,
        initialStateSortBy,
        rowProps,
        cellProps,
        initialPageSize,
        hiddenColumnsProps,
        handleColumnVisibilityChange,
        pageIndexProps,
        autoResetSortBy,
        sx,
        ...props
    }: TableReactProps<Type>
) {

    // Filtre ou non la ligne en fonction d'une valeur de colonne
    const globalFilterTypes = useCallback(
        (
            rows: Row<Type>[],
            columnIds: IdType<Type>[],
            filterValue: FilterValue
        ) : Row<Type>[] => {
        // Filtre sur les lignes
        return rows.filter(row => {
            // Map sur les colonnes de la ligne filtrée & on regarde si une cellule de la ligne (colonne) correspond à la recherche
            const isOneTrue : boolean[] = columnIds.map(colId => {
                let rowValue = row.values[colId];
                if (typeof rowValue === "object" && rowValue) {
                    let values = "";
                    Object.values(rowValue).forEach(value => {
                        values += String(value);
                    })
                    rowValue = values;
                }
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(
                            String(filterValue)
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        )
                    : false;
            });
            // on retourne la ligne si au moins une valeur dans celle-ci (une des colonne) correspond à la recherche
            return isOneTrue.includes(true)
        });
    }, []);

    const filterTypes = useMemo(
        () => ({
            text: globalFilterTypes
        }),
        [globalFilterTypes]
    );

    const defaultColumn = useMemo(
        () => ({
            // Configurons du filtre par défaut sur les colonnes
            Filter: TextFieldFilter,
            filter: 'text'
        }),
        []
    )

    //  Utilisation de l'état et des fonctions renvoyées par useTable pour créer l'interface utilisateur (UI)
    const {
        // Propriétés générales pour le tableau (header, body, row, ...)
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        // Propriétés pour les filtres sur le tableau
        state,
        visibleColumns,
        setGlobalFilter,

        // Propriétés pour la pagination du tableau
        page,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },

        // Propriétés pour gérer l'ensemble des colonnes (pour en afficher que certaines par exemple)
        allColumns,

    } = useTable<Type>(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: {
                pageIndex: pageIndexProps ? pageIndexProps : 0,
                pageSize: initialPageSize ? initialPageSize : 10,
                filters: initialStateFilters ? initialStateFilters : [],
                sortBy: initialStateSortBy ? initialStateSortBy : [],
                hiddenColumns: hiddenColumnsProps ? hiddenColumnsProps: []
            },
            globalFilter: globalFilterTypes,
            autoResetSortBy: autoResetSortBy !== undefined ? autoResetSortBy : true
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    return (
        <Grid container paddingY={1} sx={sx}>
            {
                (props.isCsvExport) && (
                    <Grid item xs={12} sm={4} marginBottom={1}>
                        <Stack
                            direction={"row"}
                            justifyContent={"start"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <ExportCsvTable
                                data={rows}
                                visibleColumns={visibleColumns}
                                csvFileName={props.csvFileName}
                                csvDataSerialization={props.csvDataSerialization || csvDataSerializationDefault}
                            />
                        </Stack>
                    </Grid>
                )
            }
            <Grid item xs={12} sm={props.isCsvExport ? 8 : 12} marginBottom={1}>
                <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    alignItems={"center"}
                    spacing={2}
                >
                    <GlobalFilter
                        id={"global-filter"}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                    <ColumnVisibilityFilter
                        id={"dropdown-affichage-colonnes"}
                        allColumns={allColumns}
                        visibleColumns={visibleColumns}
                        handleColumnVisibilityChange={handleColumnVisibilityChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Paper variant={"outlined"} id={props.id}>
                    <TableContainer>
                        <Table className={"custom-table"} {...getTableProps()}>
                            <TableHead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <TableCell key={`col-${column.id}`} id={`col-${column.id}`} align={"center"}>
                                                        <div {...column.getHeaderProps(column.getSortByToggleProps())} title={"Tri croissant/décroissant"}>
                                                            {
                                                                column.disableSortBy
                                                                    ? column.render('Header')
                                                                    : (
                                                                        <TableSortLabel
                                                                            active={column.isSorted}
                                                                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                                        >
                                                                            { column.render('Header') }
                                                                        </TableSortLabel>
                                                                    )
                                                            }
                                                        </div>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                }
                                {
                                    headerGroups.map(headerGroup => (
                                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <TableCell align={"center"} {...column.getHeaderProps({
                                                        style: {
                                                            minWidth: column.minWidth,
                                                            width: column.width,
                                                            maxWidth: column.maxWidth,
                                                        },
                                                    })}>
                                                        { column.canFilter ? column.render('Filter') : null }
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {
                                    page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <TableRow
                                                {...row.getRowProps({...rowProps?.(row) })}
                                                hover={true}
                                            >
                                                {
                                                    row.cells.map(cell => (
                                                        <TableCell
                                                            align={"center"}
                                                            {...cell.getCellProps({...cellProps?.(cell) })}
                                                            sx={{p: '10px'}}
                                                        >
                                                            { cell.render('Cell') }
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        count={rows.length}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        onPageChange={(e: unknown, newPage: number) => gotoPage(newPage)}
                        onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => setPageSize(Number(e.target.value))}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}