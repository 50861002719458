import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ModaleUrlsSpecifiques from "../../../components/modals/modaleUrlsSpecifiques";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import urlSpecifiqueService from "../../../services/survox-back/operation/urlSpecifique.service";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import {Operation} from "../../../models/operation/operation.model";
import {UrlSpecifique} from "../../../models/operation/urlSpecifique.model";
import {EditUrlsSpecifiquesDto} from "../../../services/survox-back/operation/dtos/request/editUrlsSpecifiques.dto";
import {EtatDemande} from "../../../models/operation/etatDemande.enum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import {Link, List, ListItem} from "@mui/material";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";

export default function OperationsDetailUrlsSpecifiques(props: { operation: Operation}) {

    // On déstructure l'objet props de manière à avoir une gestion plus fine des dépendances pour les hooks
    const {operation} = props;

    const authContext = useContext(AuthContext);

    const [urlsSpecifiques, setUrlsSpecifiques] = useState<UrlSpecifique[]>([]);
    const [isLoadingUrlsSpecifiques, setIsLoadingUrlsSpecifiques] = useState<boolean>(false);
    const [isErrorLoadingUrlsSpecifiques, setIsErrorLoadingUrlsSpecifiques] = useState<boolean>(false);
    const [isErrorEditUrlsSpecifiques, setIsErrorEditUrlsSpecifiques] = useState<boolean>(false);
    const [errorMessageUrlsSpecifiques, setErrorMessageUrlsSpecifiques] = useState<string>("");
    const [showModalUrlsSpecifiques, setShowModalUrlsSpecifiques] = useState<boolean>(false);

    /**
     * Callback permettant de récupérer les urls spécifiques liées à l'opération
     * → fait un appel à survox-back en donnant en paramètre l'ID de l'opération courante
     */
    const chargerUrlsSpecifiques = useCallback((controller?: AbortController) => {
        setIsLoadingUrlsSpecifiques(true);
        setIsErrorLoadingUrlsSpecifiques(false);
        urlSpecifiqueService.getUrlsSpecifiquesByOperation(operation.id, controller?.signal)
            .then(response => {
                setIsLoadingUrlsSpecifiques(false);
                if (response.status === 200) {
                    setUrlsSpecifiques(response.data);
                }
            })
            .catch((err) => {
                if (err.message !== "canceled") {
                    setIsLoadingUrlsSpecifiques(false);
                    setIsErrorLoadingUrlsSpecifiques(true);
                }
            });
    }, [operation.id]);

    /**
     * Use effect qui s'exécute lorsque le composant est monté dans le DOM et lance
     * le chargement des urls spécifiques selon le groupe de l'utilisateur courant
     */
    useEffect(() => {
        const controller = new AbortController();
        if (
            authContext.utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
            || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
            || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
        ) {
            chargerUrlsSpecifiques(controller);
        }
        return () => controller.abort();
    }, [authContext.utilisateur.groupe.nom, chargerUrlsSpecifiques])

    /**
     * Callback permettant de gérer l'envoi du formulaire d'édition des Urls Spécifique via un appel HTTP à survox-back
     */
    const editUrlsSpecifiques = useCallback((editUrlsSpecifiquesDto: EditUrlsSpecifiquesDto) => {
        setIsLoadingUrlsSpecifiques(true);
        setIsErrorLoadingUrlsSpecifiques(false);
        setErrorMessageUrlsSpecifiques("");
        urlSpecifiqueService.editUrlsSpecifiques(props.operation.id, editUrlsSpecifiquesDto)
            .then(() => setIsLoadingUrlsSpecifiques(false))
            .catch((error) => {
                setIsLoadingUrlsSpecifiques(false);
                setIsErrorEditUrlsSpecifiques(true);
                if (error.response.status === 400) {
                    setErrorMessageUrlsSpecifiques(error.response.data.message[0]);
                } else {
                    setErrorMessageUrlsSpecifiques("Il y a eu une erreur lors de la modification des Urls spécifiques");
                }
            })
            .finally(() => {
                chargerUrlsSpecifiques();
            })
    }, [props.operation.id, chargerUrlsSpecifiques]);

    /**
     * Callback permettant de gérer l'affichage de la modale d'édition des urls spécifiques
     * → accessible que par les utilisateurs des groupes : Administrateur et Support SI Infra
     */
    const handleOnShowModaleUrlsSpecifiques = useCallback(() => {
        if (
            authContext.utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
            || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
        ) {
            setShowModalUrlsSpecifiques(!showModalUrlsSpecifiques)
        }
    }, [authContext.utilisateur.groupe.nom, showModalUrlsSpecifiques]);

    const onCloseModaleUrlsSpecifiques = useCallback(() => {
        setShowModalUrlsSpecifiques(false);
        if (isErrorEditUrlsSpecifiques) {
            setIsErrorEditUrlsSpecifiques(false);
            setErrorMessageUrlsSpecifiques("");
        }
    }, [isErrorEditUrlsSpecifiques]);

    const urlsSpecifiqueCardContent = useMemo(() => {
        if (isLoadingUrlsSpecifiques) {
            return <VoxLoader/>;
        } else if (isErrorLoadingUrlsSpecifiques) {
            return (
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des URLs spécifiques...
                </VoxAlert>
            );
        } else {
            return (
                <>
                    {(urlsSpecifiques.length !== 0) && (
                        <List>
                            {urlsSpecifiques.map((urlSpecifique) => (
                                <ListItem key={urlSpecifique.id}>
                                    <Link href={urlSpecifique.url} target={"_blank"} rel={"noreferrer"}>
                                        {urlSpecifique.url}
                                    </Link>
                                </ListItem>))}
                        </List>
                    )
                    }
                </>
            )
        }
    }, [
        isErrorLoadingUrlsSpecifiques,
        isLoadingUrlsSpecifiques,
        urlsSpecifiques
    ]);

    if (
        (
            authContext.utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
            || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
            || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
        )
        && !(props.operation.etatDemande === EtatDemande.SUPPRIME && urlsSpecifiques.length === 0)
    ) {
        return (

            <>
                <VoxCard
                    id={"urls-specifiques"}
                    iconBeforeTitle={"link"}
                    title={"URLs spécifiques"}
                    action={
                        ((authContext.utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                                authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) &&
                            (props.operation.etatDemande !== EtatDemande.SUPPRIME)) &&
                        (
                            <VoxButton
                                variant={"text"}
                                size={"small"}
                                endIcon={<FontAwesomeIcon icon={"edit"}/>}
                                onClick={handleOnShowModaleUrlsSpecifiques}
                                sx={{marginRight: 1.5}}
                            >
                                Modifier
                            </VoxButton>
                        )
                    }
                >
                    {urlsSpecifiqueCardContent}
                </VoxCard>

                {/* Pop-up de modification des URLs spécifiques */}
                <ModaleUrlsSpecifiques
                    show={showModalUrlsSpecifiques}
                    onClose={onCloseModaleUrlsSpecifiques}
                    isLoading={isLoadingUrlsSpecifiques}
                    isErrorEdittingUrlSpecifique={isErrorEditUrlsSpecifiques}
                    errorMessageEdittingUrlSpecifique={errorMessageUrlsSpecifiques}
                    editUrlsSpecifiques={editUrlsSpecifiques}
                    urlsSpecifiquesProps={urlsSpecifiques}
                />
            </>
        )
    } else {
        return null
    }
}