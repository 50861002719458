import { FilterProps } from "react-table";
import TextField from "@mui/material/TextField";
import React from "react";

export function TextFieldFilter<Type extends object>({ column: { filterValue, setFilter }}: FilterProps<Type>) {
    return (
        <TextField
            name={"text-input-filter"}
            placeholder={'Rechercher'}
            size={'small'}
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)}
        />
    )
}
