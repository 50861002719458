import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import ViewRestricted from "../layouts/viewRestricted/viewRestricted";
import React, { ReactNode } from "react";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import { Grid } from "@mui/material";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { OperationVerificationRestrictedRoute } from "../../models/operation/operationForm/operationVerificationRestrictedRoute.model";

type propsType = {
    children: ReactNode;
    groupes: NomGroupe[];
    etatsDemande: EtatDemande[];
    typesOperation: TypeOperation[];
    operation: OperationVerificationRestrictedRoute | undefined;
    isLoadingOperation: boolean;
    isLoadingOperationError: boolean;
}

/**
 * Fonction Composant permettant de restreindre les routes pour accéder à des url comprenant l'id d'une opération
 * - Les utilisateurs qui ont accès à la route sont les utilisateurs appartenant aux groupes cités ainsi que l'utilisateur ayant créé l'opération
 * @param children
 * @param groupes , liste des groupes ayant l'accès
 * @param etatsDemande
 * @param typesOperation
 * @param operation
 * @param isLoadingOperation
 * @param isLoadingOperationError
 * @constructor
 */
export default function RestrictedRouteOperation({ children, groupes, etatsDemande, typesOperation, operation, isLoadingOperation, isLoadingOperationError }: propsType) {

    if (operation && !etatsDemande.includes(operation.etatDemande)) {
        // Gestion du cas où l'opération n'est pas à l'état de demande spécifié(s)
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={8}>
                    <VoxAlert severity={"warning"}>
                        Il est seulement possible de modifier les opérations lorsqu'elles sont à l'état "Déclaré".
                    </VoxAlert>
                </Grid>
            </Grid>
        );
    } else if (operation && !typesOperation.includes(operation.type)) {
        // Gestion du cas ou l'opération n'est pas du type spécifié(s)
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={8}>
                    <VoxAlert severity={"warning"}>
                        Il est seulement possible de modifier les opérations de type : { typesOperation.join(', ')}
                    </VoxAlert>
                </Grid>
            </Grid>
        );
    } else if (isLoadingOperation) {
        // Gestion du cas ou le chargement de l'opération est en cours
        return (
            <VoxLoader />
        )
    } else if (isLoadingOperationError) {
        // Gestion du cas ou il y a une erreur de chargement de l'opération
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={8}>
                    <VoxAlert severity={"error"}>
                        Une erreur est survenue lors du chargement de l'opération.
                    </VoxAlert>
                </Grid>
            </Grid>
        );
    } else if (operation) {
        return (
            <AuthContext.Consumer>
                {value => {
                    const utilisateurIsAuthorized = value.utilisateur && (groupes?.includes(value.utilisateur.groupe?.nom) || (operation.utilisateur.login === value.utilisateur.login))
                    if (!utilisateurIsAuthorized) {
                        return <ViewRestricted/>
                    }
                    return children
                }}
            </AuthContext.Consumer>
        );
    } else {
        return null;
    }
}