export enum ResultatSurveillance {
    OK = "OK",
    KO = "KO",
    ERREUR_CERTIFICAT = "ERREUR_CERTIFICAT",
    SCELLEMENT_OK = "SCELLEMENT_OK",
    SCELLEMENT_KO = "SCELLEMENT_KO",
    SCELLEMENT_INACTIF = "SCELLEMENT_INACTIF",
    DEMANDE_ELECTEUR_OK = "DEMANDE_ELECTEUR_OK",
    DEMANDE_ELECTEUR_KO = "DEMANDE_ELECTEUR_KO"
}


/**
 * Méthode permettant de récupérer un 'Résultat de surveillance' en fonction de sa valeur
 * @param valeurResultatSurveillance
 */
export function getResultatSurveillanceByValue(valeurResultatSurveillance: string) : ResultatSurveillance | null {
    let resultatSurveillance: ResultatSurveillance | null;
    switch (valeurResultatSurveillance) {
        case ResultatSurveillance.OK:
            resultatSurveillance = ResultatSurveillance.OK;
            break;
        case ResultatSurveillance.KO:
            resultatSurveillance = ResultatSurveillance.KO;
            break;
        case ResultatSurveillance.ERREUR_CERTIFICAT:
            resultatSurveillance = ResultatSurveillance.ERREUR_CERTIFICAT;
            break;
        case ResultatSurveillance.SCELLEMENT_OK:
            resultatSurveillance = ResultatSurveillance.SCELLEMENT_OK;
            break;
        case ResultatSurveillance.SCELLEMENT_KO:
            resultatSurveillance = ResultatSurveillance.SCELLEMENT_KO;
            break;
        case ResultatSurveillance.SCELLEMENT_INACTIF:
            resultatSurveillance = ResultatSurveillance.SCELLEMENT_INACTIF;
            break;
        case ResultatSurveillance.DEMANDE_ELECTEUR_OK:
            resultatSurveillance = ResultatSurveillance.DEMANDE_ELECTEUR_OK;
            break;
        case ResultatSurveillance.DEMANDE_ELECTEUR_KO:
            resultatSurveillance = ResultatSurveillance.DEMANDE_ELECTEUR_KO;
            break;
        default:
            resultatSurveillance = null;
    }
    return resultatSurveillance;
}