import React from "react";
import TypesDePlateformeListe from "./typesDePlateformeListe";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import { Grid } from "@mui/material";


export default function TypesDePlateformeView () {
    return (
        <>
            <VoxHeader title={"Types de plateforme"}/>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={10} md={8}>
                    <TypesDePlateformeListe/>
                </Grid>
            </Grid>
        </>
    );
}