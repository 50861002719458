import { EnvironnementOperation } from "../../../models/environnements/environnementOperation.model";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import EnvironnementsService from "../../../services/survox-back/environnements/environnements.service";
import { CellProps, Column } from "react-table";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { affichageLibelleEtatDemande, affichageLibelleTypeEnvironnement } from "../../../utils/string.utils";
import { SelectInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { EtatDemande } from "../../../models/operation/etatDemande.enum";
import { Box, Grid } from "@mui/material";
import VoxIconAction from "../../../components/genericComponentsUI/pictogrammes/voxIconAction";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import EnvironnementOperationForm
    from "../../../components/forms/environnementsOperationsFormulaire/environnementOperationForm";
import { GroupesEnvironnement } from "../../../models/utilisateur/nomGroupe.enum";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";
import ModaleConfirmationSuppressionEnvironnement
    from "../../../components/modals/modaleConfirmationSuppressionEnvironnement";


export default function OperationsDetailEnvironnements(props: { operationId: number }) {

    //Récupérer l'utilisateur authentifié
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    const utilisateurAutorise = GroupesEnvironnement.includes(utilisateur.groupe.nom);

    const [environnements, setEnvironnements] = useState<EnvironnementOperation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>("");
    const [showModale, setShowModale] = useState<boolean>(false);
    const [modEnvironnement, setModEnvironnement] = useState<EnvironnementOperation>();
    const [showSuppressionModale, setShowSuppressionModale] = useState<boolean>(false);

    //On charge les environnements liés à l'opération
    const loadEnvironnements = useCallback((controller?: AbortController) => {
        setIsLoading(true)
        setIsError(false)
        EnvironnementsService.getSomeEnvironnementsByOperationId(props.operationId, controller?.signal)
            .then(reponse => {
                if(reponse.status === 200) {
                    setEnvironnements(reponse.data)
                    setIsLoading(false)
                    setIsError(false)
                }
            })
            .catch((error) => {
                if(error.message !== 'canceled') {
                    setIsError(true)
                    setIsLoading(false)
                    setMessageError("Impossible de récupérer les environnements annexes")
                }
                })
    }, [props.operationId])

    useEffect(() => {
        const controller = new AbortController();
        loadEnvironnements(controller);
        return () => controller.abort();
    }, [loadEnvironnements])

    /**
     * Gestion Modale Modification
     */
    const handleOnOpenModale = () => {
        setShowModale(true);
    }

    const handleOnCloseModale = () => {
        setModEnvironnement(undefined);
        setShowModale(false);
    }

    /**
     * Gestion Modale Suppression
     */
    const handleOnCloseSuppModale = () => {
        setModEnvironnement(undefined);
        setShowSuppressionModale(false);
    }

    /**
     * Changement vers l'état 'A supprimer' ou 'Supprime' selon le cas
     */
    const askForDeleteEnvironnement = () => {
        setIsLoading(true);
        setIsError(false);
        if(modEnvironnement) {
            EnvironnementsService.updateEtatEnvironnementOperation({
                    id: Number(modEnvironnement.id),
                    etatDemande: modEnvironnement.etatDemande === EtatDemande.DECLARE ? EtatDemande.SUPPRIME : EtatDemande.A_SUPPRIMER
                })
                .then(() => {
                    setIsLoading(false);
                    setIsError(false);
                    loadEnvironnements();
                })
                .catch(() => {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError("Une erreur est survenue lors du changement d'état")
                })
                .finally(() => {
                    handleOnCloseSuppModale();
                })
        }
    }

    /**
     * Actions Possibles
     */
    const cellColumnActions = useCallback((props: CellProps<EnvironnementOperation>) => {
        return (
            <Box display={"flex"} justifyContent={"center"} gap={1}>
                {/* Crayon → Modification de l'environnement */}
                {(utilisateurAutorise && props.row.original.etatDemande === EtatDemande.DECLARE) &&
                    <VoxIconAction
                        tooltip={"Modifier l'environnement"}
                        icon={"edit"}
                        onClick={() => {
                            setModEnvironnement(props.row.original);
                            handleOnOpenModale()
                        }}
                    />
                }
                {/* Poubelle → Suppression de l'environnement */}
                {(utilisateurAutorise &&
                    (props.row.original.etatDemande === EtatDemande.DECLARE
                    || props.row.original.etatDemande === EtatDemande.INSTALLE)) &&
                <VoxIconAction
                    tooltip={(props.row.original.etatDemande === EtatDemande.DECLARE ?
                        "Supprimer "
                        : "Demander la suppression de ")
                        + "l'environnement"
                    }
                    icon={"trash-alt"}
                    onClick={() => {
                        setModEnvironnement(props.row.original);
                        setShowSuppressionModale(true);
                    }}
                />
                }
            </Box>
        )
    }, [utilisateurAutorise]);

    /**
     * Cellules URL
     */
    const linkUrlCells = useCallback((url: string) => {
        return (
            <VoxLinkUrl href={url}>
                {url}
            </VoxLinkUrl>
        )
    }, []);

    /**
     * Cellules Description
     */
    const affichageTexteAvecSautsDeLigne = useCallback((texte: string) => {
        if (texte) {
            const values: string[] = texte.split('\n');
            return <>{
                values.map((value, index) => <p key={`${value.substring(0, 5).replace(" ", "")}-${index}`}>{value}</p>)
            }</>;
        } else {
            return <></>;
        }
    }, []);

    /**
     * Lignes du tableau
     */
    const rows = useMemo(() => {
        return environnements.map(
            (env) => ({
                id: env.id,
                description: env.description,
                url: env.url,
                typeEnvironnement: env.typeEnvironnement,
                dateInstallation: env.dateInstallation,
                etatDemande: env.etatDemande
            })
        ).sort((a,b) => a.typeEnvironnement.localeCompare(b.typeEnvironnement))
    }, [environnements]);

    /**
     * Colonnes du tableau
     */
    const columns: Array<Column<EnvironnementOperation>> = useMemo(() => [
        {
            Header: 'Type Environnement',
            accessor: (originalRow => affichageLibelleTypeEnvironnement(originalRow.typeEnvironnement)),
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 80
        },
        {
            Header: 'URL',
            accessor: 'url',
            minWidth: 200,
            Cell: (props: CellProps<EnvironnementOperation>) => linkUrlCells(props.row.original.url)
        },
        {
            Header: 'Etat',
            accessor: (originalRow => affichageLibelleEtatDemande(originalRow.etatDemande)),
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 80
        },
        {
            Header: 'Description',
            accessor: 'description',
            minWidth: 150,
            Cell: ((props: CellProps<EnvironnementOperation>) => affichageTexteAvecSautsDeLigne(props.value)),
        },
        {
            Header: 'Actions Possibles',
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: cellColumnActions,
            minWidth: 60,
            maxWidth: 90
        }
    ], [cellColumnActions, linkUrlCells, affichageTexteAvecSautsDeLigne])

    return (
        <>
            {(isError) &&
            <VoxAlert severity={"error"}>
                { messageError }
            </VoxAlert>
            }

            {isLoading ? <VoxLoader/> :
                <Grid container justifyContent={"space-evenly"} gap={3}>
                    <Grid item xs={12}>
                        <VoxCard id={"envOperations"} iconBeforeTitle={"cogs"} title={"Environnements Annexes"}>

                            {utilisateurAutorise &&
                            <VoxButton
                                customColor={"blue"}
                                startIcon={<FontAwesomeIcon icon={"plus"}/>}
                                onClick={handleOnOpenModale}
                            >
                                Ajouter
                            </VoxButton>}

                            <VoxTable
                                data={rows}
                                columns={utilisateurAutorise ? columns
                                    : columns.filter(col => col.id !== 'actions')
                                }
                                cellProps={(cell: CellProps<EnvironnementOperation>) => {
                                    if (cell.column.id === "description") {
                                        return {
                                            style: {textAlign: "left"}
                                        }
                                    }
                                }}
                            />
                        </VoxCard>

                        {showModale &&
                            <EnvironnementOperationForm
                                environnement={modEnvironnement}
                                operationId={props.operationId}
                                show={showModale}
                                close={handleOnCloseModale}
                                loadData={loadEnvironnements}
                            />
                        }

                        {(showSuppressionModale && modEnvironnement) &&
                            <ModaleConfirmationSuppressionEnvironnement
                                etatDemande={modEnvironnement.etatDemande}
                                url={modEnvironnement.url}
                                show={showSuppressionModale}
                                close={handleOnCloseSuppModale}
                                submit={askForDeleteEnvironnement}
                            />
                        }

                    </Grid>
                </Grid>
            }
        </>
    )
}