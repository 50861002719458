import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {affichageDateWithFormatDMYHm} from "../../../utils/string.utils";
import auditService from "../../../services/survox-back/audit/audit.service";
import {Audit} from "../../../models/audit/audit.model";
import {CellProps, Column, IdType, Row} from "react-table";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import dayjs from "dayjs";
import { sortDatesInTables } from "../../../utils/date.utils";
import ConcatenedStringColumnTypeCell from "../../../components/genericComponentsUI/table/cells/concatenedStringColumnTypeCell";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { SelectInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";

type PropsType = { operationId: number };


export default function OperationsDetailAudit(props: PropsType) {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [auditsOp, setAuditsOp] = useState<Audit[]>([])

    const [isLoadingAuditsOp, setIsLoadingAuditsOp] = useState(false);

    /**
     * Chargement des données du tableau
     */
    const chargerAuditsDeOperation = useCallback( (controller: AbortController) => {
        setIsLoadingAuditsOp(true);
        auditService.getAuditByOperation(props.operationId, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const auditsOpListe = response.data;
                    setAuditsOp(auditsOpListe);
                    setIsLoadingAuditsOp(false);
                }
            })
            .catch(() => {
                if (isMountedRef.current) {
                    setIsLoadingAuditsOp(false);
                }
            });
    }, [props.operationId]);

    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        chargerAuditsDeOperation(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [chargerAuditsDeOperation])


    /**
     * Remplissage des lignes du tableau
     */
    const rows: any[] = useMemo(() => {
        return auditsOp.map(
            (audit: Audit) => ({
                id: audit.id,
                utilisateur: audit.utilisateur.nom + " " + audit.utilisateur.prenom,
                type: audit.type,
                valeurAvant: audit.valeurAvant,
                valeurApres: audit.valeurApres,
                dateDebutMillisecondes: Number(dayjs(audit.dateDebut, "x")),    // "x" : format Unix ms timestamp
                dateDebut: audit.dateDebut,
                dateFin: audit.dateFin,
                description: audit.description,
            })
        ).sort((a, b) => a.dateDebutMillisecondes - b.dateDebutMillisecondes)
    }, [auditsOp]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<Audit>> = useMemo(() => [
            {
                Header: 'Utilisateur',
                accessor: 'utilisateur',
                minWidth: 150,
            },
            {
                Header: 'Type',
                accessor: 'type',
                minWidth: 170,
                Filter: SelectInputFilter,
                filter: 'includes',
            },
            {
                Header: "Valeur avant",
                accessor: 'valeurAvant',
                minWidth: 200,
                Cell: (props: CellProps<Audit>) => <ConcatenedStringColumnTypeCell cellProps={props}/>
            },
            {
                Header: "Valeur après",
                accessor: 'valeurApres',
                minWidth: 200,
                Cell: (props: CellProps<Audit>) => <ConcatenedStringColumnTypeCell cellProps={props}/>
            },
            {
                Header: "Date de début",
                id: 'dateDebut',
                accessor: (originalRow: Audit) => affichageDateWithFormatDMYHm(originalRow.dateDebut),
                sortType: (a: Row<Audit>,
                           b: Row<Audit>,
                           columnId: IdType<Audit>,
                           desc: boolean | undefined) => sortDatesInTables(a.original.dateDebut, b.original.dateDebut, desc),
                minWidth: 150,
            },
            {
                Header: "Date de fin",
                id: 'dateFin',
                accessor: (originalRow: Audit) => affichageDateWithFormatDMYHm(originalRow.dateFin),
                sortType: (a: Row<Audit>,
                           b: Row<Audit>,
                           columnId: IdType<Audit>,
                           desc: boolean | undefined) => sortDatesInTables(a.original.dateFin, b.original.dateFin, desc),
                minWidth: 150,
            },
            {
                Header: "Description",
                accessor: 'description',
                minWidth: 140,
            },
        ], []
    );


    return (
        <>
            {isLoadingAuditsOp ? <VoxLoader/> :
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialStateSortBy={[{id: 'dateFin', desc: true}]}
                    cellProps={(cell: CellProps<Audit>) => {
                        if (["valeurAvant", "valeurApres", "description"].includes(cell.column.id)) {
                            return {
                                style: {textAlign: "left"}
                            }
                        }
                    }}
                />
            }
        </>
    )
}