import React, { ChangeEvent } from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { VoxLabel } from './voxLabel';
import { FormControl } from "@mui/material";

type VoxTextAreaProps = {
    id: string
    startIconLabel?: IconProp
    label: string
    multilineMaxRows?: number
    minRows?: number
    value?: string | number
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
    maxLength?: number
    required?: boolean
    disabled?: boolean
    placeholder?: string
}

const StyledTextarea = styled(TextareaAutosize)(
    () => `
        width: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px;
        color: #666d92;
        background: #fff;
        border: 1px solid;
        resize: vertical;
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
    `
);

export const VoxTextArea = (props: VoxTextAreaProps) => {
    return (
        <FormControl fullWidth>
            <VoxLabel
                id={props.id}
                startIconLabel={props.startIconLabel}
                label={props.label}
                required={props.required}
            />
            <StyledTextarea
                sx={{
                    borderColor: 'cobalt.grey10',
                    '&:hover': {
                        borderColor: 'cobalt.hover'
                    },
                    '&:focus': {
                        borderColor: 'cobalt.selected'
                    },
                    '::placeholder': {
                        color: 'cobalt.lynch'
                    },
                    '&:disabled': {
                        color: '#939396',
                        borderColor: '#b0b0b4',
                        backgroundColor: '#ededf2'
                    }
                }}
                id={props.id}
                maxRows={props.multilineMaxRows}
                minRows={props.minRows}
                value={props.value}
                onChange={props.onChange}
                required={props.required}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
        </FormControl>
    );
}