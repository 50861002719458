import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import OperationsFacturation from "../../components/facturation/operationsFacturation";

export default function OperationsFacturationView() {

    return (
        <>
            <VoxHeader title={"Facturation"}/>
            <OperationsFacturation typeOperation={'all'}/>
        </>
    )
}