import {Navigate, Route, Routes} from "react-router-dom";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import React from "react";
import PortefeuillesProjetsListe from "./portefeuillesProjetsListe";
import PortefeuillesProjetsCreation from "./portefeuillesProjetsCreation";
import PortefeuillesProjetsModification from "./portefeuillesProjetsModification";
import {Grid} from "@mui/material";

export default function PortefeuillesProjetsView() {
    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Portefeuilles projets"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={8}>
                            <PortefeuillesProjetsListe/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"/creation"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true}
                               lienRetour={'/administration/portefeuilles_projets'}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={8}>
                            <PortefeuillesProjetsCreation/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={8}>
                            <PortefeuillesProjetsModification/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}