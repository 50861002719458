import {ReactNode} from "react";
import {Box} from "@mui/material";

type TabPanelPropsType = {
    index: number,
    value: number,
    children?: ReactNode
}

export default function TabPanel(props: TabPanelPropsType) {

    return(
        <Box
            id={`tab-panel-${props.index}`}
            hidden={props.value !== props.index}
            role={"tabpanel"}
            aria-labelledby={`tab-${props.index}`}
            sx={{paddingX: 2, paddingTop: 5}}
        >
            {props.value === props.index &&
                props.children
            }
        </Box>
    )

}