import jwtDecode from "jwt-decode";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";

export function extractUtilisateurFromToken(token: string): Utilisateur {
    const decodedToken = jwtDecode(token) as any;
    return {
        id: decodedToken.sub,
        login: decodedToken.login,
        prenom: decodedToken.prenom,
        nom: decodedToken.nom,
        mail: decodedToken.email,
        estActif: decodedToken.estActif,
        groupe: decodedToken.groupe,
        mdpAModifier: decodedToken.mdpAModifier,
        derniereConnexion: decodedToken.derniereConnexion
    };
}