import { Tab, Tabs } from "@mui/material"
import { ReactElement } from "react";

type VoxTabsProps = {
    activeTab: number;
    handleOnChangeTabs: (event: React.SyntheticEvent, newTabIndex: number) => void;
    children: ReactElement<typeof Tab>[];
}


export const VoxTabs = (props: Readonly<VoxTabsProps>) => {

    return (
        <Tabs
            value={ props.activeTab }
            onChange={ props.handleOnChangeTabs }
            variant={"scrollable"}
            allowScrollButtonsMobile
            aria-label={"Onglets de navigation"}
            sx={{
                borderColor: 'rgba(0,0,0,0.08)',
                '& .MuiButtonBase-root:not(.MuiTabs-scrollButtons)': {
                    paddingTop: '18px',
                    borderRadius: '5px 5px 0 0',
                    transition: 'background-color 0.2s ease-in-out'
                },
                '& .MuiButtonBase-root:hover:not(.Mui-selected)': {
                    backgroundColor: 'rgba(0,0,0,0.025)'
                },
                '& .Mui-selected': {
                    backgroundColor: 'white',
                    boxShadow: '0 0 5px rgba(0,0,0,0.08)'
                }
            }}
        >
            { props.children }
        </Tabs>
    )

}