import axios_instance from "../axios-client"
import { UpdateUtilisateurDto } from "./dtos/request/updateUtilisateur.dto";
import { CreateUtilisateurDto } from "./dtos/request/createUtilisateur.dto";
import { UpdateMdpUtilisateurDto } from "./dtos/request/updateMdpUtilisateur.dto";
import { ResetMdpUtilisateurDto } from "./dtos/request/resetMdpUtilisateur.dto";

const utilisateurService =  {

    getAllUtilisateurs: (signal?: AbortSignal) => {
        return axios_instance.get("/utilisateurs", { signal });
    },

    getUtilisateur: (id: string, signal?: AbortSignal) => {
        return axios_instance.get(`utilisateurs/${id}`, { signal });
    },

    updateUtilisateur: (id: number, updateUtilisateurDto: UpdateUtilisateurDto) => {
        return axios_instance.put(`/utilisateurs/${id}`, updateUtilisateurDto);
    },

    createUtilisateur: (createUtilisateurDto: CreateUtilisateurDto) => {
        return axios_instance.post('/utilisateurs', createUtilisateurDto);
    },

    updateMdpUtilisateur: (updateMdpUtilisateurDto: UpdateMdpUtilisateurDto) => {
        return axios_instance.put(`/modification_mdp`, updateMdpUtilisateurDto);
    },

    resetMdpUtilisateur: (resetMdpUtilisateurDto: ResetMdpUtilisateurDto) => {
        return axios_instance.put(`/reinitialisation_mdp`, resetMdpUtilisateurDto);
    }
}

export default utilisateurService;