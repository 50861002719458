import "../../genericComponentsUI/button/voxButton.css";
import React, {ChangeEvent, FormEvent, SyntheticEvent, useEffect, useMemo, useState} from "react";
import { Operation } from "../../../models/operation/operation.model";
import { NomPlateforme } from "../../../models/operation/nomPlateforme.enum";
import { Hebergeur } from "../../../models/operation/hebergeur.enum";
import { Plateforme } from "../../../models/operation/plateforme.dto";
import { EtatDemande, INSTALLATION_EN_COURS } from "../../../models/operation/etatDemande.enum";
import plateformeService from "../../../services/survox-back/operation/plateforme.service";
import { useNavigate } from "react-router-dom";
import { UpdateVMOperationDto, UpdateOperationDto } from "../../../services/survox-back/operation/dtos/request/updateAvanceeOperation.dto";
import operationService from "../../../services/survox-back/operation/operation.service";
import ModaleConfirmationSuppressionAvancee from "../../modals/modaleConfirmationSuppressionAvancee";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import { PortefeuilleProjet } from "../../../models/portefeuilleProjet/portefeuilleProjet.model";
import portefeuilleProjetService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjet.service";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import { Box, Grid, MenuItem, SelectChangeEvent, Typography} from "@mui/material";
import { VoxLabel } from "../../genericComponentsUI/input/voxLabel";
import { VoxSelectInput } from "../../genericComponentsUI/input/voxSelectInput";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import { VoxLoaderInputFormulaire } from "../../genericComponentsUI/loaders/voxLoaderInputFormulaire";
import { VoxTextField } from "../../genericComponentsUI/input/voxTextField";
import { VoxTextArea } from "../../genericComponentsUI/input/voxTextArea";
import { PATTERN_URL, TITLE_PATTERN_URL, getNumeroVersionMajeure } from "../../../utils/string.utils";
import { VoxLegendChampsObligatoires } from "../../genericComponentsUI/label/voxLegendChampsObligatoires";
import { RegionOvh } from "../../../models/virtualMachine/regionOvh.enum";
import { TypeEnregistrementDNS, getDisplayValueFromTypeEnregistrementDNS } from "../../../models/operation/enregistrementDNS/typeEnregistrementDNS.enum";
import { OperationModificationAvanceeForm, virtualMachineOperationForm } from "../../../models/operation/operationForm/operationModificationAvanceeForm.model";
import {Utilisateur} from "../../../models/utilisateur/utilisateur.model";
import utilisateurService from "../../../services/survox-back/utilisateur/utilisateur.service";
import {VoxAutocomplete} from "../../genericComponentsUI/input/voxAutocomplete";


type PropsType = {
    operation: Operation,
    onUpdate: (updateOp: UpdateOperationDto, machineVirtuelleOpDto: UpdateVMOperationDto) => void
}


export default function OperationsModificationAvanceeForm(props: PropsType) {

    const navigate = useNavigate();

    // Déclaration de l'état lié au formulaire stockant les informations d'une opération
    const [operation, setOperation] = useState<OperationModificationAvanceeForm>({
        id: props.operation.id,
        nom: props.operation.nom,
        nomClient: props.operation.nomClient,
        portefeuilleProjet: props.operation.portefeuilleProjet,
        utilisateur: props.operation.utilisateur,
        url: props.operation.url,
        plateforme: props.operation.plateforme,
        hebergeur: props.operation.hebergeur,
        etatDemande: props.operation.etatDemande,
        description: props.operation.description ?? "",
        enregistrementsDns: props.operation.enregistrementsDns ?? []
    });

    
    const etatDemandeInitialOperation = useMemo(() => props.operation.etatDemande, [props.operation.etatDemande]);

    // Déclaration de l'état lié au formulaire stockant les information de la VM liée à une opération
    const [virtualMachineDeOperation, setVirtualMachineDeOperation] = useState<virtualMachineOperationForm>({
        vmName: (
            (props.operation.virtualMachine === null || props.operation.virtualMachine?.vmName === null) 
            ? "" 
            : props.operation.virtualMachine?.vmName
        ),
        identifiantMachine: (
            (props.operation.virtualMachine === null || props.operation.virtualMachine?.identifiantMachine === null) 
            ? "" 
            : props.operation.virtualMachine?.identifiantMachine
        ),
        region: (
            (props.operation.virtualMachine === null || props.operation.virtualMachine?.region === null) 
            ? "" 
            : props.operation.virtualMachine?.region
        )
    });

    // Déclaration des états gérant la récupération des portefeuilles projets
    const [listePortefeuillesProjets, setListePortefeuillesProjets] = useState<PortefeuilleProjet[]>([]);
    const [isLoadingPortefeuillesProjets, setIsLoadingPortefeuillesProjets] = useState<boolean>(false);
    const [isLoadingPortefeuillesProjetsError, setIsLoadingPortefeuillesProjetsError] = useState<boolean>(false);

    // Déclaration des états gérant la récupération des reponsables projets
    const [listeChefProjet, setListeChefProjet] = useState<Utilisateur[]>([]);
    const [isLoadingChefProjet, setIsLoadingChefProjet] = useState<boolean>(false);
    const [isLoadingChefProjetError, setIsLoadingChefProjetError] = useState<boolean>(false);

    // Déclaration des états gérant la récupération des plateformes
    const [listePlateformes, setListePlateformes] = useState<Plateforme[]>([]);
    const [isLoadingListePlateformes, setIsLoadingListePlateformes] = useState(false);
    const [isLoadListePlateformesError, setIsLoadListePlateformesError] = useState(false);

    // Déclaration de l'état stockant la vérification de la propriété "Vote en cours ou scellements actifs" pour l'opération courante
    const [isAlerteOperationScelleOuVoteEnCours, setIsAlerteOperationScelleOuVoteEnCours] = useState(false);

    // Déclaration de l'état gérant l'affichage de la modale de confirmation de suppression
    const [showModaleConfirmationSuppression, setShowModaleConfirmationSuppression] = useState(false);

    // Déclaration de l'état gérant le contrôle avant suppression d'une opération et du message affiché
    const [isLoadingAlerteAvantSuppression, setIsLoadingAlerteAvantSuppression] = useState(false);
    const [isErrorControleAvantSuppression, setIsErrorControleAvantSuppression] = useState(false);
    const [messageErrorControleAvantSuppression, setMessageErrorControleAvantSuppressionMessage] = useState<string>("");

    /**
     * Méthode se déclenchant lors de l'envoi du formulaire de modification avancée permettant :
     * -->  Effectuer un contrôle de l'état du vote et des scellements afin d'afficher une alerte lors d'un passage
     *      à l'état A_SUPPRIMER afin d'informer l'utilisateur de l'impact de l'action + ouvrir une modale de confirmation 
     *      (concerne les opération déployées chez OVH)
     * -->  Effectuer un contrôle s'il s'agit d'une suppression (état SUPPRIME) pour afficher une modale de confirmation
     * -->  Appeler la méthode modificationAvanceeOperation définie dans le composant pour appliquer les modifications
     * @param event
     */
    const handleOnModificationAvanceeOperation = (event: FormEvent) => {
        // Bloquer le comportement classique
        event.preventDefault();

        if (operation.hebergeur === Hebergeur.OVH && operation.etatDemande === EtatDemande.A_SUPPRIMER) {
            setIsLoadingAlerteAvantSuppression(true);
            setIsErrorControleAvantSuppression(false);
            setIsAlerteOperationScelleOuVoteEnCours(false);
            // Contrôle avant suppression pour l'opération
            operationService.alerteAvantSuppressionPourSuppressionAvancee(operation.id)
                .then(response => {
                    if (response.status === 200) {
                        // Mise à jour de l'état gérant l'affichage de l'alerte selon la valeur de retour
                        if (response.data) {
                            setIsAlerteOperationScelleOuVoteEnCours(true);
                        } else {
                            setIsAlerteOperationScelleOuVoteEnCours(false);
                        }
                        // Affichage de la modale de confirmation
                        // (le texte de la modale sera différent selon la valeur de la variable : isAlerteOperationScelleOuVoteEnCours)
                        setShowModaleConfirmationSuppression(true);
                    }
                })
                .catch(err => {
                    // Dans le cas où le contrôle à échoué, une alerte est affichée dans le formulaire et la modification n'est pas applicable
                    setIsErrorControleAvantSuppression(true);
                    if (err.response.status === 404 && (err.response.data.message).includes(`L'opération avec l'id ${operation.id} n'existe pas`)) {
                        setMessageErrorControleAvantSuppressionMessage(err.response.data.message);
                    } else {
                        setMessageErrorControleAvantSuppressionMessage("Une erreur est survenue lors de la vérification du statut de l'opération (scellée et/ou en cours de vote).");
                    }
                })
                .finally(() => setIsLoadingAlerteAvantSuppression(false))

        } else if (operation.etatDemande === EtatDemande.SUPPRIME) {
            // Dans le cas où les modification implique une suppression
            // --> Affichage d'une modale de confirmation avant suppression
            setShowModaleConfirmationSuppression(true);
        } else {
            modificationAvanceeOperation();
        }
    }

    /**
     * Méthode permettant de déclencher la modification avancée d'une opération en appelant
     * la méthode donnée en props par le composant parent
     */
    const modificationAvanceeOperation = () => {
        props.onUpdate(
            {
                nom: operation.nom,
                nomClient: operation.nomClient,
                portefeuilleProjet: operation.portefeuilleProjet,
                url: operation.url,
                utilisateur: operation.utilisateur,
                etatDemande: operation.etatDemande,
                plateforme: operation.plateforme,
                description: (operation.description.trim() === "") ? null : operation.description.trim(),    // .trim() : // enlève les espaces au debut et à la fin de la chaine de caractères
                enregistrementsDns: operation.enregistrementsDns.filter(el => el.enregistrementDnsId !== "")
            },
            {
                vmName: (virtualMachineDeOperation.vmName) ? virtualMachineDeOperation.vmName : null,
                identifiantMachine: (virtualMachineDeOperation.identifiantMachine) ? virtualMachineDeOperation.identifiantMachine : null,
                region: (virtualMachineDeOperation.region) ? virtualMachineDeOperation.region : null
            }
        );
    }

    /**
     * Récupération de tous les portefeuilles projets
     */
    const chargerPortefeuillesProjets = (controller: AbortController) => {
        setIsLoadingPortefeuillesProjets(true);
        setIsLoadingPortefeuillesProjetsError(false);
        portefeuilleProjetService.getAllPortefeuillesProjets(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    setListePortefeuillesProjets(response.data);
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(false);
                }
            })
            .catch(() => {
                setIsLoadingPortefeuillesProjets(false);
                setIsLoadingPortefeuillesProjetsError(true);
            });
    }

    /**
     * Récupération de tous les responsables projets
     */
    const chargerChefProjet = (controller: AbortController) => {
        setIsLoadingChefProjet(true);
        setIsLoadingChefProjetError(false);
        utilisateurService.getAllUtilisateurs(controller.signal)
            .then(response => {
                if(response.status === 200) {
                    //On exclue les utilisateurs sans groupe
                    const resp = response.data.filter((user: Utilisateur) => user.groupe);
                    //On exclue le groupe "Observateur"
                    setListeChefProjet(resp.filter((chef: Utilisateur) => chef.groupe.id !== 8));
                    setIsLoadingChefProjet(false);
                    setIsLoadingChefProjetError(false);
                }
            })
            .catch(() => {
                setIsLoadingChefProjet(false);
                setIsLoadingChefProjetError(true);
            })
    }

    /**
     * Récupération de toutes les plateformes
     */
    const chargerPlateformes = (controller: AbortController) => {
        setIsLoadingListePlateformes(true);
        setIsLoadListePlateformesError(false);
        plateformeService.getAllPlateformes(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    const plateformes = response.data;
                    setListePlateformes(plateformes);
                    setIsLoadingListePlateformes(false);
                    setIsLoadListePlateformesError(false);
                }
            })
            .catch(() => {
                setIsLoadingListePlateformes(false);
                setIsLoadListePlateformesError(true);
            })
    }

    useEffect(() => {
        const controller = new AbortController();
        chargerPortefeuillesProjets(controller);
        chargerChefProjet(controller);
        chargerPlateformes(controller);
        return () => controller.abort();
    }, []);


    useEffect(() => {
        if (operation.hebergeur === Hebergeur.OVH) {
            if (getNumeroVersionMajeure(props.operation.versionApplicative.version) >= 23) {
                setOperation((prevState) => {
                    let enregistrementsDns;
                
                    enregistrementsDns = [
                        { 
                            typeEnregistrementDns: TypeEnregistrementDNS.ADMIN,
                            enregistrementDnsId: prevState.enregistrementsDns.find(
                                    el => el.typeEnregistrementDns === TypeEnregistrementDNS.ADMIN
                                )?.enregistrementDnsId ?? ""
                        },
                        {
                            typeEnregistrementDns: TypeEnregistrementDNS.ELECTEUR,
                            enregistrementDnsId: prevState.enregistrementsDns.find(
                                    el => el.typeEnregistrementDns === TypeEnregistrementDNS.ELECTEUR
                                )?.enregistrementDnsId ?? ""
                        }
                    ];
                    
                    return { ...prevState, enregistrementsDns };
                });
            } else {
                setOperation((prevState) => {
                    let enregistrementsDns;

                    enregistrementsDns = [
                        {
                            typeEnregistrementDns: TypeEnregistrementDNS.UNIQUE,
                            enregistrementDnsId: prevState.enregistrementsDns.find(
                                el => el.typeEnregistrementDns === TypeEnregistrementDNS.UNIQUE
                            )?.enregistrementDnsId ?? ""
                        }
                    ];

                    return { ...prevState, enregistrementsDns };
                });
            }
        }
    }, [operation.hebergeur, props.operation.versionApplicative.version]);


    /**
     * Méthode permettant de mettre à jour les valeurs textuelles de l'opération en fonction des valeurs renseignés
     * @param event Évenement émit lorsque l'utilisateur modifie la valeur du champ texte
     */
    const handleOnChangeOperationTextValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value: string = "";
        if (event.target.id === "url") {
            value = String(event.target.value).trim();   // empêche/enlève les espaces
        } else {
            value = event.target.value;
        }
        setOperation((prevState: OperationModificationAvanceeForm) => ({ ...prevState, [event.target.id]: value }));
    }

    /**
     * Méthode permettant de gérer le changement de valeur du portefeuille projet sélectionné et de mettre à jour l'état
     * @param event Évenement émit lorsque l'utilisateur modifie la valeur du select
     */
    const handleOnChangePortefeuilleProjet = (event: SelectChangeEvent<HTMLInputElement>) => {
        const newPortefeuilleProjet = listePortefeuillesProjets.find((portefeuilleProjet: PortefeuilleProjet) => portefeuilleProjet.id === Number(event.target.value));
        if (newPortefeuilleProjet) {
            setOperation((prevState: OperationModificationAvanceeForm) => ({ ...prevState, [event.target.name]: newPortefeuilleProjet }));
        }
    }

    /**
     * Méthode permettant de gérer le changement chef(fe) de projet et de mettre à jour l'état
     * @param event Évènement émit lorsque l'utilisateur modifie la valeur du select
     * @param newUser L'utilisateur sélectionné pour être chef projet
     */
    const handleOnChangeChefProjet = (event: SyntheticEvent, newUser: Utilisateur | null) => {
        if(newUser) {
            const newChefProjet = listeChefProjet.find((chefProjet: Utilisateur) => chefProjet.id === newUser.id);
            if (newChefProjet) {
                setOperation((prevState: OperationModificationAvanceeForm) => ({
                    ...prevState,
                    utilisateur: newChefProjet
                }));
            }
        }
    }

    /**
     * Méthode permettant de gérer le changement de valeur de l'état demande sélectionné et met à jour l'état
     * @param event Évenement émit lorsque l'utilisateur modifie la valeur du select
     */
    const handleOnChangeEtat = (event: SelectChangeEvent<HTMLInputElement>) => {
        setOperation((prevState: OperationModificationAvanceeForm) => ({ ...prevState, [event.target.name]: event.target.value }));
    }

    /**
     * Gère le changement de valeur de la plateforme sélectionnée et met à jour l'état
     * @param event
     */
    const handleOnChangePlateforme = (event: SelectChangeEvent<HTMLInputElement>) => {
        const newPlateforme = listePlateformes.find((plateforme: Plateforme) => plateforme.id === Number(event.target.value));
        if (newPlateforme) {
            setOperation((prevState: OperationModificationAvanceeForm) => ({ ...prevState, [event.target.name]: newPlateforme }));
        }
    }

    /**
     * Méthode permettant de mettre à jour l'état lors de la modification de la valeur de l'identifiant d'un enregistrement DNS
     * --> Met à jour l'enregistrement dont l'id correspond à celui récupéré dans l'évenement
     * @param event Évenement émit lorsque l'utilisateur modifie la valeur du champ texte
     */
    const handleOnChangeEnregistrementDNSIdentifiant = (event: ChangeEvent<HTMLInputElement>) => {
        setOperation((prevState: OperationModificationAvanceeForm) => {
            const updatedEnregistrementsDNS = prevState.enregistrementsDns.map(enregistrementDns => {
                // Met à jour la valeur de l'identifiant de l'enregistrement DNS pour l'enregistrement correspondant
                if(enregistrementDns.typeEnregistrementDns === event.target.id) {
                    enregistrementDns.enregistrementDnsId = event.target.value;
                }
                return enregistrementDns;
            })
            return { ...prevState, enregistrementsDns: updatedEnregistrementsDNS };
        });
    }

    /**
     * Méthode mettant à jour les valeurs des champs liés à la machine virtuelle (Nom VM & Identifiant machine) de l'opération en fonction des inputs renseignés
     * @param event
     */
    const handleOnChangeVirtualMachineDeOperation = (event: ChangeEvent<HTMLInputElement>) => {
        setVirtualMachineDeOperation((prevState: virtualMachineOperationForm) => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    /**
     * Méthode mettant à jour la région OVH de la machine virtuelle de l'opération
     * @param event
     */
    const handleOnChangeRegionOVH = (event: SelectChangeEvent<HTMLInputElement>) => {
        setVirtualMachineDeOperation((prevState: virtualMachineOperationForm) => ({ ...prevState, [event.target.name]: event.target.value}));
    }


    /**
     * Gestion de l'affichage pour l'input "Portefeuille Projet"
     */

    const idLabelPortefeuilleProjet: string = "portefeuilleProjet-label";
    const startIconLabelPortefeuilleProjet: IconProp = "wallet";
    const labelTextPortefeuilleProjet: string = "Portefeuille projet";

    const labelPortefeuilleProjet = (
        <VoxLabel
            id={idLabelPortefeuilleProjet}
            startIconLabel={startIconLabelPortefeuilleProjet}
            label={labelTextPortefeuilleProjet}
            required
        />
    );

    let selectPortefeuilleProjet;
    if (isLoadingPortefeuillesProjets) {
        selectPortefeuilleProjet = (
            <>
                {labelPortefeuilleProjet}
                <VoxLoaderInputFormulaire />
            </>
        );
    } else if (isLoadingPortefeuillesProjetsError) {
        selectPortefeuilleProjet = (
            <>
                {labelPortefeuilleProjet}
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des portefeuilles projets.
                </VoxAlert>
            </>
        );
    } else {

        selectPortefeuilleProjet = listePortefeuillesProjets.length > 0 && (
            <VoxSelectInput
                id={"portefeuilleProjet"}
                idLabel={idLabelPortefeuilleProjet}
                startIconLabel={startIconLabelPortefeuilleProjet}
                label={labelTextPortefeuilleProjet}
                value={operation.portefeuilleProjet.id}
                onChange={handleOnChangePortefeuilleProjet}
                fullWidth
                required={true}
            >
                {
                    listePortefeuillesProjets.map((portefeuilleProjet: PortefeuilleProjet) => {
                        return (
                            <MenuItem key={portefeuilleProjet.id}
                                value={portefeuilleProjet.id}>{portefeuilleProjet.nom}</MenuItem>
                        )
                    })
                }
            </VoxSelectInput>
        );
    }

    /**
     * Gestion affichage pour l'input "Chef(fe) Projet"
     */

    const idLabelChefProjet: string = "chefProjet-label";
    const startIconLabelChefProjet: IconProp = "user";
    const labelTextChefProjet: string = "Chef(fe) de projet";

    const labelChefProjet = (
        <VoxLabel
            id={idLabelChefProjet}
            startIconLabel={startIconLabelChefProjet}
            label={labelTextChefProjet}
            required
        />
    );

    let selectChefProjet;
    if (isLoadingChefProjet) {
        selectChefProjet = (
            <>
                {labelChefProjet}
                <VoxLoaderInputFormulaire />
            </>
        );
    } else if (isLoadingChefProjetError) {
        selectChefProjet = (
            <>
                {labelChefProjet}
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des responsables projets.
                </VoxAlert>
            </>
        );
    } else if(listeChefProjet.length > 0) {

        //Tri des utilisateurs par ordre alphabétique
        listeChefProjet.sort((a, b) => a.nom.localeCompare(b.nom));

        //Puis tri par nom de groupe, nécessaire pour l'option 'groupBy' qui sera faite sur cet attribut
        listeChefProjet.sort((a, b) => a.groupe.nom.localeCompare(b.groupe.nom))

        selectChefProjet = (
            <VoxAutocomplete
                id={"utilisateur"}
                label={labelTextChefProjet}

                options={listeChefProjet}
                optionLabel={(user: Utilisateur) => user.prenom+" "+user.nom}
                value={operation.utilisateur}
                onChange={handleOnChangeChefProjet}
                optionDisabled={(user: Utilisateur) => !user.estActif}
                optionEqualToValue={(userA: Utilisateur, userB: Utilisateur) => userA.id === userB.id}
                groupBy={(user: Utilisateur) => user.groupe.nom}

                helperText={"Tapez un nom ou cherchez dans la liste"}
                startIconLabel={startIconLabelChefProjet}
                fullWidth
                required
            />
        )
    }

    /**
     * Gestion affichage des plateformes
     */

    const idLabelPlateform: string = "platform-label";
    const startIconLabelPlateform: IconProp = "hdd";
    const labelTextPlateform: string = "Type de plateforme";

    const labelPlateforme = (
        <VoxLabel
            id={idLabelPlateform}
            startIconLabel={startIconLabelPlateform}
            label={labelTextPlateform}
            required
        />
    );
    let selectTypePlateforme;
    if (isLoadingListePlateformes) {
        selectTypePlateforme = (
            <>
                {labelPlateforme}
                <VoxLoaderInputFormulaire />
            </>
        );
    } else if (isLoadListePlateformesError) {
        selectTypePlateforme = (
            <>
                {labelPlateforme}
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des plateformes.
                </VoxAlert>
            </>
        );
    } else {
        selectTypePlateforme = listePlateformes.length > 0 && (
            <VoxSelectInput
                id={"plateforme"}
                idLabel={idLabelPlateform}
                startIconLabel={startIconLabelPlateform}
                label={labelTextPlateform}
                value={operation.plateforme.id}
                onChange={handleOnChangePlateforme}
                fullWidth
                required={true}
            >
                {
                    listePlateformes.map((plateforme: Plateforme) => {
                        return (
                            <MenuItem key={plateforme.id}
                                value={plateforme.id}>{plateforme.nom} ({plateforme.definition})</MenuItem>
                        )
                    })
                }
            </VoxSelectInput>
        );
    }

    /**
     * Mise à jour de l'hébergeur en fonction du type de plateforme sélectionnée
     */
    useEffect(() => {
        if (operation.plateforme.nom === NomPlateforme.H1) {
            setOperation((prevState: OperationModificationAvanceeForm) => ({ ...prevState, "hebergeur": Hebergeur.OVH }));
        }
        else if (operation.plateforme.nom === NomPlateforme.H2 || operation.plateforme.nom === NomPlateforme.H3 || operation.plateforme.nom === NomPlateforme.H4 || operation.plateforme.nom === NomPlateforme.H5) {
            setOperation((prevState: OperationModificationAvanceeForm) => ({ ...prevState, "hebergeur": Hebergeur.DOCAPOSTE }));
        }
    }, [operation.plateforme.nom]);

    /**
     * Méthode pour l'affichage des messages d'erreur
     */
    let affichageMessageErreur;
    if (isErrorControleAvantSuppression) {
        affichageMessageErreur = (
            <VoxAlert severity={"error"} sx={{ mb: 2 }}>
                {messageErrorControleAvantSuppression}
            </VoxAlert>
        )
    }

    /**
     * Déclaration des champs textes liés au identitifiants d'enregistrements DNS
     */
    let enregistrementsDnsIdTextInputs;
    if (operation.hebergeur === Hebergeur.OVH) {
        enregistrementsDnsIdTextInputs = (
            <>
                {
                    operation.enregistrementsDns.map((enregistrementDNS) => {
                        if (
                            (enregistrementDNS.enregistrementDnsId || enregistrementDNS.enregistrementDnsId === "")
                            && enregistrementDNS.typeEnregistrementDns
                        ) {
                            return (
                                <Grid item xs={12} key={enregistrementDNS.typeEnregistrementDns}>
                                    <VoxTextField
                                        id={enregistrementDNS.typeEnregistrementDns}
                                        startIconLabel={"passport"}
                                        label={getDisplayValueFromTypeEnregistrementDNS(enregistrementDNS.typeEnregistrementDns)}
                                        onChange={handleOnChangeEnregistrementDNSIdentifiant}
                                        fullWidth
                                        value={enregistrementDNS.enregistrementDnsId}
                                        type="number"
                                        helperText="Exemple : 5232181527"
                                        min={0}
                                    />
                                </Grid>
                            );
                        }
                        return null;
                    }) 
                }
            </>
        );
    }


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12}>

                {affichageMessageErreur}

                <form onSubmit={handleOnModificationAvanceeOperation}>
                    <Grid container spacing={3} padding={2}>

                        <Grid item xs={12}>
                            <VoxLegendChampsObligatoires/>
                        </Grid>

                        {/* Champ : Nom */}
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"nom"}
                                startIconLabel={"poll-h"}
                                label={"Nom"}
                                onChange={handleOnChangeOperationTextValue}
                                fullWidth
                                required={true}
                                value={operation.nom}
                            />
                        </Grid>

                        {/* Champ : Client */}
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"nomClient"}
                                startIconLabel={"address-book"}
                                label={"Client"}
                                onChange={handleOnChangeOperationTextValue}
                                fullWidth
                                required={true}
                                value={operation.nomClient}
                            />
                        </Grid>

                        {/* Champ : Portefeuille projet */}
                        <Grid item xs={12}>
                            {selectPortefeuilleProjet}
                        </Grid>

                        {/* Champ : Chef(fe) Projet */}
                        <Grid item xs={12}>
                            {selectChefProjet}
                        </Grid>

                        {/* Champ : Site de vote */}
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"url"}
                                startIconLabel={"globe-americas"}
                                label={"Site de vote"}
                                onChange={handleOnChangeOperationTextValue}
                                fullWidth
                                required={true}
                                value={operation.url}
                                type="url"
                                pattern={PATTERN_URL}
                                title={TITLE_PATTERN_URL}
                            />
                        </Grid>

                        {/* Champ : Etat */}
                        <Grid item xs={12}>
                            <VoxSelectInput
                                id={"etatDemande"}
                                idLabel={"etatDemande"}
                                startIconLabel={"cog"}
                                label={"État"}
                                value={operation.etatDemande}
                                onChange={handleOnChangeEtat}
                                fullWidth
                                required={true}
                            >
                                {(etatDemandeInitialOperation !== EtatDemande.SUPPRIME) &&
                                    <MenuItem key={`etat-${EtatDemande.DECLARE}`} value={EtatDemande.DECLARE}>
                                        {EtatDemande.DECLARE}
                                    </MenuItem>
                                }

                                {!(etatDemandeInitialOperation === EtatDemande.DECLARE
                                    || etatDemandeInitialOperation === EtatDemande.EN_PRODUCTION
                                    || etatDemandeInitialOperation === EtatDemande.A_SUPPRIMER
                                    || etatDemandeInitialOperation === EtatDemande.SUPPRIME
                                ) &&
                                    <MenuItem key={`etatop-${etatDemandeInitialOperation}`} value={etatDemandeInitialOperation}>
                                        {etatDemandeInitialOperation}
                                    </MenuItem>
                                }

                                {(etatDemandeInitialOperation !== EtatDemande.SUPPRIME) &&
                                    <MenuItem key={`etat-${EtatDemande.EN_PRODUCTION}`} value={EtatDemande.EN_PRODUCTION}>
                                        {EtatDemande.EN_PRODUCTION}
                                    </MenuItem>
                                }

                                {!((operation.hebergeur === Hebergeur.DOCAPOSTE && etatDemandeInitialOperation !== EtatDemande.A_SUPPRIMER)
                                    || etatDemandeInitialOperation === EtatDemande.DECLARE
                                    || etatDemandeInitialOperation === EtatDemande.SUPPRIME
                                ) &&
                                    <MenuItem key={`etat-${EtatDemande.A_SUPPRIMER}`} value={EtatDemande.A_SUPPRIMER}>
                                        {EtatDemande.A_SUPPRIMER}
                                    </MenuItem>
                                }

                                {!((etatDemandeInitialOperation === EtatDemande.EN_PRODUCTION || INSTALLATION_EN_COURS.includes(etatDemandeInitialOperation))
                                    && operation.hebergeur === Hebergeur.OVH
                                ) &&
                                    <MenuItem key={`etat-${EtatDemande.SUPPRIME}`} value={EtatDemande.SUPPRIME}>
                                        {EtatDemande.SUPPRIME}
                                    </MenuItem>
                                }
                            </VoxSelectInput>
                        </Grid>

                        {/* Champ : Type Plateforme */}
                        <Grid item xs={12}>
                            {selectTypePlateforme}
                        </Grid>

                        {/* Champ : Hébergeur */}
                        <Grid item xs={12}>
                            <VoxLabel
                                id={"hebergeur"}
                                startIconLabel={"server"}
                                label={"Hébergeur"}
                            />
                            <Box sx={{minHeight: 46, display: 'flex', flexDirection: "row", alignItems: "center"}}>
                                <Typography component={"p"} variant={"body2"} sx={{fontWeight: 1000}}>
                                    {operation.hebergeur}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Champs : Enregistrements DNS identifiants */}
                        { enregistrementsDnsIdTextInputs }

                        {/* Champ : Description */}
                        <Grid item xs={12}>
                            <VoxTextArea
                                id={"description"}
                                startIconLabel={"comment-dots"}
                                label={"Description"}
                                onChange={handleOnChangeOperationTextValue}
                                multilineMaxRows={3}
                                minRows={3}
                                value={operation.description}
                            />
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Typography component={"label"} variant={'h4'}>
                                Machine Virtuelle liée à l'opération
                            </Typography>
                        </Grid>

                        {/* Champ : vmName */}
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"vmName"}
                                startIconLabel={"laptop"}
                                label={"Nom"}
                                onChange={handleOnChangeVirtualMachineDeOperation}
                                fullWidth
                                value={virtualMachineDeOperation.vmName}
                            />
                        </Grid>


                        {(operation.hebergeur === Hebergeur.OVH) &&
                            <>
                                {/* Champ : Identifiant machine */}
                                <Grid item xs={12}>
                                    <VoxTextField
                                        id={"identifiantMachine"}
                                        startIconLabel={"passport"}
                                        label={"Identifiant machine"}
                                        onChange={handleOnChangeVirtualMachineDeOperation}
                                        fullWidth
                                        value={virtualMachineDeOperation.identifiantMachine}
                                    />
                                </Grid>

                                {/* Champ : Région OVH */}
                                <Grid item xs={12}>
                                    <VoxSelectInput
                                        id={"region"}
                                        idLabel={"region-label"}
                                        startIconLabel={"server"}
                                        label={"Région OVH"}
                                        value={virtualMachineDeOperation.region}
                                        onChange={handleOnChangeRegionOVH}
                                        fullWidth
                                    >
                                        {/* On "mappe" directement sur l'Enum des régions OVH pour les afficher */}
                                        {Object.values(RegionOvh).map((region: RegionOvh) => (
                                            <MenuItem key={region} value={region}>{region}</MenuItem>
                                        ))}
                                    </VoxSelectInput>
                                </Grid>
                            </>
                        }

                        <Grid item xs={12} display={"flex"} justifyContent={"space-evenly"} mt={1}>
                            <VoxButton
                                customColor={"red"}
                                variant={"outlined"}
                                className={"size-button"}
                                sx={{ mb: 2 }}
                                onClick={() => navigate(`/operation/detail/${operation.id}`)}
                            >
                                Annuler
                            </VoxButton>
                            <VoxButton
                                customColor={"green"}
                                className={"size-button"}
                                sx={{ mb: 2 }}
                                type={"submit"}
                            >
                                Valider
                            </VoxButton>
                        </Grid>
                    </Grid>
                </form>

                <ModaleConfirmationSuppressionAvancee
                    show={showModaleConfirmationSuppression}
                    operation={operation}
                    etatDemandePrecedantOperation={props.operation.etatDemande}
                    isAlerteOperationScelleOuVoteEnCours={isAlerteOperationScelleOuVoteEnCours}
                    handleClose={() => setShowModaleConfirmationSuppression(false)}
                    handleSubmit={modificationAvanceeOperation}
                />
                { isLoadingAlerteAvantSuppression ? <VoxLoader isBackgroundColor={true} /> : null }
            </Grid>
        </Grid>
    )
}