import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import './voxLinkInstallation.css';

type VoxLinkInstallationType = {
    startIcon: IconProp
    isDisabledLink?: boolean
    onClick: () => void
    children: string
}

export default function VoxLinkInstallation(props: VoxLinkInstallationType) {

    return (
        <span className={props.isDisabledLink ? "install-link-disabled" : "install-link"} onClick={props.onClick}>
            <FontAwesomeIcon className={"margin-right-demi"} icon={props.startIcon}/>
            {props.children}
        </span>
    )
}