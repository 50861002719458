import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Operation } from "../../../models/operation/operation.model";
import operationService from "../../../services/survox-back/operation/operation.service";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import OperationsModificationAvanceeForm
    from "../../../components/forms/operationsFormulaires/operationsModificationAvanceeForm";
import {
    UpdateAvanceeOperationDto,
    UpdateVMOperationDto,
    UpdateOperationDto
} from "../../../services/survox-back/operation/dtos/request/updateAvanceeOperation.dto";
import { EtatDemande } from "../../../models/operation/etatDemande.enum";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";


export default function OperationsModificationAvancee() {
    const params = useParams();
    const navigate = useNavigate();

    const [operation, setOperation] = useState<Operation>();

    const [isLoadingOperation, setIsLoadingOperation] = useState<boolean>(false);
    const [isLoadingError, setIsLoadingError] = useState<boolean>(false);

    const [isUpdateOperation, setIsUpdateOperation] = useState<boolean>(false);
    const [isUpdateError, setIsUpdateError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");


    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        if (params.id) {
            chargerOperation(Number(params.id), controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [params.id]);

    /**
     * Méthode qui permet de charger l'opération que l'on souhaite modifier
     * @param id
     * @param controller
     */
    const chargerOperation = (id: number, controller: AbortController) => {
        setIsLoadingOperation(true);
        setIsLoadingError(false);
        operationService.getOperation(id, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // récupération de l'opération
                    setOperation(response.data);
                    setIsLoadingOperation(false);
                    setIsLoadingError(false);
                }
            })
            .catch((err) => {
                if (isMountedRef.current && err.message !== "canceled") {
                    setIsLoadingOperation(false);
                    setIsLoadingError(true);
                }
            });
    };

    /**
     * Enregistrement des modifications de l'opération
     */
    const onUpdateOperation = (updateOperationDto: UpdateOperationDto, updateVMOperationDto: UpdateVMOperationDto) => {
        setIsUpdateOperation(true);
        setIsUpdateError(false);
        const updateOperationWithVirtualMachineDto: UpdateAvanceeOperationDto = {...updateOperationDto, ...updateVMOperationDto};

        operationService.miseAJourAvanceeOperation(Number(params.id), updateOperationWithVirtualMachineDto)
            .then((response) => {
                if (response.status === 200 && !response.data.error) {
                    setIsUpdateOperation(false);
                    setIsUpdateError(false);
                    if (
                        operation?.etatDemande === EtatDemande.DECLARE
                        && updateOperationWithVirtualMachineDto.etatDemande === EtatDemande.SUPPRIME
                    ) {
                        navigate("/operations/initialiser");
                    } else {
                        navigate(`/operation/detail/${params.id}`);
                    }
                } else {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                }
            })
            .catch((error) => {
                setIsUpdateOperation(false);
                setIsUpdateError(true);
                if (error.response.status === 400) {
                    setErrorMessage(error.response.data.message[0]);
                } else {
                    setErrorMessage(error.response.data.message);
                }
            })
    }


    return (
        <>

            {(operation && operation.etatDemande === EtatDemande.EN_PRODUCTION) &&
                <VoxAlert severity={"warning"} sx={{mb: 4}}>
                    Vous envisagez d'effectuer des modifications sur une opération en production.
                </VoxAlert>
            }

            <VoxCard title={operation && `Modification d'une opération ${operation.type}`}>

                {
                    // Affichage de l'erreur de chargement des données si il y en a une
                    isLoadingError
                        ? (
                            <VoxAlert severity={"error"}>
                                Une erreur est survenue lors du chargement de l'opération.
                            </VoxAlert>
                        ) : null
                }
                {
                    // Affichage de l'erreur de la modification si il y en a une
                    isUpdateError
                        ? (
                            (errorMessage !== "") ? (
                                <VoxAlert severity={"error"}>
                                    {errorMessage}
                                </VoxAlert>
                            ) : (
                                <VoxAlert severity={"error"}>
                                    Une erreur est survenue lors de l'enregistrement des modifications apportées à
                                    l'opération.
                                </VoxAlert>
                            )
                        ) : null
                }

                {(isLoadingOperation) ?
                    <VoxLoader/>
                    :
                    (operation &&
                        <OperationsModificationAvanceeForm
                            operation={operation}
                            onUpdate={onUpdateOperation}
                        />
                    )
                }
                {
                    isUpdateOperation ? <VoxLoader isBackgroundColor={true}/> : null
                }
            </VoxCard>
        </>
    )
}