import { Operation } from "../../../models/operation/operation.model";
import React, { FormEvent, useState } from "react";
import dayjs from "dayjs";
import { OperationSurveillanceQRadarType, ParamsSurveillanceQRadarType } from "../../../services/survox-back/operation/dtos/request/updateSurveillanceQRadar.dto";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Grid } from "@mui/material";
import { VoxLabel } from "../../genericComponentsUI/input/voxLabel";
import { VoxCalendarInput } from "../../genericComponentsUI/input/voxCalendarInput";
import { VoxLegendChampsObligatoires } from "../../genericComponentsUI/label/voxLegendChampsObligatoires";

type PropsType = {
    operation: Operation,
    onUpdate: (op: OperationSurveillanceQRadarType, params: ParamsSurveillanceQRadarType) => void
}

export default function OperationsModificationSurveillanceQRadarForm(props: PropsType) {

    const [operationSurveillanceQRadar, setOperationSurveillanceQRadar] = useState<OperationSurveillanceQRadarType>({
        dateDebutQRadar: props.operation.dateDebutQRadar,
        dateFinQRadar: props.operation.dateFinQRadar
    });

    const [paramsSurveillanceQRadar, setParamsSurveillanceQRadar] = useState<ParamsSurveillanceQRadarType>({
        isSurveillanceQRadar: (props.operation.dateDebutQRadar !== null && props.operation.dateFinQRadar !== null)
    });

    /**
     * Appel de cette méthode lors du clic sur la checkbox, afin de mettre à jour la valeur du champ 'isModeTest'
     * @param event
     */
    function handleOnChangeTrueFalse(event: any) {
        if (event.target.id === "surveillanceQRadar") {
            setParamsSurveillanceQRadar((prevState: ParamsSurveillanceQRadarType) => ({ ...prevState, isSurveillanceQRadar: event.target.checked }));
            if (!event.target.checked)
                setOperationSurveillanceQRadar((prevState: OperationSurveillanceQRadarType) => ({ ...prevState, dateDebutQRadar: null, dateFinQRadar: null }));
            else
                setOperationSurveillanceQRadar((prevState: OperationSurveillanceQRadarType) => ({ ...prevState, dateDebutQRadar: props.operation.dateDebutQRadar, dateFinQRadar: props.operation.dateFinQRadar }));
        }
    }

    /**
     * Appel de cette méthode dès la modification de la valeur des inputs "dateOuverture" & "dateFermeture" (afin de mettre à jour la valeur)
     * @param id        Id du champ correspondant à un champ de l'objet operation
     * @param date      Date à mettre à jour
     */
    function handleOnChangeDate(id: string, date: Date | undefined) {
        setOperationSurveillanceQRadar((prevState: OperationSurveillanceQRadarType) => ({
            ...prevState,
            [id]: date
        }));
    }

    /**
     * Méthode pour enregistrer les modifications d'une opération Saturne ou Maileva
     */
    const handleOnUpdate = (event: FormEvent) => {
        event.preventDefault();
        if (operationSurveillanceQRadar && paramsSurveillanceQRadar) {
            props.onUpdate(operationSurveillanceQRadar, paramsSurveillanceQRadar);
        }
    }


    return (
        <form onSubmit={handleOnUpdate}>
            <Grid container spacing={3} padding={2}>

                <Grid item xs={12} pb={2} sx={{ display: 'flex', alignItems: 'center'}}>
                    <VoxLabel
                        id={"surveillanceQRadar"}
                        startIconLabel={'satellite-dish'}
                        label={"Surveillance QRadar"}
                    />
                    <Checkbox
                        id="surveillanceQRadar"
                        aria-label="Surveillance QRadar" sx={{ marginLeft: 2 }}
                        onChange={handleOnChangeTrueFalse}
                        checked={paramsSurveillanceQRadar.isSurveillanceQRadar} />
                </Grid>

                {(paramsSurveillanceQRadar.isSurveillanceQRadar) ? (
                    <>
                        <Grid item xs={12}>
                            <VoxLegendChampsObligatoires/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <VoxCalendarInput
                                id={"dateDebutQRadar"}
                                startIconLabel={"calendar-alt"}
                                label={"Début de la surveillance"}
                                onChange={handleOnChangeDate}
                                minDate={new Date()}
                                maxDate={operationSurveillanceQRadar.dateFinQRadar ? new Date(dayjs(operationSurveillanceQRadar.dateFinQRadar).subtract(1, "day").format("YYYY-MM-DD")) : undefined}
                                fullWidth
                                required
                                value={operationSurveillanceQRadar.dateDebutQRadar !== null && operationSurveillanceQRadar.dateDebutQRadar !== undefined ? new Date(dayjs(operationSurveillanceQRadar.dateDebutQRadar).format("YYYY-MM-DD")) : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <VoxCalendarInput
                                id={"dateFinQRadar"}
                                startIconLabel={"calendar-alt"}
                                label={"Fin de la surveillance"}
                                onChange={handleOnChangeDate}
                                minDate={operationSurveillanceQRadar.dateDebutQRadar ? new Date(dayjs(operationSurveillanceQRadar.dateDebutQRadar).add(1, "day").format("YYYY-MM-DD")) : undefined}
                                fullWidth
                                required
                                value={operationSurveillanceQRadar.dateFinQRadar !== null && operationSurveillanceQRadar.dateFinQRadar !== undefined ? new Date(dayjs(operationSurveillanceQRadar.dateFinQRadar).format("YYYY-MM-DD")) : undefined}
                            />
                        </Grid>
                    </>
                    ) : null
                }

                <Grid item xs={12} display={"flex"} justifyContent={"center"} mt={1}>
                    <VoxButton
                        customColor={"green"}
                        sx={{ fontSize: '0.95rem', py: 2.8 }}
                        startIcon={<FontAwesomeIcon icon={"save"} />}
                        type={"submit"}
                    >
                        Valider
                    </VoxButton>
                </Grid>
            </Grid>
        </form>
    )
}