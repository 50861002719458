import { ReactNode } from "react";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import { Box } from "@mui/material";

type TabContentPropsType = {
    isLoading: boolean;
    isLoadError: boolean;
    messageError: string;
    children?: ReactNode;
}

/**
 * Composant qui permet d'afficher le contenu d'un onglet MUI
 */
export default function TabContent(props: TabContentPropsType) {

    if (props.isLoading) {
        return (
            <Box position={"relative"} sx={{ height: 100 }}>
                <VoxLoader />
            </Box>
        );
    }

    if (props.isLoadError) {
        return (
            <VoxAlert severity={"error"}>
                {props.messageError}
            </VoxAlert>
        );
    }

    return (
        <>{ props.children }</>
    );
}