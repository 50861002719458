import { Popover, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Article } from "../../../models/operation/article.model";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import "./facturationPopover.css"

const tableSx = {
    border: 'solid 1px #dfe0e1',
    ".MuiTableCell-root": {
        padding: "5px 10px",
        height: "38px"
    },
    ".MuiTableCell-head": {
        height: "45px"
    },
}
const cellsSx = {
    borderLeft: 'solid 1px #dfe0e1'
};

const popoverSx = {
    '.MuiPopover-paper': {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        maxWidth: '800px',
        border: 'solid 1px #dfe0e1',
        borderRadius: '8px',
    },
    '.MuiPopover-paper:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '50%',
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translate(-50%,-50%) rotate(45deg)',
        zIndex: 0,
        borderTop: 'solid 1px #dfe0e1',
        borderLeft: 'solid 1px #dfe0e1',
        backgroundColor: '#f8f9fa',
    }
}

type FacturationPopoverPropsType = {
    id: string | undefined,
    isLoadingArticles: boolean | undefined,
    open: boolean,
    onClose: () => void,
    anchorEl: any,
    typeOperation: TypeOperation | string,
    isErrorArticles: boolean | undefined,
    articles: Article[] | undefined
}

export default function FacturationPopover(props: FacturationPopoverPropsType) {

    return (
        <Popover id={props.id} className={"popover-articles"} open={props.open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={popoverSx}
            onClose={props.onClose}
            anchorEl={props.anchorEl}

        >
            <Typography variant="h4" className="popover-header">Tarifs {dayjs().format("YYYY")}</Typography>

            <div className={"popover-body"}>

                {(props.typeOperation === 'all') &&
                    <Typography variant="subtitle1" pb={2}>Applicable pour les opérations Saturne / Maileva uniquement</Typography>
                }

                {
                    props.isLoadingArticles ? (
                        <CircularProgress />
                    ) : (
                        props.isErrorArticles ? (
                            <VoxAlert severity={"error"}>
                                Une erreur est survenue pour charger les tarifs
                            </VoxAlert>

                        ) : (
                            <TableContainer>
                                <Table
                                    sx={tableSx}
                                    aria-label="simple table">
                                    <TableHead >
                                        <TableRow sx={{
                                            backgroundColor: '#f8f9fa',
                                        }}>
                                            <TableCell>
                                               <Typography component={"span"} variant="h4">Article</Typography>
                                            </TableCell>
                                            <TableCell sx={cellsSx}>
                                               <Typography component={"span"} variant="h4">Tarif</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props?.articles?.map((article) => (
                                            <TableRow key={article.id}>
                                                <TableCell>
                                                    <Typography variant="body1">{article.typeArticle}</Typography> 
                                                </TableCell>
                                                <TableCell sx={cellsSx}>
                                                    <Typography variant="body1">{article.tarif} €</Typography> 
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    )
                }
            </div>
        </Popover>
    )
}