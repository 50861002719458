import React, { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { Link as RouterLink, To } from "react-router-dom";

type VoxLinkOperationPropsType = {
    isAuthorized?: boolean
    href: To
    children: string
}

export default function VoxLinkOperation(props: VoxLinkOperationPropsType) {

    const [isLinkAuthorized, setIsLinkAuthorized] = useState<boolean>(false);

    useEffect(() => {
        if (props.isAuthorized === undefined) {
            setIsLinkAuthorized(true);
        } else {
            setIsLinkAuthorized(props.isAuthorized);
        }
    }, [props.isAuthorized]);


    return (
        <Link
            variant={"body2"}
            component={RouterLink}
            to={(isLinkAuthorized) ? props.href : ""}
            sx={{
                textDecoration: 'none',
                color: (isLinkAuthorized) ? "cobalt.bleuDigital100" : "cobalt.lynch",
                cursor: (isLinkAuthorized) ? "pointer" : "unset",
                "&:hover" : {
                    color: (isLinkAuthorized) ? "cobalt.hover" : "cobalt.lynch",
                    cursor: (isLinkAuthorized) ?  "pointer" : "unset",
                }
            }}
        >
            {props.children}
        </Link>
    )
}