import {SIRSOperation} from "../../../models/operationSIRS/sirsOperation.model";
import {useMemo} from "react";
import {Surveillance} from "../../../models/surveillance/surveillance.model";
import {affichageDateWithFormatDMYHm} from "../../../utils/string.utils";
import {CellProps, Column, IdType, Row} from "react-table";
import {TypeSurveillance} from "../../../models/surveillance/typeSurveillance.enum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ResultatSurveillance} from "../../../models/surveillance/resultatSurveillance.enum";
import {sortDatesInTables} from "../../../utils/date.utils";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";

type PropsType = {
    operationSIRS: SIRSOperation;
}

type SurveillanceOperationSIRSTableau = {
    id: number;
    checkTime: Date;
    typeSurveillance: string;
    resultatSurveillance: ResultatSurveillance | string;
    codeHttp: number;
    tempsDeReponse: number;
    erreur: string;
}


export default function OperationsSIRSDetailSurveillance(props: PropsType) {

    /**
     * Remplissage des lignes du tableau
     */
    const rows: SurveillanceOperationSIRSTableau[] = useMemo(() => {
        if (props.operationSIRS.traces) {
            return props.operationSIRS.traces.map(
                (surveillance: Surveillance) => ({
                    // Accessors
                    id: surveillance.id,
                    checkTime: surveillance.checkTime,
                    typeSurveillance: surveillance.typeSurveillance,
                    resultatSurveillance: surveillance.resultatSurveillance,
                    codeHttp: surveillance.codeHttp,
                    tempsDeReponse: surveillance.tempsDeReponse,
                    erreur: surveillance.erreur
                })
            )
        } else {
            return [];
        }
    }, [props.operationSIRS])

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<SurveillanceOperationSIRSTableau>[] = useMemo(() => [
        {
            Header: 'Date de vérification',
            id: 'checkTime',
            accessor: (originalRow: SurveillanceOperationSIRSTableau) => affichageDateWithFormatDMYHm(originalRow.checkTime),
            minWidth: 160,
            sortType: (a: Row<SurveillanceOperationSIRSTableau>,
                       b: Row<SurveillanceOperationSIRSTableau>,
                       columnId: IdType<SurveillanceOperationSIRSTableau>,
                       desc: boolean | undefined) => sortDatesInTables(a.original.checkTime, b.original.checkTime, desc)
        },
        {
            Header: 'Type',
            accessor: 'typeSurveillance',
            minWidth: 190
        },
        {
            Header: 'Résultat',
            accessor: 'resultatSurveillance',
            minWidth: 160,
            disableFilters: true,
            Cell: (props: CellProps<SurveillanceOperationSIRSTableau>) => {
                const surveillance: SurveillanceOperationSIRSTableau = props.row.original;

                if (surveillance.typeSurveillance === TypeSurveillance.TEST_SITE && surveillance.resultatSurveillance !== null) {
                    return (
                        <FontAwesomeIcon
                            // ICÔNE = Éclair si KO (ou erreur certificat), sinon Soleil
                            icon={
                                (surveillance.resultatSurveillance === ResultatSurveillance.KO) ? 'bolt' :
                                    ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT) ? 'bolt' : 'sun')
                            }
                            // TAILLE
                            size={'2x'}
                            // COULEUR = Jaune (#FFC300) si Erreur Certificat, sinon undefined
                            color={
                                (surveillance.resultatSurveillance === ResultatSurveillance.KO) ? undefined :
                                    ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT) ? '#FFC300' : undefined)
                            }
                            // TITLE
                            title={`Test Site : ${surveillance.resultatSurveillance}`}
                        />
                    )
                } else if (surveillance.typeSurveillance === TypeSurveillance.INFORMATION && surveillance.resultatSurveillance !== null) {
                    return (
                        <FontAwesomeIcon
                            // ICÔNE = Croix si KO (ou erreur certificat), sinon Check
                            icon={
                                (surveillance.resultatSurveillance === ResultatSurveillance.KO) ? 'times-circle' :
                                    ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT) ? 'times-circle' : 'check-circle')
                            }
                            // TAILLE
                            size={'2x'}
                            // COULEUR = Jaune (#FFC300) si Erreur Certificat, Rouge (#F10000) si KO, sinon vert
                            color={
                                (surveillance.resultatSurveillance === ResultatSurveillance.KO) ? "#F10000" :
                                    ((surveillance.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT) ? '#FFC300' : "green")
                            }
                            // TITLE
                            title={`Information Site : ${surveillance.resultatSurveillance}`}
                        />
                    )
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Code HTTP',
            accessor: 'codeHttp',
            minWidth: 100,
        },
        {
            Header: 'Temps de réponse (en ms)',
            accessor: 'tempsDeReponse',
            minWidth: 170,
        },
        {
            Header: "Message d'erreur",
            accessor: 'erreur',
            minWidth: 170,
        }
    ], [])


    return (
        <VoxTable
            data={rows}
            columns={columns}
            // Tri par défaut (date de vérification, descendant)
            initialStateSortBy={[{id: 'checkTime', desc: true}]}
            // Surcharge de l'alignement du texte
            cellProps={(cell: CellProps<SurveillanceOperationSIRSTableau>) => {
                if (cell.column.id === "erreur") {
                    return {
                        style: {textAlign: "left"}
                    }
                }
            }}
        />
    )

}