import './dataCounter.css';
import {Link} from "react-router-dom";

type dataCounterType = {
    value: number,
    name: string,
    link: string
}

/**
 * Mini composant qui permet d'afficher les compteurs du Dashboard
 */
export default function DataCounter(props: dataCounterType) {
    return (
        <div className="stats">
            <Link to={props.link}>
                <span>{props.value}</span>{props.name}
            </Link>
        </div>
    );
}