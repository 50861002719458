import axios_instance from "../axios-client";
import { EditUrlsSpecifiquesDto } from "./dtos/request/editUrlsSpecifiques.dto";

const urlSpecifiqueService =  {

    getUrlsSpecifiquesByOperation: (operationId: number, signal?: AbortSignal) => {
        return axios_instance.get(`/urls_specifiques/par_operation/${operationId}`, {signal: signal});
    },

    editUrlsSpecifiques: (
        operationId: number,
        editUrlsSpecifiquesDto: EditUrlsSpecifiquesDto
    ) => {
        return axios_instance.put(
            `urls_specifiques/par_operation/${operationId}`,
            editUrlsSpecifiquesDto
        );
    },
}

export default urlSpecifiqueService;