import {Navigate, Route, Routes} from "react-router-dom";
import OperationsAInitialiserListe from "../../components/operationsListe/operationsAInitialiserListe";
import {TypeOperation} from "../../models/operation/typeOperation.enum";
import OperationsModification from "../../components/forms/operationsFormulaires/operationsModification";
import React from "react";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsMailevaAInitialiserView() {
    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Opérations MailEva à initialiser"}/>
                    <OperationsAInitialiserListe typeOperation={TypeOperation.MAILEVA}/>
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/maileva/initialiser"}/>
                    <OperationsModification typesOperation={[TypeOperation.MAILEVA]}/>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}