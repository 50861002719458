import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import React from "react";
import {ListItemIcon, MenuItem} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { Link as RouterLink } from "react-router-dom";

type NavbarDropdownItemPropsType = {
    icon?: IconProp,
    title: string,
    href: string,
    onClick: () => void,
    groupes: NomGroupe[]
}

export default function NavbarDropdownItem(props: NavbarDropdownItemPropsType) {

    return (
        <AuthContext.Consumer>
            {value => {

                let utilisateurIsAuthorized = false;

                if (value.utilisateur && (props.groupes.length === 0 || props.groupes?.includes(value.utilisateur.groupe?.nom))) {
                    utilisateurIsAuthorized = true;
                }

                if (utilisateurIsAuthorized) {
                    return (
                        <MenuItem onClick={props.onClick} component={RouterLink} to={props.href} sx={{minHeight: 'initial'}}>
                            <ListItemIcon sx={{minWidth: '25px !important', marginTop: '2px'}}>
                                {props.icon &&
                                        <FontAwesomeIcon icon={props.icon} size={"xs"}/>
                                }
                            </ListItemIcon>
                            {props.title}
                        </MenuItem>
                    )
                }
            return null
            }}
        </AuthContext.Consumer>
    )

}