import axios_instance from "../axios-client";

const facturationService =  {

    // Index Facturation
    indexFacturationSaturne: (signal?: AbortSignal) => {
        return axios_instance.get('/facturation/saturne', { signal });
    },
    indexFacturationMaileva: (signal?: AbortSignal) => {
        return axios_instance.get('/facturation/maileva', { signal });
    },
    indexFacturationGlobale: (signal?: AbortSignal) => {
        return axios_instance.get('/facturation/globale', { signal });
    },

    // Extraction de la facturation
    extrairePourFacturationSaturne: () => {
        return axios_instance.get('/facturation/extraireSaturne');
    },
    extrairePourFacturationMaileva: () => {
        return axios_instance.get('/facturation/extraireMaileva');
    },
    extrairePourFacturation: () => {
        return axios_instance.get('/facturation/extraire');
    },

    // Téléchargement du fichier
    telechargementFichierFacturationById: (id: number) => {
        return axios_instance.get(`/facturation/telechargementFichier/${id}`, );
    },

    // Historique (10 derniers fichiers générés)
    getHistoriqueFacturationSaturne: (signal?: AbortSignal) => {
        return axios_instance.get('/facturation/historique_saturne', { signal });
    },
    getHistoriqueFacturationMaileva: (signal?: AbortSignal) => {
        return axios_instance.get('/facturation/historique_maileva', { signal });
    },
    getHistoriqueFacturationGlobale: (signal?: AbortSignal) => {
        return axios_instance.get('/facturation/historique_global', { signal });
    },

}

export default facturationService;