import axios_instance from "../axios-client";

const favoriService = {

    ajoutOperationFavorisUtilisateur: (operationId: number) => {
        return axios_instance.post(`/favoris/operation/${operationId}`);
    },

    suppressionOperationFavorisUtilisateur: (operationId: number) => {
        return axios_instance.delete(`/favoris/operation/${operationId}`);
    }

}

export default favoriService;