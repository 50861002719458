import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import operationSIRSService from "../../services/survox-back/operationSIRS/operationSIRS.service";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import { SIRSOperation } from "../../models/operationSIRS/sirsOperation.model";
import { CellProps, Column, IdType, Row } from "react-table";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import { affichageDateWithFormatDMYHm, affichageLibelleEtatDemande } from "../../utils/string.utils";
import { TypePrestation } from "../../models/operationSIRS/typePrestation.enum";
import { useNavigate } from "react-router-dom";
import ModaleConfirmationInstallationDocaposteSIRS from "../modals/modaleConfirmationInstallationDocaposteSIRS";
import { sortDatesInTables } from "../../utils/date.utils";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import { Box } from "@mui/material";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import VoxTable from "../genericComponentsUI/table/voxTable";
import VoxLinkInstallation from "../genericComponentsUI/link/voxLinkInstallation";
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";

/* Type des opérations SIRS à initialiser récupérées depuis survox-back
 * -> La propriété isInstalling est utilisée pour savoir si l'utilisateur a déclenché l'installation
 */
type OperationsSIRSAInitialiser = {
    id: number;
    nomClient: string;
    url: string;
    etatDemande: EtatDemande;
    typePrestation: TypePrestation;
    dateInstallation: Date;
    isInstalling: boolean;
}

// Type des objets utilisés dans le tableau correspondant aux informations des opérations SIRS à afficher
type OperationsSIRSAInitialiserTableau = {
    id: number;
    nomClient: string;
    url: string;
    etatDemande: EtatDemande;
    typePrestation: TypePrestation;
    dateInstallation: Date;
    loadingInstallation: boolean;
}


export default function OperationsSIRSAInitialiserListe() {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    const navigate = useNavigate();

    // Utilisation d'une ref pour connaître l'état du composant éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [isInstalling, setIsInstalling] = useState(false);
    const [operationsSIRSAInitialiser, setOperationsSIRSAInitialiser] = useState<OperationsSIRSAInitialiser[]>([]);
    const [selectedOperationSIRSId, setSelectedOperationSIRSId] = useState<number | undefined>();
    const [showConfirmationDocaposteModale, setShowConfirmationDocaposteModale] = useState(false);


    /**
     * Charger les opérations SIRS à l'état déclaré
     */
    const chargerOperationsSIRSAInitialiser = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        operationSIRSService.getOperationsSIRSAInitialiser(controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const operationsSIRSAInitialiserRecuperees: OperationsSIRSAInitialiser[] = response.data.map((operationSIRS: SIRSOperation) => {
                        return {...operationSIRS, isInstalling: false}
                    });
                    setOperationsSIRSAInitialiser(operationsSIRSAInitialiserRecuperees);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                }
            })
    }, [])


    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        chargerOperationsSIRSAInitialiser(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [chargerOperationsSIRSAInitialiser]);


    /**
     * Méthode qui permet de changer la valeur de l'attribut isInstalling pour une opération SIRS donnée
     * @param operationsSIRS, un tableau d'opérations
     * @param operationId, l'Id de l'opération
     * @param value, la valeur (true ou false)
     */
    const changeIsInstallingOperationSIRS = function (operationsSIRS: OperationsSIRSAInitialiser[], operationId: number, value: boolean) {
        return operationsSIRS.map(operationSIRS => {
            if (operationSIRS.id === operationId) {
                operationSIRS.isInstalling = value;
            }
            return operationSIRS;
        });
    }

    /**
     * Ouverture d'une pop-up de confirmation d'installation chez Docaposte (passage en prod)
     */
    const handleOperationSIRSPopupConfirmationDocaposte = useCallback((operationSIRSId: number) => {
        setSelectedOperationSIRSId(operationSIRSId);
        setShowConfirmationDocaposteModale(true);
    }, []);

    /**
     * Méthode déclenchée lorsque l'utilisateur clique sur le bouton pour passer l'opération SIRS en production
     * chez l'hébergeur Docaposte dans le tableau des opérations SIRS à initialiser
     */
    const handleEnProductionDocaposteSIRS = useCallback(() => {
        if (selectedOperationSIRSId) {
            setIsInstalling(true);
            setOperationsSIRSAInitialiser(changeIsInstallingOperationSIRS(operationsSIRSAInitialiser, selectedOperationSIRSId, true));

            operationSIRSService.updateOperationSIRSEnProductionDocaposte(selectedOperationSIRSId)
                .finally(() => {
                    setIsInstalling(false);
                    setShowConfirmationDocaposteModale(false);
                    setSelectedOperationSIRSId(undefined);
                    chargerOperationsSIRSAInitialiser();
                });
        }
    }, [operationsSIRSAInitialiser, chargerOperationsSIRSAInitialiser, selectedOperationSIRSId])


    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationsSIRSAInitialiserTableau[] = useMemo(() => {
        return operationsSIRSAInitialiser.map(
            (operationSIRS: OperationsSIRSAInitialiser) => ({
                id: operationSIRS.id,
                nomClient: operationSIRS.nomClient,
                url: operationSIRS.url,
                etatDemande: operationSIRS.etatDemande,
                loadingInstallation: operationSIRS.isInstalling,
                typePrestation: operationSIRS.typePrestation,
                dateInstallation: operationSIRS.dateInstallation
            })
        )
    }, [operationsSIRSAInitialiser]);

    /**
     * Remplissage des cellules du tableau
     */
    const cellNomClient = useCallback((props: CellProps<OperationsSIRSAInitialiserTableau>) => {
        return (
            <VoxLinkOperation href={`/sirs/detail/${props.row.original.id}`}>{props.value}</VoxLinkOperation>
        )
    }, []);

    const cellUrl = useCallback((props: CellProps<OperationsSIRSAInitialiserTableau>) => {
        return (
            <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
        )
    }, []);

    const cellEtatInstallation = useCallback((props: CellProps<OperationsSIRSAInitialiserTableau>) => {
        // VoxLoader affiché au clic sur le bouton "Confirmer" de la pop-in de confirmation Docaposte
        if (props.row.original.loadingInstallation) {
            return (
                <Box sx={{position: 'relative'}}>
                    <VoxLoader size={20}/>
                </Box>
            );

            // Si ADMIN ou INFRA → possibilité de passer en production
        } else if ([NomGroupe.ADMINISTRATEUR, NomGroupe.SUPPORT_SI_INFRA].includes(utilisateur.groupe.nom)) {
            // Ouverture de la pop-in de confirmation (onClick) :
            return (
                <VoxLinkInstallation
                    startIcon={"hand-point-right"}
                    onClick={() => handleOperationSIRSPopupConfirmationDocaposte(props.row.original.id)}
                >
                    Passer en production (Docaposte)
                </VoxLinkInstallation>
            )

            // Si autre groupe utilisateur → l'état de la demande est affiché
        } else {
            return (
                <>
                    {affichageLibelleEtatDemande(props.row.original.etatDemande)}
                </>
            )
        }
    }, [utilisateur.groupe.nom, handleOperationSIRSPopupConfirmationDocaposte]);

    /**
     * Actions possibles
     */
    const cellActions = useCallback((props: CellProps<OperationsSIRSAInitialiserTableau>) => {
        return (
            <Box display={"flex"} justifyContent={"center"} gap={1}>

                {/* Oeil → Détail de l'opération */}
                <VoxIconAction
                    tooltip={"Consulter la fiche de l'opération"}
                    icon={"eye"}
                    onClick={() => navigate(`/sirs/detail/${props.row.original.id}`)}
                />

                {/* Crayon → Modification de l'opération */}
                {(props.row.original.etatDemande === EtatDemande.DECLARE &&
                        (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                            utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA ||
                            utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_SIRS)) &&
                    <VoxIconAction
                        tooltip={"Modifier l'opération"}
                        icon={"edit"}
                        onClick={() => navigate(`/sirs/modification/${props.row.original.id}`)}
                    />
                }

            </Box>
        )
    }, [utilisateur.groupe.nom, navigate]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationsSIRSAInitialiserTableau>[] = useMemo(() => [
        {
            Header: 'Nom du client',
            accessor: 'nomClient',
            minWidth: 120,
            Cell: cellNomClient
        },
        {
            Header: 'Site',
            accessor: 'url',
            minWidth: 210,
            Cell: cellUrl
        },
        {
            Header: "État d'installation",
            accessor: 'etatDemande',
            minWidth: 190,
            Cell: cellEtatInstallation
        },
        {
            Header: 'Type de prestation',
            accessor: 'typePrestation',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 120
        },
        {
            Header: "Date d'installation",
            id: 'dateInstallation',
            accessor: (originalRow: OperationsSIRSAInitialiserTableau) => affichageDateWithFormatDMYHm(originalRow.dateInstallation),
            sortType: (a: Row<OperationsSIRSAInitialiserTableau>,
                       b: Row<OperationsSIRSAInitialiserTableau>,
                       columnId: IdType<OperationsSIRSAInitialiserTableau>,
                       desc: boolean | undefined) => sortDatesInTables(a.original.dateInstallation, b.original.dateInstallation, desc),
            minWidth: 120
        },
        {
            Header: 'Actions à effectuer',
            id: 'actions',
            align: 'center',
            flexGrow: 0.5,
            disableFilters: true,
            disableSortBy: true,
            Cell: cellActions
        },
    ], [cellNomClient, cellUrl, cellEtatInstallation, cellActions]);


    return (
        <>
            {isLoading ? <VoxLoader positionRelative/> :
                <>
                    {/* Tableau */}
                    <VoxTable
                        data={rows}
                        columns={columns}
                    />

                    {/* Pop-up Installation Docaposte */}
                    <ModaleConfirmationInstallationDocaposteSIRS
                        show={showConfirmationDocaposteModale}
                        handleClose={() => {
                            setShowConfirmationDocaposteModale(false);
                            setSelectedOperationSIRSId(undefined);
                        }}
                        handleSubmit={handleEnProductionDocaposteSIRS}
                        isInstalling={isInstalling}
                    />
                </>
            }
        </>
    )
}