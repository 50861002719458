export enum TypeEnregistrementDNS {
    UNIQUE = "UNIQUE",
    ADMIN = "ADMIN",
    ELECTEUR = "ELECTEUR"
}

/**
 * Fonction permettant de récupérer le libellé à afficher pour un type d'enregistrement
 * @param type la valeur du type d'enregistrement (UNIQUE, ADMIN, ELECTEUR)
 * @returns une string représentant le libellé correspondant
 */
export const getDisplayValueFromTypeEnregistrementDNS = (type: TypeEnregistrementDNS): string => {
    switch(type) {
        case TypeEnregistrementDNS.ADMIN: 
            return "Identifiant enregistrement DNS : Admin";
        case TypeEnregistrementDNS.ELECTEUR:
            return "Identifiant enregistrement DNS : Électeur";
        case TypeEnregistrementDNS.UNIQUE: 
            return "Identifiant enregistrement DNS unique"
    }
}