import React, { useContext } from "react";
import { ParametresContext } from "../../../contexts/parametrage/parametres.context";
import { Parametrage } from "../../../models/parametrage/parametrage.model";
import { TypeParametre } from "../../../models/parametrage/typeParametre.enum";
import { ValeurBooleenneParametrage } from "../../../models/parametrage/valeurBooleenneParametrage.enum";
import { VoxAlert } from "./voxAlert";

export default function BandeauAlerteMaintenance() {

    const parametresContext = useContext(ParametresContext);

    // Récupération du paramétrage présent dans le contexte de maintenance pour le blocage de l'installation de VM
    const parametrageBlocageSitesVoxcoreElection = parametresContext.parametrages.find((parametrage: Parametrage) => {
        return (parametrage.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_ELECTION)
    });
    const parametrageBlocageSitesVoxcoreAG = parametresContext.parametrages.find((parametrage: Parametrage) => {
        return (parametrage.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_AG)
    });

    if ((parametrageBlocageSitesVoxcoreElection !== undefined && parametrageBlocageSitesVoxcoreAG !== undefined) || (!parametresContext.isErrorParametrages && parametresContext.isLoadingParametrages)) {
        if (parametrageBlocageSitesVoxcoreElection?.valeur === ValeurBooleenneParametrage.TRUE || parametrageBlocageSitesVoxcoreAG?.valeur === ValeurBooleenneParametrage.TRUE) {
            return (
                <VoxAlert severity={"info"} sx={{mb: 5}}>
                    Maintenance en cours. Toutes les fonctionnalités ne sont pas accessibles.
                </VoxAlert>
            )
        } else {
            // Pas de maintenance sur l'application
            return null;
        }
    } else {
        // Ne doit pas arriver, les paramètres doivent toujours être trouvés dans le contexte de maintenance
        return (
            <VoxAlert severity={"error"} sx={{mb: 5}}>
                Une erreur est survenue lors de la vérification des paramètres de maintenance !
            </VoxAlert>
        )
    }
}