import {MouseEvent, useContext, useEffect, useState} from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import NavbarMenuItem from "./navbarMenuItem";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import authService from "../../../services/survox-back/auth.service";
import {ReactComponent as LogoVoxaly} from '../../../assets/logo_VOXALY.svg';
import {
    AppBar,
    Backdrop,
    Button,
    Container,
    Fade,
    IconButton,
    Link,
    Menu,
    Popover,
    Toolbar,
    Typography
} from "@mui/material"
import Box from "@mui/material/Box";
import NavbarDropdownItem from "./navbarDropdownItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './navbar.css';
import VoxConditionalWrapper from "../wrapper/voxConditionalWrapper";
import VoxButton from "../../genericComponentsUI/button/voxButton";

export default function Navbar() {

    // Navigation
    const navigate = useNavigate();

    // État de l'utilisateur connecté
    const authContext = useContext(AuthContext);

    // Déconnexion
    const handleOnLogout = () => {
        authService.logout()
            .then(() => {
                authContext.logout();
            })
            .catch(() => {
            });
    };


    // [AppBar MUI] States
    const [isOpenOnMobile, setIsOpenOnMobile] = useState<boolean>(false);
    const [anchorItemOperations, setAnchorItemOperations] = useState<null | HTMLElement>(null);
    const [anchorItemSaturne, setAnchorItemSaturne] = useState<null | HTMLElement>(null);
    const [anchorItemMailEva, setAnchorItemMailEva] = useState<null | HTMLElement>(null);
    const [anchorItemSIRS, setAnchorItemSIRS] = useState<null | HTMLElement>(null);
    const [anchorItemAdministration, setAnchorItemAdministration] = useState<null | HTMLElement>(null);

    // [AppBar MUI] Clic sur un lien du menu
    const handleClickMenuItem = () => {
        handleCloseMenuItem();
        setIsOpenOnMobile(false);
    }

    // [AppBar MUI] Fermeture des menus affichés
    const handleCloseMenuItem = () => {
        setAnchorItemOperations(null);
        setAnchorItemSaturne(null);
        setAnchorItemMailEva(null);
        setAnchorItemSIRS(null);
        setAnchorItemAdministration(null);
    }

    // [AppBar MUI] Gestion du responsive lors du resize du viewport
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1200) {
                handleCloseMenuItem();
                setIsOpenOnMobile(false);
            }
        }
        window.addEventListener('resize', handleResize);
        // Clean Up
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    // [Popover MUI] State
    const [anchorElPopover, setAnchorElPopover] = useState<HTMLButtonElement | null>(null);

    // [Popover MUI] Ouverture/Fermeture
    const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
        handleCloseMenuItem();
        setAnchorElPopover(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorElPopover(null);
    }

    // [Popover MUI] Gestion de l'ID
    const openPopover = Boolean(anchorElPopover);
    const idPopover = openPopover ? 'simple-popover' : undefined;

    // [Popover MUI] Gestion de l'affichage selon responsive
    const popoverAnchorOriginVertical = () => {
        if (isOpenOnMobile) {
            return 'top';
        } else {
            return 'bottom';
        }
    }
    const popoverTransformOriginVertical = () => {
        if (isOpenOnMobile) {
            return 'bottom';
        } else {
            return 'top';
        }
    }


    return (
        <AppBar position={"static"} className={"nav-bar-color"} sx={{boxShadow: 2}}>
            <Container sx={{maxWidth: '100% !important'}}>
                <Toolbar className={"survox-navbar"} disableGutters={true} sx={{display: 'flex'}}>

                    {/* LOGO VOXALY */}
                    <RouterLink to={"/"}>
                        <LogoVoxaly className={"logo-style"}/>
                    </RouterLink>

                    {/* MENU-BURGER ICON (RESPONSIVE) */}
                    <IconButton
                        size={"large"}
                        aria-label={"Ouvrir/Fermer le menu de navigation"}
                        aria-controls={"menu-appbar"}
                        aria-expanded={"false"}
                        onClick={() => setIsOpenOnMobile(!isOpenOnMobile)}
                        sx={{
                            display: {xs: 'flex', md: 'none'},
                            order: '20',
                            marginLeft: 'auto',
                            minWidth: 45,
                            zIndex: 'inherit'
                        }}
                    >
                        <FontAwesomeIcon icon={isOpenOnMobile ? "times" : "bars"}/>
                    </IconButton>

                    {/* ITEMS NAVBAR */}
                    <VoxConditionalWrapper
                        condition={isOpenOnMobile}
                        wrapper={children => (
                            <Backdrop
                                open={isOpenOnMobile}
                                onClick={() => {
                                    handleCloseMenuItem();
                                    setIsOpenOnMobile(false);
                                }}
                            >
                                {children}
                            </Backdrop>
                        )}
                    >
                        <Box
                            className={"survox-nav-all-items"}
                            onClick={(e: any) => e.stopPropagation()}
                            sx={{
                                display: 'flex',
                                flexDirection: {xs: 'column', md: 'row'},
                                alignItems: {xs: 'initial', md: 'center'},
                                flexGrow: 1,
                                transform: isOpenOnMobile ? 'translateX(0) !important' : ''
                            }}
                        >

                            {/* ACCUEIL */}
                            <NavbarMenuItem groupes={[
                                NomGroupe.ADMINISTRATEUR,
                                NomGroupe.SUPPORT_SI_INFRA,
                                NomGroupe.SUPPORT_SI_DEV,
                                NomGroupe.SUPPORT_SI_DEV_SIRS,
                                NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                NomGroupe.CHEF_DE_PROJET_VOTE,
                                NomGroupe.CHEF_DE_PROJET_SIRS,
                                NomGroupe.OBSERVATEUR,
                                NomGroupe.GESTIONNAIRE_DE_COMPTE
                            ]}>
                                <Button
                                    variant={"text"}
                                    component={RouterLink}
                                    className={"survox-nav-item"}
                                    to={"/"}
                                >
                                    Accueil
                                </Button>
                            </NavbarMenuItem>

                            {/* MENU "OPÉRATIONS" */}
                            {
                                authContext.authenticated && !authContext.utilisateur.mdpAModifier ?
                                    (
                                        <NavbarMenuItem groupes={[
                                            NomGroupe.ADMINISTRATEUR,
                                            NomGroupe.SUPPORT_SI_INFRA,
                                            NomGroupe.SUPPORT_SI_DEV,
                                            NomGroupe.SUPPORT_SI_DEV_SIRS,
                                            NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                            NomGroupe.CHEF_DE_PROJET_VOTE,
                                            NomGroupe.CHEF_DE_PROJET_SIRS,
                                            NomGroupe.OBSERVATEUR
                                        ]}>
                                            <>
                                                <Button
                                                    variant={"text"}
                                                    className={"survox-nav-item dropdown"}
                                                    onClick={e => {
                                                        handleCloseMenuItem();
                                                        setAnchorItemOperations(e.currentTarget);
                                                    }}
                                                >
                                                    Opérations
                                                </Button>
                                                <Menu
                                                    id={"menu-operations"}
                                                    anchorEl={anchorItemOperations}
                                                    open={Boolean(anchorItemOperations)}
                                                    onClose={handleCloseMenuItem}
                                                    onClick={handleCloseMenuItem}
                                                    elevation={1}
                                                    TransitionComponent={Fade}
                                                    disablePortal={!isOpenOnMobile}
                                                    hideBackdrop={!isOpenOnMobile}
                                                >

                                                    {/* Opérations > Opérations en production */}
                                                    <NavbarDropdownItem
                                                        icon={"play-circle"}
                                                        title={"Opérations en production"}
                                                        href={"/operations/production"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE,
                                                            NomGroupe.OBSERVATEUR
                                                        ]}
                                                    />

                                                    {/* Opérations > Mes opérations */}
                                                    <NavbarDropdownItem
                                                        icon={"user"}
                                                        title={"Mes opérations"}
                                                        href={"/operations/mes_operations"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE
                                                        ]}
                                                    />

                                                    {/* Opérations > Déclarer une opération */}
                                                    <NavbarDropdownItem
                                                        icon={"plus"}
                                                        title={"Déclarer une opération"}
                                                        href={"/operations/declarer_operation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE
                                                        ]}
                                                    />

                                                    {/* Opérations > Opérations à initialiser */}
                                                    <NavbarDropdownItem
                                                        icon={"plus-square"}
                                                        title={"Opérations à initialiser"}
                                                        href={"/operations/initialiser"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE,
                                                            NomGroupe.SUPPORT_SI_DEV_SIRS,
                                                            NomGroupe.CHEF_DE_PROJET_SIRS
                                                        ]}
                                                    />

                                                    {/* Opérations > Opérations à supprimer */}
                                                    <NavbarDropdownItem
                                                        icon={"minus-square"}
                                                        title={"Opérations à supprimer"}
                                                        href={"/operations/supprimer"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_SIRS
                                                        ]}
                                                    />

                                                    {/* Opérations > Opérations supprimées */}
                                                    <NavbarDropdownItem
                                                        icon={"trash-alt"}
                                                        title={"Opérations supprimées"}
                                                        href={"/operations/supprimees"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE
                                                        ]}
                                                    />

                                                    {/* Opérations > Toutes les opérations */}
                                                    <NavbarDropdownItem
                                                        icon={"bars"}
                                                        title={"Toutes les opérations"}
                                                        href={"/operations/all"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE,
                                                            NomGroupe.OBSERVATEUR
                                                        ]}
                                                    />

                                                    {/* Opérations > Environnements hors production */}
                                                    <NavbarDropdownItem
                                                        icon={"cogs"}
                                                        title={"Environnements hors production"}
                                                        href={"/environnements/all"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE,
                                                            NomGroupe.OBSERVATEUR
                                                        ]}
                                                    />

                                                    {/* Opérations > Facturation */}
                                                    <NavbarDropdownItem
                                                        icon={"euro-sign"}
                                                        title={"Facturation"}
                                                        href={"/operations/facturation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                                            NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                                            NomGroupe.CHEF_DE_PROJET_VOTE
                                                        ]}
                                                    />

                                                </Menu>
                                            </>
                                        </NavbarMenuItem>
                                    )
                                    : null
                            }


                            {/* MENU "SATURNE" */}
                            {
                                authContext.authenticated && !authContext.utilisateur.mdpAModifier ?
                                    (
                                        <NavbarMenuItem groupes={[
                                            NomGroupe.ADMINISTRATEUR,
                                            NomGroupe.SUPPORT_SI_INFRA,
                                            NomGroupe.SUPPORT_SI_DEV,
                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                        ]}>
                                            <>
                                                <Button
                                                    variant={"text"}
                                                    className={"survox-nav-item dropdown"}
                                                    onClick={e => {
                                                        handleCloseMenuItem();
                                                        setAnchorItemSaturne(e.currentTarget);
                                                    }}
                                                >
                                                    Saturne
                                                </Button>
                                                <Menu
                                                    id={"menu-saturne"}
                                                    anchorEl={anchorItemSaturne}
                                                    open={Boolean(anchorItemSaturne)}
                                                    onClick={handleCloseMenuItem}
                                                    onClose={handleCloseMenuItem}
                                                    elevation={1}
                                                    TransitionComponent={Fade}
                                                    disablePortal={!isOpenOnMobile}
                                                    hideBackdrop={!isOpenOnMobile}
                                                >

                                                    {/* Saturne > Mes opérations Saturne */}
                                                    <NavbarDropdownItem
                                                        icon={"user"}
                                                        title={"Mes opérations Saturne"}
                                                        href={"/saturne/mes_operations"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* Saturne > Déclarer une opération */}
                                                    <NavbarDropdownItem
                                                        icon={"plus"}
                                                        title={"Déclarer une opération"}
                                                        href={"/saturne/declarer_operation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* Saturne > Opérations à initialiser */}
                                                    <NavbarDropdownItem
                                                        icon={"plus-square"}
                                                        title={"Opérations à initialiser"}
                                                        href={"/saturne/initialiser"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* Saturne > Opérations à supprimer */}
                                                    <NavbarDropdownItem
                                                        icon={"minus-square"}
                                                        title={"Opérations à supprimer"}
                                                        href={"/saturne/supprimer"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* Saturne > Facturation */}
                                                    <NavbarDropdownItem
                                                        icon={"euro-sign"}
                                                        title={"Facturation"}
                                                        href={"/saturne/facturation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                </Menu>
                                            </>
                                        </NavbarMenuItem>
                                    )
                                    : null
                            }

                            {/* MENU "MAILEVA" */}
                            {
                                authContext.authenticated && !authContext.utilisateur.mdpAModifier ?
                                    (
                                        <NavbarMenuItem groupes={[
                                            NomGroupe.ADMINISTRATEUR,
                                            NomGroupe.SUPPORT_SI_INFRA,
                                            NomGroupe.SUPPORT_SI_DEV,
                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                        ]}>
                                            <>
                                                <Button
                                                    variant={"text"}
                                                    className={"survox-nav-item dropdown"}
                                                    onClick={e => {
                                                        handleCloseMenuItem();
                                                        setAnchorItemMailEva(e.currentTarget);
                                                    }}
                                                >
                                                    MailEva
                                                </Button>
                                                <Menu
                                                    id={"menu-maileva"}
                                                    anchorEl={anchorItemMailEva}
                                                    open={Boolean(anchorItemMailEva)}
                                                    onClick={handleCloseMenuItem}
                                                    onClose={handleCloseMenuItem}
                                                    elevation={1}
                                                    TransitionComponent={Fade}
                                                    disablePortal={!isOpenOnMobile}
                                                    hideBackdrop={!isOpenOnMobile}
                                                >

                                                    {/* MailEva > Mes opérations MailEva */}
                                                    <NavbarDropdownItem
                                                        icon={"user"}
                                                        title={"Mes opérations MailEva"}
                                                        href={"/maileva/mes_operations"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* MailEva > Déclarer une opération */}
                                                    <NavbarDropdownItem
                                                        icon={"plus"}
                                                        title={"Déclarer une opération"}
                                                        href={"/maileva/declarer_operation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* MailEva > Opérations à initialiser */}
                                                    <NavbarDropdownItem
                                                        icon={"plus-square"}
                                                        title={"Opérations à initialiser"}
                                                        href={"/maileva/initialiser"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* MailEva > Opérations à supprimer */}
                                                    <NavbarDropdownItem
                                                        icon={"minus-square"}
                                                        title={"Opérations à supprimer"}
                                                        href={"/maileva/supprimer"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                    {/* MailEva > Facturation */}
                                                    <NavbarDropdownItem
                                                        icon={"euro-sign"}
                                                        title={"Facturation"}
                                                        href={"/maileva/facturation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV,
                                                            NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                        ]}
                                                    />

                                                </Menu>
                                            </>
                                        </NavbarMenuItem>
                                    )
                                    : null
                            }

                            {/* MENU "SIRS" */}
                            {
                                authContext.authenticated && !authContext.utilisateur.mdpAModifier ?
                                    (
                                        <NavbarMenuItem groupes={[
                                            NomGroupe.ADMINISTRATEUR,
                                            NomGroupe.SUPPORT_SI_INFRA,
                                            NomGroupe.SUPPORT_SI_DEV_SIRS,
                                            NomGroupe.CHEF_DE_PROJET_SIRS,
                                            NomGroupe.OBSERVATEUR
                                        ]}>
                                            <>
                                                <Button
                                                    variant={"text"}
                                                    className={"survox-nav-item dropdown"}
                                                    onClick={e => {
                                                        handleCloseMenuItem();
                                                        setAnchorItemSIRS(e.currentTarget);
                                                    }}
                                                >
                                                    SIRS
                                                </Button>
                                                <Menu
                                                    id={"menu-sirs"}
                                                    anchorEl={anchorItemSIRS}
                                                    open={Boolean(anchorItemSIRS)}
                                                    onClick={handleCloseMenuItem}
                                                    onClose={handleCloseMenuItem}
                                                    elevation={1}
                                                    TransitionComponent={Fade}
                                                    disablePortal={!isOpenOnMobile}
                                                    hideBackdrop={!isOpenOnMobile}
                                                >

                                                    {/* SIRS > Sites en production */}
                                                    <NavbarDropdownItem
                                                        icon={"play-circle"}
                                                        title={"Sites en production"}
                                                        href={"/sirs/sites_production"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV_SIRS,
                                                            NomGroupe.CHEF_DE_PROJET_SIRS,
                                                            NomGroupe.OBSERVATEUR
                                                        ]}
                                                    />

                                                    {/* SIRS > Mes opérations SIRS */}
                                                    <NavbarDropdownItem
                                                        icon={"user"}
                                                        title={"Mes opérations SIRS"}
                                                        href={"/sirs/mes_operations"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.CHEF_DE_PROJET_SIRS
                                                        ]}
                                                    />

                                                    {/* SIRS > Déclarer une opération */}
                                                    <NavbarDropdownItem
                                                        icon={"plus"}
                                                        title={"Déclarer une opération"}
                                                        href={"/sirs/declarer_operation"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.CHEF_DE_PROJET_SIRS
                                                        ]}
                                                    />

                                                    {/* SIRS > Sites archivés */}
                                                    <NavbarDropdownItem
                                                        icon={"trash-alt"}
                                                        title={"Sites supprimés"}
                                                        href={"/sirs/sites_supprimes"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.SUPPORT_SI_INFRA,
                                                            NomGroupe.SUPPORT_SI_DEV_SIRS,
                                                            NomGroupe.CHEF_DE_PROJET_SIRS
                                                        ]}
                                                    />

                                                </Menu>
                                            </>
                                        </NavbarMenuItem>
                                    )
                                    : null
                            }

                            {/* MENU "ADMINISTRATION" */}
                            {
                                authContext.authenticated && !authContext.utilisateur.mdpAModifier ?
                                    (
                                        <NavbarMenuItem groupes={[
                                            NomGroupe.ADMINISTRATEUR,
                                            NomGroupe.GESTIONNAIRE_DE_COMPTE
                                        ]}>
                                            <>
                                                <Button
                                                    variant={"text"}
                                                    className={"survox-nav-item dropdown"}
                                                    onClick={e => {
                                                        handleCloseMenuItem();
                                                        setAnchorItemAdministration(e.currentTarget);
                                                    }}
                                                >
                                                    Administration
                                                </Button>
                                                <Menu
                                                    id={"menu-administration"}
                                                    anchorEl={anchorItemAdministration}
                                                    open={Boolean(anchorItemAdministration)}
                                                    onClick={handleCloseMenuItem}
                                                    onClose={handleCloseMenuItem}
                                                    elevation={1}
                                                    TransitionComponent={Fade}
                                                    disablePortal={!isOpenOnMobile}
                                                    hideBackdrop={!isOpenOnMobile}
                                                >

                                                    {/* Administration > Table d'audits */}
                                                    <NavbarDropdownItem
                                                        icon={"table"}
                                                        title={"Table d'audits"}
                                                        href={"/administration/audits"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                    {/* Administration > Jobs */}
                                                    <NavbarDropdownItem
                                                        icon={"tasks"}
                                                        title={"Jobs"}
                                                        href={"/administration/jobs"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                    {/* Administration > Groupes */}
                                                    <NavbarDropdownItem
                                                        icon={"id-card-alt"}
                                                        title={"Groupes"}
                                                        href={"/administration/groupes"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.GESTIONNAIRE_DE_COMPTE
                                                        ]}
                                                    />

                                                    {/* Administration > Utilisateurs */}
                                                    <NavbarDropdownItem
                                                        icon={"users"}
                                                        title={"Utilisateurs"}
                                                        href={"/administration/utilisateurs"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[
                                                            NomGroupe.ADMINISTRATEUR,
                                                            NomGroupe.GESTIONNAIRE_DE_COMPTE
                                                        ]}
                                                    />

                                                    {/* Administration > Versions des produits */}
                                                    <NavbarDropdownItem
                                                        icon={"code-branch"}
                                                        title={"Versions des produits"}
                                                        href={"/administration/versions_produits"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                    {/* Administration > Types de plateforme */}
                                                    <NavbarDropdownItem
                                                        icon={"cogs"}
                                                        title={"Types de plateforme"}
                                                        href={"/administration/types_plateforme"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                    {/* Administration > Portefeuilles projets */}
                                                    <NavbarDropdownItem
                                                        icon={"wallet"}
                                                        title={"Portefeuilles projets"}
                                                        href={"/administration/portefeuilles_projets"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                    {/* Administration > Accès aux logs */}
                                                    <NavbarDropdownItem
                                                        icon={"code"}
                                                        title={"Accès aux logs"}
                                                        href={"/administration/acces_logs"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                    {/* Administration > Paramétrage */}
                                                    <NavbarDropdownItem
                                                        icon={"cog"}
                                                        title={"Paramétrage"}
                                                        href={"/administration/parametrage"}
                                                        onClick={() => handleClickMenuItem()}
                                                        groupes={[NomGroupe.ADMINISTRATEUR]}
                                                    />

                                                </Menu>
                                            </>
                                        </NavbarMenuItem>
                                    )
                                    : null
                            }

                            {/* PARTIE DROITE - BOUTONS */}
                            <Box sx={{
                                marginLeft: {md: 'auto'},
                                marginTop: {xs: 'auto', md: 'initial'},
                                display: 'flex',
                                flexDirection: {xs: 'column', md: 'row'},
                                gap: '1rem'
                            }}>

                                {/* POPOVER PROFIL UTILISATEUR */}
                                {
                                    authContext.authenticated ?
                                        (
                                            <>
                                                <VoxButton
                                                    variant={"outlined"}
                                                    startIcon={<FontAwesomeIcon icon={"user"}/>}
                                                    onClick={handleOpenPopover}
                                                >
                                                    {authContext.utilisateur.login}
                                                </VoxButton>
                                                <Popover
                                                    id={idPopover}
                                                    open={openPopover}
                                                    anchorEl={anchorElPopover}
                                                    onClose={handleClosePopover}
                                                    elevation={2}
                                                    anchorOrigin={{
                                                        vertical: popoverAnchorOriginVertical(),
                                                        horizontal: 'center'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: popoverTransformOriginVertical(),
                                                        horizontal: 'center'
                                                    }}
                                                    sx={{
                                                        marginTop: {xs: '-12px', md: '12px'},
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
                                                        '.MuiPopover-paper': {
                                                            overflow: 'visible'
                                                        },
                                                        '.MuiPopover-paper:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: {xs: 'initial', md: 0},
                                                            bottom: {xs: '-10px', md: 'initial'},
                                                            left: '50%',
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translate(-50%,-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        }
                                                    }}
                                                >
                                                    {authContext.utilisateur?.groupe &&
                                                        <Box sx={{
                                                            padding: '1.2rem',
                                                            textAlign: 'center'
                                                        }}>
                                                            <Typography variant={"body1"}>
                                                                {authContext.utilisateur.prenom} {authContext.utilisateur.nom}
                                                            </Typography>
                                                            <Typography variant={"body2"}
                                                                        sx={{margin: '0.5rem !important'}}>
                                                                {authContext.utilisateur.groupe.nom}
                                                            </Typography>
                                                            <Box sx={{
                                                                margin: '1rem',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '0.5rem'
                                                            }}>
                                                                <Link component={RouterLink} to={"/mon_profil"}>
                                                                    Accéder à mon profil
                                                                </Link>
                                                                {
                                                                    (
                                                                        authContext.utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                                                                        || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                                                                        || authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                                                                        || authContext.utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE
                                                                        || authContext.utilisateur.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
                                                                        || authContext.utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE
                                                                        || authContext.utilisateur.groupe.nom === NomGroupe.OBSERVATEUR
                                                                    ) && (
                                                                        <Link component={RouterLink} to={"/mes_portefeuilles_projets"}>
                                                                            Gérer mes portefeuilles projets
                                                                        </Link>
                                                                    )
                                                                }
                                                            </Box>
                                                            <hr/>
                                                            <VoxButton
                                                                customColor={"red"}
                                                                size={"small"}
                                                                startIcon={<FontAwesomeIcon icon={"power-off"}/>}
                                                                onClick={handleOnLogout}
                                                            >
                                                                Déconnexion
                                                            </VoxButton>
                                                        </Box>
                                                    }
                                                </Popover>
                                            </>
                                        )
                                        : null
                                }

                                {/* TV DASHBOARD */}
                                <VoxButton
                                    startIcon={<FontAwesomeIcon icon={"tv"}/>}
                                    onClick={() => navigate('/dashboard')}
                                >
                                    Dashboard
                                </VoxButton>

                            </Box>

                        </Box>
                    </VoxConditionalWrapper>

                </Toolbar>
            </Container>
        </AppBar>
    )
}