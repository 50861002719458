import {Grid} from "@mui/material";
import {VoxAlert} from "../../genericComponentsUI/alerte/voxAlert";

export default function ViewRestricted() {
    return (
        <Grid container justifyContent={"center"} marginTop={5}>
            <Grid item xs={8}>
                <VoxAlert severity={"warning"}>
                    Vous n'êtes pas autorisé à voir cette page
                </VoxAlert>
            </Grid>
        </Grid>
    )
}