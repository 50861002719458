import React from "react";
import OperationsFacturation from "../../components/facturation/operationsFacturation";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsMailevaFacturationView() {

    return (
        <>
            <VoxHeader title={"Facturation Vente indirecte (MailEva)"}/>
            <OperationsFacturation typeOperation={TypeOperation.MAILEVA}/>
        </>
    );
}