import { Box, DialogTitle, IconButton } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system/";

type DialogTitleProps = {
    children?: React.ReactNode;
    onClose?: () => void;
}

export function VoxDialogTitle(props: Readonly<DialogTitleProps>) {
    const { children, onClose, ...other } = props;

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle {...other}>
                { children }
            </DialogTitle>
            {
                onClose && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{ color: "#00008c" }}
                        size="large"
                    >
                        <CobaltIcon name={"x"}/>
                    </IconButton>
                )
            }
        </Box>
    );
}