import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Cell, CellProps, Column } from "react-table";
import VoxLoader from "../../components/genericComponentsUI/loaders/voxLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OperationSuivieUtilisateur } from "../../models/operation/operationSuivieUtilisateur.model";
import { OperationPhase } from "../../models/operation/operationPhase.enum";
import { ResultatSurveillance } from "../../models/surveillance/resultatSurveillance.enum";
import GestionFavorisListe from "../../components/operationsListe/gestionFavorisListe/gestionFavorisListe";
import operationService from "../../services/survox-back/operation/operation.service";
import VoxTable from "../../components/genericComponentsUI/table/voxTable";
import { VoxAlert } from "../../components/genericComponentsUI/alerte/voxAlert";
import { SelectInputFilter } from "../../components/genericComponentsUI/table/filters/SelectInputFilter";
import VoxLinkOperation from "../../components/genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../../components/genericComponentsUI/link/voxLinkUrl";

type OperationTableauAccueil = {
    id: number;
    nom: string;
    favori: boolean;
    dateCreationMillisecondes: number;
    phase: string;
    portefeuilleProjet: string;
    chefProjet: string;
    etatSite: string | null;
    url: string;
}

// Constantes qui définissent le texte à afficher dans le tableau en fonction de la phase
const texte_phase_AVANTT1: string = "Avant 1er tour";
const texte_phase_T1: string = "1er tour en cours";
const texte_phase_ENTRET1T2: string = "Entre 2 tours";
const texte_phase_T2: string = "2ème tour en cours";
const texte_phase_FIN: string = "Scrutin terminé";
const texte_phase_nonApplicable: string = "Non applicable";

/**
 * Fonction qui renvoie le texte pour la cellule du tableau en fonction de la valeur de la phase
 */
function getTextePhaseOperation(operationPhase: OperationPhase | null): string {
    switch (operationPhase) {
        case OperationPhase.AVANTT1: return texte_phase_AVANTT1;
        case OperationPhase.T1: return texte_phase_T1;
        case OperationPhase.ENTRET1T2: return texte_phase_ENTRET1T2;
        case OperationPhase.T2: return texte_phase_T2;
        case OperationPhase.FIN: return texte_phase_FIN;
        default: return texte_phase_nonApplicable;
    }
}

/**
 * Fonction qui renvoie le picto à afficher en fonction de l'état du site lié à l'opération
 */
function getPictoEtatSite(resultatSurveillance: ResultatSurveillance | null): ReactElement {

    if (resultatSurveillance === null) {
        return <FontAwesomeIcon icon={"circle"}
                                color={"darkGrey"}
                                size={"lg"}
                                title={"En attente de supervision"}/>

    } else if (resultatSurveillance === ResultatSurveillance.KO) {
        return <FontAwesomeIcon icon={"times-circle"}
                                color={"red"}
                                size={"lg"}
                                title={"Site inaccessible"}/>

    } else {
        return <FontAwesomeIcon icon={"check-circle"}
                                color={"green"}
                                size={"lg"}
                                title={"Site accessible"} />
    }
}

/**
 * Fonction qui permet d'appliquer des propriétés particulières aux cellules du tableau
 */
function getCellStyle(cell: Cell<OperationTableauAccueil>) {
    let style = {};
    style = { ...style, textAlign: "center" };
    if (cell.column.id === "phase") {
        // Couleur d'arrière-plan des cellules de la colonne "Phase"
        switch (cell.value) {
            case texte_phase_AVANTT1: {
                style = {
                    ...style,
                    backgroundColor: "#d1ecf1"  // couleur alerte-bleu
                }
                break;
            }
            case texte_phase_T1:
            case texte_phase_T2: {
                style = {
                    ...style,
                    backgroundColor: "#d4edda"  // couleur alerte-vert
                }
                break;
            }
            case texte_phase_ENTRET1T2: {
                style = {
                    ...style,
                    backgroundColor: "#fff3cd"  // couleur alerte-jaune
                }
                break;
            }
            case texte_phase_FIN: {
                style = {
                    ...style,
                    backgroundColor: "#f8d7da"  // couleur alerte-rouge
                }
                break;
            }
        }
    }
    return style;
}

export default function OperationsUtilisateurListe() {

    const [operations, setOperations] = useState<OperationSuivieUtilisateur[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isErrorEnregistrementFavoris, setIsErrorEnregistrementFavoris] = useState(false);
    const [messageErreurFavoris, setMessageErreurFavoris] = useState<string>("");

    useEffect(() => {
        const controller = new AbortController();
        chargerOperations(controller);
        return () => controller.abort();
    }, []);

    /**
     * Chargement des données du tableau
     */
    const chargerOperations = (controller?: AbortController) => {
        setIsLoading(true);
        operationService.getOperationsUtilisateurCourant(controller?.signal)
            .then(response => {
                if (response.status === 200) {
                    const operationsListe: OperationSuivieUtilisateur[] = response.data;
                    setOperations(operationsListe);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    /**
     * Remplissage des lignes du tableau
     */
    const rows : OperationTableauAccueil[] = useMemo(() => {
        return operations.map(operationSuivieUtilisateur => ({
            id: operationSuivieUtilisateur.id,
            nom: operationSuivieUtilisateur.nom,
            favori: operationSuivieUtilisateur.isOperationFavoriteUtilisateur,
            dateCreationMillisecondes: operationSuivieUtilisateur.dateCreationMillisecondes,
            phase: getTextePhaseOperation(operationSuivieUtilisateur.phase),
            portefeuilleProjet: operationSuivieUtilisateur.portefeuilleProjet.nom,
            chefProjet: operationSuivieUtilisateur.chefProjet,
            etatSite: operationSuivieUtilisateur.etatSite,
            url: operationSuivieUtilisateur.url,
        }));
    }, [operations]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns : Column<OperationTableauAccueil>[] = useMemo( () => [
        {
            Header: 'Favoris',
            accessor: 'favori',
            disableFilters: true,
            maxWidth: 80,
            minWidth: 80,
            Cell: (props: CellProps<OperationTableauAccueil>) => {
                return (
                    <GestionFavorisListe
                        setListeOperations={(opId, isFavori) => {
                            setOperations((prevState => prevState.map(op => {
                                if (op.id === opId) op.isOperationFavoriteUtilisateur = isFavori;
                                return op;
                            })));
                        }}
                        isOperationFavoriteUtilisateur={props.value}
                        operationId={props.row.original.id}
                        setIsErrorEnregistrementFavoris={(value) => setIsErrorEnregistrementFavoris(value)}
                        setMessageErreurFavoris={(message) => setMessageErreurFavoris(message)}
                    />
                )
            },
            sortType: 'basic'
        },
        {
            Header: 'Opération',
            accessor: 'nom',
            flexGrow: 0.5,
            minWidth: 250,
            Cell: (props: CellProps<OperationTableauAccueil>) => (
                <VoxLinkOperation href={`/operation/detail/${props.row.original.id}`}>
                    { props.value }
                </VoxLinkOperation>
            )
        },
        {
            Header: "Portefeuille projet",
            accessor: 'portefeuilleProjet',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 140,
        },
        {
            Header: "Chef(fe) de projet",
            accessor: 'chefProjet',
            flexGrow: 0.5,
            minWidth: 100,
        },
        {
            Header: 'Phase',
            accessor: 'phase',
            flexGrow: 1,
            minWidth: 120,
            Filter: SelectInputFilter,
            filter: 'exact',
            cellClassName: "text-center"
        },
        {
            Header: 'État du site',
            accessor: 'etatSite',
            flexGrow: 0.3,
            minWidth: 90,
            maxWidth: 150,
            disableFilters: true,
            Cell: (props: CellProps<OperationTableauAccueil>) => getPictoEtatSite(props.value)

        },
        {
            Header: 'URL',
            accessor: 'url',
            flexGrow: 0.5,
            minWidth: 200,
            Cell: (props: CellProps<OperationTableauAccueil>) => <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
        },
    ],[]);


    if (isLoading) {
        return <VoxLoader />
    } else {
        return (
            <>
                {isErrorEnregistrementFavoris && (
                    <VoxAlert severity={"error"}>
                        {messageErreurFavoris}
                    </VoxAlert>
                )}
                <VoxTable
                    id={"table-operations-suivies"}
                    data={rows}
                    columns={columns}
                    cellProps={(cell: Cell<OperationTableauAccueil>) => ({ style: getCellStyle(cell) })}
                    initialStateSortBy={[{ id: 'nom', desc: false }]}
                />
            </>
        )
    }
}