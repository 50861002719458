import React, {useCallback, useEffect, useMemo, useState} from "react";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import {CellProps, Column, IdType, Row} from "react-table";
import {Audit} from "../../../models/audit/audit.model";
import auditService from "../../../services/survox-back/audit/audit.service";
import {affichageDateWithFormatDMYHm} from "../../../utils/string.utils";
import {AuditListDto} from "../../../services/survox-back/audit/dtos/response/auditList.dto";
import {sortDatesInTables} from "../../../utils/date.utils";
import ConcatenedStringColumnTypeCell from "../../../components/genericComponentsUI/table/cells/concatenedStringColumnTypeCell";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import {SelectInputFilter} from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";

export default function AuditsListe(props: { auditsDateRange: { startDate: Date, endDate: Date } }) {

    const [audits, setAudits] = useState<AuditListDto[]>([])
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Charger les audits
     */
    const loadAudits = useCallback((controller: AbortController) => {
        setIsLoading(true);
        auditService.getAllAudit(props.auditsDateRange.startDate, props.auditsDateRange.endDate, controller.signal)
            .then(response => {
                if (response.status === 200) {
                    const auditsList: AuditListDto[] = response.data;
                    setAudits(auditsList);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [props.auditsDateRange]);

    useEffect(() => {
        const controller = new AbortController();
        loadAudits(controller);
        return () => controller.abort();
    }, [props.auditsDateRange, loadAudits])

    /**
     * Remplissage des lignes du tableau
     */
    const rows: any[] = useMemo(() => {
        return audits.map(
            (audit) => {

                // Définition de la valeur de la colonne "Opération"
                let nomOperation: string = '';
                if (audit.operationVoteNom) nomOperation = audit.operationVoteNom;
                else if (audit.operationSIRSNomClient) nomOperation = audit.operationSIRSNomClient;

                return {
                    utilisateur: audit.utilisateur ? audit.utilisateur : "",
                    operation: nomOperation,
                    type: audit.type,
                    valeurAvant: audit.valeurAvant,
                    valeurApres: audit.valeurApres,
                    dateDebut: audit.dateDebut,
                    dateFin: audit.dateFin,
                    description: audit.description
                }
            }
        )
    }, [audits]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<Audit>> = useMemo(() => [
            {
                Header: 'Utilisateur',
                accessor: 'utilisateur',
                flexGrow: 0.5,
                minWidth: 180,
            },
            {
                Header: 'Opération',
                accessor: 'operation',
                minWidth: 160,
            },
            {
                Header: 'Type',
                accessor: 'type',
                flexGrow: 0.5,
                minWidth: 200,
                Filter: SelectInputFilter,
                filter: 'includes',
            },
            {
                Header: 'Valeur avant',
                accessor: 'valeurAvant',
                minWidth: 200,
                Cell: (props: CellProps<Audit>) => <ConcatenedStringColumnTypeCell cellProps={props}/>
            },
            {
                Header: 'Valeur après',
                accessor: 'valeurApres',
                minWidth: 200,
                Cell: (props: CellProps<Audit>) => <ConcatenedStringColumnTypeCell cellProps={props}/>
            },
            {
                Header: 'Date de début',
                id: 'dateDebut',
                accessor: (originalRow: Audit) => affichageDateWithFormatDMYHm(originalRow.dateDebut),
                sortType: (a: Row<Audit>,
                           b: Row<Audit>,
                           columnId: IdType<Audit>,
                           desc: boolean | undefined) => sortDatesInTables(a.original.dateDebut, b.original.dateDebut, desc),
                minWidth: 160,
            },
            {
                Header: 'Date de fin',
                id: 'dateFin',
                accessor: (originalRow: Audit) => affichageDateWithFormatDMYHm(originalRow.dateFin),
                sortType: (a: Row<Audit>,
                           b: Row<Audit>,
                           columnId: IdType<Audit>,
                           desc: boolean | undefined) => sortDatesInTables(a.original.dateFin, b.original.dateFin, desc),
                minWidth: 160,
            },
            {
                Header: 'Description',
                accessor: 'description',
                minWidth: 160,
            },
        ], []
    )


    return (
        isLoading ? <VoxLoader/> :
            <VoxTable
                data={rows}
                columns={columns}
                initialStateSortBy={[{id: 'dateFin', desc: true}]}
                cellProps={(cell: CellProps<Audit>) => {
                    if (!["dateDebut", "dateFin"].includes(cell.column.id)) {
                        return {
                            style: {textAlign: "left"}
                        }
                    }
                }}
            />
    )
}