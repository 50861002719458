import ParametrageListe from "./parametrageListe";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import { Grid } from "@mui/material";
import React from "react";

export default function ParametrageView() {
    return (
        <>
            <VoxHeader title={"Paramétrage"}/>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={8} md={6}>
                    <ParametrageListe/>
                </Grid>
            </Grid>
        </>
    );
}