import React from "react";
import OperationsCreation from "../../components/forms/operationsFormulaires/operationsCreation";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import IntroJsDeclarerOperationPackagee from "../../components/tutorial/IntroJsDeclarerOperationPackagee";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsSaturneCreationView() {

    return (
        <>
            <VoxHeader title={"Nouvelle opération Saturne"}
                       isBoutonRetour={true} lienRetour={"/saturne/mes_operations"}
                       boutonTutoriels={<IntroJsDeclarerOperationPackagee/>}
            />
            <OperationsCreation typeOperation={TypeOperation.SATURNE}/>
        </>
    );
}