import "../../../components/genericComponentsUI/button/voxButton.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import VersionVoxCoreElectionForm from "./versionVoxCoreElectionForm";
import OtherVersionsForm from "./otherVersionsForm";
import {TypeProduit} from "../../../models/versionApplicative/typeProduit.enum";

export default function VersionDesProduitsCreation() {

    const navigate = useNavigate();
    const [typeProduit, setTypeProduit] = useState<TypeProduit>(TypeProduit.VOXCORE_ELECTION);

    // recherche du paramètre dans l'URL spécifiant le type de produit
    const [searchParams, ] = useSearchParams();
    const type = searchParams.get("type");

    useEffect(() => {
        // Mise à jour du type de produit pour la version applicative à créer
        if (type === TypeOperation.ACTIONNARIAT) {
            setTypeProduit(TypeProduit.ACTIONNARIAT);
        }
        else if (type === TypeOperation.SIRS) {
            setTypeProduit(TypeProduit.SIRS);
        }
        else if (type === TypeOperation.VISIOVOTE) {
            setTypeProduit(TypeProduit.VISIOVOTE);
        }
        else if (type === TypeOperation.VOXCORE_ELECTION) {
            setTypeProduit(TypeProduit.VOXCORE_ELECTION);
        }
        else {
            // Erreur : Le paramètre dans l'URL est incorrect.
            // On redirige vers la page des versions applicatives
            navigate(`/administration/versions_produits`);
        }

    }, [type, navigate]);

    if(typeProduit === TypeProduit.VOXCORE_ELECTION) {
        return <VersionVoxCoreElectionForm typeProduit={typeProduit}/>
    } else {
        return <OtherVersionsForm typeProduit={typeProduit}/>
    }

}