import axios_instance from "../axios-client";
import { CreateVersionApplicativeDto } from "./dtos/request/createVersionApplicative.dto";
import { UpdateVersionsApplicativesDto } from "./dtos/request/updateVersionsApplicatives.dto";
import { GetVersionsApplicativesDto } from "./dtos/request/getVersionsApplicatives.dto";
import { TypeProduit } from "../../../models/versionApplicative/typeProduit.enum";
import { CreateVersionApplicativeV23Dto } from "./dtos/request/createVersionApplicativeV23.dto";

const versionApplicativeService =  {

    getVersionsApplicativesParDefaut: (reqParams: GetVersionsApplicativesDto, signal: AbortSignal) => {
        return axios_instance.get("/version_applicative/versions-par-defaut", { params: reqParams, signal: signal });
    },

    getVersionsApplicativesAvecLivrables: (reqParams: GetVersionsApplicativesDto, signal: AbortSignal) => {
        return axios_instance.get("/version_applicative/versions-avec-livrables", { params: reqParams, signal: signal });
    },

    getVersionsApplicativesActionnariat: (typeProduit: TypeProduit, signal: AbortSignal) => {
        return axios_instance.get("/version_applicative/versions_actionnariat", { params: {typeProduit: typeProduit}, signal: signal });
    },

    getAllVersionApplicativeByTypeProduitWithArchiveFalse: (typeProduit: TypeProduit, signal?: AbortSignal) => {
        return axios_instance.get("/version_applicative/versions_produits", {
            params: { typeProduit: typeProduit },
            signal
        });
    },

    getVersionsArchivables: (typeProduit: TypeProduit) => {
        return axios_instance.get("version_applicative/versions_archivables", {params: {typeProduit}});
    },

    createVersionApplicative: (createVersionApplicative: CreateVersionApplicativeDto) => {
        return axios_instance.post("/version_applicative", createVersionApplicative)
    },

    createVersionApplicativeVoxElectionV23: (versionApplicativeV23: CreateVersionApplicativeV23Dto) => {
        return axios_instance.post("/version_applicative/vox_election_v23", versionApplicativeV23);
    },

    updateVersionsApplicatives: (updateVersionsApplicativesDto: UpdateVersionsApplicativesDto) => {
        return axios_instance.put("/version_applicative/maj_versions", updateVersionsApplicativesDto);
    },

    archiverVersionsApplicatives: (typeProduit: TypeProduit) => {
        return axios_instance.put("/version_applicative/archiver", {typeProduit});
    },
}

export default versionApplicativeService;