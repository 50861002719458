import React from "react";
import { FilterProps } from "react-table";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem } from "@mui/material";

/**
 * Filtre sous la forme d'un select avec 2 options 'Oui / Non', adapté pour les colonnes dont les valeurs
 * sont de type boolean
 * @param filterValue la valeur sélectionnée dans le filtre
 * @param setFilter fonction permettant de mettre à jour la valeur de filtre
 */
export function SelectBooleanInputFilter<Type extends object>({ column: { filterValue, setFilter } }: FilterProps<Type>) {
    return (
        <Select
            name={"select-boolean-input-filter"}
            value={!filterValue ? '' : filterValue}
            onChange={(event: SelectChangeEvent) => setFilter(event.target.value || undefined)}
            size={'small'}
            sx={{ width: '100%' }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        border: '1px solid var(--cobalt-grey20)',
                        boxShadow: 'rgba(102, 109, 146, 0.08) 0px 4px 8px',
                        marginTop: 0.5
                    }
                }
            }}
        >
            <MenuItem aria-label={"None"} value={''} sx={{ height: 36 }}/>
            <MenuItem value={"true"} sx={{ height: 36 }}>Oui</MenuItem>
            <MenuItem value={"false"} sx={{ height: 36 }}>Non</MenuItem>
        </Select>
    )
}