import React from "react";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import OperationsSIRSCreation from "../../components/forms/operationsSIRSFormulaires/operationsSIRSCreation";

export default function OperationsSIRSCreationView() {

    return (
        <>
            <VoxHeader title={"Nouvelle opération SIRS"} isBoutonRetour={true} lienRetour={"/sirs/mes_operations"}/>
            <OperationsSIRSCreation/>
        </>
    );
}