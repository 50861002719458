import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,

    SelectChangeEvent,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { VoxLegendChampsObligatoires } from "../../genericComponentsUI/label/voxLegendChampsObligatoires";
import { VoxTextField } from "../../genericComponentsUI/input/voxTextField";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import { VoxCalendarInput } from "../../genericComponentsUI/input/voxCalendarInput";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import { VoxLabel } from "../../genericComponentsUI/input/voxLabel";
import { VoxSelectInput } from "../../genericComponentsUI/input/voxSelectInput";
import { NomPlateforme } from "../../../models/operation/nomPlateforme.enum";
import { domaineUrl } from "../../../utils/string.utils";
import { Domaine } from "../../../models/operation/domaine.enum";
import dayjs from "dayjs";
import { VoxTextArea } from "../../genericComponentsUI/input/voxTextArea";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { PortefeuilleProjet } from "../../../models/portefeuilleProjet/portefeuilleProjet.model";
import { VersionApplicative } from "../../../models/versionApplicative/versionApplicative.model";
import { Plateforme } from "../../../models/operation/plateforme.dto";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { VoxLoaderInputFormulaire } from "../../genericComponentsUI/loaders/voxLoaderInputFormulaire";
import portefeuilleProjetService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjet.service";
import { NomGroupe } from "../../../models/utilisateur/nomGroupe.enum";
import { AuthContext } from "../../../contexts/authentication/authentication.context";
import { AxiosResponse } from "axios";
import versionApplicativeService from "../../../services/survox-back/versionApplicative/version-applicative.service";
import plateformeService from "../../../services/survox-back/operation/plateforme.service";
import { DatesActionnariatFormulaire } from "../../../models/operation/operationForm/datesActionnariatFormulaire.model";
import { OperationFormulaire } from "../../../models/operation/operationForm/operationFormulaire.model";
import { ParamsOperationFormulaire } from "../../../models/operation/operationForm/paramsOperationFormulaire.model";
import { TypeOption } from "../../../models/operation/typeOption.enum";
import { isVersionApplicativeSansComposants } from "../../../utils/versionApplicative.utils";
import { TypeProduit } from "../../../models/versionApplicative/typeProduit.enum";


type OperationFormPropsType = {
    // props permettant de récupérer les données
    operation: OperationFormulaire
    paramsOperation: ParamsOperationFormulaire
    datesActionnariat: DatesActionnariatFormulaire

    // props pour l'affichage de l'input "date d'installation"
    isAffichageDateInstallation: boolean

    // props concernant la validation du formulaire
    onSubmit: (operation: OperationFormulaire, paramsOperation: ParamsOperationFormulaire, datesActionnariat: DatesActionnariatFormulaire) => void
    isLoadingSubmit: boolean

    // props concernant les erreurs potentielles
    isErrorIdSalesforce: boolean
    isErrorDateInstallation: boolean
    isErrorDateOuverture: boolean
    isErrorDateFermeture: boolean
    messageError: string
    messageErrorIdSalesforce: string
}

type ConditionalProps =
    | {
        typeFormulaire: "creation"
        // props non communes mais obligatoires dans le cas d'un formulaire de création
        isError: boolean
      }
    | {
        typeFormulaire: "modification"
      };


// Définition de la version spécifique (pour le menu déroulant du choix de la version)
const versionSpecifiqueVoxcoreElection = {version: "Version Spécifique", typeProduit: TypeProduit.VOXCORE_ELECTION};


export default function OperationsForm(props: OperationFormPropsType & ConditionalProps) {

    // récupération de l'utilisateur connecté à l'application
    const authContext = useContext(AuthContext);
    const utilisateurAuth = authContext.utilisateur;

    const [operation, setOperation] = useState<OperationFormulaire>(props.operation);
    const [paramsOperation, setParamsOperation] = useState<ParamsOperationFormulaire>(props.paramsOperation);
    const [datesActionnariat, setDatesActionnariat] = useState<DatesActionnariatFormulaire>(props.datesActionnariat);

    const [listePortefeuillesProjets, setListePortefeuillesProjets] = useState<PortefeuilleProjet[]>([]);
    const [isLoadingPortefeuillesProjets, setIsLoadingPortefeuillesProjets] = useState<boolean>(false);
    const [isLoadingPortefeuillesProjetsError, setIsLoadingPortefeuillesProjetsError] = useState<boolean>(false);
    const [isChargementTerminePortefeuilleProjet, setIsChargementTerminePortefeuilleProjet] = useState<boolean>(false);
    const valeurInitialePortefeuilleProjet = props.operation.portefeuilleProjet;  // variable utile lors d'un formulaire de modification (typeFormulaire === "modification")

    const [listeVersionsApplicatives, setListeVersionsApplicatives] = useState<VersionApplicative[]>([]);
    const [isLoadingListeVersionsApplicatives, setIsLoadingListeVersionsApplicatives] = useState<boolean>(false);
    const [isLoadingListeVersionsApplicativesError, setIsLoadingListeVersionsApplicativesError] = useState<boolean>(false);
    const [isAffichageVersionApplicativeSouhaitee, setIsAffichageVersionApplicativeSouhaitee] = useState<boolean>(false);

    const [versionApplicativeSouhaitee, setVersionApplicativeSouhaitee] = useState<Partial<VersionApplicative>>(
        (operation.type === TypeOperation.VOXCORE_ELECTION && operation.versionApplicative && isVersionApplicativeSansComposants(operation.versionApplicative))
            ? versionSpecifiqueVoxcoreElection
            : operation.versionApplicative
    );

    // Initialisation de la variable versionApplicativeSpecifiqueSouhaitee :
    //    si la version applicative récupérée n'a pas de composant et que le type de l'opération est VoxCore-Election, alors il s'agit d'une Version Spécifique
    //    et la version doit s'afficher dans le champ versionApplicativeSpecifiqueSouhaitee
    const [versionApplicativeSpecifiqueSouhaitee, setVersionApplicativeSpecifiqueSouhaitee] = useState<string>(
        (operation.type === TypeOperation.VOXCORE_ELECTION && operation.versionApplicative
            && operation.versionApplicative.version && isVersionApplicativeSansComposants(operation.versionApplicative))
                ? operation.versionApplicative.version
                : ""
    );

    const [listePlateformes, setListePlateformes] = useState<Plateforme[]>([]);
    const plateformeH1 = listePlateformes.find(plateforme => plateforme.nom === 'H1');
    const plateformeH2 = listePlateformes.find(plateforme => plateforme.nom === 'H2');
    const [isLoadingListePlateformes, setIsLoadingListePlateformes] = useState<boolean>(false);
    const [isLoadListePlateformesError, setIsLoadListePlateformesError] = useState<boolean>(false);

    const [isAffichageDomaineSiteDeVote, setIsAffichageDomaineSiteDeVote] = useState<boolean>(false);

    const [isAffichageModeTest, setIsAffichageModeTest] = useState<boolean>(false);

    // variables utiles lors d'un formulaire de modification (typeFormulaire === "modification")
    const versionApplicativeEnregistree = props.operation.versionApplicative;
    const [isVersionEnregistreeDisponible, setIsVersionEnregistreeDisponible] = useState(false);


    // Variable permettant de savoir la taille de l'écran
    // (cela va permettre de placer le champ "Version applicative spécifique souhaitée" à un endroit différent selon la taille de l'écran)
    const theme = useTheme();
    const isBreakpointDownSm = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        // Affichage du champ 'Version applicative souhaitée' :
        //    - pour le type d'opération VoxCore-Election, VoxCore-AG, Saturne & Maileva, Actionnariat : pour tous les groupes autorisés à accéder à la page
        if (
            (
                operation.type === TypeOperation.SATURNE
                || operation.type === TypeOperation.MAILEVA
                || operation.type === TypeOperation.VOXCORE_ELECTION
                || operation.type === TypeOperation.VOXCORE_AG
                || operation.type === TypeOperation.ACTIONNARIAT
            ) && (
                utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR
                || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                || utilisateurAuth.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
                || utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE
                || utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE
            )
        ) {
            setIsAffichageVersionApplicativeSouhaitee(true);
        } else {
            setIsAffichageVersionApplicativeSouhaitee(false);
        }
    }, [operation.type, utilisateurAuth.groupe.nom]);


    useEffect(() => {
        // L'utilisateur peut sélectionner le nom de domaine lorsqu'il s'agit d'une opération
        // avec les conditions suivantes (exemple : optivox) :
        //       -> type: VoxCore-Election
        //       -> hébergeur: OVH
        //       -> plateforme: H1
        if (operation.type === TypeOperation.VOXCORE_ELECTION && operation.plateforme.nom === NomPlateforme.H1) {
            setIsAffichageDomaineSiteDeVote(true);
        } else {
            setIsAffichageDomaineSiteDeVote(false);
        }
    }, [operation.type, operation.plateforme.nom]);


    useEffect(() => {
        // Affichage du champ 'Mode test' seulement pour les utilisateurs Administrateur, Support SI Infra ou Support SI Dev
        // & lorsqu'il s'agit d'une opération dont le type de produit est VOXCORE-ELECTION ou VOXCORE-AG (dont Saturne et Maileva)
        if (
            utilisateurAuth && utilisateurAuth.groupe
            && (
                utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR
                || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV
            )
            && (
                operation.type === TypeOperation.SATURNE
                || operation.type === TypeOperation.MAILEVA
                || operation.type === TypeOperation.VOXCORE_ELECTION
                || operation.type === TypeOperation.VOXCORE_AG
            )
        ) {
            setIsAffichageModeTest(true);
        } else {
            setIsAffichageModeTest(false);
        }
    }, [utilisateurAuth, operation.type]);


    /**
     * Récupération de tous les portefeuilles projets
     */
    const chargerPortefeuillesProjets = (controller: AbortController) => {
        setIsLoadingPortefeuillesProjets(true);
        setIsLoadingPortefeuillesProjetsError(false);
        setIsChargementTerminePortefeuilleProjet(false);
        portefeuilleProjetService.getAllPortefeuillesProjets(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    setListePortefeuillesProjets(response.data);
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(false);
                }
            })
            .catch((err) => {
                if (err.message !== "canceled") {
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(true);
                }
            })
            .finally(() => {
                setIsChargementTerminePortefeuilleProjet(true);
            });
    }


    /**
     * Récupération de toutes les plateformes (concernant l'hébergement avec les VM)
     */
    const chargerPlateformes = (controller: AbortController) => {
        setIsLoadingListePlateformes(true);
        setIsLoadListePlateformesError(false);
        plateformeService.getAllPlateformes(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    const plateformes = response.data;
                    setListePlateformes(plateformes);
                    setIsLoadingListePlateformes(false);
                    setIsLoadListePlateformesError(false);
                }
            })
            .catch((error) => {
                if (error.message !== "canceled") {
                    setIsLoadingListePlateformes(false);
                    setIsLoadListePlateformesError(true);
                }
            })
    }

    /**
     * Lorsque le composant est monté dans le DOM on déclenche le chargement des portefeuilles projets
     */
    useEffect(() => {
        const controller = new AbortController();
        chargerPortefeuillesProjets(controller);
        return () => controller.abort();
    }, []);

    /**
     * Lorsque le composant est monté dans le DOM on déclenche le chargement des plateformes disponibles (en fonction des conditions)
     */
    useEffect(() => {
        const controller = new AbortController();
        if (
            props.typeFormulaire === "creation"
            || (
                props.typeFormulaire === "modification" &&
                !(operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA)
            )
        ) {
            chargerPlateformes(controller);
        }
        return () => controller.abort();
    }, [props.typeFormulaire, operation.type]);


    /**
     * Récupération des versions applicatives suivant le type de produit
     */
    const chargerListeVersionsApplicatives = useCallback((controller: AbortController) => {
        let typeProduit: TypeProduit | undefined;
        if (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA || operation.type === TypeOperation.VOXCORE_ELECTION) {
            typeProduit = TypeProduit.VOXCORE_ELECTION;
        } else if (operation.type === TypeOperation.VOXCORE_AG) {
            typeProduit = TypeProduit.VOXCORE_AG;
        } else if (operation.type === TypeOperation.ACTIONNARIAT) {
            typeProduit = TypeProduit.ACTIONNARIAT;
        }

        if ((typeProduit === TypeProduit.VOXCORE_ELECTION || typeProduit === TypeProduit.VOXCORE_AG) && (operation.type !== undefined)) {
            setIsLoadingListeVersionsApplicatives(true);
            setIsLoadingListeVersionsApplicativesError(false);
            let getVersionsApplicativesRequest: Promise<AxiosResponse>;
            if (
                utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR
                || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV
            ) {
                getVersionsApplicativesRequest = versionApplicativeService.getVersionsApplicativesAvecLivrables(
                    { typeProduit },
                    controller.signal
                );
            } else {
                getVersionsApplicativesRequest = versionApplicativeService.getVersionsApplicativesParDefaut(
                    { typeProduit },
                    controller.signal
                );
            }

            getVersionsApplicativesRequest
                .then(response => {
                    if (response.status === 200) {
                        const versionsApplicativesListe: VersionApplicative[] = response.data;
                        setListeVersionsApplicatives(versionsApplicativesListe);
                        setIsLoadingListeVersionsApplicatives(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== "canceled") {
                        setIsLoadingListeVersionsApplicatives(false);
                        setIsLoadingListeVersionsApplicativesError(true);
                    }
                });
        } else if (typeProduit === TypeProduit.ACTIONNARIAT) {
            setIsLoadingListeVersionsApplicatives(true);
            setIsLoadingListeVersionsApplicativesError(false);
            versionApplicativeService.getVersionsApplicativesActionnariat(typeProduit, controller.signal)
                .then(response => {
                    if (response.status === 200) {
                        const versionsApplicativesListe: VersionApplicative[] = response.data;
                        setListeVersionsApplicatives(versionsApplicativesListe);
                        setIsLoadingListeVersionsApplicatives(false);
                    }
                })
                .catch((error) => {
                    if (error.message !== "canceled") {
                        setIsLoadingListeVersionsApplicatives(false);
                        setIsLoadingListeVersionsApplicativesError(true);
                    }
                });
        } else {
            return setListeVersionsApplicatives([]);
        }
    }, [operation.type, utilisateurAuth]);


    useEffect(() => {
        const controller = new AbortController();
        chargerListeVersionsApplicatives(controller);
        return () => controller.abort();
    }, [chargerListeVersionsApplicatives]);


    useEffect(() => {
        if (props.typeFormulaire === "creation") {
            // on inscrit par défaut la première version de la liste, si cette liste est composée d'un seul élément (afin de mettre à jour l'opération à l'initialisation du formulaire)
            // sinon, on n'affecte pas de version, ce sera à l'utilisateur de faire son choix
            if (listeVersionsApplicatives.length === 1) {
                setVersionApplicativeSouhaitee(listeVersionsApplicatives[0]);
                setOperation((prevState: OperationFormulaire) => ({...prevState, versionApplicative: listeVersionsApplicatives[0]}));
            }
        }
    }, [props.typeFormulaire, listeVersionsApplicatives]);

    useEffect(() => {
        if (props.typeFormulaire === "modification") {
            // vérification que la version enregistrée en base est toujours disponible (c'est-à-dire inclus dans la liste des versions proposées)
            let versionEnregistree: Partial<VersionApplicative> | undefined = listeVersionsApplicatives.find((version: VersionApplicative) => Number(version.id) === Number(versionApplicativeEnregistree.id));

            if (versionApplicativeEnregistree.typeProduit === TypeProduit.VOXCORE_ELECTION
                && isVersionApplicativeSansComposants(versionApplicativeEnregistree)
            ) {
                // Il s'agit d'une Version Spécifique
                versionEnregistree = versionSpecifiqueVoxcoreElection;
            }

            // valeur booléenne permettant de savoir si la version enregistrée en base de données est toujours disponible.
            setIsVersionEnregistreeDisponible(versionEnregistree !== undefined);
        }
    }, [props.typeFormulaire, listeVersionsApplicatives, versionApplicativeEnregistree]);


    useEffect(() => {
        if (props.typeFormulaire === "modification") {
            if (!isVersionEnregistreeDisponible) {
                // version non disponible (on enlève la version applicative pour l'input afin d'éviter un warning dans la console)
                setVersionApplicativeSouhaitee({} as VersionApplicative);
            } else if (
                operation.type === TypeOperation.VOXCORE_ELECTION
                && isVersionApplicativeSansComposants(versionApplicativeEnregistree)
            ) {
                // Il s'agit d'une Version Spécifique
                setVersionApplicativeSouhaitee(versionSpecifiqueVoxcoreElection);
            } else {
                setVersionApplicativeSouhaitee(versionApplicativeEnregistree);
            }
        }
    }, [props.typeFormulaire, isVersionEnregistreeDisponible, versionApplicativeEnregistree, operation.type]);


    /**
     * Mise à jour du type de plateforme lorsqu'il s'agit de certains types d'opération (valeur non modifiable)
     */
    useEffect(() => {
        if (props.typeFormulaire === "creation") {
            if ((operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA) && plateformeH1 !== undefined) {
                setOperation((prevState: OperationFormulaire) => ({...prevState, plateforme: plateformeH1}));
            }
            if ((operation.type === TypeOperation.ACTIONNARIAT) && plateformeH2 !== undefined) {
                setOperation((prevState: OperationFormulaire) => ({...prevState, plateforme: plateformeH2}));
            }
        }
    }, [props.typeFormulaire, operation.type, plateformeH1, plateformeH2]);



    /**
     * Appel de cette méthode dès la modification des valeurs des inputs "nomClient", "salesforceId" et "description" (afin de mettre à jour les champs correspondants)
     * @param event
     */
    const handleOnChangeOperation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOperation((prevState: OperationFormulaire) => ({...prevState, [event.target.id]: event.target.value}));
    }

    /**
     * Méthode permettant de modifier le portefeuille projet (modifier l'état de l'input)
     * @param event
     */
    const handleOnChangePortefeuilleProjet = (event: SelectChangeEvent<HTMLInputElement>) => {
        const newPortefeuilleProjet = listePortefeuillesProjets.find((portefeuilleProjet: PortefeuilleProjet) => portefeuilleProjet.id === Number(event.target.value));
        if (newPortefeuilleProjet) {
            setOperation((prevState: OperationFormulaire) => ({...prevState, portefeuilleProjet: newPortefeuilleProjet}));
        }
    }

    /**
     * Appel de cette méthode dès la modification de la valeur de l'input "dateInstallation" (afin de mettre à jour la valeur)
     * @param id        Id du champ correspondant à un champ de l'objet operation
     * @param date      Date à mettre à jour
     */
    const handleOnChangeDate = (id: string, date: Date | undefined) => {
        setOperation((prevState: OperationFormulaire) => ({...prevState, [id]: date}));
    }

    /**
     * Appel de cette méthode dès la modification de la valeur de l'input "type"
     * @param event
     */
    const handleOnChangeTypeProduitOperation = (event: SelectChangeEvent<HTMLInputElement>) => {
        setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, urlDomaine: Domaine.VOXALY_VOTE}));

        // Lorsque l'on fait la modification du produit, on supprime la valeur de la version applicative (évite un warning dans la console)
        setVersionApplicativeSouhaitee({} as VersionApplicative);

        setOperation((prevState: OperationFormulaire) => ({...prevState, [event.target.name]: event.target.value}));
    }

    /**
     * Appel de cette méthode dès la modification du select "versionApplicativeSouhaitee" (afin de mettre à jour la valeur)
     * @param event
     */
    const handleOnChangeVersionApplicative = (event: SelectChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Version Spécifique") {
            setVersionApplicativeSouhaitee(versionSpecifiqueVoxcoreElection);
            setOperation((prevState: OperationFormulaire) => ({...prevState, versionApplicative: {version: versionApplicativeSpecifiqueSouhaitee, typeProduit: TypeProduit.VOXCORE_ELECTION}}));
        } else {
            const versionApplicative = listeVersionsApplicatives.find(version => version.version === event.target.value);
            if (versionApplicative) {
                setVersionApplicativeSouhaitee(versionApplicative);
                setOperation((prevState: OperationFormulaire) => ({...prevState, versionApplicative: versionApplicative}));
            }
        }
    }

    /**
     * Méthode permettant de metre à jour la valeur de la version applicative spécifique souhaitée
     * @param event
     */
    const handleOnChangeVersionSpecifiqueSouhaitee = (event: ChangeEvent<HTMLInputElement>) => {
        setVersionApplicativeSpecifiqueSouhaitee(event.target.value.trimStart());
        setOperation((prevState: OperationFormulaire) => ({...prevState, versionApplicative: {version: event.target.value.trimStart(), typeProduit: TypeProduit.VOXCORE_ELECTION}}));
    }

    /**
     * Méthode permettant de modifier le type de plateforme (modifier l'état de l'input)
     * @param event
     */
    const handleOnChangePlateforme = (event: SelectChangeEvent<HTMLInputElement>) => {
        const newPlateforme = listePlateformes.find((plateforme: Plateforme) => plateforme.id === Number(event.target.value));
        if (
            operation.type === TypeOperation.VOXCORE_ELECTION
            && operation.plateforme.nom === NomPlateforme.H1
            && paramsOperation.urlDomaine === Domaine.VOXINTIME
        ) {
            setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, urlDomaine: Domaine.VOXALY_VOTE}));
        }
        if (newPlateforme) {
            setOperation((prevState: OperationFormulaire) => ({...prevState, plateforme: newPlateforme}));
        }
    }

    /**
     * Appel de cette méthode dès la modification de la valeur de l'input "urlPartieClient" (afin de mettre à jour la valeur)
     * @param event
     */
    const handleOnChangeUrlPartieClient = (event: ChangeEvent<HTMLInputElement>) => {
        // valeur récupérée de l'input
        const urlPartieClientValue = event.target.value;
        // valeur de l'urlPartieClient où les espaces de la chaîne de caractères ont été supprimés
        const urlFinalPartieClient = urlPartieClientValue.replace(/\s+/g, '');
        setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, urlPartieClient: urlFinalPartieClient}));
    }

    /**
     * Appel de cette méthode dès la modification de la valeur de l'input "urlDomaine" (afin de mettre à jour le champ)
     * @param event
     */
    const handleOnChangeUrlDomaine = (event: SelectChangeEvent<HTMLInputElement>) => {
        setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, urlDomaine: event.target.value as Domaine}));
    }

    /**
     * Appel de cette méthode lors du clic sur la checkbox, afin de mettre à jour la valeur du champ 'isModeTest'
     * @param event
     */
    const handleOnChangeTrueFalse = (event: ChangeEvent<HTMLInputElement>) => {
        // quand le "Mode Test" est activé (=true) : le numeroOperation = 8000  (géré coté "back")
        setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, isModeTest: event.target.checked}));
    }

    /**
     * Appel de cette méthode dès la modification de la valeur des inputs "dateOuverture" & "dateFermeture" (afin de mettre à jour la valeur)
     * dans le cas d'une opération de type Actionnariat
     * @param id        Id du champ correspondant à un champ de l'objet operation
     * @param date      Date à mettre à jour
     */
    const handleOnChangeDateActionnariat = (id: string, date: Date | undefined) => {
        setDatesActionnariat((prevState: DatesActionnariatFormulaire) => ({...prevState, [id]: date}));
    }

    /**
     * Appel de cette méthode pour la mise à jour du champ 'optionEnvoi' & 'optionPapier' lors de la modification du select
     * @param event
     */
    const handleOnChangeOptions = (event: SelectChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, [event.target.name]: null}));
        } else {
            setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, [event.target.name]: event.target.value as TypeOption}));
        }
    }

    // Mise à jour de l'option papier à null si le mode d'envoi est différent de "Courrier"
    useEffect(() => {
        if (paramsOperation.optionEnvoi !== TypeOption.COURRIER) {
            setParamsOperation((prevState: ParamsOperationFormulaire) => ({...prevState, optionPapier: null}));
        }
    }, [paramsOperation.optionEnvoi]);



    /**
     * Gestion de l'affichage pour l'input "Portefeuille Projet"
     */

    const idLabelPortefeuilleProjet: string = "portefeuilleProjet-label";
    const startIconLabelPortefeuilleProjet: IconProp = "wallet";
    const labelTextPortefeuilleProjet: string = "Portefeuille projet";

    const labelPortefeuilleProjet = (
        <VoxLabel
            id={idLabelPortefeuilleProjet}
            startIconLabel={startIconLabelPortefeuilleProjet}
            label={labelTextPortefeuilleProjet}
            required
        />
    );

    let selectPortefeuilleProjet;
    if (isLoadingPortefeuillesProjets) {
        selectPortefeuilleProjet = (
            <>
                {labelPortefeuilleProjet}
                <VoxLoaderInputFormulaire/>
            </>
        );
    } else if (isLoadingPortefeuillesProjetsError) {
        selectPortefeuilleProjet = (
            <>
                {labelPortefeuilleProjet}
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des portefeuilles projets.
                </VoxAlert>
            </>
        );
    } else if (
        isChargementTerminePortefeuilleProjet &&
        (props.typeFormulaire === "creation") &&
        (listePortefeuillesProjets.length === 0 || (listePortefeuillesProjets.length === 1 && listePortefeuillesProjets[0].nom === 'Anonyme'))
    ) {
        // Pour les formulaires de création
        selectPortefeuilleProjet = (
            <>
                {labelPortefeuilleProjet}
                <VoxAlert severity={"error"}>
                    Aucun portefeuille projet n'est disponible !
                </VoxAlert>
                <VoxAlert severity={"info"}>
                    Veuillez vous rapprocher d'une personne Administrateur de l'application afin qu'elle puisse vous
                    déclarer un portefeuille projet.
                </VoxAlert>
            </>
        )
    } else {
        selectPortefeuilleProjet = (listePortefeuillesProjets.length > 0) && (
            <VoxSelectInput
                id={"portefeuilleProjet"}
                idLabel={idLabelPortefeuilleProjet}
                startIconLabel={startIconLabelPortefeuilleProjet}
                label={labelTextPortefeuilleProjet}
                value={operation.portefeuilleProjet.id}
                onChange={handleOnChangePortefeuilleProjet}
                fullWidth
                required={true}
                disabled={props.isLoadingSubmit}
            >
                {/* On affiche le choix du portefeuille projet 'Anonyme' seulement si ce portefeuille projet était associé à l'opération avant modification */}
                {/* Sinon, il n'y a pas la possibilité de choisir le portefeuille projet 'Anonyme' */}
                {(props.typeFormulaire === "modification" && valeurInitialePortefeuilleProjet?.nom === "Anonyme") &&
                    <MenuItem key={valeurInitialePortefeuilleProjet.id} value={valeurInitialePortefeuilleProjet.id}>
                        {valeurInitialePortefeuilleProjet.nom}
                    </MenuItem>
                }
                {
                    listePortefeuillesProjets.map((portefeuilleProjet: PortefeuilleProjet) => {
                        return (
                            (portefeuilleProjet.nom !== 'Anonyme') &&
                            <MenuItem key={portefeuilleProjet.id} value={portefeuilleProjet.id}>
                                {portefeuilleProjet.nom}
                            </MenuItem>
                        )
                    })
                }
            </VoxSelectInput>
        );
    }



    /**
     * Gestion de l'affichage pour l'input "Version applicative souhaitée"
     */

    const idLabelVersionApplicativeSouhaitee: string = "versionApplicativeSouhaitee-label";
    const startIconLabelVersionApplicativeSouhaitee: IconProp = "list-ol";
    const labelTextVersionApplicativeSouhaitee: string = "Version applicative souhaitée";

    const labelVersionApplicativeSouhaitee = (
        <VoxLabel
            id={idLabelVersionApplicativeSouhaitee}
            startIconLabel={startIconLabelVersionApplicativeSouhaitee}
            label={labelTextVersionApplicativeSouhaitee}
            required
        />
    );

    let selectVersionsApplicatives;
    if (isLoadingListeVersionsApplicatives) {
        selectVersionsApplicatives = (
            <>
                {labelVersionApplicativeSouhaitee}
                <VoxLoaderInputFormulaire/>
            </>
        );
    } else if (isLoadingListeVersionsApplicativesError) {
        selectVersionsApplicatives = (
            <>
                {labelVersionApplicativeSouhaitee}
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des versions applicatives.
                </VoxAlert>
            </>
        );
    } else {
        if (listeVersionsApplicatives && listeVersionsApplicatives.length !== 0) {
            selectVersionsApplicatives = (
                <>
                    <VoxSelectInput
                        id={"versionApplicativeSouhaitee"}
                        idLabel={idLabelVersionApplicativeSouhaitee}
                        startIconLabel={startIconLabelVersionApplicativeSouhaitee}
                        label={labelTextVersionApplicativeSouhaitee}
                        value={versionApplicativeSouhaitee ? versionApplicativeSouhaitee.version : ""}
                        onChange={handleOnChangeVersionApplicative}
                        fullWidth
                        required={true}
                        disabled={props.isLoadingSubmit}
                    >
                        {
                            listeVersionsApplicatives.map((versionApplicative: VersionApplicative) => {
                                return (
                                    <MenuItem key={versionApplicative.id} value={versionApplicative.version}>
                                        {versionApplicative.version}
                                    </MenuItem>
                                )
                            })
                        }
                        {/* Contrôle d'affichage pour la Version applicative Spécifique
                            seulement visible pour une opération de type VoxCore-Election (pas pour une opération Saturne ou Maileva ou autres...) */}
                        {(operation.type === TypeOperation.VOXCORE_ELECTION) && (
                            <MenuItem key={"version-specifique"} value={"Version Spécifique"}>
                                Spécifique (à préciser dans le champ ci-dessous)
                            </MenuItem>)
                        }
                    </VoxSelectInput>

                    {/* Dans le cas des formulaires de modification :
                        si la version enregistrée n'est plus disponible (affichage message d'information) */}
                    {(props.typeFormulaire === "modification" && !isVersionEnregistreeDisponible) &&
                        <VoxAlert severity={"info"}>
                            La version applicative enregistrée {versionApplicativeEnregistree.version} n'est plus disponible.
                        </VoxAlert>
                    }
                </>
            );
        } else if (
            (
                props.typeFormulaire === "creation" &&
                props.isError && !props.isErrorIdSalesforce && props.messageError === "versionApplicative must be a non-empty object"
            ) || (
                props.typeFormulaire === "modification" &&
                listeVersionsApplicatives && listeVersionsApplicatives.length === 0
            )
        ) {
            selectVersionsApplicatives = (
                <>
                    {labelVersionApplicativeSouhaitee}
                    <VoxAlert severity={"error"}>
                        Aucune version n'est disponible.
                    </VoxAlert>
                    <VoxAlert severity={"info"}>
                        Veuillez vous rapprocher d'une personne Administrateur de l'application afin qu'elle puisse
                        déclarer une version pour le produit souhaité.
                    </VoxAlert>
                </>
            );
        } else {
            selectVersionsApplicatives = (
                <>
                    {labelVersionApplicativeSouhaitee}
                    <Box sx={{blockSize: 46, display: 'flex', flexDirection: "row", alignItems: "center"}}>
                        <Typography component={"span"} sx={{px: 5}}>-</Typography>
                    </Box>
                </>
            );
        }
    }


    /**
     * Gestion de l'affichage pour l'input "Type de plateforme"
     */

    const idLabelTypePlateforme: string = "plateforme-label";
    const startIconLabelTypePlateforme: IconProp = "hdd";
    const labelTextTypePlateforme: string = "Type de plateforme";

    const labelTypePlateforme = (
        <VoxLabel
            id={idLabelTypePlateforme}
            startIconLabel={startIconLabelTypePlateforme}
            label={labelTextTypePlateforme}
            required
        />
    );

    const alertErrorChargementPlateformes = (
        <>
            {labelTypePlateforme}
            <VoxAlert severity={"error"}>
                Une erreur est survenue lors du chargement des plateformes.
            </VoxAlert>
        </>
    )

    const affichagePlateforme = (plateformeAvecDefinition: string) => (
        <Box id={"champ-type-plateforme-readonly"}>
            <VoxLabel
                id={"plateforme"}
                startIconLabel={startIconLabelTypePlateforme}
                label={labelTextTypePlateforme}
                required
            />
            <Box sx={{minHeight: 46, display: 'flex', flexDirection: "row", alignItems: "center"}}>
                <Typography component={"p"} variant={"body2"} sx={{fontWeight: 1000}}>
                    {plateformeAvecDefinition}
                </Typography>
            </Box>
        </Box>
    )

    let selectTypePlateforme;
    if (
        props.typeFormulaire === "modification" &&
        (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA || operation.type === TypeOperation.ACTIONNARIAT)
    ) {
        // Affichage de la plateforme présente en base de données pour les opération Saturne, MailEva et Actionnariat (non modifiable)
        selectTypePlateforme = affichagePlateforme(`${operation.plateforme.nom} (${operation.plateforme.definition})`);
    } else {

        if (isLoadingListePlateformes) {
            selectTypePlateforme = (
                <>
                    {labelTypePlateforme}
                    <VoxLoaderInputFormulaire/>
                </>
            );
        } else if (isLoadListePlateformesError) {
            selectTypePlateforme = alertErrorChargementPlateformes;
        } else {
            if (
                props.typeFormulaire === "creation" &&
                (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA)
            ) {
                if (plateformeH1 !== undefined) {
                    // Affichage de la plateforme H1 pour les opérations Saturne et MailEva
                    selectTypePlateforme = affichagePlateforme(`${plateformeH1.nom} (${plateformeH1.definition})`);
                } else {
                    selectTypePlateforme = alertErrorChargementPlateformes;
                }
            } else if (props.typeFormulaire === "creation" && operation.type === TypeOperation.ACTIONNARIAT) {
                if (plateformeH2 !== undefined) {
                    // Affichage de la plateforme H2 pour les opérations Actionnariat
                    selectTypePlateforme = affichagePlateforme(`${plateformeH2.nom} (${plateformeH2.definition})`);
                } else {
                    selectTypePlateforme = alertErrorChargementPlateformes;
                }
            } else {
                selectTypePlateforme = (listePlateformes.length > 0) && (
                    <VoxSelectInput
                        id={"plateforme"}
                        idLabel={idLabelTypePlateforme}
                        startIconLabel={startIconLabelTypePlateforme}
                        label={labelTextTypePlateforme}
                        value={operation.plateforme.id}
                        onChange={handleOnChangePlateforme}
                        fullWidth
                        required={true}
                        disabled={props.isLoadingSubmit}
                    >
                        {
                            listePlateformes.map((plateforme: Plateforme) => {
                                return (
                                    <MenuItem key={plateforme.id} value={plateforme.id}>
                                        {plateforme.nom} ({plateforme.definition})
                                    </MenuItem>
                                )
                            })
                        }
                    </VoxSelectInput>
                );
            }
        }
    }


    /**
     * Input "version applicative spécifique souhaitée"
     * (positionné à deux endroits différents selon la taille de l'écran)
     */
    const inputVersionApplicativeSpecifiqueSouhaitee = (
        <>
            <VoxTextField
                id={"versionApplicativeSpecifiqueSouhaitee"}
                startIconLabel={"pen"}
                label={"Version applicative spécifique souhaitée"}
                value={versionApplicativeSpecifiqueSouhaitee}
                onChange={handleOnChangeVersionSpecifiqueSouhaitee}
                pattern={"^([0-9]?[0-9])(.([0-9]?[0-9])){2}-.+$"}
                title={"La version spécifique demandée doit commencer avec ce format : XX.YY.ZZ-"}
                fullWidth
                required={true}
                disabled={props.isLoadingSubmit}
            />
            {(
                (props.typeFormulaire === "creation" && props.isError && !props.isErrorIdSalesforce && props.messageError.includes("[versionSpecifique]"))
                || (props.typeFormulaire === "modification" && props.messageError.includes("[versionSpecifique]"))
            ) &&
                <VoxAlert severity={"error"}>
                    {props.messageError.split("[versionSpecifique] ")}
                </VoxAlert>
            }
        </>
    );


    return (
        <Box component={"form"}
             onSubmit={(event: FormEvent<HTMLDivElement>) => {
                event.preventDefault();
                props.onSubmit(operation, paramsOperation, datesActionnariat);
             }}
        >

            <Grid container spacing={3} padding={2}>
                <Grid item xs={12}>
                    <VoxLegendChampsObligatoires/>
                </Grid>

                {/* Champ : Client */}
                <Grid item xs={12} sm={6}>
                    <VoxTextField
                        id={"nomClient"}
                        startIconLabel={"address-book"}
                        label={"Client"}
                        value={operation.nomClient}
                        onChange={handleOnChangeOperation}
                        fullWidth
                        required={true}
                        disabled={props.isLoadingSubmit}
                    />
                </Grid>

                {/* Champ : Salesforce ID */}
                <Grid item xs={12} sm={6}>
                    <VoxTextField
                        id={"salesforceId"}
                        startIconLabel={"id-badge"}
                        label={"Salesforce ID"}
                        value={operation.salesforceId ? operation.salesforceId : ""}
                        onChange={handleOnChangeOperation}
                        maxLength={38}
                        fullWidth
                        required={true}
                        disabled={props.isLoadingSubmit}
                    />
                    {(props.isErrorIdSalesforce) &&
                        <VoxAlert severity={"error"}>
                            {props.messageErrorIdSalesforce}
                        </VoxAlert>
                    }
                </Grid>

                {/* Champ : Portefeuille projet */}
                <Grid item xs={12} sm={6} id={"champ-portefeuille-projet"}>
                    {selectPortefeuilleProjet}
                </Grid>

                {/* Champ : Date d'installation */}
                <Grid item xs={12} sm={6} sx={{p: !props.isAffichageDateInstallation ? "0 !important" : "initial"}} id={"champ-date-installation"}>
                    {props.isAffichageDateInstallation &&
                    <>
                        <VoxCalendarInput
                            id={"dateInstallation"}
                            startIconLabel={"calendar-alt"}
                            label={"Date d'installation"}
                            value={
                                (operation.dateInstallation === null || operation.dateInstallation === undefined)
                                    ? undefined
                                    : new Date(dayjs(operation.dateInstallation).format("YYYY-MM-DD"))
                            }
                            onChange={handleOnChangeDate}
                            minDate={new Date()}
                            fullWidth
                            required={true}
                            disabled={props.isLoadingSubmit}
                        />
                        {(props.isErrorDateInstallation) &&
                            <VoxAlert severity={"error"}>
                                La date d'installation doit être renseignée.
                            </VoxAlert>
                        }
                    </>
                    }
                </Grid>

                {/* Champ : Produit */}
                <Grid item xs={12} sm={6} id={"champ-produit"}>
                    {(
                        (
                            (props.typeFormulaire === "creation") &&
                            (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA)
                        )
                        || props.typeFormulaire === "modification"
                    ) ?
                        <Box id={"champ-produit-readonly"}>
                            <VoxLabel
                                id={"type"}
                                startIconLabel={"list-ol"}
                                label={"Produit"}
                                required
                            />
                            <Box sx={{
                                minHeight: 46,
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <Typography component={"p"} variant={"body2"} sx={{fontWeight: 1000}}>
                                    {
                                        (
                                            (props.typeFormulaire === "creation") &&
                                            (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA)
                                        )
                                            ? TypeOperation.VOXCORE_ELECTION
                                            : operation.versionApplicative.typeProduit   // récupération de cette valeur (lors de la modification)
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        :
                        <VoxSelectInput
                            id={"type"}
                            idLabel={"type-label"}
                            startIconLabel={"list-ol"}
                            label={"Produit"}
                            value={operation.type}
                            onChange={handleOnChangeTypeProduitOperation}
                            fullWidth
                            required={true}
                            disabled={props.isLoadingSubmit}
                        >
                            <MenuItem
                                value={`${TypeOperation.VOXCORE_ELECTION}`}>{TypeOperation.VOXCORE_ELECTION}</MenuItem>
                            <MenuItem
                                value={`${TypeOperation.VOXCORE_AG}`}>{TypeOperation.VOXCORE_AG}</MenuItem>
                            <MenuItem
                                value={`${TypeOperation.ACTIONNARIAT}`}>{TypeOperation.ACTIONNARIAT}</MenuItem>
                        </VoxSelectInput>
                    }
                </Grid>

                {/* Champ : Version applicative souhaitée */}
                <Grid item xs={12} sm={6} sx={{p: !isAffichageVersionApplicativeSouhaitee ? "0 !important" : "initial"}}>
                    {/* Affichage du champ 'Version applicative souhaitée' :
                        - pour le type d'opération VoxCore-Election, VoxCore-AG, Saturne & Maileva, Actionnariat : pour tous les groupes autorisés à accéder à la page
                    */}
                    {isAffichageVersionApplicativeSouhaitee &&
                        <>
                            {selectVersionsApplicatives}
                        </>
                    }
                </Grid>

                {/* Champ : Version applicative spécifique souhaitée   (1er emplacement selon la taille d'écran) */}
                {/* Placement du champ après la "Version applicative souhaitée" lorsque l'on est en TAILLE d'écran 'xs' (inférieure à 'sm') */}
                {(isBreakpointDownSm && (versionApplicativeSouhaitee && versionApplicativeSouhaitee.version === "Version Spécifique")) &&
                    <Grid item xs={12}>
                        {inputVersionApplicativeSpecifiqueSouhaitee}
                    </Grid>
                }

                {/* Champ : Type de plateforme */}
                <Grid item xs={12} sm={6} id={"champ-type-plateforme"}>
                    {selectTypePlateforme}
                </Grid>

                {/* Champ : Version applicative spécifique souhaitée   (2ème emplacement selon la taille d'écran) */}
                {/* Placement du champ en-dessous de la "Version applicative souhaitée" lorsque l'on est en TAILLE d'écran 'sm' (supérieure à 'sm') */}
                {(!isBreakpointDownSm) &&
                    <Grid item xs={12} sm={6} sx={{p: (versionApplicativeSouhaitee === undefined || versionApplicativeSouhaitee.version !== "Version Spécifique") ? "0 !important" : "initial"}}>
                        {(versionApplicativeSouhaitee && versionApplicativeSouhaitee.version === "Version Spécifique") &&
                            inputVersionApplicativeSpecifiqueSouhaitee
                        }
                    </Grid>
                }

                {/* Champ : Préfixe du site de vote */}
                <Grid item xs={12} sm={6} id={"champ-prefixe-site-vote"}>
                    <Box sx={{display: 'flex', flexDirection: "column"}}>
                        <Grid item xs={12}>
                            <VoxTextField
                                id={"urlPartieClient"}
                                startIconLabel={"globe-americas"}
                                label={"Préfixe du site de vote"}
                                value={paramsOperation.urlPartieClient}
                                onChange={handleOnChangeUrlPartieClient}
                                pattern={"[a-z0-9\\-]+"}
                                title={"Seuls les caractères minuscules alpha-numériques ainsi que '-' sont autorisés"}
                                minLength={3}
                                maxLength={55}
                                fullWidth
                                required={true}
                                disabled={props.isLoadingSubmit}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={"p"} variant={"body2"}
                                        sx={{pt: 1, pb: 0.5, color: "cobalt.lynch"}}>
                                {process.env.REACT_APP_SURVOX_URL_HTTPS}
                                {process.env.REACT_APP_SURVOX_URL_PREFIXE}
                                {
                                    (paramsOperation.urlPartieClient === "")
                                        ? "[prefixe]"
                                        : paramsOperation.urlPartieClient
                                }
                                {
                                    (
                                        operation.type === TypeOperation.VOXCORE_ELECTION
                                        && operation.plateforme.nom === NomPlateforme.H1
                                    ) ? `.${paramsOperation.urlDomaine}` : domaineUrl(operation.type)
                                }
                            </Typography>
                        </Grid>
                    </Box>
                    {(
                        (props.typeFormulaire === "creation" && props.isError && !props.isErrorIdSalesforce && props.messageError.includes("[urlClient]"))
                        || (props.typeFormulaire === "modification" && props.messageError.includes("[urlClient]"))
                    ) &&
                        <VoxAlert severity={"error"}>
                            {props.messageError.split("[urlClient] ")}
                        </VoxAlert>
                    }
                </Grid>

                {/* Champ : Domaine du site de vote */}
                <Grid item xs={12} sm={6} sx={{p: !isAffichageDomaineSiteDeVote ? "0 !important" : "initial"}}>
                    {/* L'utilisateur peut sélectionner le nom de domaine lorsqu'il s'agit d'une opération
                                    avec les conditions suivantes (exemple : optivox) :
                                        -> type: VoxCore-Election
                                        -> hébergeur: OVH
                                        -> plateforme: H1
                                */}
                    {isAffichageDomaineSiteDeVote &&
                        <VoxSelectInput
                            id={"urlDomaine"}
                            idLabel={"urlDomaine-label"}
                            startIconLabel={"globe-americas"}
                            label={"Domaine du site de vote"}
                            value={paramsOperation.urlDomaine}
                            onChange={handleOnChangeUrlDomaine}
                            fullWidth
                            required={true}
                            disabled={props.isLoadingSubmit}
                        >
                            <MenuItem id={"VOXINTIME"} value={Domaine.VOXINTIME}>{Domaine.VOXINTIME}</MenuItem>
                            <MenuItem id={"VOXALY_VOTE"} value={Domaine.VOXALY_VOTE}>{Domaine.VOXALY_VOTE}</MenuItem>
                        </VoxSelectInput>
                    }
                </Grid>

                {/* Champ : Mode test */}
                <Grid item xs={12} sm={6} sx={{p: !isAffichageModeTest ? "0 !important" : "initial"}}>
                    {/* Affichage du champ 'Mode test' seulement pour les utilisateurs Administrateur, Support SI Infra ou Support SI Dev
                                    & lorsqu'il s'agit d'une opération dont le type de produit est VOXCORE-ELECTION ou VOXCORE-AG (dont Saturne et Maileva) */}
                    {isAffichageModeTest &&
                    <>
                        <VoxLabel
                            id={"modeTest"}
                            startIconLabel={"question-circle"}
                            label={"Mode test (Op id à 8000)"}
                        />
                        <Grid item sx={{
                            blockSize: 46,
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <FormControlLabel
                                sx={{ml: 0}}
                                control={<Checkbox name={"mode-test"} checked={paramsOperation.isModeTest} onChange={handleOnChangeTrueFalse}/>}
                                label={paramsOperation.isModeTest ? "Mode test actif" : "Mode test inactif"}
                                disabled={props.isLoadingSubmit}
                            />
                        </Grid>
                    </>
                    }
                </Grid>

                <Grid item xs={12} sm={6} sx={{p: "0 !important"}}/>

                {(operation.type === TypeOperation.ACTIONNARIAT) &&
                <>
                    {/* Champ : Date d'ouverture */}
                    <Grid item xs={12} sm={6}>
                        <VoxCalendarInput
                            id={"dateOuverture"}
                            startIconLabel={"calendar-alt"}
                            label={"Date d'ouverture"}
                            value={(datesActionnariat.dateOuverture === null || datesActionnariat.dateOuverture === undefined) ? undefined : new Date(dayjs(datesActionnariat.dateOuverture).format("YYYY-MM-DD"))}
                            onChange={handleOnChangeDateActionnariat}
                            minDate={new Date()}
                            maxDate={datesActionnariat.dateFermeture ? new Date(dayjs(datesActionnariat.dateFermeture).subtract(1, "day").format("YYYY-MM-DD")) : undefined}
                            fullWidth
                            required={true}
                            disabled={props.isLoadingSubmit}
                        />
                        {(props.isErrorDateOuverture) &&
                            <VoxAlert severity={"error"}>
                                La date d'ouverture doit être renseignée.
                            </VoxAlert>
                        }
                    </Grid>

                    {/* Champ : Date de clôture */}
                    <Grid item xs={12} sm={6}>
                        <VoxCalendarInput
                            id={"dateFermeture"}
                            startIconLabel={"calendar-alt"}
                            label={"Date de clôture"}
                            value={(datesActionnariat.dateFermeture === null || datesActionnariat.dateFermeture === undefined) ? undefined : new Date(dayjs(datesActionnariat.dateFermeture).format("YYYY-MM-DD"))}
                            onChange={handleOnChangeDateActionnariat}
                            minDate={datesActionnariat.dateOuverture ? new Date(dayjs(datesActionnariat.dateOuverture).add(1, "day").format("YYYY-MM-DD")) : new Date()}
                            fullWidth
                            required={true}
                            disabled={props.isLoadingSubmit}
                        />
                        {(props.isErrorDateFermeture) &&
                            <VoxAlert severity={"error"}>
                                La date de clôture doit être renseignée.
                            </VoxAlert>
                        }
                    </Grid>
                </>
                }

                {(operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA) &&
                <>
                    <Grid item xs={12}>
                        <Typography component={"label"} variant={"h4"}>
                            Options
                        </Typography>
                    </Grid>

                    {/* Champ : Envoi des codes */}
                    <Grid item xs={12} sm={6} id={"champ-envoi-codes"}>
                        <VoxSelectInput
                            id={"optionEnvoi"}
                            idLabel={"option-envoi-label"}
                            startIconLabel={"list-ol"}
                            label={"Envoi des codes"}
                            value={(paramsOperation.optionEnvoi === null) ? "" : paramsOperation.optionEnvoi}
                            onChange={handleOnChangeOptions}
                            fullWidth
                            required={operation.type === TypeOperation.MAILEVA}
                            disabled={props.isLoadingSubmit}
                        >
                            <MenuItem id="autre-canal" value={""}><em>Autre canal</em></MenuItem>
                            <MenuItem value={TypeOption.COURRIER}>100% courrier</MenuItem>
                            <MenuItem value={TypeOption.EMAIL}>100% email</MenuItem>
                        </VoxSelectInput>
                    </Grid>

                    {/* Champ : Courrier */}
                    <Grid item xs={12} sm={6} sx={{p: (paramsOperation.optionEnvoi !== TypeOption.COURRIER) ? "0 !important" : "initial"}}>
                        {(paramsOperation.optionEnvoi === TypeOption.COURRIER) &&
                            <VoxSelectInput
                                id={"optionPapier"}
                                idLabel={"option-papier-label"}
                                startIconLabel={"list-ol"}
                                label={"Courrier"}
                                value={(paramsOperation.optionPapier === null) ? "" : paramsOperation.optionPapier}
                                onChange={handleOnChangeOptions}
                                fullWidth
                                required={operation.type === TypeOperation.MAILEVA}
                                disabled={props.isLoadingSubmit}
                            >
                                <MenuItem value={""}>Autre sélection</MenuItem>
                                <MenuItem value={TypeOption.RECTO_VERSO}>Recto/verso (avec notice)</MenuItem>
                                <MenuItem value={TypeOption.RECTO}>Recto (codes uniquement)</MenuItem>
                            </VoxSelectInput>
                        }
                    </Grid>
                </>
                }

                {/* Champ : Description */}
                <Grid item xs={12}>
                    <VoxTextArea
                        id={"description"}
                        startIconLabel={"comment-dots"}
                        label={"Description"}
                        value={operation.description}
                        onChange={handleOnChangeOperation}
                        multilineMaxRows={3}
                        minRows={3}
                        disabled={props.isLoadingSubmit}
                    />
                </Grid>

                <VoxButton
                    id={"btn-commander-operation"}
                    customColor={"green"}
                    startIcon={<FontAwesomeIcon icon={"save"}/>}
                    sx={{fontSize: "0.95rem", py: 2.8, margin: '2rem auto 0'}}
                    type={"submit"}
                    disabled={props.isLoadingSubmit}
                >
                    {(props.typeFormulaire === "creation") ? "Commander une opération" : "Valider"}
                </VoxButton>
            </Grid>
        </Box>
    )
}