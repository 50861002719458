import React from "react";
import { Typography } from "@mui/material";
import VoxModal from "./voxModal";

type ModalePortefeuilleProjetPropsType = {
    show : boolean
    handleClose: () => void
    handleSubmit: () => void
    nbOperationsPortefeuilleProjet: number
    isLoadingSuppression: boolean
}


export default function ModaleConfirmationSuppressionPortefeuilleProjet(props: ModalePortefeuilleProjetPropsType) {

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Suppression d'un portefeuille projet"}
            content={
                <Typography variant={"body1"} component={"div"}>
                    Êtes-vous sûr de vouloir supprimer ce portefeuille projet ?
                    {
                        (props.nbOperationsPortefeuilleProjet !== 0) &&
                        <div>Les opérations rattachées à ce portefeuille projet vont basculer vers le portefeuille projet "Anonyme".</div>
                    }
                </Typography>
            }
            closeButtonText={"Non"}
            submitButtonText={"Oui, je confirme"}
            isValidationLoading={props.isLoadingSuppression}
        />
    )
}