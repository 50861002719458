import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import operationService from "../../../services/survox-back/operation/operation.service";
import { Operation } from "../../../models/operation/operation.model";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import { Hebergeur } from "../../../models/operation/hebergeur.enum";
import { NomPlateforme } from "../../../models/operation/nomPlateforme.enum";
import OperationsModificationSurveillanceQRadarForm from "../../../components/forms/operationsFormulaires/operationsModificationSurveillanceQRadarForm";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import { OperationSurveillanceQRadarType, ParamsSurveillanceQRadarType } from "../../../services/survox-back/operation/dtos/request/updateSurveillanceQRadar.dto";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";


export default function OperationsModificationSurveillanceQRadar() {
    const params = useParams();
    const navigate = useNavigate();

    const [operation, setOperation] = useState<Operation>();

    const [isLoadingOperation, setIsLoadingOperation] = useState<boolean>(false);
    const [isLoadingError, setIsLoadingError] = useState<boolean>(false);

    const [isUpdateOperation, setIsUpdateOperation] = useState<boolean>(false);
    const [isUpdateError, setIsUpdateError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");


    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        if (params.id) {
            chargerOperation(Number(params.id), controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [params.id]);

    /**
     * Méthode qui permet de charger l'opération dont l'on souhaite modifier la surveillance QRadar
     * @param id
     * @param controller
     */
    const chargerOperation = (id: number, controller: AbortController) => {
        setIsLoadingOperation(true);
        setIsLoadingError(false);
        operationService.getOperation(id, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // récupération de l'opération
                    setOperation(response.data);
                    setIsLoadingOperation(false);
                    setIsLoadingError(false);
                }
            })
            .catch((err) => {
                if (isMountedRef.current && err.message !== "canceled") {
                    setIsLoadingOperation(false);
                    setIsLoadingError(true);
                }
            });
    };

    /**
     * Enregistrement des modifications de la surveillance QRadar de l'opération sélectionnée
     */
    const onUpdateSurveillanceQRadar = useCallback((operationSurveillanceQRadar: OperationSurveillanceQRadarType, paramsSurveillanceQRadar: ParamsSurveillanceQRadarType) => {
        setIsUpdateOperation(true);
        setIsUpdateError(false);
        setErrorMessage("");
        const updateSurveillanceQRadarDto = { ...operationSurveillanceQRadar, ...paramsSurveillanceQRadar };
        operationService.updateSurveillanceQRadarOperation(Number(params.id), updateSurveillanceQRadarDto)
            .then((response) => {
                if (isMountedRef.current && response.status === 200 && !response.data.error) {
                    setIsUpdateOperation(false);
                    setIsUpdateError(false);
                    setErrorMessage("");
                    navigate(-1);
                } else {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                    setErrorMessage(response.data.error);
                }
            })
            .catch(() => {
                if (isMountedRef.current) {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                }
            })
    }, [navigate, params.id]);

    let surveillanceQRadarForm = null;
    if (isLoadingOperation) {
        surveillanceQRadarForm = (
            <VoxLoader />
        );
    } else if (operation) {
        if (
            operation.hebergeur === Hebergeur.DOCAPOSTE
            && (operation.plateforme.nom === NomPlateforme.H4 || operation.plateforme.nom === NomPlateforme.H5)
            && operation.type === TypeOperation.VOXCORE_ELECTION
            && !operation.archivee
        ) {
            surveillanceQRadarForm = (
                <>
                    <OperationsModificationSurveillanceQRadarForm
                        operation={operation}
                        onUpdate={onUpdateSurveillanceQRadar}
                    />
                    {
                        isUpdateOperation ? <VoxLoader isBackgroundColor={true} /> : null
                    }
                </>
            );
        } else {
            surveillanceQRadarForm = (
                <VoxAlert severity={"warning"} sx={{ mb: 4 }}>
                    La surveillance QRadar n'est applicable que pour les opérations chez l'hébergeur
                    Docaposte pour une plateforme sur-mesure.
                </VoxAlert>
            )
        }
    }

    return (
        <VoxCard title={"Modification de la surveillance QRadar"}>
            {
                // Affichage de l'erreur de chargement des données si il y en a une
                isLoadingError
                    ? (
                        <VoxAlert severity={"error"}>
                            Une erreur est survenue lors du chargement de l'opération.
                        </VoxAlert>
                    ) : null
            }

            {
                // Affichage de l'erreur de la modification si il y en a une
                isUpdateError
                    ? (
                        <VoxAlert severity={"error"}>
                            {errorMessage
                                ? errorMessage
                                : "Une erreur est survenue lors de l'enregistrement des modifications apportées à la surveillance QRadar de l'opération."
                            }
                        </VoxAlert>
                    ) : null
            }

            {surveillanceQRadarForm}
        </VoxCard>
    )
}