import React, { useCallback, useMemo, useState } from "react";
import { ColumnInstance } from "react-table";
import { Box, Checkbox, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

export default function ColumnVisibilityFilter<Type extends object>(
    {
        id,
        allColumns,
        visibleColumns,
        handleColumnVisibilityChange
    }: {
        id?: string,
        allColumns: ColumnInstance<Type>[],
        visibleColumns: ColumnInstance<Type>[],
        handleColumnVisibilityChange?: (visibleColumnsId: string[]) => void
    }
) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleOnChangeColumnVisibility = useCallback((event: any, column: ColumnInstance<Type>) => {
        const prevStateIsChecked = column.getToggleHiddenProps().checked;
        if (handleColumnVisibilityChange) {
            if (!prevStateIsChecked) {
                handleColumnVisibilityChange([...visibleColumns, column].map(item => item.id));
            } else {
                handleColumnVisibilityChange(visibleColumns.filter(item => item.id !== column.id).map(item => item.id));
            }
        }
        column.getToggleHiddenProps().onChange(event);
    }, [visibleColumns, handleColumnVisibilityChange]);


    const isPopoverOpened = useMemo(() => Boolean(anchorEl), [anchorEl]);

    return (
        <Box id={id}>
            <ToggleButton
                color={"secondary"}
                value={"check"}
                selected={!!anchorEl}
                onChange={handleClick}
                title={"Colonnes"}
            >
                <CobaltIcon name={"columns"}/>
                <CobaltIcon name={"chevron-down"} sx={{ml: 0.5}}/>
            </ToggleButton>
            <Popover
                id={isPopoverOpened ? 'columnVisibility-popover' : undefined}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                elevation={2}
            >
                {
                    allColumns.map(column => (
                        <ListItemButton
                            key={column.id}
                            role="listitem"
                            component={"label"}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={column.getToggleHiddenProps().checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': column.id,
                                    }}
                                    onChange={(e) => handleOnChangeColumnVisibility(e, column)}

                                />
                            </ListItemIcon>
                            <ListItemText id={column.id} primary={column.render('Header')} />
                        </ListItemButton>
                    ))
                }
            </Popover>
        </Box>
    )
}