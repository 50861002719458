import axios_instance from "../axios-client";
import { CreateOperationSIRSDto } from "./dtos/request/createOperationSIRS.dto";
import { UpdateAcquittementOperationDto } from "../operation/dtos/request/updateAcquittementOperation.dto";
import { UpdateOperationSIRSDto } from "./dtos/request/updateOperationSIRS.dto";
import { FiltresOperationSIRSEnProductionDto } from "./dtos/request/filtresOperationSIRSEnProduction.dto";

const operationSIRSService = {

    /**
     * Méthodes de récupération de données SIRS
     */

    getOperationSIRSAvecRelations: (id: number, signal?: AbortSignal) => {
        return axios_instance.get(`operation_sirs/detail/${id}`, { signal });
    },

    getAllOperationsSIRSByCurrentUtilisateur: (signal?: AbortSignal) => {
        return axios_instance.get('operation_sirs/mes_operations', { signal });
    },

    getOperationSIRSAvecContactsClientById: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`operation_sirs/avec_contacts_client/${id}`, { signal });
    },

    getOperationsSIRSAInitialiser: (signal?: AbortSignal) => {
        return axios_instance.get('operation_sirs/initialiser', { signal });
    },

    getAllOperationSIRSEnProduction: (
        filtresOperationSIRSEnProductionDto?: FiltresOperationSIRSEnProductionDto,
        signal?: AbortSignal
    ) => {
        return axios_instance.get("operation_sirs/production", {
            params: filtresOperationSIRSEnProductionDto,
            signal: signal
        });
    },


    /**
     * Méthodes de création SIRS
     */

    createOperationSIRS: (createOperationSIRS: CreateOperationSIRSDto) => {
        return axios_instance.post('operation_sirs', createOperationSIRS);
    },


    /**
     * Méthodes de modification SIRS
     */

    updateOperationSIRS: (id: number, updateOperationSIRSDto: UpdateOperationSIRSDto) => {
        return axios_instance.put(`operation_sirs/modification/${id}`, updateOperationSIRSDto)
    },

    updateAcquittementOperationSIRS: (id: number, updateAcquittementOperationDto: UpdateAcquittementOperationDto) => {
        return axios_instance.put(`operation_sirs/acquittement/${id}`, updateAcquittementOperationDto);
    },

    updateOperationSIRSEnProductionDocaposte: (id: number) => {
        return axios_instance.patch(`operation_sirs/enProductionDocaposte/${id}`);
    },


    /**
     * Méthodes de suppression SIRS
     */

    getOperationsSIRSASupprimer: (signal?: AbortSignal) => {
        return axios_instance.get('operation_sirs/a_supprimer', { signal });
    },

    getOperationsSIRSSupprimees: (signal: AbortSignal) => {
        return axios_instance.get('operation_sirs/supprimees', { signal });
    },

    demandeSuppressionOperationSIRS: (id: number) => {
        return axios_instance.patch(`operation_sirs/demander_suppression/${id}`);
    },

    deleteOperationSIRS: (id: number) => {
        return axios_instance.delete(`operation_sirs/supprimer/${id}`);
    }
}

export default operationSIRSService;