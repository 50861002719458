import axios_instance from "../axios-client";

const auditService =  {

    getAllAudit: (startDate: Date, endDate: Date, signal: AbortSignal) => {
        return axios_instance.get('/audits', {
            params: { startDate: startDate, endDate: endDate },
            signal: signal
        });
    },

    getAuditByOperation: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`/audits/par_operation/${id}`, {signal: signal});
    },

    getAuditByOperationSIRSId: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`/audits/par_operation_sirs/${id}`, {signal: signal});
    }

}

export default auditService;