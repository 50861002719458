import { Navigate, Route, Routes } from "react-router-dom";
import OperationsAInitialiserListe from "../../components/operationsListe/operationsAInitialiserListe";
import OperationsModification from "../../components/forms/operationsFormulaires/operationsModification";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsSaturneAInitialiserView() {
    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Opérations Saturne à initialiser"}/>
                    <OperationsAInitialiserListe typeOperation={TypeOperation.SATURNE}/>
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/saturne/initialiser"}/>
                    <OperationsModification typesOperation={[TypeOperation.SATURNE]}/>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}