import axios_instance from "../axios-client";
import { CreateOperationSaturneMailevaWithParamsDto } from "./dtos/request/createOperationSaturneMaileva.dto";
import { UpdateOperationSaturneMailevaWithParamsDto } from "./dtos/request/updateOperationSaturneMaileva.dto";
import { UpdateAcquittementOperationDto } from "./dtos/request/updateAcquittementOperation.dto";
import { FiltresAffichageDto } from "./dtos/request/filtresAffichage.dto";
import { CreateOperationWithParamsDto } from "./dtos/request/createOperation.dto";
import { UpdateOperationWithParamsDto } from "./dtos/request/updateOperation.dto";
import { FiltresDashboardDto } from "./dtos/request/filtresDashboard.dto";
import { CreateOperationActionnariatWithParamsAndDatesDto } from "./dtos/request/createOperationActionnariat.dto";
import { UpdateOperationActionnariatWithParamsAndDatesDto } from "./dtos/request/updateOperationActionnariat.dto";
import { UpdateSurveillanceQRadarDto } from "./dtos/request/updateSurveillanceQRadar.dto";
import { UpdateAvanceeOperationDto } from "./dtos/request/updateAvanceeOperation.dto";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { OperationsASupprimerForRappelType } from "../../../models/operation/operationsASupprimerForRappelType.model";
import { NomGroupe } from "../../../models/utilisateur/nomGroupe.enum";
import dayjs from "dayjs";
import {InitVMnameDto} from "./dtos/request/initVMname.dto";


const operationService =  {

    /**
     * Méthodes de récupération de données
     */

    getOperationsUtilisateurCourant: (signal?: AbortSignal) => {
        return axios_instance.get('/operations/mes_operations', { signal });
    },

    getAllOperationsEnProduction: (signal?: AbortSignal) => {
        return axios_instance.get('/operations/production', { signal });
    },

    filtrer: (filtresAffichageDto: FiltresAffichageDto) => {
        return axios_instance.get('/operations/production', { params: filtresAffichageDto });
    },

    filtrerParDashboard: (filtresDashboardDto: FiltresDashboardDto, signal?: AbortSignal) => {
        return axios_instance.get('/operations/production', { params: filtresDashboardDto, signal: signal });
    },

    getOperation: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`operations/operation/${id}`, { signal });
    },

    getOperationAvecRelations: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`operations/detail/${id}`, { signal });
    },

    getOperationAvecOptions: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`operations/avec_options/${id}`, { signal });
    },

    getAllOperationsSaturneByCurrentUtilisateur: (signal?: AbortSignal) => {
        return axios_instance.get('operations/saturne/mes_operations', { signal });
    },

    getAllOperationsMailevaByCurrentUtilisateur: (signal?: AbortSignal) => {
        return axios_instance.get('operations/maileva/mes_operations', { signal });
    },

    getAllOperationsVoxCoreActionnariatByCurrentUtilisateur: (signal?: AbortSignal) => {
        return axios_instance.get('operations/voxcore_actionnariat/mes_operations', { signal });
    },

    getOperationsVoxcoreActionnariatAInitialiser: (signal?: AbortSignal) => {
        return axios_instance.get('operations/voxcore_actionnariat/initialiser', { signal });
    },

    getOperationsSaturneAInitialiser: (signal?: AbortSignal) => {
        return axios_instance.get('operations/saturne/initialiser', { signal });
    },

    getOperationsMailevaAInitialiser: (signal?: AbortSignal) => {
        return axios_instance.get('operations/maileva/initialiser', { signal });
    },

    getAllOperationsArchivees: (signal?: AbortSignal) => {
        return axios_instance.get('operations/archivees', { signal });
    },

    getAllOperations : (signal?: AbortSignal) => {
        return axios_instance.get('operations/all', { signal });
    },

    getDataDashboard: (signal?: AbortSignal) => {
        return axios_instance.get("operations/data_dashboard", { signal })
    },


    /**
     * Méthodes de création
     */

    createOperationSaturne: (createOperationSaturneDto: CreateOperationSaturneMailevaWithParamsDto) => {
        return axios_instance.post('/operations/saturne', createOperationSaturneDto );
    },

    createOperationMaileva: (createOperationMailevaDto: CreateOperationSaturneMailevaWithParamsDto) => {
        return axios_instance.post('/operations/maileva', createOperationMailevaDto);
    },

    createOperation: (createOperationDto: CreateOperationWithParamsDto) => {
        return axios_instance.post('/operations', createOperationDto);
    },

    createOperationActionnariat: (createOperationActionnariatDto: CreateOperationActionnariatWithParamsAndDatesDto) => {
        return axios_instance.post('/operations/actionnariat', createOperationActionnariatDto);
    },


    /**
     * Méthodes de modification
     */

    updateOperationSaturne: (id: number, updateOperationSaturneDto: UpdateOperationSaturneMailevaWithParamsDto) => {
        return axios_instance.put(`/operations/saturne/${id}`, updateOperationSaturneDto);
    },

    updateOperationMaileva: (id: number, updateOperationMailevaDto: UpdateOperationSaturneMailevaWithParamsDto) => {
        return axios_instance.put(`/operations/maileva/${id}`, updateOperationMailevaDto);
    },

    updateOperation: (id: number, updateOperationDto: UpdateOperationWithParamsDto) => {
        return axios_instance.put(`/operations/${id}`, updateOperationDto);
    },

    updateOperationActionnariat: (id: number, updateOperationActionnariatDto: UpdateOperationActionnariatWithParamsAndDatesDto) => {
        return axios_instance.put(`/operations/actionnariat/${id}`, updateOperationActionnariatDto);
    },

    installerOperation: (id: number) => {
        return axios_instance.get(`/operations/installer/${id}`);
    },

    updateAcquittementOperation: (id: number, updateAcquittementOperationDto: UpdateAcquittementOperationDto) => {
        return axios_instance.put(`/operations/acquittement/${id}`, updateAcquittementOperationDto);
    },

    updateSurveillanceQRadarOperation: (id: number, updateSurveillanceQRadarDto: UpdateSurveillanceQRadarDto) => {
        return axios_instance.put(`/operations/surveillanceQRadar/${id}`, updateSurveillanceQRadarDto);
    },

    updateOperationEnProductionDocaposte: (id: number, initVMnameDto: InitVMnameDto) => {
        return axios_instance.patch(`/operations/enProductionDocaposte/${id}`, initVMnameDto);
    },

    updateInitialisationOperationDocaposte: (id: number, initVMnameDto: InitVMnameDto) => {
        return axios_instance.patch(`/operations/initialisationOperationDocaposte/${id}`, initVMnameDto);
    },

    miseAJourAvanceeOperation: (id: number, updateAvanceeOperationDto: UpdateAvanceeOperationDto) => {
        return axios_instance.put(`/operations/modificationAvancee/${id}`, updateAvanceeOperationDto);
    },


    /**
     * Méthodes de suppression
     */

    getAllOperationsASupprimer: (signal?: AbortSignal) => {
        return axios_instance.get('operations/supprimer', { signal });
    },

    getOperationsSaturneASupprimer: (signal?: AbortSignal) => {
        return axios_instance.get('operations/supprimer/saturne', { signal });
    },

    getOperationsMailevaASupprimer: (signal?: AbortSignal) => {
        return axios_instance.get('operations/supprimer/maileva', { signal });
    },

    getOperationsASupprimerByGroupeUtilisateur: () => {
        return axios_instance.get('operations/supprimer/utilisateur');
    },

    demanderArchivageOperationProduction: (id: number) => {
        return axios_instance.patch(`operations/demander_archivage/${id}`);
    },

    demanderSuppressionOperationProduction: (id: number) => {
        return axios_instance.patch(`operations/demander_suppression/${id}`);
    },

    allowedDeleteOperationSaturne: (id: number) => {
        return axios_instance.get(`/operations/suppression_possible/saturne/${id}`);
    },

    deleteOperationSaturne: (id: number) => {
        return axios_instance.delete(`/operations/supprimer/saturne/${id}`);
    },

    allowedDeleteOperationMaileva: (id: number) => {
        return axios_instance.get(`/operations/suppression_possible/maileva/${id}`);
    },

    deleteOperationMaileva: (id: number) => {
        return axios_instance.delete(`/operations/supprimer/maileva/${id}`);
    },

    allowedDeleteOperation: (id: number) => {
        return axios_instance.get(`/operations/suppression_possible/${id}`);
    },

    deleteOperation: (id: number) => {
        return axios_instance.delete(`/operations/supprimer/${id}`);
    },

    alerteAvantSuppressionPourSuppressionAvancee: (id : number) => {
        return axios_instance.get(`operations/controle_avant_suppression/${id}`);
    },

    verificationAffichageModaleDeRappel(utilisateur: Utilisateur): Promise<{affichageModaleRappel: boolean, data: OperationsASupprimerForRappelType[]} | null> {
        // Affichage d'une modale de rappel concernant les opérations à supprimer
        // - pour les personnes appartenant aux groupes suivants : RESPONSABLE_PROJETS_VOTE, CHEF_DE_PROJET_VOTE, CHEF_DE_PROJET_PACKAGE
        // - et si la date de dernière connexion de l'utilisateur est antérieure à date actuelle à 00:00:00
        if (
            [NomGroupe.RESPONSABLE_PROJETS_VOTE, NomGroupe.CHEF_DE_PROJET_VOTE, NomGroupe.CHEF_DE_PROJET_PACKAGE].includes(utilisateur.groupe.nom)
             && (!utilisateur.derniereConnexion || dayjs(utilisateur.derniereConnexion).isBefore(dayjs().startOf('day')))
        ) {
            // Récupération des opérations pour savoir si on affiche ou non la modale
            return this.getOperationsASupprimerByGroupeUtilisateur()
                .then(response => {
                    if (response.status === 200 && response.data.length !== 0) {
                        // On affichera la modale de rappel
                        return {affichageModaleRappel: true, data: response.data};
                    } else {
                        return null;
                    }
                })
                .catch(() => {
                    return null;
                })
        } else {
            return Promise.resolve(null);
        }
    }

}

export default operationService;