import OperationsAInitialiserListe from "../../components/operationsListe/operationsAInitialiserListe";
import {Navigate, Route, Routes} from "react-router-dom";
import React, {useContext} from "react";
import OperationsModification from "../../components/forms/operationsFormulaires/operationsModification";
import {TypeOperation} from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import EnvironnementsAInitialiser from "../../components/environnements/environnementsAInitialiser";
import OperationsSIRSAInitialiserListe from "../../components/operationsSIRSListe/operationsSIRSAInitialiserListe";
import {AuthContext} from "../../contexts/authentication/authentication.context";
import {Utilisateur} from "../../models/utilisateur/utilisateur.model";
import {NomGroupe} from "../../models/utilisateur/nomGroupe.enum";


export default function OperationsAInitialiserView() {
    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    return (
        <Routes>
            <Route path={""} element={
                <>
                    {/* Suite au déplacement de l'écran Opérations SIRS à initialiser
                    On restreint l'affichage des Opérations VoxCore et Actionnariat
                    et des Environnements Hors Production */}
                    {
                        (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                        || utilisateur.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
                        || utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE) &&
                        <>
                            <VoxHeader title={"Opérations VoxCore & Actionnariat à initialiser"}/>
                            <OperationsAInitialiserListe typeOperation={"VoxCoreElection_VoxCoreAG_Actionnariat"}/>
                            <VoxHeader title={"Environnements hors production à initialiser"} marginTop={6}/>
                            <EnvironnementsAInitialiser/>
                        </>
                    }
                    {
                        (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV_SIRS
                        || utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_SIRS) &&
                        <>
                            <VoxHeader title={"Opérations SIRS à initialiser"}
                                       marginTop={(utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV_SIRS
                                           || utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_SIRS) ? 0 : 6}
                            />
                            <OperationsSIRSAInitialiserListe/>
                        </>
                    }
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/operations/initialiser"}/>
                    <OperationsModification
                        typesOperation={[
                            TypeOperation.VOXCORE_ELECTION,
                            TypeOperation.VOXCORE_AG,
                            TypeOperation.ACTIONNARIAT
                        ]}/>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}