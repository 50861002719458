import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {VersionApplicative} from "../../models/versionApplicative/versionApplicative.model";
import {CellProps, Column, IdType, Row} from "react-table";
import {VersionApplicativeTable} from "../../models/versionApplicative/versionsApplicativesTable.model";
import {SelectBooleanInputFilter} from "../genericComponentsUI/table/filters/SelectBooleanInputFilter";
import {sortDatesInTables} from "../../utils/date.utils";
import {affichageDateWithFormatDMYHm} from "../../utils/string.utils";
import {isComposantsAvecNomLivrable} from "../../utils/versionApplicative.utils";
import {TypeProduit} from "../../models/versionApplicative/typeProduit.enum";
import {useNavigate} from "react-router-dom";
import versionApplicativeService from "../../services/survox-back/versionApplicative/version-applicative.service";
import { Box } from "@mui/material";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import VoxCard from "../genericComponentsUI/card/voxCard";
import {VoxAlert} from "../genericComponentsUI/alerte/voxAlert";
import VoxButton from "../genericComponentsUI/button/voxButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VoxTable from "../genericComponentsUI/table/voxTable";
import ModaleConfirmationVersionsApplicatives from "../modals/modaleConfirmationVersionsApplicatives";
import ModaleConfirmationArchivageVersionsProduits from "../modals/modaleConfirmationArchivageVersionsProduits";
import {CheckboxCell} from "../genericComponentsUI/table/cells/checkboxCell";
import {getTypeLivrableDisplayedText} from "../../models/versionApplicative/typeLivrable.enum";


type VersionsApplicativesListeProps = {
    typeProduit: TypeProduit;
    versionsApplicatives: VersionApplicative[];
    isLoadingVersionsApplicatives: boolean;
    loadData: () => void; 
}

export const VersionsApplicativesListe = (
    { 
        typeProduit, 
        versionsApplicatives, 
        isLoadingVersionsApplicatives,
        loadData 
    }: Readonly<VersionsApplicativesListeProps>
) => {

    const [pageIndex, setPageIndex] = useState<number>(0);

    // Liste des versions applicatives affichées dans le tableau et modifiable par l'utilisateur
    const [updateVersionsApplicatives, setUpdateVersionsApplicatives] = useState<VersionApplicative[]>(versionsApplicatives.map(version => ({...version})));

    // Liste des versions applicatives qui seront archivée dans le cas où l'utilisateur déclenche l'archivage de masse
    const [versionsApplicativesArchivees, setVersionsApplicativesArchivees] = useState<VersionApplicative[]>([]);

    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isSuccessUpdate, setIsSuccessUpdate] = useState<boolean>(false);
    const [isErrorUpdate, setIsErrorUpdate] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [showConfirmationModificationModal, setShowConfirmationModificationModal] = useState<boolean>(false);
    const [showConfirmationArchivageModal, setShowConfirmationArchivageModal] = useState<boolean>(false)

    const navigate = useNavigate();

    useEffect(
        () => setUpdateVersionsApplicatives(versionsApplicatives.map(version => ({...version}))), 
        [versionsApplicatives]
    );

    /**
     * Méthode appelée dès qu'une checkbox est cochée dans la colonne "Version par défaut" du tableau
     * afin de mettre à jour la valeur du champ "versionParDefaut" des versions applicatives
     */
    const handleOnClickVersionParDefaut = useCallback((event: ChangeEvent<HTMLInputElement>, idVersionApplicative: number) => {
        setUpdateVersionsApplicatives(prevState => {
            return prevState.map(version => {
                if (version.id === idVersionApplicative) {
                    version.versionParDefaut = event.target.checked;
                    version.archive = false;
                }
                return version;
            });
        });
    }, []);

    /**
     * Méthode appelée dès qu'une case est sélectionnée ou désélectionnée dans la colonne "Version à archiver" du tableau
     * afin de mettre à jour la valeur du champ "archive" de la version applicative correspondante
     */
    const handleOnClickVersionAArchiver = useCallback((event: ChangeEvent<HTMLInputElement>, idVersionApplicative: number) => {
        setUpdateVersionsApplicatives(prevState => {
            return prevState.map(version => {
                if (version.id === idVersionApplicative) {
                    version.archive = event.target.checked
                }
                return version;
            });
        });
    }, []);

    /**
     * Méthode permettant d'effectuer l'enregistrement des modifications auprès de l'API survox-back
     */
    const handleUpdateVersionsApplicatives = useCallback(() => {
        const updateVersionsApplicativesDto = {
            versionsApplicatives: updateVersionsApplicatives.filter(updateVersion => {
                const currentVersion = versionsApplicatives.find(version => version.id === updateVersion.id);
                return currentVersion && (
                    currentVersion.versionParDefaut !== updateVersion.versionParDefaut
                    || currentVersion.archive !== updateVersion.archive
                );
            }).map(version => ({id: version.id, versionParDefaut: version.versionParDefaut, archive: version.archive}))
        };

        setShowConfirmationModificationModal(false);
        setIsLoadingUpdate(true);
        setIsSuccessUpdate(false);
        setIsErrorUpdate(false);
        versionApplicativeService.updateVersionsApplicatives(updateVersionsApplicativesDto)
            .then((response) => {
                if (response.status === 200 && !response.data.error) {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(true);
                    setIsErrorUpdate(false);
                    // On recharge les versions applicatives après la mise à jour
                    loadData();
                } else {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(false);
                    setIsErrorUpdate(true);
                }
            })
            .catch((error) => {
                setIsLoadingUpdate(false);
                setIsSuccessUpdate(false);
                setIsErrorUpdate(true);
                if (error.response.status === 400 && error.response.data.message !== "Bad Request") {
                    // ex : "Vous ne pouvez pas archiver des versions courantes."
                    // ex : "Vous devez sélectionner une et une seule version applicative par défaut."
                    // ex : "Vous ne pouvez pas enregistrer une version par défaut ne possédant pas de composant."
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Une erreur est survenue lors de la validation des versions applicatives.");
                }
            });
    }, [updateVersionsApplicatives, versionsApplicatives, loadData]);

    /**
     * Archivage automatique des versions applicatives
     */
    const archiverVersionsApplicatives = () => {
        setShowConfirmationArchivageModal(false);
        setIsLoadingUpdate(true);
        setIsSuccessUpdate(false);
        setIsErrorUpdate(false);

        versionApplicativeService.archiverVersionsApplicatives(typeProduit)
            .then((resp) => {
                if (resp.status === 200 && !resp.data.error) {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(true);
                    setIsErrorUpdate(false);
                    // On recharge les versions applicatives après la mise à jour
                    loadData();
                } else {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(false);
                    setIsErrorUpdate(true);
                }
            })
            .catch((err) => {
                setIsLoadingUpdate(false);
                setIsSuccessUpdate(false);
                setIsErrorUpdate(true);
                setErrorMessage("Une erreur est survenue lors de l'archivage des versions applicatives.");
            });
    }

    const handleOnClickArchiverVersionsApplicatives = () => {
        setIsLoadingUpdate(true);
        setIsSuccessUpdate(false);
        setIsErrorUpdate(false);
        setShowConfirmationArchivageModal(false);

        versionApplicativeService.getVersionsArchivables(typeProduit)
            .then(response => {
                if (response.status === 200 && !response.data.error) {
                    setIsLoadingUpdate(false);
                    setVersionsApplicativesArchivees(response.data);
                    setShowConfirmationArchivageModal(true);
                } else {
                    setIsLoadingUpdate(false);
                    setIsErrorUpdate(true);
                }
            })
            .catch(() => {
                setIsLoadingUpdate(false);
                setIsErrorUpdate(true);
                setErrorMessage("Une erreur est survenue lors du chargement des versions archivables.");
            });
    }

    /**
     * Remplissage des lignes du tableau
     */
    const rows: VersionApplicativeTable[] = useMemo(() => {
        return updateVersionsApplicatives.map((version: VersionApplicative) => {
            const originalVersionApplicative = versionsApplicatives.find(v => v.id === version.id);
            return {
                id: version.id,
                typeProduit: version.typeProduit,
                version: version.version,
                versionParDefaut: version.versionParDefaut,
                versionParDefautInitialValue: originalVersionApplicative ? originalVersionApplicative.versionParDefaut : false,
                dateReferencement: version.dateReferencement,
                archive: version.archive,
                composants: version.composants ? version.composants.sort((a, b) => a.typeLivrable.localeCompare(b.typeLivrable)) : []
            }
        })
    }, [versionsApplicatives, updateVersionsApplicatives]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<VersionApplicativeTable>[] = useMemo(() => [
        {
            Header: 'Numéro de version',
            accessor: 'version',
            minWidth: 130,
        },
        {
            Header: 'Date de référencement',
            id: 'dateReferencement',
            accessor: (originalRow: VersionApplicativeTable) => affichageDateWithFormatDMYHm(originalRow.dateReferencement),
            minWidth: 150,
            sortType: (
                a: Row<VersionApplicativeTable>,
                b: Row<VersionApplicativeTable>,
                _columnId: IdType<VersionApplicativeTable>,
                desc: boolean | undefined
            ) => sortDatesInTables(a.original.dateReferencement, b.original.dateReferencement, desc)
        },
        {
            Header: 'Composant(s)',
            id: 'composants',
            accessor: (originalRow => originalRow.composants.map(comp =>
                getTypeLivrableDisplayedText(comp.typeLivrable) +" = "+ comp.version
            )),
            minWidth: 180,
            Cell: ((props: CellProps<VersionApplicativeTable>) => props.value.map((c: string) => <p key={c}>{c}</p>))
        },
        {
            Header: 'Version(s) autorisée(s) pour les CP',
            accessor: 'versionParDefaut',
            minWidth: 80,
            maxWidth: 100,
            Filter: SelectBooleanInputFilter,
            sortType: (a: Row<VersionApplicativeTable>, b) => {
                if (a.original.versionParDefautInitialValue > b.original.versionParDefautInitialValue) return 1;
                return -1;
            },
            Cell: (cellProps: CellProps<VersionApplicativeTable>) => (
                <CheckboxCell
                    id={ `version-${cellProps.row.original.version}` }
                    name="checkboxVersionParDefaut"
                    cellProps={ cellProps }
                    onChange={(event) => {
                        setPageIndex(cellProps.state.pageIndex);
                         handleOnClickVersionParDefaut(event, cellProps.row.original.id);
                    }}
                    disabled={ false }
                    show={isComposantsAvecNomLivrable(cellProps.row.original.composants)}
                />
            )
        },
        {
            Header: 'Version à archiver',
            accessor: 'archive',
            minWidth: 80,
            maxWidth: 100,
            disableFilters: true,
            disableSortBy: true,
            Cell: (cellProps: CellProps<VersionApplicativeTable>) => (
                <CheckboxCell
                    id={ `version-${cellProps.row.original.version}` }
                    name="checkboxVersionAArchiver"
                    cellProps={ cellProps }
                    onChange={(event) => {
                        setPageIndex(cellProps.state.pageIndex);
                        handleOnClickVersionAArchiver(event, cellProps.row.original.id);
                    }}
                    // Impossible de mettre la version à archiver s'il s'agit d'une version définie par défaut
                    disabled={cellProps.row.original.versionParDefaut}
                />
            )
        }
    ], [handleOnClickVersionParDefaut, handleOnClickVersionAArchiver]);

    const getColumns = () => {
        if(typeProduit === TypeProduit.VOXCORE_ELECTION) {
            return columns
        } else if(typeProduit === TypeProduit.VOXCORE_AG) {
            return columns.filter(c => c.id !== "composants")
        } else {
            return columns.filter(column => (column.accessor !== 'versionParDefaut' && column.id !== "composants"))
        }
    };

    return (
        <VoxCard
            title={`Versions applicatives ${typeProduit} ${(typeProduit === TypeProduit.VOXCORE_ELECTION) ? '(dont Saturne et MailEva)' : ''}`}
        >
            <Box margin={2.5}>
                {
                    (isErrorUpdate) && (
                        <VoxAlert severity={"error"} sx={{ marginBottom: 3 }}>
                            { errorMessage }
                        </VoxAlert>
                    )
                }
                {
                    (isSuccessUpdate) && (
                        <VoxAlert severity={"success"} onClose={() => setIsSuccessUpdate(false)} sx={{ marginBottom: 3 }}>
                            Les versions applicatives de type { typeProduit } ont été mises à jour.
                        </VoxAlert>
                    )
                }
                {
                    (
                        typeProduit === TypeProduit.ACTIONNARIAT 
                        || typeProduit === TypeProduit.SIRS 
                        || typeProduit === TypeProduit.VISIOVOTE 
                        || typeProduit === TypeProduit.VOXCORE_ELECTION
                    ) && (
                        <VoxButton
                            startIcon={ <FontAwesomeIcon icon={"plus"}/> }
                            onClick={ () => navigate(`./creation?type=${typeProduit}`) }
                        >   
                            Nouvelle version applicative
                        </VoxButton>
                    )
                }

                <VoxTable
                    data={rows}
                    columns={getColumns()}
                    pageIndexProps={ pageIndex }
                    initialStateSortBy={ [{id: 'versionParDefaut', desc: true}] }
                    autoResetSortBy={ false }
                />

                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <VoxButton
                            customColor={"blue"}
                            sx={{ mt: 3 }}
                            startIcon={ <FontAwesomeIcon icon={"archive"}/> }
                            onClick={ handleOnClickArchiverVersionsApplicatives }
                            disabled={ isLoadingUpdate }
                        >
                            Archivage Rapide
                        </VoxButton>

                    <VoxButton
                        customColor={"green"}
                        sx={{ mt: 3 }}
                        startIcon={ <FontAwesomeIcon icon={"save"}/> }
                        onClick={() => setShowConfirmationModificationModal(true)}
                        disabled={ isLoadingUpdate }
                    >
                        Valider les versions
                    </VoxButton>
                </Box>

                <ModaleConfirmationVersionsApplicatives
                    show={ showConfirmationModificationModal }
                    handleClose={() => setShowConfirmationModificationModal(false) }
                    handleSubmit={ handleUpdateVersionsApplicatives }
                    typeProduit={ typeProduit }
                />

                <ModaleConfirmationArchivageVersionsProduits
                    type="versionApplicative"
                    show={ showConfirmationArchivageModal }
                    handleClose={ () => setShowConfirmationArchivageModal(false) }
                    handleSubmit={ archiverVersionsApplicatives }
                    archivees={ versionsApplicativesArchivees }
                />

                { (isLoadingVersionsApplicatives || isLoadingUpdate) && <VoxLoader isBackgroundColor={true} /> }
            </Box>
        </VoxCard>
    );
}