import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import React from "react";

/**
 * Interface qui définit les informations qui sont accessibles dans le context d'authentification partout dans
 * l'application React
 */
export interface AuthContextType {
    authenticated: boolean,
    utilisateur: Utilisateur,
    access_token: string,
    login: (access_token: string) => void,
    logout: () => void,
    refreshToken: () => void
}

/**
 * Création du contexte React qui permet de conserver l'état d'authentification, accessible partout dans l'application
 */
export const AuthContext = React.createContext<AuthContextType>(
    { authenticated: false } as AuthContextType
);