import { VersionApplicative } from "../models/versionApplicative/versionApplicative.model";
import { Composant } from "../models/versionApplicative/composant.model";

/**
 * Méthode permettant de vérifier qu'une version applicative ne possède aucun composant
 * @param versionApplicative
 */
export function isVersionApplicativeSansComposants(versionApplicative: Partial<VersionApplicative>) : boolean {
    return (!versionApplicative.composants || !versionApplicative.composants.length);
}

/**
 * Méthode permettant de vérifier que pour un tableau de composant, chacun possède un nom de livrable
 * @param composants    Tableau de composants d'une version applicative
 */
export function isComposantsAvecNomLivrable(composants: Composant[]) : boolean {
    if (composants.length === 0) {
        // Le tableau de composants est vide (donc aucun livrable)
        return false;
    }

    for (const composant of composants) {
        // Vérification que chaque composant possède un nom de livrable non null
        if (composant.nomLivrable === null) {
            return false;
        }
    }

    // Tous les composants ont un nom de livrable (non null)
    return true;
}