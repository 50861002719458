import React, {useCallback, useEffect, useMemo, useState} from "react";
import { Parametrage } from "../../models/parametrage/parametrage.model";
import parametrageService from "../../services/survox-back/parametrage.service";
import { useLocation } from "react-router-dom";
import { ParametresContext } from "./parametres.context";



/**
 * Composant React qui est le provider du contexte de paramètres de l'application
 */
export default function ParametresProvider({ children } : any) {

    const location = useLocation();
    const [parametrages, setParametrages] = useState<Parametrage[]>([]);
    const [isLoadingParametrages, setIsLoadingParametrages] = useState(true);
    const [isErrorParametrages, setIsErrorParametrages] = useState(false);

    /**
     * Méthode permettant de récupérer les paramétres de l'application
     */
    const updateParametrage = useCallback((controller?: AbortController) => {
        setIsLoadingParametrages(true);
        setIsErrorParametrages(false);
        parametrageService.getAllParametrages(controller?.signal)
            .then(response => {
                setParametrages(response.data);
                setIsLoadingParametrages(false);
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    setIsErrorParametrages(true);
                    setIsLoadingParametrages(false)
                }
            });
    }, []);

    /**
     * Mets à jour le tableau de paramètres à chaque fois que l'utilisateur change de page sur l'application
     */
    useEffect(() => {
        const controller = new AbortController();
        updateParametrage(controller);
        return () => controller.abort();
    }, [location, updateParametrage]);

    const value = useMemo(() => ({
        parametrages: parametrages,
        updateParametrage: updateParametrage,
        isErrorParametrages: isErrorParametrages,
        isLoadingParametrages: isLoadingParametrages
    }), [parametrages, isErrorParametrages, isLoadingParametrages, updateParametrage]);

    return (
        <ParametresContext.Provider value={value}>
            { children }
        </ParametresContext.Provider>
    )
}