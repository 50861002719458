import React, { useEffect, useRef, useState } from "react";
import operationSIRSService from "../../../services/survox-back/operationSIRS/operationSIRS.service";
import { useNavigate, useParams } from "react-router-dom";
import { SIRSOperation } from "../../../models/operationSIRS/sirsOperation.model";
import { UpdateOperationSIRSDto } from "../../../services/survox-back/operationSIRS/dtos/request/updateOperationSIRS.dto";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import OperationsSirsForm, { EditSIRSContactClient } from "./operationsSIRSForm";
import { Grid, Typography } from "@mui/material";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import VoxCard from "../../genericComponentsUI/card/voxCard";
import { OperationSIRSFormulaire } from "../../../models/operationSIRS/operationSIRSFormulaire.model";
import { getUrlPartieClienteFromOperationSIRS } from "../../../utils/string.utils";
import VoxModal from "../../modals/voxModal";
import { EtatDemande } from "../../../models/operation/etatDemande.enum";


/**
 * Fonction composant appelant le formulaire d'opération SIRS pour la MODIFICATION
 */
export default function OperationsSIRSModification() {

    // Contexte
    let params = useParams();
    const navigate = useNavigate();

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // States
    const [operationSIRS, setOperationSIRS] = useState<OperationSIRSFormulaire>();
    const [isLoadingOperationSIRS, setIsLoadingOperationSIRS] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);
    const [isLoadingOnUpdate, setIsLoadingOnUpdate] = useState(false);
    const [isErrorOnUpdate, setIsErrorOnUpdate] = useState(false);
    const [isErrorDateInstallation, setIsErrorDateInstallation] = useState(false);
    const [isErrorTypePrestation, setIsErrorTypePrestation] = useState(false);
    const [messageError, setMessageError] =  useState<string>("");
    const [messageErrorUrlDoublon, setMessageErrorUrlDoublon] = useState<string>("");

    const [showConfirmationSuppressionModale, setShowConfirmationSuppressionModale] = useState(false);
    const [sauvegardeDataOnSubmit, setSauvegardeDataOnSubmit] = useState<{operationSIRS: OperationSIRSFormulaire, contactsClientListe: EditSIRSContactClient[]}>();
    const [isErreurModale, setIsErreurModale] = useState(false);

    // Création/Destruction du composant
    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        if (params.id) {
            chargerOperationSIRS(Number(params.id), controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [params.id]);

    /**
     * Chargement de l'opération sélectionnée (opération que l'on souhaite modifier)
     * @param id
     * @param controller
     */
    const chargerOperationSIRS = (id: number, controller: AbortController) => {
        setIsLoadingOperationSIRS(true);
        setIsLoadingError(false);
        operationSIRSService.getOperationSIRSAvecContactsClientById(id, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // récupération de l'opération
                    setIsLoadingError(false);

                    const operationSIRSFromBDD: SIRSOperation = response.data;

                    setOperationSIRS({
                        id: operationSIRSFromBDD.id,
                        nomClient: operationSIRSFromBDD.nomClient,
                        dateInstallation: operationSIRSFromBDD.dateInstallation,
                        etatDemande: operationSIRSFromBDD.etatDemande,
                        typePrestation: operationSIRSFromBDD.typePrestation,
                        urlPartieClient: getUrlPartieClienteFromOperationSIRS(operationSIRSFromBDD.url),
                        contactsClient: operationSIRSFromBDD.sirsContactClient,
                        nbLicencesVendues: operationSIRSFromBDD.nbLicencesVendues,
                        description: operationSIRSFromBDD.description ?? ""
                    });
                    setIsLoadingOperationSIRS(false);
                }
            })
            .catch((err) => {
                if (isMountedRef.current && err.message !== "canceled") {
                    setIsLoadingError(true);
                    setIsLoadingOperationSIRS(false);
                }
            });
    };

    const handleOnSumbit = (operationSIRS: OperationSIRSFormulaire, contactsClientListe: EditSIRSContactClient[]) => {
        if (operationSIRS.etatDemande === EtatDemande.SUPPRIME) {
            // Affichage d'une modale de confirmation car l'état de l'opération a été modifié à l'état "SUPPRIME"
            setShowConfirmationSuppressionModale(true);
            // Sauvegarde des données pour le OnSubmit après confirmation de la modale
            setSauvegardeDataOnSubmit({operationSIRS, contactsClientListe});
        } else {
            handleOnUpdate(operationSIRS, contactsClientListe);
        }
    }

    /**
     * Enregistrement des modifications de l'opération SIRS en base de données
     * @param updateOperationSIRSDto
     */
    const onUpdateOp = (updateOperationSIRSDto: UpdateOperationSIRSDto) => {
        setIsLoadingOnUpdate(true);
        setIsErrorOnUpdate(false);
        setMessageErrorUrlDoublon("");
        setMessageError("");
        operationSIRSService.updateOperationSIRS(Number(params.id), updateOperationSIRSDto)
            .then((resp) => {
                if(resp.status === 200) {
                    setIsLoadingOnUpdate(false);
                    setIsErrorOnUpdate(false);
                    navigate(-1);
                }
            })
            .catch((err) => {
                setIsLoadingOnUpdate(false);
                setIsErrorOnUpdate(true);
                if (err.response.status === 403) {
                    // Erreur doublon url
                    setMessageErrorUrlDoublon(err.response.data.message);
                } else if (err.response.status === 400) {
                    setMessageError(err.response.data.message[0]);
                } else {
                    setMessageError(err.response.data.message);
                }
            });
    }

    /**
     * Méthode appelée lors de la soumission du formulaire pour METTRE À JOUR l'opération SIRS en BDD
     * @param operationSIRS
     * @param contactsClientListe
     */
    const handleOnUpdate = (operationSIRS: OperationSIRSFormulaire, contactsClientListe: EditSIRSContactClient[]) => {
        setShowConfirmationSuppressionModale(false);
        setIsErrorDateInstallation(false);
        setIsErrorTypePrestation(false);
        if (operationSIRS.dateInstallation !== undefined) {
            if (operationSIRS.typePrestation !== undefined) {
                onUpdateOp(
                    {
                        nomClient: operationSIRS.nomClient,
                        dateInstallation: operationSIRS.dateInstallation,
                        typePrestation: operationSIRS.typePrestation,
                        urlPartieClient: operationSIRS.urlPartieClient,
                        nbLicencesVendues: operationSIRS.nbLicencesVendues,
                        etatDemande: operationSIRS.etatDemande,
                        description: (operationSIRS.description.trim() === "") ? null : operationSIRS.description.trim(),       // .trim() : // enlève les espaces au debut et à la fin de la chaine de caractères
                        updateContactsClient: contactsClientListe
                            .filter((contact: EditSIRSContactClient) => (contact.isUpdate && !contact.isNew && !contact.isDelete))
                            .map(({isUpdate, isNew, isDelete, ...contactClient}) => contactClient),
                        deleteContactsClient: contactsClientListe
                            .filter((contact: EditSIRSContactClient) => contact.isDelete)
                            .map(({isUpdate, isNew, isDelete, ...contactClient}) => contactClient),
                        newContactsClient: contactsClientListe
                            .filter((contact: EditSIRSContactClient) => (contact.isNew))
                            .map(({id, isUpdate, isNew, isDelete, ...contactClient}) => contactClient)
                    }
                );
            } else {
                setIsErrorTypePrestation(true);
            }
        } else {
            setIsErrorDateInstallation(true);
        }
    }


    return (
        <>
            {/* Chargement de l'opération SIRS à modifier */}
            {isLoadingOperationSIRS && <VoxLoader/>}

            {/* Erreur lors du chargement */}
            {isLoadingError &&
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={8} lg={5}>
                        <VoxAlert severity={"error"}>Une erreur est survenue lors du chargement de l'opération SIRS.</VoxAlert>
                    </Grid>
                </Grid>
            }

            {/* Conteneur du formulaire de modification */}
            {(!isLoadingOperationSIRS && !isLoadingError && operationSIRS) &&
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={8} lg={5}>
                        <VoxCard title={"Modification d'une opération SIRS"}>

                            {/* SI erreur lors de la soumission */}
                            {isErrorOnUpdate &&
                                <VoxAlert severity={"error"}>
                                    {messageErrorUrlDoublon !== "" ?
                                        messageErrorUrlDoublon : "Une erreur est survenue lors de la modification de l'opération SIRS."
                                    }
                                </VoxAlert>
                            }

                            {/* Formulaire de modification */}
                            {(operationSIRS) &&
                                <>
                                    <OperationsSirsForm
                                        operationSIRS={operationSIRS}
                                        onSubmit={handleOnSumbit}
                                        isLoadingOnSubmit={isLoadingOnUpdate}
                                        isErrorOnSubmit={isErrorOnUpdate}
                                        isErrorDateInstallation={isErrorDateInstallation}
                                        isErrorTypePrestation={isErrorTypePrestation}
                                        messageError={messageError}
                                    />

                                    <VoxModal
                                        open={showConfirmationSuppressionModale}
                                        onSubmit={ sauvegardeDataOnSubmit
                                            ? () => handleOnUpdate(sauvegardeDataOnSubmit?.operationSIRS, sauvegardeDataOnSubmit?.contactsClientListe)
                                            : () => setIsErreurModale(true)
                                        }
                                        onClose={() => {
                                            setIsErreurModale(false);
                                            setShowConfirmationSuppressionModale(false);
                                        }}
                                        title={"Suppression d'une opération SIRS"}
                                        closeButtonText={"Non"}
                                        submitButtonText={"Confirmer"}
                                        content={
                                            isErreurModale ?
                                            <VoxAlert severity={"error"}>Une erreur est survenue.</VoxAlert>
                                            :
                                            <Typography variant={"body1"}>
                                                Souhaitez-vous vraiment supprimer l'opération SIRS ?
                                            </Typography>
                                        }
                                    />
                                </>
                            }

                            {/* Chargement lors de la soumission */}
                            {isLoadingOnUpdate && <VoxLoader isBackgroundColor={true} />}

                        </VoxCard>
                    </Grid>
                </Grid>
            }
        </>
    );

}