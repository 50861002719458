import React, {ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import "./operationsAInitialiser.css"
import { Operation } from "../../models/operation/operation.model";
import operationService from "../../services/survox-back/operation/operation.service";
import { CellProps, Column, IdType, Row } from "react-table";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import {
    affichageDateWithFormatDMYHm,
    affichageLibelleEtatDemande,
    getNumeroVersionMajeure
} from "../../utils/string.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import LienInstallerMaintenant from "./lienInstallerMaintenant";
import { NomPlateforme } from "../../models/operation/nomPlateforme.enum";
import ModaleConfirmationInstallationOVH from "../modals/modaleConfirmationInstallationOVH";
import ModaleConfirmationFinalisationInitialisationDocaposte
    from "../modals/modaleConfirmationFinalisationInitialisationDocaposte";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import { sortDatesInTables } from "../../utils/date.utils";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import VoxTable from "../genericComponentsUI/table/voxTable";
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";
import { Box } from "@mui/material";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";
import ModaleConfirmationInstallationDocaposte from "../modals/modaleConfirmationInstallationDocaposte";
import VoxLinkInstallation from "../genericComponentsUI/link/voxLinkInstallation";

/* Type des opérations à initialiser récupérées depuis survox-back
 * -> La propriété isInstalling est utilisée pour savoir si l'utilisateur a déclenché l'installation
 */
type OperationsAInitialiser = {
    id: number;
    numeroOperation: number;
    nomClient: string;
    url: string;
    etatDemande: EtatDemande;
    isBlocked: boolean;
    dateInstallation: Date;
    type: TypeOperation;
    nomPlateforme: NomPlateforme;
    typeProduitDeVersionApplicative?: string;
    versionDeVersionApplicative?: string;
    isInstalling: boolean;
};

type OperationsAInitialiserTableau = {
    id: number;
    nomClient: string;
    numeroOperation: number;
    url: string;
    etatDemande: EtatDemande;
    plateforme: string;
    dateInstallation: Date;
    isBlocked: boolean;
    loadingInstallation: boolean;
    type: TypeOperation;
    typeProduitDeVersionApplicative: string;
    versionDeVersionApplicative: string;
}


export default function OperationsAInitialiserListe(props: { typeOperation: TypeOperation | string }) {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    // Utilisation d'une ref pour connaître l'état du composant éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [operationsAInitialiser, setOperationsAInitialiser] = useState<(OperationsAInitialiser)[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [selectedOperationId, setSelectedOperationId] = useState<number | undefined>();
    const [showConfirmationOVHModale, setShowConfirmationOVHModale] = useState(false);
    const [showConfirmationDocaposteModale, setShowConfirmationDocaposteModale] = useState(false);
    const [showConfirmationFinalisationInitialisationDocaposteModale, setShowConfirmationFinalisationInitialisationDocaposteModale] = useState(false);
    const [isInstalling, setIsInstalling] = useState(false);
    const [nomVM, setNomVM] = useState<string>("");

    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");

    /**
     * Gestion du nom de la VM lors de la finalisation
     */
    const handleChangeNomVM = (event: ChangeEvent<HTMLInputElement>) => {
        setNomVM(event.target.value);
    };

    /**
     * Charger les opérations à l'état DECLARE selon le type
     */
    const chargerOperationsAInitialiser = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        /**
         * charger les opérations de type VoxCore-Election, VoxCore-AG et Actionnariat à l'état DECLARE
         */
        if (props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat") {
            operationService.getOperationsVoxcoreActionnariatAInitialiser(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        const operationsAInitialiserRecuperees: OperationsAInitialiser[] = response.data.map((operation: Operation) => {
                            return {...operation, isInstalling: false}
                        });
                        setOperationsAInitialiser(operationsAInitialiserRecuperees);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }

        /**
         * charger les opérations de type Saturne à l'état DECLARE
         */
        if (props.typeOperation === TypeOperation.SATURNE) {
            operationService.getOperationsSaturneAInitialiser(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        const operationsAInitialiserRecuperees: OperationsAInitialiser[] = response.data.map((operation: Operation) => {
                            return {...operation, isInstalling: false}
                        });
                        setOperationsAInitialiser(operationsAInitialiserRecuperees);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }

        /**
         * charger les opérations de type MailEva à l'état DECLARE
         */
        if (props.typeOperation === TypeOperation.MAILEVA) {
            operationService.getOperationsMailevaAInitialiser(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        const operationsAInitialiserRecuperees: OperationsAInitialiser[] = response.data.map((operation: Operation) => {
                            return {...operation, isInstalling: false}
                        });
                        setOperationsAInitialiser(operationsAInitialiserRecuperees);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }
    }, [props.typeOperation]);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        chargerOperationsAInitialiser(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [chargerOperationsAInitialiser]);

    /**
     * Méthode qui permet de changer la valeur de l'attribut isInstalling pour une opération donnée
     * @param operations , un tableau d'opérations
     * @param operationId , l'Id de l'opération
     * @param value , la valeur (true ou false)
     */
    const changeIsInstallingOperation = function (operations: OperationsAInitialiser[], operationId: number, value: boolean) {
        return operations.map(operation => {
            if (operation.id === operationId) {
                operation.isInstalling = value;
            }
            return operation;
        });
    }

    /**
     * Handle qui se déclenche lorsque l'utilisateur clique sur installer maintenant
     * dans le tableau des opérations à initialiser
     */
    const handleInstallerOperation = useCallback(() => {
        if (selectedOperationId) {
            setIsInstalling(true);
            // l'on change la valeur de isInstalling pour l'opération concernée afin d'afficher le loader
            setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, true));

            operationService.installerOperation(selectedOperationId)
                .then(() => {
                    if (isMountedRef.current) {
                        setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, false));
                        chargerOperationsAInitialiser();
                    }
                })
                .catch(() => {
                    if (isMountedRef.current) {
                        setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, false));
                    }
                })
                .finally(() => {
                    setIsInstalling(false);
                    setShowConfirmationOVHModale(false);
                    setSelectedOperationId(undefined);
                })
        }
    }, [operationsAInitialiser, chargerOperationsAInitialiser, selectedOperationId]);

    /**
     * Méthode qui se déclenche lorsque l'utilisateur clique sur le bouton pour passer l'opération en production chez
     * l'hébergeur Docaposte dans le tableau des opérations à initialiser
     */
    const handleEnProductionDocaposte = useCallback(() => {
        if (selectedOperationId) {
            setIsInstalling(true);
            setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, true));

            operationService.updateOperationEnProductionDocaposte(selectedOperationId, {vmName: nomVM})
                .finally(() => {
                    setIsInstalling(false);
                    setShowConfirmationDocaposteModale(false);
                    setSelectedOperationId(undefined);
                    chargerOperationsAInitialiser();
                });
        }
    }, [operationsAInitialiser, chargerOperationsAInitialiser, selectedOperationId, nomVM]);


    /**
     * Méthode qui se déclenche lorsque l'utilisateur clique sur le bouton pour finir l'initialisation du site chez
     * l'hébergeur Docaposte dans le tableau des opérations à initialiser
     */
    const handleFinalisationInitialisationDocaposte = useCallback(() => {
        setIsError(false);
        if (selectedOperationId) {
            setIsInstalling(true);
            setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, true));

            operationService.updateInitialisationOperationDocaposte(selectedOperationId, {vmName: nomVM})
                .then((response) => {
                    if (isMountedRef.current && response.status === 200) {
                        setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, false));
                        setIsError(false);
                        chargerOperationsAInitialiser();
                    }
                })
                .catch((err) => {
                    if (isMountedRef.current) {
                        setOperationsAInitialiser(changeIsInstallingOperation(operationsAInitialiser, selectedOperationId, false));
                        setIsError(true);
                        if (err.response.status === 400 && err.response.data.message.includes("Au moins un email doit être présent pour le portefeuille")) {
                            setMessageError(`Une erreur est survenue pour l'opération : ${err.response.data.message}`);    // Au moins un email doit être présent pour le portefeuille ... pour permettre l'initialisation du site.
                        } else if (
                            (err.response.status === 400 || err.response.status === 404)
                            && err.response.data.message.includes("[Erreur Initialisation]")
                        ) {
                            setMessageError(err.response.data.message.split("[Erreur Initialisation] "));
                        } else if (err.response.status === 500 && err.response.data.message.includes("[Erreur Initialisation]")) {
                            setMessageError(err.response.data.message.split("[Erreur Initialisation] "));
                        } else {
                            setMessageError("Une erreur est survenue");
                        }
                    }
                })
                .finally(() => {
                    setIsInstalling(false);
                    setShowConfirmationFinalisationInitialisationDocaposteModale(false);
                    setSelectedOperationId(undefined);
                })
        }
    }, [operationsAInitialiser, chargerOperationsAInitialiser, selectedOperationId, nomVM]);


    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationsAInitialiserTableau[] = useMemo(() => {
        return operationsAInitialiser.map(
            (operation: OperationsAInitialiser) => ({
                id: operation.id,
                numeroOperation: operation.numeroOperation,
                nomClient: operation.nomClient,
                url: operation.url,
                etatDemande: operation.etatDemande,
                isBlocked: operation.isBlocked,
                loadingInstallation: operation.isInstalling,
                dateInstallation: operation.dateInstallation,
                type: operation.type,
                plateforme: operation.nomPlateforme,
                typeProduitDeVersionApplicative: operation.typeProduitDeVersionApplicative ? operation.typeProduitDeVersionApplicative : "",
                versionDeVersionApplicative: operation.versionDeVersionApplicative ? operation.versionDeVersionApplicative : "",
            })
        )
    }, [operationsAInitialiser]);

    /**
     * Ouverture d'une popup de confirmation d'installation chez OVH
     */
    const handleOperationConfirmationOVH = useCallback((operationId: number) => {
        setSelectedOperationId(operationId);
        setShowConfirmationOVHModale(true);
    }, []);

    /**
     * Ouverture d'une popup de confirmation d'installation chez Docaposte (passage en production)
     */
    const handleOperationConfirmationDocaposte = useCallback((operationId: number) => {
        setSelectedOperationId(operationId);
        setShowConfirmationDocaposteModale(true);
    }, []);

    /**
     * Ouverture d'une popup de confirmation pour la finalisation de l'initialisation du site chez Docaposte (appel webservice initialisation Voxcore & passage en production)
     */
    const handleOperationConfirmationInitialisationDocaposte = useCallback((operationId: number) => {
        setSelectedOperationId(operationId);
        setShowConfirmationFinalisationInitialisationDocaposteModale(true);
    }, []);


    /**
     * Affichage du nom du client dans la colonne "Client" du tableau
     */
    const cellColumnNomClient = useCallback((props: CellProps<OperationsAInitialiserTableau>) => (
        <VoxLinkOperation href={`/operation/detail/${props.row.original.id}`}>{props.value}</VoxLinkOperation>
    ), []);

    /**
     * Affichage des URLs dans la colonne "Site" du tableau
     */
    const cellColumnUrl = useCallback((props: CellProps<OperationsAInitialiserTableau>) => (
        <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
    ), []);

    /**
     * Affichage des données dans la colonne "État d'installation" du tableau
     */
    const cellColumnEtatInstallation = useCallback((props: CellProps<OperationsAInitialiserTableau>) => {
        let cellContent;
        if (props.row.original.loadingInstallation) {
            cellContent = (
                <Box sx={{position: 'relative'}}>
                    <VoxLoader size={20}/>
                </Box>
            );
        } else if (props.row.original.etatDemande === "declare") {
            const typeOperation = props.row.original.type;
            const typePlateforme = props.row.original.plateforme;
            if (
                (
                    (
                        typeOperation === TypeOperation.SATURNE
                        || typeOperation === TypeOperation.MAILEVA
                        || typeOperation === TypeOperation.VOXCORE_ELECTION
                        || typeOperation === TypeOperation.VOXCORE_AG
                        || typeOperation === TypeOperation.ACTIONNARIAT
                    ) && (
                        /*
                         * Seuls les utilisateurs ayant le rôle Administrateur et Support SI Infra peuvent
                         * effectuer des actions liées à l'état d'installation de l'opération
                         */
                        utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                    )
                ) || (
                    (
                        typeOperation === TypeOperation.SATURNE
                        || typeOperation === TypeOperation.MAILEVA
                    ) && (
                        utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE
                        || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                    )
                )
            ) {
                if (typePlateforme === NomPlateforme.H1) {
                    cellContent = (
                        <LienInstallerMaintenant
                            idOperation={props.row.original.id}
                            typeOperation={props.row.original.type}
                            handleOperationConfirmationOVH={handleOperationConfirmationOVH}    // Ouverture popup de confirmation
                        />
                    );
                } else if (
                    typePlateforme === NomPlateforme.H2
                    || typePlateforme === NomPlateforme.H3
                    || typePlateforme === NomPlateforme.H4
                    || typePlateforme === NomPlateforme.H5
                ) {
                    // Pour une opération VoxCore-Election dont la version est supérieure ou égale à 22.0.0
                    if (props.row.original.typeProduitDeVersionApplicative === TypeOperation.VOXCORE_ELECTION &&
                        getNumeroVersionMajeure(props.row.original.versionDeVersionApplicative) >= 22) {
                        // Actions effectuées (coté back) après confirmation :
                        //  - Appel du webservice d'initialisation de VoxCore
                        //  - Modification du statut de l'opération à "En production"
                        cellContent = (
                            <VoxLinkInstallation
                                startIcon={"caret-square-right"}
                                onClick={() => handleOperationConfirmationInitialisationDocaposte(props.row.original.id)}   // Ouverture pop-up de confirmation
                            >
                                Finaliser l'initialisation (Docaposte)
                            </VoxLinkInstallation>
                        );
                    } else {
                        // Action effectuée (coté back) après confirmation :
                        //  - Modification du statut de l'opération à "En production"
                        cellContent = (
                            <VoxLinkInstallation
                                startIcon={"hand-point-right"}
                                onClick={() => handleOperationConfirmationDocaposte(props.row.original.id)}   // Ouverture pop-up de confirmation
                            >
                                Passer en production (Docaposte)
                            </VoxLinkInstallation>
                        );
                    }
                }
            } else {
                cellContent = affichageLibelleEtatDemande(props.row.original.etatDemande);
            }
        } else {
            // variable permettant de savoir si l'opération est bloquée dans une étape du workflow de déploiement
            const isInstallationAnsibleBlockedOrFailed = (
                props.row.original.isBlocked
                || props.row.original.etatDemande === EtatDemande.ERREUR_INSTALLATION_ANSIBLE
            );
            // Si l'opération est considérée comme bloquée, ajout d'un picto et libellé en rouge (afin de signaler le problème)
            cellContent = (
                <Box component={"span"} sx={{color: isInstallationAnsibleBlockedOrFailed ? 'cobalt.redText' : 'inherit'}}>
                    {
                        isInstallationAnsibleBlockedOrFailed && (
                            <FontAwesomeIcon
                                icon={'exclamation-triangle'}
                                className={"margin-right-demi"}
                                title={
                                    props.row.original.etatDemande === EtatDemande.ERREUR_INSTALLATION_ANSIBLE
                                    ? "Une erreur est survenue lors de l'installation. Analyse à effectuer."
                                    : "Installation bloquée depuis plus de 60 minutes. Analyse à effectuer."
                                }
                            />
                        )
                    }
                    {affichageLibelleEtatDemande(props.row.original.etatDemande)}
                </Box>
            );
        }
        return (
            <Box sx={{textAlign: "center"}}>
                {cellContent}
            </Box>
        );
    }, [
        handleOperationConfirmationInitialisationDocaposte,
        handleOperationConfirmationDocaposte,
        handleOperationConfirmationOVH,
        utilisateur.groupe.nom
    ]);

    /**
     * Affichage des pictogrammes dans la colonne "Actions à effectuer" du tableau
     */
    const cellColumnActions = useCallback((props: CellProps<OperationsAInitialiserTableau>) => {
        return (
            <Box display={"flex"} justifyContent={"center"} gap={1}>

                {/* Oeil → Détail de l'opération */}
                <VoxIconAction
                    tooltip={"Consulter la fiche de l'opération"}
                    icon={"eye"}
                    onClick={() => navigate(`../../operation/detail/${props.row.original.id}`)}
                />

                {/* Crayon → Modification de l'opération */}
                {(props.row.original.etatDemande === EtatDemande.DECLARE &&
                    (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                        utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA)) &&
                    <VoxIconAction
                        tooltip={"Modifier l'opération"}
                        icon={"edit"}
                        onClick={() => navigate(`modification/${props.row.original.id}`)}
                    />
                }

            </Box>
        )
    }, [navigate, utilisateur.groupe.nom]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationsAInitialiserTableau>[] = useMemo(() => [
            {
                Header: 'Nom du client',
                accessor: "nomClient",
                minWidth: 190,
                Cell: cellColumnNomClient
            },
            {
                Header: "Site",
                accessor: 'url',
                minWidth: 210,
                Cell: cellColumnUrl
            },
            {
                Header: "État d'installation",
                accessor: "etatDemande",
                minWidth: 190,
                Cell: cellColumnEtatInstallation
            },
            {
                Header: "Numéro Opération",
                accessor: "numeroOperation",
                minWidth: 100
            },
            {
                Header: "Type de plateforme",
                accessor: "plateforme",
                minWidth: 130,
                Filter: SelectInputFilter,
                filter: 'includes',
            },
            {
                Header: "Date d'installation",
                id: "dateInstallation",
                accessor: (originalRow: OperationsAInitialiserTableau) => affichageDateWithFormatDMYHm(originalRow.dateInstallation),
                minWidth: 150,
                sortType: (a: Row<OperationsAInitialiserTableau>,
                           b: Row<OperationsAInitialiserTableau>,
                           columnId: IdType<OperationsAInitialiserTableau>,
                           desc: boolean | undefined) => sortDatesInTables(a.original.dateInstallation, b.original.dateInstallation, desc),
            },
            {
                Header: 'Actions à effectuer',
                id: 'actions',
                disableFilters: true,
                disableSortBy: true,
                Cell: cellColumnActions,
                minWidth: 60,
                maxWidth: 90,
            },
        ], [cellColumnNomClient, cellColumnUrl, cellColumnEtatInstallation, cellColumnActions]
    )


    if (isLoading) {
        return <VoxLoader positionRelative/>;
    } else {
        return (
            <>
                {/* Affichage d'un message d'erreur s'il y en a un */}
                {isError &&
                    <VoxAlert severity={"error"} onClose={() => setIsError(false)}>
                        {messageError}
                    </VoxAlert>
                }

                <VoxTable
                    data={rows}
                    columns={
                        (props.typeOperation === TypeOperation.SATURNE || props.typeOperation === TypeOperation.MAILEVA)
                            ? columns.filter(column => (column.accessor === 'nomClient' || column.accessor === 'url' || column.accessor === 'etatDemande' || column.id === 'actions'))
                            : columns   // toutes les colonnes définies
                    }
                />

                <ModaleConfirmationInstallationDocaposte
                    show={showConfirmationDocaposteModale}
                    handleClose={() => {
                        setShowConfirmationDocaposteModale(false);
                        setSelectedOperationId(undefined);
                    }}
                    handleSubmit={handleEnProductionDocaposte}
                    isInstalling={isInstalling}
                    handleChangeNomVM={handleChangeNomVM}
                />

                <ModaleConfirmationFinalisationInitialisationDocaposte
                    show={showConfirmationFinalisationInitialisationDocaposteModale}
                    handleClose={() => {
                        setShowConfirmationFinalisationInitialisationDocaposteModale(false);
                        setSelectedOperationId(undefined);
                    }}
                    handleSubmit={handleFinalisationInitialisationDocaposte}
                    isInstalling={isInstalling}
                    handleChangeNomVM={handleChangeNomVM}
                />

                <ModaleConfirmationInstallationOVH
                    show={showConfirmationOVHModale}
                    handleClose={() => {
                        setShowConfirmationOVHModale(false);
                        setSelectedOperationId(undefined);
                    }}
                    handleSubmit={handleInstallerOperation}
                    isInstalling={isInstalling}
                />

            </>
        )
    }

}