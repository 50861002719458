import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import {VoxLegendChampsObligatoires} from "../../genericComponentsUI/label/voxLegendChampsObligatoires";
import React, {ChangeEvent, FormEvent, useState} from "react";
import {VoxSelectInput} from "../../genericComponentsUI/input/voxSelectInput";
import {VoxTextField} from "../../genericComponentsUI/input/voxTextField";
import {TypeEnvironnement, TypeEnvironnements} from "../../../models/environnements/typeEnvironnement.enum";
import {VoxTextArea} from "../../genericComponentsUI/input/voxTextArea";
import {VoxAlert} from "../../genericComponentsUI/alerte/voxAlert";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import EnvironnementsService from "../../../services/survox-back/environnements/environnements.service";
import {EnvironnementOperation} from "../../../models/environnements/environnementOperation.model";
import dayjs from "dayjs";
import {VoxCalendarInput} from "../../genericComponentsUI/input/voxCalendarInput";
import {CreateEnvOperationsDto} from "../../../services/survox-back/environnements/dtos/createEnvOperations.dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VoxButton from "../../genericComponentsUI/button/voxButton";
import {
    affichageLibelleTypeEnvironnement,
    prefixeUrlEnvironnements,
    TITLE_PATTERN_URL
} from "../../../utils/string.utils";

type propsEnvironnementForm = {
    operationId: number
    environnement?: EnvironnementOperation
    show: boolean
    close: () => void
    loadData: () => void
}

export default function EnvironnementOperationForm(props: propsEnvironnementForm) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>("");

    const close = props.close;
    const reload = props.loadData;

    const [typeEnv, setTypeEnv] = useState<TypeEnvironnement | undefined>(props.environnement ? props.environnement.typeEnvironnement : undefined);
    const [url, setUrl] = useState<string>(props.environnement ? props.environnement.url : "https://");
    const [description, setDescription] = useState<string>(props.environnement ? props.environnement.description : "");
    const [dateInstallation, setDateInstallation] = useState<Date | undefined>(props.environnement ? props.environnement.dateInstallation : undefined);

    //Gestion changement Type Environnement
    const handleOnChangeTypeEnv = (event: SelectChangeEvent<HTMLInputElement>) => {
        const env = TypeEnvironnements.find((t) => event.target.value === t) ?? undefined;
        setTypeEnv(env);
        setUrl("https://" + prefixeUrlEnvironnements(env));

    }

    //Gestion changement URL
    const handleOnChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value);
    }

    //Gestion changement Commentaires
    const handleOnChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }

    //Gestion changement Date Installation
    const handleOnChangeDate = (id: string, date: Date | undefined) => {
        setDateInstallation(date);
    }

    /**
     * Création Environnement
     */
    const handleOnSubmitNewEnv = () => {
        setIsLoading(true);
        setIsError(false);
        if (!typeEnv || !dateInstallation) {
            setIsLoading(false);
            setIsError(true);
            setMessageError("Le type d'environnement et la date d'installation doivent être renseignés")
        } else {
            const newEnvOperation: CreateEnvOperationsDto = {
                operation: {id: props.operationId},
                description: description,
                url: url,
                dateInstallation: dateInstallation,
                typeEnv: typeEnv
            }

            EnvironnementsService.createEnvironnementsOperations(newEnvOperation)
                .then((response) => {
                    if (response.status === 201) {
                        setIsLoading(false);
                        setIsError(false);
                        close();
                        reload();
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    if ((error.response.status === 400)) {
                        setMessageError(error.response.data.message);
                    } else {
                        setMessageError("Une erreur est survenue lors de la création");
                    }
                })
        }
    }

    /**
     * Modification Environnement
     */
    const handleOnSubmitModEnv = () => {
        setIsLoading(true);
        setIsError(false);
        if (!typeEnv || !dateInstallation) {
            setIsLoading(false);
            setIsError(true);
            setMessageError("Le type d'environnement et la date d'installation doivent être renseignés")
        } else {
            EnvironnementsService.updateEnvironnementsOperations({
                id: props.environnement ? Number(props.environnement.id) : 0,
                description: description,
                url: url,
                typeEnv: typeEnv,
                dateInstallation: dateInstallation,
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        setIsError(false);
                        close();
                        reload();
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    if (error.response.status === 400) {
                        setMessageError(error.response.data.message);
                    } else {
                        setMessageError("Une erreur est survenue lors de la mise à jour");
                    }
                })
        }
    };

    return (
        <>
            {(isLoading) ? <VoxLoader/> :
                <Dialog
                    open={props.show}
                    onClose={close}
                >
                    <DialogTitle>{(props.environnement ? "Modifier" : "Ajouter") + " un Environnement"}</DialogTitle>
                    <DialogContent>
                        {isError &&
                            <VoxAlert severity={"error"}>{messageError}</VoxAlert>
                        }

                        <Box component={"form"} onSubmit={(event: FormEvent<HTMLDivElement>) => {
                            event.preventDefault();
                            props.environnement ? handleOnSubmitModEnv() : handleOnSubmitNewEnv()
                        }}>
                            <Grid container spacing={3} padding={2}>
                                <Grid item xs={12}>
                                    <VoxLegendChampsObligatoires/>
                                </Grid>

                                <Grid item xs={12}>
                                    <VoxSelectInput
                                        id={"typeEnv"}
                                        idLabel={"labType"}
                                        startIconLabel={"cogs"}
                                        label={"Type d'environnement souhaité"}
                                        value={typeEnv}
                                        onChange={handleOnChangeTypeEnv}
                                        fullWidth
                                        required
                                    >
                                        {TypeEnvironnements.map((env) => {
                                            return (
                                                <MenuItem key={env} value={env}>{affichageLibelleTypeEnvironnement(env)}</MenuItem>
                                            )
                                        })}
                                    </VoxSelectInput>
                                </Grid>

                                <Grid item xs={12}>
                                    <VoxTextField
                                        id={"url"}
                                        label={"URL"}
                                        startIconLabel={"globe"}
                                        value={url}
                                        pattern={"^(https?:\\/\\/)([a-z0-9\\-\\.\\/]+)(\\.[a-z]{2,3})$"}
                                        title={TITLE_PATTERN_URL + " Suffixe '.xx' ou '.xxx'"}
                                        onChange={handleOnChangeUrl}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <VoxCalendarInput
                                        id={"dateInstallation"}
                                        startIconLabel={"calendar-alt"}
                                        label={"Date d'installation"}
                                        value={props.environnement ?
                                            new Date(dayjs(props.environnement.dateInstallation).format("YYYY-MM-DD"))
                                            : dateInstallation
                                        }
                                        onChange={handleOnChangeDate}
                                        minDate={new Date()}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <VoxTextArea
                                        id={"description"}
                                        label={"Description"}
                                        startIconLabel={"pen"}
                                        value={description}
                                        onChange={handleOnChangeDescription}
                                        minRows={3}
                                    />
                                </Grid>

                                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                                    <VoxButton
                                        id={"btn-annulerEnv"}
                                        customColor={"red"}
                                        startIcon={<FontAwesomeIcon icon={"ban"}/>}
                                        sx={{fontSize: "0.95rem", py: 2.8, margin: '2rem auto 0'}}
                                        onClick={close}
                                    >
                                        Annuler
                                    </VoxButton>

                                    <VoxButton
                                        id={"btn-validerEnv"}
                                        customColor={"green"}
                                        startIcon={<FontAwesomeIcon icon={"save"}/>}
                                        sx={{fontSize: "0.95rem", py: 2.8, margin: '2rem auto 0'}}
                                        type={"submit"}
                                    >
                                        {props.environnement ? "Modifier" : "Valider"}
                                    </VoxButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}