import axios_instance from "./axios-client";


const jobService = {

    getJobsSurvox: (signal: AbortSignal) => {
        return axios_instance.get('job', { signal });
    },

    executeJobSurvox: (url: string) => {
        return axios_instance.get(`job/${url}`);
    },
}

export default jobService;