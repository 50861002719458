import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Cell, CellProps, Column, IdType, Row } from "react-table";
import FiltresAffichage from "../../../components/filtresAffichage/filtresAffichage";
import GestionFavorisListe from "../../../components/operationsListe/gestionFavorisListe/gestionFavorisListe";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import ModaleConfirmationAcquittement from '../../../components/modals/modaleConfirmationAcquittement';
import { AuthContext } from "../../../contexts/authentication/authentication.context";
import operationService from "../../../services/survox-back/operation/operation.service";
import { getResultatSurveillanceScellement, Surveillance } from "../../../models/surveillance/surveillance.model";
import { OperationProduction } from "../../../models/operation/operationProduction/operationProduction.model";
import { StatutOperation } from "../../../models/operation/statutOperation.enum";
import { ResultatSurveillance } from "../../../models/surveillance/resultatSurveillance.enum";
import { FiltresAffichageDto } from "../../../services/survox-back/operation/dtos/request/filtresAffichage.dto";
import { Periode } from "../../../models/operation/periode.enum";
import { OperationPhase } from "../../../models/operation/operationPhase.enum";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import { StatutPVDemat } from "../../../models/operation/statutPVDemat.enum";
import { Hebergeur } from "../../../models/operation/hebergeur.enum";
import { RegionOvh } from "../../../models/virtualMachine/regionOvh.enum";
import { NomGroupe } from "../../../models/utilisateur/nomGroupe.enum";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { OperationProductionTableau } from "../../../models/operation/operationProduction/operationProductionTableau.model";
import { affichageDateWithFormatDMYHm } from "../../../utils/string.utils";
import { csvDataSerializationOperationProduction } from "../../../utils/csv.utils";
import { getHiddenColumnsFromVisible } from "../../../components/genericComponentsUI/table/columns/columnsTable";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { sortDatesInTables } from "../../../utils/date.utils";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { Grid } from "@mui/material";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import { SelectBooleanInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectBooleanInputFilter";
import { SelectInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";
import VoxLinkOperation from "../../../components/genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";

dayjs.extend(utc);


/**
 * Définition du type des filtres liés aux alertes
 */
type FiltresAlertesType = {
    siteKO: boolean;
    certificatKO: boolean;
    scellementKO: boolean;
    demandesElecteursKO: boolean;
    horodatageKO: boolean;
    teletransmissionPvKO: boolean
}


/**
 * Définition du type des filtres liés aux opérations suivies par l'utilisateur
 */
type FiltresPerimetresOperationsType = {
    opFavorites: boolean;
    opPortefeuilles: boolean;
}


export default function OperationsEnProductionListe() {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const navigate = useNavigate();

    // récupération de l'utilisateur connecté à l'application
    const authContext = useContext(AuthContext);
    const utilisateurAuth: Utilisateur = authContext.utilisateur;

    // Groupes autorisés pour l'acquittement d'une opération
    const isAuthorizedAcquittement: boolean = (
        utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR
        || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
        || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV
    );

    // Groupes autorisés pour visualiser le détail d'une opération
    const isAuthorizedVisualiserOperation: boolean = (
        utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR
        || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
        || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV
        || utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE
        || utilisateurAuth.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
        || utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE
        || utilisateurAuth.groupe.nom === NomGroupe.OBSERVATEUR
    );

    const [operationsEnProduction, setOperationsEnProduction] = useState<OperationProduction[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [showAcquittementModale, setShowAcquittementModale] = useState(false);
    const [selectedOperation, setSelectedOperation] = useState<OperationProduction>();
    const [isLoadingAcquittementOperation, setIsLoadingAcquittementOperation] = useState(false);
    const [succesAcquittementOperation, setSuccesAcquittementOperation] = useState(false);
    const [isErrorAcquittementOperation, setIsErrorAcquittementOperation] = useState(false);

    const [isErrorEnregistrementFavoris, setIsErrorEnregistrementFavoris] = useState(false);
    const [messageErreurFavoris, setMessageErreurFavoris] = useState<string>("");

    // Stockage des valeurs des filtres d'affichage
    const [filtreAffichageDate, setFiltreAffichageDate] = useState<StatutOperation | Periode | OperationPhase | undefined>(undefined);
    const [filtresAffichageAlerte, setFiltresAffichageAlerte] = useState<FiltresAlertesType>({
        siteKO: false,
        certificatKO: false,
        scellementKO: false,
        demandesElecteursKO: false,
        horodatageKO: false,
        teletransmissionPvKO: false
    });
    const [filtresAffichagePerimetresOperations, setFiltresAffichagePerimetresOperations] = useState<FiltresPerimetresOperationsType>({
        opFavorites: false, opPortefeuilles: false
    });
    const [isAppelFonctionFiltresAffichage, setIsAppelFonctionFiltresAffichage] = useState(false);

    const [hiddenColumns, setHiddenColumns] = useState<(string | undefined)[]>([]);

    // Recherche de paramètres dans l'URL
    const [searchParams,] = useSearchParams();
    const paramTypeOp = (searchParams.getAll("type").length !== 0);    // true si la condition est remplie
    const paramTypeHebergeur = (searchParams.getAll("hebergeur").length !== 0);
    const paramTypeRegionOVH = (searchParams.getAll("region").length !== 0);
    const paramStatutOp = (searchParams.getAll("statut").length !== 0);
    const paramResultatSurveillance = (searchParams.getAll("surveillance").length !== 0);
    const paramIsHorodatageKO = (searchParams.getAll("horodatage").length !== 0);
    const paramTeletransmissionPVDematKO = (searchParams.getAll("teletransmission").length !== 0);

    /**
     * Chargement des données du tableau
     */
    const chargerOperationsEnProduction = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        operationService.getAllOperationsEnProduction(controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const operationsListe = response.data;
                    setOperationsEnProduction(operationsListe);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                }
            });
    }, []);

    /**
     * Filtre avec paramètres dans l'URL (sur le type d'opération, d'hébergeur ou de VM ainsi que sur les alertes)
     */
    const filtrerOperationsEnProductionParURL = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        /**
         * Récupération des indicateurs
         */
        const typesOperation: TypeOperation[] = [];
        if (paramTypeOp) {
            searchParams.getAll("type").forEach(typeOp => {
                if (typeOp === TypeOperation.SATURNE) {
                    typesOperation.push(TypeOperation.SATURNE);
                }
                if (typeOp === TypeOperation.MAILEVA) {
                    typesOperation.push(TypeOperation.MAILEVA);
                }
                if (typeOp === TypeOperation.VOXCORE_ELECTION) {
                    typesOperation.push(TypeOperation.VOXCORE_ELECTION);
                }
                if (typeOp === TypeOperation.VOXCORE_AG) {
                    typesOperation.push(TypeOperation.VOXCORE_AG);
                }
                if (typeOp === TypeOperation.ACTIONNARIAT) {
                    typesOperation.push(TypeOperation.ACTIONNARIAT);
                }
                if (typeOp === TypeOperation.SIRS) {
                    typesOperation.push(TypeOperation.SIRS);
                }
                if (typeOp === TypeOperation.VISIOVOTE) {
                    typesOperation.push(TypeOperation.VISIOVOTE);
                }
            });
        }
        let hebergeur: Hebergeur | null = null;
        if (paramTypeHebergeur) {
            searchParams.getAll("hebergeur").forEach(typeHebergeur => {
                if (typeHebergeur === Hebergeur.DOCAPOSTE) {
                    hebergeur = Hebergeur.DOCAPOSTE;
                } else if (typeHebergeur === Hebergeur.OVH) {
                    hebergeur = Hebergeur.OVH;
                } else {
                    hebergeur = null;
                }
            });
        }
        let region: RegionOvh | null = null;
        if (paramTypeRegionOVH) {
            searchParams.getAll("region").forEach(regionOVH => {
                if (regionOVH === RegionOvh.GRA3) {
                    region = RegionOvh.GRA3;
                } else if (regionOVH === RegionOvh.GRA5) {
                    region = RegionOvh.GRA5;
                } else if (regionOVH === RegionOvh.SBG7) {
                    region = RegionOvh.SBG7;
                } else if (regionOVH === RegionOvh.SBG5) {
                    region = RegionOvh.SBG5;
                } else {
                    region = null;
                }
            });
        }
        let statutOperation: StatutOperation | null = null;
        if (paramStatutOp) {
            searchParams.getAll("statut").forEach(statutOp => {
                if (statutOp === StatutOperation.VOTE) {
                    statutOperation = StatutOperation.VOTE;
                    setFiltreAffichageDate(StatutOperation.VOTE);
                } else if (statutOp === StatutOperation.OUVERTURE) {
                    statutOperation = StatutOperation.OUVERTURE;
                    setFiltreAffichageDate(StatutOperation.OUVERTURE);
                } else if (statutOp === StatutOperation.CLOTURE) {
                    statutOperation = StatutOperation.CLOTURE;
                    setFiltreAffichageDate(StatutOperation.CLOTURE);
                } else if (statutOp === StatutOperation.AVANT_VOTE) {
                        statutOperation = StatutOperation.AVANT_VOTE;
                        setFiltreAffichageDate(StatutOperation.AVANT_VOTE);
                } else {
                    region = null;
                }
            });
        }

        /**
         * Récupération des alertes
         */
        const resultatsSurveillance: ResultatSurveillance[] = [];
        if (paramResultatSurveillance) {
            searchParams.getAll("surveillance").forEach(surveillance => {
                if (surveillance === ResultatSurveillance.KO) {
                    resultatsSurveillance.push(ResultatSurveillance.KO);
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({...prevState, 'siteKO': true}));
                }
                if (surveillance === ResultatSurveillance.ERREUR_CERTIFICAT) {
                    resultatsSurveillance.push(ResultatSurveillance.ERREUR_CERTIFICAT);
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'certificatKO': true
                    }));
                }
                if (surveillance === ResultatSurveillance.SCELLEMENT_KO) {
                    resultatsSurveillance.push(ResultatSurveillance.SCELLEMENT_KO);
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'scellementKO': true
                    }));
                }
                if (surveillance === ResultatSurveillance.DEMANDE_ELECTEUR_KO) {
                    resultatsSurveillance.push(ResultatSurveillance.DEMANDE_ELECTEUR_KO);
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'demandesElecteursKO': true
                    }));
                }
            })
        }
        let horodatageCertinomis: boolean | null = null;
        if (paramIsHorodatageKO) {
            searchParams.getAll("horodatage").forEach(horodatage => {
                if (horodatage === 'false') {
                    horodatageCertinomis = false;
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'horodatageKO': true
                    }));
                } else {
                    horodatageCertinomis = null;
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'horodatageKO': false
                    }));
                }
            })
        }
        let teletransmissionPVDemat: StatutPVDemat | null = null;
        if (paramTeletransmissionPVDematKO) {
            searchParams.getAll("teletransmission").forEach(teletransmission => {
                if (teletransmission === StatutPVDemat.ECHEC) {
                    teletransmissionPVDemat = StatutPVDemat.ECHEC;
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'teletransmissionPvKO': true
                    }));
                } else {
                    teletransmissionPVDemat = null;
                    setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({
                        ...prevState,
                        'teletransmissionPvKO': false
                    }));
                }
            })
        }

        /**
         * Application des filtres trouvés sur les opérations en production
         */
        operationService.filtrerParDashboard(
            {
                typesOperation,
                hebergeur,
                region,
                statutOperation,
                resultatsSurveillance,
                horodatageCertinomis,
                teletransmissionPVDemat
            },
            controller?.signal
        )
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const operationsListe = response.data;
                    setOperationsEnProduction(operationsListe);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                }
            });
    }, [
        paramTypeOp,
        paramTypeHebergeur,
        paramTypeRegionOVH,
        paramStatutOp,
        paramResultatSurveillance,
        paramIsHorodatageKO,
        paramTeletransmissionPVDematKO,
        searchParams
    ]);


    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        if (
            paramTypeOp
            || paramTypeHebergeur
            || paramTypeRegionOVH
            || paramStatutOp
            || paramResultatSurveillance
            || paramIsHorodatageKO
            || paramTeletransmissionPVDematKO
        ) {
            filtrerOperationsEnProductionParURL(controller);
        } else if (!isAppelFonctionFiltresAffichage) {
            chargerOperationsEnProduction(controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [
        paramTypeOp,
        paramTypeHebergeur,
        paramTypeRegionOVH,
        paramStatutOp,
        paramResultatSurveillance,
        paramIsHorodatageKO,
        paramTeletransmissionPVDematKO,
        filtrerOperationsEnProductionParURL,
        isAppelFonctionFiltresAffichage,
        chargerOperationsEnProduction
    ]);


    /**
     * Filtre sur les opérations en production à afficher
     */
    const filtresOperationsEnProduction = (fitresAffichage: FiltresAffichageDto) => {
        setIsLoading(true);
        setIsAppelFonctionFiltresAffichage(true);
        operationService.filtrer(fitresAffichage)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const operationsListe = response.data;
                    setOperationsEnProduction(operationsListe);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                }
            });
        navigate('/operations/production');
    }

    /**
     * Fonction permettant de mettre à jour l'état du filtre sur la date
     */
    function miseAJourFiltreDate(filtreDate: StatutOperation | Periode | OperationPhase | undefined) {
        setFiltreAffichageDate(filtreDate);
    }

    /**
     * Fonction permettant de mettre à jour l'état des filtres d'alerte
     */
    function miseAJourFiltresAlerte(filtresAlerte: FiltresAlertesType) {
        for (let [filtre, valeur] of Object.entries(filtresAlerte)) {
            setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({...prevState, [`${filtre}`]: valeur}));
        }
    }

    /**
     * Fonction permettant de mettre à jour l'état des filtres concernant les périmètres opérations
     */
    function miseAJourFiltresPerimetresOperations(filtresPerimetresOperations: FiltresPerimetresOperationsType) {
        for (let [filtre, valeur] of Object.entries(filtresPerimetresOperations)) {
            setFiltresAffichagePerimetresOperations((prevState: FiltresPerimetresOperationsType) => ({
                ...prevState,
                [`${filtre}`]: valeur
            }));
        }
    }


    /**
     * Suppression des filtres pour les opérations en production, lors du clic sur le bouton "Réinitialiser les filtres" dans les filtres d'affichage
     */
    const reinitialiserFiltresOperationsEnProduction = () => {
        chargerOperationsEnProduction();
        // Réinitialisation de la valeur des filtres (= aucun filtre sélectionné)
        setFiltreAffichageDate(undefined);
        for (let filtreA of Object.keys(filtresAffichageAlerte)) {
            setFiltresAffichageAlerte((prevState: FiltresAlertesType) => ({...prevState, [`${filtreA}`]: false}));
        }
        for (let filtrePO of Object.keys(filtresAffichagePerimetresOperations)) {
            setFiltresAffichagePerimetresOperations((prevState: FiltresPerimetresOperationsType) => ({
                ...prevState,
                [`${filtrePO}`]: false
            }));
        }
        setIsAppelFonctionFiltresAffichage(false);
        navigate('/operations/production');
    }


    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationProductionTableau[] = useMemo(() => {
        return operationsEnProduction.map(
            (operation: OperationProduction) => ({
                isOperationFavoriteUtilisateur: operation.isOperationFavoriteUtilisateur,
                idOperation: operation.idOperation,
                numeroOperation: operation.numeroOperation,
                statutOperation: operation.statutOperation,
                nom: operation.nom,
                urlSite: operation.urlSite,
                proprietaire: operation.proprietaire.prenom + " " + operation.proprietaire.nom,
                portefeuilleProjet: operation.portefeuilleProjet.nom,
                versionApplicative: operation.versionApplicative.version,
                typeOperation: operation.typeOperation,
                tourEnCours: (operation.tourEnCours !== null) ? operation.tourEnCours : undefined,
                dernierRedemarrageTomcat: operation.dernierRedemarrageTomcat,
                nomVM: operation.nomVM,
                nbJoursVM: operation.nbJoursVM,
                dateScellement: operation.dateScellement,
                ouvertureT1: operation.ouvertureT1,
                fermetureT1: operation.fermetureT1,
                ouvertureT2: operation.ouvertureT2,
                fermetureT2: operation.fermetureT2,
                hebergeur: operation.hebergeur,
                region: (operation.region) ? operation.region : undefined,
                plateforme: operation.plateforme.nom,
                isSurveillanceQRadar: operation.isSurveillanceQRadar,
                nbElections: operation.nbElections,
                nbGestionnaires: operation.nbGestionnaires,
                nbElecteurs: operation.nbElecteurs,
                participationGlobale: operation.participationGlobale,
                etatDepouillement: operation.etatDepouillement,
                statut: (operation.statut) ? operation.statut : undefined,
                acquittementSite: operation.acquittementSite,
                scellements: (operation.scellements) ? operation.scellements : undefined,
                basculeT2: (operation.basculeT2 === null) ? false : operation.basculeT2,
                clesGenereesPourTousLesBVs: (operation.clesGenereesPourTousLesBVs === null) ? false : operation.clesGenereesPourTousLesBVs,
                isServiceCertinomisOK: (operation.isServiceCertinomisOK === null) ? undefined : operation.isServiceCertinomisOK,
                statutPVDemat: (operation.statutPVDemat) ? operation.statutPVDemat : undefined,
            })
        )
    }, [operationsEnProduction]);


    /**
     * Se déclenche lorsque l'utilisateur clique sur un pictogramme dans la colonne "Etat du site"
     * et ouvre la modale de confirmation
     */
    const handleOperationAcquittement = useCallback((operation: OperationProductionTableau) => {
        setShowAcquittementModale(true);
        setSelectedOperation(operationsEnProduction.find(op => operation.idOperation === op.idOperation));
    }, [operationsEnProduction]);

    /**
     * Se déclenche à la confirmation de l'acquittement et acquitte l'opération
     */
    const operationAcquittement = useCallback(() => {
        if (selectedOperation && selectedOperation.idOperation) {
            setIsLoadingAcquittementOperation(true);
            setSuccesAcquittementOperation(false);
            setIsErrorAcquittementOperation(false);
            operationService.updateAcquittementOperation(selectedOperation.idOperation, {acquittementSite: true})
                .then((response) => {
                    if (response.status === 200 && !response.data.error) {
                        setSuccesAcquittementOperation(true);
                    } else {
                        setIsErrorAcquittementOperation(true);
                    }
                })
                .catch(() => {
                    setSuccesAcquittementOperation(false);
                    setIsErrorAcquittementOperation(true);
                })
                .finally(() => {
                    setIsLoadingAcquittementOperation(false);
                    setShowAcquittementModale(false);
                    setSelectedOperation(undefined);
                    chargerOperationsEnProduction();
                })
        }
    }, [selectedOperation, chargerOperationsEnProduction]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationProductionTableau>[] = useMemo(() => (
        [
            {
                Header: 'Favoris',
                accessor: 'isOperationFavoriteUtilisateur',
                disableFilters: true,
                minWidth: 80,
                maxWidth: 80,
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    return (
                        <GestionFavorisListe
                            setListeOperations={(opId, isFavori) => {
                                setOperationsEnProduction((prevState => prevState.map(op => {
                                    if (op.idOperation === opId) op.isOperationFavoriteUtilisateur = isFavori;
                                    return op;
                                })));
                            }}
                            isOperationFavoriteUtilisateur={props.value}
                            operationId={props.row.original.idOperation}
                            setIsErrorEnregistrementFavoris={(b) => setIsErrorEnregistrementFavoris(b)}
                            setMessageErreurFavoris={(message) => setMessageErreurFavoris(message)}
                        />
                    )
                },
                sortType: 'basic'
            },
            {
                Header: 'Numéro opération',
                accessor: 'numeroOperation',
                minWidth: 130,
            },
            {
                Header: 'Opération',
                id: 'operation',
                accessor: (originalRow: OperationProductionTableau) => ({
                    nom: originalRow.nom,
                    urlSite: originalRow.urlSite
                }),
                minWidth: 300,
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    return (
                        <>
                            <VoxLinkOperation
                                isAuthorized={isAuthorizedVisualiserOperation}
                                href={`/operation/detail/${props.row.original.idOperation}`}
                            >
                                {props.value.nom}
                            </VoxLinkOperation>
                            <br/>
                            <VoxLinkUrl
                                href={props.value.urlSite}
                                isGreyColor={true}
                            >
                                {props.value.urlSite}
                            </VoxLinkUrl>
                        </>
                    )
                },
            },
            {
                Header: 'Version applicative',
                accessor: 'versionApplicative',
                Filter: SelectInputFilter,
                filter: 'includes',
                minWidth: 150,
            },
            {
                Header: "Chef(fe) de projet",
                accessor: 'proprietaire',
                minWidth: 130,
            },
            {
                Header: "Portefeuille projet",
                accessor: 'portefeuilleProjet',
                Filter: SelectInputFilter,
                filter: 'includes',
                minWidth: 140,
            },
            {
                Header: "Type d'opération",
                accessor: 'typeOperation',
                Filter: SelectInputFilter,
                filter: 'includes',
                minWidth: 130,
            },
            {
                Header: 'Tour',
                accessor: 'tourEnCours',
                Filter: SelectInputFilter,
                filter: 'text',
                minWidth: 90,
            },
            {
                // Date redémarrage Tomcat
                Header: 'Redémarrage Tomcat',
                id: 'dernierRedemarrageTomcat',
                accessor: (originalRow: OperationProductionTableau) => affichageDateWithFormatDMYHm(originalRow.dernierRedemarrageTomcat),
                minWidth: 140,
                sortType: (a: Row<OperationProductionTableau>,
                           b: Row<OperationProductionTableau>,
                           columnId: IdType<OperationProductionTableau>,
                           desc: boolean | undefined) => sortDatesInTables(a.original.dernierRedemarrageTomcat, b.original.dernierRedemarrageTomcat, desc)
            },
            {
                Header: "Nom VM",
                accessor: 'nomVM',
                minWidth: 130,
            },
            {
                // Nombre de jours de la VM
                Header: 'Nombre Jours VM',
                accessor: 'nbJoursVM',
                minWidth: 115,
            },
            {
                // Date dernier scellement
                Header: 'Date scellement',
                id: 'dateScellement',
                accessor: (originalRow: OperationProductionTableau) => affichageDateWithFormatDMYHm(originalRow.dateScellement),
                minWidth: 140,
                sortType: (
                    a: Row<OperationProductionTableau>,
                    b: Row<OperationProductionTableau>,
                    columnId: IdType<OperationProductionTableau>,
                    desc: boolean | undefined
                ) =>  sortDatesInTables(a.original.dateScellement, b.original.dateScellement, desc)
            },
            {
                // Date ouverture du tour 1
                Header: 'Ouverture T1',
                id: 'ouvertureT1',
                accessor: (originalRow: OperationProductionTableau) => affichageDateWithFormatDMYHm(originalRow.ouvertureT1),
                minWidth: 140,
                sortType: (
                    a: Row<OperationProductionTableau>,
                    b: Row<OperationProductionTableau>,
                    columnId: IdType<OperationProductionTableau>,
                    desc: boolean | undefined
                ) => sortDatesInTables(a.original.ouvertureT1, b.original.ouvertureT1, desc)
            },
            {
                // Date fermeture du tour 1
                Header: 'Fermeture T1',
                id: 'fermetureT1',
                accessor: (originalRow: OperationProductionTableau) => affichageDateWithFormatDMYHm(originalRow.fermetureT1),
                minWidth: 140,
                sortType: (
                    a: Row<OperationProductionTableau>,
                    b: Row<OperationProductionTableau>,
                    columnId: IdType<OperationProductionTableau>,
                    desc: boolean | undefined
                ) => sortDatesInTables(a.original.fermetureT1, b.original.fermetureT1, desc)
            },
            {
                // Date ouverture du tour 2
                Header: 'Ouverture T2',
                id: 'ouvertureT2',
                accessor: (originalRow: OperationProductionTableau) => affichageDateWithFormatDMYHm(originalRow.ouvertureT2),
                minWidth: 140,
                sortType: (
                    a: Row<OperationProductionTableau>,
                    b: Row<OperationProductionTableau>,
                    columnId: IdType<OperationProductionTableau>,
                    desc: boolean | undefined
                ) => sortDatesInTables(a.original.ouvertureT2, b.original.ouvertureT2, desc)
            },
            {
                // Date fermeture du tour 2
                Header: 'Fermeture T2',
                id: 'fermetureT2',
                accessor: (originalRow: OperationProductionTableau) => affichageDateWithFormatDMYHm(originalRow.fermetureT2),
                minWidth: 140,
                sortType: (
                    a: Row<OperationProductionTableau>,
                    b: Row<OperationProductionTableau>,
                    columnId: IdType<OperationProductionTableau>,
                    desc: boolean | undefined
                ) => sortDatesInTables(a.original.fermetureT2, b.original.fermetureT2, desc)
            },
            {
                Header: 'Hébergeur',
                accessor: 'hebergeur',
                Filter: SelectInputFilter,
                filter: 'includes',
                minWidth: 130,
            },
            {
                Header: 'Région OVH',
                accessor: 'region',
                Filter: SelectInputFilter,
                filter: 'equal',
                minWidth: 130
            },
            {
                Header: 'Surveillance QRadar',
                accessor: 'isSurveillanceQRadar',
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    return (props.value)
                        ? <FontAwesomeIcon icon={"satellite-dish"}
                                           size={"2x"}
                                           color={"green"}
                                           title={"Surveillance QRadar activée"}
                        /> : null;
                },
                minWidth: 130,
                Filter: SelectBooleanInputFilter,
                filter: 'equal',
                sortType: 'basic'   // permet le tri de la colonne avec des valeurs booléennes
            },
            {
                Header: 'Type de plateforme',
                accessor: 'plateforme',
                Filter: SelectInputFilter,
                filter: 'includes',
                minWidth: 130,
            },
            {
                Header: "Nombre d'élections",
                accessor: 'nbElections',
                minWidth: 130,
            },
            {
                Header: "Nombre de gestionnaires",
                accessor: 'nbGestionnaires',
                minWidth: 130,
            },
            {
                Header: "Nombre d'électeurs",
                accessor: 'nbElecteurs',
                minWidth: 130,
            },
            {
                Header: 'Participation globale',
                id: 'participationGlobale',
                accessor: (originalRow: OperationProductionTableau) => `${(originalRow.participationGlobale === null) ? '0.00' : originalRow.participationGlobale}%`,
                minWidth: 170,
            },
            {
                // Etat du dépouillement
                Header: 'État du dépouillement',
                id: 'etatDepouillement',
                accessor: (originalRow: OperationProductionTableau) => (originalRow.etatDepouillement !== null) ? `${originalRow.etatDepouillement}%` : null,
                minWidth: 150,
            },
            {
                // Etat du site
                Header: 'État du site',
                accessor: 'statut',
                minWidth: 100,
                disableFilters: true,
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    const surveillanceEtatDuSite: Surveillance = props.value;

                    // Si au moins une surveillance a été effectuée pour cette opération, alors on peut afficher le statut de la surveillance
                    if (surveillanceEtatDuSite !== undefined) {
                        const operation = props.row.original;
                        // Statut Ouverture
                        if (
                            operation.statutOperation === StatutOperation.OUVERTURE
                            && surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                            && !operation.acquittementSite
                        ) {
                            return <FontAwesomeIcon
                                icon={"caret-square-right"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Statut : Ouverture - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                                style={{cursor: (isAuthorizedAcquittement) ? "pointer" : undefined}}
                                onClick={() => {
                                    (isAuthorizedAcquittement) && handleOperationAcquittement(props.row.original)
                                }}
                            />
                        }
                        if (
                            operation.statutOperation === StatutOperation.OUVERTURE
                            && (
                                surveillanceEtatDuSite.resultatSurveillance !== ResultatSurveillance.KO
                                || (
                                    surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                                    && operation.acquittementSite
                                )
                            )
                        ) {
                            return <FontAwesomeIcon
                                icon={"caret-square-right"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Statut : Ouverture - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                            />
                        }

                        // Statut Vote
                        if (
                            operation.statutOperation === StatutOperation.VOTE
                            && surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                            && !operation.acquittementSite
                        ) {
                            return <FontAwesomeIcon
                                icon={"users"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Statut : Vote - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                                style={{cursor: (isAuthorizedAcquittement) ? "pointer" : undefined}}
                                onClick={() => {
                                    (isAuthorizedAcquittement) && handleOperationAcquittement(props.row.original)
                                }}
                            />
                        }
                        if (
                            operation.statutOperation === StatutOperation.VOTE
                            && (
                                surveillanceEtatDuSite.resultatSurveillance !== ResultatSurveillance.KO
                                || (
                                    surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                                    && operation.acquittementSite
                                )
                            )
                        ) {
                            return <FontAwesomeIcon
                                icon={"users"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Statut : Vote - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                            />
                        }

                        // Statut Cloture
                        if (
                            operation.statutOperation === StatutOperation.CLOTURE
                            && surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                            && !operation.acquittementSite
                        ) {
                            return <FontAwesomeIcon
                                icon={"flag-checkered"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Statut : Clôture - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                                style={{cursor: (isAuthorizedAcquittement) ? "pointer" : undefined}}
                                onClick={() => {
                                    (isAuthorizedAcquittement) && handleOperationAcquittement(props.row.original)
                                }}
                            />
                        }
                        if (
                            operation.statutOperation === StatutOperation.CLOTURE
                            && (
                                surveillanceEtatDuSite.resultatSurveillance !== ResultatSurveillance.KO
                                || (
                                    surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                                    && operation.acquittementSite
                                )
                            )
                        ) {
                            return <FontAwesomeIcon
                                icon={"flag-checkered"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Statut : Clôture - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                            />
                        }

                        // Statut Hors Vote
                        if (
                            surveillanceEtatDuSite.resultatSurveillance !== null
                            && !(Object.values(StatutOperation).includes(operation.statutOperation))
                            && surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                            && !operation.acquittementSite
                        ) {
                            return <FontAwesomeIcon
                                icon={"cogs"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Aucun statut - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                                style={{cursor: (isAuthorizedAcquittement) ? "pointer" : undefined}}
                                onClick={() => {
                                    (isAuthorizedAcquittement) && handleOperationAcquittement(props.row.original)
                                }}
                            />
                        }
                        if (
                            surveillanceEtatDuSite.resultatSurveillance !== null
                            && !(Object.values(StatutOperation).includes(operation.statutOperation))
                            && (
                                surveillanceEtatDuSite.resultatSurveillance !== ResultatSurveillance.KO
                                || (
                                    surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO
                                    && operation.acquittementSite
                                )
                            )
                        ) {
                            return <FontAwesomeIcon
                                icon={"cogs"}
                                size={"2x"}
                                color={(surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.KO)
                                    ? "#F10000"  // couleur rouge
                                    : ((surveillanceEtatDuSite.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT)
                                            ? "#FFC300"  // couleur jaune
                                            : "green"  // couleur vert
                                    )
                                }
                                title={"Aucun statut - Test de site : " + surveillanceEtatDuSite.resultatSurveillance + " - " + ((operation.acquittementSite) ? "Acquitté" : "Non acquitté")}
                            />
                        }
                    }
                    // Si aucune surveillance n'a été effectuée pour cette opération, alors n'affiche pas les statuts
                    return null;
                }
            },
            {
                // Acquittement
                Header: "Acquittement site KO",
                accessor: 'acquittementSite',
                Cell: (props: CellProps<OperationProductionTableau>) => (props.value) ?
                    <FontAwesomeIcon icon={"check"} size={"lg"}/> : null,
                minWidth: 130,
                disableFilters: true,
                sortType: 'basic'
            },
            {
                // Etat du scellement
                Header: 'État du scellement',
                accessor: 'scellements',
                minWidth: 150,
                disableFilters: true,
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    if (props.value !== undefined) {
                        return <FontAwesomeIcon icon={"lock"}
                                                size={"lg"}
                                                color={
                                                    (getResultatSurveillanceScellement(props.value.resultatSurveillance) === ResultatSurveillance.KO || getResultatSurveillanceScellement(props.value.resultatSurveillance) === ResultatSurveillance.SCELLEMENT_INACTIF)
                                                        ? "#CDCCCC"  // couleur gris (inactif)
                                                        : ((getResultatSurveillanceScellement(props.value.resultatSurveillance) === ResultatSurveillance.SCELLEMENT_KO)
                                                                ? "#F10000"  // couleur rouge
                                                                : ((getResultatSurveillanceScellement(props.value.resultatSurveillance) === ResultatSurveillance.ERREUR_CERTIFICAT)
                                                                        ? "#FFC300" // couleur jaune
                                                                        : "green"  // couleur vert
                                                                )
                                                        )
                                                }
                                                title={`Scellement - ${getResultatSurveillanceScellement(props.value.resultatSurveillance)}`}
                        />
                    } else {
                        return null;
                    }
                }
            },
            {
                // T2 nécessaire ?
                Header: "Bascule T2",
                accessor: 'basculeT2',
                Cell: (props: CellProps<OperationProductionTableau>) => <p>{ (props.value) ? 'Oui' : 'Non' }</p>,
                Filter: SelectBooleanInputFilter,
                filter: 'equal',
                minWidth: 90,
                sortType: 'basic'
            },
            {
                Header: 'Clés générées',
                accessor: 'clesGenereesPourTousLesBVs',
                Cell: (props: CellProps<OperationProductionTableau>) => <p>{ (props.value) ? 'Oui' : 'Non' }</p>,
                minWidth: 115,
                Filter: SelectBooleanInputFilter,
                filter: 'equal',
                sortType: 'basic'
            },
            {
                Header: 'Horodatage Certinomis',
                accessor: 'isServiceCertinomisOK',
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    return (props.value === undefined)
                        ? null
                        : <FontAwesomeIcon icon={"fingerprint"}
                                           size={"2x"}
                                           color={(props.value)
                                               ? "green"
                                               : "#F10000" // couleur rouge
                                           }
                                           title={(props.value) ? 'Oui' : 'Non'}
                        />;
                },
                minWidth: 130,
                Filter: SelectBooleanInputFilter,
                filter: 'equal',
                sortType: 'basic'
            },
            {
                Header: 'Télétransmission PV Démat',
                accessor: 'statutPVDemat',
                Cell: (props: CellProps<OperationProductionTableau>) => {
                    if (props.value !== undefined) {
                        return <FontAwesomeIcon icon={"file-export"}
                                                size={"2x"}
                                                color={
                                                    (props.value === StatutPVDemat.NON_EFFECTUE)
                                                        ? "#CDCCCC"  // couleur gris (inactif)
                                                        : (props.value === StatutPVDemat.ECHEC)
                                                            ? "#F10000"  // couleur rouge
                                                            : "green"  // couleur vert  // StatutPVDemat.VALIDE
                                                }
                                                title={props.value}
                        />
                    } else {
                        return null;
                    }
                },
                minWidth: 150,
                Filter: SelectInputFilter,
                filter: 'equal'
            },
        ]
    ), [isAuthorizedVisualiserOperation, isAuthorizedAcquittement, handleOperationAcquittement]);

    /**
     * Mets à jour l'état local des colonnes masqués en fonction de ce qui est stocké dans le localStorage
     */
    useEffect(() => {
        const visibleColumnsStringified: (string | null) = localStorage.getItem("visibleColumns");
        if (visibleColumnsStringified) {
            const visibleColumns: string[] = JSON.parse(visibleColumnsStringified);
            if (visibleColumns) {
                setHiddenColumns(getHiddenColumnsFromVisible(visibleColumns, columns));
            } else {
                setHiddenColumns([]);
            }
        } else {
            setHiddenColumns([]);
        }
    }, [columns]);


    /**
     * Se déclenche lorsque l'utilisateur modifie l'affichage des colonnes du tableau et mets à jour
     * le localStorage et l'état local du composant
     */
    const handleColumnVisibilityChange = useCallback((visibleColumns: string[]) => {
        localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
        setHiddenColumns(getHiddenColumnsFromVisible(visibleColumns, columns))
    }, [columns]);


    /**
     * Le message d'alerte à afficher pour confirmer l'acquittement ou informer l'utilisateur qu'il y a eu une erreur
     */
    const messageAlerte = useMemo(() => {
        if (succesAcquittementOperation) {
            return (
                <VoxAlert severity={"success"} onClose={() => setSuccesAcquittementOperation(false)}>
                    L'opération a été acquittée.
                </VoxAlert>
            )
        } else if (isErrorAcquittementOperation) {
            return (
                <VoxAlert severity={"error"} onClose={() => setIsErrorAcquittementOperation(false)}>
                    Une erreur est survenue lors de l'acquittement de l'opération.
                </VoxAlert>
            )
        }
    }, [succesAcquittementOperation, isErrorAcquittementOperation]);

    return (
        <>
            {messageAlerte}
            {isLoading ?
                <VoxLoader />
                :
                <div>
                    {isErrorEnregistrementFavoris &&
                        <VoxAlert severity={"error"} sx={{mb: 3}}>
                            {messageErreurFavoris}
                        </VoxAlert>
                    }

                    <Grid container sx={{mt: 2, mb: 2}}>
                        <Grid item xs={12} md={7}>
                            <FiltresAffichage
                                filtreAffichageDate={filtreAffichageDate}
                                filtresAffichageAlerte={filtresAffichageAlerte}
                                filtresAffichagePerimetresOperations={filtresAffichagePerimetresOperations}
                                filtresOperationsEnProduction={filtresOperationsEnProduction}
                                reinitialiserFiltresOperationsEnProduction={reinitialiserFiltresOperationsEnProduction}
                                miseAJourFiltreDate={miseAJourFiltreDate}
                                miseAJourFiltresAlerte={miseAJourFiltresAlerte}
                                miseAJourFiltresPerimetresOperations={miseAJourFiltresPerimetresOperations}
                            />
                        </Grid>
                    </Grid>

                    <VoxTable
                        id={"table-operations-production"}
                        isCsvExport={true}
                        csvDataSerialization={csvDataSerializationOperationProduction}
                        csvFileName={"operations_production"}
                        handleColumnVisibilityChange={handleColumnVisibilityChange}
                        hiddenColumnsProps={hiddenColumns}
                        data={rows}
                        columns={columns}
                        cellProps={(cell: Cell<OperationProduction>) => {
                            let style = {};
                            // Couleur d'arrière-plan mise sur les cellules de la colonne "État du site"
                            if (cell.column.id === 'statut' && cell.value !== undefined && cell.value.resultatSurveillance !== ResultatSurveillance.KO
                                && cell.value.resultatSurveillance !== ResultatSurveillance.ERREUR_CERTIFICAT) {
                                style = {
                                    ...style,
                                    backgroundColor: "#d4edda"  // couleur alerte-vert
                                }
                            }
                            if (cell.column.id === 'statut' && cell.value && cell.value.resultatSurveillance === ResultatSurveillance.ERREUR_CERTIFICAT) {
                                style = {
                                    ...style,
                                    backgroundColor: "#fff3cd"  // couleur alerte-jaune
                                }
                            }
                            if (cell.column.id === 'statut' && cell.value && cell.value.resultatSurveillance === ResultatSurveillance.KO) {
                                style = {
                                    ...style,
                                    backgroundColor: "#f8d7da"  // couleur alerte-rouge
                                }
                            }
                            return {
                                style: {
                                    ...style,
                                    style,
                                }
                            };
                        }}
                    />
                    <ModaleConfirmationAcquittement
                        show={showAcquittementModale}
                        operationNom={selectedOperation?.nom}
                        authorizedAcquittement={isAuthorizedAcquittement}
                        handleClose={() => {
                            setShowAcquittementModale(false);
                            setSelectedOperation(undefined);
                        }}
                        handleSubmit={operationAcquittement}
                        loadingAcquittementOperation={isLoadingAcquittementOperation}
                    />
                </div>
            }
        </>
    )

}