import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import operationService from "../../../services/survox-back/operation/operation.service";
import { affichageLibelleEtatDemande } from "../../../utils/string.utils";
import { OperationAll } from "../../../models/operation/operationAll/operationAll.model";
import { CellProps, Column } from "react-table";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import { csvDataSerializationAllOperations } from "../../../utils/csv.utils";
import { OperationAllTableau } from "../../../models/operation/operationAll/operationAllTableau.model";
import { getHiddenColumnsFromVisible } from "../../../components/genericComponentsUI/table/columns/columnsTable";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { SelectInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxLinkOperation from "../../../components/genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";


export default function OperationsAllListe() {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [allOperations, setAllOperations] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [hiddenColumns, setHiddenColumns] = useState<(string | undefined)[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        chargerAllOperations(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, []);

    /**
     * Chargement des données : toutes les opérations
     */
    const chargerAllOperations = (controller?: AbortController) => {
        setIsLoading(true);
        setIsError(false);
        operationService.getAllOperations(controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setAllOperations(response.data);
                    setIsLoading(false);
                    setIsError(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                    setIsError(true);
                }
            });
    }


    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationAllTableau[] = useMemo(() => {
        return allOperations.map(
            (operation: OperationAll) => ({
                idOperation: operation.idOperation,
                numeroOperation: operation.numeroOperation,
                etatDemande: affichageLibelleEtatDemande(operation.etatDemande),
                nom: operation.nom,
                urlSite: operation.urlSite,
                nomVersionApplicative: operation.nomVersionApplicative,
                proprietaire: operation.proprietaire,
                nomPortefeuilleProjet: operation.nomPortefeuilleProjet,
                typeOperation: operation.typeOperation,
                hebergeur: operation.hebergeur,
                nomPlateforme: operation.nomPlateforme,
                nbElecteurs: operation.nbElecteurs,
                participationGlobale: operation.participationGlobale,
            })
        )
    }, [allOperations]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationAllTableau>[] = useMemo(() => [
        {
            Header: 'Numéro opération',
            accessor: 'numeroOperation',
            minWidth: 130,
        },
        {
            Header: "État de l'opération",
            accessor: 'etatDemande',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 150,
        },
        {
            Header: 'Opération',
            id: 'operation',
            accessor: function (originalRow: any) {
                return {
                    nom: originalRow.nom,
                    urlSite: originalRow.urlSite
                }
            },
            minWidth: 300,
            Cell: (props: CellProps<OperationAllTableau>) => {
                return (
                    <>
                        <VoxLinkOperation href={`/operation/detail/${props.row.original.idOperation}`}>
                            {props.value.nom}
                        </VoxLinkOperation>
                        <br />
                        <VoxLinkUrl href={props.value.urlSite} isGreyColor={true}>
                            {props.value.urlSite}
                        </VoxLinkUrl>
                    </>
                )
            },
        },
        {
            Header: 'Version applicative',
            accessor: 'nomVersionApplicative',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 150,
        },
        {
            Header: "Chef(fe) de projet",
            accessor: 'proprietaire',
            minWidth: 130,
        },
        {
            Header: "Portefeuille projet",
            accessor: 'nomPortefeuilleProjet',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 140,
        },
        {
            Header: "Type d'opération",
            accessor: 'typeOperation',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 130,
        },
        {
            Header: 'Hébergeur',
            accessor: 'hebergeur',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 130,
        },
        {
            Header: 'Type de plateforme',
            accessor: 'nomPlateforme',
            Filter: SelectInputFilter,
            filter: 'includes',
            minWidth: 130,
        },
        {
            Header: "Nombre d'électeurs",
            accessor: 'nbElecteurs',
            minWidth: 130,
        },
        {
            Header: 'Participation globale',
            id: 'participationGlobale',
            accessor: (originalRow: OperationAllTableau) => (`${originalRow.participationGlobale === null ? '0.00' : originalRow.participationGlobale}%`),
            minWidth: 170,
        }
    ], []
    );


    /**
     * Met à jour l'état local des colonnes masqués en fonction de ce qui est stocké dans le localStorage
     */
    useEffect(() => {
        const visibleColumnsStringified: (string | null) = localStorage.getItem("visibleColumnsAllOp");
        if (visibleColumnsStringified) {
            const visibleColumns: string[] = JSON.parse(visibleColumnsStringified);
            if (visibleColumns) {
                setHiddenColumns(getHiddenColumnsFromVisible(visibleColumns, columns));
            } else {
                setHiddenColumns([]);
            }
        } else {
            setHiddenColumns([]);
        }
    }, [columns]);


    /**
     * Se déclenche lorsque l'utilisateur modifie l'affichage des colonnes du tableau et mets à jour
     * le localStorage et l'état local du composant
     */
    const handleColumnVisibilityChange = useCallback((visibleColumns: string[]) => {
        localStorage.setItem("visibleColumnsAllOp", JSON.stringify(visibleColumns));
        setHiddenColumns(getHiddenColumnsFromVisible(visibleColumns, columns))
    }, [columns]);


    return (
        <>
            {/* Erreur lors du chargement des données */}
            {isError &&
                <VoxAlert severity={"error"} >
                    Une erreur est survenue lors de la récupération des opérations.
                </VoxAlert>
            }
            {isLoading ?
                <VoxLoader />
                :
                <VoxTable
                    isCsvExport={true}
                    csvDataSerialization={csvDataSerializationAllOperations}
                    csvFileName={"operations"}
                    handleColumnVisibilityChange={handleColumnVisibilityChange}
                    hiddenColumnsProps={hiddenColumns}
                    data={rows}
                    columns={columns}
                />
            }
        </>
    )
}