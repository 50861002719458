import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonProps } from "@mui/material";


type CustomButtonPropsType = {
    customColor?: 'blue' | 'green' | 'grey' | 'red'
}

export default function VoxButton(props: CustomButtonPropsType & ButtonProps) {

    const {customColor, variant, sx, ...otherProps} = props;

    const [color, setColor] = useState<object>({});


    const udpateGreenColorByVariant = useCallback((variant: string | undefined) => {
        switch (variant) {
            case "contained":
            case undefined:
                setColor({
                    backgroundColor: "cobalt.greenIcon",
                    '&:focus': {
                        backgroundColor: "cobalt.greenIcon"
                    },
                    '&:hover': {
                        backgroundColor: "rgba(40, 157, 21, 0.9)"
                    },
                    '&:active': {
                        backgroundColor: "cobalt.greenText"
                    }
                });
                break;
            case "outlined":
                setColor({
                    color: "cobalt.greenIcon",
                    borderColor: "cobalt.greenIcon",
                    '&:focus': {
                        color: "cobalt.greenIcon",
                        borderColor: "cobalt.greenIcon",
                        backgroundColor: "transparent"
                    },
                    '&:hover': {
                        color: "cobalt.greenText",
                        borderColor: "cobalt.greenText",
                        backgroundColor: "cobalt.greenBackground"
                    },
                    '&:active': {
                        color: "cobalt.greenText",
                        borderColor: "cobalt.greenText",
                        backgroundColor: "rgb(220, 239, 226)"
                    },
                });
                break;
            case "text":
                setColor({
                    color: "cobalt.greenIcon",
                    '&:focus': {
                        color: "cobalt.greenIcon"
                    },
                    '&:hover': {
                        color: "rgba(40, 157, 21, 0.9)"
                    },
                    '&:active': {
                        color: "cobalt.greenText"
                    }
                });
                break;
        }
    }, []);


    const udpateGreyColorByVariant = useCallback((variant: string | undefined) => {
        switch (variant) {
            case "contained":
            case undefined:
                setColor({
                    backgroundColor: "cobalt.lynch",
                    '&:focus': {
                        backgroundColor: "cobalt.lynch",
                    },
                    '&:hover': {
                        backgroundColor: "rgba(102, 109, 146, 0.9)"
                    },
                    '&:active': {
                        backgroundColor: "rgb(91, 98, 132)"
                    }
                });
                break;
            case "outlined":
                setColor({
                    color: "cobalt.lynch",
                    borderColor: "cobalt.lynch",
                    '&:focus': {
                        color: "cobalt.lynch",
                        borderColor: "cobalt.lynch",
                        backgroundColor: "transparent"
                    },
                    '&:hover': {
                        color: "cobalt.lynch",
                        borderColor: "cobalt.lynch",
                        backgroundColor: "cobalt.grey10"
                    },
                    '&:active': {
                        color: "rgb(91, 98, 132)",
                        borderColor: "rgb(91, 98, 132)",
                        backgroundColor: "rgb(222, 222, 231)"
                    }
                });
                break;
            case "text":
                setColor({
                    color: "cobalt.lynch",
                    '&:focus': {
                        color: "cobalt.lynch"
                    },
                    '&:hover, &:active': {
                        color: "rgb(72, 78, 104)"
                    }
                });
                break;
        }
    }, []);


    const udpateRedColorByVariant = useCallback((variant: string | undefined) => {
        switch (variant) {
            case "contained":
            case undefined:
                setColor({
                    backgroundColor: "cobalt.redText",
                    '&:focus': {
                        backgroundColor: "cobalt.redText"
                    },
                    '&:hover': {
                        backgroundColor: "cobalt.redIcon"
                    },
                    '&:active': {
                        backgroundColor: "rgb(187, 0, 0)"
                    }
                });
                break;
            case "outlined":
                setColor({
                    color: "cobalt.redText",
                    borderColor: "cobalt.redText",
                    '&:focus': {
                        color: "cobalt.redText",
                        borderColor: "cobalt.redText",
                        backgroundColor: "transparent"
                    },
                    '&:hover': {
                        color: "cobalt.redText",
                        borderColor: "cobalt.redText",
                        backgroundColor: "cobalt.redBackground"
                    },
                    '&:active': {
                        color: "rgb(190, 0, 0)",
                        borderColor: "rgb(190, 0, 0)",
                        backgroundColor: "rgb(252, 226, 228)"
                    },
                });
                break;
            case "text":
                setColor({
                    color: "cobalt.redText",
                    '&:focus': {
                        color: "cobalt.redText"
                    },
                    '&:hover': {
                        color: "cobalt.redIcon"
                    },
                    '&:active': {
                        color: "rgb(190, 0, 0)"
                    }
                });
                break;
        }
    }, []);



    const updateColor = useCallback((color: string | undefined, variant: string | undefined) => {
        if (color === "green") {
            udpateGreenColorByVariant(variant);
        } else if (color === "grey") {
            udpateGreyColorByVariant(variant);
        } else if (color === "red") {
            udpateRedColorByVariant(variant);
        } else {
            // color = blue (couleur par défaut)
            setColor({});
        }
    }, [udpateGreenColorByVariant, udpateGreyColorByVariant, udpateRedColorByVariant]);


    useEffect(() => {
        updateColor(customColor, variant)
    }, [customColor, variant, updateColor]);


    return (
        <Button
            variant={variant}
            sx={{
                ...color,
                ...sx
            }}
            {...otherProps}
        >
            {props.children}
        </Button>
    )
}