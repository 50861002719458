import OperationsArchiveesListe from "./operationsArchiveesListe";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";

export default function OperationsArchiveesView() {
    return (
        <>
            <VoxHeader title={"Opérations supprimées"}/>
            <OperationsArchiveesListe/>
        </>
    );
}