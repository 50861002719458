import OperationsASupprimerListe from "../../components/operationsListe/operationsASupprimerListe";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsSaturneASupprimerView() {
    return (
        <>
            <VoxHeader title={"Opérations Saturne à supprimer"}/>
            <OperationsASupprimerListe typeOperation={TypeOperation.SATURNE}/>
        </>
    );
}