import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import operationService from "../../../services/survox-back/operation/operation.service";
import {useNavigate, useParams} from "react-router-dom";
import {Operation} from "../../../models/operation/operation.model";
import OperationsDetailParametresGeneraux from "./operationsDetailParametresGeneraux";
import OperationsDetailIndicateurs from "./operationsDetailIndicateurs";
import OperationsDetailSurveillance from "./operationsDetailSurveillance";
import OperationsDetailAudit from "./operationsDetailAudit";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import {EtatDemande} from "../../../models/operation/etatDemande.enum";
import VoxPictoFavori from "../../../components/genericComponentsUI/pictogrammes/voxPictoFavori";
import favoriService from "../../../services/survox-back/operation/favori.service";
import IntroJsDetailOperation from "../../../components/tutorial/IntroJsDetailOperation";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {VoxLoaderInputFormulaire} from "../../../components/genericComponentsUI/loaders/voxLoaderInputFormulaire";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import {Paper, Tab, Tabs} from "@mui/material";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import TabPanel from "../../../components/layouts/tabs/TabPanel";
import TabContent from "../../../components/layouts/tabs/TabContent";
import OperationsDetailEnvironnements from "./operationsDetailEnvironnements";

export default function OperationsDetail() {

    // Params, Navigation & Authentification
    let params = useParams();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const utilisateurAuth = authContext.utilisateur;

    // Utilisation d'une ref pour connaitre l'état du composant,
    // éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // States
    const [operation, setOperation] = useState<Operation>();
    const [isLoadingOperation, setIsLoadingOperation] = useState(false);
    const [isLoadError, setIsLoadError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");

    // States - Gestion des favoris
    const [isLoadingFavoris, setIsLoadingFavoris] = useState<boolean>(false);
    const [isErrorFavoris, setIsErrorFavoris] = useState<boolean>(false);
    const [favorisMessageError, setFavorisMessageError] = useState<string>("");

    // Gestion des Onglets MUI
    const [activeTab, setActiveTab] = useState<number>(0);
    const handleOnChangeTabs = (event: React.SyntheticEvent, newTabIndex: number) => {
        setActiveTab(newTabIndex);
    }

    // Onglets MUI - RGAA
    const a11yProps = (index: number) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tab-panel-${index}`
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        if (params.id) {
            chargerOperation(Number(params.id), controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [params.id]);

    /**
     * Méthode qui permet de charger l'opération sélectionnée (dont on souhaite voir le détail)
     * @param id
     * @param controller
     */
    const chargerOperation = (id: number, controller: AbortController) => {
        setIsLoadingOperation(true);
        setIsLoadError(false);
        operationService.getOperationAvecRelations(id, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // récupération de l'opération
                    setOperation(response.data);
                    setIsLoadingOperation(false);
                    setIsLoadError(false);
                }
            })
            .catch((error) => {
                if (isMountedRef.current && error.message !== "canceled") {
                    setIsLoadingOperation(false);
                    if (error.response.status === 404) {
                        setIsLoadError(true);
                        setMessageError(error.response.data.message);         // "L'opération avec l'id ... n'existe pas"
                    }

                }
            });
    };

    /**
     * Permet de savoir si l'utilisateur connecté a l'opération dans ses favoris
     */
    const isOperationInFavorisUtilisateur = useMemo(() => {
        if (operation && utilisateurAuth) {
            return operation.favoris?.some((f) => f.utilisateurId === utilisateurAuth.id);
        }
    }, [operation, utilisateurAuth]);

    /**
     * Méthode qui permet de faire des appels HTTP au back lorsque l'utilisateur
     * interagit avec le pictogramme d'ajout ou de suppression des favoris
     */
    const changeOperationFavorisUtilisateur = useCallback((value: boolean, id: number) => {
        setIsLoadingFavoris(true);
        setIsErrorFavoris(false);
        setFavorisMessageError("");
        if (isOperationInFavorisUtilisateur && !value) {
            favoriService.suppressionOperationFavorisUtilisateur(id)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        setOperation((prevState) => {
                            if (prevState) {
                                return {
                                    ...prevState,
                                    favoris: prevState.favoris.filter(f => f.operationId !== id && f.utilisateurId !== utilisateurAuth.id)
                                }
                            }
                        });
                    }
                })
                .catch(() => {
                    if (isMountedRef.current) {
                        setIsErrorFavoris(true);
                        setFavorisMessageError("Il y a eu une erreur lors de la suppression de l'opération des favoris");
                    }
                })
                .finally(() => setIsLoadingFavoris(false));
        } else {
            favoriService.ajoutOperationFavorisUtilisateur(id)
                .then(response => {
                    if (isMountedRef.current && response.status === 201 && operation) {
                        setOperation((prevState) => {
                            if (prevState) {
                                return {
                                    ...prevState,
                                    favoris: [...prevState.favoris, {
                                        utilisateurId: utilisateurAuth.id,
                                        operationId: prevState.id
                                    }]
                                }
                            }
                        });
                    }
                })
                .catch(() => {
                    if (isMountedRef.current) {
                        setIsErrorFavoris(true);
                        setFavorisMessageError("Il y a eu une erreur lors de l'ajout de l'opération dans les favoris");
                    }
                })
                .finally(() => setIsLoadingFavoris(false));
        }
    }, [isOperationInFavorisUtilisateur, operation, utilisateurAuth.id]);

    /**
     * Pictogramme lié à la gestion des favoris pour l'opération
     */
    const pictogrammeFavoris = useMemo(() => {
        if (operation && operation.etatDemande !== EtatDemande.SUPPRIME) {
            return (
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "100px",
                        minHeight: "42px",
                        border: "#d2d2d2 solid 1px",
                        borderRadius: "24px",
                        paddingX: "10px"
                    }}
                    elevation={0}
                >
                    {(isLoadingFavoris) ?
                        <VoxLoaderInputFormulaire/>
                        :
                        ((isOperationInFavorisUtilisateur !== undefined) ?
                                <VoxPictoFavori
                                    isFavori={isOperationInFavorisUtilisateur}
                                    operationId={operation.id}
                                    onChange={changeOperationFavorisUtilisateur}
                                    iconSize={"lg"}
                                    libelle={true}
                                />
                                : undefined
                        )
                    }
                </Paper>
            )
        } else {
            return undefined;
        }
    }, [operation, isLoadingFavoris, isOperationInFavorisUtilisateur, changeOperationFavorisUtilisateur])


    return (
        <>
            {/* Header spécifique étendu (titre à gauche) */}
            <VoxHeader
                title={operation ? operation.nom : undefined}
                isLeftTitleMode={true}
                boutonFavoris={pictogrammeFavoris}
                boutonLibre={
                    // Bouton de modification d'une opération, visible uniquement pour un utilisateur du groupe "ADMINISTRATEUR" ou "SUPPORT_SI_INFRA"
                    // ET il faut que le champ etatDemande de l'opération soit différent de "SUPPRIME"
                    ((utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA)
                        && (operation && operation.etatDemande !== EtatDemande.SUPPRIME)) ?
                        <VoxButton
                            sx={{py: 2.8, px: 4}}
                            startIcon={<FontAwesomeIcon icon={"edit"}/>}
                            onClick={() => navigate(`/operation/modification/${params.id}`)}
                        >
                            Modification
                        </VoxButton>
                        : undefined
                }
                boutonTutoriels={<IntroJsDetailOperation/>}
            />

            {/* Erreur liée au bouton "Suivre/Ne plus suivre" */}
            {isErrorFavoris && (
                <VoxAlert severity={"error"} sx={{marginBottom: 4}} onClose={() => setIsErrorFavoris(false)}>
                    {favorisMessageError}
                </VoxAlert>
            )}

            {/* Onglets */}
            <Tabs
                value={activeTab}
                onChange={handleOnChangeTabs}
                variant={"scrollable"}
                allowScrollButtonsMobile
                aria-label={"Onglets de navigation"}
                sx={{
                    borderColor: 'rgba(0,0,0,0.08)',
                    '& .MuiButtonBase-root:not(.MuiTabs-scrollButtons)': {
                        paddingTop: '18px',
                        borderRadius: '5px 5px 0 0',
                        transition: 'background-color 0.2s ease-in-out'
                    },
                    '& .MuiButtonBase-root:hover:not(.Mui-selected)': {
                        backgroundColor: 'rgba(0,0,0,0.025)'
                    },
                    '& .Mui-selected': {
                        backgroundColor: 'white',
                        boxShadow: '0 0 5px rgba(0,0,0,0.08)'
                    }
                }}
            >
                <Tab label={"Paramètres généraux"} {...a11yProps(0)}/>
                <Tab label={"Environnements"} {...a11yProps(1)}/>
                <Tab label={"Indicateurs"} {...a11yProps(2)}/>
                <Tab label={"Surveillance"} {...a11yProps(3)}/>
                <Tab label={"Audit"} {...a11yProps(4)}/>
            </Tabs>

            {/* Onglet "Paramètres généraux */}
            <TabPanel index={0} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operation &&
                    <OperationsDetailParametresGeneraux operation={operation}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Environnements */}
            <TabPanel index={1} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operation &&
                    <OperationsDetailEnvironnements operationId={operation.id}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Indicateurs" */}
            <TabPanel index={2} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operation &&
                    <OperationsDetailIndicateurs operation={operation}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Surveillance" */}
            <TabPanel index={3} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operation &&
                    <OperationsDetailSurveillance operation={operation}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Audit */}
            <TabPanel index={4} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operation &&
                    <OperationsDetailAudit operationId={operation.id}/>
                    }
                </TabContent>
            </TabPanel>
        </>
    )
}