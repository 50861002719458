import React, {useContext} from "react";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import './profil.css'
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import {Box, Card, CardContent, Grid, TextField} from "@mui/material";


export default function Profil() {

    // État de l'utilisateur connecté
    const authContext = useContext(AuthContext);
    const utilisateur = authContext.utilisateur;

    return (
        <>
            <VoxHeader title={"Mon profil"}/>

            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={8} md={4}>
                    <Card variant={"outlined"} sx={{position: "relative"}}>
                        <CardContent>
                            <Box
                                component={"form"}
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"1.5rem"}
                                padding={"1.25rem 2rem"}
                            >

                                {/* LOGIN */}
                                <TextField
                                    id={"login"}
                                    label={"Login"}
                                    defaultValue={utilisateur.login}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                {/* PRÉNOM */}
                                <TextField
                                    id={"prenom"}
                                    label={"Prénom"}
                                    defaultValue={utilisateur.prenom}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                {/* NOM */}
                                <TextField
                                    id={"nom"}
                                    label={"Nom"}
                                    defaultValue={utilisateur.nom}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                {/* ADRESSE E-MAIL */}
                                <TextField
                                    id={"email"}
                                    label={"Adresse e-mail"}
                                    defaultValue={utilisateur.mail}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                {/* GROUPE UTILISATEUR */}
                                <TextField
                                    id={"groupe"}
                                    label={"Groupe utilisateur"}
                                    defaultValue={utilisateur.groupe.nom}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}