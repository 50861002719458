import axios_instance from "./axios-client";

const appService = {

    getInformationsTechniques: (signal: AbortSignal) => {
        return axios_instance.get('/app/informationsTechniques', {signal: signal});
    }

}

export default appService;