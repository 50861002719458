export enum TypeLivrable {
    LIVRABLE_UNIQUE = "LIVRABLE_UNIQUE",
    LIVRABLE_FRONT_ELECTEUR = "LIVRABLE_FRONT_ELECTEUR",
    LIVRABLE_BACK_ELECTEUR = "LIVRABLE_BACK_ELECTEUR",
    LIVRABLE_ADMIN = "LIVRABLE_ADMIN"
}

export const getTypeLivrableDisplayedText = (typeLivrable: TypeLivrable) => {
    switch (typeLivrable) {
        case TypeLivrable.LIVRABLE_UNIQUE: return "Unique";
        case TypeLivrable.LIVRABLE_FRONT_ELECTEUR: return "Front Électeur";
        case TypeLivrable.LIVRABLE_BACK_ELECTEUR: return "Back Électeur";
        case TypeLivrable.LIVRABLE_ADMIN: return "Admin"
    }
}