import {Commentaire} from "../../models/commentaire/commentaire.model";
import {affichageDateWithFormatDMYHm} from "../../utils/string.utils";
import "./commentaireCard.css"
import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function CommentaireCard(props: { commentaire: Commentaire }) {

    return (
        <>
            <Grid
                container
                justifyContent={"space-between"}
                marginBottom={1}
            >
                <Grid item>
                    <Typography component={"span"} variant={"subtitle2"}>
                        <FontAwesomeIcon icon={"user"} size={"sm"} style={{marginRight: '0.5rem'}} />
                        {`${props.commentaire.utilisateur.prenom} ${props.commentaire.utilisateur.nom}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"body2"} color={'cobalt.lynch'}>
                        {affichageDateWithFormatDMYHm(props.commentaire.date)}
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{whiteSpace: "pre-wrap"}}>
                {props.commentaire.commentaire}
            </Box>
        </>
    )
}