import { Navigate, Route, Routes } from "react-router-dom";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import { Grid } from "@mui/material";
import VersionsDesProduitsGestion from "./versionsDesProduitsGestion";
import VersionDesProduitsCreation from "./versionsDesProduitsCreation";


export default function VersionsDesProduitsView() {
    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Versions des produits"}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={10} md={8}>
                            <VersionsDesProduitsGestion/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"/creation"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true}/>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8} md={5}>
                            <VersionDesProduitsCreation/>
                        </Grid>
                    </Grid>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}