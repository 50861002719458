import { SIRSOperation } from "../../models/operationSIRS/sirsOperation.model";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import operationSIRSService from "../../services/survox-back/operationSIRS/operationSIRS.service";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { CellProps, Column } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { affichageLibelleEtatDemande } from "../../utils/string.utils";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import VoxButton from "../genericComponentsUI/button/voxButton";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import {Box, Grid, Typography} from "@mui/material";
import VoxTable from "../genericComponentsUI/table/voxTable";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";
import VoxModal from "../modals/voxModal";
import OperationSIRSService from "../../services/survox-back/operationSIRS/operationSIRS.service";
import {VoxAlert} from "../genericComponentsUI/alerte/voxAlert";

type OperationsSIRSTableau = {
    id: number;
    nomClient: string;
    url: string;
    etatDemande: EtatDemande;
    dateCreation: Date;
}


export default function OperationsSIRSListe() {

    // Utilisation d'une ref pour connaître l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [operationsSIRS, setOperationsSIRS] = useState<SIRSOperation[]>([]);

    const [showConfirmationDemandeSuppressionModale, setShowConfirmationDemandeSuppressionModale] = useState<boolean>(false);
    const [selectedOperationSIRS, setSelectedOperationSIRS] = useState<SIRSOperation>();

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isSuccessDemandeSuppression, setIsSuccessDemandeSuppression] = useState(false);
    const [isErrorDemandeSuppression, setIsErrorDemandeSuppression] = useState(false);


    const chargerOperationsSIRS = useCallback((controller?: AbortController) => {

        /**
         * Charger les opérations SIRS de l'utilisateur courant
         */
        setIsLoading(true);
        operationSIRSService.getAllOperationsSIRSByCurrentUtilisateur(controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const operationsSIRSDeUtilisateur: SIRSOperation[] = response.data.map((operationSIRS: SIRSOperation) => {
                        return {...operationSIRS, isInstalling: false, isLoadingActions: false}
                    });
                    setOperationsSIRS(operationsSIRSDeUtilisateur);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                if (isMountedRef.current) {
                    setIsLoading(false)
                }
            })
    }, []);


    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        chargerOperationsSIRS(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [chargerOperationsSIRS]);

    /**
     * Méthode qui permet d'ouvrir la modale de confirmation de demande de suppression de l'opération SIRS
     */
    const handleOperationDemandeSuppression = useCallback((operationId: number) => {
        const operationSIRS = operationsSIRS.find(op => Number(op.id) === operationId);
        if (operationSIRS) {
            setShowConfirmationDemandeSuppressionModale(true);
            setSelectedOperationSIRS(operationSIRS);
        }
    }, [operationsSIRS]);


    /**
     * Méthode de demande de suppression (appel de la méthode back)
     */
    const demandeSuppressionOperationSIRS = () => {
        setIsSuccessDemandeSuppression(false);
        setIsErrorDemandeSuppression(false);
        if (selectedOperationSIRS) {
            OperationSIRSService.demandeSuppressionOperationSIRS(selectedOperationSIRS.id)
                .then((response) => {
                    if (response.status === 200) {
                        setIsSuccessDemandeSuppression(true);
                        setIsErrorDemandeSuppression(false);
                    } else {
                        setIsSuccessDemandeSuppression(false);
                        setIsErrorDemandeSuppression(true);
                    }
                })
                .catch(() => {
                    setIsSuccessDemandeSuppression(false);
                    setIsErrorDemandeSuppression(true);
                })
                .finally(() => {
                    setShowConfirmationDemandeSuppressionModale(false);
                    chargerOperationsSIRS();
                })
        }
    }

    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationsSIRSTableau[] = useMemo(() => {
        return operationsSIRS.map(
            (operationSIRS: SIRSOperation) => ({
                // Accessors
                id: operationSIRS.id,
                nomClient: operationSIRS.nomClient,
                url: operationSIRS.url,
                etatDemande: operationSIRS.etatDemande,
                dateCreation: operationSIRS.dateCreation
            })
        ).sort((a, b) => new Date(a.dateCreation).getTime() - new Date(b.dateCreation).getTime());
    }, [operationsSIRS]);

    /**
     * Cellules du tableau
     */
    const cellNomClient = useCallback((props: CellProps<OperationsSIRSTableau>) => {
        return (
            <VoxLinkOperation href={`/sirs/detail/${props.row.original.id}`}>{props.value}</VoxLinkOperation>
        )
    }, []);

    const cellUrl = useCallback((props: CellProps<OperationsSIRSTableau>) => {
        return (
            <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
        )
    }, []);

    const cellEtatDemande = useCallback((props: CellProps<OperationsSIRSTableau>) => {
        return (
            <>{affichageLibelleEtatDemande(props.value)}</>
        )
    }, []);

    /**
     * Actions possibles
     */
    const cellActions = useCallback((props: CellProps<OperationsSIRSTableau>) => {
        return (
            <Box display={"flex"} justifyContent={"center"} gap={1}>

                {/* Oeil → Détail de l'opération SIRS */}
                <VoxIconAction
                    tooltip={"Consulter la fiche de l'opération"}
                    icon={"eye"}
                    onClick={() => navigate(`../../sirs/detail/${props.row.original.id}`)}
                />

                {/* Crayon → Modification de l'opération */}
                {(props.row.original.etatDemande === EtatDemande.DECLARE) &&
                    <VoxIconAction
                        tooltip={"Modifier l'opération"}
                        icon={"edit"}
                        onClick={() => navigate(`modification/${props.row.original.id}`)}
                    />
                }

                {/* Corbeille → Demande de suppression de l'opération */}
                {(props.row.original.etatDemande === EtatDemande.EN_PRODUCTION) &&
                    <VoxIconAction
                        tooltip={"Demander la suppression"}
                        icon={"trash-alt"}
                        onClick={() => handleOperationDemandeSuppression(Number(props.row.original.id))}
                    />
                }
            </Box>
        )
    }, [navigate, handleOperationDemandeSuppression]);



    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationsSIRSTableau>[] = useMemo(() => [
        {
            Header: 'Nom du client',
            accessor: 'nomClient',
            flexGrow: 1,
            minWidth: 120,
            Cell: cellNomClient
        },
        {
            Header: 'Site',
            accessor: 'url',
            flexGrow: 1,
            minWidth: 120,
            Cell: cellUrl
        },
        {
            Header: "État d'installation",
            accessor: 'etatDemande',
            flexGrow: 1,
            minWidth: 120,
            Cell: cellEtatDemande
        },
        {
            Header: 'Actions à effectuer',
            id: 'actions',
            flexGrow: 0.5,
            disableFilters: true,
            disableSortBy: true,
            Cell: cellActions
        }
    ], [cellNomClient, cellUrl, cellEtatDemande, cellActions]);

    /**
     * Redirige vers la page de création d'une opération SIRS
     */
    const handleOnCreationOperation = () => {
        navigate(`./../declarer_operation`);
    };

    /**
     * Message d'information suite à la demande de suppression
     */
    const messageAlerteDemandeSuppression = useMemo(() => {
        if (isSuccessDemandeSuppression) {
            return (
                <VoxAlert severity={"success"} sx={{mb: 2}}
                          onClose={() => setIsSuccessDemandeSuppression(false)}
                >
                    La demande de suppression pour l'opération SIRS "{selectedOperationSIRS?.nomClient}" a bien été prise en compte.
                </VoxAlert>
            )
        } else if (isErrorDemandeSuppression) {
            return (
                <VoxAlert severity={"error"} sx={{mb: 2}}
                          onClose={() => setIsErrorDemandeSuppression(false)}
                >
                    Une erreur est survenue lors de la demande de suppression.
                </VoxAlert>
            )
        }
    }, [isErrorDemandeSuppression, isSuccessDemandeSuppression, selectedOperationSIRS]);

    return (
        <>
            {messageAlerteDemandeSuppression}
            {isLoading ? <VoxLoader /> :
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12}>
                        <VoxButton
                            id={"btn-nouvelle-operation-sirs"}
                            startIcon={<FontAwesomeIcon icon={"plus"}/>}
                            onClick={handleOnCreationOperation}
                        >
                            Nouvelle opération SIRS
                        </VoxButton>
                        <VoxTable
                            id={"table-mes-operations"}
                            data={rows}
                            columns={columns}
                        />

                        <VoxModal
                            open={showConfirmationDemandeSuppressionModale}
                            onSubmit={demandeSuppressionOperationSIRS}
                            onClose={() => setShowConfirmationDemandeSuppressionModale(false)}
                            title={"Demande de suppression d'une opération SIRS"}
                            closeButtonText={"Non"}
                            submitButtonText={"Confirmer"}
                            content={
                                <Typography variant={"body1"}>
                                    Souhaitez-vous vraiment demander la suppression de l'opération SIRS {selectedOperationSIRS && `"${selectedOperationSIRS.nomClient}"`} ?
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}