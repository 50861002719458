import {Navigate, Route, Routes} from "react-router-dom";
import OperationsListe from "../../components/operationsListe/operationsListe";
import OperationsModification from "../../components/forms/operationsFormulaires/operationsModification";
import React from "react";
import {TypeOperation} from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";


export default function OperationsView() {

    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Mes opérations VoxCore-Election, VoxCore-AG & Actionnariat"}/>
                    <OperationsListe typeOperation={"VoxCoreElection_VoxCoreAG_Actionnariat"}/>
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/operations/mes_operations"}/>
                    <OperationsModification typesOperation={[
                        TypeOperation.VOXCORE_ELECTION,
                        TypeOperation.VOXCORE_AG,
                        TypeOperation.ACTIONNARIAT]}
                    />
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}