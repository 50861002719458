import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { UrlSpecifique } from "../../models/operation/urlSpecifique.model";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { CreateUrlSpecifiqueDto } from "../../services/survox-back/operation/dtos/request/createUrlSpecifique.dto";
import { EditUrlsSpecifiquesDto } from "../../services/survox-back/operation/dtos/request/editUrlsSpecifiques.dto";
import { PATTERN_URL, TITLE_PATTERN_URL } from "../../utils/string.utils";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VoxRedTrashButton } from "../genericComponentsUI/button/voxRedTrashButton";
import { Box, Dialog, DialogContent, Grid } from "@mui/material";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { VoxTextField } from "../genericComponentsUI/input/voxTextField";
import { VoxDialogTitle } from "./voxDialogTitle";

type PropsType = {
    show: boolean;
    onClose: () => void;
    isLoading: boolean;
    isErrorEdittingUrlSpecifique: boolean;
    errorMessageEdittingUrlSpecifique: string;
    urlsSpecifiquesProps: UrlSpecifique[];
    editUrlsSpecifiques: (dto: EditUrlsSpecifiquesDto) => void;
}

type EditUrlsSpecifiquesType = UrlSpecifique & { isUpdated: boolean, isDeleted: boolean }

export default function ModaleUrlsSpecifiques(props: PropsType) {

    const [createUrlSpecifiqueValue, setCreateUrlSpecifiqueValue] = useState<string>("");

    const [newUrlsSpecifiques, setNewUrlsSpecifiques] = useState<CreateUrlSpecifiqueDto[]>([]);

    const [urlsSpecifiquesEdit, setUrlsSpecifiquesEdit] = useState<EditUrlsSpecifiquesType[]>([]);

    /**
     * Callback qui va réinitialiser le formulaire d'édition des URLs spécifiques
     * -> réinitialise l'état local du composant
     */
    const resetUrlsSpecifiques = useCallback(() => {
        setUrlsSpecifiquesEdit(
            props.urlsSpecifiquesProps.map(urlSpecifiques => ({
                ...urlSpecifiques,
                isUpdated: false,
                isDeleted: false
            }))
        );
        setNewUrlsSpecifiques([]);
        setCreateUrlSpecifiqueValue("");
    }, [props.urlsSpecifiquesProps]);


    /**
     * Hook permettant de réinitialiser l'état du formulaire lors de la modification des urls spécifiques passés en
     * props par le composant parent
     */
    useEffect(() => {
        resetUrlsSpecifiques()
    }, [resetUrlsSpecifiques]);

    /**
     * Callback qui s'éxécute lors de la fermeture de la modale
     * -> réinitialise l'état local du composant
     */
    const handleOnClose = useCallback(() => {
        resetUrlsSpecifiques();
        props.onClose();
    }, [props, resetUrlsSpecifiques]);

    /**
     * Callback qui s'éxécute lorsque l'utilisateur veut supprimer une Url Spécifique
     * -> met à jour l'état local du composant en utilisant un accumulateur
     */
    const handleOnDeleteUrlSpecifique = useCallback((urlSpecifiqueId: number) => {
        setUrlsSpecifiquesEdit(prevState => prevState.reduce((memo,urlSpecifique) => {
            if (urlSpecifique.id === urlSpecifiqueId) {
                urlSpecifique.isUpdated = false;
                urlSpecifique.isDeleted = true;
            }
            memo.push(urlSpecifique);
            return memo;
        }, [] as EditUrlsSpecifiquesType[]));
    }, []);

    /**
     * Callback qui s'éxécute lorsque l'utilisateur veut annuler la suppression une Url Spécifique
     * -> met à jour l'état local du composant en utilisant un accumulateur
     */
    const handleOnCancelDeleteUrlSpecifique = useCallback((urlSpecifiqueId: number) => {
        setUrlsSpecifiquesEdit(prevState => prevState.reduce((memo,urlSpecifique) => {
            if (urlSpecifique.id === urlSpecifiqueId) {
                const propsUrlSpecifique = props.urlsSpecifiquesProps.find(item => item.id === urlSpecifiqueId);
                urlSpecifique.isUpdated = !!(propsUrlSpecifique && urlSpecifique.url !== propsUrlSpecifique.url);
                urlSpecifique.isDeleted = false;
            }
            memo.push(urlSpecifique);
            return memo;
        }, [] as EditUrlsSpecifiquesType[]));
    }, [props.urlsSpecifiquesProps]);

    /**
     * Callback permettant de mettre à jour l'état local lorsqu'une url est mise à jour
     */
    const handleOnChangeUrlSpecifique = useCallback((urlSpecifiqueId: number, url: string) => {
        setUrlsSpecifiquesEdit(prevState => prevState.map(urlSpecifique => {
            if (urlSpecifique.id === urlSpecifiqueId) {
                urlSpecifique.url = url;
                urlSpecifique.isUpdated = true;
            }
            return urlSpecifique;
        }));
    }, []);


    /**
     * Callback qui s'éxécute lorsque l'utilisateur valide le formulaire de création d'une url spécifique
     * -> crée la nouvelle Url et la conserve dans l'état local du composant les informations seront envoyés à
     * survox-back lors de la validation globale du formulaire
     */
    const handleOnSubmitCreate = useCallback((event: FormEvent) => {
        event.preventDefault();
        setNewUrlsSpecifiques(prevState => [...prevState, { url: createUrlSpecifiqueValue }]);
        setCreateUrlSpecifiqueValue("");
    }, [createUrlSpecifiqueValue]);

    /**
     * Callback qui s'éxécute lors de la validation du formulaire global par l'utilisateur
     * -> réinitialise l'état local et notifie le composant parents de l'action à l'aide de la fonction passée en props
     * pour enregistrer les modifications
     */
    const handleOnSubmitEdit = useCallback((event: FormEvent) => {
        event.preventDefault();
        props.editUrlsSpecifiques({
            createUrlsSpecifiques: newUrlsSpecifiques,
            updateUrlsSpecifiques: urlsSpecifiquesEdit.filter(item => item.isUpdated).map(item => ({
                id: item.id,
                url: item.url
            })),
            deleteUrlsSpecifiques: urlsSpecifiquesEdit.filter(item => item.isDeleted).map(item => ({
                id: item.id,
                url: item.url
            }))
        })
        resetUrlsSpecifiques();
    }, [newUrlsSpecifiques, urlsSpecifiquesEdit, props, resetUrlsSpecifiques]);

    /**
     * Formulaire d'ajout d'une Url Spécifique
     */
    const formulaireCreationUrlsSpecifiques = useMemo(() => (
        <Box component={"form"} onSubmit={handleOnSubmitCreate}>
            <Grid container>
                <Grid item flex={"auto"}>
                    <VoxTextField
                        id={"id-new-url-specifique"}
                        label={"Nouvelle URL spécifique"}
                        value={createUrlSpecifiqueValue}
                        onChange={(event) => setCreateUrlSpecifiqueValue(event.target.value)}
                        pattern={PATTERN_URL}
                        title={TITLE_PATTERN_URL}
                        fullWidth={true}
                        required={true}
                    />
                </Grid>
                <Grid item display={"flex"} flex={"0 0 135px"} alignItems={"end"}>
                    <VoxButton
                        customColor={"green"}
                        sx={{fontSize: "0.85rem", ml: 2, mt: 1}}
                        startIcon={<FontAwesomeIcon icon={"plus"}/>}
                        type={"submit"}
                    >
                        Ajouter
                    </VoxButton>
                </Grid>
            </Grid>
        </Box>
    ), [handleOnSubmitCreate, createUrlSpecifiqueValue]);

    /**
     * Formulaire de modification des Urls Spécifiques
     */
    const formulaireEditUrlsSpecifiques = useMemo(() => (
        <Box component={"form"} onSubmit={handleOnSubmitEdit} sx={{mt: 5}}>
            {
                urlsSpecifiquesEdit.map(urlSpecifique => (
                    <Grid container key={`edit-${urlSpecifique.id}`} sx={{mb: 1}}>
                        <Grid item flex={"auto"}>
                           <VoxTextField
                               id={`id-edit-url-specifique-${urlSpecifique.id}`}
                               sx={{
                                   '.MuiInputLabel-asterisk':{
                                       display: 'none'
                                   }
                               }}
                               value={urlSpecifique.url}
                               onChange={(event) => {
                                   handleOnChangeUrlSpecifique(urlSpecifique.id, event.target.value)
                               }}
                               pattern={PATTERN_URL}
                               title={TITLE_PATTERN_URL}
                               disabled={urlSpecifique.isDeleted}
                               fullWidth={true}
                               required={true}
                           />
                        </Grid>
                        <Grid item display={"flex"} flex={"0 0 135px"} alignItems={"end"}>
                            {
                                urlSpecifique.isDeleted
                                    ? (
                                        <VoxButton
                                            customColor={"grey"}
                                            variant={"outlined"}
                                            size={"small"}
                                            sx={{
                                                minWidth: "32px",
                                                width: "32px",
                                                height: "32px",
                                                ml: 2,
                                                mt: 1,
                                                mb: 0.5
                                            }}
                                            onClick={() => handleOnCancelDeleteUrlSpecifique(urlSpecifique.id)}
                                        >
                                            <FontAwesomeIcon icon={"times"}/>
                                        </VoxButton>
                                    ) : (
                                        <VoxRedTrashButton
                                            sx={{ml: 2, mt: 1, mb: 0.5}}
                                            onClick={() => handleOnDeleteUrlSpecifique(urlSpecifique.id)}
                                        />
                                    )
                            }
                        </Grid>
                    </Grid>
                ))
            }
            {
                newUrlsSpecifiques.map((newUrlSpecifique, key) => (
                    <Grid container key={`new-${key}`} sx={{mb: 1}}>
                        <Grid item flex={"auto"}>
                            <VoxTextField
                                id={`id-edit-new-url-specifique-${key}`}
                                sx={{
                                    '.MuiInputLabel-asterisk':{
                                        display: 'none'
                                    }
                                }}
                                value={newUrlSpecifique.url}
                                onChange={(event) => setNewUrlsSpecifiques(prevState => (
                                    prevState.map((urlSpecifique, index) => {
                                        if (index === key) { urlSpecifique.url = event.target.value }
                                        return urlSpecifique;
                                    })
                                ))}
                                pattern={PATTERN_URL}
                                title={TITLE_PATTERN_URL}
                                fullWidth={true}
                                required={true}
                            />
                        </Grid>
                        <Grid item display={"flex"} flex={"0 0 135px"} alignItems={"end"}>
                            <VoxRedTrashButton
                                sx={{ml: 2, mt: 1, mb: 0.5}}
                                onClick={() => setNewUrlsSpecifiques(prevState => (
                                    prevState.filter((urlSpecifique, index) => (index !== key))
                                ))}
                            />
                        </Grid>
                    </Grid>
                ))
            }

            <Box
                component={"div"}
                display={"flex"}
                justifyContent={"center"}
                marginTop={5}
            >
                    <VoxButton
                        sx={{minWidth: "190px"}}
                        startIcon={<FontAwesomeIcon icon={"save"}/>}
                        type={"submit"}
                        disabled={newUrlsSpecifiques.length === 0 && urlsSpecifiquesEdit.length === 0}
                    >
                        Enregistrer les modifications
                    </VoxButton>
            </Box>
        </Box>
    ), [
        newUrlsSpecifiques,
        urlsSpecifiquesEdit,
        handleOnChangeUrlSpecifique,
        handleOnDeleteUrlSpecifique,
        handleOnCancelDeleteUrlSpecifique,
        handleOnSubmitEdit
    ]);


    return (
        <Dialog open={props.show}
                onClose={!props.isLoading ? handleOnClose : undefined}
                fullWidth
        >
            <VoxDialogTitle onClose={!props.isLoading ? handleOnClose : undefined}>
                Gestion des URLs spécifiques
            </VoxDialogTitle>

            <DialogContent
                dividers
            >
                { (
                    props.isErrorEdittingUrlSpecifique
                    && props.errorMessageEdittingUrlSpecifique
                ) && (
                    <VoxAlert severity={"error"} sx={{mb: 4}}>
                        { props.errorMessageEdittingUrlSpecifique }
                    </VoxAlert>
                )
                }

                { formulaireCreationUrlsSpecifiques }

                { (
                    (
                        props.urlsSpecifiquesProps.length === 0
                        && newUrlsSpecifiques.length === 0
                        && urlsSpecifiquesEdit.length === 0
                    )
                    && !props.isLoading
                    && !props.isErrorEdittingUrlSpecifique
                ) && (
                    <VoxAlert severity={"info"} sx={{marginTop: 3}}>
                        Aucune URL spécifique pour cette opération.
                    </VoxAlert>
                )
                }

                { formulaireEditUrlsSpecifiques }

            </DialogContent>

            {(props.isLoading) &&
                <VoxLoader isBackgroundColor={true}/>
            }
        </Dialog>
    )
}