import IntroJS from './IntroJs';
import {allSteps} from "../../models/tutorial/allSteps.model";

export default function IntroJsAccueil() {

    // Contenu des étapes - Page d'accueil
    const steps: allSteps[] = [
        {
            // 1. Tableau global
            element: "#table-operations-suivies",
            position: "bottom-middle-aligned",
            intro: <p>Dans ce tableau apparaissent toutes les opérations qui sont dans les portefeuilles que vous avez choisi de suivre et les opérations que vous avez sélectionnées comme "favorites".</p>
        }
    ];

    return (

        <IntroJS
            steps={steps}
        />

    );

}