import React from "react";
import {Parametrage} from "../../models/parametrage/parametrage.model";

/**
 * Interface qui définit les informations qui sont accessible dans le context de maintenance partout dans
 * l'application React
 */
export interface ParametresContextType {
    parametrages: Parametrage[],
    isErrorParametrages: boolean,
    isLoadingParametrages: boolean,
    updateParametrage: () => void
}

/**
 * Création d'un contexte React qui permet de conserver l'état des valeurs du paramétrage,
 * accessible partout dans l'application
 */
export const ParametresContext = React.createContext<ParametresContextType>({} as ParametresContextType);