export enum EtatDemande {
    DECLARE = "declare",
    COMMANDE = "commande",
    LIVRE_OVH = "livre_ovh",
    DNS_DECLARE = "dns_declare",
    INSTALLATION_ANSIBLE = "installation_ansible",
    ERREUR_INSTALLATION_ANSIBLE = "erreur_installation_ansible",
    INSTALLE_ANSIBLE = "installe_ansible",
    INSTALLE = "installe",
    DNS_VALIDE = "dns_valide",
    VM_INITIALISEE = "vm_initialisee",
    EN_PRODUCTION = "en_production",
    A_SUPPRIMER = "a_supprimer",
    SUPPRESSION_ANSIBLE = "suppression_ansible",
    SUPPRIME_ANSIBLE = "supprime_ansible",
    SUPPRIME = "supprime",
    ERREUR = "erreur",
    A_ANNULER = "a_annuler",
    EN_CONTENTIEUX = "en_contentieux",
    A_ARCHIVER = "a_archiver",
    ABANDONNE = "abandonne"
}

export const INSTALLATION_EN_COURS : EtatDemande[] = [
    EtatDemande.COMMANDE,
    EtatDemande.LIVRE_OVH,
    EtatDemande.DNS_DECLARE,
    EtatDemande.INSTALLATION_ANSIBLE,
    EtatDemande.ERREUR_INSTALLATION_ANSIBLE,
    EtatDemande.INSTALLE_ANSIBLE,
    EtatDemande.DNS_VALIDE,
    EtatDemande.VM_INITIALISEE
];

export const ETATS_DEMANDES_ENVIRONNEMENT : EtatDemande[] = [
    EtatDemande.DECLARE,
    EtatDemande.INSTALLE,
    EtatDemande.A_SUPPRIMER,
    EtatDemande.SUPPRIME
];