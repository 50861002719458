import { SIRSOperation } from "../../../models/operationSIRS/sirsOperation.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link, List, ListItem, ListItemText, Typography} from "@mui/material";

export default function OperationsSIRSDetailContactsClients(props: { operationSIRS: SIRSOperation }) {

    /**
     * Liste des contacts clients SIRS
     */
    const allContactsClientsSIRS = props.operationSIRS.sirsContactClient;

    return (
        <List sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: 1.5
        }}>
            {
                // Chaque contact (prénom/nom, téléphone, email) est listé sous forme d'item flexible
                allContactsClientsSIRS.map((contact) => (
                    <ListItem
                        key={`op-sirs-${props.operationSIRS.id}-contact-${contact.id}`}     // ex. op-sirs-1-contact-9
                        sx={{
                            paddingX: 1,
                            border: '1px solid rgba(0,0,0,0.075)',
                            borderRadius: 1,
                            transition: 'background-color 0.2s ease-out',
                            '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.035)'
                            }
                        }}
                    >
                        <ListItemText sx={{
                            '& .MuiTypography-root': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }
                        }}>
                            <Typography>
                                <FontAwesomeIcon icon={"user"} size={"sm"} width={30} color={"var(--cobalt-lynch)"}/>
                                {contact.contactClient}
                            </Typography>
                            <Typography marginY={"8px !important"}>
                                <FontAwesomeIcon icon={"envelope"} size={"sm"} width={30}  color={"var(--cobalt-lynch)"}/>
                                <Link href={`mailto:${contact.mail}`}>{contact.mail}</Link>
                            </Typography>
                            <Typography>
                                <FontAwesomeIcon icon={"phone-alt"} size={"sm"} width={30}  color={"var(--cobalt-lynch)"}/>
                                {contact.telephone}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))
            }
        </List>
    );

}