import React, { useState } from "react";
import { FilterValue, useAsyncDebounce } from "react-table";
import {Box, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";

/**
 * Définition du type des props du composant GlobalFilter
 */
type GlobalFilterProps = {
    id?: string;
    globalFilter?: string;
    setGlobalFilter: (filterValue: FilterValue) => void;
}


export default function GlobalFilter({ id, globalFilter, setGlobalFilter }: Readonly<GlobalFilterProps>) {

    const [value, setValue] = useState(globalFilter);
    const handleOnChangeValue = useAsyncDebounce(
        value => setGlobalFilter(value || undefined),
        200
    );

    return (
        <Box id={id} display={"flex"} alignItems={"center"} gap={2}>
            <Typography noWrap={true}>Recherche globale :</Typography>
            <TextField
                name="text-input-global-filter"
                value={value || ""}
                onChange={e => {
                    // Met à jour le state local qui stocke la value passé en props à l'input
                    setValue(e.target.value);
                    // Appel la callback qui met à jour le state de la table avec un délai de 200ms
                    handleOnChangeValue(e.target.value);
                }}
                placeholder={'Rechercher'}
            />
        </Box>
    );
}