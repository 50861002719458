import {IconButton, SxProps, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import VoxConditionalWrapper from "../../layouts/wrapper/voxConditionalWrapper";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type VoxIconActionPropsType = {
    tooltip?: string,
    color?: string,
    icon: IconProp,
    onClick: () => void,
    disabled?: boolean,
    sx?: SxProps
}

/**
 * Composant permettant d'afficher un bouton icône avec ou sans tooltip au hover.
 * Notamment utilisé dans les colonnes "Actions à effectuer" des tableaux.
 */
export default function VoxIconAction(props: VoxIconActionPropsType) {

    return(
        <VoxConditionalWrapper condition={Boolean(props.tooltip)} wrapper={children => (
            <Tooltip
                arrow
                placement={"top"}
                title={props.tooltip}
            >
                {children}
            </Tooltip>
        )}>
            <IconButton
                sx={{color: props.color ? props.color : 'cobalt.click', ...props.sx}}
                onClick={props.onClick}
                disabled={props.disabled}
            >
                <FontAwesomeIcon icon={props.icon} />
            </IconButton>
        </VoxConditionalWrapper>
    )
}