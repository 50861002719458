import React, { useState } from 'react';
import { Steps } from "intro.js-react";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './IntroJs.css';
import { allSteps } from "../../models/tutorial/allSteps.model";
import VoxButton from "../genericComponentsUI/button/voxButton";


/**
 * Librairie : intro.js-react, permet de créer des tutoriels intégrés
 * Repo Github : https://github.com/HiDeoo/intro.js-react#readme (wrapper React)
 * Docs Intro.js : https://introjs.com/docs (librairie initiale)
 */


interface IntroJsProps {
    // Etapes du tutoriels
    steps: allSteps[],
    // Callback appelée avant de changer l'étape courante
    onBeforeChange?: any,
    // Texte du bouton "Précédent" (def: "Back")
    prevLabel?: string,
    // Texte du bouton "Suivant" (def: "Next")
    nextLabel?: string,
    // Texte du bouton "Terminer" (def: "Done")
    doneLabel?: string,
    // Affiche des puces de progression (def: true)
    showBullets?: boolean,
    // Affiche une barre de progression (def: false)
    showProgress?: boolean,
    // Ferme le tutoriel si clic à côté (def: true)
    exitOnOverlayClick?: boolean,
    // Désactive l'interaction avec les éléments mis en surbrillance (def: false)
    disableInteraction?: boolean,
    // Click sur le premier élément du tuto, avant le démarrage, afin d'éviter des focus "dans le vide".
    // Utile en cas de tuto sur des onglets, par exemple sur la page de détail d'une opération.
    // Fournir l'Id de l'élément à cliquer.
    selectStartElement?: string

    // Plus d'options : https://introjs.com/docs/intro/options
}

const IntroJs = ({
                     steps = [],
                     onBeforeChange,
                     prevLabel = 'Précédent',
                     nextLabel = 'Suivant',
                     doneLabel = 'Terminer',
                     showBullets = false,
                     showProgress = true,
                     exitOnOverlayClick = false,
                     disableInteraction = false,
                    selectStartElement = undefined
                 }: IntroJsProps) => {


    // State
    const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);


    // Lancer le tutoriel manuellement
    const startTuto = () => {
        setStepsEnabled(true);
    };

    // Fermeture du tutoriel
    const onExit = () => {
        setStepsEnabled(false);
    };

    // Titre dynamique pour chaque étape du tutoriel (ex. Étape 3 sur 5)
    steps.forEach((step, index) => {
        step.title = `Étape ${index + 1} sur ${steps.length}`;
    });


    return (
        <>
            <Steps enabled={stepsEnabled}
                   steps={steps}
                   initialStep={0}
                   onBeforeChange={onBeforeChange}
                   onExit={onExit}
                   options={{
                       prevLabel: prevLabel,
                       nextLabel: nextLabel,
                       doneLabel: doneLabel,
                       showBullets: showBullets,
                       showProgress: showProgress,
                       exitOnOverlayClick: exitOnOverlayClick,
                       disableInteraction: disableInteraction,
                   }}
            />

            {/* Lancer manuellement le tutoriel */}
            <VoxButton
                className={"tuto-btn"}
                onClick={() => {
                    if(selectStartElement) document.querySelector<HTMLElement>(`${selectStartElement}`)?.click();
                    startTuto();
                }}
            >
                <FontAwesomeIcon icon={"question"}/>
                <span>Rejouer le tutoriel</span>
            </VoxButton>
        </>
    );

}

export default IntroJs;