import React from "react";
import {Grid, Typography} from "@mui/material";
import VoxModal from "./voxModal";
import {EtatDemande} from "../../models/operation/etatDemande.enum";

type propsSuppressionEnv = {
    etatDemande: EtatDemande
    url: string
    show: boolean
    close: () => void
    submit: () => void
}

export default function ModaleConfirmationSuppressionEnvironnement(props: propsSuppressionEnv) {

    const suppressionMessage = (props.etatDemande === EtatDemande.DECLARE ?
            "Souhaitez-vous vraiment supprimer "
            : "Souhaitez-vous demander la suppression de ")
            + `l'environnement ${props.url} ?`

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.submit}
            onClose={props.close}
            title={"Suppression d'un environnement"}
            startIconCloseButton={"ban"}
            startIconSubmitButton={"check"}
            closeButtonText={"Annuler"}
            submitButtonText={"Confirmer"}
            content={
                <Grid item xs={12}>
                    <Typography>{suppressionMessage}</Typography>
                </Grid>
            }
        />
    )
}