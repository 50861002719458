import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import EnvironnementsAllListe from "./environnementsAllListe";

export default function EnvironnementsAllView() {
    return (
        <>
            <VoxHeader title={"Environnements hors production"}/>
            <EnvironnementsAllListe/>
        </>
    )
}