import OperationsCreation from "../../components/forms/operationsFormulaires/operationsCreation";
import React from "react";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import IntroJsDeclarerOperationPackagee from "../../components/tutorial/IntroJsDeclarerOperationPackagee";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsMailevaCreationView() {

    return (
        <>
            <VoxHeader title={"Nouvelle opération MailEva"}
                       isBoutonRetour={true} lienRetour={"/maileva/mes_operations"}
                       boutonTutoriels={<IntroJsDeclarerOperationPackagee />}
            />
            <OperationsCreation typeOperation={TypeOperation.MAILEVA}/>
        </>
    );
}