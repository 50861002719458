import IntroJS from './IntroJs';
import {allSteps} from "../../models/tutorial/allSteps.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function IntroJsMesOperationsPackagees() {

    // Contenu des étapes - Pages "Mes opérations" pour Saturne & MailEva
    const steps: allSteps[] = [
        {
            // 1. Bouton "Nouvelle opération Saturne / Nouvelle opération Maileva"
            element: "#btn-nouvelle-operation",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Ce bouton vous permet d'accéder à un formulaire permettant de déclarer une nouvelle opération Packagée.</p>
                    <p>Les opérations Saturne et Maileva sont des opérations qui se déroulent de la même manière. La différence se situe au niveau du canal de vente, Saturne est une vente directe, MailEva une vente indirecte (commerciaux Maileva).</p>
                </>
            )
        },
        {
            // 2. Tableau des opérations
            element: "#table-mes-operations",
            position: "bottom-middle-aligned",
            intro: <p>Après validation du formulaire précédent, l'opération apparaîtra dans ce tableau.</p>
        },
        {
            // 3. Colonne 1 = Nom du client
            element: "#col-nomClient",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Dans cette 1<sup>e</sup> colonne, vous retrouverez le nom de votre client, par exemple :</p>
                    <p className='introjs-cell'>VOXALY</p>
                </>
            )

        },
        {
            // 4. Colonne 2 = Site de vote
            element: "#col-url",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Dans cette 2<sup>e</sup> colonne, vous retrouverez l'URL d'accès au site de vote de votre client, par exemple :</p>
                    <p className='introjs-cell'>
                        <span className={"link-style"}>https://voxaly.voxintime.com</span>
                    </p>
                </>
            )
        },
        {
            // 5. Colonne 3 = État d'installation
            element: "#col-etatDemande",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Dans cette 3<sup>e</sup> colonne, vous pourrez visualiser l'état d'installation de vos opérations. Juste après avoir déclaré une nouvelle opération, vous pourrez par exemple lancer l'option suivante :</p>
                    <p className='introjs-cell link-style'>
                        <FontAwesomeIcon icon={"cogs"} />
                        <span>Installer maintenant (OVH)</span>
                    </p>
                </>
            )
        },
        {
            // 6. Colonne 4 = Actions à effectuer
            element: "#col-actions",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Enfin, dans cette 4<sup>e</sup> colonne, vous pourrez effectuer jusqu'à 3 actions sur chacune de vos opérations :</p>
                    <p className={"introjs-cell"} style={{color: '#001EAE'}}>
                        <FontAwesomeIcon icon={"eye"} />
                        <FontAwesomeIcon icon={"edit"} />
                        <FontAwesomeIcon icon={"trash-alt"} />
                    </p>
                    <p>
                        <FontAwesomeIcon icon={"eye"} />
                        Affiche le détail de l'opération
                    </p>
                    <p className={"margin-0"}>
                        <FontAwesomeIcon icon={"edit"} />
                        Modifie l'opération (si état "déclaré")
                    </p>
                    <p className={"margin-0"}>
                        <FontAwesomeIcon icon={"trash-alt"} />
                        Déclenche la suppression de la VM de l'opération
                    </p>
                </>
            )
        },
    ];

    return (

        <IntroJS
            steps={steps}
        />

    );

}