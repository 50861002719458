import React, { useCallback, useState } from "react";
import {Box, Card, CardContent, Checkbox, Collapse, FormControlLabel, Grid, Typography} from "@mui/material";
import { FiltresAlertesSIRS } from "../../models/operationSIRS/operationSIRSProduction/filtresAlertesSIRS.model";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PropsType = {
    filtresAlertes: FiltresAlertesSIRS;
    reinitialiserFiltres: () => void;
    filtrerOperationsSIRSEnProduction: (filtresAlertes: FiltresAlertesSIRS) => void;
}

export default function FiltresAffichageOperationsSIRS(props: PropsType) {

    const [isFiltresSIRSExpanded, setIsFiltresSIRSExpanded] = useState<boolean>(false);

    const [filtresAlertes, setFiltresAlertes] = useState<FiltresAlertesSIRS>({
        siteKO: props.filtresAlertes.siteKO
    });

    /**
     * Se déclenche lorsque le formulaire est submit, et transmets au composant parent les valeurs des filtres
     */
    const handleOnApplyFilters = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.filtrerOperationsSIRSEnProduction(filtresAlertes);
    }, [filtresAlertes, props]);

    /**
     * Appel de cette méthode lors du clic sur la checkbox, afin de mettre à jour la valeur des filtres sur les alertes
     * @param event
     */
    const handleOnChangeFiltreAlerte = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFiltresAlertes((prevState: FiltresAlertesSIRS) => ({
            ...prevState, [event.target.id]: event.target.checked
        }));
    }, []);

    return (
        <>
            <VoxButton
                id={"collapse-filtres-sirs-btn"}
                variant={"outlined"}
                startIcon={<FontAwesomeIcon icon={"filter"}/>}
                endIcon={<FontAwesomeIcon icon={"caret-down"}/>}
                aria-controls={"collapseFiltresSIRS"}
                aria-expanded={isFiltresSIRSExpanded}
                onClick={() => setIsFiltresSIRSExpanded(!isFiltresSIRSExpanded)}
            >
                Filtres d'affichage
            </VoxButton>
            <Collapse in={isFiltresSIRSExpanded} sx={{width: 'fit-content'}}>
                <Card id={"collapseFiltresSIRS"}
                      variant={"outlined"}
                      sx={{mt: 1, borderRadius: "24px", borderColor: "#0000FF"}}
                >
                    <CardContent>
                        <Box component={"form"} onSubmit={handleOnApplyFilters}>
                            <Grid container rowGap={3}>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={"h3"} marginY={0.8}>Alerte</Typography>
                                    <div>
                                        <FormControlLabel
                                            sx={{ml: 0}}
                                            control={<Checkbox id={"siteKO"} onChange={handleOnChangeFiltreAlerte}/>}
                                            label={"Site KO"}
                                            checked={filtresAlertes.siteKO}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Box sx={{mt: 3, display: "flex", justifyContent: "space-evenly", gap: 2}}>
                                <VoxButton
                                    variant={"outlined"}
                                    onClick={props.reinitialiserFiltres}
                                    sx={{paddingX: 3}}
                                >
                                    Réinitialiser les filtres
                                </VoxButton>
                                <VoxButton
                                    customColor={"green"}
                                    startIcon={<FontAwesomeIcon icon={"filter"}/>}
                                    type={"submit"}
                                    sx={{paddingX: 3}}
                                >
                                    Appliquer les filtres
                                </VoxButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Collapse>
        </>
    );
}