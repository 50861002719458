import React, {useEffect, useRef, useState} from "react";
import "../../../components/genericComponentsUI/button/voxButton.css";
import {useNavigate, useParams} from "react-router-dom";
import portefeuilleProjetService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjet.service";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import {PortefeuilleProjet} from "../../../models/portefeuilleProjet/portefeuilleProjet.model";
import {PortefeuilleProjetDto} from "../../../services/survox-back/portefeuilleProjet/dtos/portefeuilleProjet.dto";
import {EmailPortefeuilleProjet} from "../../../models/portefeuilleProjet/EmailPortefeuilleProjet.model";
import {Grid} from "@mui/material";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import PortefeuillesProjetsForm from "./portefeuillesProjetsForm";


export default function PortefeuillesProjetsModification() {

    const params = useParams();
    const navigate = useNavigate();

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // States
    const [portefeuilleProjet, setPortefeuilleProjet] = useState<Partial<PortefeuilleProjet>>({nom: "", emails: ""});
    const [isLoadingPortefeuilleProjet, setIsLoadingPortefeuilleProjet] = useState<boolean>(false);
    const [isLoadingPortefeuilleProjetError, setIsLoadingPortefeuilleProjetError] = useState<boolean>(false);
    const [isUpdatePortefeuilleProjet, setIsUpdatePortefeuilleProjet] = useState<boolean>(false);
    const [isUpdateError, setIsUpdateError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>("");

    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        if (params.id) {
            chargerPortefeuilleProjet(Number(params.id), controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [params.id]);

    /**
     * Méthode qui permet de charger le portefeuille projet sélectionné (portefeuille projet que l'on souhaite modifier)
     * @param id ID du portefeuille projet
     * @param controller
     */
    const chargerPortefeuilleProjet = (id: number, controller: AbortController) => {
        setIsLoadingPortefeuilleProjet(true);
        setIsLoadingPortefeuilleProjetError(false);
        portefeuilleProjetService.getPortefeuilleProjetById(id, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // récupération du portefeuille projet
                    setPortefeuilleProjet(response.data);
                    setIsLoadingPortefeuilleProjet(false);
                    setIsLoadingPortefeuilleProjetError(false);
                }
            })
            .catch((err) => {
                if (err.message !== "canceled" && isMountedRef.current) {
                    setIsLoadingPortefeuilleProjetError(true);
                    setIsLoadingPortefeuilleProjet(false);
                    if (err.response.status === 404 && (err.response.data.message).includes("Le portefeuille projet avec l'id")) {
                        setMessageError(err.response.data.message);     // Le portefeuille projet avec l'id ... n'existe pas
                    } else {
                        setMessageError("Une erreur est survenue lors du chargement du portefeuille projet");
                    }
                }
            });
    };

    /**
     * Gère l'événement de l'envoi du formulaire et déclenche la création du portefeuille projet
     */
    const handleOnUpdate = (nomPortefeuilleProjet: string, tableauEmails: EmailPortefeuilleProjet[]) => {
        if (nomPortefeuilleProjet && nomPortefeuilleProjet !== "") {
            onUpdatePortefeuilleProjet(Number(params.id), {
                nom: nomPortefeuilleProjet,
                tableauEmails: tableauEmails.filter((objEmail: EmailPortefeuilleProjet) => objEmail.email !== "").map((objEmail: EmailPortefeuilleProjet) => objEmail.email)
            });
        }
    }

    /**
     * Mise à jour du portefeuille projet
     */
    const onUpdatePortefeuilleProjet = (id: number, portefeuilleProjet: PortefeuilleProjetDto) => {
        setIsUpdatePortefeuilleProjet(true);
        setIsUpdateError(false);
        portefeuilleProjetService.updatePortefeuilleProjet(id, portefeuilleProjet)
            .then((response) => {
                if (response.status === 200 && !response.data.error) {
                    setIsUpdatePortefeuilleProjet(false);
                    setIsUpdateError(false);
                    navigate("/administration/portefeuilles_projets");
                }
            })
            .catch((err) => {
                setIsUpdatePortefeuilleProjet(false);
                setIsUpdateError(true);
                if (err.response.status === 403) {
                    setMessageError(err.response.data.message);     // Il est interdit de modifier ce portefeuille projet (cas du portefeuille 'Anonyme')
                } else if (err.response.status === 404 && (err.response.data.message).includes("Le portefeuille projet avec l'id")) {
                    setMessageError(err.response.data.message);     // Le portefeuille projet avec l'id ... n'existe pas
                } else if (err.response.status === 409) {
                    setMessageError(err.response.data.message);     // Un portefeuille projet avec le nom ... existe déjà.
                } else {
                    setMessageError("Une erreur est survenue lors de la mise à jour du nom du portefeuille projet");
                }
            })
    }


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={8} gap={2}>
                <VoxCard title={"Modification du portefeuille projet"}>

                    {/* Chargement du formulaire */}
                    {isLoadingPortefeuilleProjet &&
                        <VoxLoader/>
                    }

                    {portefeuilleProjet.nom === "Anonyme" &&
                        <VoxAlert severity={"error"}>
                            Il est interdit de modifier le portefeuille projet "Anonyme".
                        </VoxAlert>
                    }

                    {/* Affichage du formulaire (si aucune erreur) */}
                    {(
                        (portefeuilleProjet.nom && portefeuilleProjet.nom !== "Anonyme") &&
                        (portefeuilleProjet.emails || portefeuilleProjet.emails === "" || portefeuilleProjet.emails === null) &&
                        !isLoadingPortefeuilleProjet && !isLoadingPortefeuilleProjetError
                    ) && (
                        <PortefeuillesProjetsForm
                            nomPortefeuille={portefeuilleProjet.nom}
                            emailsPortefeuille={portefeuilleProjet.emails}
                            onSubmit={handleOnUpdate}
                            isLoadingOnSubmit={isUpdatePortefeuilleProjet}
                            isError={isUpdateError}
                            messageError={messageError}
                        />
                    )}

                    {isLoadingPortefeuilleProjetError &&
                        <VoxAlert severity={"error"} sx={{marginTop: 0}}>
                            {messageError}
                        </VoxAlert>
                    }

                    {/* Chargement du formulaire */}
                    {isUpdatePortefeuilleProjet &&
                        <VoxLoader isBackgroundColor={true}/>
                    }
                </VoxCard>
            </Grid>
        </Grid>
    )
}
