import {TypeProduit} from "../../../models/versionApplicative/typeProduit.enum";
import axios_instance from "../axios-client";
import {TypeLivrable} from "../../../models/versionApplicative/typeLivrable.enum";
import {UpdateComposantDto} from "./dtos/request/updateComposant.dto";

const composantService = {
    getComposantsArchivables: (typeProduit: TypeProduit) => {
        return axios_instance.get("composant/composants_archivables", {params: {typeProduit}})
    },

    getAllComposantByTypeProduit: (typeProduit: TypeProduit, signal?: AbortSignal) => {
        return axios_instance.get("/composant/composants", { params: { typeProduit, typesLivrables: Object.values(TypeLivrable) }, signal });
    },

    updateComposants: (updateComposantsDto: UpdateComposantDto[]) => {
        return axios_instance.put("/composant/maj_composants", updateComposantsDto);
    },

    archiverComposants: (typeProduit: TypeProduit) => {
        return axios_instance.put("/composant/archiver", {typeProduit});
    }
}

export default composantService;