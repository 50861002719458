import React, {FormEvent, useState} from "react";
import AuditsListe from "./auditsListe";
import dayjs from "dayjs";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import {VoxCalendarInput} from "../../../components/genericComponentsUI/input/voxCalendarInput";
import Box from "@mui/material/Box";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";

type DateRange = {
    startDate: Date;
    endDate: Date;
}

const inputWidthCssStyle = {
    width: 240
}

export default function AuditsView() {

    // Dates affichées par défaut au chargement de la page
    const startDateDefault = dayjs().subtract(1, "day").startOf('day').format("YYYY-MM-DDTHH:mm");
    const endDateDefault = dayjs().format("YYYY-MM-DDTHH:mm");

    // States
    const [startDateAudits, setStartDateAudits] = useState<string>(startDateDefault);
    const [endDateAudits, setEndDateAudits] = useState<string>(endDateDefault);

    // Gestion du Date Range
    const [auditsDateRange, setAuditsDateRange] = useState<DateRange>({
        startDate: dayjs().subtract(1, "day").toDate(),
        endDate: dayjs().toDate()
    });

    function handleOnChangeStartDate(id: string, date: Date | undefined) {
        setStartDateAudits(dayjs(date).format('YYYY-MM-DDTHH:mm'));
    }

    function handleOnChangeEndDate(id: string, date: Date | undefined) {
        setEndDateAudits(dayjs(date).format('YYYY-MM-DDTHH:mm'));
    }

    function handleOnSubmit(event: FormEvent) {
        event.preventDefault();
        setAuditsDateRange({
            startDate: startDateAudits !== undefined ? dayjs(startDateAudits).toDate() : dayjs("2020-01-01").toDate(),
            endDate: endDateAudits !== undefined ? dayjs(endDateAudits).toDate() : dayjs("2099-12-31").toDate()
        })
    }

    return (
        <>
            <VoxHeader title={"Liste des audits"}/>

            <Box component="form" onSubmit={handleOnSubmit}
                 sx={{
                     display: 'flex',
                     flexDirection: {xs: 'column', sm: 'row'},
                     justifyContent: {xs: 'flex-end', sm: 'center'},
                     alignItems: {xs: 'center', sm: 'flex-end'},
                     gap: 4,
                     marginTop: 8,
                     marginBottom: 4,
                     '& .MuiGrid-root': {
                         width: 'initial'
                     }
                 }}
            >

                {/* DÉBUT */}
                <VoxCalendarInput
                    id={"startDate"}
                    label={"Date de début"}
                    value={new Date(dayjs(startDateAudits).format('YYYY-MM-DDTHH:mm'))}
                    onChange={handleOnChangeStartDate}
                    maxDate={endDateAudits ? new Date(dayjs(endDateAudits).subtract(1, "day").format("YYYY-MM-DDTHH:mm")) : undefined}
                    dateTimeLocalType
                    sx={inputWidthCssStyle}
                />

                {/* FIN */}
                <VoxCalendarInput
                    id={"endDate"}
                    label={"Date de fin"}
                    value={new Date(dayjs(endDateAudits).format('YYYY-MM-DDTHH:mm'))}
                    onChange={handleOnChangeEndDate}
                    minDate={startDateAudits ? new Date(dayjs(startDateAudits).add(1, "day").format("YYYY-MM-DDTHH:mm")) : undefined}
                    dateTimeLocalType
                    sx={inputWidthCssStyle}
                />

                <Box display={"flex"} gap={2} marginBottom={"1px"}>

                    {/* BOUTON "RÉINITIALISER" */}
                    <VoxButton type={"submit"}
                               variant={"outlined"}
                               onClick={() => {
                                   setStartDateAudits(startDateDefault);
                                   setEndDateAudits(endDateDefault);
                               }}
                    >
                        Réinitialiser
                    </VoxButton>

                    {/* BOUTON "FILTRER" */}
                    <VoxButton type={"submit"}>Filtrer</VoxButton>

                </Box>

            </Box>

            <AuditsListe auditsDateRange={auditsDateRange}/>
        </>
    )
}