export enum TypeEnvironnement {
    DEMO = "demo",
    RECETTE_CLIENT = "recette-client",
    RECETTE_INTERNE = "recette-interne",
    PREPROD = "preprod"
}

export const TypeEnvironnements: TypeEnvironnement[] = [
    TypeEnvironnement.DEMO,
    TypeEnvironnement.PREPROD,
    TypeEnvironnement.RECETTE_CLIENT,
    TypeEnvironnement.RECETTE_INTERNE
]