import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, CellProps, Column } from "react-table";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import portefeuilleProjetService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjet.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ModaleConfirmationSuppressionPortefeuilleProjet from "../../../components/modals/modaleConfirmationSuppressionPortefeuilleProjet";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import {Box} from "@mui/material";
import VoxIconAction from "../../../components/genericComponentsUI/pictogrammes/voxIconAction";

type PortefeuillesProjetType = {
    id: number,
    nom: string,
    emails: string,
    nboperations: string
}
type TableauPortefeuillesProjets = Partial<PortefeuillesProjetType> & { actions: string };


export default function PortefeuillesProjetsListe() {

    const [portefeuillesProjets, setPortefeuillesProjets] = useState<PortefeuillesProjetType[]>([]);
    const [isLoadingPortefeuillesProjets, setIsLoadingPortefeuillesProjets] = useState(false);
    const [isLoadingPortefeuillesProjetsError, setIsLoadingPortefeuillesProjetsError] = useState(false);

    const [selectedPortefeuilleProjet, setSelectedPortefeuilleProjet] = useState<TableauPortefeuillesProjets | undefined>(undefined);
    const [showModaleSuppressionPortefeuilleProjet, setShowModaleSuppressionPortefeuilleProjet] = useState<boolean>(false);
    const [isLoadingSuppressionPortefeuilleProjet, setIsLoadingSuppressionPortefeuilleProjet] = useState<boolean>(false);
    const [succesSuppressionPortefeuilleProjet, setSuccesSuppressionPortefeuilleProjet] = useState<boolean>(false);
    const [isErrorSuppressionPortefeuilleProjet, setIsErrorSuppressionPortefeuilleProjet] = useState<boolean>(false);
    const [messageErreurSuppression, setMessageErreurSuppression] = useState<string>("");

    const navigate = useNavigate();

    /**
     * Chargement des portefeuilles projets
     */
    const chargerPortefeuillesProjets = (controller?: AbortController) => {
        setIsLoadingPortefeuillesProjets(true);
        setIsLoadingPortefeuillesProjetsError(false);
        portefeuilleProjetService.getAllPortefeuillesProjetsAvecNbOperations(controller?.signal)
            .then(response => {
                if (response.status === 200) {
                    setPortefeuillesProjets(response.data);
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(true);
                }
            });
    }

    useEffect(() => {
        const controller = new AbortController();
        chargerPortefeuillesProjets(controller);
        return () => controller.abort();
    }, []);


    /**
     * Remplissage des lignes du tableau
     */
    const rows: any[] = useMemo(() => {
        return portefeuillesProjets.map(
            (portefeuille: PortefeuillesProjetType) => ({
                ...portefeuille,
                emails: (portefeuille.emails === null) ? [] : portefeuille.emails.split(";")
            })
        );
    }, [portefeuillesProjets]);


    /**
     * Se déclenche lorsque l'utilisateur clique sur le pictogramme de suppression d'un portefeuille projet
     * et ouvre la modale de confirmation
     */
    const handlePortefeuilleProjetSuppression = useCallback((portefeuilleProjet: TableauPortefeuillesProjets) => {
        setShowModaleSuppressionPortefeuilleProjet(true);
        setSelectedPortefeuilleProjet(portefeuilleProjet);
    }, []);

    /**
     * Méthode permettant de supprimer un portefeuille projet
     */
    const suppressionPortefeuilleProjet = () => {
        if (selectedPortefeuilleProjet?.id) {
            setIsLoadingSuppressionPortefeuilleProjet(true);
            setSuccesSuppressionPortefeuilleProjet(false);
            setIsErrorSuppressionPortefeuilleProjet(false);
            portefeuilleProjetService.deletePortefeuilleProjet(selectedPortefeuilleProjet.id)
                .then((response) => {
                    if (response.status === 200 && !response.data.error) {
                        setSuccesSuppressionPortefeuilleProjet(true);
                    } else {
                        setIsErrorSuppressionPortefeuilleProjet(true);
                    }
                })
                .catch((err) => {
                    setSuccesSuppressionPortefeuilleProjet(false);
                    setIsErrorSuppressionPortefeuilleProjet(true);
                    if (err.response.status === 403) {
                        setMessageErreurSuppression(err.response.data.message);   // Il est interdit de supprimer ce portefeuille projet (cas du portefeuille projet "Anonyme")
                    }
                })
                .finally(() => {
                    setIsLoadingSuppressionPortefeuilleProjet(false);
                    setShowModaleSuppressionPortefeuilleProjet(false);
                    setSelectedPortefeuilleProjet(undefined);
                    chargerPortefeuillesProjets();
                })
        }
    }


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<TableauPortefeuillesProjets>[] = useMemo(() => [
        {
            Header: 'Responsable du portefeuille projet',
            accessor: 'nom',
            minWidth: 180,
        },
        {
            Header: 'E-mails des responsables du portefeuille projet',
            accessor: "emails",
            minWidth: 200,
            Cell: (props: CellProps<TableauPortefeuillesProjets>) => {
                return (
                    // Chaque e-mail est retourné dans un <p> de la cellule du tableau
                    props.value.map((email: string) => <p key={`portefeuille${props.row.original.id}-${email}`} >{email}</p>)
                )
            }
        },
        {
            Header: "Nombre d'opérations",
            accessor: 'nboperations',
            minWidth: 140,
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            align: "center",
            flexGrow: 0.5,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: CellProps<TableauPortefeuillesProjets>) => {
                return props.row.original.nom !== "Anonyme" ? (
                    <Box display={"flex"} justifyContent={"center"} gap={1}>

                        {/* Crayon → Modification du portefeuille */}
                        <VoxIconAction
                            tooltip={"Modifier le portefeuille"}
                            icon={"edit"}
                            onClick={() => navigate(`modification/${props.row.original.id}`)}
                        />

                        {/* Corbeille → Suppression du portefeuille */}
                        <VoxIconAction
                            tooltip={"Supprimer le portefeuille"}
                            icon={"trash-alt"}
                            onClick={() => handlePortefeuilleProjetSuppression(props.row.original)}
                        />

                    </Box>
                ) : null
            },
            minWidth: 50,
            maxWidth: 70,
        },
    ], [navigate, handlePortefeuilleProjetSuppression]
    );


    /**
     * Le message d'alerte à afficher pour confirmer la suppression ou informer l'utilisateur qu'il y a eu une erreur
     */
    const messageAlerteSuppression = useMemo(() => {
        if (succesSuppressionPortefeuilleProjet) {
            return (
                <VoxAlert severity={"success"} onClose={() => setSuccesSuppressionPortefeuilleProjet(false)}>
                    Le portefeuille projet a été supprimé.
                </VoxAlert>
            )
        } else if (isErrorSuppressionPortefeuilleProjet) {
            return (
                <VoxAlert severity={"error"} onClose={() => setIsErrorSuppressionPortefeuilleProjet(false)}>
                    {(messageErreurSuppression !== "")
                        ? messageErreurSuppression
                        : "Une erreur est survenue lors de la suppression du portefeuille projet."
                    }
                </VoxAlert>
            )
        }
    }, [succesSuppressionPortefeuilleProjet, isErrorSuppressionPortefeuilleProjet, messageErreurSuppression]);


    return (
        <>
            {messageAlerteSuppression}
            {(isLoadingPortefeuillesProjetsError) ?
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des portefeuilles projets.
                </VoxAlert>
                : null
            }
            {(isLoadingPortefeuillesProjets) ?
                <VoxLoader />
                :
                <>
                    <VoxButton
                        startIcon={<FontAwesomeIcon icon={"plus"} />}
                        onClick={() => navigate(`creation`)}
                    >
                        Nouveau portefeuille projet
                    </VoxButton>
                    <VoxAlert severity={"info"} sx={{ marginTop: 3 }}>
                        Le nombre d'opérations ne prend pas en compte les opérations passées.
                    </VoxAlert>
                    <VoxTable
                        data={rows}
                        columns={columns}
                        rowProps={() => {
                            return {
                                style: {
                                    height: "50px",
                                    verticalAlign: "middle"
                                }
                            }
                        }}
                        cellProps={(cell: Cell<PortefeuillesProjetType>) => {
                            let props = {}
                            if (["nboperations", "actions"].includes(cell.column.id)) {
                                props = {
                                    ...props,
                                    style: { textAlign: "center" }
                                }
                            }
                            return props;
                        }}
                    />
                </>
            }

            <ModaleConfirmationSuppressionPortefeuilleProjet
                show={showModaleSuppressionPortefeuilleProjet}
                nbOperationsPortefeuilleProjet={Number(selectedPortefeuilleProjet?.nboperations)}
                handleClose={() => {
                    setShowModaleSuppressionPortefeuilleProjet(false);
                }}
                handleSubmit={suppressionPortefeuilleProjet}
                isLoadingSuppression={isLoadingSuppressionPortefeuilleProjet}
            />
        </>
    )
}