export enum NomGroupe {
    ADMINISTRATEUR = "Administrateur",
    SUPPORT_SI_INFRA = "Support SI Infra",
    SUPPORT_SI_DEV = "Support SI Dev",
    SUPPORT_SI_DEV_SIRS = "Support SI Dev SIRS",
    CHEF_DE_PROJET_PACKAGE = "Chef de projet Packagé",
    RESPONSABLE_PROJETS_VOTE = "Responsable de projets Vote",
    CHEF_DE_PROJET_VOTE = "Chef de projet Vote",
    CHEF_DE_PROJET_SIRS = "Chef de projet SIRS",
    OBSERVATEUR = "Observateur",
    GESTIONNAIRE_DE_COMPTE = "Gestionnaire de compte"
}

export const GroupesEnvironnement: NomGroupe[] = [
    NomGroupe.ADMINISTRATEUR,
    NomGroupe.SUPPORT_SI_INFRA,
    NomGroupe.SUPPORT_SI_DEV,
    NomGroupe.CHEF_DE_PROJET_PACKAGE,
    NomGroupe.RESPONSABLE_PROJETS_VOTE,
    NomGroupe.CHEF_DE_PROJET_VOTE
]