import {Operation} from "../operation/operation.model";
import {getResultatSurveillanceByValue, ResultatSurveillance} from "./resultatSurveillance.enum";

export type Surveillance = {
    id: number
    operation: Operation
    checkTime: Date
    erreur: string
    codeHttp: number
    tempsDeReponse: number
    typeSurveillance: string
    resultatSurveillance: ResultatSurveillance | string
}


/**
 * Retourne le résultat du test des scellements
 * @return
 */
export function getResultatSurveillanceScellement(resultatSurveillance: string) : ResultatSurveillance | null {
    if (resultatSurveillance !== null) {
        const resultats: string[] = resultatSurveillance.split("|");
        const scellement: string = resultats[0];

        return getResultatSurveillanceByValue(scellement);
    }
    else {
        return null;
    }
}

/**
 * Retourne le résultat du test des demandes électeurs
 * @return
 */
export function getResultatSurveillanceDemandeElecteur(resultatSurveillance: string) : ResultatSurveillance | null {
    let resultatSurveillanceDemandeElecteur: ResultatSurveillance | null = null;

    if (resultatSurveillance !== null) {
        const resultats: string[]  = resultatSurveillance.split("|");

        if (resultats.length > 1){
            const demandeElecteur: string = resultats[1];
            resultatSurveillanceDemandeElecteur = getResultatSurveillanceByValue(demandeElecteur);
        }
    }
    return resultatSurveillanceDemandeElecteur;
}