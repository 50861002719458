import {Navigate, Route, Routes} from "react-router-dom";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import React from "react";
import OperationsSIRSModification from "../../components/forms/operationsSIRSFormulaires/operationsSIRSModification";

export default function OperationsSIRSModificationView() {

    return (
        <Routes>
            <Route path={"/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true}/>
                    <OperationsSIRSModification/>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}