import IntroJS from './IntroJs';
import {allSteps} from "../../models/tutorial/allSteps.model";
import {useContext} from "react";
import {AuthContext} from "../../contexts/authentication/authentication.context";
import {NomGroupe} from "../../models/utilisateur/nomGroupe.enum";

export default function IntroJsDetailOperation() {

    // Groupe de l'utilisateur connecté
    const authContext = useContext(AuthContext);
    const currentUserGroup = authContext.utilisateur.groupe.nom;

    // Permet d'afficher l'étape uniquement si l'utilisateur connecté fait partie des groupes autorisés
    const conditionalStep = (groupesAutorises: NomGroupe[]) => {
        // includes() retourne "true" si le groupe de l'utilisateur (currentUserGroup) fait partie des groupes autorisés
        return groupesAutorises.includes(currentUserGroup);
    }

    // Contenu des étapes - Fiche opération
    const steps: allSteps[] = [
        {
            // 1. Onglet "Paramètres généraux"
            element: "#tab-0",
            position: "bottom-right-aligned",
            intro: <p>Dans cet onglet, vous pourrez accéder aux informations principales de l'opération.</p>
        },
        {
            // 2. Bloc "Infos"
            element: "#infos-op",
            position: "bottom-right-aligned",
            intro: <p>Dans ce bloc, vous avez les informations générales de l'opération.</p>
        },
            // 3. Bloc "URLs spécifiques" (sous condition du groupe utilisateur)
            ...conditionalStep([NomGroupe.ADMINISTRATEUR, NomGroupe.SUPPORT_SI_DEV, NomGroupe.SUPPORT_SI_INFRA]) ? [{
            element: "#urls-specifiques",
            position: "bottom-right-aligned",
            intro: <p>Ici, vous pouvez consulter ou définir des URLs spécifiques d'accès. Cela est notamment utile dans
                le cadre des projets multi-serveurs afin d'indiquer les URLs d'accès directes de chaque serveur.</p>
        } as allSteps] : [],
        {
            // 4. Bloc "Commentaires"
            element: "#card-commentaires",
            position: "left",
            intro: <p>Ici, vous pouvez ajouter des commentaires à l'opération afin de partager des demandes, des actions
                particulières avec d'autres personnes.</p>
        },
        {
            // 5. Onglet "Environnements"
            element: "#tab-1",
            position: "bottom-right-aligned",
            intro: <p>Dans cet onglet, vous pourrez déclarer et modifier des environnements hors production pour cette opération.</p>
        },
        {
            // 6. Onglet "Indicateurs"
            element: "#tab-2",
            position: "bottom-right-aligned",
            intro: <p>Dans cet onglet, vous pourrez accéder à tous les indicateurs de l'opération. Cela concerne toutes
                les données métiers collectées par Survox via les web services de l'opération.</p>
        },
        {
            // 7. Onglet "Surveillance"
            element: "#tab-3",
            position: "bottom-right-aligned",
            intro: <p>Dans cet onglet, tous les appels à la supervision propre à l'opération sont tracés et datés. Cela
                peut permettre d'analyser les problèmes éventuels sur l'accès au site, et toute donnée utile.</p>
        },
        {
            // 8. Onglet "Audits"
            element: "#tab-4",
            position: "bottom-right-aligned",
            intro: <p>Dans cet onglet, vous pourrez visualiser toutes les actions menées sur l'opération, de sa création
                à sa suppression.</p>
        },

    ];

    return (

        <IntroJS
            steps={steps}
            selectStartElement={"#tab-0"}
            disableInteraction={true}
        />

    );

}