import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import EnvironnementsService from "../../services/survox-back/environnements/environnements.service";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import VoxTable from "../genericComponentsUI/table/voxTable";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { EnvironnementAInitialiser } from "../../models/environnements/environnementAInitialiser.model";
import { EnvironnementAInitialiserTableau } from "../../models/environnements/environnementAInitialiserTableau.model";
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";
import {
    affichageDateWithFormatDMYHm,
    affichageLibelleEtatDemande,
    affichageLibelleTypeEnvironnement
} from "../../utils/string.utils";
import { sortDatesInTables } from "../../utils/date.utils";
import { Typography } from "@mui/material";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import { CellProps, Column } from "react-table";
import VoxModal from "../modals/voxModal";
import { UpdateEtatEnvOperationsDto } from "../../services/survox-back/environnements/dtos/updateEtatEnvOperations.dto";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import VoxLinkInstallation from "../genericComponentsUI/link/voxLinkInstallation";


export default function EnvironnementsAInitialiser() {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");

    // Utilisation d'une ref pour connaître l'état du composant éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [environnementsAInitialiser, setEnvironnementsAInitialiser] = useState<EnvironnementAInitialiser[]>([]);
    const [showInstallationModale, setShowInstallationModale] = useState<boolean>(false);
    const [envAinstaller, setEnvAinstaller] = useState<UpdateEtatEnvOperationsDto>();

    /**
     * Charger les environnements à initialiser
     */
    const loadEnvironnements = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        setIsError(false);
        EnvironnementsService.getSomeEnvironnementsByEtatDemande(EtatDemande.DECLARE, controller?.signal)
            .then((response) => {
                if(isMountedRef.current && response.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    setEnvironnementsAInitialiser(response.data)
                }
            })
            .catch((error) => {
                if(error.message !== 'canceled') {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError("Une erreur est survenue lors du chargement des environnements")
                }
                })
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        loadEnvironnements(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [loadEnvironnements])

    /**
     * Submit du changement d'état
     */
    const handleSubmitModificationEtat = () => {
        setIsLoading(true);
        setIsError(false);
        if (envAinstaller) {
            EnvironnementsService.updateEtatEnvironnementOperation(envAinstaller)
                .then((response) => {
                    if(response.status === 200) {
                        loadEnvironnements();
                    }
                })
                .catch((error) => {
                    setIsError(true);
                    if(error.status === 400 || error.status === 404) {
                        setMessageError(error.response.data.message);
                    } else {
                        setMessageError("Une erreur est survenue lors de la mise à jour");
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                    setShowInstallationModale(false);
                })
        } else {
            setIsLoading(false);
            setIsError(true);
            setMessageError("Aucun environnement à modifier");
        }
    }

    /**
     * Liens vers les pages de détail des opérations
     */
    const linkCellOperation = (opId: number, opName: string) => (
        <VoxLinkOperation
            href={`/operation/detail/${opId}`}
        >
            {opName}
        </VoxLinkOperation>
    );

    /**
     * Liens de changement d'état
     */
    const linkCellEtatDemande = useCallback((props: CellProps<EnvironnementAInitialiserTableau>) => {
        return (
            (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) ?
                <VoxLinkInstallation
                    startIcon={"hand-point-right"}
                    onClick={() => {
                        setEnvAinstaller({id: Number(props.row.original.id), etatDemande: EtatDemande.INSTALLE})
                        setShowInstallationModale(true);
                    }}
                >
                    Passer à l'état installé
                </VoxLinkInstallation>

                : <div>{affichageLibelleEtatDemande(props.row.original.etatDemande)}</div>
        )
    }, [utilisateur.groupe.nom]);

    /**
     * Liens des urls
     */
    const linkUrlCell = useCallback((props: CellProps<EnvironnementAInitialiserTableau>) => {
        return (
            <VoxLinkUrl href={props.row.original.url}>
                {props.row.original.url}
            </VoxLinkUrl>
        )
    }, []);

    /**
     * Cellules Description
     */
    const affichageTexteAvecSautsDeLigne = useCallback((texte: string) => {
        if (texte) {
            const values: string[] = texte.split('\n');
            return <>{
                values.map((value, index) => <p key={`${value.substring(0, 5).replace(" ", "")}-${index}`}>{value}</p>)
            }</>;
        } else {
            return <></>;
        }
    }, []);

    /**
     * Lignes du Tableau
     */
    const rows: EnvironnementAInitialiserTableau[] = useMemo(() => {
        return environnementsAInitialiser.map((env) => ({
            id: env.id,
            operationId: env.operation.id,
            operationName: env.operation.nom,
            description: env.description,
            url: env.url,
            typeEnvironnement: env.typeEnvironnement,
            dateInstallation: env.dateInstallation,
            etatDemande: env.etatDemande
        }))
    }, [environnementsAInitialiser])

    /**
     * Colonnes du Tableau
     */
    const columns: Column<EnvironnementAInitialiserTableau>[] = useMemo(() => [
        {
            Header: 'Opération Liée',
            accessor: 'operationName',
            minWidth: 100,
            Cell: (props: CellProps<EnvironnementAInitialiserTableau>) => linkCellOperation(props.row.original.operationId, props.row.original.operationName)
        },
        {
            Header: 'Site',
            accessor: 'url',
            minWidth: 200,
            Cell: linkUrlCell
        },
        {
            Header: "État d'installation",
            accessor: 'etatDemande',
            minWidth: 80,
            Cell: linkCellEtatDemande
        },
        {
            Header: "Type d'environnement",
            Filter: SelectInputFilter,
            filter: 'includes',
            accessor: (originalRow => affichageLibelleTypeEnvironnement(originalRow.typeEnvironnement)),
            minWidth: 80,
            maxWidth: 80
        },
        {
            Header: 'Description',
            accessor: 'description',
            minWidth: 120,
            Cell: ((props: CellProps<EnvironnementAInitialiserTableau>) => affichageTexteAvecSautsDeLigne(props.value)),
        },
        {
            Header: "Date d'installation",
            accessor: (originalRow => affichageDateWithFormatDMYHm(originalRow.dateInstallation)),
            minWidth: 100,
            maxWidth: 120,
            sortType: (a, b, columnId, desc) =>
                sortDatesInTables(a.original.dateInstallation, b.original.dateInstallation, desc)
        }
    ], [linkCellEtatDemande, linkUrlCell, affichageTexteAvecSautsDeLigne]);

    return (
        <>
            {isError &&
                <VoxAlert severity={"error"}>
                    {messageError}
                </VoxAlert>
            }

            {isLoading
                ? <VoxLoader positionRelative/>
                : <VoxTable
                    data={rows}
                    columns={columns}
                    cellProps={(cell: CellProps<EnvironnementAInitialiserTableau>) => {
                        if (cell.column.id === "description") {
                            return {
                                style: {textAlign: "left"}
                            }
                        }
                    }}
                  />
            }

            {showInstallationModale &&
                <VoxModal
                    open={showInstallationModale}
                    onSubmit={handleSubmitModificationEtat}
                    onClose={() => setShowInstallationModale(false)}
                    title={"Confirmation d'installation"}
                    startIconCloseButton={"ban"}
                    startIconSubmitButton={"check"}
                    closeButtonText={"Annuler"}
                    submitButtonText={"Confirmer"}
                    content={
                        <>
                            <Typography>L'application a-t-elle bien été <strong>déployée ET initialisée</strong> ?</Typography>
                            <Typography>En cliquant sur Confirmer, cet environnement passera à l'état "Installé"</Typography>
                        </>
                    }
                />
            }
        </>
    )
}