import {Autocomplete, Box, SxProps, TextField} from "@mui/material";
import React, {SyntheticEvent} from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";

/**
 * Propriétés pour autocomplétion
 */
type AutoCompleteProps<Type extends object> = {
    id: string;
    label: string;

    //Les données
    options: Type[];
    //Ce que l'on souhaite afficher dans la liste déroulante
    optionLabel: (option: Type) => string;
    value: Type;
    onChange: (event: SyntheticEvent, object: Type | null) => void;
    //Redéfinir le test d'égalité entre value et la donnée sélectionnée dans la liste
    optionEqualToValue: (option: Type, value: Type) => boolean;
    //Groupement des données par catégories
    groupBy?: (option: Type) => string;
    //Désactiver certaines données dans la liste, selon un critère
    optionDisabled?: (option: Type) => boolean;

    //Autres Options
    small?: boolean;
    helperText?: string;
    startIconLabel?: IconProp;
    disabled?: boolean;
    fullWidth?: boolean;
    required?: boolean;
    sx?: SxProps;
}

/**
 * Composant générique: Input avec autocomplétion
 * @description /!\ Le type des variables passées en arguments pour les props
 * 'optionLabel, value, optionEqualToValue, groupBy, optionDisabled'
 * doit être identique au type passé dans 'options'
 * @link https://mui.com/material-ui/react-autocomplete/
 * @param props Les propriétés
 */
export const VoxAutocomplete = <Type extends object>(props: AutoCompleteProps<Type>) => {
    return (
        <Autocomplete
            disablePortal
            id={props.id}

            options={props.options}
            getOptionLabel={props.optionLabel}
            value={props.value}
            onChange={props.onChange}
            isOptionEqualToValue={props.optionEqualToValue}
            groupBy={props.groupBy}
            getOptionDisabled={props.optionDisabled}

            size={props.small ? 'small' : 'medium'}
            fullWidth={props.fullWidth}
            disabled={props.disabled}

            renderInput={(params) =>
                <TextField
                    {...params}
                    required={props.required}
                    helperText={props.helperText}
                    sx={{
                        ...props.sx,
                        marginTop: !props.label ? 1 : undefined,
                        '& input::placeholder': {
                            opacity: 1,
                            color: 'rgba(102,109,146,0.7)' // Lynch (70%)
                        }
                    }}
                    label={
                        props.label &&
                        <>
                            {props.startIconLabel &&
                            <Box component={"span"} sx={{pr: 1}}>
                                <FontAwesomeIcon icon={props.startIconLabel}/>
                            </Box>
                            }
                            <Typography component={"span"} variant="subtitle1">{props.label}</Typography>
                        </>
                    }
                />
            }
        />
    )
}