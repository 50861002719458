import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { useNavigate } from "react-router-dom";
import utilisateurService from "../../../services/survox-back/utilisateur/utilisateur.service";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CellProps, Column, FilterProps } from "react-table";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { MenuItem, Select } from "@mui/material";
import { SelectInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import VoxIconAction from "../../../components/genericComponentsUI/pictogrammes/voxIconAction";

type UtilisateurTableau = {
    id: number;
    login: string;
    prenom: string;
    nom: string;
    mail: string;
    groupe: string;
    estActif: boolean;
}

export default function UtilisateursListe() {

    const navigate = useNavigate();

    const [utilisateurs, setUtilisateurs] = useState<Utilisateur[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        loadUtilisateurs(controller);
        return () => controller.abort();
    }, []);

    /**
     * Charger les utilisateurs
     */
    const loadUtilisateurs = (controller?: AbortController) => {
        setIsLoading(true);
        utilisateurService.getAllUtilisateurs(controller?.signal)
            .then(response => {
                if (response.status === 200) {
                    const utilisateursListe: Utilisateur[] = response.data;
                    setUtilisateurs(utilisateursListe);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    /**
     * Redirige vers la page de modification de l'utilisateur donné
     */
    const handleUtilisateurModification = useCallback( (id: number) => {
        navigate(`modification/${id}`);
    }, [navigate]);

    /**
     * Redirige vers la page de création d'un utilisateur
     */
    const handleOnCreationUtilisateur = () => {
        navigate('creation');
    };


    /**
     * Remplissage des lignes du tableau
     */
    const rows : UtilisateurTableau[] = useMemo(() => {
        return utilisateurs.map(
            (utilisateur: Utilisateur) => ({
                id: utilisateur.id,
                login: utilisateur.login,
                prenom: utilisateur.prenom,
                nom: utilisateur.nom,
                mail: utilisateur.mail,
                groupe: utilisateur.groupe ? utilisateur.groupe.nom : "Non défini",
                estActif: utilisateur.estActif,
            })
        )
    }, [utilisateurs]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns : Column<UtilisateurTableau>[] = useMemo( () => [
                {
                    Header: 'Login',
                    accessor: 'login',
                    flexGrow: 0.5,
                    minWidth: 120,
                },
                {
                    Header: 'Prénom',
                    accessor: 'prenom',
                    flexGrow: 0.5,
                    minWidth: 120,
                },

                {
                    Header: 'Nom',
                    accessor: 'nom',
                    flexGrow: 0.5,
                    minWidth: 120,
                },
                {
                    Header: 'Email',
                    accessor: 'mail',
                    flexGrow: 1,
                    minWidth: 250,
                },
                {
                    Header: 'Groupe',
                    accessor: 'groupe',
                    Filter: SelectInputFilter,
                    filter: 'includes',
                    flexGrow: 0.5,
                    minWidth: 160,
                },
                {
                    Header: 'Actif',
                    accessor: 'estActif',
                    flexGrow: 0.3,
                    minWidth: 90,
                    maxWidth: 110,
                    Cell: (props: CellProps<UtilisateurTableau>) => {
                        return (props.value) ? <FontAwesomeIcon icon={"check"}/> : <FontAwesomeIcon icon={"times"}/>
                    },
                    Filter: ({column: {filterValue, setFilter}}: FilterProps<UtilisateurTableau>) => {
                        return <Select
                            onChange={event => setFilter(event.target.value || undefined)}
                            sx={{ width: "100%" }}
                            value={!filterValue ? '' : filterValue}
                            size={'small'}
                        >
                            <MenuItem sx={{ height: 36 }} value=""/>
                            <MenuItem value="true">Actif</MenuItem>
                            <MenuItem value="false">Inactif</MenuItem>
                        </Select>
                    },
                    filter: 'equal',
                    sortType: 'basic'   // permet le tri de la colonne avec des valeurs booléennes
                },
                {
                    Header: 'Actions',
                    id: 'actions',
                    align: "center",
                    flexGrow: 0.1,
                    disableFilters: true,
                    disableSortBy: true,
                    Cell: (props: CellProps<UtilisateurTableau>) => {
                        if (props.row.original.login !== "audit" && props.row.original.login !== "systeme") {
                            return (
                                 // Crayon → Modification d'un utilisateur
                                <VoxIconAction
                                    tooltip={"Modifier un utilisateur"}
                                    icon={"edit"}
                                    onClick={() => handleUtilisateurModification(props.row.original.id)}
                                />
                            )
                        }
                        return null;
                    },
                    minWidth: 60,
                    maxWidth: 100,
                },
        ],[handleUtilisateurModification]
    )


    if (isLoading) {
        return <VoxLoader />
    } else {
        return (
            <div>
                <VoxButton
                    startIcon={<FontAwesomeIcon icon={"plus"}/>}
                    onClick={handleOnCreationUtilisateur}
                >
                    Ajout d'un utilisateur
                </VoxButton>
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialStateFilters={ [{id: 'estActif', value: true}] }
                    initialStateSortBy={ [{id: "login", desc: false}] }
                />
            </div>
        )
    }
}