import dayjs from "dayjs";

/**
 * Fonction permettant de trier des dates dans la colonne d'un tableau.
 * Prend en paramètre 2 dates fournies par les lignes du tableau.
 * @param dateA
 * @param dateB
 * @param desc
 */
export const sortDatesInTables = (dateA: Date | null, dateB: Date | null, desc: boolean | undefined) => {

    const firstDate = dayjs(dateA);
    const secondDate = dayjs(dateB);

    if (firstDate.isValid() && secondDate.isValid()) {
        if (firstDate.isBefore(secondDate)) return -1;
        else return 1;
    } else if (firstDate.isValid() && dateB === null) {
        return desc ? 1 : -1;
    } else if (dateA === null && secondDate.isValid()) {
        return desc ? -1 : 1;
    } else {
        return 0;
    }
}


/**
 * REGEX d'une date (format anglais) (YYYY-MM-DD)
 */
export const REGEX_DATE_EN: RegExp = /^(\d{4})-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/;

/**
 * REGEX d'une date avec heures et minutes (format anglais) (YYYY-MM-DDTHH:mm)
 */
export const REGEX_DATE_EN_HOURS_MINUTES: RegExp = /^(\d{4})-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])T(0\d|1\d|2[0-4]):(0\d|[1-5]\d)(?::(0\d|[1-5]\d))?(?:.(00\d|0[1-9]\d|[1-9]\d{2}))?$/;