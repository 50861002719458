import React, {useContext} from "react";
import OperationsASupprimerListe from "../../../components/operationsListe/operationsASupprimerListe";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import EnvironnementsAsupprimer from "../../../components/environnements/environnementsAsupprimer";
import OperationsSIRSASupprimerListe from "../../../components/operationsSIRSListe/operationsSIRSASupprimerListe";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {Utilisateur} from "../../../models/utilisateur/utilisateur.model";

export default function OperationsASupprimerView() {
    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    return (
        <>
            {
                (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                || utilisateur.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
                || utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE ) && (
                    <>
                        <VoxHeader title={"Opérations à supprimer"}/>
                        <OperationsASupprimerListe typeOperation={"all"}/>
                        <VoxHeader title={"Environnements hors production à supprimer"} marginTop={6}/>
                        <EnvironnementsAsupprimer/>
                    </>
                )
            }
            {
                (utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                || utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_SIRS) && (
                    <>
                        <VoxHeader title={"Opérations SIRS à supprimer"}
                                   marginTop={(utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_SIRS) ? 0 : 6}/>
                        <OperationsSIRSASupprimerListe/>
                    </>
                )
            }
        </>
    );
}