import React from "react";
import Navbar from "../../components/layouts/navbar/navbar";
import { Container, Grid } from "@mui/material";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import ModificationMdpForm from "../../components/forms/authentication/modificationMdpForm";


export default function UtilisateurModificationMdpView()  {

    return (
        <>
            <Navbar/>
            <Container sx={{padding: '3rem 0 !important', maxWidth: '90%'}}>
                <VoxHeader title={"Modification de votre mot de passe"}/>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={10} sm={6} md={5}>
                        <ModificationMdpForm/>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}