import { Column } from "react-table";

/**
 * Permet de récupérer la liste des id des colonnes masquées à partir de la liste des id des colonnes affichées et de la configuration
 * @param visibleColumns
 * @param columns
 */
export function getHiddenColumnsFromVisible<Type extends object>(visibleColumns: string[], columns: Column<Type>[]) {
    return columns
        .filter(column => !visibleColumns.includes(String(column.id ? column.id : column.accessor)))
        .map(column => String(column.id ? column.id : column.accessor));
}