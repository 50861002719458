import {Box, DialogActions, DialogContent, Dialog} from "@mui/material";
import VoxButton from "../genericComponentsUI/button/voxButton";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import {FormEvent, ReactElement, ReactFragment, useCallback} from "react";
import VoxConditionalWrapper from "../layouts/wrapper/voxConditionalWrapper";
import { VoxDialogTitle } from "./voxDialogTitle";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type VoxModalPropsType = {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
    title: ReactFragment | ReactElement ;
    content: ReactElement | undefined;
    authorized?: boolean;
    widthButton?: string;
    isValidationLoading?: boolean;
    closeButtonText: string;
    submitButtonText: string;
    customColorCloseButton?: "blue" | "green" | "grey" | "red";
    customColorSubmitButton?: "blue" | "green" | "grey" | "red";
    startIconCloseButton?: IconProp;
    startIconSubmitButton?: IconProp;
    submitButtonDisabled?: boolean;
    isFormulaire?: boolean;
}

/**
 *@description Modale simple, ou avec Formulaire
 */
export default function VoxModal(props: Readonly<VoxModalPropsType>) {

    const submit = props.onSubmit;

    // Wrapper pour loader sur le bouton de validation
    const handleWrapperVoxLoader = useCallback((children: ReactElement) => {
        return (
            <Box sx={{position: 'relative'}}>
                {children}
                <VoxLoader size={20}/>
            </Box>
        )
    }, []);

    // Wrapper pour définir un formulaire dans la modale
    const handleWrapperFormulaire = useCallback((children: ReactElement) => {
        return (
            <Box component={"form"} onSubmit={(event: FormEvent<HTMLDivElement>) => {
                event.preventDefault();
                submit();
            }}>
                {children}
            </Box>
        )
    }, [submit])

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <VoxDialogTitle onClose={props.onClose}>
                {props.title}
            </VoxDialogTitle>

            <VoxConditionalWrapper condition={props.isFormulaire} wrapper={handleWrapperFormulaire}>
                <>
                    <DialogContent dividers>
                        {props.content}
                    </DialogContent >

                    {((props.authorized !== undefined) ? props.authorized : true) &&
                    <DialogActions
                        sx={{
                            borderTop: "1px solid #EDEDF2",
                            display: "flex",
                            alignItems: "center",
                            gap: '0.8rem'
                        }}
                    >
                        <VoxButton
                            variant={"outlined"}
                            sx={{width: props.widthButton}}
                            customColor={props.customColorCloseButton}
                            startIcon={props.startIconCloseButton ? <FontAwesomeIcon icon={props.startIconCloseButton}/> : undefined}
                            onClick={props.onClose}
                            disabled={props.isValidationLoading}
                        >
                            {props.closeButtonText}
                        </VoxButton>

                        <VoxConditionalWrapper
                            condition={props.isValidationLoading}
                            wrapper={handleWrapperVoxLoader}
                        >
                            <VoxButton
                                sx={{width: props.widthButton, margin: '0 !important'}}
                                onClick={props.isFormulaire ? undefined : props.onSubmit}
                                type={props.isFormulaire ? "submit" : undefined}
                                customColor={props.customColorSubmitButton}
                                startIcon={props.startIconSubmitButton ? <FontAwesomeIcon icon={props.startIconSubmitButton}/> : undefined}
                                disabled={props.submitButtonDisabled || props.isValidationLoading}
                            >
                                {props.submitButtonText}
                            </VoxButton>
                        </VoxConditionalWrapper>
                    </DialogActions>
                    }
                </>
            </VoxConditionalWrapper>
        </Dialog>
    )
}