import React, { useCallback, useMemo, useState } from "react";
import { ColumnInstance, Row } from "react-table";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import VoxButton from "../button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Définition du type des props du composant ExportCsvTable
 */
type ExportCsvTablePropsType<Type extends Object> = {
    csvFileName?: string; // Le préfixe du nom du fichier (Optionel)
    data: Row<Type>[]; // Les données brutes affichées dans le tableau
    visibleColumns: ColumnInstance<Type>[]; // Les colonnes affichées dans le tableau
    csvDataSerialization: (columns: ColumnInstance<Type>[], row: Row<Type>) => object; // Une fonction pour serialiser les lignes du tableau
}

export default function ExportCsvTable<Type extends Object>({ csvFileName, data, visibleColumns, csvDataSerialization }: ExportCsvTablePropsType<Type>) {

    const [fileName, setFileName] = useState<string>(`${csvFileName ? csvFileName : 'operation'}_${dayjs().format("YYYY_MM_DD_HH-mm-ss")}.csv`);

    /**
     * Sérialise les données affichées dans le tableau pour l'export csv
     */
    const dataCsvExport = useMemo(() => {
        return data.map((row: Row<Type>) => csvDataSerialization(visibleColumns, row));
    }, [data, visibleColumns, csvDataSerialization]);

    /**
     * Permet de mettre à jour le nom du fichier lorsque l'utilisateur clique sur le bouton télécharger csv
     */
    const handleOnClickDownloadCsv = useCallback(() => {
        setFileName(`${csvFileName ? csvFileName : 'operations'}_${dayjs().format("YYYY_MM_DD_HH-mm-ss")}.csv`);
    }, [csvFileName]);

    return (
        <CSVLink
            data={dataCsvExport}
            separator={';'}
            filename={fileName}
            target="_blank"
            onClick={handleOnClickDownloadCsv}
            style={{ textDecoration: "none" }}
        >
            <VoxButton
                id={"download-csv-btn"}
                variant={"outlined"}
                startIcon={<FontAwesomeIcon icon={"download"}/>}
            >
                Télécharger CSV
            </VoxButton>
        </CSVLink>
    );
}