import React from "react";
import {Typography} from "@mui/material";
import VoxModal from "./voxModal";

type ModaleType = {
    show: boolean,
    isInstalling: boolean,
    handleClose: () => void,
    handleSubmit: () => void,
}

export default function ModaleConfirmationInstallationOVH(props: ModaleType) {

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Confirmation d'installation"}
            content={
                <Typography variant={"body1"}>
                    Souhaitez-vous lancer l'installation automatique du site chez l'hébergeur OVH ?
                </Typography>
            }
            closeButtonText={"Non"}
            submitButtonText={"Confirmer"}
            isValidationLoading={props.isInstalling}
        />
    )
}