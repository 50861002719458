import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Operation } from "../../models/operation/operation.model";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import React from "react";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { VoxDialogTitle } from "./voxDialogTitle";

type PropsType = {
    show: boolean;
    isLoading: boolean;
    handleClose: () => void;
    handleDemandeSuppression: () => void;
    handleDemandeArchivage: () => void;
    operation: Operation | undefined
}

const buttonSx = {
    minHeight: "48px",
    fontSize: "0.85rem"
}

export default function ModaleDemandeSuppressionOuArchivage(props: PropsType) {

    return (
        <Dialog open={props.show} onClose={!props.isLoading ? props.handleClose : undefined}>
            <VoxDialogTitle onClose={!props.isLoading ? props.handleClose : undefined}>
                Choix du processus de suppression d'une opération
            </VoxDialogTitle>

            <DialogContent
                dividers
            >
                <Typography variant={"h3"} component={"div"} textAlign={"center"}>
                    Opération sélectionnée : {props.operation?.nom}
                </Typography>

                <VoxAlert severity={"info"} sx={{my: 3}}>
                    L'archivage induit des traitements spécifiques de l'équipe hébergement pour enregistrer les
                    données du vote tandis que la suppression consiste au décommissionnement du site de vote.
                </VoxAlert>

                <Typography variant={"body1"} component={"div"} textAlign={"center"}>
                    Quelle action souhaitez-vous réaliser ?
                </Typography>

                <Grid container
                      display={"flex"}
                      justifyContent={"center"}
                      marginTop={2}
                      gap={"0.8rem 2rem"}
                >
                    <VoxButton
                        startIcon={<FontAwesomeIcon icon={"trash"}/>}
                        sx={buttonSx}
                        onClick={props.handleDemandeSuppression}
                        disabled={props.isLoading}
                    >
                        Demander la suppression
                    </VoxButton>
                    <VoxButton
                        startIcon={<FontAwesomeIcon icon={"file-archive"}/>}
                        sx={buttonSx}
                        onClick={props.handleDemandeArchivage}
                        disabled={props.isLoading}
                    >
                        Demander l'archivage
                    </VoxButton>
                </Grid>
            </DialogContent>

            {(props.isLoading) &&
                <VoxLoader isBackgroundColor={true}/>
            }
        </Dialog>
    )
}