import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./operationsFacturation.css"
import facturationService from "../../services/survox-back/operation/facturation.service";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { ExtractionFacturation } from "../../models/operation/extractionFacturation.model";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import articleService from "../../services/survox-back/operation/article.service";
import { Article } from "../../models/operation/article.model";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { affichageDateWithFormatFullTexteDMYHms } from "../../utils/string.utils";
import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import FacturationPopover from "./facturationPopover/facturationPopover";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import VoxCard from "../genericComponentsUI/card/voxCard";


/**
 * Fonction composant pour la facturation pour les opérations (VoxCore-Election, VoxCore-AG, Saturne, MailEva et Actionnariat)
 * @param props
 * @constructor
 */
export default function OperationsFacturation(props: { typeOperation: TypeOperation | string }) {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [extractionFacturation, setExtractionFacturation] = useState<ExtractionFacturation>();

    const [isLoadingDerniereExtractionFacturation, setIsLoadingDerniereExtractionFacturation] = useState(false);
    const [isLoadingErrorDerniereExtractionFacturation, setIsLoadingErrorDerniereExtractionFacturation] = useState(false);
    const [isLoadingExtraction, setIsLoadingExtraction] = useState(false);
    const [isSuccesExtraction, setIsSuccesExtraction] = useState(false);
    const [isLoadingErrorExtraction, setIsLoadingErrorExtraction] = useState(false);
    const [isNotDataExtraction, setIsNotDataExtraction] = useState(false);
    const [isLoadingErrorTelechargementCsv, setIsLoadingErrorTelechargementCsv] = useState(false);

    const [articles, setArticles] = useState<Article[]>();
    const [isLoadingArticles, setIsLoadingArticles] = useState<boolean>();
    const [isErrorArticles, setIsErrorArticles] = useState<boolean>();

    const [historiqueExtractions, setHistoriqueExtractions] = useState<ExtractionFacturation[]>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'popover-basic' : undefined;

    /**
     * Méthode récupérant la dernière extraction de facturation en BDD (selon le type d'opération)
     */
    const indexFacturation = useCallback((controller?: AbortController) => {

        setIsLoadingDerniereExtractionFacturation(true);
        setIsLoadingErrorDerniereExtractionFacturation(false);

        let indexFacturationRequest: Promise<AxiosResponse>;

        if (props.typeOperation === TypeOperation.SATURNE) {
            indexFacturationRequest = facturationService.indexFacturationSaturne(controller?.signal);
        } else if (props.typeOperation === TypeOperation.MAILEVA) {
            indexFacturationRequest = facturationService.indexFacturationMaileva(controller?.signal);
        } else {
            indexFacturationRequest = facturationService.indexFacturationGlobale(controller?.signal);
        }

        indexFacturationRequest
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // Récupération des données de l'extraction de facturation (Objet de type ExtractionFacturation)
                    setExtractionFacturation(response.data);
                    setIsLoadingDerniereExtractionFacturation(false);
                    setIsLoadingErrorDerniereExtractionFacturation(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoadingDerniereExtractionFacturation(false);
                    setIsLoadingErrorDerniereExtractionFacturation(true);
                }
            })

    }, [props.typeOperation]);


    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        indexFacturation(controller);
        chargerArticles(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [indexFacturation])


    /**
     * Méthode qui permet de charger les données pour l'extraction de facturation (en base de données)
     */
    const handleExtraireFacturation = () => {
        setIsLoadingExtraction(true);
        setIsNotDataExtraction(false);
        setIsSuccesExtraction(false);
        setIsLoadingErrorExtraction(false);

        let extraireFacturationRequest: Promise<AxiosResponse>;

        if (props.typeOperation === TypeOperation.SATURNE) {
            extraireFacturationRequest = facturationService.extrairePourFacturationSaturne();
        } else if (props.typeOperation === TypeOperation.MAILEVA) {
            extraireFacturationRequest = facturationService.extrairePourFacturationMaileva();
        } else {
            extraireFacturationRequest = facturationService.extrairePourFacturation();
        }

        extraireFacturationRequest
            .then(response => {
                if (response.data === "") {
                    setIsLoadingExtraction(false);
                    setIsNotDataExtraction(true);
                    setIsSuccesExtraction(false);
                    setIsLoadingErrorExtraction(false);
                } else {
                    // Récupération des données de l'extraction de facturation
                    setExtractionFacturation(response.data);
                    setIsLoadingExtraction(false);
                    setIsNotDataExtraction(false);
                    setIsSuccesExtraction(true);
                    setIsLoadingErrorExtraction(false);
                }
            })
            .catch(() => {
                setIsLoadingExtraction(false);
                setIsNotDataExtraction(false);
                setIsSuccesExtraction(false);
                setIsLoadingErrorExtraction(true);
            })
            .finally(() => {
                afficherHistoriqueExtractions();
            });
    }

    const chargerArticles = (controller?: AbortController) => {
        setIsLoadingArticles(true);
        setIsErrorArticles(false);
        articleService.getArticles(controller?.signal)
            .then(response => {
                if (isMountedRef.current) {
                    setIsLoadingArticles(false);
                    setArticles(response.data);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoadingArticles(false);
                    setIsErrorArticles(true);
                }
            });
    }

    /**
     * Méthode permettant de faire le téléchargement du fichier CSV correspondant à la dernière extraction de facturation selon le type d'opération
     */
    const telechargerFichierCsv = (fichierCsvId: number) => {
        setIsLoadingErrorTelechargementCsv(false);
        facturationService.telechargementFichierFacturationById(Number(fichierCsvId))
            .then(response => {
                // Le caractère '\ufeff' correspond au BOM lors de l'encodage en utf-8 ;
                // Cela permet la reconnaissance des caractères spéciaux lors de l'ouverture du fichier CSV avec Excel
                const url = window.URL.createObjectURL(new Blob(['\ufeff' + response.data]));
                const link = document.createElement('a');
                link.href = url;
                const nomFichier: string = response.headers['content-disposition'].split('filename=')[1];
                link.setAttribute('download', nomFichier);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
                setIsLoadingErrorTelechargementCsv(false);
            })
            .catch(() => {
                setIsLoadingErrorTelechargementCsv(true);
            })
    }

    /**
     * Méthode permettant de lister l'historique des 10 derniers fichiers générés
     */
    const afficherHistoriqueExtractions = useCallback((controller?: AbortController) => {

        let getHistoriqueExtractions: Promise<AxiosResponse>;

        if (props.typeOperation === TypeOperation.SATURNE) {
            getHistoriqueExtractions = facturationService.getHistoriqueFacturationSaturne(controller?.signal);
        } else if (props.typeOperation === TypeOperation.MAILEVA) {
            getHistoriqueExtractions = facturationService.getHistoriqueFacturationMaileva(controller?.signal);
        } else {
            getHistoriqueExtractions = facturationService.getHistoriqueFacturationGlobale(controller?.signal);
        }

        getHistoriqueExtractions
            .then(response => {
                if (isMountedRef.current) {
                    setHistoriqueExtractions(response.data);
                }
            })
            .catch(() => {})
    }, [props.typeOperation]);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        afficherHistoriqueExtractions(controller);
        // Cleaner
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [afficherHistoriqueExtractions])


    /**
     * Le message d'alerte à afficher pour informer l'utilisateur lors de l'extraction d'un fichier de facturation ou du téléchargement du fichier CSV
     */
    const messageAlerte = useMemo(() => {
        if (isSuccesExtraction && extractionFacturation) {
            return (
                <VoxAlert severity={"success"} onClose={() => setIsSuccesExtraction(false)} sx={{wordSpacing: "0.15rem"}}>
                    <span>Le fichier <i>{extractionFacturation?.nomFichierCsv}</i> a bien été généré.</span>
                </VoxAlert>
            )
        } else if (isLoadingErrorExtraction) {
            return (
                <VoxAlert severity={"error"} onClose={() => setIsLoadingErrorExtraction(false)}>
                    Une erreur est survenue lors de l'extraction du fichier de facturation.
                </VoxAlert>
            )
        } else if (isNotDataExtraction) {
            return (
                <VoxAlert severity={"info"} onClose={() => setIsNotDataExtraction(false)}>
                    Aucune donnée de facturation à extraire sur la période écoulée (3 mois).
                </VoxAlert>
            )
        } else if (isLoadingErrorTelechargementCsv) {
            return (
                <VoxAlert severity={"error"} onClose={() => setIsLoadingErrorTelechargementCsv(false)}>
                    Une erreur est survenue lors du téléchargement du fichier CSV.
                </VoxAlert>
            )
        }
    }, [isSuccesExtraction, extractionFacturation, isLoadingErrorExtraction, isNotDataExtraction, isLoadingErrorTelechargementCsv]);

    return (
        <Grid container display={'flex'} justifyContent={'center'}>
            <Grid item xs={12} sm={8} md={6}>
                {messageAlerte}
                {(isLoadingDerniereExtractionFacturation) ?
                    <VoxLoader />
                    :
                    ((isLoadingErrorDerniereExtractionFacturation)
                        ?
                        <VoxAlert severity={"error"} >
                            Une erreur est survenue lors de la récupération de la dernière extraction de facturation.
                        </VoxAlert>
                        :
                        <Grid container gap={4}>

                            {/* NOUVEL EXPORT */}
                            <Grid item xs={12}>
                                <VoxCard
                                    title={"Créer un nouvel export"}
                                    action={
                                        <>
                                            <div className={"info-wrapper"}>
                                                <VoxButton
                                                    variant={"text"}
                                                    sx={{ fontSize: "1.2rem" }}
                                                    aria-describedby={id}
                                                    onClick={handleClick}
                                                >
                                                    <FontAwesomeIcon icon={"info-circle"} />
                                                </VoxButton>
                                            </div>
                                            <FacturationPopover
                                                id={id}
                                                open={open}
                                                onClose={handleClose}
                                                anchorEl={anchorEl}
                                                typeOperation={props.typeOperation}
                                                isLoadingArticles={isLoadingArticles}
                                                isErrorArticles={isErrorArticles}
                                                articles={articles}
                                            />
                                        </>
                                    }
                                >
                                    {/* Bouton "Extraire" */}
                                    <div className={"extract-wrapper"}>
                                        <VoxButton
                                            startIcon={<FontAwesomeIcon icon={"file-export"} />}
                                            onClick={handleExtraireFacturation}
                                        >
                                            Extraire la facturation
                                        </VoxButton>
                                    </div>

                                    {/* Téléchargement du dernier fichier */}
                                    {(extractionFacturation) &&
                                        <div className={"download-wrapper"}>
                                            <VoxButton
                                                variant={"outlined"}
                                                startIcon={<FontAwesomeIcon icon={"download"} />}
                                                onClick={() => {
                                                    setIsLoadingErrorTelechargementCsv(false);
                                                    if (extractionFacturation) {
                                                        telechargerFichierCsv(extractionFacturation.id)
                                                    } else {
                                                        setIsLoadingErrorTelechargementCsv(true);
                                                    }
                                                }}
                                            >
                                                Télécharger le dernier fichier
                                            </VoxButton>
                                        </div>
                                    }
                                    {
                                        (isLoadingExtraction) ? <VoxLoader isBackgroundColor={true} /> : null
                                    }
                                </VoxCard>
                            </Grid>

                            {/* HISTORIQUE DES 10 DERNIERS EXPORTS */}
                            {historiqueExtractions?.length !== 0 && (
                                <Grid item xs={12}>
                                    <VoxCard title={"Télécharger un export précédent"}>
                                        <ButtonGroup
                                            orientation={"vertical"}
                                            aria-label={"Liste des exports précédents de facturation"}
                                            fullWidth
                                        >
                                            {historiqueExtractions?.map((extract: ExtractionFacturation) => (
                                                <Button
                                                    key={extract.id}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        py: 3,
                                                        borderRadius: 3,
                                                        borderColor: "rgba(0, 0, 255, .3)",
                                                        transition: "all 0.2s ease-in-out",
                                                        "&:hover": {
                                                            borderColor: "rgba(0, 0, 255, .5)",
                                                            paddingLeft: "28px"
                                                        }
                                                    }}
                                                    onClick={() => telechargerFichierCsv(extract.id)}
                                                >
                                                    <Typography component={"span"}>
                                                        <FontAwesomeIcon icon={"download"}/>
                                                        <Box component={"span"} pl={1.5}>Export CSV du {affichageDateWithFormatFullTexteDMYHms(extract.dateGeneration)}</Box>
                                                    </Typography>
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </VoxCard>
                                </Grid>
                            )}

                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    )
}