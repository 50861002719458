import React from "react";
import OperationsFacturation from "../../components/facturation/operationsFacturation";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsSaturneFacturationView() {

    return (
        <>
            <VoxHeader title={"Facturation Vente directe (Saturne)"}/>
            <OperationsFacturation typeOperation={TypeOperation.SATURNE}/>
        </>
    );
}