import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import VoxModal from "./voxModal";
import { Typography } from "@mui/material";


type PropsType = {
    authorizedAcquittement: boolean;
    operationNom?: string;
    show: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    loadingAcquittementOperation: boolean;
}

export default function ModaleConfirmationAcquittement(props: PropsType) {

    let modalBody;
    if (props.authorizedAcquittement) {
        modalBody = (
            <Typography variant={"body1"}>
                Souhaitez-vous vraiment acquitter l'opération { props.operationNom } ?
            </Typography>
        )
    } else {
        modalBody = (
            <VoxAlert severity={"error"}>
                Vous n'êtes pas autorisé à faire l'acquittement d'une opération.
            </VoxAlert>
        )
    }

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            authorized={props.authorizedAcquittement}
            title={"Confirmation d'acquittement"}
            content={modalBody}
            widthButton={"100px"}
            closeButtonText={"Annuler"}
            submitButtonText={"Valider"}
            isValidationLoading={props.loadingAcquittementOperation}
        />
    )
}