import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import { useNavigate, useParams } from "react-router-dom";
import operationService from "../../../services/survox-back/operation/operation.service";
import { Operation } from "../../../models/operation/operation.model";
import { OperationOption } from "../../../models/operation/operationOption.model";
import { ParamsUpdateOperationSaturneMailevaDto, UpdateOperationSaturneMailevaDto, UpdateOperationSaturneMailevaWithParamsDto } from "../../../services/survox-back/operation/dtos/request/updateOperationSaturneMaileva.dto";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import { ParamsUpdateOperationDto, UpdateOperationDto, UpdateOperationWithParamsDto } from "../../../services/survox-back/operation/dtos/request/updateOperation.dto";
import { DatesActionnariatDto, ParamsUpdateOperationActionnariatDto, UpdateOperationActionnariatDto, UpdateOperationActionnariatWithParamsAndDatesDto } from "../../../services/survox-back/operation/dtos/request/updateOperationActionnariat.dto";
import RestrictedRouteOperation from "../../routes/restrictedRouteOperation";
import { NomGroupe } from "../../../models/utilisateur/nomGroupe.enum";
import { EtatDemande } from "../../../models/operation/etatDemande.enum";
import { Grid } from "@mui/material";
import VoxCard from "../../genericComponentsUI/card/voxCard";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import { DatesActionnariatFormulaire } from "../../../models/operation/operationForm/datesActionnariatFormulaire.model";
import { ParamsOperationFormulaire } from "../../../models/operation/operationForm/paramsOperationFormulaire.model";
import { OperationFormulaire } from "../../../models/operation/operationForm/operationFormulaire.model";
import { Domaine } from "../../../models/operation/domaine.enum";
import { TypeOption } from "../../../models/operation/typeOption.enum";
import { NomPlateforme } from "../../../models/operation/nomPlateforme.enum";
import OperationsForm from "./operationsForm";
import { OperationVerificationRestrictedRoute } from "../../../models/operation/operationForm/operationVerificationRestrictedRoute.model";
import { isVersionApplicativeSansComposants } from "../../../utils/versionApplicative.utils";
import { TypeProduit } from "../../../models/versionApplicative/typeProduit.enum";


export default function OperationsModification(props: { typesOperation: TypeOperation[] }) {
    let params = useParams();
    const navigate = useNavigate();

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // Récupération des données de l'opération en base de données que l'on souhaite fournir au formulaire
    const [operationBdd, setOperationBdd] = useState<OperationFormulaire>();
    const [paramsOperationBdd, setParamsOperationBdd] = useState<ParamsOperationFormulaire>({} as ParamsOperationFormulaire);
    const [datesActionnariatBdd, setDatesActionnariatBdd] = useState<DatesActionnariatFormulaire>();

    const [operationVerification, setOperationVerification] = useState<OperationVerificationRestrictedRoute>();

    const [isLoadingOperation, setIsLoadingOperation] = useState(false);
    const [isLoadError, setIsLoadError] = useState(false);
    const [isUpdateOperation, setIsUpdateOperation] = useState(false);
    const [isUpdateError, setIsUpdateError] = useState(false);
    const [isErrorIdSalesforce, setIsErrorIdSalesforce] = useState(false);
    const [messageErrorIdSalesforce, setMessageErrorIdSalesforce] = useState("");
    const [messageError, setMessageError] = useState("");
    const [messageErrorUrlDoublon, setMessageErrorUrlDoublon] = useState("");

    const [isErrorVersionSpecifique, setIsErrorVersionSpecifique] = useState(false);
    const [messageErrorVersionSpecifique, setMessageErrorVersionSpecifique] = useState("");

    const [isErrorDateInstallation, setIsErrorDateInstallation] = useState(false);
    const [isErrorDateOuverture, setIsErrorDateOuverture] = useState(false);
    const [isErrorDateFermeture, setIsErrorDateFermeture] = useState(false);

    const [isAffichageDateInstallation, setIsAffichageDateInstallation] =  useState<boolean>(false);

    useEffect(() => {
        // Condition d'affichage de l'input "Date d'installation"
        setIsAffichageDateInstallation(
            operationBdd?.type === TypeOperation.VOXCORE_ELECTION
            || operationBdd?.type === TypeOperation.VOXCORE_AG
            || operationBdd?.type === TypeOperation.ACTIONNARIAT
        );
    }, [operationBdd]);



    /**
     * Méthode qui permet de charger l'opération sélectionnée (opération que l'on souhaite modifier)
     * @param id
     */
    const chargerOperation = useCallback((id: number, controller: AbortController) => {
        setIsLoadingOperation(true);
        setIsLoadError(false);
        operationService.getOperationAvecOptions(id, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setIsLoadError(false);
                    // récupération de l'opération
                    const operation: Operation = response.data[0];
                    // récupération d'un tableau contenant les éventuelles options de l'opération
                    const operationOptions: OperationOption[] = response.data[1];

                    /** Alimentation des différentes variables */
                    setOperationBdd({
                        nomClient: operation.nomClient,
                        portefeuilleProjet: operation.portefeuilleProjet,
                        dateInstallation: operation.dateInstallation,
                        type: operation.type,
                        versionApplicative: operation.versionApplicative,
                        salesforceId: operation.salesforceId,
                        plateforme: operation.plateforme,
                        description: (operation.description === null) ? "" : operation.description
                    });

                    setParamsOperationBdd({
                        urlPartieClient: getUrlPartieClient(operation),
                        urlDomaine: Domaine[operation.urlDomaine as keyof typeof Domaine],
                        isModeTest: (operation.numeroOperation === 8000),
                        optionEnvoi: null,      // Initialisation de la valeur juste en dessous
                        optionPapier: null,     // Initialisation de la valeur juste en dessous
                    });
                    // Initialisation des options de l'opération avec les données présentes en base
                    operationOptions.forEach((operationOption: OperationOption) => {
                        if (operationOption.option === TypeOption.COURRIER || operationOption.option === TypeOption.EMAIL) {
                            return setParamsOperationBdd((prevState: ParamsOperationFormulaire) => ({...prevState, optionEnvoi: operationOption.option}))
                        }
                        else if (operationOption.option === TypeOption.RECTO_VERSO || operationOption.option === TypeOption.RECTO) {
                            return setParamsOperationBdd((prevState: ParamsOperationFormulaire) => ({...prevState, optionPapier: operationOption.option}))
                        }
                    });

                    setDatesActionnariatBdd({
                        dateOuverture: operation.informationsOperation?.dateOuvertureTour1,
                        dateFermeture: operation.informationsOperation?.dateFermetureTour1
                    });

                    setOperationVerification({
                        etatDemande: operation.etatDemande,
                        type: operation.type,
                        utilisateur: operation.utilisateur
                    });

                    setIsLoadingOperation(false);
                }
            })
            .catch((error) => {
                if (isMountedRef.current && error.message !== "canceled") {
                    setIsLoadError(true);
                    setIsLoadingOperation(false);
                }
            });
    }, []);

    const getUrlPartieClient = (operation: Operation) => {
        if (operation.urlClient && (process.env.REACT_APP_SURVOX_URL_PREFIXE !== '')) {
            return operation.urlClient.split(`${process.env.REACT_APP_SURVOX_URL_PREFIXE}`)[1];
        } else if (operation.urlClient && (process.env.REACT_APP_SURVOX_URL_PREFIXE === '')) {
            // Cas sur l'environnement de production
            return operation.urlClient;
        } else {
            return "";
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        if (params.id) {
            chargerOperation(Number(params.id), controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [params.id, chargerOperation]);


    const onUpdateOpSaturneMaileva = (updateOperationSaturneMailevaDto: UpdateOperationSaturneMailevaDto, paramsOperationSaturneMailevaDto: ParamsUpdateOperationSaturneMailevaDto) => {
        setIsUpdateOperation(true);
        setIsUpdateError(false);
        setIsErrorIdSalesforce(false);
        const updateOperationSaturneMailevaWithParamsDto: UpdateOperationSaturneMailevaWithParamsDto = {...updateOperationSaturneMailevaDto, ...paramsOperationSaturneMailevaDto}
        /**
         * Enregistrement des modifications de l'opération Saturne
         */
        if (operationBdd && (operationBdd.type === TypeOperation.SATURNE)) {
            operationService.updateOperationSaturne(Number(params.id), updateOperationSaturneMailevaWithParamsDto)
                .then((response) => {
                    if(response.status === 200 && !response.data.error) {
                        setIsUpdateOperation(false);
                        setIsUpdateError(false);
                        setIsErrorIdSalesforce(false);
                        navigate(-1);
                    } else {
                        setIsUpdateOperation(false);
                        setIsUpdateError(true);
                        setIsErrorIdSalesforce(false);
                    }
                })
                .catch((error) => {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                    setIsErrorIdSalesforce(false);
                    if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                        setIsUpdateError(false);
                        setIsErrorIdSalesforce(true);
                        setMessageErrorIdSalesforce(error.response.data.message[0]);
                    } else if (error.response.status === 403) {
                        // erreur doublon url
                        setMessageErrorUrlDoublon(error.response.data.message);
                    } else {
                        setMessageError(error.response.data.message[0]);
                    }
                })
        }
        /**
         * Enregistrement des modifications de l'opération Maileva
         */
        if (operationBdd && (operationBdd.type === TypeOperation.MAILEVA)) {
            operationService.updateOperationMaileva(Number(params.id), updateOperationSaturneMailevaWithParamsDto)
                .then((response) => {
                    if(response.status === 200 && !response.data.error) {
                        setIsUpdateOperation(false);
                        setIsUpdateError(false);
                        setIsErrorIdSalesforce(false);
                        navigate(-1);
                    } else {
                        setIsUpdateOperation(false);
                        setIsUpdateError(true);
                        setIsErrorIdSalesforce(false);
                    }
                })
                .catch((error) => {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                    setIsErrorIdSalesforce(false);
                    if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                        setIsUpdateError(false);
                        setIsErrorIdSalesforce(true);
                        setMessageErrorIdSalesforce(error.response.data.message[0]);
                    } else if (error.response.status === 403) {
                        // erreur doublon url
                        setMessageErrorUrlDoublon(error.response.data.message);
                    } else {
                        setMessageError(error.response.data.message[0]);
                    }
                })
        }
    }

    /**
     * Enregistrement des modifications de l'opération de type VoxCore-Election ou VoxCore-AG
     */
    const onUpdateOp = (updateOperationDto: UpdateOperationDto, paramsOperationDto: ParamsUpdateOperationDto) => {
        setIsUpdateOperation(true);
        setIsUpdateError(false);
        setIsErrorIdSalesforce(false);
        const updateOperationWithParamsDto: UpdateOperationWithParamsDto = {...updateOperationDto, ...paramsOperationDto};

        operationService.updateOperation(Number(params.id), updateOperationWithParamsDto)
            .then((response) => {
                if(response.status === 200 && !response.data.error) {
                    setIsUpdateOperation(false);
                    setIsUpdateError(false);
                    setIsErrorIdSalesforce(false);
                    navigate(-1);
                } else {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                    setIsErrorIdSalesforce(false);
                }
            })
            .catch((error) => {
                setIsUpdateOperation(false);
                setIsUpdateError(true);
                setIsErrorIdSalesforce(false);
                if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                    setIsUpdateError(false);
                    setIsErrorIdSalesforce(true);
                    setMessageErrorIdSalesforce(error.response.data.message[0]);
                } else if (error.response.status === 403) {
                    // erreur doublon url
                    setMessageErrorUrlDoublon(error.response.data.message);
                } else {
                    setMessageError(error.response.data.message[0]);
                }
            })
    }

    /**
     * Enregistrement des modifications de l'opération de type Actionnariat
     */
    const onUpdateOpActionnariat = (updateOperationActionnariatDto: UpdateOperationActionnariatDto, paramsOperationActionnariatDto: ParamsUpdateOperationActionnariatDto, datesActionnariatDto: DatesActionnariatDto) => {
        setIsUpdateOperation(true);
        setIsUpdateError(false);
        setIsErrorIdSalesforce(false);
        const updateOperationActionnariatWithParamsDto: UpdateOperationActionnariatWithParamsAndDatesDto = {...updateOperationActionnariatDto, ...paramsOperationActionnariatDto, ...datesActionnariatDto};

        operationService.updateOperationActionnariat(Number(params.id), updateOperationActionnariatWithParamsDto)
            .then((response) => {
                if(response.status === 200 && !response.data.error) {
                    setIsUpdateOperation(false);
                    setIsUpdateError(false);
                    setIsErrorIdSalesforce(false);
                    navigate(-1);
                } else {
                    setIsUpdateOperation(false);
                    setIsUpdateError(true);
                    setIsErrorIdSalesforce(false);
                }
            })
            .catch((error) => {
                setIsUpdateOperation(false);
                setIsUpdateError(true);
                setIsErrorIdSalesforce(false);
                if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                    setIsUpdateError(false);
                    setIsErrorIdSalesforce(true);
                    setMessageErrorIdSalesforce(error.response.data.message[0]);
                } else if (error.response.status === 403) {
                    // erreur doublon url
                    setMessageErrorUrlDoublon(error.response.data.message);
                } else {
                    setMessageError(error.response.data.message[0]);
                }
            })
    }


    /**
     * Méthode pour enregistrer les modifications d'une opération Saturne, Maileva, VoxCore-Election, VoxCore-AG ou Actionnariat
     * @param operation
     * @param paramsOperation
     * @param datesActionnariat
     */
    const handleOnUpdate = (operation: OperationFormulaire, paramsOperation: ParamsOperationFormulaire, datesActionnariat: DatesActionnariatFormulaire) => {
        setIsErrorDateInstallation(false);
        setIsErrorDateOuverture(false);
        setIsErrorDateFermeture(false);
        setIsErrorVersionSpecifique(false);

        // Vérifications pour la version spécifique (c'est-à-dire qui ne possède pas de composants)
        // (si elle est renseignée pour le type de produit VoxCore-Election)
        if (operation.versionApplicative && operation.versionApplicative.typeProduit === TypeProduit.VOXCORE_ELECTION
                && isVersionApplicativeSansComposants(operation.versionApplicative)
                && operation.plateforme.nom === NomPlateforme.H1) {
            setIsErrorVersionSpecifique(true);
            setMessageErrorVersionSpecifique("Incohérence sur le choix de la version : sur une plateforme H1, seules les versions standards sont autorisées. Merci de changer soit la version, soit la plateforme cible.");
            return;
        }

        if (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA) {
            onUpdateOpSaturneMaileva(
                {
                    nomClient: operation.nomClient,
                    portefeuilleProjet: operation.portefeuilleProjet,
                    versionApplicative: operation.versionApplicative,
                    salesforceId: operation.salesforceId,
                    description: (operation.description.trim() === "") ? null : operation.description.trim()       // .trim() : // enlève les espaces au debut et à la fin de la chaine de caractères
                },
                {
                    urlPartieClient: paramsOperation.urlPartieClient,
                    isModeTest: paramsOperation.isModeTest,
                    optionEnvoi: paramsOperation.optionEnvoi,
                    optionPapier: paramsOperation.optionPapier
                }
            );
        }
        if (operation.type === TypeOperation.VOXCORE_ELECTION || operation.type === TypeOperation.VOXCORE_AG) {
            if (operation.dateInstallation) {
                onUpdateOp(
                    {
                        nomClient: operation.nomClient,
                        portefeuilleProjet: operation.portefeuilleProjet,
                        dateInstallation: operation.dateInstallation,
                        versionApplicative: operation.versionApplicative,
                        salesforceId: operation.salesforceId,
                        plateforme: operation.plateforme,
                        description: (operation.description.trim() === "") ? null : operation.description.trim()
                    },
                    {
                        urlPartieClient: paramsOperation.urlPartieClient,
                        urlDomaine: paramsOperation.urlDomaine,
                        isModeTest: paramsOperation.isModeTest,
                    }
                );
            } else {
                setIsErrorDateInstallation(true);
            }
        }
        if (operation.type === TypeOperation.ACTIONNARIAT) {
            if (operation.dateInstallation) {
                if (datesActionnariat.dateOuverture) {
                    if (datesActionnariat.dateFermeture) {
                        onUpdateOpActionnariat(
                            {
                                nomClient: operation.nomClient,
                                portefeuilleProjet: operation.portefeuilleProjet,
                                dateInstallation: operation.dateInstallation,
                                versionApplicative: operation.versionApplicative,
                                salesforceId: operation.salesforceId,
                                plateforme: operation.plateforme,
                                description: (operation.description.trim() === "") ? null : operation.description.trim()
                            },
                            {urlPartieClient: paramsOperation.urlPartieClient},
                            {
                                dateOuverture: datesActionnariat.dateOuverture,
                                dateFermeture: datesActionnariat.dateFermeture
                            }
                        );
                    } else {
                        setIsErrorDateFermeture(true);
                    }
                } else {
                    setIsErrorDateOuverture(true);
                }
            } else {
                setIsErrorDateInstallation(true);
            }
        }
    }


    const titrePageModification = useMemo(() => {
        if (operationBdd) return `Modification d'une opération ${operationBdd.type}`;
        else return "";
    }, [operationBdd]);


    /**
     * Méthode pour l'affichage des messages d'erreur
     */
    let affichageMessageErreur;
    if (isErrorVersionSpecifique) {
        affichageMessageErreur = (
            <VoxAlert severity={"error"}>
                {messageErrorVersionSpecifique}
            </VoxAlert>
        )
    }
    else if (isUpdateError) {
        affichageMessageErreur = (
            <VoxAlert severity={"error"}>
                {(messageErrorUrlDoublon !== "")
                    ? `${messageErrorUrlDoublon}`
                    : "Une erreur est survenue lors de l'enregistrement des modifications apportées à l'opération."
                }
            </VoxAlert>
        )
    }


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8} lg={5}>
                <RestrictedRouteOperation
                    groupes={[ NomGroupe.ADMINISTRATEUR, NomGroupe.SUPPORT_SI_INFRA, NomGroupe.SUPPORT_SI_DEV ]}
                    etatsDemande={[EtatDemande.DECLARE]}
                    typesOperation={props.typesOperation}
                    operation={operationVerification}
                    isLoadingOperation={isLoadingOperation}
                    isLoadingOperationError={isLoadError}
                >

                    <VoxCard title={titrePageModification}>

                            {/* Affichage de l'erreur d'enregistrement si il y en a une */}
                            {affichageMessageErreur}

                            {(operationBdd && paramsOperationBdd && datesActionnariatBdd) &&
                                // Formulaire
                                <OperationsForm
                                    typeFormulaire={"modification"}

                                    operation={operationBdd}
                                    paramsOperation={paramsOperationBdd}
                                    datesActionnariat={datesActionnariatBdd}

                                    isAffichageDateInstallation={isAffichageDateInstallation}

                                    onSubmit={handleOnUpdate}
                                    isLoadingSubmit={isUpdateOperation}

                                    isErrorIdSalesforce={isErrorIdSalesforce}
                                    isErrorDateInstallation={isErrorDateInstallation}
                                    isErrorDateOuverture={isErrorDateOuverture}
                                    isErrorDateFermeture={isErrorDateFermeture}

                                    messageError={messageError}
                                    messageErrorIdSalesforce={messageErrorIdSalesforce}
                                />
                            }
                            {
                                isUpdateOperation ? <VoxLoader isBackgroundColor={true} /> : null
                            }
                    </VoxCard>

                </RestrictedRouteOperation>
            </Grid>
        </Grid>
    )
}