import React from "react";
import "./LoaderOnStartSurvox.css";
import { ReactComponent as LogoVoxaly } from '../../../../assets/logo_VOXALY.svg';


export default function LoaderOnStartSurvox() {
    return (
        <div className="loader-container">
            <div className="card card-container m-2">
                <div className="card-body p-5 d-flex flex-column justify-content-center align-items-center">
                    <LogoVoxaly className="logo-voxaly"/>
                    <h2 className="text-center mb-4 text-survox">Chargement de Survox</h2>
                    <div className="spinner-border spinner-color" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}