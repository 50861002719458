import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import './operationsListe.css'
import {AuthContext} from "../../contexts/authentication/authentication.context";
import operationService from "../../services/survox-back/operation/operation.service";
import {Operation} from "../../models/operation/operation.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import {CellProps, Column} from "react-table";
import {TypeOperation} from "../../models/operation/typeOperation.enum";
import {EtatDemande} from "../../models/operation/etatDemande.enum";
import ModaleConfirmationSuppressionOuAnnulation from "../modals/modaleConfirmationSuppressionOuAnnulation";
import {NomGroupe} from "../../models/utilisateur/nomGroupe.enum";
import LienInstallerMaintenant from "./lienInstallerMaintenant";
import {ParametresContext} from "../../contexts/parametrage/parametres.context";
import ModaleConfirmationInstallationOVH from "../modals/modaleConfirmationInstallationOVH";
import {affichageLibelleEtatDemande} from "../../utils/string.utils";
import ModaleDemandeSuppressionOuArchivage from "../modals/modaleDemandeSuppressionOuArchivage";
import {Hebergeur} from "../../models/operation/hebergeur.enum";
import VoxButton from "../genericComponentsUI/button/voxButton";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import {Box, Grid} from "@mui/material";
import VoxTable from "../genericComponentsUI/table/voxTable";
import {VoxAlert} from "../genericComponentsUI/alerte/voxAlert";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";

/* Type des opérations liées à l'utilisateur courant et récupérées depuis survox-back
 * -> La propriété isInstalling est utilisée pour savoir si l'utilisateur a déclenché l'installation
 */
type OperationUtilisateur = Operation & { isInstalling: boolean };

// Type des objets utilisés dans le tableau correspondant aux informations des opérations à afficher
type OperationTableau = {
    id: number;
    nomClient: string;
    url: string;
    etatDemande: EtatDemande;
    hebergeur: Hebergeur;
    loadingInstallation: boolean;
    dateCreation: Date;
    type: TypeOperation;
}


export default function OperationsListe(props: { typeOperation: TypeOperation | string }) {

    // Utilisation d'une ref pour connaître l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // Récupération de l'utilisateur connecté à l'application
    const authContext = useContext(AuthContext);
    const utilisateurAuth = authContext.utilisateur;

    const parametresContext = useContext(ParametresContext);

    const [operations, setOperations] = useState<OperationUtilisateur[]>([]);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuppressionOuAnnulationModale, setShowSuppressionOuAnnulationModale] = useState(false);
    const [selectedOperation, setSelectedOperation] = useState<OperationUtilisateur>();
    const [isAllowedDeleteOperation, setIsAllowedDeleteOperation] = useState(true);
    const [isLoadingAllowedDeleteOperation, setIsLoadingAllowedDeleteOperation] = useState(false);
    const [isLoadingDeleteOperation, setIsLoadingDeleteOperation] = useState(false);
    const [isSuccesDeleteOperation, setIsSuccesDeleteOperation] = useState(false);
    const [isErrorDeleteOperation, setIsErrorDeleteOperation] = useState(false);
    const [successMessageSuppression, setSuccessMessageSuppression] = useState<string>("");
    const [errorMessageSuppression, setErrorMessageSuppression] = useState<string>("");

    const [selectedOperationId, setSelectedOperationId] = useState<number | undefined>();
    const [showConfirmationOVHModale, setShowConfirmationOVHModale] = useState(false);
    const [isInstalling, setIsInstalling] = useState(false);

    // Gestion du processus de la demande de suppression d'une opération par un chef de projet vote
    const [showDemandeSuppressionOuArchivageModale, setShowDemandeSuppressionOuArchivageModale] = useState<boolean>(false);
    const [operationDemandeSuppressionOuArchivage, setOperationDemandeSuppressionOuArchivage] = useState<OperationUtilisateur>();
    const [isLoadingDemandeSuppressionOuArchivage, setIsLoadingDemandeSuppressionOuArchivage] = useState<boolean>(false);
    const [isErrorDemandeSuppressionOuArchivage, setIsErrorDemandeSuppressionOuArchivage] = useState<boolean>(false);
    const [errorMessageDemandeSuppressionOuArchivage, setErrorMessageDemandeSuppressionOuArchivage] = useState<string>("");
    const [isSuccessDemandeSuppressionOuArchivage, setIsSuccessDemandeSuppressionOuArchivage] = useState<boolean>(false);
    const [successMessageDemandeSuppressionOuArchivage, setSuccessMessageDemandeSuppressionOuArchivage] = useState<string>("");

    const chargerOperations = useCallback((controller?: AbortController) => {
        /**
         * Charger les opérations Saturne de l'utilisateur courant
         */
        if (props.typeOperation === TypeOperation.SATURNE) {
            setIsLoading(true);
            operationService.getAllOperationsSaturneByCurrentUtilisateur(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        const operationsSaturneDeUtilisateur: OperationUtilisateur[] = response.data.map((operation: Operation) => {
                            return {...operation, isInstalling: false}
                        });
                        setOperations(operationsSaturneDeUtilisateur);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }

        /**
         * Charger les opérations MailEva de l'utilisateur courant
         */
        if (props.typeOperation === TypeOperation.MAILEVA) {
            setIsLoading(true);
            operationService.getAllOperationsMailevaByCurrentUtilisateur(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        const operationsMailevaDeUtilisateur: OperationUtilisateur[] = response.data.map((operation: Operation) => {
                            return {...operation, isInstalling: false, isLoadingActions: false}
                        });
                        setOperations(operationsMailevaDeUtilisateur);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }

        /**
         * Charger les opérations de type VoxCore-Election, VoxCore-AG et Actionnariat de l'utilisateur courant
         */
        if (props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat") {
            setIsLoading(true);
            operationService.getAllOperationsVoxCoreActionnariatByCurrentUtilisateur(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        const operationsVoxCoreActionnariatDeUtilisateur: OperationUtilisateur[] = response.data.map((operation: Operation) => {
                            return {...operation, isInstalling: false, isLoadingActions: false}
                        });
                        setOperations(operationsVoxCoreActionnariatDeUtilisateur);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }
    }, [props.typeOperation]);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        chargerOperations(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [chargerOperations]);

    /**
     * Ouverture d'une popup de confirmation d'installation chez OVH
     */
    const handleOperationConfirmationOVH = useCallback((operationId: number) => {
        setSelectedOperationId(operationId);
        setShowConfirmationOVHModale(true);
    }, []);

    /**
     * Redirige vers la page de création d'une opération
     */
    const handleOnCreationOperation = () => {
        navigate(`./../declarer_operation`);
    };

    /**
     * Se déclenche lorsque l'utilisateur clique sur le pictogramme de suppression ou d'annulation d'une opération
     * et ouvre la modale de confirmation
     */
    const handleOperationSuppressionOuAnnulation = useCallback((operationId: number) => {
        const operation = operations.find(op => op.id === operationId);
        if (operation) {
            setShowSuppressionOuAnnulationModale(true);
            setSelectedOperation(operation);
            if (operation.etatDemande !== EtatDemande.DECLARE && !isLoadingAllowedDeleteOperation) {
                setIsLoadingAllowedDeleteOperation(true);
                if (props.typeOperation === TypeOperation.SATURNE) {
                    operationService.allowedDeleteOperationSaturne(operation.id)
                        .then((response) => {
                            if (response.data.suppressionOk) {
                                setIsAllowedDeleteOperation(true);
                            } else {
                                setIsAllowedDeleteOperation(false);
                            }
                        })
                        .catch(() => setIsAllowedDeleteOperation(false))
                        .finally(() => setIsLoadingAllowedDeleteOperation(false))
                } else if (props.typeOperation === TypeOperation.MAILEVA) {
                    operationService.allowedDeleteOperationMaileva(operation.id)
                        .then((response) => {
                            if (response.data.suppressionOk) {
                                setIsAllowedDeleteOperation(true);
                            } else {
                                setIsAllowedDeleteOperation(false);
                            }
                        })
                        .catch(() => setIsAllowedDeleteOperation(false))
                        .finally(() => setIsLoadingAllowedDeleteOperation(false))
                } else if (props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat") {
                    operationService.allowedDeleteOperation(operation.id)
                        .then((response) => {
                            if (response.data.suppressionOk) {
                                setIsAllowedDeleteOperation(true);
                            } else {
                                setIsAllowedDeleteOperation(false);
                            }
                        })
                        .catch(() => setIsAllowedDeleteOperation(false))
                        .finally(() => setIsLoadingAllowedDeleteOperation(false))
                }
            }
        }
    }, [isLoadingAllowedDeleteOperation, props.typeOperation, operations]);

    /**
     * Se déclenche à la confirmation de la suppression ou de l'annulation d'une opération et supprimer l'opération
     */
    const operationSuppressionOuAnnulation = useCallback(() => {
        if (selectedOperation) {
            setIsLoadingDeleteOperation(true);
            setIsSuccesDeleteOperation(false);
            setIsErrorDeleteOperation(false);
            setSuccessMessageSuppression("");
            setErrorMessageSuppression("");

            const successMessage = `L'opération "${selectedOperation.nom}" a bien été supprimée.`;
            const errorMessage =  `Une erreur est survenue lors de la suppression de l'opération "${selectedOperation.nom}".`;

            if (props.typeOperation === TypeOperation.SATURNE) {
                operationService.deleteOperationSaturne(selectedOperation.id)
                    .then((response) => {
                        if (response.status === 200 && !response.data.error) {
                            setIsSuccesDeleteOperation(true);
                            setSuccessMessageSuppression(successMessage);
                        } else {
                            setIsErrorDeleteOperation(true);
                            setErrorMessageSuppression(errorMessage);
                        }
                    })
                    .catch((err) => {
                        setIsSuccesDeleteOperation(false);
                        setIsErrorDeleteOperation(true);
                        if (err.response.status === 400 && err.response.data.message.includes('[Suppression]')) {
                            setErrorMessageSuppression(err.response.data.message.split('[Suppression] ')[1]);
                        } else {
                            setErrorMessageSuppression(errorMessage);
                        }
                    })
                    .finally(() => {
                        setIsLoadingDeleteOperation(false);
                        setShowSuppressionOuAnnulationModale(false);
                        setSelectedOperation(undefined);
                        chargerOperations();
                    })
            } else if (props.typeOperation === TypeOperation.MAILEVA) {
                operationService.deleteOperationMaileva(selectedOperation.id)
                    .then((response) => {
                        if (response.status === 200 && !response.data.error) {
                            setIsSuccesDeleteOperation(true);
                            setSuccessMessageSuppression(successMessage);
                        } else {
                            setIsErrorDeleteOperation(true);
                            setErrorMessageSuppression(errorMessage);
                        }
                    })
                    .catch((error) => {
                        setIsSuccesDeleteOperation(false);
                        setIsErrorDeleteOperation(true);
                        if (error.response.status === 400 && error.response.data.message.includes('[Suppression]')) {
                            setErrorMessageSuppression(error.response.data.message.split('[Suppression] ')[1]);
                        } else {
                            setErrorMessageSuppression(errorMessage);
                        }
                    })
                    .finally(() => {
                        setIsLoadingDeleteOperation(false);
                        setShowSuppressionOuAnnulationModale(false);
                        setSelectedOperation(undefined);
                        chargerOperations();
                    })
            } else if (props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat") {
                operationService.deleteOperation(selectedOperation.id)
                    .then((response) => {
                        if (response.status === 200 && !response.data.error) {
                            setIsSuccesDeleteOperation(true);
                            setSuccessMessageSuppression(successMessage);
                        } else {
                            setIsErrorDeleteOperation(true);
                            setErrorMessageSuppression(errorMessage);
                        }
                    })
                    .catch((e) => {
                        setIsSuccesDeleteOperation(false);
                        setIsErrorDeleteOperation(true);
                        if (e.response.status === 400 && e.response.data.message.includes('[Suppression]')) {
                            setErrorMessageSuppression(e.response.data.message.split('[Suppression] ')[1]);
                        } else {
                            setErrorMessageSuppression(errorMessage);
                        }
                    })
                    .finally(() => {
                        setIsLoadingDeleteOperation(false);
                        setShowSuppressionOuAnnulationModale(false);
                        setSelectedOperation(undefined);
                        chargerOperations();
                    })
            }
        }
    }, [selectedOperation, props.typeOperation, chargerOperations]);

    /**
     * Callback qui s'exécute lorsqu'un utilisateur ayant le rôle de chef de projet vote
     * clique sur le PICTO de suppression d'une opération et affiche la modale de choix de
     * processus de suppression d'une opération à l'état en production
     */
    const handleDemandeSuppressionOuArchivage = useCallback((operationId: number) => {
        const operation = operations.find(op => op.id === operationId);
        setShowDemandeSuppressionOuArchivageModale(true);
        setOperationDemandeSuppressionOuArchivage(operation);
    }, [operations]);

    /**
     * Callback qui s'exécute lorsqu'un utilisateur ayant le rôle de chef de projet vote
     * clique sur le bouton demande d'ARCHIVAGE de l'opération dans la modale de choix de
     * processus de suppression d'une opération à l'état en production
     */
    const handleDemandeArchivageOperationProduction = useCallback(() => {
        if (operationDemandeSuppressionOuArchivage) {
            setIsLoadingDemandeSuppressionOuArchivage(true);
            setIsErrorDemandeSuppressionOuArchivage(false);
            setErrorMessageDemandeSuppressionOuArchivage("");
            setIsSuccessDemandeSuppressionOuArchivage(false);
            setSuccessMessageDemandeSuppressionOuArchivage("");
            operationService.demanderArchivageOperationProduction(operationDemandeSuppressionOuArchivage.id)
                .then((res) => {
                    if (isMountedRef.current && res.status === 200) {
                        setIsSuccessDemandeSuppressionOuArchivage(true);
                        setSuccessMessageDemandeSuppressionOuArchivage(
                            `La demande d'archivage pour l'opération "${operationDemandeSuppressionOuArchivage.nom}" a bien été prise en compte.`
                        );
                    }
                })
                .catch((err) => {
                    if (isMountedRef.current) {
                        setIsErrorDemandeSuppressionOuArchivage(true);
                        if (err.response?.status === 400 && err.response?.data?.source === "[Demande Archivage]") {
                            setErrorMessageDemandeSuppressionOuArchivage(err.response?.data?.message);
                        } else {
                            setErrorMessageDemandeSuppressionOuArchivage(
                                `Une erreur est survenue lors de la demande d'archivage de l'opération "${operationDemandeSuppressionOuArchivage.nom}"`
                            );
                        }
                    }
                })
                .finally(() => {
                    if (isMountedRef.current) {
                        setIsLoadingDemandeSuppressionOuArchivage(false);
                        setShowDemandeSuppressionOuArchivageModale(false);
                        setOperationDemandeSuppressionOuArchivage(undefined);
                        chargerOperations();
                    }
                })
        }
    }, [operationDemandeSuppressionOuArchivage, chargerOperations]);

    /**
     * Callback qui s'exécute lorsqu'un utilisateur ayant le rôle de chef de projet vote
     * clique sur le bouton demande de SUPPRESSION de l'opération dans la modale de choix de
     * processus de suppression d'une opération à l'état en production
     */
    const handleDemandeSuppressionOperationProduction = useCallback(() => {
        if (operationDemandeSuppressionOuArchivage) {
            setIsLoadingDemandeSuppressionOuArchivage(true);
            setIsErrorDemandeSuppressionOuArchivage(false);
            setErrorMessageDemandeSuppressionOuArchivage("");
            setIsSuccessDemandeSuppressionOuArchivage(false);
            setSuccessMessageDemandeSuppressionOuArchivage("");
            operationService.demanderSuppressionOperationProduction(operationDemandeSuppressionOuArchivage.id)
                .then((res) => {
                    if (isMountedRef.current && res.status === 200) {
                        setIsSuccessDemandeSuppressionOuArchivage(true);
                        setSuccessMessageDemandeSuppressionOuArchivage(
                            `La demande de suppression pour l'opération "${operationDemandeSuppressionOuArchivage.nom}" a bien été prise en compte.`
                        );
                    }
                })
                .catch((err) => {
                    if (isMountedRef.current) {
                        setIsErrorDemandeSuppressionOuArchivage(true);
                        if (err.response?.status === 400 && err.response?.data?.source === "[Demande Suppression]") {
                            setErrorMessageDemandeSuppressionOuArchivage(err.response?.data?.message);
                        } else {
                            setErrorMessageDemandeSuppressionOuArchivage(
                                `Une erreur est survenue lors de la demande de suppression de l'opération "${operationDemandeSuppressionOuArchivage.nom}"`
                            );
                        }
                    }
                })
                .finally(() => {
                    if (isMountedRef.current) {
                        setIsLoadingDemandeSuppressionOuArchivage(false);
                        setShowDemandeSuppressionOuArchivageModale(false);
                        setOperationDemandeSuppressionOuArchivage(undefined);
                        chargerOperations();
                    }
                })
        }
    }, [operationDemandeSuppressionOuArchivage, chargerOperations]);


    /**
     * Méthode qui permet de changer la valeur de l'attribut isInstalling pour une opération donnée
     * @param listeOperations un tableau d'opérations
     * @param operationId l'Id de l'opération
     * @param value la valeur (true ou false)
     */
    const changeIsInstallingOperation = function (listeOperations: OperationUtilisateur[], operationId: number, value: boolean) {
        return listeOperations.map(operation => {
            if (operation.id === operationId) {
                operation.isInstalling = value;
            }
            return operation;
        });
    }

    /**
     * Handle qui se déclenche lorsque l'utilisateur clique sur installer maintenant
     * dans le tableau des opérations à initialiser
     */
    const handleInstallerOperation = useCallback(() => {
        if (selectedOperationId) {
            setIsInstalling(true);
            // On change la valeur de isInstalling pour l'opération concernée afin d'afficher le Loader
            setOperations(changeIsInstallingOperation(operations, selectedOperationId, true));

            operationService.installerOperation(selectedOperationId)
                .then(() => {
                    if (isMountedRef.current) {
                        setOperations(changeIsInstallingOperation(operations, selectedOperationId, false));
                        chargerOperations();
                    }
                })
                .catch((err) => {
                    if (isMountedRef.current) {
                        if (err.response.data.error === "maintenance") {
                            parametresContext.updateParametrage();
                        }
                        setOperations(changeIsInstallingOperation(operations, selectedOperationId, false));
                    }
                })
                .finally(() => {
                    setIsInstalling(false);
                    setShowConfirmationOVHModale(false);
                    setSelectedOperationId(undefined);
                })
        }
    }, [operations, chargerOperations, parametresContext, selectedOperationId]);

    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationTableau[] = useMemo(() => {
        return operations.map(
            (operation: OperationUtilisateur) => ({
                id: operation.id,
                nomClient: operation.nomClient,
                url: operation.url,
                etatDemande: operation.etatDemande,
                hebergeur: operation.hebergeur,
                loadingInstallation: operation.isInstalling,
                dateCreation: operation.dateCreation,
                type: operation.type
            })
        ).sort((a, b) => new Date(a.dateCreation).getTime() - new Date(b.dateCreation).getDate());
    }, [operations]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationTableau>[] = useMemo(() => [
            {
                Header: 'Nom du client',
                accessor: 'nomClient',
                minWidth: 120,
                Cell: (props: CellProps<OperationTableau>) => {
                    return (
                        <VoxLinkOperation href={`/operation/detail/${props.row.original.id}`}>
                            {props.value}
                        </VoxLinkOperation>
                    )
                }
            },
            {
                Header: (props.typeOperation === TypeOperation.SATURNE || props.typeOperation === TypeOperation.MAILEVA) ? 'Site de vote' : 'Site',
                accessor: 'url',
                minWidth: 120,
                Cell: (props: CellProps<OperationTableau>) => <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
            },
            {
                Header: "État d'installation",
                accessor: 'etatDemande',
                minWidth: 120,
                Cell: (propsCell: CellProps<OperationTableau>) => {
                    let cellContent;
                    if (propsCell.row.original.loadingInstallation) {
                        cellContent = (
                            <Box position={"relative"}>
                                <VoxLoader size={20} />
                            </Box>
                        );
                    } else if (propsCell.row.original.etatDemande === "declare") {
                        const type = propsCell.row.original.type;
                        // On regarde que l'on se trouve sur la page "Mes Opérations Saturne" ou "Mes opérations MailEva"
                        // ET que l'opération est d'un type particulier pour afficher le lien "Installer maintenant"
                        if (
                            (
                                props.typeOperation === TypeOperation.SATURNE
                                || props.typeOperation === TypeOperation.MAILEVA
                            ) && (
                                type === TypeOperation.SATURNE
                                || type === TypeOperation.MAILEVA
                                || type === TypeOperation.VOXCORE_ELECTION
                                || type === TypeOperation.VOXCORE_AG
                            )
                        ) {
                            cellContent = <LienInstallerMaintenant
                                idOperation={propsCell.row.original.id}
                                typeOperation={propsCell.row.original.type}
                                handleOperationConfirmationOVH={handleOperationConfirmationOVH} // Ouverture popup de confirmation
                            />
                        } else {
                            cellContent = affichageLibelleEtatDemande(propsCell.row.original.etatDemande);
                        }
                    } else {
                        // variable permettant de savoir si l'opération est bloquée dans une étape du workflow de déploiement
                        const isInstallationAnsibleBlockedOrFailed = propsCell.row.original.etatDemande === EtatDemande.ERREUR_INSTALLATION_ANSIBLE;
                        // Si l'opération est considérée comme bloquée, ajout d'un picto et libellé en rouge (afin de signaler le problème)
                        cellContent = (
                            <Box component={"span"} sx={{color: isInstallationAnsibleBlockedOrFailed ? 'cobalt.redText' : 'inherit'}}>
                                {
                                    isInstallationAnsibleBlockedOrFailed && (
                                        <FontAwesomeIcon
                                            icon={'exclamation-triangle'}
                                            className={"margin-right-demi"}
                                            title={"Une erreur est survenue lors de l'installation. Analyse à effectuer."}
                                        />
                                    )
                                }
                                { affichageLibelleEtatDemande(propsCell.row.original.etatDemande) }
                            </Box>
                        );
                    }
                    return (
                        <>{cellContent}</>
                    );
                }
            },
            {
                Header: 'Actions à effectuer',
                id: 'actions',
                disableFilters: true,
                disableSortBy: true,
                Cell: (props: CellProps<OperationTableau>) => {

                    let pictogrammeSuppression;
                    if (
                        (
                            /*
                             * Dans le cas des opérations Saturne et MailEva, le Chef de Projet peut déclencher
                             * la suppression de son opération à n'importe quel état de l'opération sauf quand
                             * elle est déjà supprimée OU en cours de suppression
                             */
                            (props.row.original.type === TypeOperation.SATURNE ||props.row.original.type === TypeOperation.MAILEVA) &&
                            !(props.row.original.etatDemande === EtatDemande.A_SUPPRIMER || props.row.original.etatDemande === EtatDemande.SUPPRIME)
                        )
                        ||
                        (
                            /*
                             * Dans le cas des opérations VoxCore-Election / VoxCore-AG / Actionnariat, seuls
                             * les utilisateurs ayant le rôle Administrateur ou Support SI Infra peuvent déclencher
                             * la suppression des opérations à n'importe quel état sauf quand elle est supprimée ou
                             * en cours de suppression
                             */
                            (props.row.original.type === TypeOperation.VOXCORE_ELECTION || props.row.original.type === TypeOperation.VOXCORE_AG || props.row.original.type === TypeOperation.ACTIONNARIAT)
                            &&
                            (
                                (utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA)
                                && !(props.row.original.etatDemande === EtatDemande.A_SUPPRIMER || props.row.original.etatDemande === EtatDemande.SUPPRIME)
                            )
                        )
                    ) {
                        pictogrammeSuppression = (
                            <VoxIconAction
                                tooltip={"Supprimer l'opération"}
                                icon={"trash-alt"}
                                onClick={() => handleOperationSuppressionOuAnnulation(props.row.original.id)}
                            />
                        )
                    } else if (
                        /*
                         * Dans le cas des opérations VoxCore-Election / VoxCore-AG / Actionnariat hébergées chez
                         * Docaposte, le chef de projet vote peut choisir un processus de suppression de son opération
                         * lorsque celle-ci est à l'état En Production
                         * (Ceci inclus les opérations dites Optivox hébergées chez Docaposte)
                         */
                        (
                            props.row.original.type === TypeOperation.VOXCORE_ELECTION
                            || props.row.original.type === TypeOperation.VOXCORE_AG
                            || props.row.original.type === TypeOperation.ACTIONNARIAT
                        ) &&
                        (
                            utilisateurAuth.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
                            || utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE
                        )
                        && props.row.original.etatDemande === EtatDemande.EN_PRODUCTION
                    ) {
                        pictogrammeSuppression = (
                            <VoxIconAction
                                tooltip={"Demander la suppression ou l'archivage"}
                                icon={"trash-alt"}
                                onClick={() => handleDemandeSuppressionOuArchivage(props.row.original.id)}
                            />
                        )
                    }
                    return (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>

                                {/* Oeil → Détail de l'opération */}
                                <VoxIconAction
                                    tooltip={"Consulter la fiche de l'opération"}
                                    icon={"eye"}
                                    onClick={() => navigate(`../../operation/detail/${props.row.original.id}`)}
                                />

                                {/* Crayon → Modification de l'opération */}
                                {(props.row.original.etatDemande === EtatDemande.DECLARE) &&
                                    <VoxIconAction
                                        tooltip={"Modifier l'opération"}
                                        icon={"edit"}
                                        onClick={() => navigate(`modification/${props.row.original.id}`)}
                                    />
                                }

                                {/* Corbeille → Suppression de l'opération */}
                                {pictogrammeSuppression}

                        </Box>
                    )
                },
                minWidth: 60,
                maxWidth: 100,
            },
        ], [
            handleOperationConfirmationOVH,
            handleOperationSuppressionOuAnnulation,
            handleDemandeSuppressionOuArchivage,
            props.typeOperation,
            utilisateurAuth.groupe.nom,
            navigate
        ]
    );

    /**
     * Récupération du texte du bouton de création en fonction du type : Saturne / Maileva
     */
    const titreBoutonNouvelleOperation = useMemo(() => {
        if (props.typeOperation === TypeOperation.SATURNE) {
            return "Nouvelle opération Saturne";
        } else if (props.typeOperation === TypeOperation.MAILEVA) {
            return "Nouvelle opération MailEva";
        } else if (props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat") {
            return "Nouvelle opération";
        }
    }, [props.typeOperation]);

    /**
     * Le message d'alerte à afficher pour confirmer la suppression ou informer l'utilisateur qu'il y a eu une erreur
     */
    const messageAlerte = useMemo(() => {
        if (isSuccesDeleteOperation) {
            return (
                <VoxAlert severity={"success"} onClose={() => setIsSuccesDeleteOperation(false)}>
                    {successMessageSuppression}
                </VoxAlert>
            )
        } else if (isErrorDeleteOperation) {
            return (
                <VoxAlert severity={"error"} onClose={() => setIsErrorDeleteOperation(false)}>
                    {errorMessageSuppression}
                </VoxAlert>
            )
        }
    }, [isSuccesDeleteOperation, successMessageSuppression, isErrorDeleteOperation, errorMessageSuppression]);

    /**
     * Message d'information / d'erreur concernant la demande de suppression ou d'archivage d'une opération en
     * production par un utilisateur ayant le rôle chef de projet vote
     */
    const messageDemandeSuppressionOuArchivage = useMemo(() => {
        if (isSuccessDemandeSuppressionOuArchivage) {
            return (
                <VoxAlert severity={"success"}
                          onClose={() => {
                              setIsSuccessDemandeSuppressionOuArchivage(false);
                              setSuccessMessageDemandeSuppressionOuArchivage("");
                          }}
                >
                    {successMessageDemandeSuppressionOuArchivage}
                </VoxAlert>
            )
        } else if (isErrorDemandeSuppressionOuArchivage) {
            return (
                <VoxAlert severity={"error"}
                          onClose={() => {
                              setIsErrorDemandeSuppressionOuArchivage(false);
                              setErrorMessageDemandeSuppressionOuArchivage("");
                          }}
                >
                    {errorMessageDemandeSuppressionOuArchivage}
                </VoxAlert>
            )
        }
    }, [
        isSuccessDemandeSuppressionOuArchivage,
        successMessageDemandeSuppressionOuArchivage,
        isErrorDemandeSuppressionOuArchivage,
        errorMessageDemandeSuppressionOuArchivage
    ]);

    return (
        <>
            {messageAlerte}
            {messageDemandeSuppressionOuArchivage}
            {isLoading ? <VoxLoader/> :
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12}>

                        {/* Affichage du bouton "Nouvelle opération" pour les utilisateurs autorisés à faire cette action (sécurité supplémentaire)*/}
                        {(utilisateurAuth && utilisateurAuth.groupe &&
                                (
                                    (
                                        (props.typeOperation === TypeOperation.SATURNE || props.typeOperation === TypeOperation.MAILEVA) &&
                                        (utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                                            utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA ||
                                            utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV ||
                                            utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE)
                                    ) ||
                                    (
                                        (props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat") &&
                                        (utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                                            utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA ||
                                            utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV ||
                                            utilisateurAuth.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE ||
                                            utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_VOTE)
                                    ))) &&
                            <VoxButton
                                id={"btn-nouvelle-operation"}
                                startIcon={<FontAwesomeIcon icon={"plus"}/>}
                                onClick={handleOnCreationOperation}
                            >
                                {titreBoutonNouvelleOperation}
                            </VoxButton>
                        }

                        <VoxTable
                            id={"table-mes-operations"}
                            data={rows}
                            columns={columns}
                        />

                        <ModaleConfirmationSuppressionOuAnnulation
                            show={showSuppressionOuAnnulationModale}
                            operation={selectedOperation}
                            isAllowedDeleteOperation={isAllowedDeleteOperation}
                            handleClose={() => {
                                setShowSuppressionOuAnnulationModale(false);
                                setSelectedOperation(undefined);
                            }}
                            handleSubmit={operationSuppressionOuAnnulation}
                            loadingDeleteOperation={isLoadingDeleteOperation}
                            loadingAllowedDeleteOperation={isLoadingAllowedDeleteOperation}
                        />

                        <ModaleDemandeSuppressionOuArchivage
                            show={showDemandeSuppressionOuArchivageModale}
                            isLoading={isLoadingDemandeSuppressionOuArchivage}
                            handleClose={() => {
                                setShowDemandeSuppressionOuArchivageModale(false);
                                setOperationDemandeSuppressionOuArchivage(undefined);
                            }}
                            operation={operationDemandeSuppressionOuArchivage}
                            handleDemandeSuppression={handleDemandeSuppressionOperationProduction}
                            handleDemandeArchivage={handleDemandeArchivageOperationProduction}
                        />

                        <ModaleConfirmationInstallationOVH
                            show={showConfirmationOVHModale}
                            handleClose={() => {
                                setShowConfirmationOVHModale(false);
                                setSelectedOperationId(undefined);
                            }}
                            handleSubmit={handleInstallerOperation}
                            isInstalling={isInstalling}
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}