import { LoginUtilisateurDto } from "./utilisateur/dtos/request/loginUtilisateur.dto";
import axios_instance from "./axios-client"

const authService =  {

    login: (loginUtilisateurDto: LoginUtilisateurDto) => {
        return axios_instance.post("/login", loginUtilisateurDto);
    },

    logout: () => {
        return axios_instance.post("/logout");
    }
}

export default authService;