import {Box, Grid, Typography} from "@mui/material";
import {VoxTextField} from "../../../components/genericComponentsUI/input/voxTextField";
import {VoxLegendChampsObligatoires} from "../../../components/genericComponentsUI/label/voxLegendChampsObligatoires";
import {VoxLabel} from "../../../components/genericComponentsUI/input/voxLabel";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import React, {ChangeEvent, useState} from "react";
import {VersionApplicative} from "../../../models/versionApplicative/versionApplicative.model";
import {
    CreateVersionApplicativeDto
} from "../../../services/survox-back/versionApplicative/dtos/request/createVersionApplicative.dto";
import versionApplicativeService from "../../../services/survox-back/versionApplicative/version-applicative.service";
import {useNavigate} from "react-router-dom";
import {TypeProduit} from "../../../models/versionApplicative/typeProduit.enum";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";

type OtherVersionsFormProps = {
    typeProduit: TypeProduit
}


export default function OtherVersionsForm(props: Readonly<OtherVersionsFormProps>) {

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");
    const [versionApplicative, setVersionApplicative] = useState<Partial<VersionApplicative>>({ version: "", typeProduit: props.typeProduit });
    const navigate = useNavigate();

    /**
     * Mise à jour du champ "Numéro de version" lorsque l'on renseigne l'input du formulaire
     * @param event
     */
    const handleOnChangeNumeroVersion = (event: ChangeEvent<HTMLInputElement>) => {
        setVersionApplicative( (prevState: Partial<VersionApplicative>) => ({...prevState, version: event.target.value}));
    }

    /**
     * Création d'une nouvelle version applicative
     * @param createVersionApplicative  champs nécessaires à la création d'une version applicative
     */
    const onCreateVersion = (createVersionApplicative: CreateVersionApplicativeDto) => {
        setIsLoading(true);
        setIsError(false);
        versionApplicativeService.createVersionApplicative(createVersionApplicative)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false);
                    setIsError(false);
                    navigate(`/administration/versions_produits?type=${versionApplicative.typeProduit}`);
                }
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
                setMessageError("Une erreur est survenue lors de la création de la version applicative.")
            })
    }

    /**
     * Gère l'événement de l'envoi du formulaire et déclenche la création de la version applicative
     * @param event
     */
    const handleOnCreate = (event: any) => {
        event.preventDefault();
        if (versionApplicative.typeProduit !== undefined && versionApplicative.version !== undefined && versionApplicative.version !== "") {
            onCreateVersion({
                version: versionApplicative.version,
                typeProduit: versionApplicative.typeProduit,
            });
        } else {
            setIsError(true);
            setMessageError("Les champs du formulaire sont obligatoires.");
        }
    }

    return (
        <VoxCard
            title={"Ajout d'une version applicative"}
        >
            {/* Affichage de l'erreur si il y en a une*/}
            {(isError) &&
                <VoxAlert severity={"error"}>
                    { messageError }
                </VoxAlert>
            }

            <form onSubmit={handleOnCreate}>
                <Grid container spacing={3} padding={2}>

                    <Grid item xs={12}>
                        <VoxLegendChampsObligatoires/>
                    </Grid>

                    <Grid item xs={12}>
                        <VoxTextField
                            id={"version"}
                            label={"Numéro de version"}
                            onChange={handleOnChangeNumeroVersion}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box>
                            <VoxLabel
                                id={"typeProduit"}
                                label={"Type de produit"}
                                required
                            />
                            <Box sx={{minHeight: 46, display: 'flex', flexDirection: "row", alignItems: "center"}}>
                                <Typography component={"p"} variant={"body2"} sx={{fontWeight: 1000}}>
                                    {versionApplicative.typeProduit}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} display={'flex'} justifyContent={"space-evenly"}>
                        <VoxButton
                            customColor={"red"}
                            variant={"outlined"}
                            className={"size-button"}
                            onClick={() => navigate(`/administration/versions_produits?type=${versionApplicative.typeProduit}`)}
                            disabled={isLoading}
                        >
                            Annuler
                        </VoxButton>
                        <VoxButton
                            customColor={"green"}
                            className={"size-button"}
                            type={"submit"}
                            disabled={isLoading}
                        >
                            Valider
                        </VoxButton>
                    </Grid>
                </Grid>
            </form>
            {
                isLoading ? <VoxLoader isBackgroundColor={true} /> : null
            }
        </VoxCard>
    )
}