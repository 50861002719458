import React from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min';
import './App.css';
import 'intro.js/introjs.css'
import {Navigate, Route, Routes} from "react-router-dom";
import LoginView from "./views/authentification/login/loginView";
import Dashboard from "./views/dashboard/dashboard";
import UtilisateurReinitialisationMdpView from './views/authentification/utilisateurReinitialisationMdpView';
import AuthProvider from "./contexts/authentication/authentication.provider";
import AuthenticatedRoute from "./components/routes/authenticatedRoute";
import Navbar from "./components/layouts/navbar/navbar";
import Profil from "./views/gestionCompteUtilisateur/profil/profil"
import RestrictedRoute from "./components/routes/restrictedRoute";
import {NomGroupe} from "./models/utilisateur/nomGroupe.enum";
import ViewNonDeveloppee from "./components/layouts/viewNonDeveloppee/viewNonDeveloppee";
import UtilisateursView from "./views/administration/utilisateurs/utilisateursView";
import GroupesView from "./views/administration/groupes/groupesView";
import UtilisateurModificationMdpView from "./views/authentification/utilisateurModificationMdpView";
import RestrictedPasswordChangeRoute from "./components/routes/restrictedPasswordChangeRoute";
import OperationsSaturneView from "./views/saturne/operationsSaturneView";
import OperationsSaturneCreationView from "./views/saturne/operationsSaturneCreationView";
import OperationsMailevaCreationView from "./views/maileva/operationsMailevaCreationView";
import OperationsMailevaView from "./views/maileva/operationsMailevaView";
import OperationsUtilisateurView from "./views/accueil/operationsUtilisateurView";
import OperationsASupprimerView from "./views/operations/operationsASupprimer/operationASupprimerView";
import OperationsEnProductionView from "./views/operations/operationsEnProduction/operationsEnProductionView";
import OperationsDetail from "./views/operations/operationsDetail/operationsDetail";
import OperationsCreationView from "./views/operations/operationsCreationView";
import OperationsAInitialiserView from "./views/operations/operationsAInitialiserView";
import OperationsView from "./views/operations/operationsView";
import OperationsSaturneFacturationView from "./views/saturne/operationsSaturneFacturationView";
import OperationsMailevaFacturationView from "./views/maileva/operationsMailevaFacturationView";
import AuditsView from "./views/administration/audits/auditsView";
import OperationsSaturneAInitialiserView from "./views/saturne/operationsSaturneAInitialiserView";
import OperationsMailevaAInitialiserView from "./views/maileva/operationsMailevaAInitialiserView";
import VersionsDesProduitsView from "./views/administration/versionsDesProduits/versionsDesProduitsView";
import JobsView from "./views/administration/jobs/jobsView";
import ParametrageView from "./views/administration/parametrage/parametrageView";
import ParametresProvider from "./contexts/parametrage/parametres.provider";
import BandeauAlerteMaintenance from "./components/genericComponentsUI/alerte/bandeauAlerteMaintenance";
import TypesDePlateformeView from "./views/administration/typesDePlateforme/typesDePlateformeView";
import OperationsArchiveesView from "./views/operations/operationsArchivees/operationsArchiveesView";
import OperationsSaturneASupprimerView from "./views/saturne/operationsSaturneASupprimerView";
import OperationsMailevaASupprimerView from "./views/maileva/operationsMailevaASupprimerView";
import OperationsModificationView from "./views/operations/operationsModification/operationsModificationView";
import PortefeuillesProjetsView from "./views/administration/portefeuillesProjets/portefeuillesProjetsView";
import PortefeuillesProjets
    from "./views/gestionCompteUtilisateur/portefeuillesProjets/portefeuillesProjetsUtilisateur";
import OperationsAllView from "./views/operations/toutesOperations/operationsAllView";
import OperationsSIRSCreationView from "./views/operationsSIRS/operationsSIRSCreationView";
import OperationsSIRSDetail from './views/operationsSIRS/operationsSIRSDetail/operationsSIRSDetail';
import OperationsSIRSView from './views/operationsSIRS/operationsSIRSView';
import OperationsSIRSEnProductionView
    from "./views/operationsSIRS/operationsSIRSEnProduction/operationsSIRSEnProductionView";
import OperationsSIRSModificationView from "./views/operationsSIRS/operationsSIRSModificationView";
import OperationsFacturationView from "./views/operations/operationsFacturationView";
// Cobalt Design System
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {theme} from '@docaposte-agility/da-design-system';
import {Container} from '@mui/material';
import EnvironnementsAllView from "./views/operations/environnements/environnementsAllView";
import OperationsSIRSSupprimeesView from "./views/operationsSIRS/operationsSIRSSupprimees/operationsSIRSSupprimeesView";


function App() {

    return (
        // Setup du Design System "Cobalt" de Docaposte (custom d'un thème MUI)
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {/*Ajout d'une classe CSS dynamique selon l'environnement utilisé (App.css)*/}
            <div className={`App env-${process.env.REACT_APP_SURVOX_ENV}`} id={"app-container"}>
                <AuthProvider>
                    <Routes>
                        <Route path={"login"} element={<LoginView/>}/>
                        <Route path={"dashboard"} element={<Dashboard/>}/>
                        <Route path={"reinitialisation_mdp"} element={<UtilisateurReinitialisationMdpView/>}/>
                        <Route path={"*"} element={<ProtectedRoutes/>}/>
                        <Route path={"/modification_mdp"} element={
                            <RestrictedPasswordChangeRoute>
                                <UtilisateurModificationMdpView/>
                            </RestrictedPasswordChangeRoute>
                        }/>
                    </Routes>
                </AuthProvider>
            </div>
        </ThemeProvider>
    );
}

function ProtectedRoutes() {
    return (
        <>
            <Navbar/>
            <Container sx={{padding: '3rem 0 !important', maxWidth: '90%'}}>
                <AuthenticatedRoute>
                    <ParametresProvider>
                        <BandeauAlerteMaintenance/>
                        <Routes>
                            {/**
                             * Route : Accueil
                             */}
                            <Route path={"/"} element={<OperationsUtilisateurView/>}/>

                            {/**
                             * Route : Profil
                             */}
                            <Route path={"/mon_profil"} element={<Profil/>}/>

                            {/**
                             * Route : Gestion Portefeuilles projets
                             */}
                            <Route path={"/mes_portefeuilles_projets"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <PortefeuillesProjets/>
                                </RestrictedRoute>
                            }/>

                            {/**
                             * Route : Détail d'une opération
                             */}
                            <Route path={"/operation/detail/:id"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <OperationsDetail/>
                                </RestrictedRoute>
                            }
                            />

                            <Route path={"/operation/modification/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA
                                    ]
                                }>
                                    <OperationsModificationView/>
                                </RestrictedRoute>
                            }/>

                            {/**
                             * Routes : Opérations
                             */}
                            <Route path={"/operations/production"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <OperationsEnProductionView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/mes_operations/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE
                                    ]
                                }>
                                    <OperationsView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/declarer_operation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE
                                    ]
                                }>
                                    <OperationsCreationView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/initialiser/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.SUPPORT_SI_DEV_SIRS,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_SIRS
                                    ]
                                }>
                                    <OperationsAInitialiserView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/supprimer"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_SIRS
                                    ]
                                }>
                                    <OperationsASupprimerView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/supprimees"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE
                                    ]
                                }>
                                    <OperationsArchiveesView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/all"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <OperationsAllView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/environnements/all"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <EnvironnementsAllView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/operations/facturation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE,
                                        NomGroupe.RESPONSABLE_PROJETS_VOTE,
                                        NomGroupe.CHEF_DE_PROJET_VOTE
                                    ]
                                }>
                                    <OperationsFacturationView/>
                                </RestrictedRoute>
                            }
                            />

                            {/**
                             * Routes : Saturne
                             */}
                            <Route path={"/saturne/mes_operations/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsSaturneView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/saturne/declarer_operation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsSaturneCreationView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/saturne/initialiser/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsSaturneAInitialiserView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/saturne/supprimer"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsSaturneASupprimerView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/saturne/facturation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsSaturneFacturationView/>
                                </RestrictedRoute>
                            }
                            />

                            {/**
                             * Routes : MailEva
                             */}
                            <Route path={"/maileva/mes_operations/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsMailevaView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/maileva/declarer_operation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsMailevaCreationView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/maileva/initialiser/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsMailevaAInitialiserView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/maileva/supprimer"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsMailevaASupprimerView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/maileva/facturation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV,
                                        NomGroupe.CHEF_DE_PROJET_PACKAGE
                                    ]
                                }>
                                    <OperationsMailevaFacturationView/>
                                </RestrictedRoute>
                            }
                            />

                            {/**
                             * Routes : SIRS
                             */}
                            <Route path={"/sirs/sites_production"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV_SIRS,
                                        NomGroupe.CHEF_DE_PROJET_SIRS,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <OperationsSIRSEnProductionView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/sirs/mes_operations/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.CHEF_DE_PROJET_SIRS
                                    ]
                                }>
                                    <OperationsSIRSView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/sirs/declarer_operation"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.CHEF_DE_PROJET_SIRS
                                    ]
                                }>
                                    <OperationsSIRSCreationView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/sirs/sites_supprimes"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV_SIRS,
                                        NomGroupe.CHEF_DE_PROJET_SIRS
                                    ]
                                }>
                                    <OperationsSIRSSupprimeesView/>
                                </RestrictedRoute>
                            }
                            />

                            {/**
                             * Route : Détail d'une opération SIRS
                             */}
                            <Route path={"/sirs/detail/:id"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.SUPPORT_SI_DEV_SIRS,
                                        NomGroupe.CHEF_DE_PROJET_SIRS,
                                        NomGroupe.OBSERVATEUR
                                    ]
                                }>
                                    <OperationsSIRSDetail/>
                                </RestrictedRoute>
                            }
                            />

                            <Route path={"/sirs/modification/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.SUPPORT_SI_INFRA,
                                        NomGroupe.CHEF_DE_PROJET_SIRS
                                    ]
                                }>
                                    <OperationsSIRSModificationView/>
                                </RestrictedRoute>
                            }/>

                            {/**
                             * Routes : Administration
                             */}
                            <Route path={"/administration/audits"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <AuditsView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/jobs"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <JobsView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/groupes/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.GESTIONNAIRE_DE_COMPTE
                                    ]
                                }>
                                    <GroupesView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"administration/utilisateurs/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR,
                                        NomGroupe.GESTIONNAIRE_DE_COMPTE
                                    ]
                                }>
                                    <UtilisateursView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/versions_produits/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <VersionsDesProduitsView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/types_plateforme"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <TypesDePlateformeView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/portefeuilles_projets/*"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <PortefeuillesProjetsView/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/acces_logs"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <ViewNonDeveloppee/>
                                </RestrictedRoute>
                            }
                            />
                            <Route path={"/administration/parametrage"} element={
                                <RestrictedRoute groupes={
                                    [
                                        NomGroupe.ADMINISTRATEUR
                                    ]
                                }>
                                    <ParametrageView/>
                                </RestrictedRoute>
                            }
                            />

                            <Route path={"*"} element={<Navigate to={"/"}/>}/>
                        </Routes>
                    </ParametresProvider>
                </AuthenticatedRoute>
            </Container>
        </>
    )
}

export default App;
