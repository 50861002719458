import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import React, {useState} from "react";
import "../../../components/genericComponentsUI/button/voxButton.css";
import {useNavigate} from "react-router-dom";
import portefeuilleProjetService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjet.service";
import {PortefeuilleProjetDto} from "../../../services/survox-back/portefeuilleProjet/dtos/portefeuilleProjet.dto";
import {EmailPortefeuilleProjet} from "../../../models/portefeuilleProjet/EmailPortefeuilleProjet.model";
import {Grid} from "@mui/material";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import PortefeuillesProjetsForm from "./portefeuillesProjetsForm";


export default function PortefeuillesProjetsCreation() {

    // Navigation
    const navigate = useNavigate();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>("");


    /**
     * Gère l'événement de l'envoi du formulaire et déclenche la création du portefeuille projet
     */
    const handleOnCreate = (nomPortefeuilleProjet: string, tableauEmails: EmailPortefeuilleProjet[]) => {
        onCreatePortefeuilleProjet({
            nom: nomPortefeuilleProjet,
            tableauEmails: tableauEmails.filter((objEmail: EmailPortefeuilleProjet) => objEmail.email !== "").map((objEmail: EmailPortefeuilleProjet) => objEmail.email)
        });
    }

    /**
     * Création d'un nouveau portefeuille projet
     */
    const onCreatePortefeuilleProjet = (portefeuilleProjetDto: PortefeuilleProjetDto) => {
        setIsLoading(true);
        setIsError(false);
        portefeuilleProjetService.createPortefeuilleProjet(portefeuilleProjetDto)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false);
                    setIsError(false);
                    navigate(`/administration/portefeuilles_projets`);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setIsError(true);
                if (err.response.status === 409) {
                    setMessageError(err.response.data.message);   // Un portefeuille projet avec le nom ... existe déjà.
                } else {
                    setMessageError("Une erreur est survenue lors de la création du portefeuille projet.")
                }
            })
    }


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={8} gap={2}>
                <VoxCard title={"Ajout d'un portefeuille projet"}>

                    {/* Formulaire */}
                    <PortefeuillesProjetsForm
                        nomPortefeuille={""}
                        emailsPortefeuille={""}
                        onSubmit={handleOnCreate}
                        isLoadingOnSubmit={isLoading}
                        isError={isError}
                        messageError={messageError}
                    />

                    {/* Chargement du formulaire */}
                    {isLoading &&
                        <VoxLoader isBackgroundColor={true}/>
                    }

                </VoxCard>
            </Grid>
        </Grid>
    )
}