import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../contexts/authentication/authentication.context";
import {Utilisateur} from "../../models/utilisateur/utilisateur.model";
import OperationsUtilisateurListe from "./operationsUtilisateurListe";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";
import IntroJsAccueil from "../../components/tutorial/IntroJsAccueil";
import {NomGroupe} from "../../models/utilisateur/nomGroupe.enum";
import {Card, Grid, Typography} from "@mui/material";
import ModaleRappelSuppressionOperations from "../../components/modals/modaleRappelSuppressionOperations";
import {useLocation} from "react-router-dom";
import {OperationsASupprimerForRappelType} from "../../models/operation/operationsASupprimerForRappelType.model";

export default function OperationsUtilisateurView() {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    const location = useLocation();
    const stateLocation = location.state as {affichageModaleRappel: boolean, data: OperationsASupprimerForRappelType[]} | null;

    const [showModaleRappelSuppressionOperations, setShowModaleRappelSuppressionOperations] = useState<boolean>(false);

    useEffect(() => {
        // Affichage de la modale en fonction de la valeur trouvée dans le state (option du useNavigate lors du login)
        setShowModaleRappelSuppressionOperations(stateLocation?.affichageModaleRappel ?? false)
    },[stateLocation])


    if (utilisateur.groupe.nom === NomGroupe.GESTIONNAIRE_DE_COMPTE) {
        // Le gestionnaire de compte n'a pas accès aux opérations
        return (
            <Grid container justifyContent={"center"} alignItems={"center"} marginTop={"4rem"}>
                <Grid item xs={11} sm={8} md={6}>
                    <Card
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                            padding: '3rem 2rem'
                        }}
                    >
                        <Typography variant={"h2"}>Bienvenue sur Survox</Typography>
                        <img src={"./img/chaussette-multicolore-icone.png"}
                             alt={"Icône de chaussette multicolore"}
                        />
                    </Card>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <>
                <VoxHeader
                    title={"Opérations suivies"}
                    boutonTutoriels={<IntroJsAccueil/>}
                />
                <OperationsUtilisateurListe/>

                <ModaleRappelSuppressionOperations
                    open={showModaleRappelSuppressionOperations}
                    onClose={() => setShowModaleRappelSuppressionOperations(false)}
                    operationsASupprimer={stateLocation?.data ?? []}
                />
            </>
        );
    }
}