import axios_instance from "../axios-client";
import { Plateforme } from "../../../models/operation/plateforme.dto";

const plateformeService =  {

    getAllPlateformes: (signal: AbortSignal) => {
        return axios_instance.get<Plateforme[]>('/plateformes/all', { signal });
    },

}

export default plateformeService;