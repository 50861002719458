import IntroJS from './IntroJs';
import {allSteps} from "../../models/tutorial/allSteps.model";

export default function IntroJsDeclarerOperationPackagee() {

    // Contenu des étapes - Déclarer une opération packagée (Saturne/MailEva)
    const steps: allSteps[] = [
        {
            // 1. Formulaire global
            element: ".MuiCard-root",
            position: "left",
            intro: <p>Cette page vous permet de déclarer une opération packagée (Saturne / Maileva).</p>
        },
        {
            // 2. Champ "Portefeuille projet"
            element: "#champ-portefeuille-projet .MuiFormControl-root",
            position: "bottom-middle-aligned",
            intro: <p>Vous devez ici sélectionner un portefeuille projet afin de pouvoir assigner une opération à un
                périmètre d'opération.</p>
        },
        {
            // 3. Champ "Produit"
            element: "#champ-produit-readonly",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Le type de produit correspond à la solution applicative souhaitée pour l'opération.</p>
                    <p className={"fst-italic"}>NB : pour les opérations packagées, cela ne peut-être que du
                        VoxCore-Election.</p>
                </>
            )
        },
        {
            // 4. Champ "Type de plateforme"
            element: "#champ-type-plateforme-readonly",
            position: "bottom-middle-aligned",
            intro: <p>Le type de plateforme pour l'opération doit être renseignée et normalement être communiquée dans
                la F2R par le commerce.</p>
        },
        {
            // 5. Champ "Envoi des codes"
            element: "#champ-envoi-codes .MuiFormControl-root",
            position: "top-middle-aligned",
            intro: <p>Le choix du canal d'envoi des codes est renseigné dès la déclaration de l'opération. L'information
                est importante pour permettre ensuite d'avoir une extraction de la facturation des opérations
                packagées.</p>
        },
        {
            // 6. Bouton "Commander une opération"
            element: "#btn-commander-operation",
            position: "top-middle-aligned",
            intro: (
                <>
                    <p>Enfin, vous pouvez valider la déclaration de l'opération en cliquant sur ce bouton.</p>
                    <p className={"fw-bold"}>Au clic sur ce bouton, aucune action automatique n'est déclenchée.
                        L'initialisation du site s'effectue dans un second temps.</p>
                </>
            )
        },
    ];

    return (

        <IntroJS
            steps={steps}
        />

    );

}