import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import OperationsSIRSSupprimeesListe from "./operationsSIRSSupprimeesListe";

export default function OperationsSIRSSupprimeesView() {
    return (
        <>
            <VoxHeader title={"Opérations SIRS supprimées"}/>
            <OperationsSIRSSupprimeesListe/>
        </>
    );
}