import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import OperationsListe from "../../components/operationsListe/operationsListe";
import OperationsModification from "../../components/forms/operationsFormulaires/operationsModification";
import {TypeOperation} from "../../models/operation/typeOperation.enum";
import IntroJsMesOperationsPackagees from "../../components/tutorial/IntroJsMesOperationsPackagees";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsSaturneView() {

    return (
        <Routes>
            <Route path={""} element={
                <>
                    <VoxHeader title={"Mes opérations Saturne"} boutonTutoriels={<IntroJsMesOperationsPackagees/>}/>
                    <OperationsListe typeOperation={TypeOperation.SATURNE}/>
                </>
            }/>
            <Route path={"/modification/:id"} element={
                <>
                    <VoxHeader title={""} isBoutonRetour={true} lienRetour={"/saturne/mes_operations"}/>
                    <OperationsModification typesOperation={[TypeOperation.SATURNE]}/>
                </>
            }/>
            <Route path={"*"} element={
                <Navigate to={"."}/>
            }/>
        </Routes>
    );
}