import { CopyToClipboard } from  "react-copy-to-clipboard";
import React, { useState } from "react";
import "./modaleConfirmationUtilisateur.css";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VoxModal from "./voxModal";
import { Box, Typography } from "@mui/material";

type ModaleUtilisateurPropsType = {
    show : boolean
    handleClose: () => void
    handleSubmit: () => void
    nom: string
    prenom: string
    password: string
}


export default function ModaleConfirmationUtilisateur(props: ModaleUtilisateurPropsType) {

    const [isCopied, setIsCopied] = useState(false)

    const onCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Confirmation"}
            content={
                <Typography variant={"body1"} sx={{lineHeight: "30px"}}>
                    Avez-vous bien noté le mot de passe de l'utilisateur {props.nom} {props.prenom} afin de le lui transmettre ?
                    <br/>
                    Rappel du mot de passe :
                    <CopyToClipboard text={props.password} onCopy={onCopy}>
                    <Box component={"span"} sx={{mx: 1.5}}>
                        <VoxButton
                            className={"button-light-copy"}
                            endIcon={<FontAwesomeIcon icon={"copy"}/>}
                            size={"small"}
                        >
                            {props.password}
                        </VoxButton>
                    </Box>
                    </CopyToClipboard>
                    {isCopied ? <Box component={"span"} sx={{color: 'green'}}>Copié</Box> : null}
                </Typography>
            }
            widthButton={"100px"}
            closeButtonText={"Annuler"}
            submitButtonText={"Valider"}
        />
    )
}