import React from "react";
import { Link } from "@mui/material";

type VoxLinkUrlGrisPropsType = {
    href: string
    isGreyColor?: boolean
    children: string
}

export default function VoxLinkUrl(props: VoxLinkUrlGrisPropsType) {

    return (
        <Link
            variant={"body2"}
            href={props.href}
            target={"_blank"}
            rel={"noreferrer"}
            sx={{
                textDecoration: (props.isGreyColor) ? "none" : "underline",
                color: (props.isGreyColor) ? "cobalt.lynch" : "cobalt.bleuDigital100",
                fontSize: (props.isGreyColor) ? "0.79rem" : "0.875rem",
                "&:hover": {
                    color: (props.isGreyColor) ? "rgba(102, 109, 146, 0.8)" : "cobalt.hover"
                }
            }}
        >
            {props.children}
        </Link>
    )
}