import React from "react";
import favoriService from "../../../services/survox-back/operation/favori.service";
import VoxPictoFavori from "../../genericComponentsUI/pictogrammes/voxPictoFavori";

type PropsType = {
    operationId: number;
    setListeOperations: (opId: number, isFavori: boolean) => void;
    isOperationFavoriteUtilisateur: boolean;
    setIsErrorEnregistrementFavoris: (b: boolean) => void;
    setMessageErreurFavoris: (m: string) => void
};


export default function GestionFavorisListe(props: PropsType) {

    /**
     * Méthode permettant d'ajouter une nouvelle opération en favoris par l'utilisateur authentifié
     * @param checked Valeur de l'input checkbox pour l'opération sélectionnée
     * @param operationId
     */
    const ajoutOperationFavoriteUtilisateur = (checked: boolean, operationId: number) => {
        props.setIsErrorEnregistrementFavoris(false);
        props.setMessageErreurFavoris("");
        favoriService.ajoutOperationFavorisUtilisateur(operationId)
            .then(response => {
                if (response.status === 201) {
                    props.setListeOperations(operationId, checked);
                }
            })
            .catch(() => {
                props.setIsErrorEnregistrementFavoris(true);
                props.setMessageErreurFavoris("Une erreur est survenue lors de l'enregistrement d'une opération en favoris.");
            });
    }

    /**
     * Méthode permettant de supprimer une opération en favoris par l'utilisateur authentifié
     * @param checked Valeur de l'input checkbox pour l'opération sélectionnée
     * @param operationId
     */
    const suppressionOperationFavoriteUtilisateur = (checked: boolean, operationId: number) => {
        props.setIsErrorEnregistrementFavoris(false);
        props.setMessageErreurFavoris("");
        favoriService.suppressionOperationFavorisUtilisateur(operationId)
            .then(response => {
                if (response.status === 200) {
                    props.setListeOperations(operationId, checked);
                }
            })
            .catch(() => {
                props.setIsErrorEnregistrementFavoris(true);
                props.setMessageErreurFavoris("Une erreur est survenue lors de la suppression d'une opération en favoris.");
            });
    }


    /**
     * Gestion des opérations mises en favoris par l'utilisateur
     */
    const handleOnChangeOperationFavorite = (checked: boolean, operationId: number)  => {
        if (checked) {
            // Nouvelle opération en favoris pour l'utilisateur
            // (Ajout d'une ligne dans la table operation_favorite_utilisateur)
            ajoutOperationFavoriteUtilisateur(checked, operationId);
        } else {
            // Suppression d'une opération en favoris
            // (Suppression d'une ligne dans la table operation_favorite_utilisateur)
            suppressionOperationFavoriteUtilisateur(checked, operationId);
        }
    }

    return (
        <VoxPictoFavori
            isFavori={props.isOperationFavoriteUtilisateur}
            operationId={props.operationId}
            onChange={handleOnChangeOperationFavorite}
            iconSize={"1x"}
            libelle={false}
        />
    )

}