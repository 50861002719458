import { ChangeEvent } from "react";
import { CellProps } from "react-table";

type CheckboxCellProps<Type extends object> = {
    id: string;
    name: string;
    cellProps: CellProps<Type>;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    show?: boolean;
}

export const CheckboxCell = <Type extends object>(props: CheckboxCellProps<Type>) => {

    if (props.show === false) {
        return null;
    }

    return (
        <div className={"text-center"}>
            <input 
                id={ props.id }
                className="form-check-input" 
                type="checkbox" 
                name={ props.name }
                checked={props.cellProps.value}
                onChange={ props.onChange }
                disabled={ props.disabled }
            />
        </div>
    )
}