import React from "react";
import VoxModal from "./voxModal";
import { Typography } from "@mui/material";

type ModaleType = {
    show: boolean,
    isInstalling: boolean,
    handleClose: () => void,
    handleSubmit: () => void,
}

export default function ModaleConfirmationInstallationDocaposteSIRS(props: ModaleType) {

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Confirmation d'installation"}
            content={
                <Typography variant={"body1"}>
                    Le site a-t-il bien été installé manuellement chez Docaposte ?
                </Typography>
            }
            closeButtonText={"Non"}
            submitButtonText={"Confirmer"}
            isValidationLoading={props.isInstalling}
        />
    )
}