import { SIRSOperation } from "../../../models/operationSIRS/sirsOperation.model";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { SIRSIndicateursOperation } from "../../../models/operationSIRS/sirsIndicateursOperation.model";
import {Grid} from "@mui/material";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";

type PropsType = { operationSIRS: SIRSOperation }
type indicateursTableauType = { indicateur: string, valeur: string | number | null }

export default function OperationsSIRSDetailIndicateurs(props: PropsType) {

    const indicateurs: SIRSIndicateursOperation | undefined = props.operationSIRS.sirsIndicateursOperation;

    /**
     * Remplissage des lignes du tableau
     */
    const rows: indicateursTableauType[] = useMemo(() => {
        return (
            [
                {
                    indicateur: "Nombre de licences activées",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbLicencesActivees : null
                },
                {
                    indicateur: "Nombre de licences désactivées",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbLicencesDesactivees : null
                },
                {
                    indicateur: "Nombre de comptes admin maximum autorisés",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbMaxComptesAdmin : null
                },
                {
                    indicateur: "Nombre de comptes représentant maximum autorisés",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbMaxComptesRepresentant : null
                },
                {
                    indicateur: "Nombre de comptes admin actifs",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbComptesAdminActifs : null
                },
                {
                    indicateur: "Nombre de comptes admin inactifs",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbComptesAdminInactifs : null
                },
                {
                    indicateur: "Nombre de comptes représentant actifs",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbComptesRepresentantActifs : null
                },
                {
                    indicateur: "Nombre de comptes représentant inactifs",
                    valeur: (props.operationSIRS && indicateurs) ? indicateurs.nbComptesRepresentantInactifs : null
                }
            ]
        )
    }, [props.operationSIRS, indicateurs])


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<indicateursTableauType>> = useMemo(() => [
        {
            Header: 'Indicateur',
            accessor: 'indicateur',
            minWidth: 100,
            disableSortBy: true
        },
        {
            Header: 'Valeur',
            accessor: 'valeur',
            minWidth: 150,
            disableSortBy: true
        },
    ], [])


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={6}>
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialPageSize={20}
                    cellProps={() => {
                        return {
                            style: {textAlign: "left"}
                        }
                    }}
                />
            </Grid>
        </Grid>
    )

}