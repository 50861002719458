export enum OperationPhase {
    AVANTT1 = "avant_t1",
    T1 = "t1",
    ENTRET1T2 = "entre_t1_t2",
    T2 = "t2",
    FIN = "fin"
}

const texte_phase_AVANTT1: string = "Avant 1er tour";
const texte_phase_T1: string = "1er tour en cours";
const texte_phase_ENTRET1T2: string = "Entre 2 tours";
const texte_phase_T2: string = "2ème tour en cours";
const texte_phase_FIN: string = "Scrutin terminé";
const texte_phase_nonApplicable: string = "Non applicable";


/**
 * Fonction qui renvoie le texte pour la cellule du tableau en fonction de la valeur de la phase
 */
export const getTextPhaseOperation = (operationPhase: OperationPhase): string => {
    switch (operationPhase) {
        case OperationPhase.AVANTT1: return texte_phase_AVANTT1;
        case OperationPhase.T1: return texte_phase_T1;
        case OperationPhase.ENTRET1T2: return texte_phase_ENTRET1T2;
        case OperationPhase.T2: return texte_phase_T2;
        case OperationPhase.FIN: return texte_phase_FIN;
        default: return texte_phase_nonApplicable;
    }
}

export const getTableCellBackgroundColorPhase = (textPhaseOperation: string) => {
    switch (textPhaseOperation) {
        case texte_phase_AVANTT1: return 
    }
}