import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import OperationsSIRSEnProductionListe from "./operationsSIRSEnProductionListe";

export default function OperationsSIRSEnProductionView() {
    return (
        <>
            <VoxHeader title={"Opérations SIRS en production"} isBoutonRetour={true} lienRetour={"/"}/>
            <OperationsSIRSEnProductionListe/>
        </>
    )
}