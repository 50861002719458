import React, { useCallback, useContext, useEffect, useState } from "react";
import Navbar from "../../../components/layouts/navbar/navbar";
import LoginForm from "../../../components/forms/authentication/loginForm";
import './loginView.css';
import { LoginUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/loginUtilisateur.dto";
import { AuthContext } from "../../../contexts/authentication/authentication.context";
import authService from "../../../services/survox-back/auth.service";
import { useNavigate } from "react-router-dom";
import { extractUtilisateurFromToken } from "../../../utils/jwt/jwt.utils";
import appService from "../../../services/survox-back/app.service";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import operationService from "../../../services/survox-back/operation/operation.service";
import { CircularProgress } from "@mui/material";


export default function LoginView() {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoginError, setIsLoginError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [informationsTechniquesBack, setInformationsTechniquesBack] = useState({versionBack: undefined})
    const [isLoadingInformationsBack, setIsLoadingInformationsBack] = useState(false);
    const [isLoadingInformationsBackError, setIsLoadingInformationsBackError] = useState(false);

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    /**
     * Recuperation des informations techniques concernant la partie "Back" de Survox
     */
    const recuperationInformationsBack = useCallback((controller: AbortController) => {
        setIsLoadingInformationsBack(true);
        setIsLoadingInformationsBackError(false);
        appService.getInformationsTechniques(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    setInformationsTechniquesBack(response.data);
                    setIsLoadingInformationsBack(false);
                    setIsLoadingInformationsBackError(false);
                }
            })
            .catch((err) => {
                if (err.message !== "canceled") {
                    setIsLoadingInformationsBack(false);
                    setIsLoadingInformationsBackError(true);
                }
            });
    }, []);

    useEffect(() => {
        const controller = new AbortController()
        recuperationInformationsBack(controller);
        return () => controller.abort();
    }, [recuperationInformationsBack]);

    useEffect(() => {
        if (authContext.authenticated) {
            navigate("/");
        }
    }, [authContext, navigate]);


    const handleOnLogin = (loginUtilisateurDto: LoginUtilisateurDto) => {
        setIsLoading(true);
        setIsLoginError(false);
        authService.login(loginUtilisateurDto)
            .then((response) => {
                if (response.status === 201) {
                    authContext.login(response.data.access_token);
                    setIsLoading(false);

                    const informationsToken: Utilisateur = extractUtilisateurFromToken(response.data.access_token)
                    if (informationsToken.mdpAModifier) {
                        // l'utilisateur doit changer son mot de passe
                        navigate("/modification_mdp");
                    } else {
                        // Vérification pour l'affichage d'une modale de rappel concernant les opérations à supprimer
                        operationService.verificationAffichageModaleDeRappel(informationsToken)
                            .then((stateLocation) => {
                                navigate('/', {state: stateLocation});
                            });
                    }
                }
            })
            .catch((error) => {
                setIsLoginError(true);
                setIsLoading(false);
                if (error.response && error.response.status === 401) {
                    if (
                        error.response.data.message === "Le compte de l'utilisateur est désactivé"
                        || error.response.data.message === "Le mot de passe temporaire a expiré. Veuillez faire une nouvelle demande de mot de passe oublié."
                    ) {
                        setErrorMessage(error.response.data.message);
                    } else {
                        setErrorMessage("Les identifiants saisis sont incorrects.");
                    }
                } else {
                    setErrorMessage("Une erreur est survenue.");
                }
            });
    }


    return (
        <Box className={"login-container"}>
            <Navbar/>
            <Box className={"login-flex-alignement"}>
                <Paper className={"login-form-paper"} elevation={1}>
                    <Typography variant={"h1"} sx={{color: 'cobalt.bleuDigital100'}}>
                        <Typography component={"span"} variant={"h2"} sx={{color: 'cobalt.lynch', display: 'block'}}>Bienvenue sur</Typography>
                        Survox
                    </Typography>
                    <LoginForm
                        isLoading={isLoading}
                        isLoginError={isLoginError}
                        errorMessage={errorMessage}
                        onLogin={handleOnLogin}
                    />
                    <Typography component={"div"} variant={"body2"} sx={{color: 'cobalt.lynch', textAlign: 'center', mt: 4}}>
                        Version Front : {process.env.REACT_APP_VERSION}
                        {
                            isLoadingInformationsBack ?
                                <>
                                    <Box component={"span"} mx={1}>/</Box>
                                    <Box component={"span"}><CircularProgress size={16}/></Box>
                                </>
                                :
                                (informationsTechniquesBack.versionBack &&
                                    ` / Version Back : ${informationsTechniquesBack.versionBack}`
                                )
                        }
                    </Typography>
                    {
                        isLoadingInformationsBackError &&
                        <VoxAlert severity={"error"} sx={{mt: 3}}>
                            Une erreur est survenue lors de la récupération de la version Back.
                        </VoxAlert>
                    }
                </Paper>
            </Box>
        </Box>
    );
}