import { SIRSOperation } from "../../../models/operationSIRS/sirsOperation.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {List, ListItem, Typography} from "@mui/material";

export default function OperationsSIRSDetailModules(props: { operationSIRS: SIRSOperation }) {

    /**
     * Liste des modules SIRS
     * @param name string, nom du module affiché sur l'appli
     * @param etat boolean, renvoie true si le module est activé sur l'opération
     */
    const allModules = [
        {
            id: 'bdese',
            name: 'BDESE',
            etat: props.operationSIRS.sirsModulesOperation?.bdese
        },
        {
            id: 'sdh',
            name: 'Suivi des heures',
            etat: props.operationSIRS.sirsModulesOperation?.shd
        },
        {
            id: 'elections-representativite',
            name: 'Élections/Représentativité ',
            etat: props.operationSIRS.sirsModulesOperation?.electionsRepresentativite
        },
        {
            id: 'reclamations',
            name: 'Réclamations',
            etat: props.operationSIRS.sirsModulesOperation?.reclamations
        },
        {
            id: 'comite-groupe',
            name: 'Comité de groupe',
            etat: props.operationSIRS.sirsModulesOperation?.comiteGroupe
        },
        {
            id: 'accords',
            name: 'Accords',
            etat: props.operationSIRS.sirsModulesOperation?.accords
        },
        {
            id: 'reporting',
            name: 'Reporting',
            etat: props.operationSIRS.sirsModulesOperation?.reporting
        }
    ];

    return (
        <List>
            {
                // Les modules sont affichés sous forme de liste, ils sont "disabled" si l'état renvoie "false"
                allModules.map((module) => (
                    <ListItem
                        key={`sirs-module-${module.id}`}
                        sx={{paddingX: 0}}
                    >
                        <FontAwesomeIcon
                            icon={module.etat ? "check-circle" : ["far", "times-circle"]}
                            color={module.etat ? "var(--cobalt-greenIcon)" : "var(--cobalt-lynch)"}
                            size={"lg"}
                            width={45}
                        />
                        <Typography
                            color={module.etat ? "var(--cobalt-greenText)" : "var(--cobalt-lynch)"}
                            fontWeight={module.etat ? 500 : 'initial'}
                        >
                            {module.name}
                        </Typography>
                    </ListItem>
                ))
            }
        </List>
    );

}