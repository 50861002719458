import React, { useContext } from "react";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import { Parametrage } from "../../models/parametrage/parametrage.model";
import { TypeParametre } from "../../models/parametrage/typeParametre.enum";
import { ValeurBooleenneParametrage } from "../../models/parametrage/valeurBooleenneParametrage.enum";
import { ParametresContext } from "../../contexts/parametrage/parametres.context";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { TypeProduit } from "../../models/versionApplicative/typeProduit.enum";
import VoxLinkInstallation from "../genericComponentsUI/link/voxLinkInstallation";


export default function LienInstallerMaintenant(props: { idOperation: number, typeOperation: TypeOperation, handleOperationConfirmationOVH: (id: number) => void }) {

    const parametresContext = useContext(ParametresContext);

    // Redéfinition du type de produit de l'opération
    const typeOperation: TypeOperation = props.typeOperation;
    let typeProduit: TypeProduit | undefined = undefined;
    if (typeOperation === TypeOperation.SATURNE || typeOperation === TypeOperation.MAILEVA || typeOperation === TypeOperation.VOXCORE_ELECTION) {
        typeProduit = TypeProduit.VOXCORE_ELECTION;
    } else if (typeOperation === TypeOperation.VOXCORE_AG) {
        typeProduit = TypeProduit.VOXCORE_AG;
    }

    // Récupération du paramétrage présent dans le contexte de maintenance pour le blocage de l'installation de VM
    const parametrageBlocageSitesVoxcoreElection = parametresContext.parametrages.find((parametrage: Parametrage) => {
        return (parametrage.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_ELECTION)
    });
    const parametrageBlocageSitesVoxcoreAG = parametresContext.parametrages.find((parametrage: Parametrage) => {
        return (parametrage.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_AG)
    });

    /**
     * Ouverture d'une popup de confirmation pour l'installation de l'opération sur une VM
     * @param idOperation , l'id de l'opération
     */
    const handleInstallerOperationAvecConfirmation = (idOperation: number) => {
        props.handleOperationConfirmationOVH(idOperation);
    }


    if (parametrageBlocageSitesVoxcoreElection !== undefined && parametrageBlocageSitesVoxcoreAG !== undefined) {

        // Variables de maintenance pour le blocage des installations de VM selon le type de produit de l'opération
        const interdictionInstallerVmVoxcoreElection = (
            parametrageBlocageSitesVoxcoreElection.valeur === ValeurBooleenneParametrage.TRUE
            && typeProduit === TypeProduit.VOXCORE_ELECTION
        );
        const interdictionInstallerVmVoxcoreAG = (
            parametrageBlocageSitesVoxcoreAG.valeur === ValeurBooleenneParametrage.TRUE
            && typeProduit === TypeProduit.VOXCORE_AG
        );


        /**
         * Blocage de la méthode d'installation d'une VM si le paramétrage l'interdit
         */
        return (
            <VoxLinkInstallation
                startIcon={"cogs"}
                isDisabledLink={interdictionInstallerVmVoxcoreElection || interdictionInstallerVmVoxcoreAG}
                onClick={
                    (interdictionInstallerVmVoxcoreElection || interdictionInstallerVmVoxcoreAG) ?
                        () => null :
                        () => handleInstallerOperationAvecConfirmation(props.idOperation)
                }
            >
                Installer maintenant (OVH)
            </VoxLinkInstallation>
        );
    } else {
        // Ne doit pas arriver, les paramètres doivent toujours être trouvés dans le contexte de maintenance
        return <VoxAlert severity={"error"}>Erreur</VoxAlert>;
    }

}