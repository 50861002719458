import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import operationSIRSService from "../../../services/survox-back/operationSIRS/operationSIRS.service";
import {OperationSIRSFormulaire} from "../../../models/operationSIRS/operationSIRSFormulaire.model";
import { CreateOperationSIRSDto } from "../../../services/survox-back/operationSIRS/dtos/request/createOperationSIRS.dto";
import OperationsSirsForm, { EditSIRSContactClient } from "./operationsSIRSForm";
import {Grid} from "@mui/material";
import {VoxAlert} from "../../genericComponentsUI/alerte/voxAlert";
import VoxCard from "../../genericComponentsUI/card/voxCard";
import {EtatDemande} from "../../../models/operation/etatDemande.enum";


/**
 * Fonction composant appelant le formulaire d'opération SIRS pour la CRÉATION
 */
export default function OperationsSIRSCreation() {

    // Valeurs initiales de l'opération SIRS à l'ouverture du formulaire de création
    const operationSIRSInit: OperationSIRSFormulaire = {
        nomClient: "",
        dateInstallation: undefined,
        etatDemande: EtatDemande.DECLARE,
        typePrestation: undefined,
        urlPartieClient: "",
        contactsClient: [{id: 0, contactClient: "", mail: "", telephone: ""}],
        nbLicencesVendues: -1,
        description: ""
    }

    // Navigation
    const navigate = useNavigate();

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorOnCreate, setIsErrorOnCreate] = useState(false);
    const [isErrorDateInstallation, setIsErrorDateInstallation] = useState(false);
    const [isErrorTypePrestation, setIsErrorTypePrestation] = useState(false);
    const [messageError, setMessageError] = useState("");
    const [messageErrorUrlDoublon, setMessageErrorUrlDoublon] = useState<string>("");

    /**
     * Méthode appelée lors de la soumission du formulaire pour CRÉER l'opération SIRS en BDD
     * @param operationSIRS
     * @param contactsClientListe
     */
    const handleOnCreate = (operationSIRS: OperationSIRSFormulaire, contactsClientListe: EditSIRSContactClient[]) => {
        setIsErrorDateInstallation(false);
        setIsErrorTypePrestation(false);
        if (operationSIRS.dateInstallation !== undefined) {
            if (operationSIRS.typePrestation !== undefined) {
                onCreateOperationSIRS({
                    nomClient: operationSIRS.nomClient,
                    dateInstallation: operationSIRS.dateInstallation,
                    typePrestation: operationSIRS.typePrestation,
                    urlPartieClient: operationSIRS.urlPartieClient,
                    sirsContactClient: contactsClientListe.map(({
                                                                    id,
                                                                    isUpdate,
                                                                    isDelete,
                                                                    isNew,
                                                                    ...contactClient
                                                                }) => contactClient),
                    nbLicencesVendues: operationSIRS.nbLicencesVendues,
                    description: (operationSIRS.description.trim() === "") ? null : operationSIRS.description.trim()    // .trim() : // enlève les espaces au debut et à la fin de la chaine de caractères
                });
            } else {
                setIsErrorTypePrestation(true);
            }
        } else {
            setIsErrorDateInstallation(true);
        }
    }

    /**
     * Méthode qui envoie les données de la nouvelle opération SIRS en BDD
     */
    const onCreateOperationSIRS = (createOperationSIRSDto: CreateOperationSIRSDto) => {
        setIsLoading(true);
        setIsErrorOnCreate(false);
        setMessageErrorUrlDoublon("");
        operationSIRSService.createOperationSIRS(createOperationSIRSDto)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false);
                    setIsErrorOnCreate(false);
                    navigate(`/sirs/mes_operations`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsErrorOnCreate(true);
                if (error.response.status === 403) {
                    // Erreur doublon url
                    setMessageErrorUrlDoublon(error.response.data.message);
                } else {
                    setMessageError(error.response.data.message[0])
                }
            })
    }


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8} lg={5}>
                <VoxCard title={"Création d'une opération SIRS"}>

                    {/* SI erreur lors de la soumission */}
                    {isErrorOnCreate &&
                        <VoxAlert severity={"error"}>
                            {messageErrorUrlDoublon !== "" ?
                                messageErrorUrlDoublon : "Une erreur est survenue lors de la création de l'opération SIRS."
                            }
                        </VoxAlert>
                    }

                    {/* Formulaire de création */}
                    <OperationsSirsForm
                        operationSIRS={operationSIRSInit}
                        onSubmit={handleOnCreate}
                        isLoadingOnSubmit={isLoading}
                        isErrorOnSubmit={isErrorOnCreate}
                        isErrorDateInstallation={isErrorDateInstallation}
                        isErrorTypePrestation={isErrorTypePrestation}
                        messageError={messageError}
                    />

                    {/* Chargement lors de la soumission */}
                    {isLoading ? <VoxLoader isBackgroundColor={true}/> : null}

                </VoxCard>
            </Grid>
        </Grid>
    )
}