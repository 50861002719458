import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import React, {ReactElement} from "react";

type NavbarMenuItemPropsType = {
    groupes: NomGroupe[],
    children: ReactElement
}

export default function NavbarMenuItem(props: NavbarMenuItemPropsType) {

    return (
        <AuthContext.Consumer>
            {value => {

                let isUserAuthorized = false;

                if (value.utilisateur && (props.groupes.length === 0 || props.groupes?.includes(value.utilisateur.groupe?.nom))) {
                    isUserAuthorized = true;
                }

                if (isUserAuthorized) {
                    return (
                        props.children
                    )
                } else {
                    return null
                }

            }}
        </AuthContext.Consumer>
    )

}