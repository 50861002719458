import React, {ReactNode} from "react";
import {Card, CardContent, CardHeader, SxProps, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {colors} from "@docaposte-agility/da-design-system";
import './voxCard.css'

type VoxCardPropsType = {
    id?: string,
    iconBeforeTitle?: IconProp,
    title?: string,
    action?: ReactNode,
    children: ReactNode
    sxContent?: SxProps,
}

export default function VoxCard(props: VoxCardPropsType) {

    return (
        <Card
            id={props.id}
            sx={{backgroundColor: colors.white, borderRadius: 2, boxShadow: 2}}
        >

            {/* TITRE */}
            {props.title &&
                <CardHeader
                    avatar={
                        props.iconBeforeTitle ?
                            <FontAwesomeIcon icon={props.iconBeforeTitle}/>
                            : null
                    }
                    title={
                        <Typography variant={"h4"}>{props.title}</Typography>
                    }
                    action={props.action}
                    sx={{boxShadow: 2}}
                />
            }

            {/* CONTENU */}
            <CardContent sx={{padding: '24px', ...props.sxContent}}>
                {props.children}
            </CardContent>

        </Card>
    )
}