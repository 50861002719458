import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import EnvironnementsService from "../../services/survox-back/environnements/environnements.service";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import { EnvironnementSuppression } from "../../models/environnements/environnementSuppression.model";
import { CellProps, Column } from "react-table";
import { EnvironnementSuppressionTableau } from "../../models/environnements/environnementSuppressionTableau.model";
import { affichageLibelleTypeEnvironnement } from "../../utils/string.utils";
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";
import VoxTable from "../genericComponentsUI/table/voxTable";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import { Box, Typography } from "@mui/material";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";
import { UpdateEtatEnvOperationsDto } from "../../services/survox-back/environnements/dtos/updateEtatEnvOperations.dto";
import VoxModal from "../modals/voxModal";
import { TypeEnvironnement } from "../../models/environnements/typeEnvironnement.enum";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";


export default function EnvironnementsAsupprimer() {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");

    const [environnementsAsupprimer, setEnvironnementsAsupprimer] = useState<EnvironnementSuppression[]>([]);
    const [environnementSupprime, setEnvironnementSupprime] = useState<UpdateEtatEnvOperationsDto>();

    const [messageModale, setMessageModale] = useState<string>("");
    const [showSuppModale, setShowSuppModale] = useState<boolean>(false);

    /**
     * Construire le texte de la modale
     */
    const construireMessageModale = (typeEnv: TypeEnvironnement, url: string) => {

        const message = "Souhaitez-vous vraiment supprimer l'environnement "
            + `${typeEnv} - ${url} ? `
            + "En cliquant sur Confirmer, cet environnement basculera vers l'état : Supprimé";

        setMessageModale(message);
    }

    /**
     * Charger les environnements à l'état A supprimer
     */
    const loadEnvironnements = useCallback((controller?: AbortController) => {
        setIsLoading(true);
        setIsError(false);
        EnvironnementsService.getSomeEnvironnementsByEtatDemande(EtatDemande.A_SUPPRIMER, controller?.signal)
            .then((response) => {
                if(response.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    setEnvironnementsAsupprimer(response.data);
                }
            })
            .catch((error) => {
                if(error.message !== 'canceled') {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError("Impossible de récupérer les environnements à supprimer");
                }
            })
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        loadEnvironnements(controller);
        return () => controller.abort();
    }, [loadEnvironnements]);

    /**
     * Passage à l'état Supprimé
     */
    const deleteEnvironnement = useCallback(() => {
        setIsLoading(true);
        setIsError(false);
        if(environnementSupprime) {
            EnvironnementsService.updateEtatEnvironnementOperation(environnementSupprime)
                .then((response) => {
                    if(response.status === 200) {
                        setIsLoading(false);
                        setIsError(false);
                        loadEnvironnements();
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                    setIsError(true);
                    setMessageError("Une erreur est survenue lors du changement d'état.");
                })
                .finally(() => {
                    setShowSuppModale(false);
                })
        }
    }, [environnementSupprime, loadEnvironnements]);

    /**
     * Actions possibles
     */
    const cellActions  = useCallback((props: CellProps<EnvironnementSuppressionTableau>) => {
        return (
            <Box display={"flex"} justifyContent={"center"} gap={1}>
                {/* Poubelle → Suppression de l'environnement */}
                {(utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                    || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) &&
                        <VoxIconAction
                            tooltip={"Supprimer l'environnement"}
                            icon={"trash-alt"}
                            onClick={() => {
                                construireMessageModale(props.row.original.typeEnv, props.row.original.url);
                                setEnvironnementSupprime({
                                    id: Number(props.row.original.id),
                                    etatDemande: EtatDemande.SUPPRIME
                                });
                                setShowSuppModale(true);
                            }}
                        />
                }
            </Box>
        )
    }, [utilisateur.groupe.nom]);

    /**
     * Liens vers les opérations
     */
    const linkCellOperation = useCallback((props: CellProps<EnvironnementSuppressionTableau>) => {
        return (
            <VoxLinkOperation
                href={`/operation/detail/${props.row.original.operationId}`}
            >
                {props.row.original.operationName}
            </VoxLinkOperation>
        )
    }, []);

    /**
     * Liens des Urls
     */
    const linkCellUrl = useCallback((props: CellProps<EnvironnementSuppressionTableau>) => {
        return (
            <VoxLinkUrl href={props.row.original.url}>
                {props.row.original.url}
            </VoxLinkUrl>
        )
    }, []);

    /**
     * Cellules Description
     */
    const affichageTexteAvecSautsDeLigne = useCallback((texte: string) => {
        if (texte) {
            const values: string[] = texte.split('\n');
            return <>{
                values.map((value, index) => <p key={`${value.substring(0, 5).replace(" ", "")}-${index}`}>{value}</p>)
            }</>;
        } else {
            return <></>;
        }
    }, []);

    /**
     * Lignes du tableau
     */
    const rows: EnvironnementSuppressionTableau[] = useMemo(() => {
        return environnementsAsupprimer.map((env) => ({
            id: env.id,
            operationId: env.operation.id,
            operationName: env.operation.nom,
            url: env.url,
            typeEnv: env.typeEnvironnement,
            description: env.description
        }))
    }, [environnementsAsupprimer]);

    /**
     * Colonnes du tableau
     */
    const columns: Column<EnvironnementSuppressionTableau>[] = useMemo(() => [
        {
            Header: 'Opération Liée',
            accessor: 'operationName',
            minWidth: 100,
            Cell: linkCellOperation
        },
        {
            Header: 'Site',
            accessor: 'url',
            minWidth: 200,
            Cell: linkCellUrl
        },
        {
            Header: "Type d'environnement",
            Filter: SelectInputFilter,
            filter: 'includes',
            accessor: (originalRow => affichageLibelleTypeEnvironnement(originalRow.typeEnv)),
            minWidth: 60,
            maxWidth: 70
        },
        {
            Header: 'Description',
            accessor: 'description',
            minWidth: 200,
            Cell: ((props: CellProps<EnvironnementSuppressionTableau>) => affichageTexteAvecSautsDeLigne(props.value)),
        },
        {
            Header: 'Actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: cellActions,
            minWidth: 30,
            maxWidth: 50
        }
    ], [linkCellOperation, linkCellUrl, affichageTexteAvecSautsDeLigne, cellActions]);


    return(
        isLoading ? <VoxLoader positionRelative/> :
        <>
            {isError &&
                <VoxAlert severity={"error"}>{messageError}</VoxAlert>
            }
            <VoxTable
                data={rows}
                columns={(utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                    || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) ?
                    columns
                    : columns.filter((col) => col.Header !== 'Actions')
                }
                initialStateSortBy={[{id: 'operationName'}]}
                cellProps={(cell: CellProps<EnvironnementSuppressionTableau>) => {
                    if (cell.column.id === "description") {
                        return {
                            style: {textAlign: "left"}
                        }
                    }
                }}
            />

            {showSuppModale &&
                <VoxModal
                    open={showSuppModale}
                    onSubmit={deleteEnvironnement}
                    onClose={() => setShowSuppModale(false)}
                    title={"Suppression d'un environnement"}
                    startIconCloseButton={"ban"}
                    startIconSubmitButton={"check"}
                    closeButtonText={"Annuler"}
                    submitButtonText={"Confirmer"}
                    content={
                        <Typography>{messageModale}</Typography>
                    }
                />
            }
        </>
    )
}