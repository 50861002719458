import React, {ChangeEvent, useEffect, useState} from "react";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import portefeuilleProjetService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjet.service";
import {CellProps, Column} from "react-table";
import portefeuilleProjetUtilisateurService from "../../../services/survox-back/portefeuilleProjet/portefeuilleProjetUtilisateur.service";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import {Box, Checkbox, FormControlLabel, Grid, Paper} from "@mui/material";

type PortefeuillesProjetsTableau = {
    id: number;
    nom: string;
    is_suivi: boolean;
}


export default function PortefeuillesProjetsUtilisateur() {

    const [listePortefeuillesProjets, setListePortefeuillesProjets] = useState<PortefeuillesProjetsTableau[]>([]);
    const [isLoadingPortefeuillesProjets, setIsLoadingPortefeuillesProjets] = useState<boolean>(false);
    const [isLoadingPortefeuillesProjetsError, setIsLoadingPortefeuillesProjetsError] = useState<boolean>(false);

    const [isErrorEnregistrement, setIsErrorEnregistrement] = useState<boolean>(false);
    const [messageErreur, setMessageErreur] = useState<string>("");

    /**
     * Récupération de tous les portefeuilles projets
     */
    const chargerPortefeuillesProjetsAvecSuivi = (controller?: AbortController) => {
        setIsLoadingPortefeuillesProjets(true);
        setIsLoadingPortefeuillesProjetsError(false);
        portefeuilleProjetService.getPortefeuillesProjetsAvecSuiviUtilisateur(controller?.signal)
            .then(response => {
                if (response.status === 200) {
                    setListePortefeuillesProjets(response.data);
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    setIsLoadingPortefeuillesProjets(false);
                    setIsLoadingPortefeuillesProjetsError(true);
                }
            });
    }

    useEffect(() => {
        const controller = new AbortController();
        chargerPortefeuillesProjetsAvecSuivi(controller);
        return () => controller.abort();
    }, []);


    /**
     * Méthode pour ajouter un nouveau suivi d'un portefeuille projet par l'utilisateur authentifié
     * @param checked  Valeur de l'input checkbox pour le portefeuille projet sélectionné
     * @param portefeuilleProjet  Portefeuille projet sélectionné
     */
    const ajoutSuiviPortefeuilleProjetUtilisateur = (checked: boolean, portefeuilleProjet: PortefeuillesProjetsTableau) => {
        setIsErrorEnregistrement(false);
        portefeuilleProjetUtilisateurService.ajoutSuiviPortefeuilleProjet(portefeuilleProjet.id)
            .then(response => {
                if (response.status === 201) {
                    miseAJourListePortefeuillesProjets(checked, portefeuilleProjet);
                    setIsErrorEnregistrement(false);
                }
            })
            .catch(() => {
                setIsErrorEnregistrement(true);
                setMessageErreur("Une erreur est survenue lors de l'enregistrement du suivi du portefeuille projet.");
            });
    }

    /**
     * Methode permettant de supprimer le suivi d'un portefeuille projet par l'utilisateur authentifié
     * @param checked  Valeur de l'input checkbox pour le portefeuille projet sélectionné
     * @param portefeuilleProjet  Portefeuille projet sélectionné
     */
    const suppressionSuiviPortefeuilleProjetUtilisateur = (checked: boolean, portefeuilleProjet: PortefeuillesProjetsTableau) => {
        setIsErrorEnregistrement(false);
        portefeuilleProjetUtilisateurService.suppressionSuiviPortefeuilleProjet(portefeuilleProjet.id)
            .then(response => {
                if (response.status === 200) {
                    miseAJourListePortefeuillesProjets(checked, portefeuilleProjet);
                    setIsErrorEnregistrement(false);
                }
            })
            .catch(() => {
                setIsErrorEnregistrement(true);
                setMessageErreur("Une erreur est survenue lors de la suppression du suivi du portefeuille projet.");
            });
    }


    const miseAJourListePortefeuillesProjets = (checked: boolean, portefeuilleProjet: PortefeuillesProjetsTableau) => {
        setListePortefeuillesProjets((prevState: PortefeuillesProjetsTableau[]) => {
            const tableauPortefeuillesProjet = [...prevState];

            const position = listePortefeuillesProjets.indexOf(portefeuilleProjet);
            tableauPortefeuillesProjet[position].is_suivi = checked;

            return tableauPortefeuillesProjet;
        })
    }


    const handleOnChangePortefeuilleProjetSuivi = (event: any, nomPortefeuileProjetAModifier: string) => {
        const portefeuilleProjet: PortefeuillesProjetsTableau | undefined = listePortefeuillesProjets.find((pp: PortefeuillesProjetsTableau) => pp.nom === nomPortefeuileProjetAModifier);

        if (portefeuilleProjet) {
            if (event.target.checked) {
                // Nouveau suivi d'un portefeuille projet
                // (Ajout d'une ligne dans la table portefeuille_projet_utilisateur)
                ajoutSuiviPortefeuilleProjetUtilisateur(event.target.checked, portefeuilleProjet);
            } else {
                // Suppression du suivi d'un portefeuille projet
                // (Suppression d'une ligne dans la table portefeuille_projet_utilisateur)
                suppressionSuiviPortefeuilleProjetUtilisateur(event.target.checked, portefeuilleProjet);
            }
        }
    }

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<PortefeuillesProjetsTableau>[] = [
        {
            Header: 'Suivi du portefeuille',
            accessor: 'is_suivi',
            disableFilters: true,
            disableSortBy: true,
            minWidth: 180,
            Cell: (props: CellProps<PortefeuillesProjetsTableau>) => {
                return (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={`portefeuille-projet-${props.row.original.id}`}
                                    checked={props.value}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChangePortefeuilleProjetSuivi(e, props.row.original.nom)}
                                />
                            }
                            label={
                                <Paper
                                    sx={{
                                        border: '1px solid rgba(0,0,0,0.2)',
                                        padding: "4px 10px",
                                        width: '120px',
                                        textAlign: 'center'
                                    }}
                                    elevation={0}
                                >
                                    {props.value ? "Ne plus suivre" : "Suivre"}
                                </Paper>
                            }
                        />
                    </Box>
                )
            }
        },
        {
            Header: 'Responsable du portefeuille projet',
            accessor: 'nom',
            minWidth: 180,
        }
    ];


    let portefeuilleProjetUtilisateur;
    if (isLoadingPortefeuillesProjets) {
        portefeuilleProjetUtilisateur = (
            <VoxLoader/>
        )
    } else if (isLoadingPortefeuillesProjetsError) {
        portefeuilleProjetUtilisateur = (
            <VoxAlert severity={"error"}>
                Une erreur est survenue lors du chargement des portefeuilles projets.
            </VoxAlert>
        )
    } else {
        portefeuilleProjetUtilisateur = (
            <>
                <VoxAlert severity={"info"}>
                    Il est possible de suivre des portefeuilles projets.<br/>
                    Les opérations rattachées à chaque portefeuille projet suivi apparaîtront sur votre écran d'accueil.
                </VoxAlert>
                {isErrorEnregistrement &&
                    <VoxAlert severity={"error"}>
                        {messageErreur}
                    </VoxAlert>
                }
                <VoxTable
                    data={listePortefeuillesProjets}
                    columns={columns}
                    rowProps={() => {
                        return {
                            style: {
                                height: "50px",
                                verticalAlign: "middle"
                            }
                        }
                    }}
                />
            </>
        )
    }

    return (
        <>
            <VoxHeader title={"Gestion de mes portefeuilles projets"}/>
            <Grid container justifyContent={"center"}>
                <Grid item xs={11} sm={9} md={6}>
                    {portefeuilleProjetUtilisateur}
                </Grid>
            </Grid>
        </>
    )
}