import IntroJS from './IntroJs';
import {allSteps} from "../../models/tutorial/allSteps.model";

export default function IntroJsDeclarerOperation() {

    // Contenu des étapes - Déclarer une opération de type : Intégré, Optivox, Grands Projets ou Actionnariat
    const steps: allSteps[] = [
        {
            // 1. Formulaire global
            element: ".MuiCard-root",
            position: "left",
            intro: <p>Cette page vous permet de déclarer une opération (Optivox / Intégré / Grand Projets / Actionnariat).</p>
        },
        {
            // 2. Champ "Portefeuille projet"
            element: "#champ-portefeuille-projet .MuiFormControl-root",
            position: "bottom-middle-aligned",
            intro: <p>Vous devez ici sélectionner un portefeuille projet afin de pouvoir assigner une opération à un périmètre d'opération.</p>
        },
        {
            // 3. Champ "Date d'installation"
            element: "#champ-date-installation .MuiFormControl-root",
            position: "bottom-middle-aligned",
            intro: <p>Cette date a pour vocation de préciser la date à laquelle vous souhaitez disposer de la plateforme. Ceci est à titre d'information et permet également la priorisation des actions d'initialisation au niveau de l'équipe SI Infra.</p>
        },
        {
            // 4. Champ "Produit"
            element: "#champ-produit .MuiFormControl-root",
            position: "bottom-middle-aligned",
            intro: <p>Le type de produit correspond à la solution applicative souhaitée pour l'opération.</p>
        },
        {
            // 5. Champ "Type de plateforme"
            element: "#champ-type-plateforme .MuiFormControl-root",
            position: "bottom-middle-aligned",
            intro: <p>Le type de plateforme pour l'opération doit être renseigné et normalement être communiqué dans la F2R par le commerce.</p>
        },
        {
            // 6. Champ "Préfixe du site de vote"
            element: "#champ-prefixe-site-vote .MuiFormControl-root",
            position: "top-middle-aligned",
            intro: (
                <>
                    <p>Le domaine utilisé est en fonction du type de produit ainsi que du type de plateforme souhaité.</p>
                    <p>Dans le cas des Optivox (VoxCore-Election), si vous choisissez le type de plateforme H1, alors vous aurez le choix entre 2 noms de domaine :</p>
                    <ul>
                        <li><span className="fw-bold">client</span>.voxintime.com</li>
                        <li><span className="fw-bold">client</span>.vote.voxaly.com</li>
                    </ul>
                </>
            )
        },
        {
            // 7. Bouton "Commander une opération"
            element: "#btn-commander-operation",
            position: "top-middle-aligned",
            intro: (
                <>
                    <p>Enfin, vous pouvez valider la déclaration de l'opération en cliquant sur ce bouton.</p>
                    <p className={"fw-bold"}>
                        Au clic sur ce bouton, aucune action automatique n'est déclenchée.
                        L'initialisation du site s'effectue dans un second temps par l'équipe SI Infra.
                    </p>
                </>
            )
        },
    ];

    return (

        <IntroJS
            steps={steps}
        />

    );

}