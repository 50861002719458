import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactFragment } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./labelFormulaire.css"
import { AsterisqueRouge } from "../pictogrammes/asterisqueRouge";

type LabelProps = {
    htmlFor: string;
    startIcon?: IconProp;
    children: ReactFragment;
    isChampObligatoire?: boolean;
}


export function LabelFormulaire(props: LabelProps) {

    return (
        <label htmlFor={props.htmlFor} className={"mb-0 form-label"}>
            <h4 className={"label-formulaire-style"}>
                {/* Icône */}
                {props.startIcon &&
                    <FontAwesomeIcon icon={props.startIcon} className={"me-2"}/>
                }

                {/* Texte du label */}
                {props.children}

                {/* Asterisk indiquant si le champ est obligatoire dans le formulaire */}
                {props.isChampObligatoire &&
                    <AsterisqueRouge className={"ms-1"}/>
                }

                <span className={"ms-1"}>:</span>
            </h4>
        </label>
    );
}