import React, { ChangeEvent, useState } from "react";
import './filtreAffichage.css';
import { FiltresAffichageDto } from "../../services/survox-back/operation/dtos/request/filtresAffichage.dto";
import { ResultatSurveillance } from "../../models/surveillance/resultatSurveillance.enum";
import { StatutOperation } from "../../models/operation/statutOperation.enum";
import { Periode } from "../../models/operation/periode.enum";
import { OperationPhase } from "../../models/operation/operationPhase.enum";
import { StatutPVDemat } from "../../models/operation/statutPVDemat.enum";
import { Box, Card, CardContent, Checkbox, Collapse, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


type FiltresAlertesType = {
    siteKO: boolean,
    certificatKO: boolean,
    scellementKO: boolean,
    demandesElecteursKO: boolean,
    horodatageKO: boolean,
    teletransmissionPvKO: boolean
};

type FiltresPerimetresOperationsType = {
    opFavorites: boolean,
    opPortefeuilles: boolean
};

type PropsType = {
    filtreAffichageDate?: StatutOperation | Periode | OperationPhase,
    filtresAffichageAlerte: FiltresAlertesType,
    filtresAffichagePerimetresOperations: FiltresPerimetresOperationsType,
    filtresOperationsEnProduction: (filtresAffichage: FiltresAffichageDto) => void,
    reinitialiserFiltresOperationsEnProduction: () => void,
    miseAJourFiltreDate: (filtreDate: StatutOperation | Periode | OperationPhase | undefined) => void,
    miseAJourFiltresAlerte: (filtreAlerte: FiltresAlertesType) => void,
    miseAJourFiltresPerimetresOperations: (filtresPerimetresOperations: FiltresPerimetresOperationsType) => void,
};

const checkboxSx = {
    marginLeft: 0
}

const radioSx = {
    marginLeft: 0,
    minHeight: "24px"
}

const typographySx = {
    marginY: 0.8
}


export default function FiltresAffichage(props: PropsType) {

    const [filtreDate, setFiltreDate] = useState(props.filtreAffichageDate);

    const [filtresAlerte, setFiltresAlerte] = useState<FiltresAlertesType>({
        siteKO: props.filtresAffichageAlerte.siteKO,
        certificatKO: props.filtresAffichageAlerte.certificatKO,
        scellementKO: props.filtresAffichageAlerte.scellementKO,
        demandesElecteursKO: props.filtresAffichageAlerte.demandesElecteursKO,
        horodatageKO: props.filtresAffichageAlerte.horodatageKO,
        teletransmissionPvKO: props.filtresAffichageAlerte.teletransmissionPvKO
    });

    const [filtresPerimetresOperations, setFiltresPerimetresOperations] = useState<FiltresPerimetresOperationsType>({
        opFavorites: props.filtresAffichagePerimetresOperations.opFavorites,
        opPortefeuilles: props.filtresAffichagePerimetresOperations.opPortefeuilles
    });

    const [openCollapseFiltres, setOpenCollapseFiltres] = useState(false);


    const handleApplyFilters = (event: any) => {
        event.preventDefault();

        const resultatsSurveillance: ResultatSurveillance[] = [];
        Object.keys(filtresAlerte).forEach((cle) => {
            if (cle === "siteKO" && filtresAlerte.siteKO) {
                resultatsSurveillance.push(ResultatSurveillance.KO);
            }
            else if (cle === "certificatKO" && filtresAlerte.certificatKO) {
                resultatsSurveillance.push(ResultatSurveillance.ERREUR_CERTIFICAT);
            }
            else if (cle === "scellementKO" && filtresAlerte.scellementKO) {
                resultatsSurveillance.push(ResultatSurveillance.SCELLEMENT_KO);
            }
            else if (cle === "demandesElecteursKO" && filtresAlerte.demandesElecteursKO) {
                resultatsSurveillance.push(ResultatSurveillance.DEMANDE_ELECTEUR_KO);
            }
        });

        // Appel de la méthode pour appliquer les filtres sélectionnés sur les données
        props.filtresOperationsEnProduction({
            filtrePeriode: filtreDate,
            resultatsSurveillance: resultatsSurveillance,
            horodatageCertinomis: (filtresAlerte.horodatageKO) ? false : null,   // si alerte sélectionnée, alors on veut les horodatages dont la valeur est 'false'
            teletransmissionPVDemat: (filtresAlerte.teletransmissionPvKO) ? StatutPVDemat.ECHEC : null,
            operationsFavoritesUtilisateur: filtresPerimetresOperations.opFavorites,
            operationsPortefeuillesSuivisUtilisateur: filtresPerimetresOperations.opPortefeuilles,
        })

        // Mise à jour du filtre sur la date (dans le composant plus général)
        props.miseAJourFiltreDate(filtreDate);
        // Mise à jour des filtres alerte (dans le composant plus général)
        props.miseAJourFiltresAlerte(filtresAlerte);
        // Mise à jour des filtres sur les périmètres opérations (dans le composant plus général)
        props.miseAJourFiltresPerimetresOperations(filtresPerimetresOperations);
    }

    function handleReinitialiserFiltres() {
        return props.reinitialiserFiltresOperationsEnProduction();
    }

    /**
     * Appel de cette méthode lors du clic sur le radio bouton, afin de mettre à jour la valeur du filtre sur les dates
     * @param event
     */
    function handleOnChangeFiltreDateTrueFalse(event: any) {
        setFiltreDate(event.target.value);
    }

    /**
     * Appel de cette méthode lors du clic sur la checkbox, afin de mettre à jour la valeur des filtres sur les alertes
     * @param event
     */
    function handleOnChangeFiltresAlerteTrueFalse(event: ChangeEvent<HTMLInputElement>) {
        setFiltresAlerte((prevState: FiltresAlertesType) => ({...prevState, [event.target.id]: event.target.checked}));
    }

    /**
     * Appel de cette méthode lors du clic sur la checkbox, afin de mettre à jour la valeur des filtres sur périmètres opérations
     * @param event
     */
    function handleOnChangeFiltresPerimetresOperationsTrueFalse(event: ChangeEvent<HTMLInputElement>) {
        setFiltresPerimetresOperations((prevState: FiltresPerimetresOperationsType) => ({...prevState, [event.target.id]: event.target.checked}));
    }

    return(
        <>
            <VoxButton
                id={"collapse-filtres-btn"}
                variant={"outlined"}
                startIcon={<FontAwesomeIcon icon={"filter"}/>}
                endIcon={<FontAwesomeIcon icon={"caret-down"}/>}
                aria-controls={"collapseFiltres"}
                aria-expanded={openCollapseFiltres}
                onClick={() => setOpenCollapseFiltres(!openCollapseFiltres)}
            >
                Filtres d'affichage
            </VoxButton>
            <Collapse in={openCollapseFiltres}>
                <Card id={"collapseFiltres"}
                      variant={"outlined"}
                      sx={{mt: 1, borderRadius: "24px", borderColor: "#0000FF"}}
                >
                    <CardContent>
                        <Box component={"form"} onSubmit={handleApplyFilters}>
                            <Grid container rowGap={3}>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={"h3"} sx={typographySx}>Filtre sur la date</Typography>
                                    <RadioGroup
                                        aria-label={"Filtrer la liste des opérations en production par date"}
                                        name={"filtreDate"}
                                        value={filtreDate ? filtreDate : "" }
                                        onChange={handleOnChangeFiltreDateTrueFalse}
                                    >
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={StatutOperation.AVANT_VOTE}
                                            control={<Radio id={"avantVote"}/>}
                                            label={"En avant-vote"}
                                            defaultChecked={(filtreDate === StatutOperation.AVANT_VOTE)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={StatutOperation.OUVERTURE}
                                            control={<Radio id={"ouverture"}/>}
                                            label={"Ouverture aujourd'hui"}
                                            defaultChecked={(filtreDate === StatutOperation.OUVERTURE)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={StatutOperation.VOTE}
                                            control={<Radio id={"enCoursDeVote"}/>}
                                            label={"En cours de vote"}
                                            defaultChecked={(filtreDate === StatutOperation.VOTE)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={StatutOperation.CLOTURE}
                                            control={<Radio id={"cloture"}/>}
                                            label={"Clôture aujourd'hui"}
                                            defaultChecked={(filtreDate === StatutOperation.CLOTURE)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={Periode.SEMAINE_COURANTE}
                                            control={<Radio id={"ouvertureJ7"}/>}
                                            label={"Ouverture à J + 7"}
                                            defaultChecked={(filtreDate === Periode.SEMAINE_COURANTE)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={Periode.SEMAINES_COURANTE_ET_SUIVANTE}
                                            control={<Radio id={"ouverture2semaines"}/>}
                                            label={"Ouverture sur 2 semaines"}
                                            defaultChecked={(filtreDate === Periode.SEMAINES_COURANTE_ET_SUIVANTE)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={Periode.UN_MOIS}
                                            control={<Radio id={"ouvertureMois"}/>}
                                            label={"Ouverture sur un mois"}
                                            defaultChecked={(filtreDate === Periode.UN_MOIS)}
                                        />
                                        <FormControlLabel
                                            sx={radioSx}
                                            value={OperationPhase.ENTRET1T2}
                                            control={<Radio id={"entreDeuxTours"}/>}
                                            label={"Entre deux tours"}
                                            defaultChecked={(filtreDate === OperationPhase.ENTRET1T2)}
                                        />
                                    </RadioGroup>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Typography variant={"h3"} sx={typographySx}>Alerte</Typography>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"siteKO"} onChange={handleOnChangeFiltresAlerteTrueFalse}/>}
                                            label={"Site KO"}
                                            checked={filtresAlerte.siteKO}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"certificatKO"} onChange={handleOnChangeFiltresAlerteTrueFalse}/>}
                                            label={"Certificat KO"}
                                            checked={filtresAlerte.certificatKO}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"scellementKO"} onChange={handleOnChangeFiltresAlerteTrueFalse}/>}
                                            label={"Scellement KO"}
                                            checked={filtresAlerte.scellementKO}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"demandesElecteursKO"} onChange={handleOnChangeFiltresAlerteTrueFalse}/>}
                                            label={"Demandes électeurs KO"}
                                            checked={filtresAlerte.demandesElecteursKO}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"horodatageKO"} onChange={handleOnChangeFiltresAlerteTrueFalse}/>}
                                            label={"Horodatage KO"}
                                            checked={filtresAlerte.horodatageKO}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"teletransmissionPvKO"} onChange={handleOnChangeFiltresAlerteTrueFalse}/>}
                                            label={"Télétransmission PV KO"}
                                            checked={filtresAlerte.teletransmissionPvKO}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Typography variant={"h3"} sx={typographySx}>Périmètres opérations</Typography>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"opFavorites"} onChange={handleOnChangeFiltresPerimetresOperationsTrueFalse}/>}
                                            label={"Opérations favorites"}
                                            checked={filtresPerimetresOperations.opFavorites}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            sx={checkboxSx}
                                            control={<Checkbox id={"opPortefeuilles"} onChange={handleOnChangeFiltresPerimetresOperationsTrueFalse}/>}
                                            label={"Opérations des portefeuilles"}
                                            checked={filtresPerimetresOperations.opPortefeuilles}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Box sx={{mt: 3, display: "flex", justifyContent: "space-evenly"}}>
                                <VoxButton
                                    variant={"outlined"}
                                    onClick={handleReinitialiserFiltres}
                                    sx={{width: "210px"}}
                                >
                                    Réinitialiser les filtres
                                </VoxButton>
                                <VoxButton
                                    customColor={"green"}
                                    startIcon={<FontAwesomeIcon icon={"filter"}/>}
                                    type={"submit"}
                                    sx={{width: "210px"}}
                                >
                                    Appliquer les filtres
                                </VoxButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Collapse>
        </>
    )
}