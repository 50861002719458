import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import versionApplicativeService from "../../../services/survox-back/versionApplicative/version-applicative.service";
import { VersionApplicative } from "../../../models/versionApplicative/versionApplicative.model";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, MenuItem, SelectChangeEvent, Tab } from "@mui/material";
import { VoxSelectInput } from "../../../components/genericComponentsUI/input/voxSelectInput";
import { TypeProduit } from "../../../models/versionApplicative/typeProduit.enum";
import { Composant } from "../../../models/versionApplicative/composant.model";
import TabPanel from "../../../components/layouts/tabs/TabPanel";
import TabContent from "../../../components/layouts/tabs/TabContent";
import { VersionsApplicativesListe } from "../../../components/versionsProduitsListe/versionApplicativeListe";
import { ComposantsListe } from "../../../components/versionsProduitsListe/composantsListe";
import { isRequestCanceled } from "../../../services/survox-back/axios-client";
import { VoxTabs } from "../../../components/layouts/tabs/VoxTabs";
import composantService from "../../../services/survox-back/versionApplicative/composant.service";


export default function VersionsDesProduitsGestion() {

    // Gestion des URL Query params
    let [searchParams] = useSearchParams();
    const urlTypeProduit = searchParams.get("type");

    // Le type de produit 'VOXCORE_ELECTION' est affiché par défaut à l'arrivée sur la page des "Versions des produits"
    const [selectedTypeProduit, setSelectedTypeProduit] = useState<TypeProduit>(
        Object.values(TypeProduit).find(typeProduit => typeProduit === urlTypeProduit) ?? TypeProduit.VOXCORE_ELECTION
    );

    // Liste des versions applicatives récupérées depuis l'API survox-back
    const [versionsApplicatives, setVersionsApplicatives] = useState<VersionApplicative[]>([]);
    const [isLoadingVersionsApplicatives, setIsLoadingVersionsApplicatives] = useState<boolean>(false);
    const [isErrorLoadingVersionsApplicatives, setIsErrorLoadingVersionsApplicatives] = useState<boolean>(false);

    // Liste des composants récupérés depuis l'API survox-back
    const [composants, setComposants] = useState<Composant[]>([]);
    const [isLoadingComposants, setIsLoadingComposants] = useState<boolean>(false);
    const [isErrorLoadingComposants, setIsErrorLoadingComposants] = useState<boolean>(false);

    // Gestion des Onglets
    const [activeTab, setActiveTab] = useState<number>(0);

    /**
     * Méthode permettant de mettre à jour l'état stockant l'index de l'onglet actif
     * @param _event L'evénement émit lors du clique sur un onglet
     * @param newTabIndex L'index de l'onglet sur lequel il y a eu un clique
     */
    const handleOnChangeTabs = (_event: SyntheticEvent, newTabIndex: number) => setActiveTab(newTabIndex);

    /**
     * Méthode permettant de mettre à jour le type de produit dont les versions et composants sont affichés
     * @param value la valeur du type de produit souhaité
     */
    const handleOnChangeTypeProduit = useCallback((value: string) => {
        const newSelectedTypeProduit = Object.values(TypeProduit).find(typeProduit => typeProduit === value);
        if (newSelectedTypeProduit) {
            setSelectedTypeProduit(newSelectedTypeProduit);
        } else {
            setSelectedTypeProduit(TypeProduit.VOXCORE_ELECTION);
        }
    }, []);

    /** 
     * Effect permettant de mettre à jour le type de produit sélectionné en fonction des URL Query params
     */
    useEffect(
        () => { if (urlTypeProduit) handleOnChangeTypeProduit(urlTypeProduit); }, 
        [urlTypeProduit, handleOnChangeTypeProduit]
    );

    /**
     * Chargement des versions applicatives suivant le type de produit sélectionné
     */
    const loadVersionsApplicatives = (typeProduit: TypeProduit, controller?: AbortController) => {
        setIsLoadingVersionsApplicatives(true);
        setIsErrorLoadingVersionsApplicatives(false);
        versionApplicativeService.getAllVersionApplicativeByTypeProduitWithArchiveFalse(typeProduit, controller?.signal)
            .then(response => { if (response.status === 200) setVersionsApplicatives(response.data); })
            .catch(err => { if (!isRequestCanceled(err)) setIsErrorLoadingVersionsApplicatives(true); })
            .finally(() => setIsLoadingVersionsApplicatives(false));
    }

    /**
     * Chargement des composants suivant le type de produit sélectionné
     */
    const loadComposants = (TypeProduit: TypeProduit, controller?: AbortController) => {
        setIsLoadingComposants(true);
        setIsErrorLoadingComposants(false);
        composantService.getAllComposantByTypeProduit(TypeProduit, controller?.signal)
            .then(response => { if (response.status === 200) setComposants(response.data); })
            .catch(err => { if (!isRequestCanceled(err)) setIsErrorLoadingComposants(true); })
            .finally(() => setIsLoadingComposants(false));
    }

    const loadData = useCallback((controller?: AbortController) => {
        loadVersionsApplicatives(selectedTypeProduit, controller);
        // Chargement des composants uniquement lorsque le type de produit sélectionné est VoxCore-Election
        if (selectedTypeProduit === TypeProduit.VOXCORE_ELECTION) loadComposants(selectedTypeProduit, controller);
    }, [selectedTypeProduit]);

    /**
     * Effect permettant de charger les versions applicatives et composants si néscessaires.
     * À l'état initial et lorsque le type de produit est mis à jour
     */
    useEffect(() => {
        const controller = new AbortController();
        loadData(controller);
        return () => controller.abort();
    }, [selectedTypeProduit, loadData]);


    let content;

    if (selectedTypeProduit === TypeProduit.VOXCORE_ELECTION) { 
        // Dans le cas des produits VoxCore-Election affiche des onglets permettant de visualiser la liste des versions applicatives et la liste des composants
        content = (
            <Box>
                <VoxTabs activeTab={ activeTab } handleOnChangeTabs={ handleOnChangeTabs }>
                    <Tab label="Versions applicatives" id="tab-0" aria-controls="tab-panel-0" />
                    <Tab label="Composants" id="tab-1" aria-controls="tab-panel-1" />
                </VoxTabs>

                {/* Onglet Versions applicatives VoxCore-Election */}
                <TabPanel index={ 0 }  value={ activeTab }>
                    <TabContent 
                        isLoading={ isLoadingVersionsApplicatives }
                        isLoadError={ isErrorLoadingVersionsApplicatives }
                        messageError={ "Une erreur est survenue lors du chargement des versions applicative VoxCore-Election" }
                    >  
                        <VersionsApplicativesListe 
                            typeProduit={ selectedTypeProduit }
                            versionsApplicatives={ versionsApplicatives }
                            loadData={ loadData }
                            isLoadingVersionsApplicatives = { isLoadingVersionsApplicatives }
                        />  
                    </TabContent>
                </TabPanel>

                {/* Onglet Composants VoxCore-Election */}
                <TabPanel index={ 1 } value={ activeTab }>
                    <TabContent 
                        isLoading={ isLoadingComposants }
                        isLoadError={ isErrorLoadingComposants }
                        messageError={ "Une erreur est survenue lors du chargement des composants VoxCore-Election" }
                    >
                        <ComposantsListe 
                            typeProduit={ selectedTypeProduit }
                            composants={ composants }
                            loadComposants={ loadComposants }
                        />   
                    </TabContent>
                </TabPanel>
            </Box>
        );
    } else {
        // Dans le cas des produits autres que VoxCore-Election affiche la liste des versions applicatives
        content = (
            <VersionsApplicativesListe
                typeProduit={ selectedTypeProduit }
                versionsApplicatives={ versionsApplicatives }
                isLoadingVersionsApplicatives={ isLoadingVersionsApplicatives }
                loadData={ loadData }
            />
        );
    }

    return (
        <>
            <Grid container>
                <Grid item xs={8} sm={5}>
                    <VoxSelectInput
                        id={'versionProduit'}
                        idLabel={'versionProduit-label'}
                        label={'Sélectionner un type de produit :'}
                        value={selectedTypeProduit}
                        onChange={(event: SelectChangeEvent<string>) => handleOnChangeTypeProduit(event.target.value)}
                        fullWidth
                    >
                        {
                            Object.values(TypeProduit).map(typeProduit => <MenuItem key={ typeProduit } value={ typeProduit }>{ typeProduit }</MenuItem>)
                        }
                    </VoxSelectInput>
                </Grid>
            </Grid>
            <Box marginTop={5}>
                { content }
            </Box>
        </>
    )
}