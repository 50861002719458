import axios_instance from "../axios-client";

const portefeuilleProjetUtilisateurService = {

    ajoutSuiviPortefeuilleProjet: (idPortefeuilleProjet: number) => {
        return axios_instance.post("/portefeuilles_projets_utilisateur", {idPortefeuilleProjet});
    },

    suppressionSuiviPortefeuilleProjet: (idPortefeuilleProjet: number) => {
        return axios_instance.delete(`/portefeuilles_projets_utilisateur/${idPortefeuilleProjet}`);
    },

}

export default portefeuilleProjetUtilisateurService;