import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Audit } from "../../../models/audit/audit.model";
import auditService from "../../../services/survox-back/audit/audit.service";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import dayjs from "dayjs";
import { CellProps, Column, IdType, Row } from "react-table";
import { affichageDateWithFormatDMYHm } from "../../../utils/string.utils";
import { sortDatesInTables } from "../../../utils/date.utils";
import ConcatenedStringColumnTypeCell from "../../../components/genericComponentsUI/table/cells/concatenedStringColumnTypeCell";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import {SelectInputFilter} from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";

type PropsType = { operationSIRSId: number };

export default function OperationsSIRSDetailAudit(props: PropsType) {

    // Utilisation d'une ref pour connaître l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [tableAuditOpeSIRS, setTableAuditOpeSIRS] = useState<Audit[]>([]);
    const [isLoadingTableAudit, setIsLoadingTableAudit] = useState<boolean>(false);


    /**
     * Chargement des données de la table d'audit SIRS
     */
    const chargerTableAuditOpeSIRS = useCallback((controller: AbortController) => {
        setIsLoadingTableAudit(true);
        auditService.getAuditByOperationSIRSId(props.operationSIRSId, controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setTableAuditOpeSIRS(response.data);
                    setIsLoadingTableAudit(false);
                }
            })
            .catch(() => {
                if (isMountedRef.current) {
                    setIsLoadingTableAudit(false);
                }
            });
    }, [props.operationSIRSId])


    // Chargement de la page
    useEffect(() => {
        isMountedRef.current = true;
        const controller = new AbortController();
        chargerTableAuditOpeSIRS(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [chargerTableAuditOpeSIRS])


    /**
     * Remplissage des lignes du tableau
     */
    const rows: any[] = useMemo(() => {

        return tableAuditOpeSIRS.map(
            (audit: Audit) => ({
                id: audit.id,
                utilisateur: audit.utilisateur.prenom + ' ' + audit.utilisateur.nom,
                type: audit.type,
                valeurAvant: audit.valeurAvant,
                valeurApres: audit.valeurApres,
                dateDebutMillisecondes: Number(dayjs(audit.dateDebut, 'x')),    // 'x' : format Unix ms timestamp
                dateDebut: audit.dateDebut,
                dateFin: audit.dateFin,
                description: audit.description
            })
        ).sort((a,b) => a.dateDebutMillisecondes - b.dateDebutMillisecondes)

    }, [tableAuditOpeSIRS])


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<Audit>> = useMemo(() => [
        {
            Header: 'Utilisateur',
            accessor: 'utilisateur',
            minWidth: 150
        },
        {
            Header: 'Type',
            accessor: 'type',
            minWidth: 170,
            Filter: SelectInputFilter,
            filter: "includes"
        },
        {
            Header: 'Valeur avant',
            accessor: 'valeurAvant',
            minWidth: 200,
            Cell: (props: CellProps<Audit>) => <ConcatenedStringColumnTypeCell cellProps={props}/>
        },
        {
            Header: 'Valeur après',
            accessor: 'valeurApres',
            minWidth: 200,
            Cell: (props: CellProps<Audit>) => <ConcatenedStringColumnTypeCell cellProps={props}/>
        },
        {
            Header: 'Date de début',
            id: 'dateDebut',
            accessor: (originalRow: Audit) => affichageDateWithFormatDMYHm(originalRow.dateDebut),
            sortType: (a: Row<Audit>,
                       b: Row<Audit>,
                       columnId: IdType<Audit>,
                       desc: boolean | undefined) => sortDatesInTables(a.original.dateDebut, b.original.dateDebut, desc),
            minWidth: 150
        },
        {
            Header: 'Date de fin',
            id: 'dateFin',
            accessor: (originalRow: Audit) => affichageDateWithFormatDMYHm(originalRow.dateFin),
            sortType: (a: Row<Audit>,
                       b: Row<Audit>,
                       columnId: IdType<Audit>,
                       desc: boolean | undefined) => sortDatesInTables(a.original.dateFin, b.original.dateFin, desc),
            minWidth: 150
        },
        {
            Header: 'Description',
            accessor: 'description',
            minWidth: 140
        },
    ], [])


    return (
        <>
            {isLoadingTableAudit ? <VoxLoader/> :
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialStateSortBy={[{id: 'dateFin', desc: true}]}
                    cellProps={(cell: CellProps<Audit>) => {
                        if(["valeurAvant", "valeurApres", "description"].includes(cell.column.id)) {
                            return {
                                style: {textAlign: 'left'}
                            }
                        }
                    }}
                />
            }
        </>
    )
}