import React, { useContext, useEffect, useState } from "react";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import parametrageService from "../../../services/survox-back/parametrage.service";
import { Parametrage } from "../../../models/parametrage/parametrage.model";
import { TypeParametre } from "../../../models/parametrage/typeParametre.enum";
import { ValeurBooleenneParametrage } from "../../../models/parametrage/valeurBooleenneParametrage.enum";
import { ParametresContext } from "../../../contexts/parametrage/parametres.context";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import { VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import { Box, Checkbox, FormControlLabel, List, ListItem, Typography } from "@mui/material";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";

type ListeParametrage = {
    blocageSitesVoxcoreElection?: boolean,
    blocageSitesVoxcoreAG?: boolean
}


export default function ParametrageListe () {

    const parametresContext = useContext(ParametresContext);

    const [listeParametrage, setListeParametrage] = useState<Parametrage[]>([]);

    const [valeursParametrage, setValeursParametrage] = useState<ListeParametrage>({
        blocageSitesVoxcoreElection: undefined,
        blocageSitesVoxcoreAG: undefined
    });

    const [isLoadingParametres, setIsLoadingParametres] = useState(false);
    const [isLoadingUpdateParametrage, setIsLoadingUpdateParametrage] = useState(false);
    const [isSuccesUpdateParametrage, setIsSuccesUpdateParametrage] = useState(false);
    const [isUpdateError, setIsUpdateError] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        chargerParametres(controller);
        return () => controller.abort();
    },[]);

    const chargerParametres = (controller: AbortController) => {
        setIsLoadingParametres(true);
        parametrageService.getAllParametrages(controller.signal)
            .then(response => {
                if (response.status === 200) {
                    const parametrages: Parametrage[] = response.data;
                    setListeParametrage(parametrages);
                    setIsLoadingParametres(false);
                }
            })
            .catch(() => {
                setIsLoadingParametres(false);
            });
    }

    useEffect(() => {
        listeParametrage.forEach((parametrage: Parametrage) => {
            if (parametrage.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_ELECTION) {
                const newValue: boolean = (parametrage.valeur === ValeurBooleenneParametrage.TRUE);     // si la condition est vérifiée, valeur égale TRUE
                setValeursParametrage(prevState  => ({...prevState, "blocageSitesVoxcoreElection": newValue}));
            }
            if (parametrage.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_AG) {
                const newValue: boolean = (parametrage.valeur === ValeurBooleenneParametrage.TRUE);     // si la condition est vérifiée, valeur égale TRUE
                setValeursParametrage(prevState  => ({...prevState, "blocageSitesVoxcoreAG": newValue}));
            }
        })
    }, [listeParametrage]);

    /**
     * Méthode appelée dès qu'une case est sélectionnée ou désélectionnée
     * afin de mettre à jour la valeur du champ "valeur" de le paramétrage correspondante
     */
    const handleOnChangeParametrage = (event: any) => {
        const newStateParametrage = listeParametrage.map(obj => {
            let idParametrage : number = 0;    // pas d'id connu
            if (event.target.id === "blocageSitesVoxcoreElection") {
                const parametrage = listeParametrage.find(parametre => parametre.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_ELECTION);
                idParametrage = (parametrage) ? parametrage.id : 0;
            } else if (event.target.id === "blocageSitesVoxcoreAG") {
                const parametrage = listeParametrage.find(param => param.typeParametre === TypeParametre.MAINTENANCE_BLOCAGE_INITIALISATION_SITES_VOXCORE_AG);
                idParametrage = (parametrage) ? parametrage.id : 0;
            }

            // si l'id est égal à l'index du Parametrage sélectionné, on met à jour la valeur
            if (obj.id === idParametrage) {
                return {...obj, valeur: String(event.target.checked)}
            }
            // sinon on renvoie l'objet Parametrage tel quel
            else {
                return obj;
            }
        });
        setListeParametrage(newStateParametrage);
        const newValue: boolean = (event.target.checked === ValeurBooleenneParametrage.TRUE);     // si la condition est vérifiée, valeur égale TRUE
        setValeursParametrage(prevState => ({...prevState, [event.target.id]: newValue}));
    }

    /**
     * Mise a jour des valeurs du paramétrage (en base de données)
     */
    const updateParametrage = () => {
        setIsLoadingUpdateParametrage(true);
        setIsSuccesUpdateParametrage(false);
        setIsUpdateError(false);
        parametrageService.updateParametrage(listeParametrage)
            .then((response) => {
                if (response.status === 200) {
                    setIsLoadingUpdateParametrage(false);
                    setIsSuccesUpdateParametrage(true);
                    setIsUpdateError(false);
                    /**
                     * Mise à jour du paramétrage dans le contexte de maintenance de l'application
                     */
                    parametresContext.updateParametrage();
                }
            })
            .catch(() => {
                setIsLoadingUpdateParametrage(false);
                setIsSuccesUpdateParametrage(false);
                setIsUpdateError(true);
            });
    }


    return (
        <>
            {(isUpdateError) &&
                <VoxAlert severity={"error"}>
                    Une erreur est survenue.
                </VoxAlert>
            }
            {(isSuccesUpdateParametrage) &&
                <VoxAlert severity={"success"} sx={{mb: 2}} onClose={() => setIsSuccesUpdateParametrage(false)}>
                    Les valeurs du paramétrage ont été mises à jour.
                </VoxAlert>
            }

            {(isLoadingParametres) ?
                <VoxLoader />
                :
                ((valeursParametrage.blocageSitesVoxcoreElection !== undefined && valeursParametrage.blocageSitesVoxcoreAG !== undefined) &&
                    <VoxCard
                        iconBeforeTitle={"wrench"}
                        title={"Déploiement automatisé OVH"}
                        sxContent={{padding: '0 16px 8px !important'}}
                    >
                        <List sx={{
                            paddingBottom: 0,
                            '& > .MuiListItem-root': {padding: "16px", borderBottom: '1px solid rgba(0,0,0,0.1)'}
                        }}>

                            <ListItem>
                                <FormControlLabel
                                    sx={{mx: 0}}
                                    control={<Checkbox sx={{mr: 1}} id={"blocageSitesVoxcoreElection"} onChange={handleOnChangeParametrage}/>}
                                    checked={valeursParametrage.blocageSitesVoxcoreElection}
                                    label={
                                        <>
                                            <Typography variant={"body1"}>
                                                Bloquer l'initialisation des sites VoxCore-Election (dont Saturne et Maileva)
                                            </Typography>
                                            <Typography fontStyle={"italic"} color={"cobalt.lynch"}>
                                                (les utilisateurs ne pourront plus installer de VMs pour le type de produit VoxCore-Election)
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>

                            <ListItem>
                                <FormControlLabel
                                    sx={{mx: 0}}
                                    control={<Checkbox sx={{mr: 1}} id={"blocageSitesVoxcoreAG"} onChange={handleOnChangeParametrage}/>}
                                    checked={valeursParametrage.blocageSitesVoxcoreAG}
                                    label={
                                        <>
                                            <Typography variant={"body1"}>
                                                Bloquer l'initialisation des sites VoxCore-AG
                                            </Typography>
                                            <Typography fontStyle={"italic"} color={"cobalt.lynch"}>
                                                (les utilisateurs ne pourront plus installer de VMs pour le type de produit VoxCore-AG)
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>

                        </List>

                        <Box textAlign={"center"} pt={"18px"} pb={"10px"}>
                            <VoxButton
                                customColor={"green"}
                                onClick={updateParametrage}
                            >
                                Appliquer
                            </VoxButton>
                        </Box>

                        {
                            (isLoadingUpdateParametrage) && <VoxLoader isBackgroundColor={true}/>
                        }
                    </VoxCard>
                )
            }
        </>
    )
}