import { ReactElement } from "react";

type ConditionalWrapperPropsType = {
    condition: boolean | undefined,
    wrapper: (children: ReactElement) => ReactElement,
    children: ReactElement
}

/**
 * Fonction permettant d'entourer un élément JSX d'un autre si une condition est respectée.
 *
 * @example
 * <VoxConditionalWrapper
 *     condition={link}
 *     wrapper={children => <a href="{link}">{children}</a>
 * >
 *     <img src="img.jpg" alt="" />
 * </VoxConditionalWrapper>
 *
 * @description Dans cet exemple, si la variable "link" != null, alors l'image sera entourée d'un lien et deviendra cliquable.
 */
export default function VoxConditionalWrapper(props: ConditionalWrapperPropsType) {

    return (
        props.condition ? props.wrapper(props.children) : props.children
    )
}