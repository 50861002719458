import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Cell, CellProps, Column } from "react-table";
import BooleanColumnTypeCell from "../../../components/genericComponentsUI/table/cells/booleanColumnTypeCell";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../../models/utilisateur/utilisateur.model";
import { OperationSIRSProduction } from "../../../models/operationSIRS/operationSIRSProduction/operationSIRSProduction.model";
import { OperationSIRSProductionTableau } from "../../../models/operationSIRS/operationSIRSProduction/operationSIRSProductionTableau.model";
import { ResultatSurveillance } from "../../../models/surveillance/resultatSurveillance.enum";
import { FiltresAlertesSIRS } from "../../../models/operationSIRS/operationSIRSProduction/filtresAlertesSIRS.model";
import { FiltresAffichageSIRS } from "../../../models/operationSIRS/operationSIRSProduction/filtresAffichageSIRS.model";
import { NomGroupe } from "../../../models/utilisateur/nomGroupe.enum";
import FiltresAffichageOperationsSIRS from "../../../components/filtresAffichage/filtresAffichageOperationsSIRS";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import ModaleConfirmationAcquittement from "../../../components/modals/modaleConfirmationAcquittement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import operationSIRSService from "../../../services/survox-back/operationSIRS/operationSIRS.service";
import { csvDataSerializationOperationProductionSIRS } from "../../../utils/csv.utils";
import { getHiddenColumnsFromVisible } from "../../../components/genericComponentsUI/table/columns/columnsTable";
import { TypePrestation } from "../../../models/operationSIRS/typePrestation.enum";
import VoxLinkOperation from "../../../components/genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import { SelectInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";
import { SelectBooleanInputFilter } from "../../../components/genericComponentsUI/table/filters/SelectBooleanInputFilter";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import { Grid, Tooltip, Typography } from "@mui/material";


export default function OperationsSIRSEnProductionListe() {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // Récupération de l'utilisateur connecté à l'application
    const authContext = useContext(AuthContext);
    const utilisateurAuth: Utilisateur = authContext.utilisateur;

    // Groupes autorisés pour l'acquittement d'une opération SIRS
    const isAuthorizedAcquittementOperationSIRS = useMemo(() => (
        utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR
        || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
        || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_DEV_SIRS
    ), [utilisateurAuth.groupe.nom]);

    // Gestion de la récupération des données des operations SIRS
    const [operationsSIRSEnProduction, setOperationsSIRSEnProduction] = useState<OperationSIRSProduction[]>([]);
    const [isLoadingOperationsSIRSEnProduction, setIsLoadingOperationsSIRSEnProduction] = useState<boolean>(false);
    const [isErrorGetOperationsSIRSEnProduction, setIsErrorGetOperationsSIRSEnProduction] = useState<boolean>(false);
    const [errorMessageGetOperationsSIRSEnProduction, setErrorMessageGetOperationsSIRSEnProduction] = useState<string>("");

    // Gestion de l'acquittement des opérations
    const [isShowAcquittementModale, setIsShowAcquittementModale] = useState<boolean>(false);
    const [selectedOperationAcquittement, setSelectedOperationAcquittement] = useState<OperationSIRSProduction>();
    const [isLoadingAcquittementOperation, setIsLoadingAcquittementOperation] = useState<boolean>(false);
    const [isSuccessAcquittementOperation, setIsSuccessAcquittementOperation] = useState<boolean>(false);
    const [isErrorAcquittementOperation, setIsErrorAcquittementOperation] = useState<boolean>(false);

    // Gestion des filtres
    const [filtresAlertes, setFiltresAlertes] = useState<FiltresAlertesSIRS>({siteKO: false});

    const [searchParams] = useSearchParams();
    const paramResultatSurveillance = (searchParams.getAll("surveillance").length !== 0);   // true si la condition est remplie
    const paramTypePrestation = (searchParams.getAll("prestation").length !== 0);

    const [isAppelFonctionFiltresAffichageSIRS, setIsAppelFonctionFiltresAffichageSIRS] = useState(false);

    const [hiddenColumns, setHiddenColumns] = useState<(string | undefined)[]>([]);

    /**
     * Chargement des opérations SIRS en production
     */
    const chargerOperationSIRSsEnProduction = useCallback((controller?: AbortController) => {
        setIsLoadingOperationsSIRSEnProduction(true);
        setIsErrorGetOperationsSIRSEnProduction(false);
        setErrorMessageGetOperationsSIRSEnProduction("");
        operationSIRSService.getAllOperationSIRSEnProduction(undefined, controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setOperationsSIRSEnProduction(response.data);
                    setIsLoadingOperationsSIRSEnProduction(false);
                    setErrorMessageGetOperationsSIRSEnProduction("");
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoadingOperationsSIRSEnProduction(false);
                    setIsErrorGetOperationsSIRSEnProduction(true);
                    setErrorMessageGetOperationsSIRSEnProduction("Une erreur est survenue lors du chargement des opérations SIRS en production");
                }
            });
    }, []);


    /**
     * Filtre avec paramètres dans l'URL (sur le type de prestation, ainsi que sur les alertes) pour le Dashboard
     */
    const filtrerOperationsSIRSEnProductionParURL = useCallback((controller?: AbortController) => {
        setIsLoadingOperationsSIRSEnProduction(true);

        // Indicateurs Environnements
        let typePrestation: TypePrestation | null = null;
        if (paramTypePrestation) {
            searchParams.getAll("prestation").forEach(type => {
                if (type === TypePrestation.MY_BDES) {
                    typePrestation = TypePrestation.MY_BDES;
                } else if (type === TypePrestation.UNLIMITED) {
                    typePrestation = TypePrestation.UNLIMITED;
                } else {
                    typePrestation = null;
                }
            });
        }

        // Alertes Supervision Technique
        const resultatsSurveillance: ResultatSurveillance[] = [];
        if (paramResultatSurveillance) {
            searchParams.getAll("surveillance").forEach((surveillance) => {
                if (surveillance === ResultatSurveillance.KO) {
                    resultatsSurveillance.push(ResultatSurveillance.KO);
                    setFiltresAlertes((prevState: FiltresAlertesSIRS) => ({...prevState, 'siteKO': true}));
                }
                if (surveillance === ResultatSurveillance.ERREUR_CERTIFICAT) {
                    resultatsSurveillance.push(ResultatSurveillance.ERREUR_CERTIFICAT);
                }
            });
        }

        /**
         * Application des filtres trouvés sur les opérations SIRS en production
         */
        operationSIRSService.getAllOperationSIRSEnProduction(
            {
                typePrestation: typePrestation !== null ? typePrestation : undefined,
                resultatsSurveillance: resultatsSurveillance
            },
            controller?.signal
        )
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    const operationsSIRSListe = response.data;
                    setOperationsSIRSEnProduction(operationsSIRSListe);
                    setIsLoadingOperationsSIRSEnProduction(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoadingOperationsSIRSEnProduction(false);
                }
            })

    }, [paramResultatSurveillance, paramTypePrestation, searchParams]);


    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        if (paramResultatSurveillance || paramTypePrestation) {
            filtrerOperationsSIRSEnProductionParURL(controller);
        } else if (!isAppelFonctionFiltresAffichageSIRS) {
            chargerOperationSIRSsEnProduction(controller);
        }
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [paramResultatSurveillance, paramTypePrestation, filtrerOperationsSIRSEnProductionParURL, isAppelFonctionFiltresAffichageSIRS, chargerOperationSIRSsEnProduction])


    /**
     * Mapping des données pour les lignes du tableau
     */
    const rows: OperationSIRSProductionTableau[] = useMemo(() => {
        return operationsSIRSEnProduction.map(op => ({
            id: op.id,
            nomClient: op.nomClient,
            url: op.url,
            acquittementSite: op.acquittementSite,
            versionApplicative: op.versionApplicative ?? "inconnue",
            contactClient: op.sirsContactClient,
            typePrestation: op.typePrestation,
            moduleBDESE: op.moduleBDESE,
            moduleSHD: op.moduleSHD,
            moduleElectionsRepresentativite: op.moduleElectionsRepresentativite,
            moduleReclamations: op.moduleReclamations,
            moduleComiteDeGroupe: op.moduleComiteDeGroupe,
            moduleAccords: op.moduleAccords,
            moduleReporting: op.moduleReporting,
            nbLicencesActivees: op.nbLicencesActivees,
            nbLicencesVendues: op.nbLicencesVendues,
            etatSite: op.etatSite ?? "En attente de supervision",
        }))
    }, [operationsSIRSEnProduction]);

    /**
     * Se déclenche lorsque l'utilisateur clique sur un pictogramme dans la colonne "Etat du site"
     * et ouvre la modale de confirmation d'acquittement de l'opération donnée
     */
    const handleOnClickOperationSIRSAcquittement = useCallback((operationId: number) => {
        setIsShowAcquittementModale(true);
        setSelectedOperationAcquittement(operationsSIRSEnProduction.find(op => op.id === operationId));
    }, [operationsSIRSEnProduction]);

    /**
     * Se déclenche à la confirmation de l'acquittement dans la modale et acquitte l'opération
     */
    const acquittementOperationSIRS = useCallback(() => {
        if (selectedOperationAcquittement) {
            setIsLoadingAcquittementOperation(true);
            setIsSuccessAcquittementOperation(false);
            setIsErrorAcquittementOperation(false);
            operationSIRSService.updateAcquittementOperationSIRS(selectedOperationAcquittement.id, {acquittementSite: true})
                .then((response) => {
                    if (isMountedRef.current && response.status === 200) {
                        setIsSuccessAcquittementOperation(true);
                    }
                })
                .catch(() => {
                    if (isMountedRef.current) {
                        setIsErrorAcquittementOperation(true);
                    }
                })
                .finally(() => {
                    setIsLoadingAcquittementOperation(false);
                    setIsShowAcquittementModale(false);
                    setSelectedOperationAcquittement(undefined);
                    chargerOperationSIRSsEnProduction();
                })
        }
    }, [selectedOperationAcquittement, chargerOperationSIRSsEnProduction]);

    /**
     * Définition des cellules pour la colonne Etat du site
     */
    const etatSiteCell = useCallback((props: CellProps<OperationSIRSProductionTableau>) => {
        if (props.value === ResultatSurveillance.KO) {
            return (
                <FontAwesomeIcon
                    icon={"times-circle"}
                    size={"2x"}
                    color={"#F10000"}
                    title={`Test de site : ${ResultatSurveillance.KO} - ${props.row.original.acquittementSite ? "Acquitté" : "Non acquitté"}`}
                    style={{cursor: (isAuthorizedAcquittementOperationSIRS && !props.row.original.acquittementSite) ? "pointer" : "default"}}
                    onClick={() => (isAuthorizedAcquittementOperationSIRS && !props.row.original.acquittementSite) && handleOnClickOperationSIRSAcquittement(props.row.original.id)}
                />
            );
        } else if (props.value === ResultatSurveillance.OK) {
            return (
                <FontAwesomeIcon
                    icon={"check-circle"}
                    size={"2x"}
                    color={"#008000"}
                    title={`Test de site : ${ResultatSurveillance.OK} - ${props.row.original.acquittementSite ? "Acquitté" : "Non acquitté"}`}
                />
            )
        } else {
            // Lorsque le statut du site n'est pas connu, le champ etatSite = En attente de supervision
            return (
                <FontAwesomeIcon
                    icon={"question-circle"}
                    size={"2x"}
                    color={"gray"}
                    title={`Test de site : en attente de supervision`}
                />
            )
        }
    }, [isAuthorizedAcquittementOperationSIRS, handleOnClickOperationSIRSAcquittement]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationSIRSProductionTableau>[] = useMemo(() => [
        {
            Header: 'Client',
            id: 'nomClient',
            accessor: (originalRow: OperationSIRSProductionTableau) => ({
                nomClient: originalRow.nomClient,
                url: originalRow.url
            }),
            minWidth: 300,
            Cell: (cellProps: CellProps<OperationSIRSProductionTableau>) => (
                <>
                    <VoxLinkOperation href={`/sirs/detail/${cellProps.row.original.id}`}>
                        {cellProps.value.nomClient}
                    </VoxLinkOperation>
                    <br/>
                    <VoxLinkUrl href={cellProps.value.url} isGreyColor={true}>
                        {cellProps.value.url}
                    </VoxLinkUrl>
                </>
            )
        },
        {
            Header: 'Version applicative',
            accessor: 'versionApplicative',
            Filter: SelectInputFilter,
            filter: 'exact',
            minWidth: 150
        },
        {
            Header: 'Contact client',
            id: 'contactClient',
            accessor: (originalRow: OperationSIRSProductionTableau) => (originalRow.contactClient.map(contact => contact.contactClient)),
            minWidth: 170,
            Cell: (props: CellProps<OperationSIRSProductionTableau>) => {
                return props.value.map((contactClient: string) => <p key={`${props.row.original.id}-${contactClient}`} className={"padding-y-quart"}>{contactClient}</p>);
            },
        },
        {
            Header: 'Mail client',
            id: 'mailClient',
            accessor: (originalRow: OperationSIRSProductionTableau) => (originalRow.contactClient.map(contact => contact.mail)),
            minWidth: 170,
            Cell: (props: CellProps<OperationSIRSProductionTableau>) => {
                return props.value.map((mail: string) => <p key={`${props.row.original.id}-${mail}`} className={"padding-y-quart"}>{mail}</p>);
            }
        },
        {
            Header: 'Téléphone client',
            id: 'telClient',
            accessor: (originalRow: OperationSIRSProductionTableau) => (originalRow.contactClient.map(contact => contact.telephone)),
            minWidth: 170,
            Cell: (props: CellProps<OperationSIRSProductionTableau>) => {
                return props.value.map((tel: string) => <p key={`${props.row.original.id}-${tel}`} className={"padding-y-quart"}>{tel}</p>);
            }
        },
        {
            Header: 'Type de prestation',
            accessor: 'typePrestation',
            Filter: SelectInputFilter,
            filter: 'exact',
            minWidth: 130
        },
        {
            Header: 'Module BDESE',
            accessor: 'moduleBDESE',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 130,
            sortType: 'basic'   // permet le tri de la colonne avec des valeurs booléennes
        },
        {
            Header: 'Module SHD',
            accessor: 'moduleSHD',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 140,
            sortType: 'basic'
        },
        {
            Header: 'Module Élections/ Représentativité',
            accessor: 'moduleElectionsRepresentativite',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 170,
            sortType: 'basic'
        },
        {
            Header: 'Module Réclamations',
            accessor: 'moduleReclamations',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 150,
            sortType: 'basic'
        },
        {
            Header: 'Module Comité de groupe',
            accessor: 'moduleComiteDeGroupe',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 140,
            sortType: 'basic'
        },
        {
            Header: 'Module Accords',
            accessor: 'moduleAccords',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 130,
            sortType: 'basic'
        },
        {
            Header: 'Module reporting',
            accessor: 'moduleReporting',
            Filter: SelectBooleanInputFilter,
            filter: 'equal',
            Cell: BooleanColumnTypeCell,
            minWidth: 130,
            sortType: 'basic'
        },
        {
            Header: 'Total licences activées',
            accessor: 'nbLicencesActivees',
            minWidth: 130,
            Cell: props => {
                const isAlerteNbUtilisateurs = (
                    props.row.original.nbLicencesActivees
                    && props.row.original.nbLicencesVendues
                    && props.row.original.nbLicencesActivees > props.row.original.nbLicencesVendues
                );
                if (isAlerteNbUtilisateurs) {
                    return (
                        <Tooltip placement={"top"} arrow
                            title={
                                <Typography fontSize={14} color={"cobalt.white"}>
                                    Nombre de licences activées supérieur au nombre de licences vendues
                                </Typography>
                            }
                        >
                            <Typography fontWeight={"bold"} color={"cobalt.redText"}>
                                {props.value}
                            </Typography>
                        </Tooltip>
                    )
                }
                return <p>{props.value}</p>;
            }
        },
        {
            Header: 'Nombre de licences vendues',
            accessor: 'nbLicencesVendues',
            minWidth: 170
        },
        {
            Header: 'État du site',
            accessor: 'etatSite',
            Filter: SelectInputFilter,
            filter: 'exact',
            minWidth: 110,
            Cell: etatSiteCell
        },
        {
            // Acquittement
            Header: "Acquittement site KO",
            accessor: 'acquittementSite',
            Cell: (props: CellProps<OperationSIRSProductionTableau>) => (props.value) ?
                <FontAwesomeIcon icon={"check"} size={"lg"}/> : null,
            minWidth: 130,
            disableFilters: true,
            sortType: 'basic'
        },
    ], [etatSiteCell]);


    /**
     * Filtre sur les opérations en production à afficher
     * @param filtresAffichageSIRS un objet contenant les différents filtres à appliquer sur la récupération des données
     */
    const filtresOperationsSIRSEnProduction = (filtresAffichageSIRS: FiltresAffichageSIRS) => {
        setIsLoadingOperationsSIRSEnProduction(true);
        setIsErrorGetOperationsSIRSEnProduction(false);
        setErrorMessageGetOperationsSIRSEnProduction("");
        setIsAppelFonctionFiltresAffichageSIRS(true);
        operationSIRSService.getAllOperationSIRSEnProduction({
            resultatsSurveillance: filtresAffichageSIRS.surveillance
        })
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setOperationsSIRSEnProduction(response.data);
                    setIsLoadingOperationsSIRSEnProduction(false);
                    setErrorMessageGetOperationsSIRSEnProduction("");
                }
            })
            .catch(() => {
                if (isMountedRef.current) {
                    setIsLoadingOperationsSIRSEnProduction(false);
                    setIsErrorGetOperationsSIRSEnProduction(true);
                    setErrorMessageGetOperationsSIRSEnProduction("Une erreur est survenue lors du chargement des opérations SIRS en production");
                }
            });
    }

    /**
     * Suppression des filtres pour les opérations SIRS en production
     * → Se déclenche lors du clic sur le bouton "Réinitialiser les filtres" dans les filtres d'affichage
     */
    const reinitialiserFiltresAffichageOperationsSIRSEnProduction = useCallback(() => {
        setFiltresAlertes(prevState => {
            Object.keys(prevState).forEach(key => prevState = {...prevState, [key]: false});
            return prevState;
        });
        chargerOperationSIRSsEnProduction();
    }, [chargerOperationSIRSsEnProduction]);

    /**
     * Applique les filtres et déclenche un appel à l'API avec ceux-ci
     */
    const filtrerOperationsSIRSEnProduction = useCallback((filtresAlertesSIRS: FiltresAlertesSIRS) => {
        setFiltresAlertes(filtresAlertesSIRS);
        const resultatSurveillances: ResultatSurveillance[] = [];
        Object.keys(filtresAlertesSIRS).forEach(key => {
            if (key === "siteKO" && filtresAlertesSIRS.siteKO) {
                resultatSurveillances.push(ResultatSurveillance.KO);
            }
        });
        filtresOperationsSIRSEnProduction({surveillance: resultatSurveillances});
    }, []);


    /**
     * Mets à jour l'état local des colonnes masquées en fonction de ce qui est stocké dans le localStorage
     */
    useEffect(() => {
        const visibleColumnsStringified: (string | null) = localStorage.getItem("visibleColumnsSIRS");
        if (visibleColumnsStringified) {
            const visibleColumns: string[] = JSON.parse(visibleColumnsStringified);
            if (visibleColumns) {
                setHiddenColumns(getHiddenColumnsFromVisible(visibleColumns, columns));
            } else {
                setHiddenColumns([]);
            }
        } else {
            setHiddenColumns([]);
        }
    }, [columns]);

    /**
     * Se déclenche lorsque l'utilisateur modifie l'affichage des colonnes du tableau et met à jour
     * le localStorage et l'état local du composant
     */
    const handleColumnVisibilityChange = useCallback((visibleColumns: string[]) => {
        localStorage.setItem("visibleColumnsSIRS", JSON.stringify(visibleColumns));
        setHiddenColumns(getHiddenColumnsFromVisible(visibleColumns, columns))
    }, [columns]);


    /**
     * Message d'erreur qui s'affiche lorsqu'il y a une erreur de chargement des opérations
     */
    const onLoadingErrorMessage = useMemo(() => {
        if (isErrorGetOperationsSIRSEnProduction) {
            return (
                <VoxAlert severity={"error"} sx={{marginBottom: 3}}>
                    {errorMessageGetOperationsSIRSEnProduction}
                </VoxAlert>
            );
        }
    }, [isErrorGetOperationsSIRSEnProduction, errorMessageGetOperationsSIRSEnProduction]);

    /**
     * Message d'alerte qui s'affiche pour confirmer l'acquittement ou informer l'utilisateur qu'il y a eu une erreur
     */
    const acquittementAlertMessage = useMemo(() => {
        if (isSuccessAcquittementOperation) {
            return (
                <VoxAlert severity={"success"} sx={{marginBottom: 3}}
                          onClose={() => setIsSuccessAcquittementOperation(false)}>
                    L'opération a été acquittée.
                </VoxAlert>
            )
        } else if (isErrorAcquittementOperation) {
            return (
                <VoxAlert severity={"error"} sx={{marginBottom: 3}}
                          onClose={() => setIsErrorAcquittementOperation(false)}>
                    Une erreur est survenue lors de l'acquittement de l'opération.
                </VoxAlert>
            )
        }
    }, [isSuccessAcquittementOperation, isErrorAcquittementOperation]);

    return (
        <>

            {/* Erreur lors du chargement des opérations SIRS */}
            {onLoadingErrorMessage}

            {/* Alerte suite à l'acquittement d'une opération SIRS */}
            {acquittementAlertMessage}

            {isLoadingOperationsSIRSEnProduction ? <VoxLoader/> : (
                <>

                    {/* Filtres d'affichage (collapse) */}
                    <Grid container sx={{mt: 2, mb: 2}}>
                        <Grid item xs={12} md={7}>
                            <FiltresAffichageOperationsSIRS
                                filtresAlertes={filtresAlertes}
                                reinitialiserFiltres={reinitialiserFiltresAffichageOperationsSIRSEnProduction}
                                filtrerOperationsSIRSEnProduction={filtrerOperationsSIRSEnProduction}
                            />
                        </Grid>
                    </Grid>

                    {/* Tableau */}
                    <VoxTable
                        id={"table-operations-sirs-production"}
                        isCsvExport={true}
                        csvDataSerialization={csvDataSerializationOperationProductionSIRS}
                        csvFileName={"operations_sirs_production"}
                        handleColumnVisibilityChange={handleColumnVisibilityChange}
                        hiddenColumnsProps={hiddenColumns}
                        columns={columns}
                        data={rows}
                        cellProps={(cell: Cell<OperationSIRSProductionTableau>) => {
                            let style = {};
                            // Couleurs du background pour les cellules de la colonne "État du site"
                            if (cell.column.id === "etatSite" && cell.value === ResultatSurveillance.OK) {
                                style = {
                                    ...style,
                                    backgroundColor: "#D4EDDA"  // Couleur d'alerte verte
                                }
                            }
                            if (cell.column.id === "etatSite" && cell.value === ResultatSurveillance.KO) {
                                style = {
                                    ...style,
                                    backgroundColor: "#F8D7DA" // Couleur d'alerte rouge
                                }
                            }
                            return {
                                style: {
                                    ...style,
                                    style
                                }
                            }
                        }}
                    />

                    {/* Modale Acquittement */}
                    <ModaleConfirmationAcquittement
                        show={isShowAcquittementModale}
                        operationNom={selectedOperationAcquittement?.nomClient}
                        authorizedAcquittement={isAuthorizedAcquittementOperationSIRS}
                        handleClose={() => {
                            setIsShowAcquittementModale(false);
                            setSelectedOperationAcquittement(undefined);
                        }}
                        handleSubmit={acquittementOperationSIRS}
                        loadingAcquittementOperation={isLoadingAcquittementOperation}
                    />

                </>
            )}
        </>
    );
}