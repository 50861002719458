import React, {useEffect, useRef, useState} from "react";
import {SIRSOperation} from "../../../models/operationSIRS/sirsOperation.model";
import {affichageLibelleEtatDemande} from "../../../utils/string.utils";
import OperationsSIRSDetailModules from "./operationsSIRSDetailModules";
import OperationsSIRSDetailContactsClients from "./operationsSIRSDetailContactsClients";
import {Box, Grid, Link, List, ListItem, Typography} from "@mui/material";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import OperationsSIRSDetailCommentaires from "./operationsSIRSDetailCommentaires";

type PropsType = {
    operationSIRS: SIRSOperation
}

export default function OperationsSIRSDetailInformationsGenerales(props: PropsType) {

    // Gestion de la hauteur maximale du bloc "Commentaires"
    const leftSIRSContainerRef = useRef<any>(null);
    const [commentsCardHeight, setCommentsCardHeight] = useState(400);

    useEffect(() => {

        // L'observer n'est pas initialisé si la ref n'existe pas
        if (!leftSIRSContainerRef) {
            return;
        }

        // Initialisation de l'observer
        const resizeObserver = new ResizeObserver(() => {
            if (leftSIRSContainerRef.current.offsetHeight !== commentsCardHeight) {
                setCommentsCardHeight(leftSIRSContainerRef.current.offsetHeight);
            }
        })

        // Début de l'observation de la ref (conteneur de gauche)
        resizeObserver.observe(leftSIRSContainerRef.current)

        // Clean-up Function (pour éviter les fuites mémoires)
        // → L'observer est supprimé lors de la destruction du composant
        return () => {
            // Fin de l'observation de la ref
            resizeObserver.disconnect();
        }

    }, [commentsCardHeight]);


    return (
        <>
            <Grid container justifyContent={"space-evenly"} gap={3}>

                {/* PARTIE GAUCHE */}
                <Grid item
                      xs={12}
                      sm={10}
                      md={6}
                      ref={leftSIRSContainerRef}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={3}
                >

                    {/* Card "Détail de l'opération" */}
                    <VoxCard
                        id={"infos-op"}
                        iconBeforeTitle={"info-circle"}
                        title={"Détail de l'opération SIRS"}
                    >
                        <List sx={{padding: 0}}>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>Chef(fe) de projet :</Typography>
                                <Typography>{props.operationSIRS.utilisateur.prenom} {props.operationSIRS.utilisateur.nom}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>Client :</Typography>
                                <Typography>{props.operationSIRS.nomClient}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>Type de prestation :</Typography>
                                <Typography>{props.operationSIRS.typePrestation}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>Version applicative :</Typography>
                                <Typography>{props.operationSIRS.versionApplicative?.version ?? "inconnue"}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>URL :</Typography>
                                <Link href={props.operationSIRS.url} target={"_blank"} rel={"noreferrer"}>
                                    {props.operationSIRS.url}
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>Nombre de licences vendues :</Typography>
                                <Typography>{props.operationSIRS.nbLicencesVendues}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography fontWeight={500} pr={0.5}>État :</Typography>
                                <Typography>{affichageLibelleEtatDemande(props.operationSIRS.etatDemande)}</Typography>
                            </ListItem>
                        </List>
                    </VoxCard>

                    {/* Card "Liste des contacts clients" */}
                    <VoxCard
                        iconBeforeTitle={"id-card"}
                        title={"Liste des contacts clients"}
                    >
                        <OperationsSIRSDetailContactsClients operationSIRS={props.operationSIRS}/>
                    </VoxCard>

                    {/* Card "Liste des modules" */}
                    <VoxCard
                        iconBeforeTitle={"cog"}
                        title={"Liste des modules"}
                    >
                        <OperationsSIRSDetailModules operationSIRS={props.operationSIRS}/>
                    </VoxCard>

                    {/* Card "Description" */}
                    <VoxCard
                        iconBeforeTitle={"comment-dots"}
                        title={"Description"}
                    >
                        <Box sx={{whiteSpace: 'pre-wrap'}}>
                            {props.operationSIRS.description}
                        </Box>
                    </VoxCard>

                </Grid>

                {/* PARTIE DROITE */}
                <Grid item xs={12} sm={10} md={4}>
                    <OperationsSIRSDetailCommentaires
                        operationSIRSId={Number(props.operationSIRS.id)}
                        commentsCardHeight={commentsCardHeight}
                    />
                </Grid>

            </Grid>
        </>
    )

}