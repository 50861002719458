import React from "react";
import {Commentaire} from "../../models/commentaire/commentaire.model";
import CommentaireCard from "./commentaireCard";
import {Box} from "@mui/material";

export default function CommentairesListe(props: { listeCommentairesOperation: Commentaire[] }) {

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            paddingX={"24px"}
        >
            {props.listeCommentairesOperation.map((commentaire: Commentaire) =>
                <Box
                    key={`commentaire-${commentaire.id}`}
                    className={"comment-container"}
                    sx={{
                        padding: '1rem 1.5rem',
                        borderBottom: '1px solid rgba(0,0,0,.075)',
                        margin: '0 -1.5rem'
                    }}
                >
                    <CommentaireCard commentaire={commentaire}/>
                </Box>
            )}
        </Box>
    )
}