import React from "react";
import { Box, Typography } from "@mui/material";

export const VoxLegendChampsObligatoires = () => {

    return (
        <Box mb={1.5}>
            <Typography component={"span"} variant={"body2"} mr={0.5}>
                Les champs obligatoires sont annotés d'une étoile
            </Typography>
            <Box component={"span"} fontSize={"0.875rem"} fontWeight={500} color={"cobalt.redText"}>*</Box>
        </Box>
    )
}