export enum Periode {
    /**
     * Période courant d'un lundi au dimanche suivant inclu
     */
    SEMAINE_COURANTE = "SEMAINE_COURANTE",
    /**
     * Période courant d'un lundi au dimanche de la semaine suivante inclu
     */
    SEMAINES_COURANTE_ET_SUIVANTE = "SEMAINES_COURANTE_ET_SUIVANTE",
    /**
     * Période courant d'un lundi à un mois plus tard
     */
    UN_MOIS = "UN_MOIS"
}