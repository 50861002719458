import axios_instance from "./axios-client";
import { Parametrage } from "../../models/parametrage/parametrage.model";


const parametrageService = {

    getAllParametrages: (signal?: AbortSignal) => {
        return axios_instance.get("/parametrage", { signal });
    },

    updateParametrage: (parametrages: Parametrage[]) => {
        return axios_instance.put("/parametrage", { parametrages })
    },

}

export default parametrageService;