import axios_instance from "./axios-client";

const commentaireService =  {

    // VOTE
    getCommentairesByOperationId: (id: number, signal?: AbortSignal) => {
        return axios_instance.get(`/commentaires/par_operation/${id}`, {signal: signal});
    },

    // VOTE
    createCommentaire: (idOperation: number, commentaire: string) => {
        return axios_instance.post(`/commentaires/operation/${idOperation}`, {commentaire});
    },


    // SIRS
    getCommentairesByOperationSIRSId: (operationSIRSId: number, signal?: AbortSignal) => {
        return axios_instance.get(`/commentaires/par_operation_SIRS/${operationSIRSId}`, { signal });
    },

    // SIRS
    createCommentaireSIRS: (idOperationSIRS: number, commentaire: string) => {
        return axios_instance.post(`/commentaires/operation_SIRS/${idOperationSIRS}`, {commentaire});
    },

}

export default commentaireService;