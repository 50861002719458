import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import React, {useCallback, useEffect, useState} from "react";
import {Commentaire} from "../../../models/commentaire/commentaire.model";
import CommentairesListe from "../../../components/commentaire/commentairesListe";
import commentaireService from "../../../services/survox-back/commentaire.service";
import {Box, Collapse, FormControl, Typography} from "@mui/material";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VoxConditionalWrapper from "../../../components/layouts/wrapper/voxConditionalWrapper";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import {VoxTextArea} from "../../../components/genericComponentsUI/input/voxTextArea";


export default function OperationsDetailCommentaires(props: { operationId: number, commentsCardHeight: number }) {

    const [commentairesOperation, setCommentairesOperation] = useState<Commentaire[]>([]);

    const [isLoadingCommentairesOperation, setIsLoadingCommentairesOperation] = useState(false);
    const [isLoadingCommentairesError, setIsLoadingCommentairesError] = useState(false);
    const [messageCommentairesError, setMessageCommentairesError] = useState<string>("");

    const [commentaire, setCommentaire] = useState<string>("");

    const [isLoadingCreationCommentaire, setIsLoadingCreationCommentaire] = useState(false);
    const [isErrorCommentaire, setIsErrorCommentaire] = useState(false);
    const [messageErrorCommentaire, setMessageErrorCommentaire] = useState("");

    const [openCollapse, setOpenCollapse] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        if (props.operationId) {
            chargerCommentairesOperation(props.operationId, controller);
        }
        return () => controller.abort();
    }, [props.operationId]);

    /**
     * Méthode qui permet de charger les commentaires associés à l'opération
     * @param id , Id de l'opération
     * @param controller
     */
    const chargerCommentairesOperation = (id: number, controller?: AbortController) => {
        setIsLoadingCommentairesOperation(true);
        setIsLoadingCommentairesError(false);
        commentaireService.getCommentairesByOperationId(id, controller?.signal)
            .then(response => {
                // récupération des commentaires de l'opération
                setCommentairesOperation(response.data);
                setIsLoadingCommentairesOperation(false);
                setIsLoadingCommentairesError(false);
            })
            .catch((error) => {
                if (error.message !== "canceled") {
                    setIsLoadingCommentairesOperation(false);
                    if (error.response.status === 404) {
                        setIsLoadingCommentairesError(true);
                        setMessageCommentairesError(error.response.data.message);         // "L'opération avec l'id ... n'existe pas"
                    }
                }
            });
    }

    /**
     * Appel de cette méthode dès la modification de la valeur du textarea "commentaire" (afin de mettre à jour la valeur)
     * @param event
     */
    const handleOnChangeCommentaire = (event: any) => {
        setCommentaire(event.target.value)
    }

    /**
     * Méthode permettant de créer un commentaire sur une opération
     */
    const creerCommentaire = useCallback((idOperation: number, commentaireOperation: string) => {
        setIsLoadingCreationCommentaire(true);
        setIsErrorCommentaire(false);
        commentaireService.createCommentaire(idOperation, commentaireOperation)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoadingCreationCommentaire(false);
                    setIsErrorCommentaire(false);
                    setOpenCollapse(prevState => !prevState)
                    setCommentaire("");
                    chargerCommentairesOperation(idOperation);
                }
            })
            .catch((error) => {
                setIsLoadingCreationCommentaire(false);
                setIsErrorCommentaire(true);
                if (error.response.status === 404) {
                    setMessageErrorCommentaire(error.response.data.message);
                } else if (error.response.status === 400 && error.response.data.message[0] === "Le commentaire ne doit pas être vide.") {
                    setMessageErrorCommentaire(error.response.data.message[0])
                } else {
                    setMessageErrorCommentaire("Une erreur est survenue lors de l'enregistrement du commentaire.");
                }
            })
    }, []);

    /**
     * Lors de la validation du formulaire, appel de cette méthode 'handleOnCreateCommentaire' pour faire la création d'un commentaire
     * @param event
     */
    const handleOnCreateCommentaire = useCallback((event: any) => {
        event.preventDefault();
        creerCommentaire(props.operationId, commentaire);
    }, [props.operationId, commentaire, creerCommentaire]);


    /**
     * Fonction anonyme gérant l'affichage des commentaires
     */
    const showCommentaires = () => {
        if (isLoadingCommentairesOperation) {
            return <VoxLoader/>
        } else if (isLoadingCommentairesError) {
            return <VoxAlert severity={"error"} sx={{margin: 2}}>{messageCommentairesError}</VoxAlert>
        } else if (commentairesOperation.length > 0) {
            return <CommentairesListe listeCommentairesOperation={commentairesOperation}/>
        } else {
            return (
                <Box display={"flex"} justifyContent={"center"} padding={3}>
                    <Typography>Aucun commentaire à afficher...</Typography>
                </Box>
            )
        }
    }


    return (
        <VoxCard
            id={"card-commentaires"}
            iconBeforeTitle={"comments"}
            title={"Commentaires"}
            action={
                <VoxButton
                    variant={"text"}
                    size={"small"}
                    endIcon={
                        <FontAwesomeIcon
                            icon={openCollapse ? "chevron-down" : "chevron-right"}
                            style={{minWidth: '14px'}}
                        />
                    }
                    onClick={() => setOpenCollapse(!openCollapse)}
                    aria-expanded={openCollapse}
                    aria-controls={"collapse-commentaire"}
                    sx={{marginRight: 1.5}}
                >
                    Ajouter
                </VoxButton>
            }
        >

            <Box sx={{
                maxHeight: (props.commentsCardHeight - 56), // 56px = height du header de la card
                overflow: 'auto',
                margin: '-24px'
            }}>
                {/* Ajout d'un commentaire */}
                <Collapse
                    id={"collapse-commentaire"}
                    in={openCollapse}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        '&::after': {
                            content: '""',
                            display: 'block',
                            borderBottom: '1px solid rgba(0,0,0,.075)'
                        }
                    }}
                >
                    <FormControl
                        onSubmit={handleOnCreateCommentaire}
                        component={"form"}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            marginBottom: '1.5rem',
                            padding: '1rem 1.5rem 0 1.5rem'
                        }}
                    >

                        {/* TextArea */}
                        <VoxTextArea
                            id={"comment-input"}
                            label={"Ajouter un commentaire"}
                            minRows={3}
                            value={commentaire}
                            onChange={handleOnChangeCommentaire}
                            required={true}
                            disabled={isLoadingCreationCommentaire}
                        />

                        {/* Bouton "Envoyer" */}
                        <VoxConditionalWrapper
                            condition={isLoadingCreationCommentaire}
                            wrapper={children => (
                                <Box position={"relative"}
                                     display={"flex"}
                                     justifyContent={"center"}
                                >
                                    {children}
                                    <VoxLoader size={20}/>
                                </Box>
                            )}
                        >
                            <VoxButton
                                customColor={"green"}
                                size={"small"}
                                startIcon={<FontAwesomeIcon icon={"check"}/>}
                                type={"submit"}
                                disabled={isLoadingCreationCommentaire}
                                sx={{margin: '0 auto'}}
                            >
                                Envoyer
                            </VoxButton>
                        </VoxConditionalWrapper>

                        {/* Erreur lors de la création d'un commentaire */}
                        {isErrorCommentaire &&
                            <VoxAlert severity={"error"} sx={{mt: 2}}>
                                {messageErrorCommentaire}
                            </VoxAlert>
                        }

                    </FormControl>
                </Collapse>

                {/* Liste des commentaires */}
                {showCommentaires()}
            </Box>
        </VoxCard>
    )

}