import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellProps, Column, IdType, Row } from "react-table";
import VoxLinkOperation from "../../../components/genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";
import { affichageDateWithFormatDMYHm } from "../../../utils/string.utils";
import { sortDatesInTables } from "../../../utils/date.utils";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import operationSIRSService from "../../../services/survox-back/operationSIRS/operationSIRS.service";

type OperationSIRSSupprimee = {
    id: number;
    nomClient: string;
    url: string;
    utilisateur: string;
    utilisateurSuppression?: string | null;
    dateSuppression: Date;
}

export default function OperationsSIRSSupprimeesListe() {

    // Utilisation d'une ref pour connaitre l'état du composant, éviter les fuites mémoires dans les appels asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [operationsSIRSSupprimeesListe, setOperationsSIRSSupprimeesListe] = useState<OperationSIRSSupprimee[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);
    /**
     * Chargement des données du tableau : toutes les opérations SIRS supprimées
     */
    const chargerOperationsSIRSSupprimees = useCallback((controller: AbortController) => {
        setIsLoading(true);
        operationSIRSService.getOperationsSIRSSupprimees(controller.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    setOperationsSIRSSupprimeesListe(response.data);
                    setIsLoading(false);
                    setIsLoadingError(false);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoading(false);
                    setIsLoadingError(true);
                }
            });
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        chargerOperationsSIRSSupprimees(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [chargerOperationsSIRSSupprimees]);

    /**
     * Remplissage des lignes du tableau
     */
    const rows: OperationSIRSSupprimee[] = useMemo(() => operationsSIRSSupprimeesListe, [operationsSIRSSupprimeesListe]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationSIRSSupprimee>[] = useMemo(() => [
        {
            Header: 'Opération',
            accessor: 'nomClient',
            minWidth: 150,
            Cell: (props: CellProps<OperationSIRSSupprimee>) => (
                <VoxLinkOperation href={`/sirs/detail/${props.row.original.id}`}>
                    {props.value}
                </VoxLinkOperation>
            )
        },
        {
            Header: 'URL',
            accessor: 'url',
            minWidth: 200,
            Cell: (props: CellProps<OperationSIRSSupprimee>) => <VoxLinkUrl href={props.value}>{props.value}</VoxLinkUrl>
        },
        {
            Header: "Chef(fe) de projet",
            accessor: "utilisateur",
            minWidth: 150,
        },
        {
            Header: "Date de suppression",
            id: 'dateSuppression',
            accessor: (originalRow: OperationSIRSSupprimee) => affichageDateWithFormatDMYHm(originalRow.dateSuppression),
            minWidth: 150,
            sortType: (a: Row<OperationSIRSSupprimee>,
                       b: Row<OperationSIRSSupprimee>,
                       columnIdId: IdType<OperationSIRSSupprimee>,
                       desc: undefined | boolean) => sortDatesInTables(a.original.dateSuppression, b.original.dateSuppression, desc),
        },
        {
            Header: "Supprimé par",
            accessor: "utilisateurSuppression",
            minWidth: 150,
        }
    ], []);


    if (isLoading) {
        return <VoxLoader />
    }
    else if (isLoadingError) {
        return (
            <VoxAlert severity={"error"}>
                Une erreur est survenue lors du chargement des opérations SIRS supprimées.
            </VoxAlert>
        )
    } else {
        return (
            <VoxTable
                data={rows}
                columns={columns}
                initialStateSortBy={[{ id: "dateSuppression", desc: true }, { id: "nomClient" }]}
            />
        )
    }

}