import React from "react";
import VoxButton from "./voxButton";
import { ButtonProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function VoxRedTrashButton(props: ButtonProps) {

    const {sx, ...otherProps} = props;

    return (
        <VoxButton
            customColor={"red"}
            size={"small"}
            sx={{
                minWidth: "32px",
                width: "32px",
                height: "32px",
                ...sx
            }}
            {...otherProps}
        >
            <FontAwesomeIcon icon={"trash"}/>
        </VoxButton>
    )
}