import React, { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import plateformeService from "../../../services/survox-back/operation/plateforme.service";
import { Plateforme } from "../../../models/operation/plateforme.dto";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";


export default function TypesDePlateformeListe() {

    const [typesPlateforme, setTypesPlateforme] = useState<Plateforme[]>([]);
    const [isLoadingPlateformes, setIsLoadingPlateformes] = useState(false);
    const [isLoadingPlateformesError, setIsLoadingPlateformesError] = useState(false);

    /**
     * Chargement des types de plateforme
     */
    const chargerTypesDePlateforme = (controller: AbortController) => {
        setIsLoadingPlateformes(true);
        setIsLoadingPlateformesError(false);
        plateformeService.getAllPlateformes(controller.signal)
            .then((response) => {
                if (response.status === 200) {
                    const typesPlateformeListe: Plateforme[] = response.data;
                    setTypesPlateforme(typesPlateformeListe);
                    setIsLoadingPlateformes(false);
                    setIsLoadingPlateformesError(false);
                }
            })
            .catch(err => {
                if (err.message !== "canceled") {
                    setIsLoadingPlateformes(false);
                    setIsLoadingPlateformesError(true);
                }
            });
    }

    useEffect(() => {
        const controller = new AbortController();
        chargerTypesDePlateforme(controller);
        return () => controller.abort();
    }, []);

    /**
     * Remplissage des lignes du tableau
     */
    const rows: any[] = useMemo(() => {
        return typesPlateforme;
    }, [typesPlateforme]);

    /**
     * Configuration des colonnes du tableau
     */
    const columns: Array<Column<Plateforme>> = useMemo(() => [
        {
            Header: 'Ordre',
            accessor: 'ordre',
            minWidth: 90,
            maxWidth: 90,
        },
        {
            Header: 'Nom',
            accessor: 'nom',
            minWidth: 120,
            maxWidth: 90,
        },
        {
            Header: 'Définition',
            accessor: 'definition',
            minWidth: 250
        },
    ], []);


    return (
        <>
            {isLoadingPlateformesError &&
                <VoxAlert severity={"error"}>
                    Une erreur est survenue lors du chargement des différents types de plateforme.
                </VoxAlert>
            }
            {isLoadingPlateformes ? <VoxLoader/> :
                <VoxTable
                    data={rows}
                    columns={columns}
                    initialStateSortBy={[{id: "ordre", desc: false}]}
                />
            }
        </>
    )
}