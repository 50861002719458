import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import instance, {
    requestInterceptorOnFulfilled,
    requestInterceptorOnRejected,
    responseInterceptorOnFulfilled,
    responseInterceptorOnRejected
} from "../../services/survox-back/axios-client";
import { extractUtilisateurFromToken } from "../../utils/jwt/jwt.utils";
import LoaderOnStartSurvox from "../../components/genericComponentsUI/loaders/loaderOnStartSurvox/LoaderOnStartSurvox";
import { AuthContext } from "./authentication.context";


export default function AuthProvider(props: any) {

    const requestInterceptor = useRef<number>();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [utilisateur, setUtilisateur] = useState<Utilisateur>({} as Utilisateur);
    const [accessToken, setAccessToken] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const setRequestInterceptor = useCallback((accessToken: string) => {
        if (requestInterceptor.current !== undefined) {
            instance.interceptors.request.eject(requestInterceptor.current);
        }
        requestInterceptor.current = instance.interceptors.request.use(
            config => requestInterceptorOnFulfilled(config, accessToken),
            requestInterceptorOnRejected
        );
    }, []);


    const login = useCallback((access_token: string) => {
        setRequestInterceptor(access_token);
        setIsAuthenticated(true);
        setUtilisateur(extractUtilisateurFromToken(access_token));
        setAccessToken(access_token);
    }, [setRequestInterceptor]);

    const logout = useCallback(() => {
        setRequestInterceptor("");
        setIsAuthenticated(false);
        setUtilisateur({} as Utilisateur);
        setAccessToken("");
    }, [setRequestInterceptor]);


    const refreshToken = useCallback((controller?: AbortController) => {
        instance.post("/refresh", null, { signal: controller?.signal })
            .then(response => {
                login(response.data.access_token);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    logout();
                    setIsLoading(false)
                }
            })
    }, [login, logout]);

    useEffect(() => {
        const id = instance.interceptors.response.use(
            responseInterceptorOnFulfilled,
            error =>  responseInterceptorOnRejected(error, login, logout)
        );
        return () => { instance.interceptors.response.eject(id) };
    }, [login, logout]);

    useEffect(() => {
        const controller = new AbortController();
        refreshToken(controller);
        return () => controller.abort();
    }, [refreshToken]);

    const value = useMemo(() => ({
        authenticated: isAuthenticated,
        utilisateur: utilisateur,
        access_token: accessToken,
        login: login,
        logout: logout,
        refreshToken: refreshToken
    }), [accessToken, isAuthenticated, login, logout, refreshToken, utilisateur]);


    return (
        <AuthContext.Provider value={value}>
            { isLoading ? <LoaderOnStartSurvox/> : props.children }
        </AuthContext.Provider>
    )

}