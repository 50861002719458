import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { VoxSelectInput } from "../../../components/genericComponentsUI/input/voxSelectInput";
import { VoxTextField } from "../../../components/genericComponentsUI/input/voxTextField";
import { VoxLabel } from "../../../components/genericComponentsUI/input/voxLabel";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { VoxLegendChampsObligatoires } from "../../../components/genericComponentsUI/label/voxLegendChampsObligatoires";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import versionApplicativeService from "../../../services/survox-back/versionApplicative/version-applicative.service";
import { VersionApplicative } from "../../../models/versionApplicative/versionApplicative.model";
import { useNavigate } from "react-router-dom";
import { CreateVersionApplicativeV23Dto } from "../../../services/survox-back/versionApplicative/dtos/request/createVersionApplicativeV23.dto";
import { Composant } from "../../../models/versionApplicative/composant.model";
import { TypeLivrable } from "../../../models/versionApplicative/typeLivrable.enum";
import { TypeProduit } from "../../../models/versionApplicative/typeProduit.enum";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import { SelectChangeEvent } from "@mui/material/Select";
import composantService from "../../../services/survox-back/versionApplicative/composant.service";

type VoxElectionFormProps = {
    typeProduit: TypeProduit
}

export default function VersionVoxCoreElectionForm(props: Readonly<VoxElectionFormProps>) {

    //Valeurs des champs
    const [versionMajeure, setVersionMajeure] = useState<number | undefined>(undefined);
    const [numeroVersionVoxElection, setNumeroVersionVoxElection] = useState<string>("");
    const [frontComposant, setFrontComposant] = useState<string>("");
    const [backComposant, setBackComposant] = useState<string>("");
    const [adminComposant, setAdminComposant] = useState<string>("");

    //Contenu des listes déroulantes
    const [frontComposants, setFrontComposants] = useState<Composant[]>([]);
    const [backComposants, setBackComposants] = useState<Composant[]>([]);
    const [adminComposants, setAdminComposants] = useState<Composant[]>([]);

    //Utils
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");

    const [versionApplicative, setVersionApplicative] = useState<Partial<VersionApplicative>>({ version: "", typeProduit: props.typeProduit });
    const [allComposants, setAllComposants] = useState<Composant[]>([]);

    const navigate = useNavigate();

    const minVersionMajeure = 23;

    useEffect(() => {
        const abortController = new AbortController();

        //Récupération des composants
        composantService.getAllComposantByTypeProduit(props.typeProduit, abortController.signal)
            .then(response => {
                if (response.status === 200) {
                    setIsError(false);
                    setAllComposants(response.data);
                    setFrontComposants(
                        response.data.filter((composant: Composant) => composant.typeLivrable === TypeLivrable.LIVRABLE_FRONT_ELECTEUR)
                    )
                    setBackComposants(
                        response.data.filter((composant: Composant) => composant.typeLivrable === TypeLivrable.LIVRABLE_BACK_ELECTEUR)
                    )
                    setAdminComposants(
                        response.data.filter((composant: Composant) => composant.typeLivrable === TypeLivrable.LIVRABLE_ADMIN)
                    )
                }
            })
            .catch((err) => {
                if (err !== 'canceled') {
                    setIsError(true);
                    setMessageError('Impossible de récupérer les composants des versions applicatives');
                }
            })

        return () => abortController.abort();

    }, [props.typeProduit])

    /**
     * Mise à jour du champ version majeure
     * @param event
     */
    const handleOnChangeVersionMajeure = (event: ChangeEvent<HTMLInputElement>) => {
        setVersionMajeure(event.target.value ? Number(event.target.value) : undefined);
        if (event.target.value === "") {
            setNumeroVersionVoxElection("");
        } else {
            setNumeroVersionVoxElection(event.target.value + '.');
        }

    }

    /**
     * Mise à jour du champ "Numéro de version" lorsque l'on renseigne l'input du formulaire
     * @param event
     */
    const handleOnChangeNumeroVersionVoxElection = (event: ChangeEvent<HTMLInputElement>) => {
        setVersionApplicative( (prevState: Partial<VersionApplicative>) => ({...prevState, version: event.target.value}));
        setNumeroVersionVoxElection(event.target.value);
    }

    /**
     * Mise à jour du champ front electeur
     * @param event
     */
    const handleOnChangeFrontComposant = (event: SelectChangeEvent<HTMLInputElement>) => {
        setFrontComposant(event.target.value as string);
    }

    /**
     * Mise à jour du champ back electeur
     * @param event
     */
    const handleOnChangeBackComposant = (event: SelectChangeEvent<HTMLInputElement>) => {
        setBackComposant(event.target.value as string);
    }

    /**
     * Mise à jour du champ admin
     * @param event
     */
    const handleOnChangeAdminComposant = (event: SelectChangeEvent<HTMLInputElement>) => {
        setAdminComposant(event.target.value as string);
    }

    /**
     *Déclenche la création d'une version Applicative VoxCoreElection V23 ou plus
     * @param event
     */
    const handleOnCreateVoxElectionV23 = (event: FormEvent) => {
        event.preventDefault();
        if (numeroVersionVoxElection.includes('x')) {
            setIsError(true)
            setMessageError("Numéro de version invalide")
        } else {
            if (versionApplicative.typeProduit && versionApplicative.version) {
                // Récupération des Composants sélectionnés
                const selectedComposants = allComposants.filter((comp: Composant) => (comp.nomLivrable === frontComposant || comp.nomLivrable === backComposant || comp.nomLivrable === adminComposant))

                onCreateVersionVoxElectionV23({
                    version: versionApplicative.version,
                    typeProduit: versionApplicative.typeProduit,
                    composants: selectedComposants
                });
            } else {
                setIsError(true);
                setMessageError("Les champs du formulaire sont obligatoires.");
            }
        }
    }

    /**
     * Création d'une nouvelle version applicative VoxElection V23 ou plus
     * @param createVersionApplicativeV23  champs nécessaires à la création d'une version applicative
     */
    const onCreateVersionVoxElectionV23 = (createVersionApplicativeV23: CreateVersionApplicativeV23Dto) => {
        setIsLoading(true);
        setIsError(false);
        versionApplicativeService.createVersionApplicativeVoxElectionV23(createVersionApplicativeV23)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false);
                    setIsError(false);
                    navigate(`/administration/versions_produits?type=${versionApplicative.typeProduit}`);
                }
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
                setMessageError("Une erreur est survenue lors de la création de la version applicative.");
            })
    }

    return (
        <VoxCard
            title={"Ajout d'une version applicative"}
        >
            {/* Affichage de l'erreur s'il y en a une */}
            {(isError) &&
                <VoxAlert severity={"error"}>
                    { messageError }
                </VoxAlert>
            }
            <form onSubmit={handleOnCreateVoxElectionV23}>
                <Grid container spacing={3} padding={2}>

                    <Grid item xs={12}>
                        <VoxLegendChampsObligatoires/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <VoxTextField
                            id={"versionMajeure"}
                            label={"Version Majeure"}
                            value={versionMajeure}
                            onChange={handleOnChangeVersionMajeure}
                            type="number"
                            pattern={"^[0-9]+$"}  // Nombre entier
                            min={minVersionMajeure}  // Création des versions applicatives avec plusieurs composants à partir de la V23
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <VoxTextField
                            id={"version"}
                            label={"Numéro de version"}
                            value={numeroVersionVoxElection}
                            onChange={handleOnChangeNumeroVersionVoxElection}
                            placeholder={"Choisir une version majeure"}
                            pattern={`^${versionMajeure}(\\.([0-9]?[0-9])){2}$`}
                            title={"XX.YY.ZZ ; avec XX correspondant à la version majeure"}
                            fullWidth
                            required
                            disabled={!versionMajeure}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <VoxLabel
                            id={"composantsVersionApp"}
                            label={"Composants de la Version Applicative :"}
                        />
                    </Grid>

                    {(!versionMajeure) &&
                        <Grid item xs={12}>
                            <Typography id={"noComposantsVersionApp"} variant="body1">Choisir une version majeure</Typography>
                        </Grid>
                    }

                    {(versionMajeure && versionMajeure < minVersionMajeure) &&
                        <Grid item xs={12}>
                            <VoxAlert severity={"error"} sx={{m: 0}}>La version majeure doit être supérieure ou égale à 23.</VoxAlert>
                        </Grid>
                    }

                    {(versionMajeure && versionMajeure >= minVersionMajeure) &&
                     <>
                         <Grid item xs={12}>
                             <VoxSelectInput
                                 id={"frontElecteurComposant"}
                                 idLabel={"labelFrontEL"}
                                 label={"Front Electeur"}
                                 value={frontComposant}
                                 onChange={handleOnChangeFrontComposant}
                                 fullWidth
                                 required
                             >
                                 {
                                     frontComposants.map(composant => <MenuItem key={composant.id} value={`${composant.nomLivrable}`}>{composant.nomLivrable}</MenuItem>)
                                 }
                             </VoxSelectInput>
                         </Grid>

                         <Grid item xs={12}>
                             <VoxSelectInput
                                 id={"backElecteurComposant"}
                                 idLabel={"labelBackEL"}
                                 label={"Back Electeur"}
                                 value={backComposant}
                                 onChange={handleOnChangeBackComposant}
                                 fullWidth
                                 required
                             >
                                 {
                                     backComposants.map(composant => <MenuItem key={composant.id} value={`${composant.nomLivrable}`}>{composant.nomLivrable}</MenuItem>)
                                 }
                             </VoxSelectInput>
                         </Grid>

                         <Grid item xs={12}>
                             <VoxSelectInput
                                 id={"adminComposant"}
                                 idLabel={"labelAdminComposant"}
                                 label={"Admin"}
                                 value={adminComposant}
                                 onChange={handleOnChangeAdminComposant}
                                 fullWidth
                                 required
                             >
                                 {
                                     adminComposants.map(composant => <MenuItem key={composant.id} value={`${composant.nomLivrable}`}>{composant.nomLivrable}</MenuItem>)
                                 }
                             </VoxSelectInput>
                         </Grid>
                     </>
                    }

                    <Grid item xs={12}>
                        <Box>
                            <VoxLabel
                                id={"typeProduit"}
                                label={"Type de produit"}
                                required
                            />
                            <Box sx={{minHeight: 46, display: 'flex', flexDirection: "row", alignItems: "center"}}>
                                <Typography component={"p"} variant={"body2"} sx={{fontWeight: 1000}}>
                                    {versionApplicative.typeProduit}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} display={'flex'} justifyContent={"space-evenly"}>
                        <VoxButton
                            customColor={"red"}
                            variant={"outlined"}
                            className={"size-button"}
                            onClick={() => navigate(`/administration/versions_produits?type=${versionApplicative.typeProduit}`)}
                            disabled={isLoading}
                        >
                            Annuler
                        </VoxButton>
                        <VoxButton
                            customColor={"green"}
                            className={"size-button"}
                            type={"submit"}
                            disabled={isLoading}
                        >
                            Valider
                        </VoxButton>
                    </Grid>
                </Grid>
            </form>
            {
                isLoading ? <VoxLoader isBackgroundColor={true} /> : null
            }
        </VoxCard>
    )
}