import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./utilisateursCreation.css";
import "../../../components/genericComponentsUI/button/voxButton.css";
import { CreateUtilisateurDto } from "../../../services/survox-back/utilisateur/dtos/request/createUtilisateur.dto";
import { Groupe } from "../../../models/utilisateur/groupe.model";
import { generateRandomString, PATTERN_PASSWORD, PATTERN_EMAIL } from "../../../utils/string.utils";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import utilisateurService from "../../../services/survox-back/utilisateur/utilisateur.service";
import groupeService from "../../../services/survox-back/utilisateur/groupe.service";
import ModaleConfirmationUtilisateur from "../../../components/modals/modaleConfirmationUtilisateur";
import { LabelFormulaire } from "../../../components/genericComponentsUI/label/labelFormulaire";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";


export default function UtilisateursCreation()  {
    const navigate = useNavigate();

    // Le mot de passe doit contenir entre 8 et 24 caractères
    const generationMdpAleatoire = generateRandomString(10);

    // Initialisation du groupe de l'utilisateur à 0 (ce groupe n'existe pas, utile simplement pour la barre de sélection vide à l'initialisation)
    const [utilisateur, setUtilisateur] = useState<CreateUtilisateurDto>({nom: "", prenom: "", login: "", mail: "", password: generationMdpAleatoire, groupe: {id: 0}, estActif: true, mdpAModifier: true});

    const [groupes, setGroupes] = useState([]);
    const [isLoadingGroupes, setIsLoadingGroupes] = useState(false);
    const [isLoadingGroupesError, setIsLoadingGroupesError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showConfirmationModale, setShowConfirmationModale] = useState(false);

    // Affectation des valeurs par défaut pour l'utilisateur
    useEffect(() => {
        /* valeur par défaut pour le login : première lettre du prénom + nom */
        setUtilisateur( (prevState: CreateUtilisateurDto) => ({
            ...prevState,
            login: (utilisateur.nom && utilisateur.prenom) ?
                ((utilisateur.prenom).substring(0, 1) + utilisateur.nom).toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")    // supprime les accents
                    .replace(/ /g,'')     // supprime les espaces
                : ""
        }));
    }, [utilisateur.nom, utilisateur.prenom])

    useEffect(() => {
        const controller = new AbortController();
        loadGroupes(controller);
        return () => controller.abort();
    }, []);

    /**
     * Chargement des groupes
     */
    const loadGroupes = (controller?: AbortController) => {
        setIsLoadingGroupes(true);
        setIsLoadingGroupesError(false);
        groupeService.getAllGroupes(controller?.signal)
            .then((response) => {
                if(response.status === 200) {
                    setIsLoadingGroupes(false);
                    setGroupes(response.data);
                }
            })
            .catch((err) => {
                if (err.message !== 'canceled') {
                    setIsLoadingGroupes(false);
                    setIsLoadingGroupesError(true);
                }
            });
    }

    /**
     * Création d'un nouvel utilisateur
     * @param createUtilisateurDto les champs nécessaires à la création d'un utilisateur
     */
    const onCreate = (createUtilisateurDto: CreateUtilisateurDto) => {
        setIsLoading(true);
        setIsError(false);
        utilisateurService.createUtilisateur(createUtilisateurDto)
            .then((response) => {
                if(response.status === 201) {
                    setIsLoading(false);
                    setIsError(false);
                    navigate(`/administration/utilisateurs`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                if (error.response.status === 409) {
                    setErrorMessage(error.response.data.message);  // Un utilisateur avec le login .... existe déjà.
                }
                // Types d'erreur qui ne doivent pas arriver, car aussi contrôlé coté "front" dans le formulaire
                if (error.response.status === 400) {
                    const errorResponseMessage = error.response.data.message;
                    // Traitement de la première erreur trouvée dans les messages d'erreur
                    if (errorResponseMessage[0].includes('should not be empty')) {
                        const champErreur = (errorResponseMessage)[0].split(' ')[0]
                        if (champErreur === 'nom' || champErreur === 'prenom' || champErreur === 'login' || champErreur === 'password')
                            setErrorMessage("Le champ " + champErreur + " est obligatoire.");
                    }
                    if (errorResponseMessage[0] === 'password must be longer than or equal to 8 characters') {
                        setErrorMessage("Le mot de passe est obligatoire avec un nombre de caractères compris entre 8 et 24.");
                    }
                    if (errorResponseMessage[0].includes('mail must match')) {
                        setErrorMessage("Le champ de l'email est invalide.");
                    }
                }
            })
    }

    /**
     * Gère l'événement de l'envoi du formulaire et déclenche la création de l'utilisateur
     */
    const handleOnCreate = () => {
        setShowConfirmationModale(false);
        onCreate({
            login: utilisateur.login,
            nom: utilisateur.nom,
            prenom: utilisateur.prenom,
            mail: utilisateur.mail,
            password: utilisateur.password,
            groupe: utilisateur.groupe,
            estActif: utilisateur.estActif,
            mdpAModifier: utilisateur.mdpAModifier
        });
    }

    /**
     * Gère le changement de valeur d'un champ du formulaire et met à jour l'état
     */
    const handleOnChange = (event: any) => {
        setUtilisateur((prevState: CreateUtilisateurDto) => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    /**
     * Gère le change de valeur du groupe sélectionné et met à jour l'état
     */
    const handleOnChangeGroupe = (event: any) => {
        const newGroupe = groupes.find((groupe: Groupe) => groupe.id === Number(event.target.value));
        if (newGroupe) {
            setUtilisateur((prevState: CreateUtilisateurDto) => ({
                ...prevState,
                [event.target.id]: newGroupe
            }));
        }
    }

    /**
     * Gère le changement du nom de l'utilisateur
     */
    const handleOnChangeNom = (event: any) => {
        setUtilisateur( (prevState: CreateUtilisateurDto) => ({...prevState, nom: event.target.value.toUpperCase() }));
    }

    /**
     * Gère le changement du prénom de l'utilisateur
     * @param event
     */
    const handleOnChangePrenom = (event: any) => {
        const valueInput = event.target.value;
        // capitalize tous les mots de la chaine de caractères (=1ère lettre des mots en majuscule)
        const valeurPrenom = valueInput.replace(/(?:^|\s)\S/g, function(a: string) { return a.toUpperCase(); });
        setUtilisateur( (prevState: CreateUtilisateurDto) => ({...prevState, prenom: valeurPrenom }));
    }

    let selectGroupes;
    if (isLoadingGroupes) {
        selectGroupes = (
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    } else if (isLoadingGroupesError) {
        selectGroupes = (
            <div className={"alert alert-danger m-0 d-flex justify-content-center align-items-center restricted-alert"}>
                Une erreur est survenue lors du chargement des groupes
            </div>
        );
    } else {
        selectGroupes = (
            <select className="form-select" id="groupe" value={utilisateur.groupe.id} onChange={handleOnChangeGroupe} required>
                {/* option du select d'initialisation, il est vide à l'affichage et caché du choix de sélection */}
                {/* Sa valeur est nulle : "", donc cette option n'est pas considérée comme un champ sélectionné pour le 'required'*/}
                <option id="valeur-par-defaut" value={""} hidden />
                {
                    groupes.map((groupe: Groupe) => {
                        return (
                            <option key={groupe.id} value={groupe.id}>{groupe.nom}</option>
                        )
                    })
                }
            </select>
        );
    }

    return(
        <div className={"row justify-content-center"}>
            <div className={"col-xs-8 col-lg-10 col-xl-8"}>
                <div className={"position-relative"}>
                    <div className="card">
                        <div className="card-body d-flex flex-column align-content-center">
                            <div className={"w-100 mb-3 utilisateur-title-style-caption"}>
                                Ajout d'un utilisateur
                            </div>
                            <div>
                                {
                                    // Affichage de l'erreur s'il y en a une (récupération du message coté back")
                                    isError
                                        ? (
                                            <div className="alert alert-danger" role="alert">
                                                { errorMessage }
                                            </div>
                                        ) : null
                                }
                                <form onSubmit={(event: FormEvent<HTMLFormElement>) => {
                                    event.preventDefault();
                                    setShowConfirmationModale(true);
                                }}>
                                    <div className={"row mb-3"}>
                                        <div className={"col-md-4"}>
                                            <div className={"row h-100 vertical-align"}>
                                                <LabelFormulaire htmlFor={"nom"}>
                                                    Nom
                                                </LabelFormulaire>
                                            </div>
                                        </div>
                                        <div className={"col-md-8"}>
                                            <input type="text" style={{textTransform: "uppercase"}} className="form-control" id="nom" onChange={handleOnChangeNom} required/>
                                        </div>
                                    </div>
                                    <div className={"row mb-3"}>
                                        <div className={"col-md-4"}>
                                            <div className={"row h-100 vertical-align"}>
                                                <LabelFormulaire htmlFor={"prenom"}>
                                                    Prénom
                                                </LabelFormulaire>
                                            </div>
                                        </div>
                                        <div className={"col-md-8"}>
                                            <input type="text" style={{textTransform: "capitalize"}} className="form-control" id="prenom" onChange={handleOnChangePrenom} required/>
                                        </div>
                                    </div>
                                    <div className={"row mb-3"}>
                                        <div className={"col-md-4"}>
                                            <div className={"row h-100 vertical-align"}>
                                                <LabelFormulaire htmlFor={"login"}>
                                                    Login
                                                </LabelFormulaire>
                                            </div>
                                        </div>
                                        <div className={"col-md-8"}>
                                            <input type="text" className="form-control" id="login" value={utilisateur.login} onChange={handleOnChange} required/>
                                        </div>
                                    </div>
                                    <div className={"row mb-3"}>
                                        <div className={"col-md-4"}>
                                            <div className={"row h-100 vertical-align"}>
                                                <LabelFormulaire htmlFor={"password"}>
                                                    Mot de passe
                                                </LabelFormulaire>
                                            </div>
                                        </div>
                                        <div className={"col-md-8"}>
                                            <input type="text" className="form-control" id="password" pattern={PATTERN_PASSWORD} value={utilisateur.password} onChange={handleOnChange} required/>
                                        </div>
                                    </div>
                                    <div className={"row mb-3"}>
                                        <div className={"col-md-4"}>
                                            <div className={"row h-100 vertical-align"}>
                                                <LabelFormulaire htmlFor={"mail"}>
                                                    Email
                                                </LabelFormulaire>
                                            </div>
                                        </div>
                                        <div className={"col-md-8"}>
                                            <input type="text" className="form-control" id="mail" pattern={PATTERN_EMAIL} onChange={handleOnChange} required/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <div className={"row h-100 vertical-align"}>
                                                <LabelFormulaire htmlFor={"groupe"}>
                                                    Groupe
                                                </LabelFormulaire>
                                            </div>
                                        </div>
                                        <div className={"col-md-8 d-flex justify-content-center"}>
                                            { selectGroupes }
                                        </div>
                                    </div>
                                    <div className={"row mx-0"}>
                                        <div className={"card bg-light mt-4 pt-1 pb-3"} style={{borderColor: "red"}}>
                                            <p className={"utilisateur-title-style-caption pt-1 pb-1"} style={{color: "red"}}>Informations :</p>
                                            <small className={"text-justify"}>- Le mot de passe temporaire doit être noté afin de le transmettre au nouvel utilisateur.</small>
                                            <small className={"text-justify"}>- L'utilisateur devra changer son mot de passe à la première connexion.</small>
                                        </div>
                                    </div>
                                    <div className={"row mt-4 d-flex justify-content-evenly"}>
                                        <VoxButton
                                            customColor={"red"}
                                            variant={"outlined"}
                                            onClick={() => navigate("/administration/utilisateurs")}
                                            className={"size-button"}
                                            sx={{marginBottom: 2}}
                                        >
                                            Annuler
                                        </VoxButton>
                                        <VoxButton
                                            customColor={"green"}
                                            type={"submit"}
                                            className={"size-button"}
                                            sx={{marginBottom: 2}}
                                        >
                                            Valider
                                        </VoxButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <ModaleConfirmationUtilisateur
                        show={showConfirmationModale}
                        handleClose={() => {
                            setShowConfirmationModale(false);
                        }}
                        handleSubmit={handleOnCreate}
                        nom={utilisateur.nom}
                        prenom={utilisateur.prenom}
                        password={utilisateur.password}
                    />

                    {
                        isLoading ? <VoxLoader isBackgroundColor={true} /> : null
                    }
                </div>
            </div>
        </div>
    )
}
