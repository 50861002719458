import {useNavigate} from "react-router-dom";
import React, {ReactElement} from "react";
import "./voxHeader.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import VoxButton from "../../genericComponentsUI/button/voxButton";


type PropsType = {
    isLeftTitleMode?: boolean; // Permet d'afficher le header en mode "titre à gauche" et boutons à droite
    isBoutonRetour?: boolean; // Affiche un bouton "Retour" gris
    lienRetour?: string; // Permet de choisir une destination de retour, sinon -1 dans l'historique
    title?: string | ReactElement; // Affiche le titre de la page
    boutonFavoris?: ReactElement; // Affiche un bouton "Suivre" (gestion des favoris)
    boutonLibre?: ReactElement; // Permet d'ajouter librement un autre bouton (ex. Modification)
    boutonTutoriels?: ReactElement; // Affiche un bouton "Rejouer le tutoriel"
    marginTop?: number; // Ajoute une marge supérieure si éléments au-dessus du header
}

/**
 * Composant permettant la création d'un header (mode titre centré ou titre à gauche) incluant plusieurs boutons
 *
 * @param isBoutonRetour
 * @param lienRetour
 * @param title
 * @param boutonFavoris
 * @param boutonLibre
 * @param boutonTutoriels
 * @param isLeftTitleMode
 * @param marginTop
 * @constructor
 */
const VoxHeader = ({
                       isBoutonRetour = false,
                       lienRetour = undefined,
                       title = undefined,
                       boutonFavoris = undefined,
                       boutonLibre = undefined,
                       boutonTutoriels = undefined,
                       isLeftTitleMode = false,
                       marginTop = 0
                   }: PropsType) => {

    const navigate = useNavigate();

    const boutonRetour = () => (
        <VoxButton
            customColor={"grey"}
            variant={"outlined"}
            startIcon={<FontAwesomeIcon icon={"arrow-left"}/>}
            onClick={() => lienRetour ? navigate(lienRetour) : navigate(-1)}
        >
            Retour
        </VoxButton>
    )

    return (
        <Box sx={{mb: 6, mt: marginTop}}>

            {!isLeftTitleMode ?

                // MODE "TITRE CENTRÉ"
                // Bouton Retour à gauche + Titre au milieu + Bouton tuto adapté au bouton retour

                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                    gap: 4
                }}
                >

                    {/* Bouton "Retour" (gauche) */}
                    {isBoutonRetour &&
                        <Box sx={{width: "200px"}}>
                            {boutonRetour()}
                        </Box>
                    }

                    {/* Titre de la page (milieu) */}
                    <Typography variant={"h1"} flex={"auto"} sx={{textAlign: 'center'}}
                                className={`${(isBoutonRetour && !boutonTutoriels) ? "margin-right-title" : ""}`}
                    >
                        {title}
                    </Typography>

                    {/* Bouton contenant un tutoriel (droite) */}
                    {boutonTutoriels &&
                    isBoutonRetour ?
                        // Ajout d'une div de 200px si le bouton Retour est affiché à gauche
                        <Box sx={{width: "200px", display: "flex", justifyContent: "end"}}>
                            {boutonTutoriels}
                        </Box>
                        :
                        // Ou position en absolute si le bouton Retour est absent
                        <Box sx={{position: "absolute", right: 0}}>
                            {boutonTutoriels}
                        </Box>
                    }

                </Box>

                :

                // MODE "TITRE À GAUCHE" (page détail opération)
                // Titre à gauche + Plusieurs boutons à droite
                // En responsive, Flexbox passe en colonne et tout est centré

                <Box className={"left-title-mode"}>
                    {/* Titre de la page */}
                    <Box className={"title"}>
                        <Typography variant={"h1"} sx={{fontSize: "1.3rem"}}>
                            {title}
                        </Typography>
                    </Box>

                    {/* Boutons */}
                    <Box className={"buttons"}>
                        {boutonFavoris}
                        {boutonLibre}
                        {boutonTutoriels}
                    </Box>
                </Box>

            }

        </Box>
    );
}

export default VoxHeader;