import dayjs from "dayjs";
import {TypeOperation} from "../models/operation/typeOperation.enum";
import {EtatDemande} from "../models/operation/etatDemande.enum";
import {TypePrestation} from "../models/operationSIRS/typePrestation.enum";
import {TypeEnvironnement} from "../models/environnements/typeEnvironnement.enum";

/**
 * Fonction qui génère aléatoirement une chaine de caractères
 * @param stringLength longueur de la chaine de caractères que l'on souhaite générer
 */
export function generateRandomString(stringLength: number) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$!%';
    const randomValuesArray = new Uint8Array(stringLength);
    crypto.getRandomValues(randomValuesArray);
    const generatedChars: string[] = [];
    randomValuesArray.forEach(value => generatedChars.push(charset.charAt(value % charset.length)));
    return generatedChars.join("");
}


/**
 * PATTERN pour le mot de passe
 * et message correspondant au pattern pour le format autorisé
 */
export const PATTERN_PASSWORD = ".{8,24}";
export const TITLE_PATTERN_PASSWORD = "Le mot de passe doit contenir un nombre de caractères entre 8 et 24.";

/**
 * PATTERN d'un mail (pattern)
 */
export const PATTERN_EMAIL = "(?=.{5,255}$)^[\\w]{1,}[\\w\\+\\.\\-]*@[\\w]{1,}[\\w\\+\\.\\-]*\\.[\\w]{1,}[\\w\\+\\.\\-]*$";

/**
 * PATTERN d'un numéro de téléphone français (pattern)
 */
export const PATTERN_TELEPHONE_FRANCAIS = "^0\\s*[1-9](?:[\\s.\\-]*\\d{2}){4}$";

/**
 * PATTERN pour une URL
 * et message pour les caractères autorisés
 */
export const PATTERN_URL = "^(https?:\\/\\/)([a-z0-9\\-\\.\\/]+)$";
export const TITLE_PATTERN_URL = "Une URL commence par le préfixe http(s):// et ensuite seuls les caractères minuscules alpha-numériques ainsi que '-' , '.' et '/' sont autorisés.";

/**
 * Méthode permettant d'afficher la date au format 'DD/MM/YYYY HH:mm'
 * @param date
 */
export const affichageDateWithFormatDMYHm = (date: Date | null) : string => {
    if (date !== null && dayjs(date).isValid()) {
        return dayjs(date).format('DD/MM/YYYY HH:mm');
    } else {
        return "";
    }
}

/**
 * Méthode permettant d'afficher la date au format 'DD/MM/YYYY à HH:mm:ss'
 * @param date
 */
export const affichageDateWithFormatFullTexteDMYHms = (date: Date) => {
    if (date !== null && dayjs(date).isValid()) {
        return dayjs(date).format('DD/MM/YYYY à HH:mm:ss');
    } else {
        return null;
    }
}

/**
 * Méthode permettant d'afficher la date au format 'YYYY-MM-DD' (input type "date")
 * @param date
 */
export const affichageDateWithFormatYMD = (date: Date) : string => {
    if (date !== null && dayjs(date).isValid()) {
        return dayjs(date).format('YYYY-MM-DD');
    } else {
        return "";
    }
}

/**
 * Méthode permettant d'afficher la date au format 'YYYY-MM-DDTHH:mm' (input type "datetime-local")
 * @param date
 */
export const affichageDateWithFormatYMDHm = (date: Date) : string => {
    if (date !== null && dayjs(date).isValid()) {
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    } else {
        return "";
    }
}


/**
 * Spécification du domaine de l'URL suivant le type d'opération
 */
export function domaineUrl(typeOperation: TypeOperation | undefined) {
    if (typeOperation === TypeOperation.SATURNE || typeOperation === TypeOperation.MAILEVA) {
        return ".voxintime.com"
    }
    else if (typeOperation === TypeOperation.VOXCORE_ELECTION || typeOperation === TypeOperation.VOXCORE_AG) {
        return ".vote.voxaly.com"
    }
    else if (typeOperation === TypeOperation.ACTIONNARIAT) {
        return ".assets.voxaly.com"
    }
    else {
        return ".[domaine]"
    }
}

/**
 * Spécification du début de l'URL selon le type de prestation pour les opérations SIRS
 */
export function formatDebutUrlOperationSIRS(typePrestation: TypePrestation | undefined) {
    if (typePrestation === TypePrestation.UNLIMITED) {
        return "www.docapost-sirs.fr/";
    } else if (typePrestation === TypePrestation.MY_BDES) {
        return "www.mybdes.fr/";
    } else {
        return "[domaine-prestation]/"
    }
}

/**
 * Affichage correct du libellé pour l'état d'installation d'une opération
 * @param etatDemande
 */
export function affichageLibelleEtatDemande(etatDemande: EtatDemande) {
    switch (etatDemande) {
        case EtatDemande.DECLARE:
            return "Déclaré";
        case EtatDemande.COMMANDE:
            return "Commandé";
        case EtatDemande.LIVRE_OVH:
            return "Livré OVH";
        case EtatDemande.DNS_DECLARE:
            return "DNS déclaré";
        case EtatDemande.INSTALLATION_ANSIBLE:
            return "Installation Ansible";
        case EtatDemande.ERREUR_INSTALLATION_ANSIBLE:
            return "Erreur installation Ansible"
        case EtatDemande.INSTALLE_ANSIBLE:
            return "Installé Ansible";
        case EtatDemande.INSTALLE:
            return "Installé";
        case EtatDemande.DNS_VALIDE:
            return "DNS validé";
        case EtatDemande.VM_INITIALISEE:
            return "VM initialisée";
        case EtatDemande.EN_PRODUCTION:
            return "En production";
        case EtatDemande.A_SUPPRIMER:
            return "À supprimer";
        case EtatDemande.SUPPRESSION_ANSIBLE:
            return "Suppression Ansible";
        case EtatDemande.SUPPRIME_ANSIBLE:
            return "Supprimé Ansible"
        case EtatDemande.SUPPRIME:
            return "Supprimé";
        case EtatDemande.ERREUR:
            return "Erreur";
        case EtatDemande.A_ANNULER:
            return "À annuler";
        case EtatDemande.EN_CONTENTIEUX:
            return "En contentieux";
        case EtatDemande.A_ARCHIVER:
            return "À archiver";
        case EtatDemande.ABANDONNE:
            return "Abandonné";
        default:
            // ne doit pas arriver
            return etatDemande;
    }
}

/**
 * Affichage du libellé pour les Types d'Environnements
 */
export function affichageLibelleTypeEnvironnement(typeEnv: TypeEnvironnement) {
    switch(typeEnv) {
        case TypeEnvironnement.DEMO: return "Démo"
        case TypeEnvironnement.PREPROD: return "PréProd"
        case TypeEnvironnement.RECETTE_CLIENT: return "Recette Client"
        case TypeEnvironnement.RECETTE_INTERNE: return "Recette Interne (intégration continue)"
        default: return typeEnv
    }
}

/**
 * Affichage du préfixe url pour les Environnements hors production
 */
export function prefixeUrlEnvironnements(typeEnv?: TypeEnvironnement) {
    switch(typeEnv) {
        case TypeEnvironnement.DEMO: return "demo-"
        case TypeEnvironnement.PREPROD: return "preprod-"
        case TypeEnvironnement.RECETTE_CLIENT: return "recette-"
        case TypeEnvironnement.RECETTE_INTERNE: return "voxcore2XX-"
        default: return ""
    }
}

/**
 * Méthode permettant de récupérer le numéro de version majeure d'une version applicative
 * (Récupération des deux premiers caractères de la version applicative)
 * @param version
 */
export function getNumeroVersionMajeure(version: string): number {
    const versionMajeure: string = version.substring(0, 2);
    return Number(versionMajeure);
}


/**
 * Méthode permettant de récupérer la partie cliente de l'URL d'une opération SIRS
 */
export function getUrlPartieClienteFromOperationSIRS(url: string): string {
    const urlSplit = url.split('/');
    return urlSplit[urlSplit.length -1];
}