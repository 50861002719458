import IntroJS from './IntroJs';
import { allSteps } from "../../models/tutorial/allSteps.model";

export default function IntroJsOperationsEnProduction() {

    // Contenu des étapes - Opérations en production (menu "Opérations")
    const steps: allSteps[] = [
        {
            // 1. Tableau global
            element: "#table-operations-production",
            position: "bottom-middle-aligned",
            intro: <p>Dans ce tableau apparaissent toutes les opérations qui ont un site actif en production (en préparation, en cours de vote ou clos).</p>
        },
        {
            // 2. Champ de recherche globale
            element: "#global-filter",
            position: "bottom-middle-aligned",
            intro: <p>Ici, vous pouvez effectuer une recherche sur tous les champs affichés dans le tableau.</p>
        },
        {
            // 3. Picto d'affichage des colonnes du tableau
            element: "#dropdown-affichage-colonnes",
            position: "left",
            intro: <p>Ici, vous pouvez personnaliser les colonnes affichées dans le tableau.</p>
        },
        {
            // 4. Bouton "Filtres d'affichage"
            element: "#collapse-filtres-btn",
            position: "bottom-middle-aligned",
            intro: <p>Ici, vous pouvez effectuer des filtres spécifiques afin d'afficher les opérations en fonction de plusieurs critères.</p>
        },
        {
            // 5. Bouton "Télécharger CSV"
            element: "#download-csv-btn",
            position: "bottom-middle-aligned",
            intro: (
                <>
                    <p>Ce bouton permet de télécharger les opérations du tableau selon les filtres d'affichage sélectionnés.</p>
                    <p>NB : les filtres utilisés dans les colonnes du tableau sont directement pris en compte.</p>
                </>
            )
        }
    ];

    return (
        <IntroJS
            steps={steps}
        />
    );

}