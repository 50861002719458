import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import operationService from "../../../services/survox-back/operation/operation.service";
import {
    CreateOperationSaturneMailevaDto,
    CreateOperationSaturneMailevaWithParamsDto,
    ParamsCreateOperationSaturneMailevaDto
} from "../../../services/survox-back/operation/dtos/request/createOperationSaturneMaileva.dto";
import { VersionApplicative } from "../../../models/versionApplicative/versionApplicative.model";
import { TypeOperation } from "../../../models/operation/typeOperation.enum";
import {
    CreateOperationDto,
    CreateOperationWithParamsDto,
    ParamsCreateOperationDto
} from "../../../services/survox-back/operation/dtos/request/createOperation.dto";
import {
    CreateOperationActionnariatDto,
    CreateOperationActionnariatWithParamsAndDatesDto,
    DatesActionnariatDto,
    ParamsCreateOperationActionnariatDto
} from "../../../services/survox-back/operation/dtos/request/createOperationActionnariat.dto";
import { Domaine } from "../../../models/operation/domaine.enum";
import { NomPlateforme } from "../../../models/operation/nomPlateforme.enum";
import { Grid } from "@mui/material";
import { VoxAlert } from "../../genericComponentsUI/alerte/voxAlert";
import VoxLoader from "../../genericComponentsUI/loaders/voxLoader";
import VoxCard from "../../genericComponentsUI/card/voxCard";
import { ParamsOperationFormulaire } from "../../../models/operation/operationForm/paramsOperationFormulaire.model";
import { OperationFormulaire } from "../../../models/operation/operationForm/operationFormulaire.model";
import { DatesActionnariatFormulaire } from "../../../models/operation/operationForm/datesActionnariatFormulaire.model";
import OperationsForm from "./operationsForm";
import { isVersionApplicativeSansComposants } from "../../../utils/versionApplicative.utils";
import { TypeProduit } from "../../../models/versionApplicative/typeProduit.enum";


/**
 * Fonction composant avec le formulaire de création d'une opération Saturne ou MailEva ou Autre (Voxcore-Election, Voxcore-AG, Actionnariat)
 * @param props
 * @constructor
 */
export default function OperationsCreation(props: { typeOperation: TypeOperation | string }) {

    const operationInit: OperationFormulaire = {
        nomClient: "",
        portefeuilleProjet: {id: 0}, // pas de portefeuille projet sélectionné (initialisation)
        dateInstallation: undefined,
        type: (props.typeOperation === TypeOperation.SATURNE || props.typeOperation === TypeOperation.MAILEVA) ? props.typeOperation : undefined,
        versionApplicative: {} as VersionApplicative,
        salesforceId: "",
        plateforme: {id: 0}, // pas de plateforme sélectionnée (initialisation)
        description: ""
    }

    const paramsOperationInit: ParamsOperationFormulaire = {
        urlPartieClient: "",
        urlDomaine: Domaine.VOXALY_VOTE,    // Le domaine de l'URL peut être modifié dans le cas des opérations Optivox VoxCore-Election
        isModeTest: false,
        optionEnvoi: null,
        optionPapier: null
    }

    const datesActionnariatInit: DatesActionnariatFormulaire = {
        dateOuverture: undefined,
        dateFermeture: undefined
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isErrorIdSalesforce, setIsErrorIdSalesforce] = useState(false);
    const [messageErrorIdSalesforce, setMessageErrorIdSalesforce] = useState("");
    const [isErrorDateInstallation, setIsErrorDateInstallation] = useState(false);
    const [isErrorDateOuverture, setIsErrorDateOuverture] = useState(false);
    const [isErrorDateFermeture, setIsErrorDateFermeture] = useState(false);
    const [messageError, setMessageError] = useState("");
    const [messageErrorUrlDoublon, setMessageErrorUrlDoublon] = useState("");
    const [isErrorVersionSpecifique, setIsErrorVersionSpecifique] = useState(false);
    const [messageErrorVersionSpecifique, setMessageErrorVersionSpecifique] = useState("");

    const navigate = useNavigate();


    /**
     * Conditions pour l'affichage de l'input "Date d'installation"
     */
    const [isAffichageDateInstallation, setIsAffichageDateInstallation] = useState<boolean>(false);

    useEffect(() => {
        if (
            props.typeOperation === "VoxCoreElection_VoxCoreAG_Actionnariat"
            || props.typeOperation === TypeOperation.VOXCORE_ELECTION
            || props.typeOperation === TypeOperation.VOXCORE_AG
            || props.typeOperation === TypeOperation.ACTIONNARIAT
        ) {
            setIsAffichageDateInstallation(true);
        } else {
            setIsAffichageDateInstallation(false);
        }
    }, [props.typeOperation]);



    const onCreateOperationSaturneOuMaileva = (createOperationSaturneMailevaDto: CreateOperationSaturneMailevaDto, paramsOperationSaturneMailevaDto: ParamsCreateOperationSaturneMailevaDto) => {
        setIsLoading(true);
        setIsError(false);
        setIsErrorIdSalesforce(false);
        const createOperationSaturneMailevaWithParamsDto: CreateOperationSaturneMailevaWithParamsDto = {...createOperationSaturneMailevaDto, ...paramsOperationSaturneMailevaDto};
        /**
         * Création d'une opération Saturne (qui va s'enregistrer en base de données)
         */
        if (props.typeOperation === TypeOperation.SATURNE) {
            operationService.createOperationSaturne(createOperationSaturneMailevaWithParamsDto)
                .then((response) => {
                    if (response.status === 201) {
                        setIsLoading(false);
                        setIsError(false);
                        setIsErrorIdSalesforce(false);
                        navigate(`/saturne/mes_operations`);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    setIsErrorIdSalesforce(false);
                    if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                        setIsErrorIdSalesforce(true);
                        setMessageErrorIdSalesforce(error.response.data.message[0]);
                    } else if (error.response.status === 403) {
                        // erreur doublon url
                        setMessageErrorUrlDoublon(error.response.data.message);
                    } else {
                        setMessageError(error.response.data.message[0]);
                    }
                })
        }
        /**
         * Création d'une opération Maileva (qui va s'enregistrer en base de données)
         */
        if (props.typeOperation === TypeOperation.MAILEVA) {
            operationService.createOperationMaileva(createOperationSaturneMailevaWithParamsDto)
                .then((response) => {
                    if (response.status === 201) {
                        setIsLoading(false);
                        setIsError(false);
                        setIsErrorIdSalesforce(false);
                        navigate(`/maileva/mes_operations`);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    setIsErrorIdSalesforce(false);
                    if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                        setIsErrorIdSalesforce(true);
                        setMessageErrorIdSalesforce(error.response.data.message[0]);
                    } else if (error.response.status === 403) {
                        // erreur doublon url
                        setMessageErrorUrlDoublon(error.response.data.message);
                    } else {
                        setMessageError(error.response.data.message[0]);
                    }
                })
        }
    }

    /**
     * Création d'une opération VoxCore-Election ou VoxCore-AG (qui va s'enregistrer en base de données)
     */
    const onCreateOperation = (createOperationDto: CreateOperationDto, paramsOperationDto: ParamsCreateOperationDto) => {
        setIsLoading(true);
        setIsError(false);
        setIsErrorIdSalesforce(false);
        const createOperationWithParamsDto: CreateOperationWithParamsDto = {...createOperationDto, ...paramsOperationDto};
        operationService.createOperation(createOperationWithParamsDto)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false);
                    setIsError(false);
                    setIsErrorIdSalesforce(false);
                    navigate(`/operations/mes_operations`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                setIsErrorIdSalesforce(false);
                if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                    setIsErrorIdSalesforce(true);
                    setMessageErrorIdSalesforce(error.response.data.message[0]);
                } else if (error.response.status === 403) {
                    // erreur doublon url
                    setMessageErrorUrlDoublon(error.response.data.message);
                } else {
                    setMessageError(error.response.data.message[0]);
                }
            })
    }

    /**
     * Création d'une opération Actionnariat (qui va s'enregistrer en base de données)
     */
    const onCreateOperationActionnariat = (createOperationActionnariatDto: CreateOperationActionnariatDto, paramsOperationActionnariatDto: ParamsCreateOperationActionnariatDto, datesActionnariatDto: DatesActionnariatDto) => {
        setIsLoading(true);
        setIsError(false);
        setIsErrorIdSalesforce(false);
        const createOperationActionnariatWithParamsDto: CreateOperationActionnariatWithParamsAndDatesDto = {...createOperationActionnariatDto, ...paramsOperationActionnariatDto, ...datesActionnariatDto};
        operationService.createOperationActionnariat(createOperationActionnariatWithParamsDto)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false);
                    setIsError(false);
                    setIsErrorIdSalesforce(false);
                    navigate(`/operations/mes_operations`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                setIsErrorIdSalesforce(false);
                if ((error.response.status === 400) && (error.response.data.message[0] === "L'Id Salesforce ne doit pas dépasser 38 caractères")) {
                    setIsErrorIdSalesforce(true);
                    setMessageErrorIdSalesforce(error.response.data.message[0]);
                } else if (error.response.status === 403) {
                    // erreur doublon url
                    setMessageErrorUrlDoublon(error.response.data.message);
                } else {
                    setMessageError(error.response.data.message[0]);
                }
            })
    }

    /**
     * Lors de la validation du formulaire, appel de cette méthode 'handleOnCreate' pour faire la création d'une opération (de type Saturne ou Maileva)
     * @param operation
     * @param paramsOperation
     * @param datesActionnariat
     */
    const handleOnCreate = (operation: OperationFormulaire, paramsOperation: ParamsOperationFormulaire, datesActionnariat: DatesActionnariatFormulaire) => {
        setIsError(false);
        setMessageErrorUrlDoublon("");
        setIsErrorDateInstallation(false);
        setIsErrorDateOuverture(false);
        setIsErrorDateFermeture(false);
        setIsErrorVersionSpecifique(false);
        if (operation.type !== undefined) {
            // Vérification pour une version spécifique (c'est-à-dire qui ne possède pas de composants)
            // (qu'il est possible de renseigner pour le type de produit VoxCore-Election)
            if (operation.versionApplicative && operation.versionApplicative.typeProduit === TypeProduit.VOXCORE_ELECTION && isVersionApplicativeSansComposants(operation.versionApplicative)) {
                if (operation.type !== TypeOperation.VOXCORE_ELECTION) {
                    setIsErrorVersionSpecifique(true);
                    setMessageErrorVersionSpecifique("La sélection d'une version spécifique n'est possible que pour le produit VoxCore-Election.");
                    return;
                } else if (operation.plateforme.nom === NomPlateforme.H1) {
                    setIsErrorVersionSpecifique(true);
                    setMessageErrorVersionSpecifique("Incohérence sur le choix de la version : sur une plateforme H1, seules les versions standards sont autorisées. Merci de changer soit la version, soit la plateforme cible.");
                    return;
                }
            }

            if (operation.type === TypeOperation.SATURNE || operation.type === TypeOperation.MAILEVA) {
                onCreateOperationSaturneOuMaileva(
                    {
                        nomClient: operation.nomClient,
                        portefeuilleProjet: operation.portefeuilleProjet,
                        type: operation.type,
                        versionApplicative: operation.versionApplicative,
                        salesforceId: operation.salesforceId,
                        plateforme: operation.plateforme,
                        description: (operation.description.trim() === "") ? null : operation.description.trim()    // .trim() : // enlève les espaces au debut et à la fin de la chaine de caractères
                    },
                    {
                        urlPartieClient: paramsOperation.urlPartieClient,
                        isModeTest: paramsOperation.isModeTest,
                        optionEnvoi: paramsOperation.optionEnvoi,
                        optionPapier: paramsOperation.optionPapier
                    }
                );
            }
            if (operation.type === TypeOperation.VOXCORE_ELECTION || operation.type === TypeOperation.VOXCORE_AG) {
                if (operation.dateInstallation !== undefined) {
                    onCreateOperation(
                        {
                            nomClient: operation.nomClient,
                            portefeuilleProjet: operation.portefeuilleProjet,
                            dateInstallation: operation.dateInstallation,
                            type: operation.type,
                            versionApplicative: operation.versionApplicative,
                            salesforceId: operation.salesforceId,
                            plateforme: operation.plateforme,
                            description: (operation.description.trim() === "") ? null : operation.description.trim()
                        },
                        {
                            urlPartieClient: paramsOperation.urlPartieClient,
                            urlDomaine: paramsOperation.urlDomaine,
                            isModeTest: paramsOperation.isModeTest,
                        }
                    );
                } else {
                    setIsErrorDateInstallation(true);
                }
            }
            if (operation.type === TypeOperation.ACTIONNARIAT) {
                if (operation.dateInstallation !== undefined) {
                    if (datesActionnariat.dateOuverture !== undefined) {
                        if (datesActionnariat.dateFermeture !== undefined) {
                            onCreateOperationActionnariat(
                                {
                                    nomClient: operation.nomClient,
                                    portefeuilleProjet: operation.portefeuilleProjet,
                                    dateInstallation: operation.dateInstallation,
                                    type: operation.type,
                                    versionApplicative: operation.versionApplicative,
                                    salesforceId: operation.salesforceId,
                                    plateforme: operation.plateforme,
                                    description: (operation.description.trim() === "") ? null : operation.description.trim()
                                },
                                {
                                    urlPartieClient: paramsOperation.urlPartieClient
                                },
                                {
                                    dateOuverture: datesActionnariat.dateOuverture,
                                    dateFermeture: datesActionnariat.dateFermeture
                                }
                            );
                        } else {
                            setIsErrorDateFermeture(true);
                        }
                    } else {
                        setIsErrorDateOuverture(true);
                    }
                } else {
                    setIsErrorDateInstallation(true);
                }
            }
        } else {
            setIsError(true);
        }
    }


    /**
     * Méthode pour l'affichage des messages d'erreur
     */
    let affichageMessageErreur;
    if (isErrorVersionSpecifique) {
        affichageMessageErreur = (
            <VoxAlert severity={"error"} sx={{mb: 2}}>
                {messageErrorVersionSpecifique}
            </VoxAlert>
        )
    } else if (isError && !isErrorIdSalesforce) {
        affichageMessageErreur = (
            <VoxAlert severity={"error"} sx={{mb: 2}}>
                {(messageErrorUrlDoublon !== "")
                    ? `${messageErrorUrlDoublon}`
                    : "Une erreur est survenue lors de la création de l'opération."
                }
            </VoxAlert>
        )
    }


    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8} lg={5}>

                {/* Affichage d'un message d'erreur si cela arrive */}
                {affichageMessageErreur}

                <VoxCard>

                    {/* Formulaire */}
                    <OperationsForm
                        typeFormulaire={"creation"}

                        operation={operationInit}
                        paramsOperation={paramsOperationInit}
                        datesActionnariat={datesActionnariatInit}

                        isAffichageDateInstallation={isAffichageDateInstallation}

                        onSubmit={handleOnCreate}
                        isLoadingSubmit={isLoading}

                        isError={isError}
                        isErrorIdSalesforce={isErrorIdSalesforce}
                        isErrorDateInstallation={isErrorDateInstallation}
                        isErrorDateOuverture={isErrorDateOuverture}
                        isErrorDateFermeture={isErrorDateFermeture}
                        messageError={messageError}
                        messageErrorIdSalesforce={messageErrorIdSalesforce}
                    />

                    {(isLoading) ?
                        <VoxLoader isBackgroundColor={true} />
                        : null
                    }
                </VoxCard>
            </Grid>
        </Grid>
    )
}