import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import EnvironnementsService from "../../../services/survox-back/environnements/environnements.service";
import {EnvironnementAll} from "../../../models/environnements/environnementAll.model";
import {CellProps, Column} from "react-table";
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import VoxTable from "../../../components/genericComponentsUI/table/voxTable";
import {SelectInputFilter} from "../../../components/genericComponentsUI/table/filters/SelectInputFilter";
import {
    affichageLibelleEtatDemande,
    affichageLibelleTypeEnvironnement
} from "../../../utils/string.utils";
import {EnvironnementAllTableau} from "../../../models/environnements/environnementAllTableau.model";
import VoxLinkOperation from "../../../components/genericComponentsUI/link/voxLinkOperation";
import {GroupesEnvironnement, NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {Utilisateur} from "../../../models/utilisateur/utilisateur.model";
import VoxLinkUrl from "../../../components/genericComponentsUI/link/voxLinkUrl";
import {csvDataSerialisationAllEnvironnements} from "../../../utils/csv.utils";

export default function EnvironnementsAllListe() {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>("");

    const [allEnvironnements, setAllEnvironnements] = useState<EnvironnementAll[]>([]);

    // récupération de l'utilisateur connecté à l'application
    const authContext = useContext(AuthContext);
    const utilisateurAuth: Utilisateur = authContext.utilisateur;

    // Groupes autorisés pour visualiser le détail d'une opération
    const utilisateurAutorise: boolean = (
        GroupesEnvironnement.includes(utilisateurAuth.groupe.nom)
        || utilisateurAuth.groupe.nom === NomGroupe.OBSERVATEUR
    );

    useEffect(() => {
        const controller = new AbortController();
        loadAllEnvironnements(controller);
        return () => controller.abort();
    }, []);

    /**
     * Chargement des Environnements
     */
    const loadAllEnvironnements = (controller: AbortController) => {
        setIsLoading(true);
        setIsError(false);
        EnvironnementsService.getAllEnvironnements(controller.signal)
            .then(response => {
                if(response.status === 200) {
                    setAllEnvironnements(response.data);
                    setIsLoading(false);
                    setIsError(false);
                }
            })
            .catch(error => {
                if(error.message !== 'canceled') {
                    setIsLoading(false);
                    setIsError(true);
                    if ((error.status === 400)) {
                        setMessageError(error.response.data.message);
                    } else {
                        setMessageError("Impossible de récupérer les environnements d'opération");
                    }
                }
            })
    }

    /**
     * Cellules Opérations
     */
    const linkCells = useCallback( (opName: string, opId: number) => {
        return (
            <VoxLinkOperation
                isAuthorized={utilisateurAutorise}
                href={`/operation/detail/${opId}`}
            >
                {opName}
            </VoxLinkOperation>
        )
    }, [utilisateurAutorise]);

    /**
     * Cellules URL
     */
    const linkUrlCells = useCallback((url: string) => {
        return (
            <VoxLinkUrl href={url}>
                {url}
            </VoxLinkUrl>
        )
    }, []);

    /**
     * Lignes du Tableau
     */
    const rows: EnvironnementAllTableau[] = useMemo(() => {
        return allEnvironnements.map((env) => ({
            operationId: env.operation.id,
            operationName: env.operation.nom,
            typeEnvironnement: env.typeEnvironnement,
            url: env.url,
            etatDemande: env.etatDemande
        })
        )
    }, [allEnvironnements])

    /**
     * Colonnes du Tableau
     */
    const columns: Column<EnvironnementAllTableau>[] = useMemo(() => [
        {
            Header: 'Opération Liée',
            id: "operationName",
            accessor: 'operationName',
            minWidth: 100,
            Cell: (props: CellProps<EnvironnementAllTableau>) => linkCells(props.row.original.operationName, props.row.original.operationId)
        },
        {
            Header: 'Type d\'Environnement',
            id: "typeEnvironnement",
            Filter: SelectInputFilter,
            filter: 'includes',
            accessor: (originalRow => affichageLibelleTypeEnvironnement(originalRow.typeEnvironnement)),
            minWidth: 80
        },
        {
            Header: 'URL',
            id: "url",
            accessor: 'url',
            minWidth: 200,
            Cell: (props: CellProps<EnvironnementAllTableau>) => linkUrlCells(props.row.original.url)
        },
        {
            Header: 'Etat Demande',
            id: "etatDemande",
            Filter: SelectInputFilter,
            filter: 'includes',
            accessor: (originalRow => affichageLibelleEtatDemande(originalRow.etatDemande)),
            minWidth: 80
        }
    ], [linkCells, linkUrlCells])


    return (
        <>
            {isError &&
            <VoxAlert severity={"error"} >
                {messageError}
            </VoxAlert>
            }
            {isLoading ? <VoxLoader /> :
                <VoxTable
                    isCsvExport={true}
                    csvFileName={"environnementsHorsProduction"}
                    csvDataSerialization={csvDataSerialisationAllEnvironnements}
                    initialStateSortBy={[{id: 'operationName'}]}
                    data={rows}
                    columns={columns}
                />
            }
        </>
    )
}