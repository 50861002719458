import OperationsCreation from "../../components/forms/operationsFormulaires/operationsCreation";
import React from "react";
import IntroJsDeclarerOperation from "../../components/tutorial/IntroJsDeclarerOperation";
import VoxHeader from "../../components/layouts/viewHeader/voxHeader";

export default function OperationsCreationView() {

    return (
        <>
            <VoxHeader title={"Nouvelle opération"}
                       isBoutonRetour={true} lienRetour={"/operations/mes_operations"}
                       boutonTutoriels={<IntroJsDeclarerOperation/>}
            />
            <OperationsCreation typeOperation={"VoxCoreElection_VoxCoreAG_Actionnariat"}/>
        </>
    );
}