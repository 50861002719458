import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Operation} from "../../../models/operation/operation.model";
import {affichageDateWithFormatDMYHm, affichageLibelleEtatDemande} from "../../../utils/string.utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import {Hebergeur} from "../../../models/operation/hebergeur.enum";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import {NomPlateforme} from "../../../models/operation/nomPlateforme.enum";
import {TypeOperation} from "../../../models/operation/typeOperation.enum";
import OperationsDetailUrlsSpecifiques from "./operationsDetailUrlsSpecifiques";
import OperationsDetailCommentaires from "./operationsDetailCommentaires";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import {Box, Card, CardContent, CardHeader, Divider, Grid, Link, List, ListItem, Typography} from "@mui/material";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";
import './operationsDetailParametresGeneraux.css'
import {VoxAlert} from "../../../components/genericComponentsUI/alerte/voxAlert";
import {Composant} from "../../../models/versionApplicative/composant.model";
import {getTypeLivrableDisplayedText} from "../../../models/versionApplicative/typeLivrable.enum";

export default function OperationsDetailParametresGeneraux(props: { operation: Operation }) {

    // Navigation & Authentification
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    // Textes
    const operationTourEnCours: string = " - En cours";
    const texteOuverture: string = "Ouverture";
    const texteFermeture: string = "Fermeture";
    const tourEnCours: number | undefined = props.operation.informationsOperation?.tourEnCours;

    // Formulaire de modification QRadar
    const onUpdateSurveillanceQradar = useCallback(() => {
        navigate(`/operation/modification/surveillance-QRadar/${props.operation.id}`);
    }, [navigate, props.operation]);


    // Gestion de la hauteur maximale du bloc "Commentaires"
    const leftContainerRef = useRef<any>(null);
    const [commentsCardHeight, setCommentsCardHeight] = useState(400);

    useEffect(() => {

        // L'observer n'est pas initialisé si la ref n'existe pas
        if (!leftContainerRef) {
            return;
        }

        // Initialisation de l'observer
        const resizeObserver = new ResizeObserver(() => {
            if (leftContainerRef.current.offsetHeight !== commentsCardHeight) {
                setCommentsCardHeight(leftContainerRef.current.offsetHeight);
            }
        })

        // Début de l'observation de la ref (conteneur de gauche)
        resizeObserver.observe(leftContainerRef.current)

        // Clean-up Function (pour éviter les fuites mémoires)
        // → L'observer est supprimé lors de la destruction du composant
        return () => {
            // Fin de l'observation de la ref
            resizeObserver.disconnect();
        }

    }, [commentsCardHeight]);


    return (
        <Grid container justifyContent={"space-evenly"} gap={3}>

            {/* PARTIE GAUCHE */}
            <Grid item
                  xs={12}
                  sm={10}
                  md={6}
                  ref={leftContainerRef}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={3}
            >

                {/* Card "Détail de l'opération" */}
                <VoxCard
                    id={"infos-op"}
                    iconBeforeTitle={"info-circle"}
                    title={"Détail de l'opération"}
                >
                    <List sx={{padding: 0}}>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>N° Opération :</Typography>
                            <Typography>{props.operation.numeroOperation}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>Chef(fe) de projet :</Typography>
                            <Typography>{props.operation.utilisateur.prenom} {props.operation.utilisateur.nom}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>Portefeuille projet :</Typography>
                            <Typography>{props.operation.portefeuilleProjet.nom}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>Salesforce ID :</Typography>
                            <Typography>{props.operation.salesforceId}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>Client :</Typography>
                            <Typography>{props.operation.nomClient}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>Version applicative :</Typography>
                            <Typography>{props.operation.versionApplicative.version}</Typography>
                        </ListItem>
                        {!!props.operation.versionApplicative.composants?.length && (
                        <ListItem sx={{paddingTop: 0}}>
                            <details className={"details-composants"}>
                                <summary>
                                    <Typography component={"span"} fontWeight={500} fontSize={"inherit"}>Détail des livrables</Typography>
                                </summary>
                                <Box marginLeft={3} marginTop={0.2}>
                                    {props.operation.versionApplicative.composants.sort((a: Composant, b: Composant) => a.typeLivrable.localeCompare(b.typeLivrable))
                                        .map((composant: Composant) => {
                                        return (
                                            <p key={`comp-${composant.version}`}>
                                                <Typography component={"span"}>
                                                    Livrable {getTypeLivrableDisplayedText(composant.typeLivrable)} : {composant.version}
                                                </Typography>
                                            </p>
                                        )
                                    })}
                                </Box>
                            </details>
                        </ListItem>
                        )}
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>URL :</Typography>
                            <Link href={props.operation.url} target={"_blank"} rel={"noreferrer"}>
                                {props.operation.url}
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>Type :</Typography>
                            <Typography>{props.operation.type}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography fontWeight={500} pr={0.5}>État :</Typography>
                            <Typography>{affichageLibelleEtatDemande(props.operation.etatDemande)}</Typography>
                        </ListItem>
                    </List>
                </VoxCard>

                {/* Card "URLs spécifiques" (limitée à certains groupes utilisateurs) */}
                {
                    (authContext.utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                        authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA ||
                        authContext.utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                    ) &&
                    <OperationsDetailUrlsSpecifiques operation={props.operation}/>
                }

                {/* Card "Hébergement" */}
                <VoxCard
                    iconBeforeTitle={"server"}
                    title={"Hébergement"}
                >
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 5}}>
                            <Box sx={{display: 'flex', gap: 0.5}}>
                                <Typography fontWeight={500}>Hébergeur :</Typography>
                                <Typography>{props.operation.hebergeur}</Typography>
                            </Box>
                            {(props.operation.hebergeur === Hebergeur.OVH) &&
                                <Box sx={{display: 'flex', gap: 0.5}}>
                                    <Typography fontWeight={500}>Région :</Typography>
                                    <Typography>{(props.operation.virtualMachine) ? props.operation.virtualMachine.region : null}</Typography>
                                </Box>
                            }
                            <Box sx={{display: 'flex', gap: 0.5}}>
                                <Typography fontWeight={500}>Type de plateforme :</Typography>
                                <Typography>{props.operation.plateforme.nom}</Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: 0.5}}>
                                <Typography fontWeight={500}>Nom de VM :</Typography>
                                <Typography>{(props.operation.virtualMachine) ? props.operation.virtualMachine.vmName : null}</Typography>
                            </Box>
                        </Box>

                        {/* Surveillance QRadar (opérations VoxCore-Election Sur-Mesure chez Docaposte */}
                        {(props.operation.type === TypeOperation.VOXCORE_ELECTION &&
                                props.operation.hebergeur === Hebergeur.DOCAPOSTE &&
                                (props.operation.plateforme.nom === NomPlateforme.H4 || props.operation.plateforme.nom === NomPlateforme.H5) &&
                                !props.operation.archivee) &&
                            <VoxAlert
                                severity={"info"}
                                sx={{
                                    mt: 3,
                                    mb: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography>
                                    La Surveillance QRadar
                                    {
                                        (props.operation.dateDebutQRadar && props.operation.dateFinQRadar) ?
                                            ` est active du ${dayjs(props.operation.dateDebutQRadar).format('DD/MM/YYYY')}
                                        au ${dayjs(props.operation.dateFinQRadar).format('DD/MM/YYYY')}.`
                                            : ' est inactive.'
                                    }
                                </Typography>

                                {/* Bouton "Modifier" Surveillance QRadar */}
                                {
                                    (authContext.utilisateur?.groupe.nom === NomGroupe.ADMINISTRATEUR ||
                                        authContext.utilisateur?.groupe.nom === NomGroupe.SUPPORT_SI_INFRA) &&
                                    <VoxButton
                                        customColor={"grey"}
                                        variant={"outlined"}
                                        sx={{ml: 'auto'}}
                                        size={"small"}
                                        onClick={onUpdateSurveillanceQradar}
                                    >
                                        Modifier
                                    </VoxButton>
                                }
                            </VoxAlert>
                        }
                    </>
                </VoxCard>

                {/* Card "Description" */}
                <VoxCard
                    iconBeforeTitle={"comment-dots"}
                    title={"Description"}
                >
                    <Box sx={{whiteSpace: 'pre-wrap'}}>
                        {props.operation.description}
                    </Box>
                </VoxCard>

                {/* Card "Indicateurs" */}
                <VoxCard
                    iconBeforeTitle={"chart-pie"}
                    title={"Indicateurs"}
                >
                    <Box className={"flex-indicateurs"}>

                        {/* Nombre d'élections */}
                        <Box>
                            <Typography variant={"h1"} component={"p"} padding={1}>
                                {props.operation.indicateursOperation?.nbElections ?
                                    props.operation.indicateursOperation.nbElections
                                    : <FontAwesomeIcon icon={"question"}/>
                                }
                            </Typography>
                            Élections
                        </Box>

                        {/* Nombre d'électeurs */}
                        <Box>
                            <Typography variant={"h1"} component={"p"} padding={1}>
                                {props.operation.indicateursOperation?.nbElecteurs ?
                                    props.operation.indicateursOperation.nbElecteurs
                                    : <FontAwesomeIcon icon={"question"}/>
                                }
                            </Typography>
                            Électeurs
                        </Box>

                        {/* Nombre de gestionnaires */}
                        <Box>
                            <Typography variant={"h1"} component={"p"} padding={1}>
                                {props.operation.indicateursOperation?.nbGestionnaires ?
                                    props.operation.indicateursOperation.nbGestionnaires
                                    : <FontAwesomeIcon icon={"question"}/>
                                }
                            </Typography>
                            Gestionnaires
                        </Box>

                    </Box>

                    <Divider sx={{margin: 3, borderColor: 'rgba(0,0,0,0.3)'}}/>

                    <Box className={"flex-indicateurs"}>

                        {/* Dates du 1er tour */}
                        <Card className={tourEnCours === 1 ? "tourEnCours" : undefined}>
                            <CardHeader title={`1er tour ${tourEnCours === 1 ? operationTourEnCours : ''}`}/>
                            <CardContent sx={{padding: '0 2rem !important'}}>
                                <Box m={1}>
                                    <Typography fontWeight={500} sx={{mb: '5px !important'}}>
                                        {texteOuverture}
                                    </Typography>
                                    {props.operation.informationsOperation?.dateOuvertureTour1 ?
                                        affichageDateWithFormatDMYHm(props.operation.informationsOperation.dateOuvertureTour1) : '-'
                                    }
                                </Box>
                                <Box m={2}>
                                    <Typography fontWeight={500} sx={{mb: '5px !important'}}>
                                        {texteFermeture}
                                    </Typography>
                                    {props.operation.informationsOperation?.dateFermetureTour1 ?
                                        affichageDateWithFormatDMYHm(props.operation.informationsOperation.dateFermetureTour1) : '-'
                                    }
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Dates du 2nd tour */}
                        {props.operation.informationsOperation?.dateOuvertureTour2 &&
                            <Card className={tourEnCours === 2 ? "tourEnCours" : undefined}>
                                <CardHeader title={`2nd tour ${tourEnCours === 2 ? operationTourEnCours : ''}`}/>
                                <CardContent sx={{padding: '0 2rem !important'}}>
                                    <Box m={1}>
                                        <Typography fontWeight={500} sx={{mb: '5px !important'}}>
                                            {texteOuverture}
                                        </Typography>
                                        {props.operation.informationsOperation?.dateOuvertureTour2 ?
                                            affichageDateWithFormatDMYHm(props.operation.informationsOperation.dateOuvertureTour2) : '-'
                                        }
                                    </Box>
                                    <Box m={2}>
                                        <Typography fontWeight={500} sx={{mb: '5px !important'}}>
                                            {texteFermeture}
                                        </Typography>
                                        {props.operation.informationsOperation?.dateFermetureTour2 ?
                                            affichageDateWithFormatDMYHm(props.operation.informationsOperation.dateFermetureTour2) : '-'
                                        }
                                    </Box>
                                </CardContent>
                            </Card>
                        }

                    </Box>
                </VoxCard>

            </Grid>

            {/* PARTIE DROITE */}
            <Grid item xs={12} sm={10} md={4}>
                <OperationsDetailCommentaires
                    operationId={Number(props.operation.id)}
                    commentsCardHeight={commentsCardHeight}
                />
            </Grid>
        </Grid>
    )
}