import './dataDoughnut.css'
import 'chart.js/auto';
import {Doughnut} from "react-chartjs-2";
import {Link} from "react-router-dom";
import React from "react";

type dataDoughnutType = {
    value: number,
    total: number,
    name: string,
    link: string,
    valueAcquittement?: number    // Prop optionnelle s'affichant sous le donut
}

export default function DataDoughnut(props: dataDoughnutType) {

    // Remplissage du canvas + Config (couleurs, bordures...)
    const data = {
        datasets: [{
            data: [props.value, (props.total === 0 ? -1 : (props.total - props.value))], // On indique -1 si le total = 0 (pour tracer le donut dans tous les cas)
            backgroundColor: ['#dc3545', '#7f8c8d'], // Rouge (text-danger Boostrap) + Blanc pâle
            borderWidth: 0
        }]
    };

    // Options de rendu du donut
    const options = {
        responsive: true,
        aspectRatio: 1,
        cutout: 34, // 0 = épaisseur maximale // 100 = épaisseur minimale // Dépend de max-width et max-height du Canvas
        events: [] // Désactive la tooltip au hover
    };


    return(
        <Link to={props.link} className={'doughnut-link'}>
            <Doughnut data={data} options={options} />
            <span>{props.name}</span>
            <span className={`written-value ${props.value > 0 ? 'text-danger' : ''}`}>
                {props.value}
                <span>/{props.total}</span>
            </span>
            {/* Affichage optionnel de l'acquittement Site KO */}
            { (props.valueAcquittement) &&
                <span className={"couleur-texte-acquittement"}>Acquittement(s) : {props.valueAcquittement}</span>
            }
        </Link>
    )
}