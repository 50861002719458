import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SIRSOperation} from "../../../models/operationSIRS/sirsOperation.model";
import operationSIRSService from "../../../services/survox-back/operationSIRS/operationSIRS.service";
import OperationsSIRSDetailInformationsGenerales from "./operationsSIRSDetailInformationsGenerales";
import OperationsSIRSDetailIndicateurs from "./operationsSIRSDetailIndicateurs";
import OperationsSIRSDetailParametres from "./operationsSIRSDetailParametres";
import OperationsSIRSDetailSurveillance from "./operationsSIRSDetailSurveillance";
import {NomGroupe} from "../../../models/utilisateur/nomGroupe.enum";
import {EtatDemande} from "../../../models/operation/etatDemande.enum";
import {AuthContext} from "../../../contexts/authentication/authentication.context";
import OperationsSIRSDetailAudit from "./operationsSIRSDetailAudit";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tab, Tabs} from "@mui/material";
import TabPanel from "../../../components/layouts/tabs/TabPanel";
import TabContent from "../../../components/layouts/tabs/TabContent";


export default function OperationsSIRSDetail() {

    // Params, Navigation & Authentification
    let params = useParams();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const utilisateurAuth = authContext.utilisateur;

    // Utilisation d'une ref pour connaitre l'état du composant,
    // éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    // States
    const [isLoadingOperation, setIsLoadingOperation] = useState(false);
    const [isLoadError, setIsLoadError] = useState(false);
    const [messageError, setMessageError] = useState<string>("");
    const [operationSIRS, setOperationSIRS] = useState<SIRSOperation>();

    // Gestion des Onglets MUI
    const [activeTab, setActiveTab] = useState<number>(0);
    const handleOnChangeTabs = (event: React.SyntheticEvent, newTabIndex: number) => {
        setActiveTab(newTabIndex);
    }

    // Onglets MUI - RGAA
    const a11yProps = (index: number) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tab-panel-${index}`
        }
    }


    /**
     * Méthode qui permet de charger l'opération SIRS sélectionnée (dont on souhaite voir le détail)
     * @param id
     * @param controller
     */
    const chargerOperationSIRS = (id: number, controller?: AbortController) => {

        setIsLoadingOperation(true);
        setIsLoadError(false);

        operationSIRSService.getOperationSIRSAvecRelations(id, controller?.signal)
            .then(response => {
                if (isMountedRef.current && response.status === 200) {
                    // Récupération de l'opération
                    setOperationSIRS(response.data);
                    setIsLoadingOperation(false);
                    setIsLoadError(false);
                }
            })
            .catch(err => {
                if (err.message !== 'canceled' && isMountedRef.current) {
                    setIsLoadingOperation(false);
                    // Affichage du message d'erreur si l'ID n'est pas retrouvé en BDD
                    if (err.response.status === 404) {
                        setIsLoadError(true);
                        setMessageError(err.response.data.message);   // "L'opération SIRS avec l'id XX n'existe pas"
                    }
                }
            });
    };

    // Au chargement du composant
    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        if (params.id) {
            chargerOperationSIRS(Number(params.id), controller);
        }
        // Cleanup function
        return () => {
            isMountedRef.current = false;
            controller.abort();
        }
    }, [params.id])


    return (
        <>

            {/* Header spécifique étendu (titre à gauche) */}
            <VoxHeader
                title={operationSIRS?.nomClient}
                isLeftTitleMode={true}
                boutonLibre={
                    // Bouton de modification d'une opération, visible uniquement pour un utilisateur du groupe "ADMINISTRATEUR" ou "SUPPORT_SI_INFRA" ou "CHEF_DE_PROJET_SIRS"
                    // ET il faut que le champ etatDemande de l'opération soit différent de "SUPPRIME"
                    ((utilisateurAuth.groupe.nom === NomGroupe.ADMINISTRATEUR || utilisateurAuth.groupe.nom === NomGroupe.SUPPORT_SI_INFRA || utilisateurAuth.groupe.nom === NomGroupe.CHEF_DE_PROJET_SIRS)
                        && (operationSIRS && operationSIRS.etatDemande !== EtatDemande.SUPPRIME)) ?
                        <VoxButton
                            sx={{py: 2.8, px: 4}}
                            startIcon={<FontAwesomeIcon icon={"edit"}/>}
                            onClick={() => navigate(`/sirs/modification/${params.id}`)}
                        >
                            Modification
                        </VoxButton>
                        : undefined
                }
            />

            {/* Onglets */}
            <Tabs
                value={activeTab}
                onChange={handleOnChangeTabs}
                variant={"scrollable"}
                allowScrollButtonsMobile
                aria-label={"Onglets de navigation"}
                sx={{
                    borderColor: 'rgba(0,0,0,0.08)',
                    '& .MuiButtonBase-root:not(.MuiTabs-scrollButtons)': {
                        paddingTop: '18px',
                        borderRadius: '5px 5px 0 0',
                        transition: 'background-color 0.2s ease-in-out'
                    },
                    '& .MuiButtonBase-root:hover:not(.Mui-selected)': {
                        backgroundColor: 'rgba(0,0,0,0.025)'
                    },
                    '& .Mui-selected': {
                        backgroundColor: 'white',
                        boxShadow: '0 0 5px rgba(0,0,0,0.08)'
                    }
                }}
            >
                <Tab label={"Informations générales"} {...a11yProps(0)}/>
                <Tab label={"Paramètres"} {...a11yProps(1)}/>
                <Tab label={"Indicateurs"} {...a11yProps(2)}/>
                <Tab label={"Surveillance"} {...a11yProps(3)}/>
                <Tab label={"Audit"} {...a11yProps(4)}/>
            </Tabs>

            {/* Onglet "Informations générales" */}
            <TabPanel index={0} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operationSIRS &&
                        <OperationsSIRSDetailInformationsGenerales operationSIRS={operationSIRS}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Paramètres" */}
            <TabPanel index={1} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operationSIRS &&
                        <OperationsSIRSDetailParametres operationSIRS={operationSIRS}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Indicateurs" */}
            <TabPanel index={2} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operationSIRS &&
                        <OperationsSIRSDetailIndicateurs operationSIRS={operationSIRS}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Surveillance" */}
            <TabPanel index={3} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operationSIRS &&
                        <OperationsSIRSDetailSurveillance operationSIRS={operationSIRS}/>
                    }
                </TabContent>
            </TabPanel>

            {/* Onglet "Audit" */}
            <TabPanel index={4} value={activeTab}>
                <TabContent isLoading={isLoadingOperation}
                            isLoadError={isLoadError}
                            messageError={messageError}
                >
                    {operationSIRS &&
                        <OperationsSIRSDetailAudit operationSIRSId={operationSIRS.id}/>
                    }
                </TabContent>
            </TabPanel>

        </>
    )

}