import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";

/**
 * Fonction composant qui permet de définir les composants qui sont à affiche que si l'utilisateur est connecté
 */
export default function AuthenticatedRoute({ children }: { children: ReactNode}) {
    const location = useLocation();

    return (
        <AuthContext.Consumer>
            {value => {
                if (!value.authenticated) {
                    return <Navigate to={"/login"} state={{ from: location }} replace={true} />
                }
                if (value.utilisateur.mdpAModifier) {
                    return <Navigate to={"/modification_mdp"} state={{ from: location }} replace={true} />
                }
                return children
            }}
        </AuthContext.Consumer>
    )
}