import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import OperationsAllListe from "./operationsAllListe";

export default function OperationsAllView() {
    return (
        <>
            <VoxHeader title={"Toutes les opérations"} />
            <OperationsAllListe />
        </>
    );
}