import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import ViewRestricted from "../layouts/viewRestricted/viewRestricted";
import { ReactNode } from "react";

export default function RestrictedRoute({ children, groupes }: { children: ReactNode; groupes: NomGroupe[] }) {
    return (
        <AuthContext.Consumer>
            {value => {
                const utilisateurIsAuthorized = value.utilisateur && groupes?.includes(value.utilisateur.groupe?.nom)
                if (!utilisateurIsAuthorized) {
                    return <ViewRestricted/>
                }
                return children
            }}
        </AuthContext.Consumer>
    );
}