import VoxModal from "./voxModal";
import { Typography } from "@mui/material";

type ModalProps = {
    show: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
}


export default function ModaleConfirmationModificationComposants(props: Readonly<ModalProps>) {

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Confirmation"}
            content={
                <Typography variant={"body1"}>
                    Les composants archivés n'apparaîtront plus.
                </Typography>
            }
            widthButton={"100px"}
            closeButtonText={"Annuler"}
            submitButtonText={"Valider"}
        />
    )
}