import { VoxLabel } from "./voxLabel";
import { FormControl, Select, SelectChangeEvent } from "@mui/material";
import React, { ReactFragment } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type SelectInputProps = {
    id: string;
    idLabel: string;
    startIconLabel?: IconProp;
    label: string;
    value: any;
    onChange: (event: SelectChangeEvent<any>) => void;
    children: ReactFragment;
    fullWidth?: boolean;
    required?: boolean;
    disabled?: boolean;
}

export const VoxSelectInput = (props: SelectInputProps) => {

    return (
        <FormControl
            fullWidth={props.fullWidth}
            required={props.required}
        >
            <VoxLabel
                id={props.idLabel}
                sx={{px: 0}}
                startIconLabel={props.startIconLabel}
                label={props.label}
            />
            <Select
                labelId={props.idLabel}
                id={props.id}
                name={props.id}
                label={props.label}
                value={props.value ? props.value : ""}
                onChange={props.onChange}
                disabled={props.disabled}
                inputProps={{
                    sx: {
                        '&:hover': {
                            backgroundColor: 'white',
                            borderRadius: '10px'
                        }
                    }
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            border: '1px solid var(--cobalt-grey20)',
                            boxShadow: 'rgba(102, 109, 146, 0.08) 0px 4px 8px',
                            marginTop: 0.5
                        }
                    }
                }}
            >
                {props.children}
            </Select>
        </FormControl>
    )
}
