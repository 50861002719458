import { Box } from "@mui/material";
import VoxCard from "../genericComponentsUI/card/voxCard";
import { TypeProduit } from "../../models/versionApplicative/typeProduit.enum";
import { Composant } from "../../models/versionApplicative/composant.model";
import VoxButton from "../genericComponentsUI/button/voxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VoxTable from "../genericComponentsUI/table/voxTable";
import { ComposantTable } from "../../models/versionApplicative/composantTable.model";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { CellProps, Column, IdType, Row } from "react-table";
import { affichageDateWithFormatDMYHm } from "../../utils/string.utils";
import { sortDatesInTables } from "../../utils/date.utils";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import ModaleConfirmationModificationComposants from "../modals/modaleConfirmationModificationComposants";
import { UpdateComposantDto } from "../../services/survox-back/versionApplicative/dtos/request/updateComposant.dto";
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";
import { getTypeLivrableDisplayedText } from "../../models/versionApplicative/typeLivrable.enum";
import { CheckboxCell } from "../genericComponentsUI/table/cells/checkboxCell";
import ModaleConfirmationArchivageVersionsProduits from "../modals/modaleConfirmationArchivageVersionsProduits";
import composantService from "../../services/survox-back/versionApplicative/composant.service";

type ComposantsListeProps = {
    typeProduit: TypeProduit;
    composants: Composant[];
    loadComposants: (typeProduit: TypeProduit) => void; 
}

export const ComposantsListe = (
    {
        typeProduit,
        composants,
        loadComposants
    }: Readonly<ComposantsListeProps>
) => {

    const [pageIndex, setPageIndex] = useState<number>(0);

    const [updateComposants, setUpdateComposants] = useState<Composant[]>(composants.map(composant => ({...composant})));

    const [composantsArchives, setComposantsArchives] = useState<Composant[]>([]);

    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
    const [isErrorUpdate, setIsErrorUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showConfirmationModificationModal, setShowConfirmationModificationModal] = useState(false);
    const [showConfirmationArchivageModal, setShowConfirmationArchivageModal] = useState(false);

    useEffect(
        () => setUpdateComposants(composants.map(composant => ({...composant}))), 
        [composants]
    );

    /**
     * Méthode appelée dès qu'une case est sélectionnée ou désélectionnée dans la colonne "Composant à archiver" du tableau
     * afin de mettre à jour la valeur du champ "archive" du composant correspondant
     */
    const handleOnClickComposantAArchiver = useCallback((event: ChangeEvent<HTMLInputElement>, idComposant: number) => {
        setUpdateComposants(prevState => {
            return prevState.map(composant => {
                if (composant.id === idComposant) {
                    composant.archive = event.target.checked
                }
                return composant;
            });
        });
    }, []);

    /**
     * Méthode permettant d'effectuer l'enregistrement des modifications auprès de l'API survox-back
     */
    const handleUpdateComposants = useCallback(() => {
        const updateComposantsDto: UpdateComposantDto[] = updateComposants
            .filter(updateComposant => {
                const currentComposant = composants.find(composants => composants.id === updateComposant.id);
                return (
                    currentComposant
                    && currentComposant.archive !== updateComposant.archive
                );
            })
            .map(composant => ({ id: composant.id, archive: composant.archive }));

        setShowConfirmationModificationModal(false);

        setIsLoadingUpdate(true);
        setIsSuccessUpdate(false);
        setIsErrorUpdate(false);
        composantService.updateComposants(updateComposantsDto)
            .then((response) => {
                if (response.status === 200 && !response.data.error) {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(true);
                    setIsErrorUpdate(false);
                    // On recharge les composants après la mise à jour
                    loadComposants(typeProduit);
                } else {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(false);
                    setIsErrorUpdate(true);
                }
            })
            .catch((error) => {
                setIsLoadingUpdate(false);
                setIsSuccessUpdate(false);
                setIsErrorUpdate(true);
                if (error.response.status === 400 && error.response.data.message !== "Bad Request") {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Une erreur est survenue lors de la modification des composants");
                }
            });

    }, [composants, updateComposants, loadComposants, typeProduit]);

    /**
     * Méthode pour archivage automatique des composants
     */
    const archiverComposants = () => {
        setShowConfirmationArchivageModal(false);
        setIsLoadingUpdate(true);
        setIsSuccessUpdate(false);
        setIsErrorUpdate(false);

        composantService.archiverComposants(typeProduit)
            .then((response) => {
                if (response.status === 200 && !response.data.error) {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(true);
                    setIsErrorUpdate(false);
                    // On recharge les composants après la mise à jour
                    loadComposants(typeProduit);
                } else {
                    setIsLoadingUpdate(false);
                    setIsSuccessUpdate(false);
                    setIsErrorUpdate(true);
                }
            })
            .catch((error) => {
                setIsLoadingUpdate(false);
                setIsSuccessUpdate(false);
                setIsErrorUpdate(true);
                if (error.response.status === 400 && error.response.data.message !== "Bad Request") {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Une erreur est survenue lors de l'archivage des composants");
                }
            });
    }

    /**
     * Méthode pour gérer l'évènement du clique sur le bouton d'archivage
     */
    const handleOnClickArchiverComposants = () => {
        setIsLoadingUpdate(true);
        setIsSuccessUpdate(false);
        setIsErrorUpdate(false);
        setShowConfirmationArchivageModal(false);

        composantService.getComposantsArchivables(typeProduit)
            .then(response => {
                if (response.status === 200 && !response.data.error) {
                    setIsLoadingUpdate(false);
                    setComposantsArchives(response.data);
                    setShowConfirmationArchivageModal(true);
                } else {
                    setIsLoadingUpdate(false);
                    setIsErrorUpdate(true);
                }
            })
            .catch(() => {
                setIsLoadingUpdate(false);
                setIsErrorUpdate(true);
                setErrorMessage("Une erreur est survenue lors du chargement des composants archivables.");
            });
    }

    /**
     * Remplissage des lignes du tableau
     */
    const rows: ComposantTable[] = useMemo(() => {
        return updateComposants.map((composant: Composant) => {
            return {
                id: composant.id,
                version: composant.version,
                nomLivrable: composant.nomLivrable,
                typeLivrable: getTypeLivrableDisplayedText(composant.typeLivrable),
                dateReferencement: composant.dateReferencement,
                archive: composant.archive,
                versionsApplicatives: composant.versionsApplicatives ?? []
            }
        })
    }, [updateComposants]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<ComposantTable>[] = useMemo(() => [
        {
            Header: 'Version',
            accessor: 'version',
            minWidth: 130,
        },
        {
            Header: 'Nom du livrable',
            accessor: 'nomLivrable',
            minWidth: 200
        },
        {
            Header: 'Type de livrable',
            accessor: 'typeLivrable',
            minWidth: 180,
            Filter: SelectInputFilter
        },
        {
            Header: 'Date de référencement',
            id: 'dateReferencement',
            accessor: (originalRow: ComposantTable) => affichageDateWithFormatDMYHm(originalRow.dateReferencement),
            minWidth: 150,
            sortType: (
                a: Row<ComposantTable>,
                b: Row<ComposantTable>,
                _columnId: IdType<ComposantTable>,
                desc: boolean | undefined
            ) => sortDatesInTables(a.original.dateReferencement, b.original.dateReferencement, desc)
        },
        {
            Header: 'Versions Applicatives Liées',
            id: 'versionsApp',
            accessor: (originalRow => originalRow.versionsApplicatives.map(v => v.version)),
            minWidth: 100,
            Cell: ((props: CellProps<ComposantTable>) => props.value.map((s: string) => <p key={s}>{s}</p> ))
        },
        {
            Header: 'Composant à archiver',
            accessor: 'archive',
            minWidth: 130,
            disableFilters: true,
            disableSortBy: true,
            Cell: (cellProps: CellProps<ComposantTable>) => (
                <CheckboxCell
                    id={ `version-${cellProps.row.original.version}` }
                    name="checkboxArchivageComposant"
                    cellProps={ cellProps }
                    onChange={(event) => {
                        setPageIndex(cellProps.state.pageIndex);
                        handleOnClickComposantAArchiver(event, cellProps.row.original.id);
                    }}
                    disabled={cellProps.row.original.versionsApplicatives.some(version => version.versionParDefaut && !version.archive)}
                />
            )
        }
    ], [handleOnClickComposantAArchiver]);

    return (
        <VoxCard
            title={`Composants ${typeProduit}`}
        >
            <Box margin={2.5}>
                {
                    (isErrorUpdate) && (
                        <VoxAlert severity={"error"} sx={{ marginBottom: 3 }}>
                            { errorMessage }
                        </VoxAlert>
                    )
                }
                {
                    (isSuccessUpdate) && (
                        <VoxAlert severity={"success"} onClose={() => setIsSuccessUpdate(false)} sx={{marginBottom: 3}}>
                            Les composants de type {typeProduit} ont été mis à jour
                        </VoxAlert>
                    )
                }
                    
                <VoxTable
                    data={ rows }
                    columns={ columns }
                    pageIndexProps={ pageIndex }
                    initialStateSortBy={ [{id: 'dateReferencement', desc: true}] }
                    autoResetSortBy={ false }
                />
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <VoxButton
                        customColor={"blue"}
                        sx={{ mt: 3 }}
                        startIcon={ <FontAwesomeIcon icon={"archive"}/> }
                        onClick={ handleOnClickArchiverComposants }
                        disabled={ isLoadingUpdate }
                    >
                        Archivage Rapide
                    </VoxButton>

                    <VoxButton
                        customColor={"green"}
                        sx={{mt: 3}}
                        startIcon={<FontAwesomeIcon icon={"save"}/>}
                        onClick={() => setShowConfirmationModificationModal(true)}
                        disabled={ isLoadingUpdate }
                    >
                        Valider les composants
                    </VoxButton>
                </Box>

                <ModaleConfirmationModificationComposants
                    show={ showConfirmationModificationModal }
                    handleClose={() => setShowConfirmationModificationModal(false) }
                    handleSubmit={ handleUpdateComposants }
                />

                <ModaleConfirmationArchivageVersionsProduits
                    type="composant"
                    show={ showConfirmationArchivageModal }
                    handleClose={ () => setShowConfirmationArchivageModal(false) }
                    handleSubmit={ archiverComposants }
                    archivees={ composantsArchives }
                />

                { (isLoadingUpdate) && <VoxLoader isBackgroundColor={true} /> }         
            </Box>
        </VoxCard>
    );

}