// Filtre personnalisé pour sélectionner une unique option dans une liste déroulante
import {FilterProps, Row} from "react-table";
import React, {useMemo} from "react";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export function SelectInputFilter<Type extends object>({ column: { filterValue, setFilter, preFilteredRows, id }} : FilterProps<Type>) {
    // Calcul des options pour le filtre à l'aide de preFilteredRows
    const options: string[] = useMemo(() => {
        const columnValues = preFilteredRows.map((row: Row<Type>) => row.values[id]).sort();
        return columnValues.filter((value : string, id: number, array: string[]) => array.indexOf(value) === id);
    }, [id, preFilteredRows]);

    return (
        <Select
            name={"select-input-filter"}
            value={!filterValue ? '' : filterValue}
            onChange={(e: SelectChangeEvent) => setFilter(e.target.value || undefined)}
            size={'small'}
            sx={{ width: '100%' }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        border: '1px solid var(--cobalt-grey20)',
                        boxShadow: 'rgba(102, 109, 146, 0.08) 0px 4px 8px',
                        marginTop: 0.5
                    }
                }
            }}
        >
            <MenuItem aria-label={"None"} value={''} sx={{ height: 36 }}/>
            {
                options.map(option => (
                    (option !== undefined) && (
                        <MenuItem key={option} value={option} sx={{ height: 36 }}>{option}</MenuItem>
                    )
                ))
            }
        </Select>
    )
}