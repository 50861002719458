import { Box, InputLabel, SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type LabelProps = {
    id: string
    startIconLabel?: IconProp
    label: string
    required?: boolean
    sx?: SxProps
}

export const VoxLabel = (props: LabelProps) => {

    return (
        <InputLabel id={props.id} required={props.required} sx={{...props.sx}}>
            {props.startIconLabel &&
                <Box component={"span"} sx={{pr: 1}}>
                    <FontAwesomeIcon icon={props.startIconLabel}/>
                </Box>

            }
            <Typography component={"span"} variant="subtitle1">{props.label}</Typography>
        </InputLabel>
    )
}