import React, { useState } from 'react';
import { Calendar, CobaltIcon } from '@docaposte-agility/da-design-system/';
import { Box, Card, Grid, IconButton, Popover, SxProps, TextField } from '@mui/material';
import Typography from "@mui/material/Typography";
import { REGEX_DATE_EN, REGEX_DATE_EN_HOURS_MINUTES } from "../../../utils/date.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { affichageDateWithFormatYMD, affichageDateWithFormatYMDHm } from "../../../utils/string.utils";
import './voxCalendarInput.css';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


/**
 * Hack CSS permettant de masquer l'icône native de calendrier sur Firefox
 */
const inputCssStyle = {
    '&:hover .MuiInputBase-root, &:active .MuiInputBase-root': {
        background: 'white !important'
    },
    '& .MuiInputBase-root::after': {
        content: '""',
        position: 'absolute',
        right: 64,
        width: 20,
        height: '85%',
        background: 'inherit',
        cursor: 'default'
    }
}

type CalendarInputProps = {
    id: string
    startIconLabel?: IconProp
    label: string
    value?: Date
    onChange: (id: string, date: Date | undefined) => void
    minDate?: Date
    maxDate?: Date
    fullWidth?: boolean
    required?: boolean
    disabled?: boolean
    dateTimeLocalType?: boolean   // Type d'input permettant de gérer les heures/minutes
    sx?: SxProps
}


export const VoxCalendarInput = (props: CalendarInputProps) => {

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [date, setDate] = useState<Date | null>(props.value ? props.value : null);

    const initialisationInput = (date: Date | undefined) => {
        if (date) {
            if (props.dateTimeLocalType) {
                return affichageDateWithFormatYMDHm(date);
            } else {
                return affichageDateWithFormatYMD(date);
            }
        } else {
            return '';
        }
    }

    const [input, setInput] = useState<string>(initialisationInput(props.value));


    const handleOnChange = (value: string) => {

        // Contenu de l'input
        setInput(value);

        // Si Date au format YYYY-MM-DD
        if (REGEX_DATE_EN.test(value)) {
            const newDate = dayjs(value).toDate();
            setDate(newDate);
            props.onChange(props.id, newDate);
        }

        // Si Date au format YYYY-MM-DDTHH:mm
        else if (REGEX_DATE_EN_HOURS_MINUTES.test(value)) {
            const newDate = dayjs(value, "YYYY-MM-DDTHH:mm").toDate();
            setDate(newDate);
            props.onChange(props.id, newDate);
        }

        // Si input vide
        else if (value === "") {
            // réinitialise la date
            setDate(null);
            props.onChange(props.id, undefined);
        }
    };

    const handleOnClick = (event: any) => {
        setAnchorEl(event.target.parentElement.parentElement);
        setOpen(true);
    };

    const setDateCalendar = (value: Date) => {
        setDate(value);
        setInput(props.dateTimeLocalType ? affichageDateWithFormatYMDHm(value) : affichageDateWithFormatYMD(value));
        props.onChange(props.id, value);
        setTimeout(() => setOpen(false), 100);
    }


    return (
        <Grid container direction="column">
            <Grid item>
                <TextField
                    id={props.id}
                    label={
                        <>
                            {props.startIconLabel &&
                                <Box component={"span"} sx={{ pr: 1 }}>
                                    <FontAwesomeIcon icon={props.startIconLabel} />
                                </Box>
                            }
                            <Typography component={"span"} variant="subtitle1">{props.label}</Typography>
                        </>
                    }
                    value={input}
                    onChange={event => handleOnChange(event.target.value)}
                    fullWidth={props.fullWidth}
                    inputProps={{
                        min: props.minDate && (props.dateTimeLocalType ? affichageDateWithFormatYMDHm(props.minDate) : affichageDateWithFormatYMD(props.minDate)),
                        max: props.maxDate
                            ? (props.dateTimeLocalType ? affichageDateWithFormatYMDHm(props.maxDate) : affichageDateWithFormatYMD(props.maxDate))
                            : (props.dateTimeLocalType ? '9999-12-31T23:59' : '9999-12-31')       // max='9999-12-31' afin de n'autoriser que des années avec 4 chiffres
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={handleOnClick}
                                disabled={props.disabled}
                            >
                                <CobaltIcon
                                    name="calendar"
                                    sx={{
                                        cursor: 'pointer',
                                        color: open ? 'cobalt.ultramarine' : 'inherit',
                                    }}
                                />
                            </IconButton>
                        ),
                    }}
                    required={props.required}
                    disabled={props.disabled}
                    type={props.dateTimeLocalType ? "datetime-local" : "date"}
                    sx={{...inputCssStyle, ...props.sx}}
                    className={'input-calendar'}
                />
            </Grid>
            <Grid item>
                <Popover
                    open={open}
                    onClose={() => setOpen(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    elevation={1}
                    sx={{ mt: 1 }}
                >
                    <Card sx={{ width: '350px' }}>
                        <Calendar
                            lang="fr"
                            date={date ?? new Date()}
                            setDate={setDateCalendar}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                        />
                    </Card>
                </Popover>
            </Grid>
        </Grid>
    );
}