import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Operation } from "../../models/operation/operation.model";
import operationService from "../../services/survox-back/operation/operation.service";
import VoxLoader from "../genericComponentsUI/loaders/voxLoader";
import { CellProps, Column, Row } from "react-table";
import ModaleConfirmationSuppressionOuAnnulation from "../modals/modaleConfirmationSuppressionOuAnnulation";
import { TypeOperation } from "../../models/operation/typeOperation.enum";
import { AxiosResponse } from "axios";
import { SuppressionOperationPossible } from "../../models/operation/suppressionOperationPossible.model";
import { affichageLibelleEtatDemande } from "../../utils/string.utils";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import { Hebergeur } from "../../models/operation/hebergeur.enum";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Utilisateur } from "../../models/utilisateur/utilisateur.model";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import ModaleDemandeSuppressionOuArchivage from "../modals/modaleDemandeSuppressionOuArchivage";
import Grid from "@mui/material/Grid";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import './operationsASupprimerListe.css';
import { SelectInputFilter } from "../genericComponentsUI/table/filters/SelectInputFilter";
import VoxTable from "../genericComponentsUI/table/voxTable";
import VoxLinkOperation from "../genericComponentsUI/link/voxLinkOperation";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";
import { Box } from "@mui/material";
import VoxIconAction from "../genericComponentsUI/pictogrammes/voxIconAction";

type OperationASupprimer =
    Operation
    & { id: number, actions: string, nbJoursCloture: number, verification: string, utilisateur: string, loadingSuppression: boolean };


export default function OperationsASupprimerListe(props: { typeOperation: TypeOperation | string }) {

    // Contexte d'authentification
    const authContext = useContext(AuthContext);
    const utilisateur: Utilisateur = authContext.utilisateur;

    // Utilisation d'une ref pour connaitre l'état du composant éviter les fuites mémoires dans les fonctions asynchrones
    const isMountedRef = useRef<boolean | null>(null);

    const [operations, setOperations] = useState<(OperationASupprimer)[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showSuppressionOuAnnulationModale, setShowSuppressionOuAnnulationModale] = useState(false);
    const [selectedOperation, setSelectedOperation] = useState<OperationASupprimer>();
    const [isAllowedDeleteOperation, setIsAllowedDeleteOperation] = useState(true);
    const [isLoadingAllowedDeleteOperation, setIsLoadingAllowedDeleteOperation] = useState(false);
    const [isLoadingDeleteOperation, setIsLoadingDeleteOperation] = useState(false);
    const [succesDeleteOperation, setSuccesDeleteOperation] = useState(false);
    const [isErrorDeleteOperation, setIsErrorDeleteOperation] = useState(false);

    // Gestion du processus de la demande de suppression ou d'archivage d'une opération par un Responsable de Projets Vote
    const [showDemandeSuppressionOuArchivageModale, setShowDemandeSuppressionOuArchivageModale] = useState<boolean>(false);
    const [operationDemandeSuppressionOuArchivage, setOperationDemandeSuppressionOuArchivage] = useState<OperationASupprimer>();
    const [isLoadingDemandeSuppressionOuArchivage, setIsLoadingDemandeSuppressionOuArchivage] = useState<boolean>(false);
    const [isErrorDemandeSuppressionOuArchivage, setIsErrorDemandeSuppressionOuArchivage] = useState<boolean>(false);
    const [errorMessageDemandeSuppressionOuArchivage, setErrorMessageDemandeSuppressionOuArchivage] = useState<string>("");
    const [isSuccessDemandeSuppressionOuArchivage, setIsSuccessDemandeSuppressionOuArchivage] = useState<boolean>(false);
    const [successMessageDemandeSuppressionOuArchivage, setSuccessMessageDemandeSuppressionOuArchivage] = useState<string>("");

    /**
     * Charger les opérations à l'état A_SUPPRIMER
     */
    const chargerOperations = useCallback((controller?: AbortController) => {

        /**
         * charger toutes les opérations à l'état A_SUPPRIMER
         */
        if (props.typeOperation === "all") {
            setIsLoading(true);
            operationService.getAllOperationsASupprimer(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        setOperations(response.data);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });
        }

        /**
         * Charger les opérations de type Saturne à l'état A_SUPPRIMER
         */
        if (props.typeOperation === TypeOperation.SATURNE) {
            setIsLoading(true);
            operationService.getOperationsSaturneASupprimer(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        setOperations(response.data);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });

        }

        /**
         * Charger les opérations de type Maileva à l'état A_SUPPRIMER
         */
        if (props.typeOperation === TypeOperation.MAILEVA) {
            setIsLoading(true);
            operationService.getOperationsMailevaASupprimer(controller?.signal)
                .then(response => {
                    if (isMountedRef.current && response.status === 200) {
                        setOperations(response.data);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.message !== 'canceled' && isMountedRef.current) {
                        setIsLoading(false);
                    }
                });

        }
    }, [props.typeOperation]);

    useEffect(() => {
        const controller = new AbortController();
        isMountedRef.current = true;
        chargerOperations(controller);
        return () => {
            isMountedRef.current = false;
            controller.abort();
        };
    }, [chargerOperations]);

    /**
     * Remplissage des lignes du tableau
     */
    const rows: any[] = useMemo(() => {
        return operations;
    }, [operations]);


    /**
     * Se déclenche lorsque l'utilisateur (ADMIN ou SUPPORT_SI_INFRA) clique sur le pictogramme de suppression ou d'annulation d'une opération
     * et ouvre la modale de confirmation
     */
    const handleOperationSuppressionOuAnnulation = useCallback((operation: OperationASupprimer) => {
        setShowSuppressionOuAnnulationModale(true);
        setSelectedOperation(operation);
        if (!isLoadingAllowedDeleteOperation) {
            setIsLoadingAllowedDeleteOperation(true);
            let requete: Promise<AxiosResponse<SuppressionOperationPossible>> | undefined;
            if (props.typeOperation === TypeOperation.SATURNE) {
                requete = operationService.allowedDeleteOperationSaturne(operation.id);
            }
            if (props.typeOperation === TypeOperation.MAILEVA) {
                requete = operationService.allowedDeleteOperationMaileva(operation.id);
            }
            if (props.typeOperation === "all") {
                requete = operationService.allowedDeleteOperation(operation.id);
            }

            if (requete) {
                requete.then((response) => {
                    if (isMountedRef.current && response.data.suppressionOk !== undefined) {
                        setIsAllowedDeleteOperation(response.data.suppressionOk);
                    }
                })
                    .catch(() => {
                        if (isMountedRef.current)
                            setIsAllowedDeleteOperation(false);
                    })
                    .finally(() => {
                        if (isMountedRef.current)
                            setIsLoadingAllowedDeleteOperation(false)
                    });
            }
        }
    }, [isLoadingAllowedDeleteOperation, props.typeOperation]);

    /**
     * Se déclenche à la confirmation de la suppression ou de l'annulation d'une opération et supprimer l'opération
     */
    const operationSuppressionOuAnnulation = useCallback(() => {
        if (selectedOperation && selectedOperation.id) {
            setIsLoadingDeleteOperation(true);
            setSuccesDeleteOperation(false);
            setIsErrorDeleteOperation(false);

            let requete: Promise<AxiosResponse> | undefined;

            if (props.typeOperation === TypeOperation.SATURNE) {
                requete = operationService.deleteOperationSaturne(selectedOperation.id);
            }
            if (props.typeOperation === TypeOperation.MAILEVA) {
                requete = operationService.deleteOperationMaileva(selectedOperation.id);
            }
            if (props.typeOperation === "all") {
                requete = operationService.deleteOperation(selectedOperation.id);
            }

            if (requete) {
                requete.then((response) => {
                    if (isMountedRef.current) {
                        if (response.status === 200 && !response.data.error) {
                            setSuccesDeleteOperation(true);
                        } else {
                            setIsErrorDeleteOperation(true);
                        }
                    }
                })
                    .catch(() => {
                        if (isMountedRef.current) {
                            setSuccesDeleteOperation(false);
                            setIsErrorDeleteOperation(true);
                        }
                    })
                    .finally(() => {
                        if (isMountedRef.current) {
                            setIsLoadingDeleteOperation(false);
                            setShowSuppressionOuAnnulationModale(false);
                            setSelectedOperation(undefined);
                            chargerOperations();
                        }
                    })
            }
        }
    }, [selectedOperation, chargerOperations, props.typeOperation]);


    /**
     * Se déclenche lorsqu'un utilisateur (RESPONSABLE_PROJETS_VOTE) clique sur le pictogramme de suppression ou d'archivage d'une opération
     * et affiche la modale de choix de processus de suppression d'une opération à l'état en production
     */
    const handleDemandeSuppressionOuArchivage = useCallback((operationId: number) => {
        const operation = operations.find(op => op.id === operationId);
        setShowDemandeSuppressionOuArchivageModale(true);
        setOperationDemandeSuppressionOuArchivage(operation);
    }, [operations]);


    /**
     * Callback qui s'exécute lorsqu'un utilisateur (RESPONSABLE_PROJETS_VOTE)
     * clique sur le bouton demande d'archivage de l'opération dans la modale de choix de
     * processus de suppression d'une opération à l'état en production
     */
    const handleDemandeArchivageOperationProduction = useCallback(() => {
        if (operationDemandeSuppressionOuArchivage) {
            setIsLoadingDemandeSuppressionOuArchivage(true);
            setIsErrorDemandeSuppressionOuArchivage(false);
            setErrorMessageDemandeSuppressionOuArchivage("");
            setIsSuccessDemandeSuppressionOuArchivage(false);
            setSuccessMessageDemandeSuppressionOuArchivage("");
            operationService.demanderArchivageOperationProduction(operationDemandeSuppressionOuArchivage.id)
                .then((res) => {
                    if (isMountedRef.current && res.status === 200) {
                        setIsSuccessDemandeSuppressionOuArchivage(true);
                        setSuccessMessageDemandeSuppressionOuArchivage("La demande d'archivage à bien été prise en compte");
                    }
                })
                .catch((err) => {
                    if (isMountedRef.current) {
                        setIsErrorDemandeSuppressionOuArchivage(true);
                        if (err.response?.status === 400 && err.response?.data?.source === "[Demande Archivage]") {
                            setErrorMessageDemandeSuppressionOuArchivage(err.response?.data?.message);
                        } else {
                            setErrorMessageDemandeSuppressionOuArchivage("Une erreur est survenue lors de la demande d'archivage");
                        }
                    }
                })
                .finally(() => {
                    if (isMountedRef.current) {
                        setIsLoadingDemandeSuppressionOuArchivage(false);
                        setShowDemandeSuppressionOuArchivageModale(false);
                        setOperationDemandeSuppressionOuArchivage(undefined);
                        chargerOperations();
                    }
                })
        }
    }, [operationDemandeSuppressionOuArchivage, chargerOperations]);

    /**
     * Callback qui s'exécute lorsqu'un utilisateur (RESPONSABLE_PROJETS_VOTE)
     * clique sur le bouton demande de suppression de l'opération dans la modale de choix de
     * processus de suppression d'une opération à l'état en production
     */
    const handleDemandeSuppressionOperationProduction = useCallback(() => {
        if (operationDemandeSuppressionOuArchivage) {
            setIsLoadingDemandeSuppressionOuArchivage(true);
            setIsErrorDemandeSuppressionOuArchivage(false);
            setErrorMessageDemandeSuppressionOuArchivage("");
            setIsSuccessDemandeSuppressionOuArchivage(false);
            setSuccessMessageDemandeSuppressionOuArchivage("");
            operationService.demanderSuppressionOperationProduction(operationDemandeSuppressionOuArchivage.id)
                .then((res) => {
                    if (isMountedRef.current && res.status === 200) {
                        setIsSuccessDemandeSuppressionOuArchivage(true);
                        setSuccessMessageDemandeSuppressionOuArchivage("La demande de suppression à bien été prise en compte");
                    }
                })
                .catch((err) => {
                    if (isMountedRef.current) {
                        setIsErrorDemandeSuppressionOuArchivage(true);
                        if (err.response?.status === 400 && err.response?.data?.source === "[Demande Suppression]") {
                            setErrorMessageDemandeSuppressionOuArchivage(err.response?.data?.message);
                        } else {
                            setErrorMessageDemandeSuppressionOuArchivage("Une erreur est survenue lors de la demande de suppression");
                        }
                    }
                })
                .finally(() => {
                    if (isMountedRef.current) {
                        setIsLoadingDemandeSuppressionOuArchivage(false);
                        setShowDemandeSuppressionOuArchivageModale(false);
                        setOperationDemandeSuppressionOuArchivage(undefined);
                        chargerOperations();
                    }
                })
        }
    }, [operationDemandeSuppressionOuArchivage, chargerOperations]);


    /**
     * Permet de déterminer si la colonne d'actions du tableau doit être affichée
     * en fonction du groupe de l'utilisateur connecté et du types des opérations
     * -> Pour les opérations de vote si Administrateur / Support SI Infra / Responsable Projets Vote
     * -> Pour les opérations packagées si Chef De Projet Packagé / Support SI Dev
     */
    const isActionsColumnDisplayed = useMemo(() => (
        (
            utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
            || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
            || utilisateur.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
        ) || (
            (
                utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE
                || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
            ) && (
                props.typeOperation === TypeOperation.SATURNE
                || props.typeOperation === TypeOperation.MAILEVA
            )
        )
    ), [props.typeOperation, utilisateur.groupe.nom]);


    /**
     * Configuration des colonnes du tableau
     */
    const columns: Column<OperationASupprimer>[] = useMemo(() => {
        let columnsDefinition: Column<OperationASupprimer>[] = [
            {
                Header: 'Opération',
                accessor: function (originalRow) {
                    return {
                        nomClient: originalRow.nomClient,
                        url: originalRow.url
                    }
                },
                minWidth: 350,
                Cell: (props: CellProps<OperationASupprimer>) => {
                    return (
                        <>
                            <VoxLinkOperation href={`/operation/detail/${props.row.original.id}`}>
                                {props.value.nomClient}
                            </VoxLinkOperation>
                            <br />
                            <VoxLinkUrl href={props.value.url} isGreyColor={true}>
                                {props.value.url}
                            </VoxLinkUrl>
                        </>
                    )
                }
            },
            {
                Header: "Chef(fe) de projet",
                accessor: 'utilisateur',
                minWidth: 183,

            },
            {
                Header: "Type d'opération",
                accessor: 'type',
                minWidth: 165,

            },
            {
                Header: "Hébergeur",
                accessor: 'hebergeur',
                minWidth: 135,
                Filter: SelectInputFilter,
                filter: "includes",

            },
            {
                Header: "Nombre de jours depuis clôture tour en cours",
                id: "nbJoursCloture",
                accessor: (originalRow: OperationASupprimer) => originalRow.nbJoursCloture ? (
                    originalRow.nbJoursCloture >= 0 ? originalRow.nbJoursCloture : "Tour en cours non clôturé"
                ) : "Information non disponible",
                minWidth: 200,
            },
            {
                Header: "À vérifier",
                id: "verification",
                accessor: (originalRow: OperationASupprimer) => originalRow.verification ? "Demander l'accord au CP" : "Non",
                minWidth: 120,
            },
            {
                Header: "Etat de suppression",
                id: "etatDemande",
                accessor: (originalRow: OperationASupprimer) => affichageLibelleEtatDemande(originalRow.etatDemande),
                minWidth: 120,
            },
        ]
        if (isActionsColumnDisplayed) {
            columnsDefinition = [...columnsDefinition, {
                Header: 'Actions',
                accessor: 'actions',
                disableFilters: true,
                disableSortBy: true,
                Cell: (props: CellProps<OperationASupprimer>) => {
                    let cellContent;
                    if (props.row.original.loadingSuppression) {
                        cellContent = (
                            <Box sx={{position: "relative"}}>
                                <VoxLoader size={20} />
                            </Box>
                        );
                    } else if (
                        /*
                         * Action possible pour les groupes Administrateur et Support SI Infra
                         * et pour les groupes Support SI Dev et Chef de Projet Packagé pour les opérations
                         * de type Saturne et Maileva
                         */
                        (
                            (
                                utilisateur.groupe.nom === NomGroupe.ADMINISTRATEUR
                                || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_INFRA
                            ) && (
                                props.row.original.etatDemande === EtatDemande.EN_PRODUCTION
                                || props.row.original.etatDemande === EtatDemande.A_ARCHIVER
                                || (
                                    props.row.original.etatDemande === EtatDemande.A_SUPPRIMER
                                    && props.row.original.hebergeur === Hebergeur.DOCAPOSTE
                                )
                            )
                        ) || (
                            (
                                utilisateur.groupe.nom === NomGroupe.CHEF_DE_PROJET_PACKAGE
                                || utilisateur.groupe.nom === NomGroupe.SUPPORT_SI_DEV
                            ) && (
                                props.row.original.type === TypeOperation.SATURNE
                                || props.row.original.type === TypeOperation.MAILEVA
                            ) && props.row.original.etatDemande === EtatDemande.EN_PRODUCTION
                        )
                    ) {
                        cellContent = (
                            <VoxIconAction
                                tooltip={"Supprimer l'opération"}
                                icon={"trash-alt"}
                                onClick={() => handleOperationSuppressionOuAnnulation(props.row.original)}
                            />
                        )
                    } else if (
                        // Action possible pour le groupe : RESPONSABLE_PROJETS_VOTE
                        props.row.original.id
                        && utilisateur.groupe.nom === NomGroupe.RESPONSABLE_PROJETS_VOTE
                        && props.row.original.etatDemande === EtatDemande.EN_PRODUCTION
                        && (
                            props.row.original.type === TypeOperation.VOXCORE_ELECTION
                            || props.row.original.type === TypeOperation.VOXCORE_AG
                            || props.row.original.type === TypeOperation.ACTIONNARIAT
                        )
                    ) {
                        cellContent = (
                            <VoxIconAction
                                tooltip={"Demander la suppression ou l'archivage"}
                                icon={"trash-alt"}
                                onClick={() => handleDemandeSuppressionOuArchivage(props.row.original.id)}
                            />
                        )
                    }
                    return (
                        <>{cellContent}</>
                    );
                },
                minWidth: 100,
                maxWidth: 100,
            } as Column<OperationASupprimer>];
        }
        return columnsDefinition;
    }, [
        handleOperationSuppressionOuAnnulation,
        handleDemandeSuppressionOuArchivage,
        utilisateur.groupe.nom,
        isActionsColumnDisplayed
    ]);

    /**
     * Le message d'alerte à afficher pour confirmer la suppression ou informer l'utilisateur qu'il y a eu une erreur
     */
    const messageAlerte = useMemo(() => {
        if (succesDeleteOperation) {
            return (
                <VoxAlert severity={"success"} onClose={() => setSuccesDeleteOperation(false)}>
                    L'opération a été supprimée.
                </VoxAlert>
            )
        } else if (isErrorDeleteOperation) {
            return (
                <VoxAlert severity={"error"} onClose={() => setIsErrorDeleteOperation(false)}>
                    Il y a eu une erreur lors de la suppression de l'opération.
                </VoxAlert>
            )
        }
    }, [succesDeleteOperation, isErrorDeleteOperation]);

    /**
     * Message d'information / d'erreur concernant la demande de suppression ou d'archivage d'une opération en
     * production par un utilisateur (RESPONSABLE_PROJETS_VOTE)
     */
    const messageDemandeSuppressionOuArchivage = useMemo(() => {
        if (isSuccessDemandeSuppressionOuArchivage) {
            return (
                <VoxAlert severity={"success"} onClose={() => {
                    setIsSuccessDemandeSuppressionOuArchivage(false);
                    setSuccessMessageDemandeSuppressionOuArchivage("");
                }}>
                    {successMessageDemandeSuppressionOuArchivage}
                </VoxAlert>
            )
        } else if (isErrorDemandeSuppressionOuArchivage) {
            return (
                <VoxAlert severity={"error"} onClose={() => {
                    setIsErrorDemandeSuppressionOuArchivage(false);
                    setErrorMessageDemandeSuppressionOuArchivage("");
                }}>
                    {errorMessageDemandeSuppressionOuArchivage}
                </VoxAlert>
            )
        }
    }, [
        isSuccessDemandeSuppressionOuArchivage,
        successMessageDemandeSuppressionOuArchivage,
        isErrorDemandeSuppressionOuArchivage,
        errorMessageDemandeSuppressionOuArchivage
    ]);


    return (
        <Grid container>
            <Grid item xs={12} >
                {messageAlerte}
                {messageDemandeSuppressionOuArchivage}
            </Grid>
            {isLoading ?
                <VoxLoader positionRelative/>
                :
                <Grid item xs={12} >
                    {operations.length !== 0 &&

                        <VoxAlert severity={"info"}>
                            Dans la colonne « À vérifier », le message « Demander l'accord au CP » est affiché
                            s'il reste des sièges à pourvoir sur l'opération ou que l'information n'est pas connue.
                        </VoxAlert>
                    }
                    <Grid item xs={12}>
                        <VoxTable
                            data={rows}
                            columns={
                                (props.typeOperation === TypeOperation.SATURNE || props.typeOperation === TypeOperation.MAILEVA)
                                    ? columns.filter(column => (column.accessor !== 'type'))
                                    : columns
                            }
                            rowProps={(row: Row<OperationASupprimer>) => {
                                return {
                                    style: {
                                        ...(row.values.verification === "Demander l'accord au CP" && { backgroundColor: "#FCF8E3" })
                                    }
                                }
                            }}
                        />
                        <ModaleConfirmationSuppressionOuAnnulation
                            show={showSuppressionOuAnnulationModale}
                            operation={selectedOperation}
                            isAllowedDeleteOperation={isAllowedDeleteOperation}
                            handleClose={() => {
                                setShowSuppressionOuAnnulationModale(false);
                                setSelectedOperation(undefined);
                            }}
                            handleSubmit={operationSuppressionOuAnnulation}
                            loadingDeleteOperation={isLoadingDeleteOperation}
                            loadingAllowedDeleteOperation={isLoadingAllowedDeleteOperation}
                        />

                        <ModaleDemandeSuppressionOuArchivage
                            show={showDemandeSuppressionOuArchivageModale}
                            isLoading={isLoadingDemandeSuppressionOuArchivage}
                            handleClose={() => {
                                setShowDemandeSuppressionOuArchivageModale(false);
                                setOperationDemandeSuppressionOuArchivage(undefined);
                            }}
                            operation={operationDemandeSuppressionOuArchivage}
                            handleDemandeSuppression={handleDemandeSuppressionOperationProduction}
                            handleDemandeArchivage={handleDemandeArchivageOperationProduction}
                        />
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}