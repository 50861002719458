import { useCallback, useEffect, useState } from "react";
import jobService from "../../../services/survox-back/job.service";
import VoxLoader from "../../../components/genericComponentsUI/loaders/voxLoader";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import { Grid, List, ListItem, ListItemText, ListItemIcon, Typography, Box } from "@mui/material";
import VoxButton from "../../../components/genericComponentsUI/button/voxButton";
import { VoxAlert } from "../../../components/genericComponentsUI/alerte/voxAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VoxCard from "../../../components/genericComponentsUI/card/voxCard";

type JobSurvox = {
  name: string;
  execution: string;
  url: string;
  description: string;
  isLoading: boolean;
};

export default function JobsView() {
  const [jobsSurvox, setJobsSurvox] = useState<JobSurvox[]>([]);
  const [isErrorJobsSurvox, setIsErrorJobsSurvox] = useState<boolean>(false);
  const [isLoadingJobsSurvox, setIsLoadingJobsSurvox] = useState<boolean>();

  const getJobsSurvox = useCallback((controller: AbortController) => {
    setIsLoadingJobsSurvox(true);
    setIsErrorJobsSurvox(false);
    jobService
      .getJobsSurvox(controller.signal)
      .then(response => {
        if (response?.data) {
          setJobsSurvox(
            response.data.map((job: Partial<JobSurvox>) => {
              return { ...job, isLoading: false };
            })
          );
        }
      })
      .catch(err => {
        if (err.message !== "canceled") {
          setIsErrorJobsSurvox(true);
        }
      })
      .finally(() => {
        setIsLoadingJobsSurvox(false);
      });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getJobsSurvox(controller);
    return () => controller.abort();
  }, [getJobsSurvox]);

  const setIsLoadingJobSurvox = useCallback(
    (jobName: string, isLoading: boolean) => {
      setJobsSurvox(
        jobsSurvox.map(job => {
          if (job.name === jobName) {
            job.isLoading = isLoading;
          }
          return job;
        })
      );
    },
    [jobsSurvox, setJobsSurvox]
  );

  const handleJobSurvox = useCallback(
    (name: string, url: string) => {
      setIsLoadingJobSurvox(name, true);
      jobService.executeJobSurvox(url).finally(() => {
        setIsLoadingJobSurvox(name, false);
      });
    },
    [setIsLoadingJobSurvox]
  );

  let jobs;

  if (isLoadingJobsSurvox) {
    jobs = <VoxLoader />;
  } else if (isErrorJobsSurvox) {
    jobs = <VoxAlert severity={"error"}>Une erreur est survenue lors du chargement des jobs de Survox.</VoxAlert>;
  } else {
    jobs = (
      <VoxCard sxContent={{ padding: "0 16px 8px !important" }}>
        <List
          sx={{
            paddingBottom: 0,
            "& > .MuiListItem-root": { padding: "18px 130px 16px 16px" },
            "& > .MuiListItem-root:not(:last-child)": { borderBottom: "1px solid rgba(0,0,0,0.1)" },
          }}
        >
          {jobsSurvox.map((job: JobSurvox) => {
            return (
              <ListItem
                key={job.name}
                secondaryAction={
                  // Bouton "Lancer"
                  <Box sx={{ position: "relative" }}>
                    <VoxButton onClick={() => handleJobSurvox(job.name, job.url)} disabled={job.isLoading}>
                      Lancer
                    </VoxButton>
                    {job.isLoading && <VoxLoader size={20} />}
                  </Box>
                }
              >
                <Box>
                  {/* Nom du job */}
                  <Typography>{job.name} :</Typography>

                  <List
                    sx={{
                      paddingBottom: 0,
                      "& > .MuiListItem-root": { padding: "0 .5rem" },
                    }}
                  >
                    {/* Description (peut être vide) */}
                    {job.description && (
                      <ListItem>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={"info-circle"} />
                        </ListItemIcon>
                        <ListItemText primary={job.description} />
                      </ListItem>
                    )}
                    {/* Périodes d'exécution */}
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={"history"} />
                      </ListItemIcon>
                      <ListItemText primary={job.execution} />
                    </ListItem>
                  </List>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </VoxCard>
    );
  }

  return (
    <>
      <VoxHeader title={"Jobs"} />
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={10} md={6}>
          {jobs}
        </Grid>
      </Grid>
    </>
  );
}
