import { TypeOperation } from "../../models/operation/typeOperation.enum";
import {
    Dialog,
    DialogContent,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { VoxDialogTitle } from "./voxDialogTitle";
import { AuthContext } from "../../contexts/authentication/authentication.context";
import { NomGroupe } from "../../models/utilisateur/nomGroupe.enum";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";
import { OperationsASupprimerForRappelType } from "../../models/operation/operationsASupprimerForRappelType.model";
import VoxLinkUrl from "../genericComponentsUI/link/voxLinkUrl";


const tableSx = {
    border: 'solid 1px #dfe0e1',
    ".MuiTableCell-root": {
        padding: "6px 10px",
        height: "38px"
    },
    ".MuiTableCell-head": {
        height: "45px"
    },
};
const tableRowHeaderSx = {
    backgroundColor: '#f8f9fa'
};
const cellsSx = {
    borderLeft: 'solid 1px #dfe0e1'
};


type ModaleRappelSuppressionOperationsPropsType = {
    open: boolean
    onClose: () => void
    operationsASupprimer: OperationsASupprimerForRappelType[]
}


export default function ModaleRappelSuppressionOperations(props: ModaleRappelSuppressionOperationsPropsType) {

    // récupération de l'utilisateur connecté à l'application
    const authContext = useContext(AuthContext);
    const groupeUtilisateurAuth = authContext.utilisateur.groupe.nom;

    const tableauOperationsASupprimer = (operationsASupprimer: OperationsASupprimerForRappelType[]) => {
        return (
            <TableContainer>
                <Table sx={tableSx}>
                    <TableHead>
                        <TableRow sx={tableRowHeaderSx}>
                        {
                            ["Opération", "Chargé d'opération", "Portefeuille projet", "Jours depuis clôture"].map((nomColonne: string) => {
                                return (
                                    <TableCell sx={cellsSx} key={nomColonne}>
                                        <Typography component={"span"} variant="h5">{nomColonne}</Typography>
                                    </TableCell>
                                )
                            })
                        }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            operationsASupprimer.map((operation: OperationsASupprimerForRappelType) => {
                                return (
                                    <TableRow key={operation.id}>
                                        <TableCell>
                                            <Typography variant="body1">{operation.nomClient}</Typography>
                                            <VoxLinkUrl href={operation.url} isGreyColor={true}>{operation.url}</VoxLinkUrl>
                                        </TableCell>
                                        <TableCell sx={cellsSx}>
                                            <Typography variant="body1">{operation.utilisateur}</Typography>
                                        </TableCell>
                                        <TableCell sx={cellsSx}>
                                            <Typography variant="body1">{operation.portefeuilleProjet}</Typography>
                                        </TableCell>
                                        <TableCell sx={cellsSx}>
                                            <Typography variant="body1" sx={{display: "flex", justifyContent: "center"}}>{operation.nbJoursCloture}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const lienPageOperationsASupprimer = (href: string, texteLien: string) => {
        return (
            <div className={"margin-top-demi"}>
                <Link variant={"body1"} component={RouterLink} to={href}>
                    {texteLien}
                </Link>
            </div>
        );
    }

    // Contenu de la modale de rappel des opérations à supprimer
    let contenuModale;
    if ([NomGroupe.RESPONSABLE_PROJETS_VOTE, NomGroupe.CHEF_DE_PROJET_VOTE].includes(groupeUtilisateurAuth)) {
        contenuModale = (
            <>
                {tableauOperationsASupprimer(props.operationsASupprimer)}

                {(groupeUtilisateurAuth === NomGroupe.RESPONSABLE_PROJETS_VOTE)
                    ? lienPageOperationsASupprimer('operations/supprimer', "Opérations à supprimer")
                    : lienPageOperationsASupprimer('operations/mes_operations', "Mes opérations")
                }
            </>
        );

    } else if (groupeUtilisateurAuth === NomGroupe.CHEF_DE_PROJET_PACKAGE) {

        const operationsSaturneASupprimer: OperationsASupprimerForRappelType[] = props.operationsASupprimer.filter((operation: OperationsASupprimerForRappelType) => operation.type === TypeOperation.SATURNE);
        const operationsMailevaASupprimer: OperationsASupprimerForRappelType[] = props.operationsASupprimer.filter((operation: OperationsASupprimerForRappelType) => operation.type === TypeOperation.MAILEVA);

        contenuModale = (
            <Grid container gap={4}>
                {/* Liste des opérations de type Saturne */}
                {(operationsSaturneASupprimer.length !== 0) &&
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} pb={1.5}>Opérations SATURNE</Typography>

                        {tableauOperationsASupprimer(operationsSaturneASupprimer)}

                        {lienPageOperationsASupprimer('saturne/supprimer', "Opérations Saturne à supprimer")}
                    </Grid>
                }

                {/* Liste des opérations de type Maileva */}
                {(operationsMailevaASupprimer.length !== 0) &&
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} pb={1.5}>Opérations MAILEVA</Typography>

                        {tableauOperationsASupprimer(operationsMailevaASupprimer)}

                        {lienPageOperationsASupprimer('maileva/supprimer', "Opérations Maileva à supprimer")}
                    </Grid>
                }
            </Grid>
        );

    } else {
        // Ne doit jamais arriver
        contenuModale = <VoxAlert severity={"warning"}>Aucune action ne vous est demandée.</VoxAlert>
    }


    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth={"md"}>
            <VoxDialogTitle onClose={props.onClose}>
                Vous avez des opérations à supprimer
            </VoxDialogTitle>

            <DialogContent dividers>
                <Typography pb={2.5}>Ces opérations nécessitent votre intervention pour suppression :</Typography>
                {contenuModale}
            </DialogContent>
        </Dialog>
    )
}