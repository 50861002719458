import {Grid} from "@mui/material";
import {VoxAlert} from "../../genericComponentsUI/alerte/voxAlert";

export default function ViewNonDeveloppee() {
    return (
        <Grid container justifyContent={"center"} marginTop={5}>
            <Grid item xs={8}>
                <VoxAlert severity={"info"}>
                    Fonctionnalité non développée
                </VoxAlert>
            </Grid>
        </Grid>
    )
}