import axios_instance from "../axios-client";

const groupeService =  {

    getAllGroupes: (signal?: AbortSignal) => {
        return axios_instance.get("/groupes", { signal });
    },

    getGroupe: (id: number, signal: AbortSignal) => {
        return axios_instance.get(`groupes/${id}`, { signal });
    }
}

export default groupeService;