import OperationsEnProductionListe from "../operationsEnProduction/operationsEnProductionListe";
import VoxHeader from "../../../components/layouts/viewHeader/voxHeader";
import IntroJsOperationsEnProduction from "../../../components/tutorial/IntroJsOperationsEnProduction";

export default function OperationsEnProductionView() {
    return (
        <>
            <VoxHeader title={"Opérations en production"}
                       isBoutonRetour={true} lienRetour={"/"}
                       boutonTutoriels={<IntroJsOperationsEnProduction/>}
            />
            <OperationsEnProductionListe/>
        </>
    );
}