import { AuthContext } from "../../contexts/authentication/authentication.context";
import { Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";

export default function RestrictedPasswordChangeRoute({ children }: { children: ReactNode }) {
    const location = useLocation();
    return (
        <AuthContext.Consumer>
            {value => {
                if (!value.authenticated || !value.utilisateur.mdpAModifier) {
                    return <Navigate to={"/"} state={{ from: location }} replace={true} />
                }
                return children
            }}
        </AuthContext.Consumer>
    );
}