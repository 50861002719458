import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Hebergeur } from "../../models/operation/hebergeur.enum";
import { EtatDemande } from "../../models/operation/etatDemande.enum";
import { Typography } from "@mui/material";
import VoxModal from "./voxModal";
import { OperationModificationAvanceeForm } from "../../models/operation/operationForm/operationModificationAvanceeForm.model";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";

type modaleType = {
    show: boolean,
    operation: OperationModificationAvanceeForm,
    etatDemandePrecedantOperation: EtatDemande | string,
    isAlerteOperationScelleOuVoteEnCours: boolean,
    handleClose: () => void,
    handleSubmit: () => void,
}


export default function ModaleConfirmationSuppressionAvancee(props: modaleType) {

    let modalBody;

    // Message d'information avant la suppression d'une opération et de la VM associée
    let body = (
        <p>
            Le passage de l'opération à l'état "a_supprimer" va déclencher la suppression de l'opération ainsi que la suppression automatique de la VM associée.
        </p>
    )

    if (props.operation.hebergeur === Hebergeur.OVH && props.operation.etatDemande === EtatDemande.A_SUPPRIMER) {

        if (props.isAlerteOperationScelleOuVoteEnCours) {
            // Message de confirmation supplémentaire, car le site est scellé ou en cours de vote
            modalBody = (
                <Typography component={"div"} variant={"body1"}>
                    {body}
                    <br />
                    <p>
                        <FontAwesomeIcon icon={"exclamation-triangle"} className={"me-2"}/>
                        <span>Le site est scellé ou le vote est en cours : êtes-vous sûr de vouloir supprimer cette VM ?</span>
                    </p>
                </Typography>
            )
        } else {
            modalBody = (
                <Typography component={"div"} variant={"body1"}>
                    {body}
                    <br />
                    <p>Souhaitez-vous vraiment effectuer la suppression ?</p>
                </Typography>
            )
        }

    } else if (props.operation.etatDemande === EtatDemande.SUPPRIME) {

        if (props.operation.hebergeur === Hebergeur.OVH) {

            if (props.etatDemandePrecedantOperation === EtatDemande.A_SUPPRIMER) {
                // passage de l'état "a_supprimer" vers l'état "supprime"
                modalBody = (
                    <Typography component={"div"} variant={"body1"}>
                        <p>L'opération va basculer à l'état "supprimé" et sera désormais visible dans l'écran des opérations supprimées.</p>
                        <br />
                        <p>Pensez à vérifier la suppression de l'enregistrement DNS et de la VM associée.</p>
                    </Typography>
                )

            } else {
                // Opération en cours d'installation chez OVH
                // => ne peut pas arriver (message d'erreur informatif, au cas où)
                modalBody = (
                    <VoxAlert severity={"error"}>
                        Il n'est pas possible de passer l'opération à l'état "supprime" car la VM est en cours d'installation chez OVH.
                        Si vous souhaitez arrêter l'installation et supprimer la VM, vous devez choisir l'état "a_supprimer".
                    </VoxAlert>
                )
            }

        } else {
            // Hébergeur Docaposte
            modalBody = (
                <Typography component={"div"} variant={"body1"}>
                    <p>L'opération va basculer à l'état "supprimé" et sera désormais visible dans l'écran des opérations supprimées.</p>
                    <br />
                    <p>Vous devez vous assurez au préalable d'avoir bien supprimé la VM chez Docaposte avant de procéder à la suppression côté Survox.</p>
                </Typography>
            )
        }

    }


    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={"Suppression d'une opération"}
            content={modalBody}
            closeButtonText={"Non"}
            submitButtonText={"Oui, je confirme"}
        />
    )
}