import axios_instance from "../axios-client";
import {CreateEnvOperationsDto} from "./dtos/createEnvOperations.dto";
import {UpdateEnvOperationsDto} from "./dtos/updateEnvOperations.dto";
import {EtatDemande} from "../../../models/operation/etatDemande.enum";
import {UpdateEtatEnvOperationsDto} from "./dtos/updateEtatEnvOperations.dto";

const EnvironnementsService = {
    /**
     * GET Méthodes
     */

    getAllEnvironnements: (signal?: AbortSignal) => {
      return axios_instance.get('/environnements/all', {signal: signal})
    },

    getSomeEnvironnementsByOperationId: (operationId: number, signal?: AbortSignal) => {
      return axios_instance.get(`/environnements/some/op-id/${operationId}`, {signal: signal})
    },

    getSomeEnvironnementsByEtatDemande: (etat: EtatDemande, signal?: AbortSignal) => {
        return axios_instance.get(`/environnements/some/etat/${etat}`, {signal: signal})
    },

    /**
     * POST Méthodes
     */
    createEnvironnementsOperations: (createEnvOperationsDto: CreateEnvOperationsDto) => {
        return axios_instance.post('environnements/new', createEnvOperationsDto)
    },

    /**
     * PUT Méthodes
     */
    updateEnvironnementsOperations: (updateEnvOperationsDto: UpdateEnvOperationsDto) => {
        return axios_instance.put('environnements/maj', updateEnvOperationsDto)
    },

    updateEtatEnvironnementOperation: (updateEtatDto: UpdateEtatEnvOperationsDto) => {
        return axios_instance.put('/environnements/maj-etat', updateEtatDto)
    },
}

export default EnvironnementsService;