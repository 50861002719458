import { VersionApplicative } from "../../models/versionApplicative/versionApplicative.model";
import { DialogContentText } from "@mui/material";
import VoxModal from "./voxModal";
import { Composant } from "../../models/versionApplicative/composant.model";
import { VoxAlert } from "../genericComponentsUI/alerte/voxAlert";

type ModalProps = {
    type: "versionApplicative" | "composant";
    show: boolean;
    archivees: VersionApplicative[] | Composant[];
    handleClose: () => void;
    handleSubmit: () => void;
}

export default function ModaleConfirmationArchivageVersionsProduits(props: Readonly<ModalProps>) {

    let reglesArchivage;
    if (props.type === "versionApplicative") {
        reglesArchivage = (
            <DialogContentText id={'ReglesArchivage'}>
                <>
                    Rappel des règles d'archivage.<br/>
                    Sont concernées, les versions applicatives :<br/>
                    - Non définies comme version autorisée pour les CP<br/>
                    - Non liées à une opération<br/>
                    - Et dont la date de référencement est supérieure à 1 mois<br/><br/>

                    { !!props.archivees.length && <>Les versions suivantes vont être archivées :<br/></> }
                </>
            </DialogContentText>
        )
    } else {
        reglesArchivage = (
            <DialogContentText id={'ReglesArchivage'}>
                <>
                    Rappel des règles d'archivage.<br/>
                    Sont concernés, les composants :<br/>
                    - Non liés à au moins une version applicative<br/>
                    - Et dont la date de référencement est supérieure à 1 mois<br/><br/>

                    { !!props.archivees.length && <>Les composants suivants vont être archivés :<br/></> }
                </>
            </DialogContentText>
        )
    }

    let listeArchivees;
    if (props.archivees.length) {
        listeArchivees = (
            <ul>
                {
                    props.archivees.map(el => (
                        <li key={el.version}><DialogContentText>{ el.version }</DialogContentText></li>
                    ))
                }
            </ul>
        );
    } else {
        const texteModal = props.type === "versionApplicative" ? "Aucune version ne peut être archivée" : "Aucun composant ne peut être archivé"
        listeArchivees = (
            <VoxAlert severity="info">{texteModal}</VoxAlert>
        )
    }

    return (
        <VoxModal
            open={props.show}
            onSubmit={props.handleSubmit}
            onClose={props.handleClose}
            title={<p>Archivage Automatique des { props.type === "versionApplicative" ? "versions applicatives" : "composants" }</p>}
            content={
                <>
                    { reglesArchivage }
                    { listeArchivees }
                </>
            }
            closeButtonText="Annuler"
            startIconCloseButton={"ban"}
            customColorCloseButton={"red"}
            submitButtonText="Valider"
            startIconSubmitButton={"save"}
            customColorSubmitButton={"green"}
            submitButtonDisabled={!props.archivees.length}
        />
    )
}